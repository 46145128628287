import {
  getDeviceType,
  getPatientAccess,
  makeClient,
  makeClientKey,
  makeDevice,
  makePatient,
  memberships,
  org,
  patients,
} from './data'
import { dateFloat } from './utils'

const Mutation = {
  createClientKey: (_, { input } = {}) => {
    const { clientId } = input
    const clientKey = makeClientKey()
    const clientKeySecret = 'cb2b70ee-6d4f-464e-a05f-803ddaebf5c3'
    patients.forEach((p) => {
      p.clientList.clients.forEach((client) => {
        if (client.id === clientId) {
          client.clientKeyList.clientKeys.push(clientKey)
        }
      })
    })
    return {
      clientKey,
      clientKeySecret,
    }
  },
  resendUserInvitation: (_, { input = {} } = {}) => {
    const { userId } = input
    const membership = memberships.find((membership) => {
      return membership.user.id === userId
    })
    membership.user.invitatedAt = dateFloat()
    return { ok: true }
  },

  updateMembership: (_, { input } = {}) => {
    const { admin, membershipId } = input
    const membership = memberships.find((m) => m.id === membershipId)
    membership.admin = admin
    return membership
  },

  updatePatient: (_, { input } = {}) => {
    const { patientId, codeName } = input
    const patient = patients.find((p) => p.id === patientId)
    patient.codeName = codeName
    return { patient }
  },
  updateClient: (_, { input } = {}) => {
    const { clientId, displayName, disabled } = input
    patients.forEach((p) => {
      const client = p.clientList.clients.find((c) => c.id === clientId)
      if (client) {
        displayName && (client.displayName = displayName)
        disabled !== undefined && (client.disabled = disabled)
      }
    })
    return { client: { id: clientId } }
  },
  updateClientKey: (_, { input } = {}) => {
    const { clientKeyId, disabled } = input
    patients.forEach((patient) => {
      patient.clientList.clients.forEach((client) => {
        const key = client.clientKeyList.clientKeys.find(
          (key) => key.id === clientKeyId,
        )
        if (key) {
          key.disabled = disabled
        }
      })
    })
    return { clientKey: { clientKeyId } }
  },
  updateDevice: (_, { input } = {}) => {
    const { deviceId, deviceType } = input
    let device
    patients.find((patient) => {
      device = patient.deviceList.devices.find((d) => d.id === deviceId)
      return !!device
    })
    if (device) {
      const newValues = deviceType
        ? {
            ...input,
            deviceType: getDeviceType(deviceType),
          }
        : { ...input }
      Object.assign(device, newValues)
    }
    return { device }
  },
  createClient: (_, { input = {} } = {}) => {
    const { displayName, patientId } = input
    const clientKey = makeClientKey()
    const clientKeySecret = 'a6b7af18-6c2f-4bbf-95ca-84b9ceefe370'
    const client = makeClient({
      displayName,
      clientKeys: [clientKey],
    })
    const patient = patients.find((p) => p.id === patientId)
    patient.clientList.clients.push(client)
    return {
      client,
      clientKey,
      clientKeySecret,
    }
  },
  createDevice: (_, { input = {} } = {}) => {
    const device = makeDevice(input)
    const patient = patients.find((p) => p.id === input.patientId)
    patient.deviceList.devices.push(device)
    return { device }
  },
  createPatient: (_, { input = {} } = {}) => {
    const { codeName, client: clientInput, device: deviceInput } = input
    const patientId = 'f6ce406f-27b5-4dbd-a1ff-5c80e97af53b'
    const devices = []
    const clients = []
    let client, device
    if (clientInput && deviceInput) {
      const { deviceType, alias } = deviceInput
      device = makeDevice({
        patientId,
        deviceType,
        alias,
      })
      devices.push(device)
      const clientKey = makeClientKey()
      const { displayName } = clientInput
      clients.push(
        makeClient({
          displayName,
          clientKeys: [clientKey],
        }),
      )
      client = {
        clientKey: clientKey,
        clientKeySecret: '17c9d883-179e-4ad4-af08-dbdcbfb7e972',
      }
    }
    const patient = makePatient({
      id: patientId,
      codeName,
      devices,
      clients,
    })
    patients.push(patient)
    org.patientAccessList.patientAccess = getPatientAccess(patients)
    return {
      patient,
      client,
      device,
    }
  },
}
export default Mutation
