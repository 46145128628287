import axios from 'axios'

import { NEXT_PAGE_TOKEN } from './consts'

/**
 * Call the Tangerine API, with authentication handling
 * @param url
 * @param params
 * @param axiosOptions
 * @param timezone
 * @param tangerineApiClient
 * @param method
 * @return {Promise<*>}
 */
export const callTangerineApi = async ({
  url,
  params,
  headers,
  getHeaders,
  handleUnauthenticated,
  axiosOptions,
  method,
}) => {
  if (!headers) {
    try {
      headers = await getHeaders()
    } catch (error) {
      handleUnauthenticated(error)
      throw error
    }
  }

  if (method === 'GET') {
    try {
      const response = await axios.get(url, {
        params,
        headers,
      })

      const nextPageTokenResponse =
        response?.headers?.[NEXT_PAGE_TOKEN.responseHeader]

      return {
        ...(response?.data || response),
        ...(nextPageTokenResponse && {
          [NEXT_PAGE_TOKEN.key]: nextPageTokenResponse,
        }),
      }
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  } else {
    try {
      delete params.timezone_name
      headers['Content-Type'] = 'application/json'
      let response = {}
      if (method === 'POST') {
        response = await axios.post(url, params, {
          headers,
          ...axiosOptions,
        })
      } else if (method === 'PATCH') {
        response = await axios.patch(url, params, { headers })
      } else if (method === 'DELETE') {
        response = await axios.delete(url, { headers })
      }
      return response ? response.data : response
    } catch (exception) {
      console.error(exception)
      if (axios.isCancel(exception)) {
        throw exception
      }
      const { message = 'Network error', response: { data } = {} } =
        exception || {}
      if (!data) {
        throw message
      }
      const { error } = data
      if (error) {
        throw error.message || error
      }
      throw exception
    }
  }
}
