import { useContext } from 'react'
import { labels } from './consts'
import {
  AccordionSummaryBar,
  AccordionSummaryBarLoading,
  AccordionSummaryBarNoData,
} from 'ui/components/AccordionSummaryBar'
import { StyledKeyValuePair } from 'ui/components/StyledKeyValuePair'
import { RecentDataAvailabilityContext } from 'ui/contexts'
import { BulletedTooltipContent } from 'ui/components/InformationalIconTooltip'
import {
  HOUR_IN_SECONDS,
  prettyPrintRoundedTimeInHoursAndMinutes,
} from 'utilities/time'
import { string } from 'prop-types'
import { DATE_RANGE } from 'ui/contexts/DateRangeContext'
import { SummaryMetric } from '../../SummaryMetric'
import CollapsibleViewsNoData from '../CollapsibleViewsNoData'

const SummaryBar = (title, subtitle, data) => {
  const { tremor, dyskinesia } = data || {}

  const tremorAverage = prettyPrintRoundedTimeInHoursAndMinutes(
    tremor.mean[0].value / HOUR_IN_SECONDS,
  )
  const tremorMax = prettyPrintRoundedTimeInHoursAndMinutes(
    tremor.max[0].value / HOUR_IN_SECONDS,
  )
  const tremorMin = prettyPrintRoundedTimeInHoursAndMinutes(
    tremor.min[0].value / HOUR_IN_SECONDS,
  )
  const tremorTrend = tremor.trend
  const dyskinesiaAverage = prettyPrintRoundedTimeInHoursAndMinutes(
    dyskinesia.mean[0].value / HOUR_IN_SECONDS,
  )
  const dyskinesiaMax = prettyPrintRoundedTimeInHoursAndMinutes(
    dyskinesia.max[0].value / HOUR_IN_SECONDS,
  )
  const dyskinesiaMin = prettyPrintRoundedTimeInHoursAndMinutes(
    dyskinesia.min[0].value / HOUR_IN_SECONDS,
  )
  const dyskinesiaTrend = dyskinesia.trend

  return (
    <AccordionSummaryBar
      title={title}
      subtitle={subtitle}
      dataComponents={[
        <SummaryMetric
          key={labels.AVG_TREMOR_TIME}
          title={labels.AVG_TREMOR_TIME}
          metrics={{
            h: tremorAverage.hours || '0',
            m: tremorAverage.minutes || '0',
          }}
          minMetrics={{
            h: tremorMin.hours || '0',
            m: tremorMin.minutes || '0',
          }}
          maxMetrics={{
            h: tremorMax.hours || '0',
            m: tremorMax.minutes || '0',
          }}
          trend={tremorTrend}
          tooltip={
            <BulletedTooltipContent
              bulletedContent={labels.TOOLTIP_LIST_FOR_TREMOR}
            />
          }
        />,

        <SummaryMetric
          key={labels.AVG_DYSKINESIA_TIME}
          title={labels.AVG_DYSKINESIA_TIME}
          metrics={{
            h: dyskinesiaAverage.hours || '0',
            m: dyskinesiaAverage.minutes || '0',
          }}
          minMetrics={{
            h: dyskinesiaMin.hours || '0',
            m: dyskinesiaMin.minutes || '0',
          }}
          maxMetrics={{
            h: dyskinesiaMax.hours || '0',
            m: dyskinesiaMax.minutes || '0',
          }}
          trend={dyskinesiaTrend}
          tooltip={
            <BulletedTooltipContent
              bulletedContent={labels.TOOLTIP_LIST_FOR_DYSKINESIA}
            />
          }
        />,
      ]}
      metadataComponents={[
        <StyledKeyValuePair
          label={labels.NUMBER_DAYS_WITH_DATA}
          value={data.numberOfDaysWithData || '0'}
        />,
      ]}
    />
  )
}

const TremorAndDyskinesiaSummary = ({
  title,
  subtitle,
  isLoading,
  hasError,
  hasDevice,
  hasData,
  data,
}) => {
  const { data: { tremorAndDyskinesia } = {} } =
    useContext(RecentDataAvailabilityContext) || {}

  if (isLoading) {
    return (
      <AccordionSummaryBarLoading
        title={title}
        subtitle={subtitle}
        dataLabel={labels.FETCHING_TEXT}
      />
    )
  }

  if (!hasDevice) {
    return (
      <AccordionSummaryBarNoData
        title={title}
        subtitle={subtitle}
        dataLabel={labels.NO_DEVICE_TEXT}
      />
    )
  }

  if (hasError) {
    return (
      <AccordionSummaryBarNoData
        title={title}
        subtitle={subtitle}
        dataLabel={labels.ERROR_TEXT}
      />
    )
  }

  if (!hasData) {
    return (
      <AccordionSummaryBarNoData
        title={title}
        subtitle={subtitle}
        dataLabel={
          <CollapsibleViewsNoData
            text={labels.NO_DATA_TEXT}
            latestActivityDate={tremorAndDyskinesia}
            alertType="Tremor and Dyskinesia"
          />
        }
      />
    )
  }

  return SummaryBar(title, subtitle, data)
}

TremorAndDyskinesiaSummary.propTypes = {
  title: string.isRequired,
  subtitle: string,
}

TremorAndDyskinesiaSummary.defaultProps = {
  subtitle: '',
  dateRangeKey: DATE_RANGE.default.key,
}

export default TremorAndDyskinesiaSummary
