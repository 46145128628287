import { BarChart, Stack } from 'ui/baseComponents'
import { formatDailyEventsBarChartData } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
import { colors } from 'theme/colors'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle/DailyChartTitle'
import {
  SECTION_TITLES,
  smallChartHeight,
} from 'ui/clinicianScreens/Patient/Daily/consts'
import { CLINICIAN_RED_FLAGS } from 'ui/clinicianScreens/Patient/Report/sections/SymptomLog/consts'
import { RedFlagIcon } from 'ui/components/RedFlagIcon'
import moment from 'moment'
import { memo, useContext, useEffect } from 'react'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

const DailySymptoms = ({
  date,
  data = [],
  activeFilters = [],
  setHasDataForDay = () => {},
}) => {
  const { selectedTimezone } = useContext(DateRangeContext)

  const legendItem = {
    name: SECTION_TITLES.symptoms,
    color: colors.RED[200],
  }

  const redFlagLegendItem = {
    name: SECTION_TITLES.symptoms,
    color: colors.RED[200],
  }

  const filteredData = formatDailyEventsBarChartData({
    date,
    data,
    activeFilters,
    label: SECTION_TITLES.symptoms,
    timezoneName: selectedTimezone,
  })

  useEffect(() => {
    if (filteredData.length > 0) {
      setHasDataForDay(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData.length])

  return filteredData.length > 0 ? (
    <Stack>
      {filteredData.map((item, i, a) => {
        const isRedFlag = CLINICIAN_RED_FLAGS.includes(item[0].classification)

        return (
          <Stack
            spacing={0}
            key={`daily-symptoms-${moment(date).format('MM DD')}-${
              item[0].title
            }`}
          >
            <DailyChartTitle
              key={item.timestamp}
              variant={isRedFlag ? 'body12B' : 'body12'}
              component="h3"
              sx={{ height: 0 }}
            >
              {item[0].title}{' '}
              {isRedFlag && <RedFlagIcon styles={{ fontSize: '.9rem' }} />}
            </DailyChartTitle>

            <Stack sx={{ paddingLeft: '60px' }}>
              <BarChart
                {...{
                  title: SECTION_TITLES.symptoms,
                  height: smallChartHeight,
                  barSize: null,
                  barCategoryGap: 0,
                  barGap: 0,
                  data: item,
                  legendItem: isRedFlag ? redFlagLegendItem : legendItem,
                  yAxisMax: 1,
                  yAxisUnit: ' mins',
                  legend: false,
                  isAnimationActive: false,
                  hideYAxis: true,
                  hideXAxis: true,
                  hideXAxisTickLine: true,
                  hideVerticalGridLines: true,
                  hideTopXAxisGridLines: true,
                  chartId: `daily-chart-symptoms`,
                }}
              />
            </Stack>
          </Stack>
        )
      })}
    </Stack>
  ) : null
}

export default memo(DailySymptoms)
