import mixpanel from 'mixpanel-browser'
import { orgMembershipIdList, orgMembershipNamesList } from './helpers'

/**
 * Register a set of super properties, which are included with all mixpanel events.
 *
 * @param {object} currentUser Corresponds to the user returned by the GraphQL getMe query
 * @returns {void}
 */
export const registerMixpanelSuperProperties = (currentUser) => {
  mixpanel.register({
    orgMembershipIds: orgMembershipIdList(currentUser),
    orgMembershipNames: orgMembershipNamesList(currentUser),
    currentOrgId: currentUser.defaultMembership.org.id,
    currentOrgName: currentUser.defaultMembership.org.displayName,
    role: currentUser.defaultMembership.role.displayName,
  })
}
