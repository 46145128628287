import { useContext, useState } from 'react'
import { element, string } from 'prop-types'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Divider,
  Grid,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { RUNE_GRAY_300 } from 'theme/colors'
import { UiStateContext } from 'ui/contexts/UiStateContext'
import { ACCORDION_CONTENT_STYLE } from './consts'
import { SUMMARY_BAR_BOX_SHADOW } from 'ui/components/SummaryBar/consts'

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: theme.palette.background.legend,
    borderRadius: 5,
    ...SUMMARY_BAR_BOX_SHADOW,
    '&.Mui-disabled': {
      backgroundColor: theme.palette.background.legend,
    },
  },
  content: {
    ...ACCORDION_CONTENT_STYLE,
  },
  details: {
    minHeight: theme.spacing(8),
    padding: 0,
  },
  disabled: {
    visibility: 'hidden',
  },
  divider: {
    width: '100%',
    color: RUNE_GRAY_300,
  },
  dividerItem: {
    width: '100%',
  },
  gridDetails: {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  summary: {
    '&.Mui-disabled': {
      opacity: 'unset',
      pointerEvents: 'unset',
    },
  },
}))

const Accordion = ({ title, summary, details }) => {
  const classes = useStyles()
  const { uiState = {}, setUiState } = useContext(UiStateContext) || {}
  const [expanded, setExpanded] = useState(uiState[title] || false)
  const disabled = !details

  const handleChange = (_, expandedState) => {
    setExpanded(expandedState)
    setUiState(title, expandedState)
  }

  return (
    <MuiAccordion
      disabled={disabled}
      expanded={expanded && !disabled}
      onChange={handleChange}
      className={classes.accordion}
    >
      <AccordionSummary
        className={classes.summary}
        classes={{
          content: classes.content,
          expandIcon: disabled ? classes.disabled : null,
        }}
        expandIcon={
          <ExpandMoreIcon aria-label={`Expand or collapse ${title} content`} />
        }
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Grid container direction="column" alignItems="center">
          <Grid item className={classes.dividerItem}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item className={classes.gridDetails}>
            {details}
          </Grid>
        </Grid>
      </AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.propTypes = {
  title: string,
  summary: element,
  details: element,
}

Accordion.defaultProps = {
  title: '',
  summary: null,
  details: null,
}

export default Accordion
