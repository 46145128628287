import { useCallback, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import SubmitButton from 'ui/components/SubmitButton/SubmitButton'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { UPDATE_CLIENT } from '../mutations'
import { useRefetchFullPatient } from 'ui/screens/EditPatient/queries'
import { PatientContext } from 'ui/contexts'

export const EditClientName = ({ name: nameProp, clientId, disabled }) => {
  const { id: patientId } = useContext(PatientContext)
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState()
  const [displayName, setDisplayName] = useState(nameProp)
  const [updateClient, { loading }] = useMutation(UPDATE_CLIENT, {
    onError: (error) => {
      console.error(error)
      setError('Unable to update Client')
    },
    refetchQueries: [useRefetchFullPatient(patientId)],
  })
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        const input = {
          clientId,
          displayName,
        }
        await updateClient({ variables: { input } })
        setEditing(false)
      } catch (error) {
        console.error(error)
        setError('Unable to update Client name')
      }
    },
    [clientId, displayName, updateClient],
  )
  const onChange = useCallback((e) => setDisplayName(e.target.value), [])
  if (editing) {
    return (
      <form onSubmit={onSubmit}>
        <FormControl>
          <TextField
            value={displayName}
            autoFocus
            error={!!error}
            helperText={error}
            onChange={onChange}
            data-cy="edit-name"
          />
        </FormControl>
        <SubmitButton disabled={loading} icon={null} data-cy="save-button">
          Save
        </SubmitButton>
        <Button
          onClick={() => {
            setDisplayName(nameProp)
            setEditing(false)
          }}
        >
          Cancel
        </Button>
      </form>
    )
  } else {
    return (
      <Typography variant="h6">
        {displayName}
        {!disabled && (
          <Button data-cy="edit-button" onClick={() => setEditing(true)}>
            Edit
          </Button>
        )}
      </Typography>
    )
  }
}

export default EditClientName
