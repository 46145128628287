import { Stack, Text } from 'ui/baseComponents'
import dyskinesiaInsightsLeft from 'assets/img/dyskinesia-insights-left.svg'
import dyskinesiaInsightsRight from 'assets/img/dyskinesia-insights-right.svg'
import { colors } from 'theme/colors'
import {
  DYSKINESIA_INSIGHTS_BANNER_CLINICIAN_TEXT,
  DYSKINESIA_INSIGHTS_BANNER_PATIENT_TEXT,
} from 'ui/clinicianScreens/Patient/Report/sections/DIN/consts'

const STYLES = {
  dyskinesiaInsightsBanner: {
    backgroundColor: colors.PRIMARY[300],
    padding: '0.5rem 1.5rem',
    borderRadius: '1rem',
  },
  dyskinesiaInsightsImage: {
    marginTop: '-1.6rem',
  },
}

const DyskinesiaInsightsBanner = ({ isClinicianReport }) => (
  <Stack
    direction="row"
    justifyContent="center"
    gap={1}
    sx={STYLES.dyskinesiaInsightsBanner}
    data-cy="din-insights-banner"
  >
    <img
      src={dyskinesiaInsightsLeft}
      height={80}
      alt=""
      style={STYLES.dyskinesiaInsightsImage}
    />
    <Stack alignItems="center">
      <Text variant="head24B" color={colors.PRIMARY[1100]}>
        Dyskinesia Insights
      </Text>
      <Text variant="body16" color={colors.PRIMARY[1000]}>
        {isClinicianReport
          ? DYSKINESIA_INSIGHTS_BANNER_CLINICIAN_TEXT
          : DYSKINESIA_INSIGHTS_BANNER_PATIENT_TEXT}
      </Text>
    </Stack>
    <img
      src={dyskinesiaInsightsRight}
      height={80}
      alt=""
      style={STYLES.dyskinesiaInsightsImage}
    />
  </Stack>
)

export default DyskinesiaInsightsBanner
