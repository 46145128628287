import { createContext, useCallback, useState } from 'react'

/**
 * Persist state of the Summary Mode as well as provide get/set access.
 */
export const SummaryModeContext = createContext()

const useSummaryModeContext = () => {
  const [comparisonModeEnabled, setComparisonModeEnabled] = useState(false)

  return {
    comparisonModeEnabled,
    setComparisonModeEnabled: useCallback(
      (value) => setComparisonModeEnabled(value),
      [],
    ),
  }
}

export default useSummaryModeContext
