import React from 'react'
import MUIFormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 150,
    maxWidth: 300,
    margin: '1em',
  },
}))

const FormControl = (props) => {
  const classes = useStyles()

  const className = clsx(props.className, classes.formControl)
  return <MUIFormControl className={className} {...props} />
}

export default FormControl
