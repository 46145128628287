import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import { useContext } from 'react'
import RelativeDayRangePicker from './RelativeDayRangePicker'
import { TIME_BASIS_RELATIVE } from 'ui/components/TimeBasis/consts'
import TimeBasis from '../TimeBasis'
import { TimeBasisContext } from '../TimeBasis/TimeBasisContext'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './DateRangePicker.scss'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { DateRangePickerContext } from 'ui/contexts/DateRangePickerContext'
import DateRangePicker from './DateRangePicker'
import { DATE_FORMAT } from 'ui/consts'

/**
 * Control for picking a start and end date (with no time-of-day resolution).
 * @returns {JSX.Element} Components for selecting a range of dates.
 */
const TimeBasisDateRangePicker = () => {
  const {
    to,
    setTo,
    from,
    setFrom,
    onLeftArrowClick,
    onRightArrowClick,
    setDateRange,
  } = useContext(DateRangePickerContext)

  const {
    timeBasis: { type, eventTimestamp },
  } = useContext(TimeBasisContext)

  const onDatesChange = ({ startDate, endDate }) => {
    const newFrom = moment(startDate).format(DATE_FORMAT)
    const newTo = moment(endDate).format(DATE_FORMAT)
    setFrom(newFrom)
    setTo(newTo)

    setDateRange({
      from: newFrom,
      to: newTo,
    })
  }

  const leftArrow = (
    <IconButton
      size="medium"
      data-cy="left-arrow"
      onClick={onLeftArrowClick}
      title="Previous period"
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  )

  const rightArrow = (
    <IconButton
      size="medium"
      disabled={to === moment().format(DATE_FORMAT)}
      data-cy="right-arrow"
      onClick={onRightArrowClick}
      title="Next period"
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  )

  const dateRangePicker =
    type === TIME_BASIS_RELATIVE && eventTimestamp ? (
      <RelativeDayRangePicker
        from={from}
        to={to}
        onDatesChange={onDatesChange}
        eventTimestamp={eventTimestamp}
        leftArrow={leftArrow}
        rightArrow={rightArrow}
      />
    ) : (
      <DateRangePicker />
    )

  return (
    <>
      {dateRangePicker}
      <TimeBasis />
    </>
  )
}

TimeBasisDateRangePicker.propTypes = {}

TimeBasisDateRangePicker.defaultProps = {}

export default TimeBasisDateRangePicker
