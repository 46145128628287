import {
  arrayOf,
  element,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types'

const ContextCollection = ({ contexts, children }) =>
  contexts.reduce((components, nextContext) => {
    const { context: Context, value } = nextContext

    return <Context.Provider value={value}>{components}</Context.Provider>
  }, children)

ContextCollection.propTypes = {
  contexts: arrayOf(
    shape({
      context: object.isRequired,
      value: oneOfType([string, object, oneOf([null])]),
    }),
  ).isRequired,
  children: oneOfType([element, arrayOf(element)]).isRequired,
}

ContextCollection.defaultProps = {}

export default ContextCollection
