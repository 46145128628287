import { useContext } from 'react'
import { useStreamApi } from 'domains/streamApi'
import { PatientContext } from 'ui/contexts'
import {
  getTimestampStartOfNDaysAgo,
  getTimestampStartOfNextDay,
} from 'utilities/time'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
/*
 * createRecentDataAvailabilityRequest creates the requests for calling the data_availability endpoint
 * @param {string} patientId - the patient id
 * @param {string} startTime - the time to begin querying
 * @param {string} endTime - the time to end querying
 * @param {string} metricCategories - the metric categories to query for
 * @param {boolean} shouldExecuteAvailabilityCall - whether to pass the skip parameter to the api call
 * @return {Object} - the request to make
 *
 */

const createRecentDataAvailabilityRequest = ({
  patientId,
  startDate,
  endDate,
  metricCategories,
  shouldExecuteAvailabilityCall,
  timezoneName,
}) => {
  const startTime = getTimestampStartOfNDaysAgo({
    date: startDate,
    numberOfDays: 90,
    timezoneName,
  }) // startTime should be the equivalent of 12:00am in the specified timezone

  const endTime = getTimestampStartOfNextDay(endDate, timezoneName) // endTime should be the equivalent of 12:00am the day following the last day for which we want data in the user's local time. (end_time is exclusive so we want it to be the first day out of range.)

  const params = {
    start_time: startTime,
    end_time: endTime,
    patient_id: patientId,
    metric_categories: metricCategories,
  }

  const request = {
    url: `/metric_categories/recent_availability`,
    params: params,
    versionPrefix: 'v2',
    shouldExecute: shouldExecuteAvailabilityCall,
    selectedTimezone: timezoneName,
  }
  return request
}

/**
 * Maps the mobility aggregate data from the API to the format expected by the frontend.
 * @param {object} response mobility aggregate data from the API
 * @returns {object} Mobility aggregate data formatted for the frontend
 */
const formatRecentAvailabilityData = (response) => {
  if (!response) {
    return {}
  }

  const { data = {} } = response?.data || {}

  const { tremor_dyskinesia: tremorAndDyskinesia, mobility, sleep } = data

  // get the most recent timestamp that's not mobility
  const getLatestActivityDate = () => {
    const { mobility, ...metrics } = data
    const latestTimestamp = Object.values(metrics).reduce((latest, date) => {
      if (date > latest) {
        return date
      }
      return latest
    }, 0)
    return latestTimestamp === 0 ? null : latestTimestamp
  }

  const formattedData = {
    latestActivityDate: getLatestActivityDate(),
    tremorAndDyskinesia: tremorAndDyskinesia || null,
    mobility: mobility || null,
    sleep: sleep || null,
  }

  return {
    data: formattedData,
    isLoading: response.loading,
    hasError: !!response.error,
    error: response.error,
  }
}

const useRecentAvailability = (startTime, endTime, skipAvailabilityCall) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const metricCategories = 'tremor_dyskinesia,mobility,sleep'

  const patientContext = useContext(PatientContext)

  const request = createRecentDataAvailabilityRequest({
    patientId: patientContext.id,
    startDate: startTime,
    endDate: endTime,
    metricCategories,
    skipAvailabilityCall,
    timezoneName: selectedTimezone,
  })
  const response = useStreamApi(request)
  const formattedAvailabilityData = formatRecentAvailabilityData(response)
  return formattedAvailabilityData
}

export default useRecentAvailability
