import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'ui/baseComponents/Text'
import { Stack } from 'ui/baseComponents/Stack'
import ReportSection from '../../components/ReportSection'
import { Table } from 'ui/baseComponents/Table'
import ReportMetricTrendIcon from '../../components/ReportMetricTrendIcon'
import EditableContent from '../../components/EditableContent'
import useTremorAndDyskinesiaAggregate from 'ui/hooks/summaryAggregates/useTremorAndDyskinesiaAggregate'
import { PatientContext } from 'ui/contexts'
import useMobilityAggregate from 'ui/hooks/summaryAggregates/useMobilityAggregate'
import usePatientEvents from 'ui/hooks/usePatientEvents'
import { reFetchEventList } from 'ui/screens/Patient/queries'
import {
  EDUCATIONAL_TEXT,
  NO_RED_FLAGS_TEXT,
  ACTIVITY_RECOMMENDATION_MET_TEXT,
  ACTIVITY_RECOMMENDATION_NOT_MET_TEXT,
  SLOW_WALKING_SPEED_TEXT,
} from './consts'
import {
  AVG_PER,
  CHANGE_THRESHOLD_DEFAULT,
  SECTION_TITLES,
} from 'ui/clinicianScreens/Patient/Report/consts'
import {
  formatAverage,
  formatDiff,
  getAverageDiff,
  getAveragePercentChange,
  isAtOrBeyondThreshold,
  summedArray,
} from 'ui/clinicianScreens/Patient/Report/helpers'
import {
  calculateWeeklyActivityDurations,
  getAveragePerWeek,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/helpers'
import {
  getTremorDyskinesiaHighlight,
  getMobilityHighlight,
  sortHighlights,
  getHighlightDefaultText,
} from 'ui/clinicianScreens/Patient/Report/sections/Highlights/helpers'
import { MOBILITY_METRICS } from 'ui/clinicianScreens/Patient/Report/sections/Mobility/consts'
import useBoundStore from 'domains/zustand/store'
import { MINUTE_IN_SECONDS } from 'utilities/time'

const ReportSectionHighlights = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  isClinicianReport,
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
  startTime,
  endTime,
  prevStartTime,
  prevEndTime,
}) => {
  const {
    ACTIVITY,
    HIGHLIGHTS,
    MOBILITY,
    TREMOR,
    DYSKINESIA,
    RED_FLAGS,
    OTHER_ISSUES,
  } = SECTION_TITLES
  const title = HIGHLIGHTS
  const stateKeyPrefix = isClinicianReport ? 'Clinician' : 'Patient'

  const initHighlightState = isClinicianReport
    ? [
        {
          metric: RED_FLAGS,
          subtitle: '',
          value: '',
          trend: '',
          iconVariant: null,
          notes: '',
        },
        {
          metric: OTHER_ISSUES,
          subtitle: '',
          value: '',
          trend: '',
          iconVariant: null,
          notes: '',
        },
      ]
    : []

  const { id: patientId } = useContext(PatientContext)
  const [highlights, setHighlights] = useState(initHighlightState)
  const [displayedHighlights, setDisplayedHighlights] =
    useState(initHighlightState)
  const redFlags = useBoundStore((state) => state.redFlags)
  const highlightsEdits = useBoundStore((state) => state.highlightsEdits)
  const setHighlightsEdits = useBoundStore((state) => state.setHighlightsEdits)
  const resetReportState = useBoundStore((state) => state.resetReportState)

  useEffect(() => {
    setHighlights(initHighlightState)
    setDisplayedHighlights(initHighlightState)
    resetReportState()
  }, [startTime, endTime, prevStartTime, prevEndTime]) // eslint-disable-line react-hooks/exhaustive-deps

  // TREMOR AND DYSKINESIA
  const {
    data: tremorAndDyskinesiaData,
    isLoading: tremorAndDyskinesiaDataIsLoading,
  } = useTremorAndDyskinesiaAggregate(startDate, endDate) || {}
  const {
    data: tremorAndDyskinesiaPrevData,
    isLoading: tremorAndDyskinesiaPrevDataIsLoading,
  } = useTremorAndDyskinesiaAggregate(prevStartDate, prevEndDate) || {}

  const tremorAndDyskinesiaPercentChange = useMemo(() => {
    const currentTremorData = formatAverage(tremorAndDyskinesiaData?.tremor)
    const prevTremorData = formatAverage(tremorAndDyskinesiaPrevData?.tremor)
    const currentDyskinesiaData = formatAverage(
      tremorAndDyskinesiaData?.dyskinesia,
    )
    const prevDyskinesiaData = formatAverage(
      tremorAndDyskinesiaPrevData?.dyskinesia,
    )

    const tremorPercentChange = getAveragePercentChange(
      currentTremorData,
      prevTremorData,
    )

    const dyskinesiaPercentChange = getAveragePercentChange(
      currentDyskinesiaData,
      prevDyskinesiaData,
    )

    return { tremorPercentChange, dyskinesiaPercentChange }
  }, [tremorAndDyskinesiaData, tremorAndDyskinesiaPrevData])

  useEffect(() => {
    if (
      tremorAndDyskinesiaDataIsLoading ||
      tremorAndDyskinesiaPrevDataIsLoading
    )
      return

    const { tremorPercentChange, dyskinesiaPercentChange } =
      tremorAndDyskinesiaPercentChange

    if (
      tremorAndDyskinesiaData?.tremor?.numberOfDaysWithData > 0 &&
      !highlights.find((highlight) => highlight.metric === TREMOR)
    ) {
      const tremorHighlight = getTremorDyskinesiaHighlight({
        currentData: tremorAndDyskinesiaData?.tremor,
        prevData: tremorAndDyskinesiaPrevData?.tremor,
        metricCategory: TREMOR,
        percentChange: tremorPercentChange,
      })
      setHighlights((prevHighlights) => [...prevHighlights, tremorHighlight])
    }

    if (
      tremorAndDyskinesiaData?.dyskinesia?.numberOfDaysWithData > 0 &&
      !highlights.find((highlight) => highlight.metric === DYSKINESIA)
    ) {
      const dyskinesiaHighlight = getTremorDyskinesiaHighlight({
        currentData: tremorAndDyskinesiaData?.dyskinesia,
        prevData: tremorAndDyskinesiaPrevData?.dyskinesia,
        metricCategory: DYSKINESIA,
        percentChange: dyskinesiaPercentChange,
      })
      setHighlights((prevHighlights) => [
        ...prevHighlights,
        dyskinesiaHighlight,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tremorAndDyskinesiaPercentChange,
    tremorAndDyskinesiaDataIsLoading,
    tremorAndDyskinesiaPrevDataIsLoading,
    tremorAndDyskinesiaData?.tremor,
    tremorAndDyskinesiaData?.dyskinesia,
    tremorAndDyskinesiaDataIsLoading,
    tremorAndDyskinesiaPrevDataIsLoading,
  ])

  // ACTIVITY
  const {
    dataEvents: activityData,
    loadingEvents: activityDataIsLoading,
    fetchMore: activityDataFetchMore,
  } = usePatientEvents(patientId, startTime, endTime, {
    namespace: 'patient',
    category: 'activity',
    enum: '*',
  }) || {}

  const {
    dataEvents: activityPrevData,
    loadingEvents: activityPrevDataIsLoading,
    fetchMore: activityPrevDataFetchMore,
  } = usePatientEvents(patientId, prevStartTime, prevEndTime, {
    namespace: 'patient',
    category: 'activity',
    enum: '*',
  }) || {}

  const currentActivityData = useMemo(
    () => activityData?.patient?.eventList?.events || [],
    [activityData],
  )

  const prevActivityData = useMemo(
    () => activityPrevData?.patient?.eventList?.events || [],
    [activityPrevData],
  )

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = activityData?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(activityDataFetchMore, endCursor)
    }
  }, [activityData, activityDataFetchMore])

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = activityPrevData?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(activityPrevDataFetchMore, endCursor)
    }
  }, [activityPrevData, activityPrevDataFetchMore])

  useEffect(() => {
    if (activityDataIsLoading || activityPrevDataIsLoading) return

    if (
      currentActivityData?.length > 0 &&
      !highlights.find((highlight) => highlight.metric === ACTIVITY)
    ) {
      const currentWeeklyActivityDurations = calculateWeeklyActivityDurations(
        currentActivityData,
        startTime,
      )

      const prevWeeklyActivityDurations = calculateWeeklyActivityDurations(
        prevActivityData,
        prevStartTime,
      )

      const currentTotalDurationInMinutes = summedArray(
        currentWeeklyActivityDurations,
      )

      const prevTotalDurationInMinutes = summedArray(
        prevWeeklyActivityDurations,
      )
      const prevAveragePerWeek = getAveragePerWeek(
        prevTotalDurationInMinutes,
        true,
      )
      const currentAveragePerWeek = getAveragePerWeek(
        currentTotalDurationInMinutes,
        true,
      )

      const activityPercentChange = getAveragePercentChange(
        currentAveragePerWeek,
        prevAveragePerWeek,
      )

      const getActivityIconVariant = () => {
        if (!isAtOrBeyondThreshold(activityPercentChange)) {
          return null
        }
        return activityPercentChange >= CHANGE_THRESHOLD_DEFAULT
          ? 'better'
          : 'worse'
      }

      const avgDiff = getAverageDiff(
        currentAveragePerWeek * MINUTE_IN_SECONDS,
        prevAveragePerWeek * MINUTE_IN_SECONDS,
      )

      const trendDiff = formatDiff(avgDiff)

      if (
        currentTotalDurationInMinutes &&
        !highlights.find((highlight) => highlight.metric === ACTIVITY)
      ) {
        const activityChange = getHighlightDefaultText(
          activityPercentChange,
          trendDiff,
          currentAveragePerWeek,
          prevAveragePerWeek,
        )
        const activityHighlight = {
          metric: ACTIVITY,
          subtitle: AVG_PER.week,
          value: getAveragePerWeek(currentTotalDurationInMinutes),
          trend: activityPercentChange,
          iconVariant: getActivityIconVariant(),
          notes:
            activityChange +
            (isClinicianReport
              ? getAveragePerWeek(currentTotalDurationInMinutes, true) >= 150
                ? `. ${ACTIVITY_RECOMMENDATION_MET_TEXT}`
                : `. ${ACTIVITY_RECOMMENDATION_NOT_MET_TEXT}`
              : ''),
        }

        setHighlights((prevHighlights) => [
          ...prevHighlights,
          activityHighlight,
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentActivityData,
    prevActivityData,
    activityDataIsLoading,
    activityPrevDataIsLoading,
  ])

  // MOBILITY
  const {
    data: mobilityData,
    hasData: mobilityHasData,
    isLoading: mobilityDataIsLoading,
  } = useMobilityAggregate(startDate, endDate) || {}
  const { data: mobilityPrevData, isLoading: mobilityPrevDataIsLoading } =
    useMobilityAggregate(prevStartDate, prevEndDate) || {}

  useEffect(() => {
    if (mobilityDataIsLoading || mobilityPrevDataIsLoading) return

    if (
      mobilityHasData &&
      !highlights.find((highlight) => highlight.metric === MOBILITY)
    ) {
      const { stepLength, walkingSpeed, doubleSupportPercentage } =
        mobilityData || {}
      const {
        stepLength: prevStepLength,
        walkingSpeed: prevWalkingSpeed,
        doubleSupportPercentage: prevDoubleSupportPercentage,
      } = mobilityPrevData || {}

      const isLowWalkingSpeed =
        isClinicianReport && walkingSpeed?.averageValue < 1

      const stepLengthHighlight = getMobilityHighlight({
        currentData: stepLength,
        prevData: prevStepLength,
        metricCategory: MOBILITY,
        subtitle: MOBILITY_METRICS.stepLength.title,
        invertColors: false,
      })
      const walkingSpeedHighlight = getMobilityHighlight({
        currentData: walkingSpeed,
        prevData: prevWalkingSpeed,
        metricCategory: MOBILITY,
        subtitle: MOBILITY_METRICS.walkingSpeed.title,
        invertColors: false,
        noteSuffix: isLowWalkingSpeed ? `. ${SLOW_WALKING_SPEED_TEXT}` : '',
      })
      const doubleSupportPercentageHighlight = getMobilityHighlight({
        currentData: doubleSupportPercentage,
        prevData: prevDoubleSupportPercentage,
        metricCategory: MOBILITY,
        subtitle: MOBILITY_METRICS.doubleSupport.title,
        invertColors: true,
      })

      let mobilityHighlightsToDisplay = [
        stepLengthHighlight,
        walkingSpeedHighlight,
        doubleSupportPercentageHighlight,
      ].filter((highlight) => isAtOrBeyondThreshold(highlight.trend))

      if (!mobilityHighlightsToDisplay.length) {
        mobilityHighlightsToDisplay = [
          {
            metric: MOBILITY,
            subtitle: '',
            value: null,
            trend: 0,
            iconVariant: null,
            notes: `No significant change${
              isLowWalkingSpeed ? `. ${SLOW_WALKING_SPEED_TEXT}` : ''
            }`,
          },
        ]
      }

      setHighlights((prevHighlights) => [
        ...prevHighlights,
        ...mobilityHighlightsToDisplay,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobilityDataIsLoading, mobilityPrevDataIsLoading, mobilityHasData])

  useEffect(() => {
    if (
      activityDataIsLoading ||
      activityPrevDataIsLoading ||
      mobilityDataIsLoading ||
      mobilityPrevDataIsLoading ||
      tremorAndDyskinesiaDataIsLoading ||
      tremorAndDyskinesiaPrevDataIsLoading
    ) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activityDataIsLoading,
    activityPrevDataIsLoading,
    mobilityDataIsLoading,
    mobilityPrevDataIsLoading,
    tremorAndDyskinesiaDataIsLoading,
    tremorAndDyskinesiaPrevDataIsLoading,
  ])

  useEffect(() => {
    const sortedHighlights = sortHighlights([...highlights])
    const highlightsToDisplay = sortedHighlights.filter(
      (highlight) => !hiddenSections.has(highlight.metric),
    )
    setDisplayedHighlights(highlightsToDisplay)
  }, [hiddenSections, highlights])

  const HighlightsTable = () => {
    const rows = displayedHighlights.map((data) => {
      const { metric, subtitle, value, trend, iconVariant, notes } = data

      // Red flags and other issues to have a single editable text cell spanning the full table
      if ([RED_FLAGS, OTHER_ISSUES].includes(metric)) {
        return [
          {
            key: 'cell-metric',
            value: (
              <Text variant="body16B">
                {metric} <Text variant="body16">{subtitle}</Text>
              </Text>
            ),
            sx: { width: 230 },
          },
          {
            key: 'cell-notes',
            value: (
              <Text variant="body16">
                <EditableContent
                  stateKey={`${stateKeyPrefix} ${metric}`}
                  stateSrc={highlightsEdits}
                  stateSaveCallback={setHighlightsEdits}
                  dataCy={`editable-content-${metric}`}
                >
                  {metric === RED_FLAGS && isClinicianReport
                    ? redFlags.size
                      ? [...redFlags].join(', ')
                      : NO_RED_FLAGS_TEXT
                    : notes}
                </EditableContent>
              </Text>
            ),
            colSpan: 3,
          },
        ]
      }

      return [
        {
          key: 'cell-metric',
          value: (
            <Text variant="body16B">
              {metric} <Text variant="body16">{subtitle}</Text>
            </Text>
          ),
          sx: { width: 230 },
        },
        {
          key: 'cell-value',
          value: <Text variant="body16B">{value}</Text>,
          sx: { width: 80 },
        },
        {
          key: 'cell-trend',
          value: trend ? (
            <ReportMetricTrendIcon
              direction={trend > 0 ? 'up' : 'down'}
              variant={iconVariant}
            />
          ) : (
            ''
          ),
          sx: { width: 50 },
        },
        {
          key: 'cell-notes',
          value: (
            <Text variant="body16">
              <EditableContent
                stateKey={`${stateKeyPrefix} ${metric} ${subtitle}`}
                stateSrc={highlightsEdits}
                stateSaveCallback={setHighlightsEdits}
                dataCy={`editable-content-${metric} ${subtitle}`}
              >
                {notes}
              </EditableContent>
            </Text>
          ),
        },
      ]
    })

    return <Table rows={rows} tableCellProps={{ size: 'small' }} />
  }

  return (
    <ReportSection
      title={title}
      hidden={
        hiddenSections.has(title) ||
        (!isClinicianReport && !displayedHighlights.length)
      }
      noBorder
    >
      <Stack spacing={2}>
        <>
          <Text variant="body16" component="p">
            <EditableContent
              stateKey={`${stateKeyPrefix} Highlights summary`}
              stateSrc={highlightsEdits}
              stateSaveCallback={setHighlightsEdits}
              dataCy="editable-content-highlights-summary"
            />
          </Text>
          {!isClinicianReport && (
            <Text variant="body16" component="p">
              {EDUCATIONAL_TEXT}
            </Text>
          )}
          <HighlightsTable />
        </>
      </Stack>
    </ReportSection>
  )
}

export default memo(ReportSectionHighlights)
