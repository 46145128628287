import { useFlags } from 'domains/launchdarkly/hooks'
import DailyNew from 'ui/clinicianScreens/Patient/Daily/DailyNew'
import DailyOld from 'ui/clinicianScreens/Patient/Daily/DailyOld'

const Daily = () => {
  const { dailyViewRevampedVisible } = useFlags()

  return dailyViewRevampedVisible ? <DailyNew /> : <DailyOld />
}

export default Daily
