import { useState } from 'react'
import CopyDeviceIdDialog from 'ui/components/CopyDeviceIdDialog'
import DownloadAction from 'ui/components/DownloadAction'
import EventsToggleButton from './Events/EventsToggleButton'
import ShiftButtons from './ShiftButtons'
import { Button, Grid } from '@material-ui/core'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  actionsRow: {
    padding: `${theme.spacing(2)}px ${theme.spacing(6)}px !important`,
    marginTop: '1rem',
  },
  copyGroup: {
    maxHeight: '2.25rem',
    position: 'relative',
    bottom: '1rem',
  },
}))

/**
 * Container component for all stream actions.
 *
 * @param {object} device the device represented in the stream
 * @param {object} downloadOptions options for downloading stream data
 * @param {object} patient the patient whose data is represented
 * @param {boolean} showEvents The current state of Event rendering
 * @param {function} toggleShowEvents The function that toggles Event rendering
 * @param {function} toggleShowSymptoms The function that toggles Symptoms event stream visibility
 * @param {function} toggleShowMedication The function that toggles Medication event stream visibility
 * @param {function} toggleShowFood The function that toggles Food event stream visibility
 * @param {function} toggleShowTasks The function that toggles Tasks event stream visibility
 * @param {function} toggleShowActivity The function that toggles Activity event stream visibility
 * @param {function} toggleShowSleep The function that toggles Sleep event stream visibility
 * @param {boolean} showSymptoms toggle state of Symptoms event stream
 * @param {boolean} showMedication toggle state of Medication event stream
 * @param {boolean} showFood toggle state of Food event stream
 * @param {boolean} showTasks toggle state of Tasks event stream
 * @param {boolean} showActivity toggle state of Activity event stream
 * @param {boolean} showSleep toggle state of Sleep event stream
 * @param {function} updateTimeRange function that updates the time range for all streams
 * @param {int} tStart toggle state of Sleep event stream
 * @param {int} tEnd toggle state of Sleep event stream
 */
const StreamActions = ({
  device,
  downloadOptions,
  patient,
  showEvents,
  toggleShowEvents,
  toggleShowSymptoms,
  toggleShowMedication,
  toggleShowFood,
  toggleShowTasks,
  toggleShowActivity,
  toggleShowSleep,
  showSymptoms,
  showMedication,
  showFood,
  showTasks,
  showActivity,
  showSleep,
  updateTimeRange,
  tStart,
  tEnd,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <Grid classes={{ item: classes.actionsRow }} container item>
      {downloadOptions && (
        <DownloadAction
          device={device}
          options={downloadOptions}
          patient={patient}
        />
      )}
      <EventsToggleButton
        showEvents={showEvents}
        toggleShowEvents={toggleShowEvents}
        toggleShowSymptoms={toggleShowSymptoms}
        toggleShowMedication={toggleShowMedication}
        toggleShowFood={toggleShowFood}
        toggleShowTasks={toggleShowTasks}
        toggleShowActivity={toggleShowActivity}
        toggleShowSleep={toggleShowSleep}
        showSymptoms={showSymptoms}
        showMedication={showMedication}
        showFood={showFood}
        showTasks={showTasks}
        showActivity={showActivity}
        showSleep={showSleep}
        classes={classes}
      />
      <Button
        variant="outlined"
        edge="start"
        color="primary"
        endIcon={<AssignmentReturnedIcon />}
        onClick={() => setOpen(true)}
      >
        Copy Device ID
      </Button>
      <ShiftButtons
        updateTimeRange={updateTimeRange}
        tStart={tStart}
        tEnd={tEnd}
      />
      {open && (
        <CopyDeviceIdDialog
          device={device}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
    </Grid>
  )
}

export default StreamActions
