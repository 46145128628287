import { createContext, useContext } from 'react'
import { get } from 'nested-property'
import { useSelector } from 'react-redux'
import { getSelectedDeviceIds } from '../../query/domains/devices'

export const PatientContext = createContext()
export const PatientProvider = PatientContext.Provider

/**
 * Get an array of device options (name/value pairs) from PatientContext
 * @return {Array} device options
 */
export const useDeviceOptions = () => {
  const patient = useContext(PatientContext)
  const devices = get(patient, 'deviceList.devices') || []
  return devices.map((d) => ({ value: d.id, name: d.alias }))
}

/**
 * Get a list of devices from PatientContext
 * @returns {Array} devices
 */
export const useDevices = () => {
  const patient = useContext(PatientContext)
  return get(patient, 'deviceList.devices') || []
}

/**
 * Get a list of devices from PatientContext plus the Events Device
 * @returns {Array} fullDevices
 */
export const useDevicesAndEvents = () => {
  const devices = useDevices()
  const eventsDevice = {
    alias: 'Events Stream',
    deviceShortId: 'eventsStream',
    deviceType: { id: 'eventsStream', displayName: 'Events' },
    disabled: false,
    id: 'eventsStream',
    identifier: 'Events Stream',
    kind: 'Events',
  }
  const fullDevices = [...devices, eventsDevice]
  return fullDevices
}

/**
 * get a list of clients from PatientContext
 * @returns {Array} clients
 */
export const useClients = () => {
  const { clientList } = useContext(PatientContext)

  if (!clientList) {
    throw new Error(
      'Patient is missing `clientList!` This is probably due to using the wrong Patient query.',
    )
  }
  return clientList.clients || []
}

/**
 * get a list of data sessions from PatientContext
 * @returns {Array} data sessions
 */
export const useDataSessionsList = () => {
  const { dataSessionList } = useContext(PatientContext)

  if (!dataSessionList) {
    throw new Error(
      'Patient is missing `dataSessionList!` This is probably due to using the wrong Patient query.',
    )
  }
  return dataSessionList.dataSessions || []
}

/**
 * Get an array of selected device objects
 * @returns {Array} device objects
 */
export const useSelectedDevices = () => {
  const devices = useDevices()
  const selectedDeviceIds = useSelector(getSelectedDeviceIds) || []
  const emptyDeviceIds = 0
  if (selectedDeviceIds.length === emptyDeviceIds) {
    return devices
  }
  return devices.filter((d) => selectedDeviceIds.includes(d.id))
}

/**
 * Get a device object by device Id, using the PatientContext
 * @param {string} deviceId device id
 * @returns {Object} device
 */
export const useDevice = (deviceId) => {
  const devices = useDevices()
  const device = devices.find((d) => d.id === deviceId)
  return device
}

export default PatientContext
