export const eventType = {
  CUSTOM: 'Custom Event',
  EMOTION: 'emotion',
  MOOD: 'mood',
  ACTIVITY: 'activity',
  NOTE: 'note',
  MEDICATION: 'medication',
  SUPPLEMENT: 'supplement',
  WELLBEING: 'wellbeing',
  DBS: 'dbs',
}

export const duration = {
  DURATION: 'duration',
  ONGOING: 'ongoing',
}
