import { Stack } from 'ui/baseComponents/Stack'
import { colors } from 'theme/colors'
import { Text } from 'ui/baseComponents/Text'

const OverviewChartWithTitle = ({ title, children }) => {
  const STYLES = {
    container: {
      marginLeft: '1.5rem',
    },
    title: {
      width: '50px',
    },
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={STYLES.container}
      data-cy="overview-chart"
    >
      {title && (
        <Text
          variant="body16B"
          component="h2"
          color={colors.PRIMARY[1000]}
          sx={STYLES.title}
        >
          {title}
        </Text>
      )}
      {children}
    </Stack>
  )
}

export default OverviewChartWithTitle
