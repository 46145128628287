import { useMutation } from '@apollo/client'
import { useRefetchMe } from 'ui/screens/Root'
import { UPDATE_USER_MUTATION } from 'domains/carrotGraph/mutations'

const useAnalyticsTracking = () => {
  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: [useRefetchMe()],
  })

  const updateAnalyticsTracking = (selection) => {
    updateUser({
      variables: {
        input: {
          analyticsTracking: selection,
        },
      },
    })
  }

  return {
    updateAnalyticsTracking,
  }
}

export default useAnalyticsTracking
