import { Grid, Typography } from '@material-ui/core'
import { TableSearch } from 'mui-datatables'
import {
  DEFAULT_ROWS_PER_PAGE,
  MAXIMUM_ROWS_PER_PAGE,
  MINIMUM_ROWS_PER_PAGE,
} from '../consts'

const defaultOptions = (title) => ({
  download: false,
  print: false,
  elevation: 0,
  selectableRows: 'none',
  rowsPerPage: DEFAULT_ROWS_PER_PAGE,
  rowsPerPageOptions: [
    MINIMUM_ROWS_PER_PAGE,
    DEFAULT_ROWS_PER_PAGE,
    MAXIMUM_ROWS_PER_PAGE,
  ],
  filterType: 'multiselect',
  textLabels: {
    body: {
      noMatch: 'Sorry, no matching records found for the selected dates.',
    },
  },
  /*
  Custom search rendering function supports a Rune Design Team request to have the search
  input field immediately next to the Open/Close Search icon. The title of the table also
  remains visible.
  */
  customSearchRender: (searchText, handleSearch, hideSearch, options) => (
    <Grid container alignItems="center">
      <Grid item xs={7}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid item xs={5}>
        <TableSearch
          options={options}
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
        />
      </Grid>
    </Grid>
  ),
})

export default defaultOptions
