import { bool, string } from 'prop-types'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({ isAllowed, redirectPath }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet />
}

ProtectedRoute.propTypes = {
  isAllowed: bool.isRequired,
  redirectPath: string.isRequired,
}

ProtectedRoute.defaultProps = {}

export default ProtectedRoute
