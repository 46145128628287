import { Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import LogoIcon from '../LogoIcon/LogoIcon'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  mainMenuItems: {
    flexGrow: 1,
  },
}))

const MainNavSkeleton = () => {
  const classes = useStyles()
  return (
    <Fragment>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
      >
        <LogoIcon />
      </IconButton>
      <Typography variant="h6" className={classes.mainMenuItems}>
        <Box display="flex"></Box>
      </Typography>
      <div>
        <IconButton aria-label="account of current user" color="inherit">
          <AccountCircle />
        </IconButton>
      </div>
    </Fragment>
  )
}

export default MainNavSkeleton
