import { push } from 'redux-first-history'
import {
  forgotPassword as apiForgotPassword,
  confirmPassword as apiConfirmPassword,
  authenticate as apiAuthenticate,
  getJwt,
  getUser,
} from './api'

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED'
export const USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED'
export const LOGGING_IN = 'LOGGING_IN'

export const authenticated = ({ user }) => ({
  type: USER_AUTHENTICATED,
  user,
})

export const unauthenticated = () => ({
  type: USER_UNAUTHENTICATED,
})

export const loggingIn = () => ({ type: LOGGING_IN })

export const storeUser =
  (cognitoUser) =>
  async (dispatch, getState, { Rollbar }) => {
    // eslint-disable-line consistent-return
    dispatch(loggingIn())

    try {
      const jwt = await getJwt(cognitoUser)
      const user = await getUser(cognitoUser)
      dispatch(authenticated({ user }))
      Rollbar.configure({
        payload: {
          person: {
            id: user.username,
            ...user,
          },
        },
      })
      return { user, jwt }
    } catch (error) {
      console.error(error)
      dispatch(unauthenticated())
      dispatch(push('/signin'))
      return Promise.reject(error)
    }
  }

export const getCurrentUser = async (cognitoUser) => {
  try {
    const user = getUser(cognitoUser)
    return user
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const authenticate =
  (username, password) =>
  (dispatch, getState, { userPool }) =>
    apiAuthenticate({ username, password, userPool }).then((cognitoUser) =>
      dispatch(storeUser(cognitoUser)),
    )

export const unauthenticateUser = () => (dispatch) => {
  dispatch(push('/signin'))
  dispatch(unauthenticated())
}

export const logout =
  () =>
  (dispatch, getState, { userPool }) => {
    const user = userPool.getCurrentUser()
    user && user.signOut()
    dispatch(unauthenticateUser())
    window.location.href = '/signin'
  }

export const forgotPassword =
  (username) =>
  (dispatch, getState, { userPool }) =>
    apiForgotPassword({ username, userPool })

export const confirmPassword =
  ({ username, verificationCode, newPassword }) =>
  (dispatch, getState, { userPool }) =>
    apiConfirmPassword({ username, verificationCode, newPassword, userPool })
