import { gql } from '@apollo/client'

export const UPDATE_ORG = gql`
  mutation updateDefaultMembership($input: UpdateDefaultMembershipInput!) {
    updateDefaultMembership(input: $input) {
      user {
        defaultMembership {
          id
          org {
            id
          }
        }
        membershipList {
          memberships {
            id
            org {
              id
            }
          }
        }
      }
    }
  }
`
