import { MetricsRow } from './MetricsRow'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'
import PatientSymptoms from './PatientSymptoms'
import { SECTION_TITLE } from 'ui/clinicianScreens/Patient/consts'

export const OverviewPatientSymptoms = () => {
  const { id: patientId } = useContext(PatientContext)

  return (
    <MetricsRow
      title={SECTION_TITLE.symptoms}
      linkHref={`/patients/${patientId}/log`}
      linkText="View Patient Log"
    >
      <PatientSymptoms />
    </MetricsRow>
  )
}
