import moment from 'moment'
import { HOURS_STRING_PAD, HOUR_IN_MINUTES } from 'utilities/time'

/**
 * Parse a naive datetime string into properly localized MomentJS object.
 *
 * @param {string} strDatetime naive datetime string
 * @returns {moment.Moment} moment time object
 */
export const parseNaiveDatetimeDeprecated = (strDatetime) => {
  let dateTimeString = strDatetime
  if (!dateTimeString.includes(':')) {
    // It's only a date: specify time-of-day explicitly
    dateTimeString += ' 00:00:00'
  }

  const tzOffsetMinutes = -new Date().getTimezoneOffset()
  const offsetSign = tzOffsetMinutes < 0 ? '-' : '+'
  const hours = Math.floor(Math.abs(tzOffsetMinutes) / HOUR_IN_MINUTES)
    .toString()
    .padStart(HOURS_STRING_PAD, '0')

  const minutes = (Math.abs(tzOffsetMinutes) % HOUR_IN_MINUTES)
    .toString()
    .padStart(HOURS_STRING_PAD, '0')
  dateTimeString = `${dateTimeString}${offsetSign}${hours}${minutes}`

  return moment(dateTimeString)
}
