import { useContext } from 'react'
import OrgContext from '../../contexts/OrgContext'
import { GET_USERS } from 'domains/carrotGraph/queries'

export const useRefetchUsers = () => {
  const { id: orgId } = useContext(OrgContext)
  return {
    query: GET_USERS,
    variables: { orgId, withDisabled: true },
  }
}
