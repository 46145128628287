import { Skeleton } from '@mui/material'
import { FullWidthRow } from 'ui/screens/Patients/PatientList/PatientTable'

/**
 * Renders a loading skeleton for the patient table.
 *
 * @returns {JSX.Element} The loading skeleton component.
 */
const PatientTableLoading = () =>
  [60, 70, 50].map((width, index) => (
    <FullWidthRow key={index}>
      <Skeleton data-cy="skeleton" width={`${width}%`} />
    </FullWidthRow>
  ))

export default PatientTableLoading
