import { useContext, useState, useEffect } from 'react'
import EmptyResults from 'ui/components/EmptyResults'
import ErrorBoundary from '../../../../components/ErrorBoundary'
import ErrorFrame from '../../../../components/ErrorFrame'
import { getStreamOptionsForDevice } from '../StreamSelector/StreamItem/streamOptions'
import { NotificationBar, Warning } from '../../../../components/Notifications'
import StreamResult from './StreamResult'
import StreamsContext from '../StreamSelector/StreamsContext'
import { useDevicesAndEvents } from '../../../../contexts/PatientContext'
import { UserPHIContext } from 'ui/contexts'
import { Box, makeStyles } from '@material-ui/core'

const spacingValueOne = 1
const spacingValueFour = 4
const useStyles = makeStyles((theme) => ({
  browseResults: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(spacingValueFour)}px ${theme.spacing(
      spacingValueOne,
    )}px`,
  },
}))

/**
 * @returns {JSX.Element} Patient data streams selected by the user for plotting.
 */
const StreamResults = ({ patient, tStart, tEnd, updateTimeRange }) => {
  const timeoutIdInitialValue = 0
  const sixSeconds = 6000
  const [deviceInactive, setDeviceInactive] = useState(false)
  const [timeoutId, setTimeoutId] = useState(timeoutIdInitialValue)
  const classes = useStyles()
  const devices = useDevicesAndEvents()
  const { streams } = useContext(StreamsContext)
  const newStreams = streams.filter((stream) =>
    devices.some((device) => stream.deviceId === device.id),
  )
  const phiVisibility = useContext(UserPHIContext)

  useEffect(() => {
    if (
      newStreams.length !== streams.length &&
      timeoutId === timeoutIdInitialValue
    ) {
      clearTimeout(timeoutId)
      setDeviceInactive(true)
      setTimeoutId(setTimeout(() => setDeviceInactive(false), sixSeconds))
    }
  }, [timeoutId, streams, newStreams])

  return (
    <Box className={classes.browseResults}>
      {deviceInactive && (
        <NotificationBar open={deviceInactive}>
          <Warning message="One or more devices are currently inactive. To view them, please re-enable." />
        </NotificationBar>
      )}
      {streams && streams.length ? (
        newStreams.map((stream, index) => {
          const { deviceId, streamValue } = stream
          const device = devices.find((device) => device.id === deviceId)
          const streamOptions = getStreamOptionsForDevice(device, phiVisibility)
          // Fallback error frame in case streamOption is undefined
          if (!streamOptions) {
            return <ErrorFrame key={index} error="Error loading stream" />
          } else {
            const streamOption = streamOptions.find(
              (stream) => stream.value === streamValue,
            )
            const { label: streamName, streamType, queryParams } = streamOption
            return (
              <ErrorBoundary
                key={index}
                render={() => <ErrorFrame error="Error loading stream" />}
              >
                <StreamResult
                  patient={patient}
                  tStart={tStart}
                  tEnd={tEnd}
                  updateTimeRange={updateTimeRange}
                  device={device}
                  streamName={streamName}
                  streamType={streamType}
                  queryParams={queryParams}
                />
              </ErrorBoundary>
            )
          }
        })
      ) : (
        <EmptyResults
          icon="device_unknown"
          message="Select a patient device above to explore data"
        />
      )}
    </Box>
  )
}

export default StreamResults
