import mixpanel from 'mixpanel-browser'
import { orgMembershipIdList, orgMembershipNamesList } from './helpers'

/**
 * Identify a user with a unique ID to track user activity across devices, tie a user to their events, and create a user profile.
 *
 * @param {string} id The user's id
 * @returns {void}
 */
export const identifyLoggedInUserForTracking = (id) => {
  mixpanel.identify(id)
}

/**
 * Sets properties on the mixpanel User Profile.
 *
 * @param {object} currentUser Corresponds to the user returned by the GraphQL getMe query
 * @returns {void}
 */
export const setPropertiesOnUserProfile = (currentUser) => {
  mixpanel.people.set({
    $name: currentUser.displayName,
    $email: currentUser.email,
    orgMembershipIds: orgMembershipIdList(currentUser),
    orgMembershipNames: orgMembershipNamesList(currentUser),
    currentOrgId: currentUser.defaultMembership.org.id,
    currentOrgName: currentUser.defaultMembership.org.displayName,
    role: currentUser.defaultMembership.role.displayName,
  })
}
