import { createSelector } from 'reselect'
import { push, replace } from 'redux-first-history'
import qs from 'qs'
import { getNewQuery } from '../utilities'

export const parseQuery = (queryString) =>
  qs.parse(queryString.replace('?', ''))

export const getQuery = (state) => {
  const searchState = state.router.location.search
  return parseQuery(searchState)
}

// Create a selector for a given query key
export const makeQuerySelector = (key, defaultValue) =>
  createSelector(getQuery, (query) =>
    typeof query[key] !== 'undefined'
      ? query[key]
      : typeof defaultValue === 'function'
      ? defaultValue()
      : defaultValue,
  )

export const pushQuery =
  (
    query,
    {
      url,
      replaceHistory = false,
      replaceQuery = false,
      withKeys,
      omitKeys,
    } = {},
  ) =>
  (dispatch, getState) => {
    const existingQuery = getQuery(getState())

    const newQuery = replaceQuery
      ? query
      : {
          ...getNewQuery({ query: existingQuery, withKeys, omitKeys }),
          ...query,
        }

    const { location } = getState().router
    const locationClone = { ...location }

    locationClone.search = qs.stringify(newQuery)

    if (url) {
      locationClone.pathname = url
    }

    const method = replaceHistory ? replace : push

    return dispatch(method(locationClone))
  }
