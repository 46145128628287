import { useContext } from 'react'
import DaySelect from 'ui/components/DaySelect'
import FormFieldWrapper from 'ui/components/FormFieldWrapper'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import TimeList from 'ui/components/TimeList'
import {
  TASK_REPEATS_ON,
  TASK_REPEATS_EVERY,
  TASK_SCHEDULE_RANDOM,
  TASK_SCHEDULE_SPECIFIC,
} from '../../../consts'
import { TaskNotificationContext } from 'ui/contexts'
import { useFlags } from 'domains/launchdarkly/hooks'
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  miniLabel: theme.taskNotificationsMiniLabel,
  dayNumberInput: {
    width: 40,
  },
  daysLabel: {
    position: 'relative',
    top: theme.spacing(0.5),
  },
  selectToggle: {
    width: 80,
    marginRight: theme.spacing(2),
  },
}))

/**
 * The Task Frequency section of the Create Task form.
 * @returns {JSX.Element} Component for task frequency that handles conditionally diverging fields
 */
export const TaskFrequency = () => {
  const classes = useStyles()
  const { taskNotificationsRandomOptionVisibleFlag } = useFlags()
  const {
    taskRepeatsWeekly,
    taskRepeatDaysArray,
    taskRepeatDaysInteger,
    onTaskRepeatsWeeklyChange,
    onTaskRepeatDaysArrayChange,
    onTaskRepeatDaysIntegerChange,
    taskScheduleRandom,
    taskScheduleTimesArray,
    onTaskScheduleRandomChange,
    onTaskScheduleTimesArrayChange,
  } = useContext(TaskNotificationContext)

  return (
    <ErrorBoundary>
      <FormFieldWrapper label="Task Frequency">
        <Box mb={1}>
          <FormControl variant="standard">
            <Select
              id="taskRepeatsWeekly"
              name="Task Repeats Weekly"
              data-testid="taskRepeatsWeekly"
              label="Ends"
              value={taskRepeatsWeekly}
              onChange={onTaskRepeatsWeeklyChange}
              className={classes.selectToggle}
              inputProps={{
                'aria-label': 'Task Repeats Weekly Input',
                'data-testid': 'taskRepeatsWeeklyInput',
              }}
            >
              <MenuItem key={TASK_REPEATS_ON} value={TASK_REPEATS_ON}>
                On
              </MenuItem>
              <MenuItem key={TASK_REPEATS_EVERY} value={TASK_REPEATS_EVERY}>
                Every
              </MenuItem>
            </Select>
          </FormControl>

          {taskRepeatsWeekly === 'true' ? (
            <Box mt={2}>
              <FormControl component="fieldset" fullWidth>
                <DaySelect
                  selectedDayArray={taskRepeatDaysArray}
                  setDay={onTaskRepeatDaysArrayChange}
                />
              </FormControl>
            </Box>
          ) : (
            <FormControl component="fieldset">
              <Box>
                <TextField
                  id="taskRepeatDaysInteger"
                  name="Task Repeat Days Integer"
                  data-testid="taskRepeatDaysInteger"
                  type="number"
                  value={taskRepeatDaysInteger}
                  onChange={onTaskRepeatDaysIntegerChange}
                  className={classes.dayNumberInput}
                  inputProps={{
                    'aria-label': 'Task Repeat Days Integer Input',
                    'data-testid': 'taskRepeatDaysIntegerInput',
                    min: 1,
                  }}
                />
                <Typography component="span" className={classes.daysLabel}>
                  {' '}
                  days
                </Typography>
              </Box>
            </FormControl>
          )}
        </Box>
        <Box>
          {taskNotificationsRandomOptionVisibleFlag && (
            <FormControl component="fieldset">
              <FormLabel component="legend" className={classes.miniLabel}>
                Schedule Type
              </FormLabel>
              <RadioGroup
                row
                aria-label="scheduleType"
                name="scheduleType"
                value={taskScheduleRandom}
                onChange={onTaskScheduleRandomChange}
              >
                <FormControlLabel
                  value={TASK_SCHEDULE_SPECIFIC}
                  control={<Radio />}
                  label="Specific Time"
                />
                <FormControlLabel
                  value={TASK_SCHEDULE_RANDOM}
                  control={<Radio />}
                  label="Random"
                />
              </RadioGroup>
            </FormControl>
          )}

          {(taskScheduleRandom === TASK_SCHEDULE_SPECIFIC ||
            !taskNotificationsRandomOptionVisibleFlag) && (
            <TimeList
              selectedTimeArray={taskScheduleTimesArray}
              setTime={onTaskScheduleTimesArrayChange}
            />
          )}
        </Box>
      </FormFieldWrapper>
    </ErrorBoundary>
  )
}

export default TaskFrequency
