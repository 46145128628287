import moment from 'moment'
import qs from 'qs'
import { CUSTOM_TEXT } from 'ui/clinicianScreens/Patient/consts'

export const getEventName = (event) =>
  event?.customDetail?.displayName || event?.displayName

export const stringifyQueryParameters = (startTime, endTime) =>
  qs.stringify(
    {
      startTime,
      endTime,
    },
    {
      // Sorting query parameter keys in descending order
      // Docs: https://github.com/ljharb/qs
      sort: (a, b) => -a.localeCompare(b),
    },
  )

export const getFormattedDaysOfWeek = (schedule) => {
  const hasWeeklySchedule = schedule.length > 0
  if (schedule.length === 7) {
    return ['Daily']
  }
  return hasWeeklySchedule
    ? schedule.map((dayNumber) => moment().day(dayNumber).format('ddd'))
    : []
}

export const createUniqueId = () => Date.now().toString()

const getScheduledMedNames = (schedules) => {
  const scheduledAsNeededMedNames = new Set()
  const allActiveScheduledMedNames = new Set()

  // The nesting in graphql needs flattening and sorting into medication arrays
  schedules.forEach((item) => {
    const { scheduleType } = item
    if (item?.active) {
      const medicationName = getEventName(item)
      allActiveScheduledMedNames.add(medicationName)
      if (scheduleType === 'ON_DEMAND') {
        scheduledAsNeededMedNames.add(medicationName)
      }
    }
  })

  return {
    scheduledAsNeededMedNames,
    allActiveScheduledMedNames,
  }
}

export const getLoggedAsNeededAndActiveScheduledMeds = (
  schedules,
  eventList,
  prevEventList,
) => {
  const { scheduledAsNeededMedNames, allActiveScheduledMedNames } =
    getScheduledMedNames(schedules)

  let loggedAsNeededMeds
  let prevLoggedAsNeededMeds

  // A person can log medications that are not scheduled. These medications are also considered as needed.
  if (eventList) {
    loggedAsNeededMeds = eventList.filter((event) => {
      const medicationName = getEventName(event)

      /*  For users with access to PHI, the medicationName for custom medications evaluates to the name entered by the patient (e.g. "Tylenol").
          However, for users without access to PHI, it evaluates to "Custom Event".
          To avoid confusion for users without access to PHI, we exclude "Custom Event" from the list of logged as needed medications.
          Note that medicationScheduleList (returned by the getPatient query) refers to these medications as "Custom medication" instead of "Custom Event".
      */
      if (medicationName === CUSTOM_TEXT.eventName) return false

      return (
        scheduledAsNeededMedNames.has(medicationName) ||
        !allActiveScheduledMedNames.has(medicationName)
      )
    })
  }

  if (prevEventList) {
    prevLoggedAsNeededMeds = prevEventList.filter((event) => {
      const medicationName = getEventName(event)

      if (medicationName === CUSTOM_TEXT.eventName) return false

      return (
        scheduledAsNeededMedNames.has(medicationName) ||
        !allActiveScheduledMedNames.has(medicationName)
      )
    })
  }

  return {
    loggedAsNeededMeds,
    prevLoggedAsNeededMeds,
    allActiveScheduledMedNames,
  }
}
