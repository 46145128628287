import { createContext, useCallback, useState } from 'react'

/**
 * Persist state of various minor parts of the UI.
 * For example, whether or not a certain Accordion is currently expanded or collapsed.
 *
 * State information is stored as an object, e.g. key-value pairs.
 */
export const UiStateContext = createContext()

const DEFAULT_STATE = {}

const useUiStateContext = () => {
  const [uiState, setUiState] = useState(DEFAULT_STATE)

  return {
    uiState,
    setUiState: useCallback(
      (key, value) =>
        setUiState((uiState) => ({
          ...uiState,
          [key]: value,
        })),
      [],
    ),
  }
}

export default useUiStateContext
