import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Card as MaterialCard,
  CardActions,
  CardContent,
  CardHeader,
  LinearProgress,
  makeStyles,
} from '@material-ui/core'
// import { Alert } from '@material-ui/lab'
// NOTE: material 5 alpha component, left in as comment for traceback

const SUBTITLE_HEIGHT = '0.8rem'

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
  },
  hasHeader: {
    paddingTop: 0,
  },
  actions: {
    justifyContent: 'flex-end',
  },
  subtitle: {
    fontWeight: 'bold',
    color: '#333333',
    fontSize: SUBTITLE_HEIGHT,
    verticalAlign: 'middle',
    lineHeight: SUBTITLE_HEIGHT,
  },
  title: {
    paddingBottom: ({ subtitle }) => (!subtitle ? SUBTITLE_HEIGHT : '0rem'), // Add padding bottom when no subtitle to align cards next to each other.
  },
}))

/**
 * @returns {JSX.Element} A card that uses the Material UI card.
 */
const Card = ({
  actions,
  children,
  error,
  headerAction,
  loading,
  subtitle,
  title,
  ...props
}) => {
  const classes = useStyles({ subtitle })

  return (
    <MaterialCard {...props}>
      {loading && <LinearProgress />}
      {error && (
        <div severity={error.severity || 'error'}>{error.message || error}</div>
      )}
      {title && (
        <CardHeader
          subheaderTypographyProps={{ className: classes.subtitle }}
          titleTypographyProps={{ className: classes.title }}
          action={headerAction}
          title={title}
          subheader={subtitle}
        />
      )}
      <CardContent
        className={clsx(classes.content, {
          [classes.hasHeader]: Boolean(title),
        })}
      >
        {children}
      </CardContent>
      <CardActions className={classes.actions}>{actions}</CardActions>
    </MaterialCard>
  )
}

Card.propTypes = {
  /**
   * Card actions to be listed in footer
   */
  actions: PropTypes.arrayOf(PropTypes.element),
  /**
   * card children
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  /**
   * an error to be shown on card
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ message: PropTypes.string }),
  ]),
  /**
   * an action to show in the header, if any
   */
  headerAction: PropTypes.shape({}),
  /**
   * is something on this card loading?
   */
  loading: PropTypes.bool,
  /**
   * card subtitle
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  /**
   * card title
   */
  title: PropTypes.string,
}

Card.defaultProps = {
  actions: [],
  error: null,
  headerAction: null,
  loading: false,
  subtitle: null,
  title: null,
}

export default Card
