import { string } from 'prop-types'
import { Toolbar, Typography, Link } from '@mui/material'
import { colors, RUNE_GRAY_800 } from 'theme/colors'
import BetaTag from './BetaTag'

const BetaBanner = ({ featureName, tutorialLink }) => {
  const tutorialLinkComponent = (
    <Link
      color={RUNE_GRAY_800}
      sx={{
        fontWeight: 700,
      }}
      href={tutorialLink}
      target="_blank"
      rel="noreferrer"
    >
      watch our tutorial
    </Link>
  )

  return (
    <Toolbar
      variant="dense"
      sx={{
        backgroundColor: colors.PRIMARY[200],
        justifyContent: 'center',
      }}
    >
      <BetaTag />
      <Typography variant="body2">
        {featureName}: This early release is made available for testing. For an
        introduction, {tutorialLinkComponent}.
      </Typography>
    </Toolbar>
  )
}

BetaBanner.propTypes = {
  featureName: string.isRequired,
  tutorialLink: string,
}

BetaBanner.defaultProps = {}

export default BetaBanner
