import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { getAutomatedUserJwt } from 'utilities/automatedUser'

import App from 'app/App'

import './index.css'

/**
 * Asynchronous entrypoint.
 *
 * React is only initialized once feature flag provider is connected.
 */
;(async () => {
  const { vendors = {} } = window.Rune.Carrot.config
  const automatedUserJwt = getAutomatedUserJwt()

  const LDProvider = await asyncWithLDProvider({
    clientSideID: vendors.launchdarkly && vendors.launchdarkly.clientSideId,
    ...(automatedUserJwt
      ? { context: { kind: 'user', key: 'automateduser' } }
      : {}),
  })

  const rootNode = document.getElementById('root')

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    rootNode,
  )
})()
