export const LABELS = {
  max: 'Max',
  min: 'Min',
}

export const SCREEN_READER_NO_DATA_TEXT =
  'No data is available for this metric.'

export const TREND = {
  down: 'DOWN',
  up: 'UP',
  none: 'NO_DIRECTION',
}
