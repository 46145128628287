import { memo } from 'react'
import { colors } from 'theme/colors'
import { Stack, Text } from 'ui/baseComponents'

const ReportSection = ({
  title,
  subtitle,
  children,
  noBorder,
  styles,
  hidden,
  hideWhenPrinting,
  contentRight,
  forcePageBreak,
  hideTitle,
  ...props
}) => {
  const STYLES = {
    wrapper: {
      display: hidden ? 'none' : '',
    },
    section: {
      borderTop: noBorder ? '' : `1px solid ${colors.COOL[400]}`,
      paddingTop: noBorder ? '' : '1rem',
      ...styles,

      '@media print': {
        breakInside: 'avoid',
        breakAfter: forcePageBreak ? 'always' : 'auto',
        breakBefore: forcePageBreak ? 'always' : 'auto',
        display: hideWhenPrinting ? 'none' : '',
      },
    },
    subtitle: {
      '&:not(:first-child)': {
        marginTop: '0.2rem',
      },
    },
  }

  // Div wrapper is neededed here to prevent text overlap in print view
  return (
    <div style={STYLES.wrapper} {...props} data-cy={`section-${title}`}>
      <Stack spacing={3} sx={STYLES.section}>
        {(title || subtitle) && !hideTitle && (
          <div>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {title && (
                <Text
                  variant="head20B"
                  component="h2"
                  color={colors.PRIMARY[1000]}
                >
                  {title}
                </Text>
              )}
              {contentRight && <div>{contentRight}</div>}
            </Stack>

            {subtitle && (
              <Text variant="caps12" component="h3" sx={STYLES.subtitle}>
                {subtitle}
              </Text>
            )}
          </div>
        )}
        {children}
      </Stack>
    </div>
  )
}

export default memo(ReportSection)
