import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import localStorage from 'local-storage'
import { PatientContext } from 'ui/contexts'
import { TIME_BASIS_ABSOLUTE } from './consts'

export const DEFAULT_TIME_BASIS = {
  type: TIME_BASIS_ABSOLUTE,
}

export const TimeBasisContext = createContext()

// TODO should include user in this in the future
const fetchTimeBasis = (patientId) =>
  localStorage(patientId) || DEFAULT_TIME_BASIS
const storeTimeBasis = (patientId, timeBasis) =>
  localStorage(patientId, timeBasis)

export const TimeBasisProvider = ({ children }) => {
  const { id: patientId } = useContext(PatientContext)
  const [timeBasis, setTimeBasisState] = useState(fetchTimeBasis(patientId))
  // When patientId change, reset time basis value
  useEffect(() => {
    setTimeBasisState(fetchTimeBasis(patientId))
  }, [patientId])

  const setTimeBasis = useCallback(
    (timeBasis) => {
      storeTimeBasis(patientId, timeBasis)
      setTimeBasisState(timeBasis)
    },
    [patientId],
  )

  return (
    <TimeBasisContext.Provider value={{ timeBasis, setTimeBasis }}>
      {children}
    </TimeBasisContext.Provider>
  )
}
