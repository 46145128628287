import { Stack, Text } from 'ui/baseComponents'
import ReportSection from '../../components/ReportSection'
import { SECTION_TITLES } from '../../consts'

const DINMarketing = () => {
  const title = SECTION_TITLES.ABOUT_DIN

  return (
    <ReportSection title={title}>
      <Stack>
        <Text variant="body16" component="p">
          StrivePD is committed to providing you with the information and
          resources to help you better understand dyskinesia and the medications
          that can help reduce it. Our goal is to support you in achieving
          better ON time.
        </Text>
        <Text variant="body16" component="p">
          We understand that dyskinesia can significantly impact your quality of
          life and is often a noticeable symptom to others. Below, you’ll find
          your Dyskinesia Insights, which you can review with your clinicians to
          learn more about dyskinesia and explore the best treatment options for
          you.
        </Text>
      </Stack>
    </ReportSection>
  )
}

export default DINMarketing
