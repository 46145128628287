import React from 'react'
import Accel from '../Streams/Accel'
import Events from '../Streams/StreamVisualization/Events'
import deepEqual from 'deep-equal'
import { getTargetCardinality } from '../../../../../domains/streamApi/api'
import HeartRate from '../Streams/HeartRate'
import ProbSymptom from '../Streams/ProbSymptom'
import ProbSymptomSeverity from '../Streams/ProbSymptomSeverity'
import Rotation from '../Streams/Rotation'
import {
  STREAM_TYPE_ACCELERATION,
  STREAM_TYPE_EVENTS,
  STREAM_TYPE_HEART_RATE,
  STREAM_TYPE_PROB_SYMPTOM,
  STREAM_TYPE_PROB_SYMPTOM_SEVERITY,
  STREAM_TYPE_ROTATION,
} from '../StreamSelector/StreamTypes'
import { getCurrentBrowserTimezoneName } from 'utilities/time'

/**
 * Compare current (outstanding request) query params with those of the
 * previous request, decide whether the changes warrant displaying the
 * loading indicator or if we can just update on the fly.
 * In this case, were the changes temporal or more than just temporal?
 * @param {any} currentParams current params
 * @param {any} previousParams previous params
 * @returns {boolean} whether to show loading indicator
 */
export const isShowLoading = (currentParams, previousParams) => {
  if (!previousParams || !currentParams) return true
  const {
    start_time: currentTStart,
    end_time: currentTEnd,
    frequency: currentFrequency,
    ...restCurrent
  } = currentParams
  const {
    start_time: previousTStart,
    end_time: previousTEnd,
    frequency: previousFrequency,
    ...restPrevious
  } = previousParams
  // start and end time can differ without showing loading. But if any other
  // params have changed, show a loading indicator
  return !deepEqual(restCurrent, restPrevious)
}

/**
 * Visualization for a single result stream
 * @returns {JSX.Element} stream result visualization
 */
const StreamResult = ({
  device,
  patient,
  streamName,
  streamType,
  queryParams,
  tStart,
  tEnd,
  updateTimeRange,
}) => {
  const duration = tEnd - tStart

  const query = {
    start_time: tStart,
    end_time: tEnd,
    frequency: getTargetCardinality(duration) / duration,
    ...queryParams,
  }

  const timezoneName = getCurrentBrowserTimezoneName()
  const queryWithTimeZone = {
    ...query,
    timezone_name: timezoneName,
    timestamp: 'iso',
  }

  const props = {
    device,
    patient,
    title: streamName,
    query: queryWithTimeZone,
    tStart,
    tEnd,
    updateTimeRange,
  }
  switch (streamType) {
    case STREAM_TYPE_ACCELERATION:
      return <Accel {...props} />

    case STREAM_TYPE_EVENTS:
      return (
        <Events
          showSymptoms={true}
          showMedication={true}
          showFood={true}
          showTasks={true}
          showActivity={true}
          showSleep={true}
          standaloneStream={true}
          {...props}
        />
      )

    case STREAM_TYPE_HEART_RATE:
      return <HeartRate {...props} />

    case STREAM_TYPE_ROTATION:
      return <Rotation {...props} />

    case STREAM_TYPE_PROB_SYMPTOM:
      return <ProbSymptom {...props} />

    case STREAM_TYPE_PROB_SYMPTOM_SEVERITY:
      return <ProbSymptomSeverity {...props} />

    default:
      return null
  }
}

export default StreamResult
