import NavLink from 'ui/components/NavLink/index'
import TableCell from '@mui/material/TableCell'
import { clinicianRoutes } from 'ui/clinicianScreens/Patient/Tabs/consts'
import { STYLES } from 'ui/screens/Patients/PatientList/PatientTable/consts'

/**
 * Renders a table cell with a link to explore time-based patient data.
 *
 * @param {Object} props - The component props.
 * @param {string} props.patientId - The ID of the patient.
 * @returns {JSX.Element} The rendered TableCellLinkToExplore component.
 */
const TableCellLinkToExplore = ({ patientId }) => (
  <TableCell align="center" sx={STYLES.iconCell}>
    <NavLink
      to={`/patients/${patientId}/${clinicianRoutes.EXPLORE}`}
      title="Browse time-based patient data"
      data-cy="patient-row-explore-link"
    >
      <span>Explore</span>
    </NavLink>
  </TableCell>
)

export default TableCellLinkToExplore
