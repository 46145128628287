export const STREAM_TYPE_HEART_RATE = 'heartrate'
export const STREAM_HEART_RATE = {
  value: 'heartrate',
  endpoint: 'heartrate',
  csvSupported: true,
  label: 'Heart Rate',
  streamType: STREAM_TYPE_HEART_RATE,
  queryParams: {
    expression: 'bpm',
  },
}
