import { colors } from 'theme/colors'
import ReportBarChart from '../../../components/ReportBarChart'

const ReportChartTremor = ({ currentData, prevData, diff }) => (
  <ReportBarChart
    {...{
      title: 'Tremor',
      currentData,
      prevData,
      diff,
      recentPeriodColor: colors.PRIMARY[1000],
      previousPeriodColor: colors.PRIMARY[700],
    }}
  />
)

export default ReportChartTremor
