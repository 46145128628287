import React from 'react'
import darkLogo from 'assets/img/rune_light.svg'
import { EmptyPage } from 'ui/components/EmptyPage'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  box: {
    fontSize: 'medium',
    display: 'block',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.default.main,
    textAlign: 'center',
    width: '100%',
  },
  message: {
    margin: '40px',
  },
  imageIcon: {
    height: '40px',
    marginLeft: theme.imageIcon.marginLeft,
    marginTop: theme.imageIcon.marginTop,
  },
}))

export const AccessDeniedNotification = () => {
  const classes = useStyles()
  return (
    <EmptyPage
      style={{ backgroundColor: '#ffffff', height: '100vh', width: '100%' }}
    >
      <div className={classes.box}>
        <div className={classes.message}>
          You do not have access to this page. Please contact{' '}
          <a href="mailto:support@runelabs.io">support@runelabs.io</a>.
        </div>
        <img className={classes.imageIcon} src={darkLogo} alt="Rune logo" />
      </div>
    </EmptyPage>
  )
}
