import { eventAccentColor } from 'theme/colors'
import { eventType } from './consts'
import { formatActivityEventText } from './formatActivityEventText'
import { formatMedicationEventText } from './formatMedicationEventText'
import { formatDBSEventText } from 'ui/clinicianScreens/Patient/EventFormatting/formatDBSEventText'

/**
 * Formats the user-facing text for patient-logged events and determines accent color.
 *
 * @param {object} event The event object needing formatting
 * @returns {Object} A named tuple containing event display information
 * @returns {string} eventColor: The event's associated accent color
 * @returns {string} eventText: The event's simple text representation
 */
export const formatEventTextAndColor = ({ event, phiVisibility }) => {
  let eventText, eventColor
  const eventCategory = event?.classification?.category

  if (typeof event === 'undefined') {
    return {
      eventColor: eventAccentColor.RED,
    }
  }

  if (event.displayName === eventType.CUSTOM && phiVisibility) {
    if (
      eventCategory === eventType.MEDICATION ||
      eventCategory === eventType.SUPPLEMENT
    ) {
      eventText = `Patient comment: ${formatMedicationEventText({
        displayName: event.customDetail?.displayName,
        payload: event.payload,
      })}`
    } else {
      eventText = `Patient comment: ${event.customDetail?.displayName}`
    }

    return {
      eventText,
      eventColor: eventAccentColor.BLUE,
    }
  }

  switch (eventCategory) {
    case eventType.EMOTION:
    case eventType.MOOD:
    case eventType.WELLBEING:
      eventText = `${event.displayName}`
      eventColor = eventAccentColor.ORANGE
      break
    case eventType.ACTIVITY:
      eventText = formatActivityEventText(event)
      eventColor = eventAccentColor.RED
      break
    case eventType.NOTE:
      const { note_body: noteBody } = JSON.parse(event.payload || '{}') // eslint-disable-line no-case-declarations
      eventText = noteBody
      eventColor = eventAccentColor.GRAY
      break
    case eventType.MEDICATION:
    case eventType.SUPPLEMENT:
      eventText = formatMedicationEventText(event)
      eventColor = eventAccentColor.TEAL
      break
    case eventType.DBS:
      eventText = formatDBSEventText(event)
      eventColor = eventAccentColor.BLUE
      break
    default:
      eventText = event.displayName
      eventColor = eventAccentColor.PURPLE
      break
  }
  return { eventText, eventColor }
}
