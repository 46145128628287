import { createContext, useState } from 'react'

export const DragModeContext = createContext()

export const DragModeProvider = ({ children }) => {
  const [dragmode, setDragmode] = useState()
  return (
    <DragModeContext.Provider value={{ dragmode, setDragmode }}>
      {children}
    </DragModeContext.Provider>
  )
}

export default DragModeContext
