import { memo } from 'react'
import { colors } from 'theme/colors'
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts'
import { Box } from '@mui/material'
import { DEBOUNCE_DELAY_DEFAULT } from './consts'

const BarChartStackedHorizontal = ({
  data,
  barColors,
  height = 20,
  total,
  isAnimationActive,
}) => {
  const STYLES = {
    chartContainer: {
      width: '100%',
      height,
    },
  }

  const barData = data.map((item) => {
    const { name, ...rest } = item
    return rest
  })

  return (
    <Box sx={STYLES.chartContainer}>
      <ResponsiveContainer
        debounce={DEBOUNCE_DELAY_DEFAULT}
        width="100%"
        height={height}
      >
        <RechartsBarChart
          {...{
            width: 500,
            data,
            margin: {
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            },
            layout: 'vertical',
          }}
        >
          <XAxis type="number" domain={[0, total]} hide />
          <YAxis dataKey="name" type="category" hide />
          {barData?.map((item) =>
            Object.keys(item).map((key, index) => (
              <Bar
                {...{
                  key,
                  dataKey: key,
                  stackId: 'a',
                  fill: barColors[index] || colors.COOL[500],
                  barSize: 12,
                  radius: [10, 10, 10, 10],
                  isAnimationActive,
                }}
              />
            )),
          )}
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default memo(BarChartStackedHorizontal)
