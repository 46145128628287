const searchPatientsSlice = (set) => ({
  moreSearchResultsAvailable: false,
  setMoreSearchResultsAvailable: (available) =>
    set(() => ({
      moreSearchResultsAvailable: available,
    })),
  resetSearchPatients: () =>
    set(() => ({
      searchPatientsError: null,
      searchPatientsLoading: null,
      searchPatientsResults: [],
      searchPatientsInputFields: {
        codeName: '',
        email: '',
        firstName: '',
        lastName: '',
      },
    })),
  searchPatientsError: null,
  searchPatientsExecuted: false,
  setSearchPatientsExecuted: (executed) =>
    set(() => ({
      searchPatientsExecuted: executed,
    })),
  searchPatientsLoading: null,
  searchPatientsResults: [],
  searchPatientsInputFields: {
    codeName: '',
    email: '',
    firstName: '',
    lastName: '',
  },
  setSearchPatientsInputFields: (input, field) =>
    set((state) => ({
      searchPatientsInputFields: {
        ...state.searchPatientsInputFields,
        [field]: input,
      },
    })),
})

export default searchPatientsSlice
