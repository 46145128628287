import React from 'react'
import EditDeviceForm from './EditDeviceForm'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import NavLink from 'ui/components/NavLink'
import { Box, IconButton, makeStyles } from '@material-ui/core'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { useDevice } from 'ui/contexts/PatientContext'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))

const EditDevice = () => {
  const { patientId, deviceId } = useParams()
  const classes = useStyles()
  const device = useDevice(deviceId)

  return (
    <Box className={classes.root}>
      <NavLink
        to={`/patients/${patientId}/manage/devices`}
        title="Manage Devices"
        data-cy="manage-devices-link"
      >
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      </NavLink>

      <ErrorBoundary>
        <EditDeviceForm {...device} />
      </ErrorBoundary>
    </Box>
  )
}

export default EditDevice
