import React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dateMask: {
    fontSize: '1rem',
    flexBasis: 0,
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: '500',
    padding: '8px 0',
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}))

/**
 * Visualization of a patient's data availability bitmap for a single time period.
 *
 * @param {integer} index for use as a key
 * @param {date} date date to navigate to on click
 * @param {function} onSelect function that navigates to browse view for the date when clicked
 */
const MonthDateMask = ({ index, date, onSelect }) => {
  const classes = useStyles()
  return (
    <div
      className={classes.dateMask}
      key={index}
      onClick={() =>
        onSelect(moment(date).startOf('day'), moment(date).endOf('day'))
      }
      data-cy={date}
    >
      {index + 1}
    </div>
  )
}

export default MonthDateMask
