import { colors } from 'theme/colors'
import { Text } from 'ui/baseComponents/Text'

export const OverviewEmptyChart = ({ title }) => {
  const STYLES = {
    container: {
      marginLeft: '1.5rem',
      marginBottom: '1rem',
    },
  }
  return (
    <div style={STYLES.container}>
      {title ? (
        <Text variant="body16" component="span" color={colors.GREY[700]}>
          No
          <Text variant="body16B" component="span" color={colors.GREY[700]}>
            {` ${title} `}
          </Text>
          data is available within the selected period
        </Text>
      ) : (
        <Text variant="body16B" component="span" color={colors.GREY[700]}>
          No data is available within the selected period
        </Text>
      )}
    </div>
  )
}
