import React from 'react'
import { Grid } from '@material-ui/core'

import { element, string } from 'prop-types'

const DataTableColumnLabelWithIcon = ({ children, icon }) => (
  <Grid container alignItems="center" wrap="nowrap">
    <Grid item>{icon}</Grid>
    <Grid item>{children}</Grid>
  </Grid>
)

DataTableColumnLabelWithIcon.propTypes = {
  children: string.isRequired,
  icon: element.isRequired,
}

DataTableColumnLabelWithIcon.defaultProps = {}

export default DataTableColumnLabelWithIcon
