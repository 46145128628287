import { gql } from '@apollo/client'

export const CREATE_VIEW = gql`
  mutation createPersistentView($input: CreatePersistentViewInput!) {
    createPersistentView(input: $input) {
      view {
        id
        createdAt
        latest {
          id
          data
          dataSchemaVersion
          displayName
          version
        }
        disabled
        visibility
      }
    }
  }
`

export const UPDATE_VIEW = gql`
  mutation createPersistentViewVersion(
    $input: CreatePersistentViewVersionInput!
  ) {
    createPersistentViewVersion(input: $input) {
      version {
        id
        displayName
        dataSchemaVersion
        data
        version
      }
    }
  }
`
