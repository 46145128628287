import {
  fmtMomentLocal,
  SECOND_IN_MILLISECONDS,
  DAY_IN_SECONDS,
  MINUTE_IN_SECONDS,
} from 'utilities/time'
import moment from 'moment'

const INTERVALS_IN_DAY = DAY_IN_SECONDS / (MINUTE_IN_SECONDS * 5)
const MILLISECONDS_IN_DAY = DAY_IN_SECONDS * SECOND_IN_MILLISECONDS
const MILLISECONDS_IN_FIVE_MINUTES =
  MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS * 5

/**
 * Function that takes event stream data (both points and spans), creates a
 * template stream object that has 5-minute intervals (making it compatible
 * with the existing availability viz), and then finds the closest surrounds
 * times to the event time and marks them as having data.
 *
 * @param {object} requestParams Parameters common and identical to all stream requests
 * @param {object} eventData result from point events data stream
 * @param {object} spanData result from span events data stream
 * @param {object} taskData result from dataSessions GQL request
 * @param {boolean} monthlyView toggle to map events based on the monthly availability view
 */
export const mapEvents = (
  requestParams,
  eventData,
  spanData,
  taskData,
  monthlyView,
) => {
  const startInMilliseconds = requestParams.start_time * SECOND_IN_MILLISECONDS
  const endInMilliseconds = requestParams.end_time * SECOND_IN_MILLISECONDS
  let mappedObject = { events: [], time: [], formattedTime: [] }

  // we need a data point every 5 minutes for daily view (300000 milliseconds) and once a day for monthly view (86400000 milliseconds)
  const interval = monthlyView
    ? MILLISECONDS_IN_DAY
    : MILLISECONDS_IN_FIVE_MINUTES
  const timeFormat = monthlyView ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss.SSS'

  for (let i = startInMilliseconds; i < endInMilliseconds; i += interval) {
    mappedObject.events.push(0)
    mappedObject.time.push(i)
    mappedObject.formattedTime.push(fmtMomentLocal(timeFormat, i))
  }

  if (eventData && eventData.event) {
    eventData.event.forEach((eventObj) => {
      switch (eventObj.event_type) {
        case 'symptom':
        case 'symptoms':
        case 'side-effect':
        case 'medication':
        case 'food-drink':
        case 'activity':
        case 'sleep':
          // grab the times surrounding the point event and mark both as having data (we need to render them as at least 5 mins for them to show)
          const firstApproximateTimeIndex = mappedObject.time.findIndex(
            (timestamp) => timestamp > eventObj.time * SECOND_IN_MILLISECONDS,
          )
          const prevApproximateTimeIndex = firstApproximateTimeIndex - 1

          if (!monthlyView) {
            mappedObject.events[firstApproximateTimeIndex] = 1
          }

          if (prevApproximateTimeIndex >= 0) {
            mappedObject.events[prevApproximateTimeIndex] = 1
          }
          break
        default:
          break
      }
    })
  }

  if (spanData && spanData.span) {
    spanData.span.forEach((eventObj) => {
      switch (eventObj.event_type) {
        case 'symptom':
        case 'symptoms':
        case 'side-effect':
        case 'medication':
        case 'food-drink':
        case 'activity':
        case 'sleep':
          // grab the times before the start time and after the end time and mark everything in between as present
          const startApproximateTimeIndex = mappedObject.time.findIndex(
            (timestamp) =>
              timestamp > eventObj.start_time_min * SECOND_IN_MILLISECONDS,
          )
          const inclusiveStartIndex =
            startApproximateTimeIndex > 0 ? startApproximateTimeIndex - 1 : 0
          const endApproximateTimeIndex = mappedObject.time.findIndex(
            (timestamp) =>
              timestamp >= eventObj.end_time_max * SECOND_IN_MILLISECONDS,
          )

          if (monthlyView) {
            for (
              let n = inclusiveStartIndex;
              n < endApproximateTimeIndex;
              n++
            ) {
              mappedObject.events[n] = 1
            }
          } else {
            for (
              let n = inclusiveStartIndex;
              n <= endApproximateTimeIndex;
              n++
            ) {
              mappedObject.events[n] = 1
            }
          }
          break
        default:
          break
      }
    })
  }

  if (taskData && taskData.dataSessions) {
    taskData.dataSessions.forEach((taskObj) => {
      if (taskObj.schemaId === 'strivestudy-spiraltest-1') {
        // grab the times surrounding the point event and mark both as having data (we need to render them as at least 5 mins for them to show)
        const firstApproximateTimeIndex = mappedObject.time.findIndex(
          (timestamp) => timestamp > taskObj.startTime * SECOND_IN_MILLISECONDS,
        )
        const prevApproximateTimeIndex = firstApproximateTimeIndex - 1

        if (!monthlyView) {
          mappedObject.events[firstApproximateTimeIndex] = 1
        }

        if (prevApproximateTimeIndex >= 0) {
          mappedObject.events[prevApproximateTimeIndex] = 1
        }
      }
    })
  }

  let formattedArray = []

  if (monthlyView) {
    const startTimestamp = moment(mappedObject.formattedTime[0])
    const endTimestamp = moment(
      mappedObject.formattedTime[mappedObject.formattedTime.length - 1],
    )
    const numberOfMonths = endTimestamp.diff(startTimestamp, 'months')

    for (let x = 0; x <= numberOfMonths; x++) {
      const daysInCurrentMonth = moment(
        mappedObject.formattedTime[0],
      ).daysInMonth()

      formattedArray.push({
        label: 'Event Data',
        x: mappedObject.formattedTime.splice(0, daysInCurrentMonth),
        y: mappedObject.events.splice(0, daysInCurrentMonth),
      })
    }

    return formattedArray
  }

  // INTERVALS_IN_DAY is 288 because there are 288 periods of 5 minutes per day, separating this into day-length arrays
  for (let x = 0; x < mappedObject.time.length / INTERVALS_IN_DAY; x++) {
    formattedArray.push({
      label: 'Event Data',
      x: mappedObject.formattedTime.splice(0, INTERVALS_IN_DAY),
      y: mappedObject.events.splice(0, INTERVALS_IN_DAY),
    })
  }

  return formattedArray
}

/**
 * Function that formats and combines events stream data as a temporary
 * solution to the absence of a true events availability stream. If there are
 * no events streams present, returns an array identical to data
 *
 * @param {array} data stream data from requests
 * @param {object} commonParams Parameters common and identical to all stream requests
 * @param {object} tasks result from dataSessions GQL request
 * @param {boolean} monthlyView toggle to map events based on the monthly availability view
 */
export const formatDataStreamsForEvents = (
  data,
  commonParams,
  tasks,
  monthlyView = false,
) => {
  let objCounter = 0
  let formattedData = []

  if (data) {
    data.forEach((dataStream, index) => {
      // checks here for events data - everything except events will be an array
      if (Array.isArray(dataStream)) {
        formattedData.push(dataStream)
      } else {
        // if events data is present it will always have point data, then span data. This grabs each in place.
        if (objCounter > 0) {
          const events = data[index - 1].data
          const spans = data[index].data
          const mappedEvents = mapEvents(
            commonParams,
            events,
            spans,
            tasks,
            monthlyView,
          )
          formattedData.push(mappedEvents)
        } else {
          // skips over the first event result to grab both on the next go-around
          objCounter++
        }
      }
    })
    return formattedData
  }
}
