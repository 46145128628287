import { useContext } from 'react'
import { connect } from 'react-redux'
import { pushQuery } from 'query'
import { PatientContext } from 'ui/contexts/PatientContext'
import UserContext from 'ui/contexts/UserContext'
import { Tab, Tabs, makeStyles } from '@material-ui/core'
import { validateDates } from 'utilities/time'

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'inline-block',
    height: '80px',
    '& .MuiTab-root': {
      fontSize: theme.typography.h6.fontSize,
      textTransform: 'none',
      padding: theme.spacing(3),
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
      height: '3px',
    },
    '& .Mui-selected': {
      backgroundColor: theme.palette.secondary.veryLight,
      color: theme.palette.primary.main,
    },
  },
}))

/**
 * Tab component for switching between daily and monhtly availability view
 *
 * @param {boolean} monthlyView boolean indicating monthly availability mode
 * @param {date} dateStart Start date for which to show availability
 * @param {date} dateEnd End date for which to show availability
 * @param {function} pushQuery function that updates history
 * @returns {JSX.Element} Buttons that switch between Daily View and Monthly View
 */
export const ExplorerTabToggle = ({
  monthlyView,
  dateStart,
  dateEnd,
  pushQuery,
}) => {
  const classes = useStyles()
  const patient = useContext(PatientContext)
  const { defaultMembership } = useContext(UserContext)
  const currentTab = monthlyView ? 'monthly' : 'daily'

  /**
   * Function that toggles between the daily and monthly views.
   *
   * @param {event} event The event created from selecting a new patient
   * @param {string} newView A string indicating whether the view is 'daily' or not
   * @returns {void}
   */
  const onChangeView = (event, newView) => {
    const monthlyURL = `/patients/${patient.id}/explore-monthly`
    const dailyURL =
      defaultMembership?.role?.displayName === 'Clinician'
        ? `/patients/${patient.id}/explore`
        : `/patients/${patient.id}`
    const url = newView === 'daily' ? dailyURL : monthlyURL

    const validatedDates =
      newView === 'daily'
        ? validateDates(dateStart, dateEnd, false, false)
        : validateDates(dateStart, dateEnd, false, true)

    const [validatedStart, validatedEnd] = validatedDates

    pushQuery(
      {
        from: validatedStart,
        to: validatedEnd,
      },
      {
        url: url,
      },
    )
  }

  return (
    <Tabs
      aria-label="Data Viewing Options"
      className={classes.tabs}
      value={currentTab}
      onChange={onChangeView}
      data-cy="ExplorerTabToggle"
    >
      <Tab
        color="inherit"
        value="monthly"
        label="MONTHLY VIEW"
        data-cy="tab-explorer-monthly"
      />
      <Tab
        color="inherit"
        value="daily"
        label="DAILY VIEW"
        data-cy="tab-explorer-daily"
      />
    </Tabs>
  )
}

export default connect((_, props) => props, { pushQuery })(ExplorerTabToggle)
