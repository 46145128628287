import { useContext } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { RUNE_GRAY_700 } from 'theme/colors'
import { UserContext } from 'ui/contexts'
import { useAnalyticsTracking } from 'ui/hooks'
import { ANALYTICS_TRACKING_STATUS } from 'domains/mixpanel/consts'
import { Z_INDEX_COOKIE_BANNER } from 'theme/zIndexRegistry'
import { PrivacyNoticeLink } from 'ui/components/PrivacyNoticeLink'

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: '7.5rem',
  },
  buttonGroup: {
    display: 'flex',
    columnGap: '1rem',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: '3rem',
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#FAFAFA',
    boxShadow: '0px -6px 6px rgba(0, 0, 0, 0.1)',
    minHeight: '6rem',
    color: RUNE_GRAY_700,
    fontFamily: `${theme.typography.fontFamily}`,
    zIndex: Z_INDEX_COOKIE_BANNER,
  },
}))

const CookieBanner = () => {
  const classes = useStyles()
  const { analyticsTracking } = useContext(UserContext)
  const { updateAnalyticsTracking } = useAnalyticsTracking()

  if (analyticsTracking === ANALYTICS_TRACKING_STATUS.noDecision) {
    return (
      <div className={classes.container}>
        <div>
          <div>
            We use non-essential cookies to enhance your experience and improve
            the product.
          </div>
          <div>
            You can learn more in our&nbsp;
            <PrivacyNoticeLink />.
          </div>
        </div>
        <div className={classes.buttonGroup}>
          <Button
            className={classes.button}
            size="large"
            variant="outlined"
            color="primary"
            data-cy="CookieBannerDenyButton"
            onClick={() =>
              updateAnalyticsTracking(ANALYTICS_TRACKING_STATUS.optedOut)
            }
          >
            Deny
          </Button>
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            color="primary"
            data-cy="CookieBannerAcceptButton"
            onClick={() =>
              updateAnalyticsTracking(ANALYTICS_TRACKING_STATUS.optedIn)
            }
          >
            Accept
          </Button>
        </div>
      </div>
    )
  }

  return <></>
}

export default CookieBanner
