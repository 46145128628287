import { Text } from 'ui/baseComponents/Text'

export const DYSKINESIA_HELPER_TEXT = {
  aboutTheData: 'About the data:',
  aboutYourData: 'About your data:',
  clinician:
    'Certain activities like exercising, brushing your teeth, or playing piano can sometimes be interpreted by Apple Watch as dyskinesia. You can recommend your patient to note in StrivePD when these activities occur to contextualize the data.',
  patient:
    'Certain activities like exercising, brushing your teeth, or playing piano can sometimes be interpreted by your Apple Watch as dyskinesia. We recommend you note in StrivePD when these activities occur so you can contextualize your data.',
}

export const HELPER_DYSKINESIA = (
  <Text variant="body14" component="p" data-cy="dyskinesia-helper">
    <strong>{DYSKINESIA_HELPER_TEXT.aboutYourData}</strong>{' '}
    {DYSKINESIA_HELPER_TEXT.patient}
  </Text>
)

export const HELPER_DYSKINESIA_CLINICIAN = (
  <Text variant="body14" component="p" data-cy="dyskinesia-helper">
    <strong>{DYSKINESIA_HELPER_TEXT.aboutTheData}</strong>{' '}
    {DYSKINESIA_HELPER_TEXT.clinician}
  </Text>
)
