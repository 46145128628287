import { node, string } from 'prop-types'
import { colors } from 'theme/colors'
import { Stack } from 'ui/baseComponents/Stack'

const STYLES = {
  container: {
    background: colors.COOL[100],
    '@media print': {
      display: 'none',
    },
  },
  contentWrapper: {
    padding: '1rem 2rem',
    background: colors.PRIMARY[300],
    borderRadius: '24px 24px 0px 0px',
    minHeight: '1.5rem',
  },
}

const TabActionableHeader = ({ children, justifyContent }) => (
  <div style={STYLES.container}>
    <Stack
      direction="row"
      alignItems="center"
      sx={STYLES.contentWrapper}
      {...{ justifyContent }}
    >
      {children}
    </Stack>
  </div>
)

TabActionableHeader.propTypes = {
  children: node,
  justifyContent: string,
}

export default TabActionableHeader
