import React from 'react'
import { styled } from '@mui/material/styles'
import { arrayOf, element, string } from 'prop-types'
import { Grid } from '@mui/material'
import { GridItemList } from 'ui/components/Grid'
import { SummaryBarTitleItem } from '../SummaryBar'
import { Text } from 'ui/baseComponents/Text'
import { RUNE_GRAY_300 } from 'theme/colors'

const STYLES = {
  rightmostContainer: {
    margin: 0,
  },
  rightmostContentItem: {
    borderLeft: `1px solid ${RUNE_GRAY_300}`,
  },
}

const RIGHT_COLUMN_WIDTH_SMALL = 250
const RIGHT_COLUMN_WIDTH = 360

const RightmostContainer = styled(Grid)(({ theme }) => ({
  width: RIGHT_COLUMN_WIDTH_SMALL,
  [theme.breakpoints.up('xl')]: {
    minWidth: RIGHT_COLUMN_WIDTH,
  },
}))

const AccordionSummaryBar = ({
  title,
  subtitle,
  dataComponents,
  metadataComponents,
}) => (
  <Grid container alignItems="center">
    <SummaryBarTitleItem item>
      <Grid item>
        <Text component="h3" variant="head24B">
          {title}
        </Text>
      </Grid>
      <Grid item>
        <Text component="p" variant="body14">
          {subtitle}
        </Text>
      </Grid>
    </SummaryBarTitleItem>
    <Grid item xs>
      <GridItemList elements={dataComponents} spacing={3} />
    </Grid>
    <RightmostContainer item sx={STYLES.rightmostContainer}>
      {metadataComponents.length ? (
        <GridItemList
          elements={metadataComponents}
          sx={STYLES.rightmostContentItem}
          spacing={2}
        />
      ) : (
        <></>
      )}
    </RightmostContainer>
  </Grid>
)

AccordionSummaryBar.propTypes = {
  title: string,
  subtitle: string,
  dataComponents: arrayOf(element),
  metadataComponents: arrayOf(element),
}

AccordionSummaryBar.defaultProps = {
  title: '',
  subtitle: '',
  dataComponents: [],
  metadataComponents: [],
}

export default AccordionSummaryBar
