import { gql, useQuery } from '@apollo/client'

export const GET_DEVICE_TYPES = gql`
  query deviceTypes {
    deviceTypes {
      id
      displayName
    }
  }
`

export const useDeviceTypes = () => {
  const { loading, error, data } = useQuery(GET_DEVICE_TYPES, {
    fetchPolicy: 'cache-and-network',
  })
  const { deviceTypes } = data || {}
  return { loading, error, deviceTypes }
}
