import { useFlags } from 'domains/launchdarkly/hooks'
import { useContext } from 'react'
import UserPHIContext from 'ui/contexts/UserPHIContext'
import PatientListClinicianPortal from 'ui/screens/Patients/PatientList/PatientListClinicianPortal'
import PatientListResearcherPortal from 'ui/screens/Patients/PatientList/PatientListResearcherPortal'

/**
 * Renders the PatientList component.
 * This component displays either the PatientListClinicianPortal or the PatientListResearcherPortal
 * based on the phiVisibility context.
 *
 * @returns {JSX.Element} The rendered PatientList component.
 */

const PatientList = () => {
  const { deprecatedViewDisabled } = useFlags()
  const phiVisibility = useContext(UserPHIContext)

  /* TODO: When deprecatedViewDisabled is removed, remove the conditional and always render PatientListClinicianPortal.
     Since at that point there will only be one patient list, move the contents of PatientListClinicianPortal into this file. */
  if (phiVisibility || deprecatedViewDisabled) {
    return <PatientListClinicianPortal />
  }
  return <PatientListResearcherPortal />
}

export default PatientList
