import { memo } from 'react'
import IconButton from '@mui/material/IconButton'
import AssignmentIcon from '@mui/icons-material/Assignment'
import TableCell from '@mui/material/TableCell'
import NavLink from 'ui/components/NavLink/index'
import { STYLES } from 'ui/screens/Patients/PatientList/PatientTable/consts'

/**
 * Renders a table cell with a link to the patient report.
 *
 * @param {Object} props - The component props.
 * @param {string} props.patientId - The ID of the patient.
 * @returns {JSX.Element} The rendered component.
 */
const TableCellLinkToPatientReport = ({ patientId }) => (
  <TableCell sx={STYLES.iconCell}>
    <IconButton size="small" sx={STYLES.iconButton}>
      <NavLink
        to={`/patients/${patientId}/report`}
        title="Patient Report"
        data-cy="patient-row-patient-report-link"
        sx={STYLES.settingsIcon}
        target="_blank"
      >
        <AssignmentIcon />
      </NavLink>
    </IconButton>
  </TableCell>
)

export default memo(TableCellLinkToPatientReport)
