import { useContext, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { Grid, makeStyles, Switch, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { InformationalIconTooltip } from 'ui/components/InformationalIconTooltip'
import { RUNE_GRAY_500 } from 'theme/colors'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import { SummaryModeContext } from 'ui/contexts/SummaryModeContext'
import ComparisonModeTooltipContent from './ComparisonModeTooltipContent'
import { ARIA_LABEL } from './consts'
import { clinicianRoutes } from 'ui/clinicianScreens/Patient/Tabs/consts.js'
import { trackComparisonModeToggled } from 'domains/mixpanel'

const useStyles = makeStyles((theme) => ({
  label: {
    color: RUNE_GRAY_500,
    letterSpacing: 0,
  },
  enabled: {
    color: theme.palette.primary.main,
  },
}))

const SummaryComparisonSwitch = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { comparisonModeEnabled, setComparisonModeEnabled } =
    useContext(SummaryModeContext)

  const [enabled, setEnabled] = useState(comparisonModeEnabled)

  const { dateRanges } = useContext(DateRangeContext)
  const { start: startTime, end: endTime } =
    dateRanges[DATE_RANGE.default.key] || {}
  const { start: period1StartContext, end: period1EndContext } =
    dateRanges[DATE_RANGE.period1.key] || {}
  const { start: period2StartContext, end: period2EndContext } =
    dateRanges[DATE_RANGE.period2.key] || {}

  const summarySearchParams = {
    startTime,
    endTime,
  }

  const summaryComparisonSearchParams =
    period1StartContext &&
    period1EndContext &&
    period2StartContext &&
    period2EndContext
      ? {
          startTimePeriod1: period1StartContext,
          endTimePeriod1: period1EndContext,
          startTimePeriod2: period2StartContext,
          endTimePeriod2: period2EndContext,
        }
      : null

  const handleChange = (event) => {
    setEnabled(event.target.checked)
    setComparisonModeEnabled(event.target.checked)
    trackComparisonModeToggled(event.target.checked)

    event.target.checked
      ? navigate({
          pathname: `../${clinicianRoutes.SUMMARY_COMPARISON}`,
          search: summaryComparisonSearchParams
            ? createSearchParams(summaryComparisonSearchParams).toString()
            : '',
        })
      : navigate({
          pathname: `../${clinicianRoutes.SUMMARY}`,
          search: createSearchParams(summarySearchParams).toString(),
        })
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography
          variant="button"
          className={clsx({
            [classes.label]: true,
            [classes.enabled]: !enabled,
          })}
        >
          Summary Mode
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={enabled}
          onChange={handleChange}
          aria-label={
            enabled
              ? ARIA_LABEL.comparisonModeEnabled
              : ARIA_LABEL.summaryModeEnabled
          }
        />
      </Grid>
      <Grid item>
        <Typography
          variant="button"
          className={clsx({
            [classes.label]: true,
            [classes.enabled]: enabled,
          })}
        >
          Comparison Mode
        </Typography>
      </Grid>
      <Grid item>
        <InformationalIconTooltip
          color={enabled ? 'primary' : 'light'}
          wide
          title={<ComparisonModeTooltipContent />}
          placement="bottom-end"
        />
      </Grid>
    </Grid>
  )
}

SummaryComparisonSwitch.propTypes = {}

SummaryComparisonSwitch.defaultProps = {}

export default SummaryComparisonSwitch
