import { Text } from 'ui/baseComponents'

export const DYSKINESIA_SYMPTOMS_SUBTITLE =
  'Data recorded from symptom logs and daily check-ins'

export const DYSKINESIA_INSIGHTS_BANNER_PATIENT_TEXT =
  "You're receiving these insights because your dyskinesia occurs more frequently than most StrivePD users."

export const DYSKINESIA_INSIGHTS_BANNER_CLINICIAN_TEXT =
  'Your patient has these insights because their dyskinesia occurs more frequently than most StrivePD users.'

export const HELPER_DYSKINESIA_SYMPTOMS_PATIENT_EMPTY_STATE = (
  <>
    <Text variant="body16" data-cy="dyskinesia-symptoms-patient-empty-state">
      You have not reported any dyskinesia-related symptoms in the last 4 weeks.
      To better manage your dyskinesia conditions, it is essential to capture
      the time when you are:
    </Text>
    <ul style={{ paddingLeft: '1.5rem', marginTop: '0.5rem' }}>
      <li>
        <Text variant="body16">On with no dyskinesia</Text>
      </li>
      <li>
        <Text variant="body16">On with non-troublesome dyskinesia</Text>
      </li>
      <li>
        <Text variant="body16">On with troublesome dyskinesia</Text>
      </li>
      <li>
        <Text variant="body16">OFF period</Text>
      </li>
      <li>
        <Text variant="body16">Dyskinesia</Text>
      </li>
    </ul>
  </>
)

export const HELPER_DYSKINESIA_SYMPTOMS_CLINICIAN_EMPTY_STATE = (
  <>
    <Text variant="body16" data-cy="dyskinesia-symptoms-clinician-empty-state">
      Your patient has not reported any of the following during the reporting
      period:
    </Text>
    <ul style={{ paddingLeft: '1.5rem', marginTop: '0.5rem' }}>
      <li>
        <Text variant="body16">On with no dyskinesia</Text>
      </li>
      <li>
        <Text variant="body16">On with non-troublesome dyskinesia</Text>
      </li>
      <li>
        <Text variant="body16">On with troublesome dyskinesia</Text>
      </li>
      <li>
        <Text variant="body16">OFF period</Text>
      </li>
      <li>
        <Text variant="body16">Dyskinesia</Text>
      </li>
    </ul>
  </>
)
