import { cloneElement, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core'
import { component } from 'utilities/runePropTypes'

const useStyles = makeStyles((theme) => ({
  box: {
    justifyContent: `${theme.createEntity.justifyContent}`,
    display: 'flex',
    backgroundColor: `${theme.createEntity.backgroundColor}`,
    color: `${theme.createEntity.color}`,
  },
  createEntityButton: {
    color: `${theme.createEntity.button.color}`,
    backgroundColor: `${theme.createEntity.button.backgroundColor}`,
    maxHeight: '42px',
    '&:hover': {
      backgroundColor: `${theme.createEntity.button.hoverBackgroundColor}`,
    },
  },
}))

/**
 * Component that renders a dialog with various actions and options.
 * @returns {JSX.Element} Button to open a Dialog and paired Dialog with children.
 */
const CreateEntity = ({
  label,
  children,
  variant,
  customHeaderClass,
  customButtonClass,
  customColor,
  startIcon,
  size,
  disableBackdropClick,
  disableEscapeKeyDown,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleClickOpen = useCallback(() => setOpen(true), [])

  /**
   * Basic handleClose function with the addition of functionality to disable
   * closing the dialog with a click on the backdrop or an escape key press
   *
   * @param {object} event the event object supplied to the onClose function
   * @param {string} reason method of closing supplied to onClose function
   */
  const handleClose = useCallback(
    (_, reason) => {
      if (
        (disableBackdropClick && reason === 'backdropClick') ||
        (disableEscapeKeyDown && reason === 'escapeKeyDown')
      ) {
        return
      }
      setOpen(false)
    },
    [disableBackdropClick, disableEscapeKeyDown],
  )

  return (
    <Box className={customHeaderClass || classes.box} p={3}>
      <Button
        className={customButtonClass || classes.createEntityButton}
        size="large"
        variant={variant}
        color={customColor}
        data-testid="CreateEntityButton"
        data-cy={`create-entity-button ${label}`}
        onClick={handleClickOpen}
      >
        {!!startIcon && startIcon} {label}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={size}
        fullWidth={!!size}
        aria-labelledby="form-dialog-title"
      >
        {cloneElement(children, { handleClose })}
      </Dialog>
    </Box>
  )
}

CreateEntity.propTypes = {
  label: PropTypes.string,
  children: component,
  variant: PropTypes.string,
  customHeaderClass: PropTypes.string,
  customButtonClass: PropTypes.string,
  customColor: PropTypes.string,
  startIcon: PropTypes.element,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
}

CreateEntity.defaultProps = {
  variant: 'contained',
  customColor: 'primary',
  size: false,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
}

export default CreateEntity
