import { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Paper, makeStyles } from '@material-ui/core'
import { makeQuerySelector, pushQuery as pushQueryAction } from 'query'
import { createSelector } from 'reselect'
import ErrorMessage from 'ui/components/ErrorMessage'
import { PatientContext } from 'ui/contexts/PatientContext'
import BrowseControls from 'ui/screens/Patient/Browse/BrowseControls'
import { DragModeProvider } from 'ui/screens/Patient/Browse/DragModeContext'
import StreamResults from 'ui/screens/Patient/Browse/StreamResults'
import { StreamsProvider } from 'ui/screens/Patient/Browse/StreamSelector/StreamsContext'
import { validateDates } from 'utilities/time'

const startSelector = createSelector(makeQuerySelector('start'), (start) =>
  parseFloat(start),
)
const endSelector = createSelector(makeQuerySelector('end'), (end) =>
  parseFloat(end),
)

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '2rem',
  },
  tabContent: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  card: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  cardContent: {
    padding: 0,
  },
}))

const Browse = () => {
  const classes = useStyles()
  const tStart = useSelector(startSelector)
  let tEnd = useSelector(endSelector)
  // Assess url params before sending the query
  if (tStart > tEnd) {
    const validatedDates = validateDates(tStart, tEnd, true)
    const [, validEnd] = validatedDates
    tEnd = validEnd
  }
  const dispatch = useDispatch()
  const pushQuery = useCallback(
    (...args) => dispatch(pushQueryAction(...args)),
    [dispatch],
  )

  const updateTimeRange = useCallback(
    (newTStart, newTEnd) => {
      /*
       * Update the global start and end time
       */
      if (newTStart >= newTEnd) {
        console.error('Attempting to update time range with start >= end')
        return
      }

      pushQuery({
        start: newTStart,
        end: newTEnd,
      })
    },
    [pushQuery],
  )

  // If the end time needed updating we need to push it to the url params
  useEffect(() => {
    updateTimeRange(tStart, tEnd)
  }, [tEnd, tStart, updateTimeRange])

  const patient = useContext(PatientContext)

  if (!tStart || isNaN(tStart) || !tEnd || isNaN(tEnd)) {
    return <ErrorMessage />
  }

  return (
    <div data-cy="Patient" className={classes.root}>
      <DragModeProvider>
        <StreamsProvider>
          <BrowseControls patient={patient} tStart={tStart} tEnd={tEnd} />
          <Paper className={classes.paper}>
            <StreamResults
              patient={patient}
              tStart={tStart}
              tEnd={tEnd}
              updateTimeRange={updateTimeRange}
            />
          </Paper>
        </StreamsProvider>
      </DragModeProvider>
    </div>
  )
}

export default Browse
