import { PropTypes } from 'prop-types'
import React from 'react'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import moment from 'moment'
import { Box, Button, makeStyles } from '@material-ui/core'
import { DATE_FORMAT } from 'ui/consts'

const useStyles = makeStyles((theme) => ({
  arrowIcon: {
    padding: theme.spacing(0.1),
    borderRadius: '50%',
    border: `2px solid ${theme.palette.primary.main}`,
    marginLeft: theme.spacing(1),
    height: '1.25rem',
    width: '1.25rem',
  },
  container: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  loadMoreButton: {
    color: theme.palette.primary.main,
  },
}))

export const MAX_RANGE_ADJUSTED = 11 // This is the max range - 1, used in calcs for how many months to add without exceeding limit
const MONTHS_TO_ADD = 6
const MAX_RANGE_SIZE_PRE_ADDITION = MAX_RANGE_ADJUSTED - MONTHS_TO_ADD

const LoadMoreMonths = ({
  direction,
  from: fromProp,
  to: toProp,
  setDateRange,
}) => {
  const classes = useStyles()

  // prevent requests for future months
  if (direction === 'future' && moment(toProp).isSame(moment(), 'month')) {
    return null
  }

  // prevent requests for the 90s or earlier (just in case, to match calendar max range)
  if (direction === 'past' && moment(fromProp).isBefore('2000-01-01')) {
    return null
  }

  const buttonText =
    direction === 'future' ? 'Future Months' : 'Previous Months'
  const buttonIcon =
    direction === 'future' ? (
      <ArrowUpwardIcon className={classes.arrowIcon} />
    ) : (
      <ArrowDownwardIcon className={classes.arrowIcon} />
    )

  const onClick = () => {
    // Add 6 months, or fewer if that would exceed the 1 year limit.
    const rangeSize = moment(toProp).diff(moment(fromProp), 'months')
    const monthsToAdd =
      rangeSize > MAX_RANGE_SIZE_PRE_ADDITION
        ? MAX_RANGE_ADJUSTED - rangeSize
        : MONTHS_TO_ADD

    if (direction === 'future') {
      let newEnd = moment(toProp).add(monthsToAdd, 'month').endOf('month')
      const currentMonth = moment().endOf('month')
      // if this would take us into the future, set the month to this month.
      if (newEnd > currentMonth) {
        newEnd = currentMonth
      }
      setDateRange({
        from: fromProp,
        to: newEnd.format(DATE_FORMAT),
      })
    } else {
      const newStart = moment(fromProp)
        .subtract(monthsToAdd, 'month')
        .startOf('month')
      setDateRange({
        from: newStart.format(DATE_FORMAT),
        to: toProp,
      })
    }
  }

  return (
    <Box className={classes.container} data-cy="LoadMoreMonths">
      <Button
        variant="text"
        className={classes.loadMoreButton}
        onClick={onClick}
      >
        {buttonText} {buttonIcon}
      </Button>
    </Box>
  )
}

LoadMoreMonths.propTypes = {
  direction: PropTypes.string,
  from: PropTypes.PropTypes.instanceOf(Date),
  to: PropTypes.PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func,
}

export default LoadMoreMonths
