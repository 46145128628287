/**
 * A helper function to format the DBS data
 * @param {object} dbsObject DBS Log event object
 * @returns {string[]} String representations of DBS Log entries
 */
export const formatDBSEventText = (dbsObject) => {
  // Filter out not usable dbs log items and format the wording
  let neededPayloadItems = Object.entries(JSON.parse(dbsObject.payload)).map(
    ([key, value]) => {
      if (
        value &&
        key !== 'manufacturer' &&
        key !== 'program_name' &&
        key !== 'setting_snapshot'
      ) {
        // Battery charged comes with a date, but the startTime takes care of that so we remove it
        if (value && key === 'battery_charged_at') {
          return `${key.replace(/_/g, ' ').replace(/ at/g, '')}, `
        }
        return `${key.replace(/_/g, ' ')}: ${value}, `
      } else {
        return null
      }
    },
  )
  // Remove undefined items from array
  neededPayloadItems = neededPayloadItems.filter(
    (item) => typeof item !== 'undefined' && item,
  )

  // Remove the comma from the last needed payload item so it looks right in the display
  return neededPayloadItems.join('').replace(/, $/g, '')
}
