import { memo, useContext } from 'react'
import TableCell from '@mui/material/TableCell'
import UserPHIContext from 'ui/contexts/UserPHIContext'
import NavLink from 'ui/components/NavLink/index'
import { useFlags } from 'domains/launchdarkly/hooks'
import { STYLES } from 'ui/screens/Patients/PatientList/PatientTable/consts'
import { clinicianRoutes } from 'ui/clinicianScreens/Patient/Tabs/consts'

/**
 * Renders a table cell containing the patient's name as a link.
 *
 * @param {Object} props - The component props.
 * @param {string} props.patientId - The ID of the patient.
 * @param {string} props.patientName - The name of the patient. This could be the real name or the code name depending on the user's PHI visibility settings.
 * @param {Object} props.sx - The style object for the header cell.
 * @returns {JSX.Element} The rendered component.
 */
const TableCellPatientName = ({ patientId, patientName, sx }) => {
  const { deprecatedViewDisabled, overviewTabVisible } = useFlags()
  const phiVisibility = useContext(UserPHIContext)

  const getLinkForPatientName = () => {
    // TODO: When deprecatedViewDisabled is removed, remove the phiVisibility || deprecatedViewDisabled conditional and always render OVERVIEW OR SUMMARY.
    if (phiVisibility || deprecatedViewDisabled) {
      return overviewTabVisible
        ? `/patients/${patientId}/${clinicianRoutes.OVERVIEW}`
        : `/patients/${patientId}/${clinicianRoutes.SUMMARY}`
    } else {
      return `/patients/${patientId}`
    }
  }

  return (
    <TableCell sx={{ ...STYLES.patientName, ...sx }}>
      <NavLink
        to={getLinkForPatientName()}
        title="Browse time-based patient data"
        data-cy="patient-row-name-link"
      >
        {patientName}
      </NavLink>
    </TableCell>
  )
}

export default memo(TableCellPatientName)
