import { Link, Text } from 'ui/baseComponents'
import { VIDEO_LINKS } from 'ui/clinicianScreens/Patient/Report/consts'

export const MEDICATION_EMPTY_STATE_TEXT = 'No medication schedule added'

export const MEDICATION_HELPER_TEXT = {
  emptyState:
    'Set up your medication schedule and reminders on the StrivePD app. For additional support, watch our videos about',
  importanceOfLogging: 'importance of logging medication',
  linkSeparator: 'or on the',
  medicationSchedule: 'medication schedule',
  updateYourSchedule:
    'If this information is incorrect, please update your medication schedule on the StrivePD app. For additional support, watch our videos about',
}

export const SECTION_MEDICATIONS_EMPTY_STATE = (
  <Text variant="body16" component="p" data-cy="medication-section-no-data">
    {MEDICATION_EMPTY_STATE_TEXT}
  </Text>
)

export const HELPER_MEDICATIONS = (
  <Text variant="body14" component="p" data-cy="medications-helper">
    {MEDICATION_HELPER_TEXT.updateYourSchedule}{' '}
    <Link
      href={VIDEO_LINKS.medicationScheduleTutorial}
      external
      underline
      light
    >
      {MEDICATION_HELPER_TEXT.medicationSchedule}
    </Link>{' '}
    {MEDICATION_HELPER_TEXT.linkSeparator}{' '}
    <Link href={VIDEO_LINKS.medication} external underline light>
      {MEDICATION_HELPER_TEXT.importanceOfLogging}
    </Link>
    .
  </Text>
)

export const HELPER_MEDICATIONS_EMPTY_STATE = (
  <Text variant="body14" component="p" data-cy="medications-helper-no-data">
    {MEDICATION_HELPER_TEXT.emptyState}{' '}
    <Link
      href={VIDEO_LINKS.medicationScheduleTutorial}
      external
      underline
      light
    >
      {MEDICATION_HELPER_TEXT.medicationSchedule}
    </Link>{' '}
    {MEDICATION_HELPER_TEXT.linkSeparator}{' '}
    <Link href={VIDEO_LINKS.medication} external underline light>
      {MEDICATION_HELPER_TEXT.importanceOfLogging}
    </Link>
    .
  </Text>
)
