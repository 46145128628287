import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { RUNE_GRAY_400 } from 'theme/colors'
import Plot from 'ui/components/Plot'

const useStyles = makeStyles(() => ({
  plot: {
    width: '100%',
  },
}))

const Heatmap = ({
  xLabels,
  yLabels,
  data,
  customData,
  min,
  max,
  colors,
  hoverTemplate,
  showNoData,
}) => {
  const classes = useStyles()

  const Y_GAP = 3
  const SWIMLANE_HEIGHT = 35
  const MIN_NO_DATA_VALUE = 0
  const MAX_NO_DATA_VALUE = 1

  const config = {
    autosize: false,
    responsive: true,
    displayModeBar: false,
    doubleClick: false,
    scrollZoom: false,
  }

  const layout = {
    font: { family: 'Work Sans' },
    margin: { t: 10, b: 25, r: 0 },
    autosize: true,
    height: (SWIMLANE_HEIGHT + Y_GAP) * (yLabels.length + 1),
    xaxis: {
      automargin: true,
      fixedrange: true,
      gridlines: false,
      showgrid: false,
      showline: true,
      nticks: 26,
    },
    yaxis: {
      autorange: 'reversed',
      automargin: true,
      fixedrange: true,
    },
    paper_bgcolor: 'rgba(0,0,0,0)', // eslint-disable-line camelcase
    plot_bgcolor: 'rgba(0,0,0,0)', // eslint-disable-line camelcase
  }

  const noDataZ = data.map((row) =>
    row.map((datum) => (datum === null ? MAX_NO_DATA_VALUE : null)),
  )

  const plotData = []

  if (showNoData) {
    plotData.push({
      x: xLabels,
      y: yLabels,
      z: noDataZ,
      type: 'heatmap',
      colorscale: [
        [MIN_NO_DATA_VALUE, RUNE_GRAY_400],
        [MAX_NO_DATA_VALUE, RUNE_GRAY_400],
      ],
      showscale: false,
      hoverinfo: 'skip',
      ygap: Y_GAP,
    })
  }

  plotData.push({
    x: xLabels,
    y: yLabels,
    z: data,
    ygap: Y_GAP,
    customdata: customData,
    zmin: min,
    zmax: max,
    type: 'heatmap',
    colorscale: colors,
    showscale: false,
    hoverlabel: {
      bgcolor: '#ffffff',
      font: {
        color: '#000000',
        family: 'Work Sans',
      },
    },
    hovertemplate: hoverTemplate,
  })

  return (
    <Plot
      className={classes.plot}
      config={config}
      layout={layout}
      data={plotData}
    />
  )
}

Heatmap.propTypes = {
  xLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  yLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  customData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  hoverTemplate: PropTypes.string.isRequired,
}

Heatmap.defaultProps = {}

export default Heatmap
