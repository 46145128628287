import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { CssBaseline, Grid, LinearProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    height: '90vh',
    padding: theme.spacing(5),
  },
}))

/**
 * A page with no header, e.g. for non-logged-in stuffs.
 * @returns {JSX.Element} Empty page component with proper theme CSS
 */
const EmptyPage = ({ children, compliantTitle, loading, ...props }) => {
  const classes = useStyles()

  return (
    <div {...props} className={classes.root}>
      <CssBaseline />
      <title>{compliantTitle} | Rune Labs</title>
      {loading && <LinearProgress />}
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.content}
      >
        {children}
      </Grid>
    </div>
  )
}

EmptyPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  compliantTitle: PropTypes.string,
  loading: PropTypes.bool,
}

EmptyPage.defaultProps = {
  children: null,
  loading: false,
  compliantTitle: 'DHP',
}

export default EmptyPage
