import { FullWidthRow } from 'ui/screens/Patients/PatientList/PatientTable'
import EmptyResults from 'ui/components/EmptyResults'
import { TABLE_STATUS_TEXT } from 'ui/screens/Patients/PatientList/PatientTable/consts'

/**
 * Renders an empty state for the patient table.
 * @param {Object} props - The component props.
 * @param {String} props.icon - The icon to display.
 * @param {String} props.message - The message to display.
 *
 * @returns {JSX.Element} The rendered JSX element.
 */
const PatientTableEmpty = ({ icon, message = TABLE_STATUS_TEXT.empty }) => (
  <FullWidthRow>
    <EmptyResults {...{ icon, message }} />
  </FullWidthRow>
)

export default PatientTableEmpty
