import React from 'react'
import { NotificationBar, Error } from 'ui/components/Notifications'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionSummary,
  Box,
  Divider,
  MenuItem,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { sortByProperty } from 'utilities/sort'

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    paddingRight: '0.5rem',
    backgroundColor: 'transparent',
    margin: '0 !important',
  },
  accordionSummary: {
    padding: 0,
    minHeight: '1rem',
    width: '100%',
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .MuiAccordionSummary-expandIcon': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  accordionSelected: {
    fontSize: '0.75rem',
    position: 'relative',
    right: '0.5rem',
    color: theme.palette.primary.main,
  },
  falseMenuItem: {
    padding: '6px 16px',
  },
  menuItem: {
    minWidth: '200px',
    padding: theme.spacing(1, 2),
  },
}))

const SINGLE_ORG = 1

/**
 * OrgList component that offers org switching controls.
 *
 * @returns {JSX.Element} Organization list component
 */
const OrgList = ({
  defaultMembership,
  memberships,
  onClickCallback,
  error,
}) => {
  const classes = useStyles()

  if (memberships?.length === SINGLE_ORG) {
    return <></>
  }

  // fallbacks in case any data is missing
  const currentOrgDisplayName =
    defaultMembership?.org?.displayName || 'Unnamed Org'
  const currentOrgID = defaultMembership?.org?.id || 'No ID'

  return (
    <>
      <Divider variant="middle" />
      <MenuItem classes={{ root: classes.menuItem }}>
        <Accordion
          data-cy="OrgList"
          square={true}
          elevation={0}
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            {currentOrgDisplayName}
          </AccordionSummary>
          {memberships
            .slice()
            .sort((a, b) => sortByProperty(a.org, b.org, 'displayName'))
            .map(({ org }, i) => {
              const orgID = org?.id
              const orgDisplayName = org?.displayName
              return (
                <Box key={orgID}>
                  <Divider />
                  {orgID === currentOrgID ? (
                    <Box className={classes.falseMenuItem}>
                      <FiberManualRecordIcon
                        className={classes.accordionSelected}
                      />
                      {orgDisplayName}
                    </Box>
                  ) : (
                    <MenuItem
                      component="div"
                      onClick={() => onClickCallback(orgID, orgDisplayName)}
                    >
                      {orgDisplayName}
                    </MenuItem>
                  )}
                </Box>
              )
            })}
        </Accordion>
      </MenuItem>

      {error && (
        <NotificationBar open={error}>
          <Error message="There was an error switching your org. Please try again or contact support if the issue persists." />
        </NotificationBar>
      )}
    </>
  )
}

export default OrgList
