import { useEffect } from 'react'
import ReportSection from '../../components/ReportSection'
import { Stack } from 'ui/baseComponents/Stack'
import { SECTION_TITLES } from '../../consts'
import {
  HELPER_MOBILITY,
  HELPER_MOBILITY_EMPTY_STATE,
  MOBILITY_METRICS,
  SECTION_MOBILITY_EMPTY_STATE,
} from './consts'
import useMobilityAggregate from 'ui/hooks/summaryAggregates/useMobilityAggregate'
import { Text } from 'ui/baseComponents/Text'
import { Table } from 'ui/baseComponents/Table'
import ReportMetricTrend from '../../components/ReportMetricTrend'
import { getMobilityMetricProps } from 'ui/clinicianScreens/Patient/Report/sections/Mobility/helpers'

const ReportSectionMobility = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
}) => {
  const title = SECTION_TITLES.MOBILITY
  const { data, hasData, isLoading } = useMobilityAggregate(startDate, endDate)
  const { data: prevData, isLoading: prevIsLoading } = useMobilityAggregate(
    prevStartDate,
    prevEndDate,
  )

  const { stepLength, walkingSpeed, doubleSupportPercentage } = data || {}
  const {
    stepLength: prevStepLength,
    walkingSpeed: prevWalkingSpeed,
    doubleSupportPercentage: prevDoubleSupportPercentage,
  } = prevData || {}
  const loading = isLoading || prevIsLoading

  useEffect(() => {
    if (loading) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (!isLoading && data) {
      hasData ? addToHasDataSet(title) : removeFromHasDataSet(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasData])

  const stepLengthMetricProps = getMobilityMetricProps(
    stepLength,
    prevStepLength,
    MOBILITY_METRICS.stepLength.title,
  )
  const walkingSpeedMetricProps = getMobilityMetricProps(
    walkingSpeed,
    prevWalkingSpeed,
    MOBILITY_METRICS.walkingSpeed.title,
  )
  const doubleSupportPercentageMetricProps = getMobilityMetricProps(
    doubleSupportPercentage,
    prevDoubleSupportPercentage,
    MOBILITY_METRICS.doubleSupport.title,
    true,
  )

  const mobilityRowsData = [
    {
      title: MOBILITY_METRICS.stepLength.title,
      data: stepLengthMetricProps,
      goal: MOBILITY_METRICS.stepLength.goal,
    },
    {
      title: MOBILITY_METRICS.walkingSpeed.title,
      data: walkingSpeedMetricProps,
      goal: MOBILITY_METRICS.walkingSpeed.goal,
    },
    {
      title: MOBILITY_METRICS.doubleSupport.title,
      data: doubleSupportPercentageMetricProps,
      goal: MOBILITY_METRICS.doubleSupport.goal,
    },
  ]

  const formattedMobilityRows = mobilityRowsData.map((row) => {
    const { title, data, goal } = row
    const { value, trend, trendLabel, noPrevData, iconVariant } = data
    return [
      {
        key: `mobility-cell-metric-${title}`,
        value: <Text variant="body16B">{title}</Text>,
      },
      {
        key: `mobility-cell-value-${title}`,
        value: <Text variant="body16B">{value}</Text>,
      },
      {
        key: `mobility-cell-diff-${title}`,
        value: (
          <ReportMetricTrend
            {...{
              value,
              trend,
              trendLabel,
              noPrevData,
              iconVariant,
            }}
          />
        ),
      },
      {
        key: `mobility-cell-goal-${title}`,
        value: <Text variant="body16">{goal}</Text>,
      },
    ]
  })

  const mobilityHeadings = [
    { key: 'mobility-heading-metric', value: '' },
    { key: 'mobility-heading-value', value: '' },
    { key: 'mobility-heading-diff', value: '' },
    { key: 'mobility-heading-goal', value: 'Goal:' },
  ]

  return (
    <ReportSection
      title={title}
      subtitle="Data recorded by iPhone"
      hidden={
        hiddenSections.has(title) ||
        (isClinicianReport && !hasDataSet.has(title))
      }
    >
      <Stack spacing={2}>
        {hasData ? (
          <Table
            rows={formattedMobilityRows}
            headings={mobilityHeadings}
            tableCellProps={{ size: 'small' }}
          />
        ) : (
          SECTION_MOBILITY_EMPTY_STATE
        )}
        {!isClinicianReport &&
          (hasData ? HELPER_MOBILITY : HELPER_MOBILITY_EMPTY_STATE)}
      </Stack>
    </ReportSection>
  )
}

export default ReportSectionMobility
