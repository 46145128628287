import { Checkbox } from '@mui/material'
import { colors } from 'theme/colors'
import { Text } from 'ui/baseComponents/Text'

const OverviewChartToggle = ({ title, checked, onChange, sx }) => {
  const STYLES = {
    disabled: {
      color: colors.GREY[400],
    },
  }
  return (
    <span>
      <Checkbox
        checked={checked}
        onChange={onChange}
        sx={checked ? sx : STYLES.disabled}
      />
      {title && (
        <Text
          variant="body14"
          color={checked ? colors.GREY[900] : colors.GREY[400]}
        >
          {title}
        </Text>
      )}
    </span>
  )
}

export default OverviewChartToggle
