export const EDUCATIONAL_TEXT =
  'The arrows and percentage values provide insights into changes in your data compared with the previous period, with green indicating improvement and red signaling a significant change that may need attention. All the graphs are based on your timezone at report generation.'

export const NO_RED_FLAGS_TEXT =
  'No red flags reported this period. Red flags include: falls, dizziness/light-headedness/low blood pressure, hallucinations, OFF periods, and swallowing issues.'

export const ACTIVITY_RECOMMENDATION_MET_TEXT =
  'Patient is achieving 150 mins of exercise per week as recommended by the Parkinson’s Foundation in collaboration with the American College of Sports Medicine.'

export const ACTIVITY_RECOMMENDATION_NOT_MET_TEXT =
  'Patient is not achieving 150 mins of exercise per week as recommended by the Parkinson’s Foundation in collaboration with the American College of Sports Medicine.'

export const SLOW_WALKING_SPEED_TEXT =
  'Patient has a walking speed < 1m/s as measured by the iPhone. Walking speed below 1 m/s places an individual at a high risk of falls.'
