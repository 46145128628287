export const ASCENDING = 'asc'

export const DESCENDING = 'desc'

export const BEFORE = 1

export const AFTER = -1

/**
 * Case insensitive comparison-for-sort functionality.
 * @param {string} a First string to be compared
 * @param {string} b Second string to be compared
 * @param {string} order Direction of sort, either 'asc' or 'desc'.
 * @returns {number} Sort determination
 */
export const caseInsensitiveCompare = (a, b, order = ASCENDING) =>
  /* eslint-disable no-undefined */
  order === ASCENDING
    ? a.localeCompare(b, undefined, { sensitivity: 'base' })
    : b.localeCompare(a, undefined, { sensitivity: 'base' })
/* eslint-enable no-undefined */

/**
 * Case insensitive object sorting by object property.
 * @param {object} a First object to be compared
 * @param {object} b Second object to be compared
 * @param {string} property Name of object property to be compared
 * @param {string} order Direction of sort, either 'asc' or 'desc'.
 * @returns {number} Sort determination
 */
export const sortByProperty = (a, b, property, order = ASCENDING) => {
  const { [property]: aProperty } = a || {}
  const { [property]: bProperty } = b || {}

  return caseInsensitiveCompare(aProperty, bProperty, order)
}
