import { Box, makeStyles, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { useStreamApi } from 'domains/streamApi'
import { useLogger } from 'domains/logger'
import moment from 'moment'
import { useCallback, useContext } from 'react'
import { PatientContext } from 'ui/contexts'
import { useDevices } from 'ui/contexts/PatientContext'
import { SECOND_IN_MILLISECONDS } from 'utilities/time'
import Loading from '../Loading'
import SingleFilter from '../SingleFilter'

const useStyles = makeStyles((theme) => ({
  error: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  errorIcon: {
    marginRight: theme.spacing(1),
  },
}))

const EventPicker = ({ eventId, setEvent }) => {
  const { id } = useContext(PatientContext)
  const devices = useDevices()
  const logger = useLogger()
  const { data, error, loading } = useStreamApi({
    url: 'event.json',
    params: {
      patient_id: id,
      event: 'device.implant',
    },
  })
  const classes = useStyles()
  const { result = {} } = data || {}
  const { event: events = [] } = result
  const handleChange = useCallback(
    (eventId) => {
      const event = events.find((event) => event.id === eventId)
      setEvent({ ...event, time: event.time * SECOND_IN_MILLISECONDS })
    },
    [events, setEvent],
  )
  if (loading) {
    return (
      <Box m={2}>
        <Loading />
      </Box>
    )
  }
  if (error) {
    logger.error(error)
    return (
      <span className={classes.error}>
        <ErrorIcon className={classes.errorIcon} />
        <Typography variant="p">Unable to load Patient events</Typography>
      </span>
    )
  }

  // Map events and devices to a list of options
  const options = events
    .map((event) => {
      const { device_id } = event
      // Stream API device `id` is the GraphQL `deviceShortId`
      const device = devices.find((d) => d.deviceShortId === device_id)
      return {
        device,
        event,
      }
    })
    // remove any events which had no matching devices
    .filter(({ device }) => Boolean(device))
    .map(({ device, event }) => {
      const deviceName = device.alias || device.deviceShortId || device.id
      // Backend timestamps are in seconds, JS is millis
      const dateStr = moment(event.time * SECOND_IN_MILLISECONDS).format(
        'YYYY/MM/DD',
      )
      return {
        value: event.id,
        name: `${deviceName} Implant - ${dateStr}`,
      }
    })

  return (
    <SingleFilter
      label="Relative to Event"
      title="Show dates relative to a particular event"
      selected={eventId}
      setSelected={handleChange}
      options={options}
    />
  )
}
export default EventPicker
