import { getRoundedPercentChange, isAtOrBeyondThreshold } from '../../helpers'

export const getMobilityIconVariant = ({ trend, invertColors = false }) => {
  if (isAtOrBeyondThreshold(trend)) {
    if (trend > 0) {
      return invertColors ? 'worse' : 'better'
    } else if (trend < 0) {
      return invertColors ? 'better' : 'worse'
    }
  }
  return null
}

export const formatMobilityDiff = (metric, prevMetric) => {
  const diff = metric?.averageValue - prevMetric?.averageValue
  let label = ''
  if (diff < 0) {
    label = 'less'
  } else if (diff > 0) {
    label = 'more'
  }
  return `${Math.abs(diff.toFixed(2))}${metric?.averageUnit} ${label}`
}

export const getMobilityMetricProps = (
  currentData,
  prevData,
  label,
  invertColors,
) => {
  if (!currentData?.averageValue)
    return {
      label,
      value: '—',
      trend: 0,
      trendLabel: '',
    }

  const value = `${currentData?.averageValue}${currentData?.averageUnit}`
  const trend = getRoundedPercentChange(
    currentData?.averageValue,
    prevData?.averageValue,
  )
  const trendLabel = formatMobilityDiff(currentData, prevData)
  const noPrevData = !prevData?.averageValue
  const iconVariant = getMobilityIconVariant({ trend, invertColors })

  return {
    label,
    value,
    trend,
    trendLabel,
    noPrevData,
    iconVariant,
  }
}
