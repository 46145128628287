import { useEffect, useMemo } from 'react'
import ReportSection from 'ui/clinicianScreens/Patient/Report/components/ReportSection'
import {
  formatAverage,
  formatDiff,
} from 'ui/clinicianScreens/Patient/Report/helpers'
import {
  defaultAverage,
  getAverageDiff,
} from 'ui/clinicianScreens/Patient/Report/sections/TremorAndDyskinesia/helpers'
import {
  DATA_ATTRIBUTES,
  SECTION_TITLES,
} from 'ui/clinicianScreens/Patient/Report/consts'
import { HELPER_DYSKINESIA, HELPER_DYSKINESIA_CLINICIAN } from './consts'
import ReportChartDyskinesia from 'ui/clinicianScreens/Patient/Report/sections/TremorAndDyskinesia/Dyskinesia/ReportChartDyskinesia'

const ReportSectionDyskinesia = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
  data,
  prevData,
  loading,
}) => {
  const title = SECTION_TITLES.DYSKINESIA
  const { dyskinesia: dyskinesiaDataAttributes } = DATA_ATTRIBUTES
  const {
    noDyskinesiaDataAttribute,
    dyskinesiaChartPreviousAttribute,
    dyskinesiaChartRecentAttribute,
  } = dyskinesiaDataAttributes

  useEffect(() => {
    if (loading) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  let dyskinesiaAverage = { ...defaultAverage }

  let prevDyskinesiaAverage = { ...defaultAverage }

  if (data) {
    dyskinesiaAverage = formatAverage(data)
  }

  if (prevData) {
    prevDyskinesiaAverage = formatAverage(prevData)
  }

  const dyskinesiaAverageDiff = getAverageDiff(
    dyskinesiaAverage,
    prevDyskinesiaAverage,
  )
  const hasDyskinesiaData = data?.numberOfDaysWithData > 0
  const hasPrevDyskinesiaData = prevData?.numberOfDaysWithData > 0

  useEffect(() => {
    hasDyskinesiaData ? addToHasDataSet(title) : removeFromHasDataSet(title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDyskinesiaData])

  const attributesForPlaywrightScript = useMemo(() => {
    const expectedAttributes = []

    if (!hasDyskinesiaData) {
      expectedAttributes.push(
        noDyskinesiaDataAttribute.playwrightScriptSelector,
      )
    }

    if (hasDyskinesiaData) {
      expectedAttributes.push(
        dyskinesiaChartRecentAttribute.playwrightScriptSelector,
      )
    }

    if (hasDyskinesiaData && hasPrevDyskinesiaData) {
      expectedAttributes.push(
        dyskinesiaChartPreviousAttribute.playwrightScriptSelector,
      )
    }

    return expectedAttributes
  }, [hasDyskinesiaData, hasPrevDyskinesiaData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReportSection
      {...{
        hidden:
          hiddenSections.has(title) ||
          (isClinicianReport && !hasDataSet.has(title)),
        noBorder: true,
        ...(!hasDyskinesiaData && {
          [`data-${noDyskinesiaDataAttribute.carrotWebSuffix}`]: true,
        }),
        ...{
          'data-expected-dyskinesia-attributes': attributesForPlaywrightScript,
        },
      }}
    >
      <ReportChartDyskinesia
        {...{
          currentData: data,
          prevData,
          onLoadStart,
          onLoadEnd,
          diff: formatDiff(dyskinesiaAverageDiff),
        }}
      />
      {hasDyskinesiaData &&
        (isClinicianReport ? HELPER_DYSKINESIA_CLINICIAN : HELPER_DYSKINESIA)}
    </ReportSection>
  )
}

export default ReportSectionDyskinesia
