import { org } from './org'
export * from './org'
export * from './patients'
export * from './users'

// Put these here to avoid circular dependencies
export const adminMembership = {
  id: 'admin-membership',
  admin: true,
  org,
}
export const nonAdminMembership = {
  id: 'non-admin-membership',
  admin: false,
  org,
}
export const tokens = {
  accessTokens: [],
  pageInfo: {
    endCursor: null,
  },
}
export const admin = {
  id: 'user-zero',
  name: 'Admin User',
  email: 'userzero@gmail.com',
  username: 'userzero',
  accessTokenList: tokens,
  defaultMembership: adminMembership,
}
export const user = {
  id: 'user-one',
  name: 'User One',
  email: 'userone@gmail.com',
  username: 'userone',
  defaultMembership: nonAdminMembership,
}
export { DEVICE_TYPE_STRIVE_STUDY } from '../../../../ui/screens/EditPatient/Devices/consts'
export { DEVICE_TYPE_APPLE_WATCH } from '../../../../ui/screens/EditPatient/Devices/consts'
