import { createContext, useCallback, useContext, useState } from 'react'
import moment from 'moment-timezone'
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates'
import { DATE_FORMAT } from 'ui/consts'
import { ADD, getDateRange, SUBTRACT } from 'utilities/time'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

export const DateRangePickerContext = createContext({})
export const DATE_RANGE_ERROR_MESSAGE =
  'Selected date range is invalid. Please verify the selected dates.'

const useDateRangePickerContext = (
  fromProp,
  toProp,
  minimumDate,
  maximumDate,
  maxDays,
  setDateRange,
  autoSelectDayCount,
  autoSelectEndDate,
) => {
  const { selectedTimezone } = useContext(DateRangeContext) || moment.tz.guess()
  const [focusedInput, setFocusedInput] = useState()
  const [from, setFrom] = useState(fromProp)
  const [to, setTo] = useState(toProp)
  const [errorMessage, setErrorMessage] = useState('')

  const getFirstVisibleMonthStartDate = (startDate) =>
    moment.tz(startDate, selectedTimezone).startOf('month').format('YYYY-MM-DD')

  const getSecondVisibleMonthEndDate = (startDate) =>
    moment
      .tz(startDate, selectedTimezone)
      .add(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')

  const getThirdVisibleMonthEndDate = (startDate) =>
    moment
      .tz(startDate, selectedTimezone)
      .add(2, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')

  const [firstVisibleMonthStartDate, setFirstVisibleMonthStartDate] = useState(
    getFirstVisibleMonthStartDate(from),
  )
  const [secondVisibleMonthEndDate, setSecondVisibleMonthEndDate] = useState(
    getSecondVisibleMonthEndDate(from),
  )
  const [thirdVisibleMonthEndDate, setThirdVisibleMonthEndDate] = useState(
    getThirdVisibleMonthEndDate(from),
  )

  const onLeftArrowClick = (e) => {
    e && e.stopPropagation()
    const { from: newFrom, to: newTo } = getDateRange({
      from,
      to,
      operation: SUBTRACT,
      minimumDate,
      maximumDate,
    })
    setDateRange({ from: newFrom, to: newTo })
    setFrom(newFrom)
    setTo(newTo)
  }

  const onRightArrowClick = (e) => {
    e && e.stopPropagation()
    const { from: newFrom, to: newTo } = getDateRange({
      from,
      to,
      operation: ADD,
      minimumDate,
      maximumDate,
    })
    setDateRange({ from: newFrom, to: newTo })
    setFrom(newFrom)
    setTo(newTo)
  }

  const onDatePickerDatesChange = ({ startDate, endDate }) => {
    const newFrom = moment(startDate).isValid()
      ? moment.tz(startDate, selectedTimezone).format(DATE_FORMAT)
      : null
    const newTo = moment(endDate).isValid()
      ? moment.tz(endDate, selectedTimezone).format(DATE_FORMAT)
      : null
    setFrom(newFrom)
    setTo(newTo)

    if (autoSelectEndDate && startDate) {
      const start = moment.tz(startDate, selectedTimezone)
      const newTo = start.clone().add(autoSelectDayCount, 'days')
      const maxDate = moment.tz(maximumDate, selectedTimezone)

      if (
        (maximumDate && moment(newTo).isAfter(maxDate)) ||
        moment(newTo).isAfter(today)
      ) {
        setErrorMessage(DATE_RANGE_ERROR_MESSAGE)
        return
      } else {
        setErrorMessage('')
      }
    }
  }

  const today = moment.tz(selectedTimezone).startOf('day')
  const initialVisibleMonth = useCallback(
    () =>
      from ? moment.tz(from, selectedTimezone) : moment.tz(selectedTimezone),
    [from, selectedTimezone],
  )
  const isOutsideRange = useCallback(
    (day) => {
      // prevent days before minimum date
      if (
        minimumDate &&
        isInclusivelyBeforeDay(day, moment.tz(minimumDate, selectedTimezone))
      ) {
        return true
      }

      // prevent days after maximum date
      if (
        maximumDate &&
        isInclusivelyAfterDay(day, moment.tz(maximumDate, selectedTimezone))
      ) {
        return true
      }

      // prevent all future dates
      if (!isInclusivelyBeforeDay(day, moment.tz(selectedTimezone))) {
        return true
      }
      const duration = moment(day).diff(from, 'days')
      // Limit the window when the user has picked a From
      return from && duration >= maxDays
    },
    [from, today, maxDays], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    to,
    setTo,
    from,
    setFrom,
    getFirstVisibleMonthStartDate,
    getSecondVisibleMonthEndDate,
    getThirdVisibleMonthEndDate,
    firstVisibleMonthStartDate,
    secondVisibleMonthEndDate,
    thirdVisibleMonthEndDate,
    setFirstVisibleMonthStartDate,
    setSecondVisibleMonthEndDate,
    setThirdVisibleMonthEndDate,
    minimumDate,
    maximumDate,
    focusedInput,
    setFocusedInput,
    onLeftArrowClick,
    onRightArrowClick,
    onDatePickerDatesChange,
    initialVisibleMonth,
    isOutsideRange,
    setDateRange,
    errorMessage,
    setErrorMessage,
  }
}

export default useDateRangePickerContext
