export const Z_INDEX_LOADER_SHIM_CLOSED = 0
export const Z_INDEX_DRAWER = 0 // overriding the default MUI drawer z-index value
export const Z_INDEX_SLIDER_TOOLTIP = 0

export const Z_INDEX_MONTH_BITMAP_MONTH_NAME = 100
export const Z_INDEX_ADDITIONAL_INFO_CONTAINER = 100
export const Z_INDEX_APP_FOOTER = 100

export const Z_INDEX_COOKIE_BANNER = 200

export const Z_INDEX_LOADER_SHIM_OPEN = 1000
export const Z_INDEX_EXPLORER_BITMAP_STICKY = 1000

export const Z_INDEX_STREAM_NAME_CONTAINER = 2000
export const Z_INDEX_STYLED_TOOLTIP_TEXT = 0
export const Z_INDEX_STYLED_TOOLTIP_HIGHLIGHT = -1
