import { useContext } from 'react'
import moment from 'moment'
import {
  Box,
  InputLabel,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import StreamSelector from './StreamSelector'
import StreamsContext from './StreamSelector/StreamsContext'
import PatientBreadcrumbs from 'ui/components/Breadcrumbs/PatientBreadcrumbs'
import PersistentViews from './PersistentViews'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import CopyTextIcon from 'ui/components/CopyText/CopyTextIcon'

const useStyles = makeStyles((theme) => ({
  browseControls: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  date: {
    float: 'right',
    display: 'inline-block',
  },
  controlsPaper: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(3),
    minWidth: '480px',
    width: 'min-content',
    flexGrow: 1,
    '@media (max-width: 1358px)': {
      marginRight: 0,
    },
  },
  streamsPaper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    flexGrow: 1,
    minWidth: '800px',
  },
  streamsTitle: {
    display: 'inline-block',
  },
}))

/**
 * Controls for browsing patient data.
 */
const BrowseControls = ({ patient, tStart, tEnd }) => {
  const { streams } = useContext(StreamsContext)
  const classes = useStyles()

  return (
    <ErrorBoundary>
      <Box className={classes.browseControls}>
        <Paper className={classes.controlsPaper}>
          <Box>
            <PatientBreadcrumbs
              id={patient.id}
              codeName={patient.codeName}
              currentPageName="Browse"
            />
          </Box>
          <Box mb={2} ml={2}>
            <InputLabel shrink={true}>Patient ID</InputLabel>
            <CopyTextIcon value={patient.id} />
          </Box>
          <PersistentViews patient={patient} streams={streams} />
        </Paper>
        <Paper className={classes.streamsPaper}>
          <Box mt={2} mx={3}>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.streamsTitle}
            >
              Streams
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
              className={classes.date}
            >
              {/*TODO JS uses milliseconds for timestamps, backend uses seconds. //
              Previously we used JS standard in JS code, only converting at
              request/response time. // We probably want to go back to this at some
              point so there's no ambiguity, but we'll // document whichever we
              move towards*/}
              {moment(tStart * 1000).format('MMM D, YYYY')}
            </Typography>
          </Box>
          <StreamSelector />
        </Paper>
      </Box>
    </ErrorBoundary>
  )
}

export default BrowseControls
