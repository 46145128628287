import { Link } from 'ui/baseComponents/Link'
import { Text } from 'ui/baseComponents/Text'
import { VIDEO_LINKS } from 'ui/clinicianScreens/Patient/Report/consts'

export const MOBILITY_METRICS = {
  doubleSupport: {
    title: 'Double Support',
    goal: 'Keep double support percentage below 40%',
  },
  stepLength: {
    title: 'Step Length',
    goal: 'Practice taking longer steps to improve your gait',
  },
  walkingSpeed: {
    title: 'Walking Speed',
    goal: 'Aim for a walking speed of 1.0m/s or higher',
  },
}

export const MOBILITY_EMPTY_STATE_TEXT =
  'No data on step length, walking speed and double support percentage is available for recent period.'

export const MOBILITY_HELPER_TEXT = {
  emptyState:
    'Mobility data is collected from the iPhone (you do not need an Apple Watch). For the most accurate mobility data, Apple suggests keeping your iPhone in your front pocket during walking periods. This will collect more accurate step length, walking speed, and other gait metrics. Make sure to set up your Health profile in the Apple Health App on your iPhone for this data to be collected. Helpful link to set up Health profile:',
  understandMore:
    "Mobility is an important metric to consider for those living with Parkinson's. To understand more about each mobility metric and its associated goals,",
}

export const APPLE_LINK_USE_THE_HEALTH_APP =
  'https://support.apple.com/en-us/104997'

export const SECTION_MOBILITY_EMPTY_STATE = (
  <Text variant="body16" component="p" data-cy="mobility-section-no-data">
    {MOBILITY_EMPTY_STATE_TEXT}
  </Text>
)

export const HELPER_MOBILITY = (
  <Text variant="body14" component="p" data-cy="mobility-helper">
    {MOBILITY_HELPER_TEXT.understandMore}{' '}
    <Link href={VIDEO_LINKS.whatIsMobility} external underline light>
      watch our video
    </Link>
    .
  </Text>
)

export const HELPER_MOBILITY_EMPTY_STATE = (
  <Text variant="body14" component="p" data-cy="mobility-helper-no-data">
    {MOBILITY_HELPER_TEXT.emptyState}{' '}
    <Link href={APPLE_LINK_USE_THE_HEALTH_APP} external underline light>
      Apple Health
    </Link>
  </Text>
)
