import { createElement } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { SentimentVeryDissatisfiedTwoTone } from '@material-ui/icons'

const DEFAULT_ERROR_MESSAGE = `
    Oops! Something preposterous occurred. Please refresh your browser, or
    contact support@runelabs.io if the issue persists.
`
const useStyles = makeStyles(() => ({
  icon: {
    height: '8rem',
    opacity: 0.3,
    width: '8rem',
    marginBottom: '1rem',
  },
  canvas: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 4rem',
    width: '100%',
  },
}))

/**
 * @param {*} icon, The icon we want to display with the error message.
 * @param {string} message, The error message to display. If no message is provided, a default message will be displayed.
 * @returns {JSX.Element} A div with an icon and an error message.
 */
const ErrorMessage = ({
  icon = SentimentVeryDissatisfiedTwoTone,
  message = DEFAULT_ERROR_MESSAGE,
}) => {
  const classes = useStyles()
  const ErrorIcon = (props) => createElement(icon, props)

  return (
    <div className={classes.canvas} data-testid="errorMessage">
      <ErrorIcon classes={{ root: classes.icon }} />
      <Typography>{message}</Typography>
    </div>
  )
}

export default ErrorMessage
