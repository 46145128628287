import { colors } from 'theme/colors'
import ReportBarChart from '../../../components/ReportBarChart'

const ReportChartDyskinesia = ({ currentData, prevData, diff }) => (
  <ReportBarChart
    {...{
      title: 'Dyskinesia',
      currentData,
      prevData,
      diff,
      recentPeriodColor: colors.PRIMARY[1000],
      previousPeriodColor: colors.PRIMARY[700],
    }}
  />
)

export default ReportChartDyskinesia
