import { useCallback } from 'react'
import Loading from 'ui/components/Loading'
import PropTypes from 'prop-types'
import { UPDATE_TASK_NOTIFICATION } from './mutations'
import { useMutation } from '@apollo/client'
import { useRefetchTaskNotifications } from './queries'
import {
  FormControlLabel,
  Switch,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import { RUNE_GRAY_900 } from 'theme/colors.js'

const useStyles = makeStyles((theme) => ({
  statusPadding: {
    paddingLeft: theme.spacing(4),
  },
  statusLabel: {
    fontWeight: 500,
    fontSize: '0.75rem',
  },
}))

const AntSwitch = withStyles((theme) => ({
  root: {
    width: '1.75rem',
    height: '1rem',
    padding: 0,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
  },
  switchBase: {
    padding: 2,
    color: theme.palette.primary.contrastText,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.primary.contrastText,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: '0.75rem',
    height: '0.75rem',
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${RUNE_GRAY_900}`,
    borderRadius: '0.5rem',
    opacity: 1,
    backgroundColor: RUNE_GRAY_900,
  },
  checked: {},
}))(Switch)

/**
 * The task toggle component for changing a single task status
 *
 * @return {JSX.Element} The task toggle component for changing a single task status
 */
const TaskToggle = ({ id, label, inactive }) => {
  const classes = useStyles()
  const [toggleTaskNotification, { loading }] = useMutation(
    UPDATE_TASK_NOTIFICATION,
    {
      refetchQueries: [useRefetchTaskNotifications()],
      awaitRefetchQueries: true,
    },
  )
  const handleToggle = useCallback(async () => {
    try {
      const input = { id: id, disabled: !inactive }
      await toggleTaskNotification({ variables: { input } })
    } catch (error) {
      console.error(error)
    }
  }, [id, inactive, toggleTaskNotification])

  if (loading) {
    return <Loading />
  }

  return (
    <FormControlLabel
      className={classes.statusPadding}
      control={<AntSwitch checked={!inactive} onChange={handleToggle} />}
      label={<Typography className={classes.statusLabel}>{label}</Typography>}
    />
  )
}

TaskToggle.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inactive: PropTypes.bool.isRequired,
}

export default TaskToggle
