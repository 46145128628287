import NavLink from '../../NavLink'
import { object, string } from 'prop-types'
import { Breadcrumbs } from '@mui/material'
import { Text } from 'ui/baseComponents/Text'
import { RUNE_BLACK, RUNE_GRAY_500 } from 'theme/colors'
import { patientName } from 'utilities/string'

const ClinicianPortalBreadcrumbs = ({
  patient,
  currentPageName,
  navLinkText = 'All Patients',
}) => {
  const separator = (
    <Text variant="head42L" color={RUNE_GRAY_500}>
      /
    </Text>
  )
  const breadcrumbs = [
    <NavLink
      to="/patients"
      data-cy="patient-breadcrumbs-patients-list-link"
      key="allPatients"
    >
      <Text variant="head42L">{navLinkText}</Text>
    </NavLink>,
  ]
  if (patient) {
    const name = patientName(patient).withLastFirst(true) || 'Patient'

    breadcrumbs.push(
      <Text variant="head42L" key="formattedName">
        {name}
      </Text>,
      <Text variant="head42" key="currentPageName" data-cy="current-page-name">
        {currentPageName}
      </Text>,
    )
  }

  return (
    <Breadcrumbs
      separator={separator}
      aria-label="breadcrumb"
      sx={{ color: RUNE_BLACK }}
    >
      {breadcrumbs}
    </Breadcrumbs>
  )
}

ClinicianPortalBreadcrumbs.propTypes = {
  patient: object,
  currentPageName: string.isRequired,
}

export default ClinicianPortalBreadcrumbs
