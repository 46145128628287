import { useCallback, useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { formatDateTime, isOlderThanNDaysAgo } from 'utilities/time'
import { UPDATE_ACCESS_TOKEN } from '../mutations'
import { useRefetchAccessTokenList } from '../queries'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import ErrorIcon from '@material-ui/icons/Error'
import { NotificationBar, Error } from 'ui/components/Notifications'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TableRow,
  TableCell,
  Tooltip,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  disabled: {
    background: '#f5f5f5',
  },
  disabledCell: {
    color: 'rgba(0,0,0,0.38)',
  },
  warning: {
    position: 'relative',
    top: '0.3rem',
    right: '0.3rem',
  },
}))

/**
 * Component for displaying a single row of access token info.
 * Has a switch to toggle enabled state
 *
 * @param {string} id The long ID of the token
 * @param {string} accessTokenId The ID the user will use in their code
 * @param {float} createdAt Token creation timestamp
 * @param {float} usedAt Token last used timestamp
 * @param {boolean} disabled Token's status
 * @param {boolean} tokenLimitReached true when 4 tokens are enabled, used to disable activation of additional tokens
 */
const AccessTokenRow = ({
  id,
  accessTokenId,
  createdAt,
  usedAt,
  disabled: disabledProp,
  tokenLimitReached,
}) => {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(disabledProp)
  const [error, setError] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [updateToken, { loading }] = useMutation(UPDATE_ACCESS_TOKEN, {
    onError: (error) => {
      console.error(error)
      setError('There was an error when updating your token')
    },
    refetchQueries: [useRefetchAccessTokenList()],
    awaitRefetchQueries: true,
  })

  // sets the correct status on list refetch
  useEffect(() => {
    setDisabled(disabledProp)
  }, [disabledProp])

  /**
   * Allows the error notification to be manually closed.
   */
  const handleCloseNotification = () => {
    setError(false)
  }

  /**
   * Opens the <Menu> component to display options
   *
   * @param {event} event the click event
   */
  const handleMenuClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  /**
   * Closes the <Menu> component
   */
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  /**
   * Sends a request to enable or disable an access token
   *
   * @param {boolean} disabled The requested status state to update
   */
  const toggleToken = useCallback(
    async (disabled) => {
      handleCloseMenu()
      const input = {
        accessTokenId: id,
        disabled,
      }
      await updateToken({ variables: { input } })
      setDisabled(disabled)
      setError(false)
    },
    [id, updateToken, handleCloseMenu],
  )
  const tokenViewTimeFormat = 'M/D/Y h:mm a'
  const lastUsed = usedAt
    ? formatDateTime(usedAt, tokenViewTimeFormat)
    : 'Never'
  const status = disabledProp ? 'Inactive' : 'Active'
  const rowStatusClass = disabledProp ? classes.disabled : ''
  const cellStatusClass = disabledProp ? classes.disabledCell : ''
  const switchDisabled = tokenLimitReached && disabled
  const tooltipText = switchDisabled
    ? 'For security reasons, you may have at most 4 active tokens at one time.'
    : ''

  // includes a tooltip and icon if createdAt > 180 days
  const created =
    isOlderThanNDaysAgo(createdAt, 180) && !disabledProp ? (
      <Box>
        <Tooltip title="This access token is more than 180 days old. For security, we recommend you create new tokens every 3-6 months and deactivate the old.">
          <Box>
            <ErrorIcon
              fontSize="small"
              color="error"
              className={classes.warning}
            />
            {formatDateTime(createdAt, tokenViewTimeFormat)}
          </Box>
        </Tooltip>
      </Box>
    ) : (
      <Box>{formatDateTime(createdAt, tokenViewTimeFormat)}</Box>
    )

  return (
    <ErrorBoundary>
      <TableRow className={rowStatusClass}>
        <TableCell className={cellStatusClass}>{accessTokenId}</TableCell>
        <TableCell className={cellStatusClass}>{created}</TableCell>
        <TableCell className={cellStatusClass}>{lastUsed}</TableCell>
        <TableCell className={cellStatusClass}>
          {loading ? 'Pending' : status}
        </TableCell>
        <TableCell>
          <IconButton
            data-cy="tokens-more-options-button"
            aria-label={'more options for token ' + accessTokenId}
            aria-controls={'more-options-token-' + accessTokenId}
            aria-haspopup="true"
            disabled={loading}
            onClick={handleMenuClick}
          >
            <MoreVert />
          </IconButton>

          <Menu
            id={'more-options-token-' + accessTokenId}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            {disabled ? (
              <Tooltip title={tooltipText}>
                <Box>
                  <MenuItem
                    onClick={() => toggleToken(!disabled)}
                    disabled={switchDisabled}
                  >
                    Activate
                  </MenuItem>
                </Box>
              </Tooltip>
            ) : (
              <MenuItem
                onClick={() => toggleToken(!disabled)}
                disabled={switchDisabled}
              >
                Deactivate
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      </TableRow>
      <NotificationBar open={error} onClose={handleCloseNotification}>
        <Error message={error} onClose={handleCloseNotification} />
      </NotificationBar>
    </ErrorBoundary>
  )
}

export default AccessTokenRow
