import { useCallback, useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { UPDATE_USER_MUTATION } from 'domains/carrotGraph/mutations'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { NotificationBar, Success } from 'ui/components/Notifications'
import SubmitButton from 'ui/components/SubmitButton/SubmitButton'
import UserContext from 'ui/contexts/UserContext'
import { useRefetchMe } from 'ui/screens/Root'
import { formatDateTime } from 'utilities/time'
import ContentWrapper from '../ContentWrapper'

const SPACING_VALUE_ONE = 1
const SPACING_VALUE_TWO = 2
const SPACING_VALUE_FOUR = 4

const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: 120,
  },
  cancelButton: {
    marginLeft: theme.spacing(SPACING_VALUE_ONE),
  },
  column1: {
    fontWeight: 700,
  },
  editButtonGroup: {
    position: 'absolute',
    right: '-10rem',
    top: 0,
  },
  editCell: {
    position: 'relative',
    paddingTop: theme.spacing(SPACING_VALUE_ONE),
    paddingBottom: theme.spacing(SPACING_VALUE_ONE),
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  iconButton: {
    position: 'absolute',
    right: '0.75rem',
    bottom: '0.75rem',
  },
  iconCell: {
    position: 'relative',
    paddingRight: theme.spacing(SPACING_VALUE_FOUR),
  },
  nameEditInput: {
    fontSize: '0.875rem',
  },
  tableBox: {
    maxWidth: 500,
  },
  warning: {
    margin: theme.spacing(SPACING_VALUE_TWO),
    maxWidth: 400,
  },
}))

/**
 * Component for the user's profile page, which displays their basic personal
 * and org information, as well as allowing them to edit their name. Needs no
 * props because it takes its info from the user context which is globally available.
 * @returns {JSX.Element} User Profile Information, including, Name (editable), Email, Default Organization, and Account Created Date.
 */
const Profile = () => {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const { displayName, email, created, defaultMembership } =
    useContext(UserContext)
  const [success, setSuccess] = useState(false)
  const [timeoutId, setTimeoutId] = useState(false)
  const [newName, setNewName] = useState(displayName)
  const [updatedName, setUpdatedName] = useState(displayName)
  const [error, setError] = useState()
  const [updateUser, { loading }] = useMutation(UPDATE_USER_MUTATION, {
    onError: (error) => {
      console.error(error)
      setError('Unable to update user')
    },
    refetchQueries: [useRefetchMe()],
  })

  /**
   * Name edit submit function that displays success or error after completion.
   * Toggles back to the non-editing state if the new name is the old name.
   */
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      clearTimeout(timeoutId)

      // if no change was made
      if (updatedName === newName) {
        setEditing(false)
      } else {
        try {
          const input = {
            displayName: newName,
          }
          const response = await updateUser({
            variables: {
              input,
            },
          })
          const returnedName = response.data.updateUser.user.displayName
          const TWO_SECONDS = 2000
          setSuccess(true)
          setEditing(false)
          setNewName(returnedName)
          setUpdatedName(returnedName)
          setTimeoutId(setTimeout(() => setSuccess(false), TWO_SECONDS))
        } catch (error) {
          console.error(error)
          const errorMessage = 'Unable to update User'
          setError(errorMessage)
        }
      }
    },
    [newName, timeoutId, updateUser, updatedName],
  )

  // Fallback in case of org: null
  const orgDisplayName =
    defaultMembership &&
    defaultMembership.org &&
    defaultMembership.org.displayName
      ? defaultMembership.org.displayName
      : 'N/A'

  return (
    <ErrorBoundary>
      {success && (
        <NotificationBar open={success}>
          <Success message="Name successfully updated" />
        </NotificationBar>
      )}
      <ContentWrapper>
        <Typography variant="h5" component="h1">
          Profile
        </Typography>

        <Box className={classes.tableBox} pt={2}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell align="left" className={classes.column1}>
                  Name
                </TableCell>
                {editing ? (
                  <TableCell align="left" className={classes.editCell}>
                    <TextField
                      id="nameEdit"
                      data-cy="nameEdit"
                      inputProps={{ className: classes.nameEditInput }}
                      size="small"
                      fullWidth
                      autoFocus
                      error={!!error}
                      helperText={error}
                      disabled={loading}
                      label={false}
                      value={newName}
                      onChange={(e) => setNewName(e.target.value)}
                    />
                    <div className={classes.editButtonGroup}>
                      <SubmitButton
                        classes={classes}
                        icon={null}
                        success={success}
                        loading={loading}
                        successLabel="saved"
                        submitLabel="save"
                        onClick={onSubmit}
                      />
                      <Button
                        onClick={() => setEditing(false)}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </Button>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell align="left" className={classes.iconCell}>
                    <span data-cy="userName">{updatedName}</span>
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => setEditing(true)}
                      size="small"
                      data-cy="editNameButton"
                    >
                      <EditIcon className={classes.icon} />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>

              <TableRow>
                <TableCell align="left" className={classes.column1}>
                  Email
                </TableCell>
                <TableCell align="left" data-cy="userEmail">
                  {email}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" className={classes.column1}>
                  Default Organization
                </TableCell>
                <TableCell align="left" data-cy="userOrgDisplayName">
                  {orgDisplayName}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" className={classes.column1}>
                  Account Created
                </TableCell>
                <TableCell align="left" data-cy="userJoined">
                  {formatDateTime(created, 'l')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </ContentWrapper>
    </ErrorBoundary>
  )
}

export default Profile
