import { memo } from 'react'

import { APPLE_MOBILITY_METRICS } from './consts'
import { OpenLinkInNewTab } from 'ui/components/OpenLinkInNewTab'

const MobilityMetricsLink = () => (
  <OpenLinkInNewTab
    link={APPLE_MOBILITY_METRICS.url}
    text={APPLE_MOBILITY_METRICS.linkText}
  />
)

export default memo(MobilityMetricsLink)
