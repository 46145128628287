import React from 'react'
import { getHexColor } from 'utilities'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
    display: 'inline-flex',
    verticalAlign: 'bottom',
    textTransform: 'capitalize',
  },
}))

/**
 * An icon with text
 */
const IconText = ({ iconColor, icon, text, variant, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const Icon = icon

  return (
    <Typography className={classes.root} variant={variant} {...props}>
      <Icon style={{ color: getHexColor(iconColor || 'primary', theme) }} />
      <Box component="span" pl={variant === 'caption' ? 0 : 2}>
        {text}
      </Box>
    </Typography>
  )
}

IconText.propTypes = {
  /**
   * the icon
   */
  icon: PropTypes.shape({}),
  /**
   * Icon color (e.g. primary, secondary, blue, etc)
   */
  iconColor: PropTypes.string,
  /**
   * the text
   */
  text: PropTypes.string,
  /**
   * variant for text
   */
  variant: PropTypes.oneOf(['caption', 'h6', 'h5', 'h4', 'h3', 'body1']),
}

IconText.defaultProps = {
  icon: DotIcon,
  iconColor: null,
  text: 'Note',
  variant: 'caption',
}

export default IconText
