import CognitoUserPoolContext from 'ui/contexts/CognitoUserPoolContext'
import { getDownloadUrl } from 'domains/streamApi/download'
import { useStreamApiClient } from 'domains/streamApi/context'
import { useContext } from 'react'

/**
 * Function that formats and returns an array of download format options for
 * each data stream.
 *
 * @param {string} streamName The name of the stream, used in the download file name
 * @param {string} endpointName The endpoint to send the request
 * @param {string} patientID The ID of the patient whose data is being downloaded
 * @param {string} deviceID The ID of the device supplying the data
 * @param {float} startTime The start time of the data range to be downloaded
 * @param {float} endTime The end time of the data range to be downloaded
 * @param {object} query Additional query params for the request
 * @returns {object[]} An array of download format options
 */
export const useDownloadOptions = ({
  streamName,
  endpointName,
  patientID,
  deviceID,
  startTime,
  endTime,
  query,
}) => {
  const streamApiClient = useStreamApiClient()
  const cognitoUserPool = useContext(CognitoUserPoolContext)
  return [
    {
      key: 'csv',
      label: 'Download as CSV',
      downloadUrl: ({ filenamePrefix }) => {
        const friendlyTimeRange = `${Math.floor(startTime * 1000)}_${Math.floor(
          endTime * 1000,
        )}`

        const filename = `${filenamePrefix}_${streamName}_${friendlyTimeRange}.csv`
        const params = {
          filename,
          patient_id: patientID,
          device_id: deviceID,
          start_time: startTime,
          end_time: endTime,
          ...query,
          timestamp: 'unix',
        }
        return getDownloadUrl({
          endpoint: `v1/${endpointName}.csv`,
          params,
          filename,
          streamApiClient,
          cognitoUserPool,
        })
      },
    },
    {
      key: 'json',
      label: 'Download as JSON',
      downloadUrl: ({ filenamePrefix }) => {
        const friendlyTimeRange = `${Math.floor(startTime * 1000)}_${Math.floor(
          endTime * 1000,
        )}`

        const filename = `${filenamePrefix}_${streamName}_${friendlyTimeRange}.json`
        const params = {
          filename,
          patient_id: patientID,
          device_id: deviceID,
          start_time: startTime,
          end_time: endTime,
          ...query,
          timestamp: 'unix',
        }
        return getDownloadUrl({
          endpoint: `v1/${endpointName}.json`,
          params,
          filename,
          streamApiClient,
          cognitoUserPool,
        })
      },
    },
  ]
}
