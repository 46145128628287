import { SLEEP_STATE, SLEEP_STATUS } from '../consts'
import { formatEventsDataByDay } from './helpers'
import { convertTimeToUnixTimestamp } from 'utilities/time'

const determineIntensity = (sleepStatus) => {
  switch (sleepStatus) {
    case SLEEP_STATUS.asleep:
      return SLEEP_STATE.asleep
    case SLEEP_STATUS.inBed:
    default:
      return SLEEP_STATE.inBed
  }
}

const getDurationTimestamp = (time, selectedTimezone) =>
  convertTimeToUnixTimestamp({
    time,
    timezoneName: selectedTimezone,
  })

export const formatSleepData = (streamData, sleepStatus, selectedTimezone) => {
  const events = streamData.start_time.map((time, index) => ({
    classification: {
      category: 'sleep',
      enum: 'sleep_status',
    },
    displayName: 'Sleep',
    duration: {
      endTime: getDurationTimestamp(
        streamData.end_time[index],
        selectedTimezone,
      ),
      startTime: getDurationTimestamp(time, selectedTimezone),
    },
    payload: {
      intensity: determineIntensity(sleepStatus),
    },
  }))
  return formatEventsDataByDay(events, selectedTimezone)
}
