import { node } from 'prop-types'
import { Box } from '@mui/material'
import { colors } from 'theme/colors'

const MainContentWrapper = ({ children }) => {
  const STYLES = {
    mainContentWrapper: {
      backgroundColor: colors.COOL[100],
      padding: '0 2rem',
    },
  }

  return <Box sx={STYLES.mainContentWrapper}>{children}</Box>
}

MainContentWrapper.propTypes = {
  children: node.isRequired,
}

export default MainContentWrapper
