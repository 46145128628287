import React from 'react'
import { bool, func } from 'prop-types'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const PasswordVisibilityToggler = ({ showPassword, toggleShowPassword }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggles showing password"
      onClick={toggleShowPassword}
      onMouseDown={toggleShowPassword}
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
)

PasswordVisibilityToggler.propTypes = {
  showPassword: bool.isRequired,
  toggleShowPassword: func.isRequired,
}

PasswordVisibilityToggler.defaultProps = {}

export default PasswordVisibilityToggler
