export const SECTION_TITLE = {
  averageDailyValues: 'Average Daily Values',
  notes: 'Notes',
  outcomes: 'Outcomes',
  patientReported: 'Patient-reported data',
  sleep: 'Sleep data',
  symptoms: 'Symptoms',
  trends:
    'Arrows indicate a slight deviation from what is typically recorded by this patient.',
  watch: 'Data recorded by Apple watch',
  watchOrPhone: 'Data recorded by Apple watch or phone',
}

export const duration = Object.freeze({
  INTERVAL: 10,
  HALF_HOUR: 30,
})

export const PLACEHOLDER = '—'

export const TRENDS_TOOLTIP_TEXT = `We compare the averages you're viewing to the previous 90 days of data for this patient. Values that fall within the upper or lower quartiles of that historical distribution may indicate possible state changes in this patient's symptoms.`

export const CUSTOM_TEXT = {
  eventName: 'Custom Event',
  medicationName: 'Custom medication',
}
