import React from 'react'
import { usePermissions } from './hooks'

export const withPermission =
  (permissionKey) =>
  (EnabledComponent, DisabledComponent = () => null) =>
  (props) => {
    const { permissions } = usePermissions()
    return permissions && permissions[permissionKey] ? (
      <EnabledComponent {...props} />
    ) : (
      <DisabledComponent {...props} />
    )
  }
