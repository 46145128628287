export const RESEARCHER_PORTAL_PRIMARY_GREEN = 'hsla(167, 70%, 25%, 1)'

export const RUNE_WHITE = '#FFFFFF'

export const RUNE_GRAY_050 = '#FDFCFA'
export const RUNE_GRAY_100 = '#FAF9F8'
export const RUNE_GRAY_200 = '#F3F2F0'
export const RUNE_GRAY_300 = '#EEEDEA'
export const RUNE_GRAY_400 = '#CCCBC9'
export const RUNE_GRAY_500 = '#AFAEAC'
export const RUNE_GRAY_600 = '#848381'
export const RUNE_GRAY_700 = '#6F6F6D'
export const RUNE_GRAY_800 = '#504F4D'
export const RUNE_GRAY_900 = '#2D2D2B'

export const RUNE_BLACK = '#111111'

export const RUNE_BLUE_050 = '#E8F6FA'
export const RUNE_BLUE_900 = '#485E77'

export const RUNE_PRIMARY_BLUE = '#5B6F85'

export const STRIVEPD_DARK_BLUE = '#162C50'
export const STRIVEPD_PRIMARY_300 = '#DBEEFB'
export const STRIVEPD_PRIMARY_900 = '#438DC2'
export const STRIVEPD_PRIMARY_1000 = '#006EBD'
export const STRIVEPD_PRIMARY_1100 = '#005694'

export const INFOGRAPHIC_COLORS = {
  red: {
    veryLight: '#f8d4d3',
    light: '#f2b3b1',
    main: '#E7726E',
    dark: '#c02520',
    veryDark: '#6b1512',
  },
  orange: {
    veryLight: '#f8e5d0',
    light: '#f1c89c',
    main: '#e69746',
    dark: '#b16618',
    veryDark: '#6c3e0f',
  },
  yellow: {
    veryLight: '#f9ebcc',
    light: '#f4d597',
    main: '#eec062',
    dark: '#e6a41b',
    veryDark: '#a27211',
  },
  green: {
    veryLight: '#e8f6f2',
    light: '#b0e0d1',
    main: '#4db999',
    dark: '#34846c',
    veryDark: '#183e32',
  },
  blue: {
    veryLight: '#c3d7e3',
    light: '#80aac4',
    main: '#4e85a7',
    dark: '#355a72',
    veryDark: '#1c303c',
  },
  purple: {
    veryLight: '#ede1f6',
    light: '#d3b3e8',
    main: '#B986DB',
    dark: '#964ac9',
    veryDark: '#6d2d97',
  },
}

export const eventAccentColor = {
  GRAY: '#afaeac',
  RED: '#e7726e',
  ORANGE: '#e8a157',
  TEAL: '#77cab1',
  BLUE: '#588eb1',
  PURPLE: '#b986db',
}

/* REDESIGN COLORS */

export const colors = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  PRIMARY: {
    200: '#EFF7FD',
    300: '#DBEEFB',
    400: '#C4E2F9',
    500: '#A9D5F5',
    600: '#8CC6F0',
    700: '#74B4E2',
    800: '#5B9FD0',
    900: '#438DC2',
    1000: '#006EBD',
    1100: '#0063AA',
    1200: '#005694',
    1300: '#004D84',
    1400: '#004271',
    1500: '#00375F',
    1600: '#002C4C',
  },
  TEAL: {
    100: '#D9F4ED',
    200: '#B3E9DB',
    300: '#8CDDC8',
    400: '#66D2B6',
    500: '#40C7A4',
    600: '#3AB394',
    700: '#339F83',
    800: '#2D8B73',
    900: '#267762',
  },
  BONDI: {
    100: '#CCECF1',
    200: '#99D9E4',
    300: '#66C6D6',
    400: '#33B3C9',
    500: '#00A0BB',
    600: '#0090A8',
    700: '#008096',
    800: '#007083',
    900: '#006070',
  },
  TREMOR: {
    100: '#DFE9FE',
    200: '#BED4FC',
    300: '#9EBEFB',
    400: '#7DA9F9',
    500: '#5D93F8',
    600: '#5484DF',
    700: '#4A76C6',
    800: '#4167AE',
    900: '#385895',
  },
  DYSKINESIA: {
    100: '#D6ECD5',
    200: '#ADD9AB',
    300: '#85C680',
    400: '#5CB356',
    500: '#33A02C',
    600: '#2E9028',
    700: '#298023',
    800: '#24701F',
    900: '#1F601A',
  },
  GREY: {
    100: '#F6F6F6',
    200: '#EFEFEF',
    300: '#DEDEDE',
    400: '#CBCBCB',
    500: '#ACACAC',
    600: '#939393',
    700: '#777777',
    800: '#5B5B5B',
    900: '#333333',
  },
  COOL: {
    100: '#EFF4FA',
    200: '#E4E9EF',
    300: '#D8DCE0',
    400: '#C2C7CC',
    500: '#A2A8AD',
    600: '#8B9093',
    700: '#717578',
    800: '#55595B',
    900: '#2C3033',
  },
  WARM: {
    100: '#FAF9F8',
    200: '#EEECEA',
    300: '#E0DDDA',
    400: '#CDC9C5',
    500: '#ADAAA6',
    600: '#95918C',
    700: '#797571',
    800: '#5E5B58',
    900: '#373431',
  },
  ORANGE: {
    100: '#FFDACD',
    200: '#FFB49B',
    300: '#FF8F69',
    400: '#FF6937',
    500: '#FF4405',
    600: '#E63D05',
    700: '#CC3604',
    800: '#B33004',
    900: '#992903',
  },
  YELLOW: {
    100: '#FFF5D2',
    200: '#FFEBA5',
    300: '#FFE178',
    400: '#FFD74B',
    500: '#FFCD1E',
    600: '#E6B91B',
    700: '#CCA418',
    800: '#B39015',
    900: '#997B12',
  },
  LIME: {
    100: '#E0F4D2',
    200: '#C2E8A4',
    300: '#A3DD77',
    400: '#85D149',
    500: '#66C61C',
    600: '#5CB219',
    700: '#529E16',
    800: '#478B14',
    900: '#3D7711',
  },
  GREEN: {
    100: '#D0F1E1',
    200: '#A0E2C3',
    300: '#71D4A6',
    400: '#41C588',
    500: '#12B76A',
    600: '#10A55F',
    700: '#0E9255',
    800: '#0D804A',
    900: '#0B6E40',
  },
  CYAN: {
    100: '#D8F3F7',
    200: '#B2E7EF',
    300: '#8BDBE6',
    400: '#65CFDE',
    500: '#3EC3D6',
    600: '#38B0C1',
    700: '#329CAB',
    800: '#2B8996',
    900: '#257580',
  },
  BLUEBERRY: {
    100: '#D6D7F0',
    200: '#ADAFE0',
    300: '#8588D1',
    400: '#5C60C1',
    500: '#3338B2',
    600: '#2E32A0',
    700: '#292D8E',
    800: '#24277D',
    900: '#1F226B',
  },
  VIOLET: {
    100: '#E7DEFF',
    200: '#CFBDFF',
    300: '#B69DFF',
    400: '#9E7CFF',
    500: '#865BFF',
    600: '#7952E6',
    700: '#6B49CC',
    800: '#5E40B3',
    900: '#503799',
  },
  PURPLE: {
    100: '#F3DEFD',
    200: '#E7BDFB',
    300: '#DC9CFA',
    400: '#D07BF8',
    500: '#C45AF6',
    600: '#B051DD',
    700: '#9D48C5',
    800: '#893FAC',
    900: '#763694',
  },
  PINK: {
    100: '#FFE6F9',
    200: '#FFCDF4',
    300: '#FFB4EE',
    400: '#FF9BE9',
    500: '#FF82E3',
    600: '#E675CC',
    700: '#CC68B6',
    800: '#B35B9F',
    900: '#994E88',
  },
  RED: {
    100: '#F3CCD0',
    200: '#E699A1',
    300: '#D25852',
    400: '#CD3342',
    500: '#C10013',
    600: '#AE0011',
    700: '#9A000F',
    800: '#87000D',
    900: '#74000B',
  },
  SKIN: {
    100: '#F9F0DD',
    200: '#E4D7BC',
    300: '#ECD2C3',
    400: '#D6AA8D',
    500: '#C78D75',
    600: '#C78D75',
    700: '#7B4D44',
    800: '#664D48',
    900: '#3B3C37',
  },
  TRANSPARENT: 'transparent',
}

export const hexToRgb = (hex) => {
  if (hex === 'transparent') {
    return 'rgba(0, 0, 0, 0)'
  }
  const color = hex.replace('#', '')
  const r = parseInt(color.substring(0, 2), 16) || 0
  const g = parseInt(color.substring(2, 4), 16) || 0
  const b = parseInt(color.substring(4, 6), 16) || 0
  return `rgb(${r}, ${g}, ${b})`
}
