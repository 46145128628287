import { Children } from 'react'
import { arrayOf, element, object, oneOfType, string } from 'prop-types'
import { Box, Grid, Typography } from '@mui/material'
import { RUNE_GRAY_700 } from 'theme/colors'
import { InformationalIconTooltip } from 'ui/components/InformationalIconTooltip'
import { TITLE_WIDTH } from '../SummaryBar/SummaryBarTitleItem'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'

const STYLES = {
  outerGrid: {
    margin: 0,
    padding: `8px 16px`,
    width: '100%',
  },
  titleGrid: {
    minHeight: 54,
    color: RUNE_GRAY_700,
  },
  popout: {
    marginTop: '4px !important', // accounts for padding around the popout icon
  },
  title: {
    minWidth: TITLE_WIDTH,
  },
  separator: {
    marginRight: '8px !important',
  },
}

const SummarySection = ({ title, subtitle, tooltipText, popout, children }) => (
  <Stack sx={STYLES.outerGrid}>
    <Stack
      direction="row"
      alignItems="center"
      sx={STYLES.titleGrid}
      spacing={2}
    >
      <Text variant="caps12" sx={STYLES.title}>
        {title}
      </Text>
      {subtitle && (
        <>
          <Typography sx={STYLES.separator}>/</Typography>
          <Typography>{subtitle}</Typography>
        </>
      )}
      {tooltipText && (
        <InformationalIconTooltip
          placement="bottom-start"
          title={<Box>{tooltipText}</Box>}
          data-cy="tooltip"
        />
      )}
      <Grid item sx={STYLES.popout}>
        {popout}
      </Grid>
    </Stack>
    {Children.map(children, (child, index) => (
      <Grid item key={`item-${index}`}>
        {child}
      </Grid>
    ))}
  </Stack>
)

SummarySection.propTypes = {
  title: string.isRequired,
  popout: element,
  children: oneOfType([arrayOf(element), object]),
}

SummarySection.defaultProps = {
  title: '',
  children: [],
}

export default SummarySection
