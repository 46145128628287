import { useContext, useEffect, useState } from 'react'

import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import { createArrayOfDatesInDescendingOrder } from './helpers/helpers'
import DailyViewsHeader from '../Tabs/TabContentHeaders/Layouts/Tabs/DailyViewsHeader'
import { Stack } from 'ui/baseComponents'
import useBoundStore from 'domains/zustand/store'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { DAILY_EVENT_CATEGORIES } from 'ui/clinicianScreens/Patient/Daily/consts'
import DailyFilters from 'ui/clinicianScreens/Patient/Daily/components/DailyFilters/DailyFilters'
import { getCombinedMedicationNameAndStrength } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
import DailySummary from 'ui/clinicianScreens/Patient/Daily/sections/DailySummary/DailySummary'
import { PD_MEDICATIONS } from 'ui/clinicianScreens/Patient/MedicationTables/consts'
import DailyDay from 'ui/clinicianScreens/Patient/Daily/components/DailyDay/DailyDay'
import { getDateFromTimestamp } from 'utilities/time'

/**
 * The daily view with comparative charts for a variety of logged items
 *
 * @returns {JSX.Element} Daily View parent component. All Daily View components are descendants of this component.
 */
const DailyNew = () => {
  const STYLES = {
    contentContainer: {
      padding: '1rem',
      flex: 1,
    },
  }

  const { selectedTimezone, dateRanges } = useContext(DateRangeContext)
  const eventList = useBoundStore((state) => state.eventList)

  const [dateArray, setDateArray] = useState([])

  const [hasTremorData, setHasTremorData] = useState(false)
  const [hasDyskinesiaData, setHasDyskinesiaData] = useState(false)
  const [symptomEvents, setSymptomEvents] = useState([])
  const [medicationEvents, setMedicationEvents] = useState([])
  const [checkinEvents, setCheckinEvents] = useState([])
  const [notesEvents, setNotesEvents] = useState([])

  const [
    medicationNamesAndClassifications,
    setMedicationNamesAndClassifications,
  ] = useState(new Map())
  const [medicationInitialFilters, setMedicationInitialFilters] = useState(
    new Set(),
  )
  const [symptomInitialFilters, setSymptomInitialFilters] = useState(new Set())

  const [medicationActiveFilters, setMedicationActiveFilters] = useState([])
  const [symptomActiveFilters, setSymptomActiveFilters] = useState([])

  const [isTremorFilterActive, setIsTremorFilterActive] = useState(true)
  const [isDyskinesiaFilterActive, setIsDyskinesiaFilterActive] = useState(true)
  const [isMedicationFilterActive, setIsMedicationFilterActive] = useState(true)
  const [isSymptomFilterActive, setIsSymptomFilterActive] = useState(true)
  const [isSleepFilterActive, setIsSleepFilterActive] = useState(true)
  const [isNotesFilterActive, setIsNotesFilterActive] = useState(false)
  const [isCheckinFilterActive, setIsCheckinFilterActive] = useState(false)

  const resetDailyStates = () => {
    setHasTremorData(false)
    setHasDyskinesiaData(false)
    setSymptomEvents([])
    setMedicationEvents([])
    setCheckinEvents([])
    setNotesEvents([])
    setMedicationNamesAndClassifications(new Map())
    setMedicationInitialFilters(new Set())
    setSymptomInitialFilters(new Set())
    setMedicationActiveFilters([])
    setSymptomActiveFilters([])
    setIsTremorFilterActive(true)
    setIsDyskinesiaFilterActive(true)
    setIsMedicationFilterActive(true)
    setIsSymptomFilterActive(true)
    setIsSleepFilterActive(true)
    setIsNotesFilterActive(false)
    setIsCheckinFilterActive(false)
  }

  useEffect(() => {
    setIsMedicationFilterActive(medicationInitialFilters?.size > 0)
  }, [medicationInitialFilters?.size])

  useEffect(() => {
    setIsSymptomFilterActive(symptomInitialFilters?.size > 0)
  }, [symptomInitialFilters?.size])

  useEffect(() => {
    const pdMeds = []
    for (const [name, classification] of medicationNamesAndClassifications) {
      if (PD_MEDICATIONS.includes(classification)) {
        pdMeds.push(name)
      }
    }
    setMedicationActiveFilters(pdMeds)
  }, [medicationNamesAndClassifications])

  useEffect(() => {
    setSymptomActiveFilters([...symptomInitialFilters])
  }, [symptomInitialFilters])

  const hasMedicationEvents = medicationEvents.length > 0
  const hasSymptomEvents = symptomEvents.length > 0
  const hasNotesEvents = notesEvents.length > 0
  const hasCheckinEvents = checkinEvents.length > 0

  useEffect(() => {
    setIsTremorFilterActive(hasTremorData)
    setIsDyskinesiaFilterActive(hasDyskinesiaData)
    setIsMedicationFilterActive(hasMedicationEvents)
    setIsSymptomFilterActive(hasSymptomEvents)
  }, [
    hasTremorData,
    hasDyskinesiaData,
    hasMedicationEvents,
    hasSymptomEvents,
    hasNotesEvents,
    hasCheckinEvents,
  ])

  const { start: startTime, end: endTime } =
    dateRanges[DATE_RANGE.default.key] || {}

  useEffect(() => {
    setDateArray(createArrayOfDatesInDescendingOrder(endTime, startTime))
  }, [endTime, startTime])

  useEffect(() => {
    resetDailyStates()
  }, [dateRanges])

  useEffect(() => {
    if (eventList) {
      eventList?.forEach((event) => {
        const eventName = getEventName(event)
        switch (event.classification.category) {
          case DAILY_EVENT_CATEGORIES.sideEffect:
          case DAILY_EVENT_CATEGORIES.symptom: {
            const { payload } = event
            const eventPayload = JSON.parse(payload)
            const fromDailyCheckin = eventPayload?.from_daily_checkin
            if (fromDailyCheckin) {
              if (!checkinEvents.find((checkin) => checkin.id === event.id)) {
                setCheckinEvents((prev) => [...prev, event])
              }
            } else {
              setSymptomEvents((prev) => [...prev, event])
              if (!symptomInitialFilters.has(eventName)) {
                setSymptomInitialFilters(
                  (prev) => new Set([...prev, eventName]),
                )
              }
              // if endTime is the day after the startDate, add it to the next day
              const eventStartDate = getDateFromTimestamp(
                event.duration.startTime,
                selectedTimezone,
                'DD',
              )
              const eventEndDate = getDateFromTimestamp(
                event.duration.endTime,
                selectedTimezone,
                'DD',
              )
              if (eventStartDate !== eventEndDate) {
                const newStartTime = getDateFromTimestamp(
                  event.duration.endTime,
                  selectedTimezone,
                )
                  .startOf('day')
                  .unix()
                const nextDayEvent = {
                  ...event,
                  duration: { ...event.duration, startTime: newStartTime },
                }
                setSymptomEvents((prev) => [...prev, nextDayEvent])
              }
            }
            break
          }
          case DAILY_EVENT_CATEGORIES.medication: {
            setMedicationEvents((prev) => [...prev, event])
            const combinedMedicationDosage =
              getCombinedMedicationNameAndStrength(event)
            if (!medicationInitialFilters.has(combinedMedicationDosage)) {
              const classification = event.classification.enum
              setMedicationNamesAndClassifications(
                (prev) =>
                  new Map(prev.set(combinedMedicationDosage, classification)),
              )
              setMedicationInitialFilters(
                (prev) => new Set([...prev, combinedMedicationDosage]),
              )
            }
            break
          }
          case DAILY_EVENT_CATEGORIES.note:
            if (!notesEvents.find((note) => note.id === event.id)) {
              setNotesEvents((prev) => [...prev, event])
            }
            break
          default:
            break
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventList])

  const filterProps = {
    hasTremorData,
    hasDyskinesiaData,
    hasMedicationEvents,
    hasSymptomEvents,
    hasNotesEvents,
    hasCheckinEvents,
    isTremorFilterActive,
    setIsTremorFilterActive,
    isDyskinesiaFilterActive,
    setIsDyskinesiaFilterActive,
    isMedicationFilterActive,
    setIsMedicationFilterActive,
    medicationInitialFilters,
    medicationActiveFilters,
    setMedicationActiveFilters,
    isSymptomFilterActive,
    setIsSymptomFilterActive,
    symptomInitialFilters,
    symptomActiveFilters,
    setSymptomActiveFilters,
    isNotesFilterActive,
    setIsNotesFilterActive,
    isSleepFilterActive,
    setIsSleepFilterActive,
    isCheckinFilterActive,
    setIsCheckinFilterActive,
  }

  return (
    <>
      <DailyViewsHeader />
      <Stack direction="row" spacing={0}>
        <DailyFilters {...filterProps} />
        <Stack sx={STYLES.contentContainer}>
          <DailySummary data={[...symptomEvents, ...checkinEvents]} />
          {dateArray.map((date) => (
            <DailyDay
              {...{
                date,
                medicationEvents,
                medicationActiveFilters,
                isMedicationFilterActive,
                symptomEvents,
                symptomActiveFilters,
                isSymptomFilterActive,
                setHasTremorData,
                isTremorFilterActive,
                setHasDyskinesiaData,
                isDyskinesiaFilterActive,
                checkinEvents,
                isCheckinFilterActive,
                notesEvents,
                isNotesFilterActive,
              }}
            />
          ))}
        </Stack>
      </Stack>
    </>
  )
}

export default DailyNew
