import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginTop: '2rem',
  },
}))

/**
 * Component that handles the localStorage setting/removing
 * checkbox for hiding the warning modal
 *
 * @returns {JSX.Element} Form component
 */
const OrgSwitchWarningMessage = () => {
  const [checked, setChecked] = useState(false)
  const classes = useStyles()

  const handleCheckboxChange = () => {
    if (!checked) {
      localStorage.setItem('RuneDoNotShowOrgWarning', true)
    } else {
      localStorage.removeItem('RuneDoNotShowOrgWarning')
    }
    setChecked((x) => !x)
  }

  return (
    <>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={checked}
            onChange={handleCheckboxChange}
            inputProps={{ 'aria-label': "Don't show this warning again" }}
          />
        }
        label="Don't show this warning again"
      />
    </>
  )
}

export default OrgSwitchWarningMessage
