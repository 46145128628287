const reportsSlice = (set) => ({
  patientId: null,
  setPatientId: (id) => set(() => ({ patientId: id })),
  highlightsEdits: {},
  setHighlightsEdits: (edits) =>
    set((state) => ({
      highlightsEdits: { ...state.highlightsEdits, ...edits },
    })),
  redFlags: new Set(),
  appendRedFlags: (flag) =>
    set((state) => ({
      redFlags: new Set([...state.redFlags, flag]),
    })),
  resetRedFlags: () =>
    set(() => ({
      redFlags: new Set(),
    })),
  resetReportState: () =>
    set(() => ({
      highlightsEdits: {},
    })),
})

export default reportsSlice
