import ReportSection from 'ui/clinicianScreens/Patient/Report/components/ReportSection'
import useTremorAndDyskinesiaAggregate from 'ui/hooks/summaryAggregates/useTremorAndDyskinesiaAggregate'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Report/consts'
import ReportSectionTremor from './Tremor/ReportSectionTremor'
import ReportSectionDyskinesia from './Dyskinesia/ReportSectionDyskinesia'
import {
  TREMOR_AND_DYSKINESIA_SUBTITLE,
  TREMOR_AND_DYSKINESIA_TITLE,
} from 'ui/clinicianScreens/Patient/Report/sections/TremorAndDyskinesia/helpers'

const ReportSectionTremorAndDyskinesia = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
  isDINVariant,
}) => {
  const { TREMOR, DYSKINESIA } = SECTION_TITLES

  const { data, isLoading } = useTremorAndDyskinesiaAggregate(
    startDate,
    endDate,
  )
  const { data: prevData, isLoading: prevIsLoading } =
    useTremorAndDyskinesiaAggregate(prevStartDate, prevEndDate)
  const loading = isLoading || prevIsLoading

  const { tremor: tremorData } = data || {}
  const { dyskinesia: dyskinesiaData } = data || {}

  const { tremor: prevTremorData } = prevData || {}
  const { dyskinesia: prevDyskinesiaData } = prevData || {}

  const getDyskinesiaSection = () => (
    <ReportSectionDyskinesia
      {...{
        data: dyskinesiaData,
        onLoadStart,
        onLoadEnd,
        hasDataSet,
        hiddenSections,
        isClinicianReport,
        loading,
        prevData: prevDyskinesiaData,
        addToHasDataSet,
        removeFromHasDataSet,
      }}
    />
  )

  const getTremorSection = () => (
    <ReportSectionTremor
      {...{
        data: tremorData,
        onLoadStart,
        onLoadEnd,
        hasDataSet,
        hiddenSections,
        isClinicianReport,
        loading,
        prevData: prevTremorData,
        addToHasDataSet,
        removeFromHasDataSet,
      }}
    />
  )

  return (
    <>
      <ReportSection
        title={
          !isClinicianReport && !isDINVariant && TREMOR_AND_DYSKINESIA_TITLE
        }
        subtitle={TREMOR_AND_DYSKINESIA_SUBTITLE}
        hidden={
          (hiddenSections.has(TREMOR) && hiddenSections.has(DYSKINESIA)) ||
          (isClinicianReport &&
            !hasDataSet.has(TREMOR) &&
            !hasDataSet.has(DYSKINESIA))
        }
      >
        {isDINVariant ? getDyskinesiaSection() : getTremorSection()}
        {isDINVariant ? getTremorSection() : getDyskinesiaSection()}
      </ReportSection>
    </>
  )
}

export default ReportSectionTremorAndDyskinesia
