import { Fragment } from 'react'
import { arrayOf, element, func, oneOfType, string } from 'prop-types'
import { Box, Link, Paper, makeStyles } from '@material-ui/core'
import StrivePDPoweredByRuneLogo from 'assets/img/strivepd-poweredby-rune-logo.svg'
import { RUNE_GRAY_600 } from 'theme/colors'
import { PrivacyNoticeLink } from 'ui/components/PrivacyNoticeLink'

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
  formContainer: {
    padding: '4rem',
    maxWidth: '29.5rem',
    marginBottom: '1.5rem',
  },
  subheading: {
    margin: '.5rem',
  },
  termsContainer: {
    textAlign: 'center',
    color: RUNE_GRAY_600,
    '&> a': {
      color: RUNE_GRAY_600,
      margin: '0 .3rem',
    },
  },
}))

const FormWrapperLayout = ({
  children,
  termsAndPrivacyNoticeAgreementText,
}) => {
  const classes = useStyles()
  return (
    <Box
      alignItems="center"
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      id="rune-signin"
    >
      <Paper className={classes.formContainer}>
        <Box>{children}</Box>
      </Paper>

      <div className={classes.termsContainer} data-cy="terms-container">
        <div> {termsAndPrivacyNoticeAgreementText}</div>
        <Link
          href="https://www.runelabs.io/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </Link>
        and
        <PrivacyNoticeLink color="inherit" underline="hover" />
      </div>
    </Box>
  )
}

const FormWrapper = ({
  children,
  onSubmit,
  termsAndPrivacyNoticeAgreementText,
}) => (
  <FormWrapperLayout
    termsAndPrivacyNoticeAgreementText={termsAndPrivacyNoticeAgreementText}
  >
    <form
      className="form"
      data-cy="rune-signin-form"
      data-testid="rune-signin-form"
      onSubmit={onSubmit}
    >
      <div className="logo">
        <img
          alt="StrivePD powered by Rune Labs"
          src={StrivePDPoweredByRuneLogo}
        />
      </div>
      <Fragment>{children}</Fragment>
    </form>
  </FormWrapperLayout>
)

FormWrapper.propTypes = {
  children: oneOfType([element, arrayOf(element)]).isRequired,
  onSubmit: func.isRequired,
  termsAndPrivacyNoticeAgreementText: string,
}

FormWrapper.defaultProps = {}

export default FormWrapper
