import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Input,
  Select,
  ListSubheader,
} from '@mui/material'
import CheckboxPrimary from 'ui/components/Checkbox/CheckboxPrimary'

const OverviewMedicationFilters = ({
  medicationList = [],
  supplementList = [],
  onChange,
  selectedMedications,
}) => (
  <FormControl sx={{ m: 1, width: '20em' }} variant="standard" size="small">
    <InputLabel>Medication</InputLabel>
    <Select
      multiple
      input={<Input label="Medication" />}
      onChange={onChange}
      value={selectedMedications}
      renderValue={(selected) => selected.join(', ')}
    >
      <ListSubheader>Medications</ListSubheader>
      {medicationList.map((name) => (
        <MenuItem key={name} value={name}>
          <CheckboxPrimary checked={selectedMedications?.includes(name)} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
      <ListSubheader>Supplements</ListSubheader>
      {supplementList.map((name) => (
        <MenuItem key={name} value={name}>
          <CheckboxPrimary checked={selectedMedications?.includes(name)} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default OverviewMedicationFilters
