import { useContext } from 'react'
import RuneLabsLogo from 'assets/img/logo.svg'
import StrivePDLogoWhite from 'assets/img/strivepd-logo-white.svg'
import { Icon, makeStyles } from '@material-ui/core'
import { UserPHIContext } from 'ui/contexts'

const useStyles = makeStyles((theme) => ({
  imageIcon: {
    height: `${theme.imageIcon.height}`,
    marginLeft: `${theme.imageIcon.marginLeft}`,
    marginTop: `${theme.imageIcon.marginTop}`,
  },
  iconRoot: {
    textAlign: `${theme.iconRoot.textAlign}`,
    height: `${theme.iconRoot.height}`,
    width: `${theme.iconRoot.width}`,
  },
}))

const LogoIcon = () => {
  const phiVisibility = useContext(UserPHIContext)
  const classes = useStyles()

  let imgSrc, altText
  if (phiVisibility) {
    imgSrc = StrivePDLogoWhite
    altText = 'StrivePD logo'
  } else {
    imgSrc = RuneLabsLogo
    altText = 'Rune logo'
  }
  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img className={classes.imageIcon} src={imgSrc} alt={altText} />
    </Icon>
  )
}
export default LogoIcon
