import { useContext } from 'react'
import * as MUI from '@mui/material'
import PatientErrorCard from '../../PatientErrorCard'
import {
  convertUnixTimestampToSpecifiedTimezone,
  SECOND_IN_MILLISECONDS,
} from 'utilities/time'
import { Text } from 'ui/baseComponents/Text'
import { colors } from 'theme/colors'
import { NO_DATA_PATIENT_NOTES } from '../consts'
import { NoDataText } from 'ui/components/NoDataText'
import useBoundStore from 'domains/zustand/store'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

const STYLES = {
  table: {
    borderBottom: 0,
  },
  tableCell: {
    verticalAlign: 'top',
    borderBottom: 0,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    '&:first-of-type': {
      paddingLeft: 0,
    },
  },
}

const PatientNotes = () => {
  const { selectedTimezone } = useContext(DateRangeContext)

  const eventList = useBoundStore((state) => state.eventList)
  const errorEvents = useBoundStore((state) => state.errorEvents)
  const loadingEvents = useBoundStore((state) => state.loadingEvents)

  const notesEvents =
    eventList?.filter((event) => event.classification.category === 'note') || []

  const PatientNotesContent = () => {
    if (!notesEvents.length) {
      return (
        <MUI.TableRow>
          <MUI.TableCell sx={STYLES.tableCell} variant="body">
            <NoDataText text={NO_DATA_PATIENT_NOTES} />
          </MUI.TableCell>
        </MUI.TableRow>
      )
    }

    return notesEvents?.map((event, key) => {
      const timesFormatted = convertUnixTimestampToSpecifiedTimezone({
        timestamp: event.duration.startTime * SECOND_IN_MILLISECONDS,
        timezoneName: selectedTimezone,
        format: 'MMM Do, h:mmA',
      })

      // Filter out not usable notes log items and format the wording
      const CONTENT_INDEX = 1
      const neededPayloadItems = Object.entries(JSON.parse(event.payload)).map(
        (item) => item[CONTENT_INDEX],
      )

      // Return the items along with their times in the table row
      return (
        <MUI.TableRow key={`${key}-notesLog`}>
          <MUI.TableCell sx={STYLES.tableCell} width={155} variant="body">
            <Text variant="body16" color={colors.BLACK}>
              {timesFormatted}
            </Text>
          </MUI.TableCell>
          <MUI.TableCell sx={STYLES.tableCell} variant="body">
            <Text variant="body16" color={colors.GREY[900]}>
              {neededPayloadItems}
            </Text>
          </MUI.TableCell>
        </MUI.TableRow>
      )
    })
  }

  if (errorEvents) {
    return <PatientErrorCard />
  }

  if (loadingEvents) {
    return <MUI.LinearProgress />
  }

  return (
    <MUI.Table sx={STYLES.table}>
      <MUI.TableBody>
        <PatientNotesContent />
      </MUI.TableBody>
    </MUI.Table>
  )
}

export default PatientNotes
