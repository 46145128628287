import React from 'react'
import clsx from 'clsx'
import { SnackbarContent } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'

const NetworkErrorAlert = ({ errorMessage, classes }) => (
  <SnackbarContent
    className={classes.networkError}
    aria-describedby="client-error-snackbar"
    message={
      <span id="client-error-snackbar" className={classes.message}>
        <WarningIcon className={clsx(classes.icon, classes.iconVariant)} />
        {errorMessage}
      </span>
    }
  />
)

export default NetworkErrorAlert
