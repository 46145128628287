import { useContext } from 'react'
import useSleepAggregate from 'ui/hooks/summaryAggregates/useSleepAggregate'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import { prettyPrintRoundedTimeInHoursAndMinutes } from 'utilities/time'
import { SummaryMetric } from '../../Summary/components/SummaryMetric'
import { MetricsRow } from './MetricsRow'
import { ERROR_TEXT, NO_DATA_SLEEP } from '../consts'
import { NoDataText } from 'ui/components/NoDataText'

export const SleepMetrics = () => {
  const { dateRanges } = useContext(DateRangeContext)
  const { start, end } = dateRanges[DATE_RANGE.default.key] || {}
  const { data, isLoading, hasData, hasError } = useSleepAggregate(start, end)
  const summaryData = data?.summaries?.totalSleepPerDayInHours || {}
  const sleepAverage = prettyPrintRoundedTimeInHoursAndMinutes(
    summaryData?.average,
  )

  const sleepMax = prettyPrintRoundedTimeInHoursAndMinutes(summaryData?.max)
  const sleepMin = prettyPrintRoundedTimeInHoursAndMinutes(summaryData?.min)

  const Content = () => {
    if (hasError) {
      return <NoDataText text={ERROR_TEXT} />
    }

    if (!hasData && !isLoading) {
      return <NoDataText text={NO_DATA_SLEEP} />
    }

    return (
      <SummaryMetric
        title="Total Sleep"
        metrics={{ h: sleepAverage.hours, m: sleepAverage.minutes }}
        maxMetrics={{ h: sleepMax.hours, m: sleepMax.minutes }}
        minMetrics={{ h: sleepMin.hours, m: sleepMin.minutes }}
      />
    )
  }

  return (
    <MetricsRow title="Sleep" noBorder>
      <Content />
    </MetricsRow>
  )
}
