import { useCallback, useEffect, useState } from 'react'
import copy from 'clipboard-copy'
import { Box, OutlinedInput, makeStyles, Tooltip } from '@material-ui/core'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

const CopyTextIcon = ({ value, height = '24px', size = '' }) => {
  const iconAnimationTime = 2000
  const [copied, setCopied] = useState()
  const [resetTimeoutId, setResetTimeoutId] = useState()

  let minWidth = ''
  switch (size) {
    case 'small':
      minWidth = '100px'
      break
    case 'large':
      minWidth = '500px'
      break
    case 'full':
      minWidth = '100%'
      break
    default:
      minWidth = '350px'
  }

  const useStyles = makeStyles((theme) => ({
    icon: {
      display: 'flex',
      width: '1.5em',
      fill: theme.palette.primary.main,
      height: height,
      fontSize: '1.5rem',
      marginLeft: '0.5rem',
      cursor: 'pointer',
    },
    copyInput: {
      height: '36px',
      paddingRight: '0',
      minWidth: minWidth,
    },
  }))
  const classes = useStyles()
  // clear timeout when component unmounts only to prevent error
  useEffect(
    () => () => {
      clearTimeout(resetTimeoutId)
      setResetTimeoutId(false)
    },
    [resetTimeoutId],
  )
  const doCopy = useCallback(
    (e) => {
      e.preventDefault()
      copy(value)
      setCopied(true)
      setResetTimeoutId(() => {
        setTimeout(() => setCopied(false), iconAnimationTime)
      })
    },
    [value],
  )

  const inputProps = {
    tabIndex: '-1',
    'data-lpignore': true,
    'data-cy': 'copytext-icon',
  }

  return (
    <Box display="flex">
      <OutlinedInput
        value={value}
        labelWidth={0}
        autoComplete="off"
        className={classes.copyInput}
        inputProps={inputProps}
        endAdornment={
          copied ? (
            <Tooltip title="Copied!">
              <AssignmentTurnedInIcon className={classes.icon} alt="Copied" />
            </Tooltip>
          ) : (
            <Tooltip title="Copy to clipboard">
              <AssignmentReturnedIcon
                onClick={doCopy}
                className={classes.icon}
                alt="Copy to clipboard"
              />
            </Tooltip>
          )
        }
      />
    </Box>
  )
}

export default CopyTextIcon
