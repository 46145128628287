import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { RUNE_GRAY_600 } from 'theme/colors'

const useStyles = makeStyles((theme) => ({
  inner: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
    width: '100%',
    backgroundColor: theme.palette.background.legend,
  },
  message: {
    marginLeft: theme.spacing(1),
    color: RUNE_GRAY_600,
    display: 'flex',
    justifyContent: 'center',
  },
}))
const DEFAULT_MESSAGE = 'No results found'

const EmptyResultsBanner = ({ className, message }) => {
  const classes = useStyles()
  return (
    <Box className={`${classes.root} ${className}`}>
      <div className={classes.inner}>
        <Typography className={classes.message} variant="body1">
          {message}
        </Typography>
      </div>
    </Box>
  )
}

EmptyResultsBanner.propTypes = {
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

EmptyResultsBanner.defaultProps = {
  className: '',
  message: DEFAULT_MESSAGE,
}

export default EmptyResultsBanner
