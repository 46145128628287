import { memo } from 'react'
import moment from 'moment'
import TimezoneSelect, { allTimezones } from 'react-timezone-select'

const TimezoneDropdown = ({ timezone, updateTimezone }) => (
  <div data-cy="timezone-dropdown">
    <TimezoneSelect
      value={timezone || moment.tz.guess()}
      onChange={(tz) => {
        updateTimezone(tz.value)
      }}
      timezones={{
        ...allTimezones,
      }}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          minWidth: 200,
          '@media print': {
            display: 'none',
          },
        }),
      }}
    />
  </div>
)

export default memo(TimezoneDropdown)
