import { useEffect } from 'react'
import ErrorFrame from 'ui/components/ErrorFrame'
import NavLink from 'ui/components/NavLink'
import { useIsAdmin, usePatientList } from 'ui/hooks'
import { Breadcrumbs, IconButton, MenuItem, Select } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core'
import useBoundStore from 'domains/zustand/store'

/**
 * Component for dispaying a Select with all patients, which dispatches the user
 * to the same view with the patient switched out.
 *
 * @param {object} patient The current patient
 * @param {object} patients A list of possible patients
 * @param {func} onPatientChange The dispatch function to execute when the patient is switched
 * @param {boolean} patientBreadcrumb (optional) When set to false, removes the preceeding breadcrumb
 * @returns {JSX.Element} A select dropdown with patient code names as different select options
 */
const PatientSelect = ({
  patient,
  onPatientChange,
  patientBreadcrumb = true,
}) => {
  usePatientList({ limit: 200, fetchAll: true })
  const errorPatientList = useBoundStore((state) => state.errorPatientList)
  const patients = useBoundStore((state) => state.patients)
  const resetPatientList = useBoundStore((state) => state.resetPatientList)

  useEffect(() => {
    resetPatientList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { admin } = useIsAdmin()
  const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
      padding: '1rem',
      color: theme.palette.text.primary,
    },
    patientCodename: {
      fontSize: patientBreadcrumb ? '1.4rem' : '1.2rem',
      fontWeight: 500,
      padding: patientBreadcrumb ? theme.spacing(1) : theme.spacing(0),
      paddingRight: theme.spacing(4),
    },
    selectContainer: {
      maxWidth: '100%',
    },
    settingsLink: {
      height: '1.25rem',
    },
    settingsContainer: {
      marginBottom: '0.25rem',
    },
  }))
  const classes = useStyles()

  if (errorPatientList) {
    return <ErrorFrame />
  }

  const patientsIncludesCurrent = patients
    ? patients.some((returnedPatient) => returnedPatient.id === patient.id)
    : false

  return (
    <>
      {patientBreadcrumb ? (
        <Breadcrumbs className={classes.breadcrumbs}>
          <NavLink color="inherit" to="/patients">
            Patients
          </NavLink>
          <div>
            <Select
              autoWidth={true}
              classes={{ select: classes.patientCodename }}
              onChange={onPatientChange}
              value={patient.id}
              data-cy="patient-select-in-breadcrumb"
            >
              {patients?.map(({ id, preComputedName }) => (
                <MenuItem
                  key={id}
                  value={id}
                  data-cy={`patient-select-in-breadcrumb-${id}`}
                >
                  {preComputedName}
                </MenuItem>
              ))}

              {!patientsIncludesCurrent && (
                <MenuItem key={patient.id} value={patient.id}>
                  {patient.preComputedName}
                </MenuItem>
              )}
            </Select>
            {admin && (
              <IconButton size="small" className={classes.settingsContainer}>
                <NavLink
                  to={`/patients/${patient.id}/manage/details`}
                  title="Edit Patient Settings"
                  data-cy="patient-select-patient-details-link"
                  className={classes.settingsLink}
                >
                  <SettingsIcon fontSize="small" />
                </NavLink>
              </IconButton>
            )}
          </div>
        </Breadcrumbs>
      ) : (
        <Select
          autoWidth={true}
          className={classes.selectContainer}
          classes={{ select: classes.patientCodename }}
          disableUnderline
          onChange={onPatientChange}
          value={patient.id}
          data-cy="patient-select"
        >
          {patients ? (
            patients.map(({ id, preComputedName }) => (
              <MenuItem key={id} value={id} data-cy={`patient-select-${id}`}>
                {preComputedName}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              key={patient.id}
              value={patient.id}
              data-cy={`patient-select-${patient.id}`}
            >
              {patient.preComputedName}
            </MenuItem>
          )}
        </Select>
      )}
    </>
  )
}

export default PatientSelect
