import { createContext, useContext } from 'react'

import { createClient } from './client'

/**
 * Context for the Stream API client
 */
export const StreamApiContext = createContext()

/**
 * Shortcut for the Stream API context.
 */
export const useStreamApiClient = () => {
  return useContext(StreamApiContext)
}

/**
 * Shortcut for the Stream API client default for the API base URL.
 */
export const useBaseUrl = () => {
  const client = useStreamApiClient()
  return client.defaults.baseURL
}

const useAxiosClient = (props) => createClient(props)

export const StreamApiProvider = ({ config, children }) => {
  const client = useAxiosClient(config)
  return (
    <StreamApiContext.Provider value={client}>
      {children}
    </StreamApiContext.Provider>
  )
}
