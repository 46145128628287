import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

/**
 * Create an ApolloClient for Carrot GraphQL calls
 * @param host
 * @param port
 * @param secure
 * @param {function} getHeaders - promise-returning function which resolves with a JS object containing request headers
 * @return {ApolloClient<any>}
 */
export const createGraphClient = ({
  host,
  port = 443,
  secure = true,
  getHeaders = () => Promise.resolve({}),
} = {}) => {
  const uri = getBaseUrl({ host, port, secure })

  const httpLink = createHttpLink({ uri })
  const asyncAuthLink = setContext((operation, { headers }) => {
    return getHeaders().then(
      (runeHeaders) => {
        return {
          headers: {
            ...headers,
            ...runeHeaders,
          },
        }
      },
      (error) => {
        console.error(error)
      },
    )
  })

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: {
        Actor: ['Membership', 'ExternalUser'],
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'none',
      },
    },
    link: from([asyncAuthLink, httpLink]),
  })
}

export const getBaseUrl = function ({ host, port = 443, secure = true }) {
  const protocol = secure ? 'https' : 'http'
  let url = `${protocol}://${host}`
  if ((secure && port !== 443) || (!secure && port !== 80)) {
    url = `${url}:${port}`
  }
  return `${url}/graphql`
}
