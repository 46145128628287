import React from 'react'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

const useStyles = makeStyles((theme) => ({
  inner: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
    width: '100%',
  },
  row: {
    color: 'hsla(0, 0%, 50%, 0.6)',
    display: 'flex',
    fontWeight: 500,
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: 'hsla(0, 0%, 50%, 0.25)',
    fontSize: '4rem',
  },
  message: {
    marginLeft: theme.spacing(1),
  },
}))
const defaultMessage = 'No results found'

const EmptyResults = ({
  className = '',
  icon = 'layers_clear',
  message = defaultMessage,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Box className={`${classes.root} ${className}`} {...rest}>
      <div className={classes.inner}>
        <div className={classes.row}>
          <Icon className={classes.icon}>{icon}</Icon>
        </div>
        <Typography
          className={`${classes.message} ${classes.row}`}
          variant="body1"
        >
          {message}
        </Typography>
      </div>
    </Box>
  )
}

export default EmptyResults
