import { useContext } from 'react'
import CreateEntity from 'ui/components/CreateEntity'
import CreatePatientDialog from './CreatePatientDialog'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { useIsAdmin } from 'ui/hooks'
import UserPHIContext from 'ui/contexts/UserPHIContext'
import AddIcon from '@material-ui/icons/Add'

const CreatePatient = () => {
  const phiVisibility = useContext(UserPHIContext)
  const { admin } = useIsAdmin()

  if (!admin) {
    return null
  }

  return (
    !phiVisibility && (
      <ErrorBoundary>
        <CreateEntity
          label="Add Patient"
          startIcon={<AddIcon fontSize={'inherit'} />}
        >
          <CreatePatientDialog />
        </CreateEntity>
      </ErrorBoundary>
    )
  )
}

export default CreatePatient
