import { string } from 'prop-types'
import { useContext } from 'react'
import { MembershipContext } from 'ui/contexts'
import ProtectedRoute from '../ProtectedRoute'

const ProtectedByAdmin = ({ redirectPath }) => {
  const { admin } = useContext(MembershipContext)

  return <ProtectedRoute isAllowed={admin} redirectPath={redirectPath} />
}

ProtectedByAdmin.propTypes = {
  redirectPath: string.isRequired,
}

ProtectedByAdmin.defaultProps = {}

export default ProtectedByAdmin
