import { Text } from 'ui/baseComponents/Text'
import { Link } from 'ui/baseComponents/Link'
import { colors } from 'theme/colors'
import { clinicianTheme } from 'theme'
import { Box } from '@mui/material'
import { LabelledLoading } from 'ui/components/LabelledLoading'

export const MetricsRow = ({
  title,
  linkText,
  linkHref,
  linkExternal = false,
  isLoading,
  children,
  noBorder,
  sx,
}) => {
  const STYLES = {
    metricRow: {
      borderBottom: noBorder ? 'none' : `1px solid ${colors.GREY[200]}`,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      [clinicianTheme.breakpoints.up('lg')]: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    metricTitle: {
      width: '250px',
    },
    metrics: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flex: 1,
      flexWrap: 'wrap',
    },
    loading: {
      alignItems: 'baseline',
      paddingBottom: '1em',
    },
  }

  return (
    <Box
      sx={{ ...STYLES.metricRow, ...sx }}
      data-cy={`overview-${title.toLowerCase()}`}
    >
      <div style={STYLES.metricTitle}>
        <Text component="h3" variant="head24B">
          {title}
        </Text>
        {linkHref && linkText && (
          <Text variant="body14">
            <Link href={linkHref} external={linkExternal}>
              {linkText}
            </Link>
          </Text>
        )}
      </div>
      <div style={STYLES.metrics}>
        {isLoading ? (
          <LabelledLoading label={`Loading ${title} metrics`} />
        ) : (
          <>{children}</>
        )}
      </div>
    </Box>
  )
}
