import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles = makeStyles((theme) => ({
  maintenanceWarning: {
    flexWrap: 'nowrap',
    textAlign: 'top',
    alignItems: 'end',
    fontWeight: 'bold',
    color: theme?.palette?.text?.primary || '#333333',
    message: {
      alignItems: 'top',
    },
  },
  success: {
    backgroundColor: green[600],
    boxShadow: 'none',
  },
  error: {
    backgroundColor: theme?.palette?.error?.dark || '#8d1b17',
    boxShadow: 'none',
  },
  info: {
    backgroundColor: '#79AED0',
    boxShadow: 'none',
  },
  warning: {
    backgroundColor: theme?.palette?.primary?.warning || '#ffa000',
    boxShadow: 'none',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '1rem',
  },
  message: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '1.2em',
  },
  messageTop: {
    display: 'flex',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '1.2em',
    color: theme?.palette?.text?.primary || '#333333',
  },
  iconTop: {
    marginTop: '-5px',
  },
  // Override MUI's default minimum width for toasts so the toasts
  // are sized more responsively, based on content width rather than
  // set number of pixels
  fitContentWidth: {
    minWidth: 0,
  },
}))

/**
 * Notification Snackbar content wrapper for error
 * @param {object} props for snackbarContentWrapper
 * @returns {JSX.Element} Components for error snackbar.
 */
function SnackbarContentWrapper(props) {
  const classes = useStyles()
  const {
    className,
    message,
    onClose,
    variant,
    hideCloseButton = false,
    ...other
  } = props
  const Icon = variantIcon[variant]

  const snackbarContentStyles = clsx({
    [classes[variant]]: true,
    [classes[className]]: true,
    [classes.fitContentWidth]: hideCloseButton,
  })

  const closeAction = hideCloseButton
    ? []
    : [
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon
            className={
              className === 'maintenanceWarning'
                ? classes.iconTop
                : classes.icon
            }
          />
        </IconButton>,
      ]

  return (
    <SnackbarContent
      className={snackbarContentStyles}
      aria-describedby="client-snackbar"
      message={
        <span
          id="client-snackbar"
          className={
            className === 'maintenanceWarning'
              ? classes.messageTop
              : classes.message
          }
        >
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={closeAction}
      {...other}
    />
  )
}

SnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}

export const Error = ({ message, ...rest }) => (
  <SnackbarContentWrapper variant="error" message={message} {...rest} />
)

export const Success = ({ message, ...rest }) => (
  <SnackbarContentWrapper variant="success" message={message} {...rest} />
)

export const Warning = ({ message, ...rest }) => (
  <SnackbarContentWrapper variant="warning" message={message} {...rest} />
)

export const Info = ({ message, ...rest }) => (
  <SnackbarContentWrapper variant="info" message={message} {...rest} />
)

export const NotificationBar = (props) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    autoHideDuration={6000}
    {...props}
  />
)
