import React from 'react'
import Dot from './LoadingDot'
import LoadingWrapper from './LoadingWrapper'

const LoadingAnimation = () => (
  <LoadingWrapper aria-label="Loading animation" data-cy="loading-animation">
    <Dot delay="0s" />
    <Dot delay="0.5s" />
    <Dot delay="1s" />
  </LoadingWrapper>
)

LoadingAnimation.propTypes = {}

LoadingAnimation.defaultProps = {}

export default LoadingAnimation
