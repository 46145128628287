import { makeStyles } from '@material-ui/core'
import { arrayOf, string } from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  tooltipText: {
    marginLeft: -theme.spacing(2),
  },
}))

const BulletedTooltipContent = ({ bulletedContent }) => {
  const classes = useStyles()

  return (
    <ul className={classes.tooltipText}>
      {bulletedContent.map((text, index) => (
        <li key={index}>{text}</li>
      ))}
    </ul>
  )
}

export default BulletedTooltipContent

BulletedTooltipContent.propTypes = {
  bulletedContent: arrayOf(string),
}

BulletedTooltipContent.defaultProps = {
  bulletedContent: [],
}
