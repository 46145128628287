import { gql } from '@apollo/client'
import moment from 'moment'
import {
  TASK_RECURRENCE_ONCE,
  TASK_REPEATS_ON,
  TASK_REPEATS_EVERY,
} from 'ui/screens/Patients/TaskNotifications/consts.js'

export const CREATE_TASK_NOTIFICATION = gql`
  mutation createTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      task {
        id
      }
    }
  }
`

export const UPDATE_TASK_NOTIFICATION = gql`
  mutation updateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`

export const isRequestReady = (taskNotificationContextValue) => {
  const {
    taskTitle,
    taskSummary,
    taskDescription,
    taskRecurrence,
    singleTaskDate,
    singleTaskTime,
    recurringTaskStartDate,
    recurringTaskDoesEnd,
    recurringTaskEndDate,
    taskRepeatsWeekly,
    taskRepeatDaysArray,
    taskRepeatDaysInteger,
    taskScheduleTimesArray,
  } = taskNotificationContextValue

  // Prevent the user from saving empty spaces as the task title.
  if (!taskTitle.trim().length) {
    return false
  }

  const sharedFields = !!taskTitle && !!taskSummary && !!taskDescription

  if (
    taskRecurrence === TASK_RECURRENCE_ONCE &&
    sharedFields &&
    singleTaskDate &&
    singleTaskTime
  ) {
    return true
  } else {
    if (
      taskRepeatsWeekly === TASK_REPEATS_ON &&
      sharedFields &&
      recurringTaskStartDate &&
      (recurringTaskDoesEnd === 'false' || recurringTaskEndDate) &&
      taskRepeatDaysArray.length &&
      taskScheduleTimesArray.length
    ) {
      return true
    }

    if (
      taskRepeatsWeekly === TASK_REPEATS_EVERY &&
      recurringTaskStartDate &&
      (recurringTaskDoesEnd === 'false' || recurringTaskEndDate) &&
      Number(taskRepeatDaysInteger) > 0 &&
      taskScheduleTimesArray.length
    ) {
      return true
    }
  }

  return false
}

export const buildTaskNotificationRequestObject = (
  taskNotificationContextValue,
) => {
  const { taskRecurrence, taskRepeatsWeekly } = taskNotificationContextValue

  if (taskRecurrence === TASK_RECURRENCE_ONCE) {
    return buildTaskNotificationRequestObjectOnce(taskNotificationContextValue)
  }

  if (taskRepeatsWeekly === TASK_REPEATS_ON) {
    return buildTaskNotificationRequestObjectWeekly(
      taskNotificationContextValue,
    )
  }

  return buildTaskNotificationRequestObjectInterval(
    taskNotificationContextValue,
  )
}

export const buildTaskNotificationRequestObjectOnce = (
  taskNotificationContextValue,
) => {
  const {
    taskTitle,
    taskSummary,
    taskDescription,
    singleTaskDate,
    singleTaskTime,
    taskPatientList,
  } = taskNotificationContextValue

  const HOURS_IN_DAY_MINUS_ONE = 23
  const formattedStartTime = `${singleTaskDate}T${singleTaskTime}`
  const formattedEndTime = moment(formattedStartTime)
    .add(HOURS_IN_DAY_MINUS_ONE, 'hour')
    .format('YYYY-MM-DDTHH:mm')

  return {
    title: taskTitle,
    notificationText: taskSummary,
    introductionText: taskDescription,
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    schedules: [
      {
        scheduleType: 'CALENDAR_CLOCK_SCHEDULE',
        times: [singleTaskTime],
      },
    ],
    patients: taskPatientList,
  }
}

export const buildTaskNotificationRequestObjectWeekly = (
  taskNotificationContextValue,
) => {
  const {
    taskTitle,
    taskSummary,
    taskDescription,
    recurringTaskStartDate,
    recurringTaskDoesEnd,
    recurringTaskEndDate,
    taskRepeatDaysArray,
    taskScheduleTimesArray,
    taskPatientList,
  } = taskNotificationContextValue

  const formattedStartTime = recurringTaskStartDate
  const formattedEndTime =
    recurringTaskDoesEnd === 'true' ? recurringTaskEndDate : null

  return {
    title: taskTitle,
    notificationText: taskSummary,
    introductionText: taskDescription,
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    schedules: [
      {
        scheduleType: 'CALENDAR_CLOCK_SCHEDULE',
        daysOfWeek: taskRepeatDaysArray,
        times: taskScheduleTimesArray,
      },
    ],
    patients: taskPatientList,
  }
}

export const buildTaskNotificationRequestObjectInterval = (
  taskNotificationContextValue,
) => {
  const {
    taskTitle,
    taskSummary,
    taskDescription,
    recurringTaskStartDate,
    recurringTaskDoesEnd,
    recurringTaskEndDate,
    taskRepeatDaysInteger,
    taskScheduleTimesArray,
    taskPatientList,
  } = taskNotificationContextValue

  const formattedStartTime = recurringTaskStartDate
  const formattedEndTime =
    recurringTaskDoesEnd === 'true' ? recurringTaskEndDate : null
  const formattedInterval = parseInt(taskRepeatDaysInteger)

  return {
    title: taskTitle,
    notificationText: taskSummary,
    introductionText: taskDescription,
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    schedules: [
      {
        scheduleType: 'INTERVAL_CLOCK_SCHEDULE',
        dateInterval: formattedInterval,
        times: taskScheduleTimesArray,
      },
    ],
    patients: taskPatientList,
  }
}
