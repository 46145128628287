import { useCallback, useContext, useState } from 'react'
import {
  Button,
  FormControl,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import SignInContext from 'ui/contexts/SignInContext'
import FormWrapper from './FormWrapper'
import { PasswordVisibilityToggler } from 'ui/screens/SignIn/PasswordVisibilityToggler'
import {
  COGNITO_ATTR_DEFAULT_LOGIN_PORTAL,
  DEFAULT_LOGIN_PORTAL_STRIVE_STUDY,
  DEFAULT_LOGIN_PORTAL_STRIVE_STUDY_REQUIRED,
  SUBMIT_BUTTON,
  SUBMIT_ROW,
  TERMS_AND_PRIVACY_NOTICE_AGREEMENT,
} from './consts'
import { getCurrentUser } from 'domains/auth/actions'

export const STRIVE_STUDY_URL = `https://${window?.Rune?.Carrot?.config?.striveStudy?.host}`

const useStyles = makeStyles(() => ({
  submitButton: {
    ...SUBMIT_BUTTON,
  },
  submitRow: {
    ...SUBMIT_ROW,
  },
}))

export const CreateNewPasswordForm = ({ user }) => {
  const { error, password, setError, setPassword } = useContext(SignInContext)

  const classes = useStyles()

  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const completeNewPasswordChallenge = useCallback(
    (e) => {
      e.preventDefault()

      if (!password) {
        return
      }

      user.completeNewPasswordChallenge(
        password,
        { 'custom:version': '1' },
        {
          onSuccess: function () {
            // Get the user's default login portal config to determine redirect
            getCurrentUser(user)
              .then((result) => {
                if (
                  result[COGNITO_ATTR_DEFAULT_LOGIN_PORTAL] ===
                    DEFAULT_LOGIN_PORTAL_STRIVE_STUDY ||
                  result[COGNITO_ATTR_DEFAULT_LOGIN_PORTAL] ===
                    DEFAULT_LOGIN_PORTAL_STRIVE_STUDY_REQUIRED
                ) {
                  window.location.href = STRIVE_STUDY_URL
                } else {
                  window.location.href = '/'
                }
              })
              .catch((error) => {
                console.error(error)
              })
          },
          onFailure: function (reason) {
            setError(
              (reason && reason.message) ||
                'Oops! An unexpected error occurred',
            )
          },
        },
      )
    },
    [password, setError, user],
  )

  return (
    <FormWrapper
      onSubmit={completeNewPasswordChallenge}
      termsAndPrivacyNoticeAgreementText={
        TERMS_AND_PRIVACY_NOTICE_AGREEMENT.newUser
      }
    >
      <FormControl fullWidth>
        <Typography variant="subtitle1">
          Please choose a new password
        </Typography>
        {/* TODO: Update styles when error */}
        <TextField
          inputProps={{ 'data-cy': 'password' }}
          error={!!error}
          helperText={error}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          value={password}
          InputProps={{
            endAdornment: (
              <PasswordVisibilityToggler
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
              />
            ),
          }}
        />
        <Typography variant="caption">
          Must be at least 8 characters long.
        </Typography>
      </FormControl>
      <FormControl fullWidth className={classes.submitRow}>
        <Button
          color="primary"
          disabled={!password}
          type="submit"
          variant="contained"
          className={classes.submitButton}
        >
          Continue
        </Button>
      </FormControl>
    </FormWrapper>
  )
}

export default CreateNewPasswordForm
