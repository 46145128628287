import { createContext, useCallback, useState } from 'react'

export const RecentDataAvailabilityContext = createContext({
  data: {
    latestActivityDate: null,
    sleep: null,
    tremorAndDyskinesia: null,
    mobility: null,
  },
  hasError: null,
  isLoading: null,
  error: null,
})

export const useRecentDataAvailabilityContext = () => {
  const [recentAvailabilityData, setRecentAvailabilityData] = useState(null)
  return {
    recentAvailabilityData,
    setRecentAvailabilityData: useCallback((data) => {
      setRecentAvailabilityData(data)
    }, []),
  }
}

export default RecentDataAvailabilityContext
