export const translate = (objectToTranslate) => {
  const [key, nestedKey] = objectToTranslate.split('.')
  return key && nestedKey
    ? translationObject[key][nestedKey]
    : translationObject[key]
}

const translationObject = {
  clinical: {
    last_visit: 'Last Visit',
    no_change_since_last_visit: 'No change since last visit',
    patients: 'Patients',
    team: 'Team',
  },
  general: {
    account: 'Account',
    my_account: 'My Account',
    add: 'Add',
    add_new: 'Add New',
    cancel: 'Cancel',
    close: 'Close',
    date: 'Date',
    day: 'Day',
    email: 'Email Address',
    last: 'Last',
    login: 'Log in',
    logo: 'Logo',
    more: 'More',
    name: 'Name',
    open: 'Open',
    password: 'Password',
    profile: 'Profile',
    save: 'Save',
    select: 'Select',
    show: 'Show',
    submit: 'Submit',
    tabs: 'Tabs',
    type: 'Type',
    view: 'View',
  },
  placeholder: {
    a_modal: 'A Modal!',
  },
  patient: {
    adherence: 'Adherence',
    date_of_birth: 'Date of Birth',
    dosage: 'Medication Dosage',
    doses_taken: 'Doses Taken',
    event: 'Event',
    medication_adherence: 'Medication Adherence',
    medications: 'Medications',
    medication_schedule: 'Medication Schedule',
    missed_doses: 'Missed Doses',
    note: 'Note',
    pair: 'Pair',
    post_dose_symptoms: 'Post-Dose Symptoms Over Time',
    supplements: 'Supplements',
    state_by_dose_window: 'State by Dose Window',
    symptom: 'Symptom',
    top_patient_reported_symptoms: 'Top Patient-Reported Symptoms',
  },
  pd: {
    beta_power: 'Beta Power',
    beta_power_change: 'Change in Beta Power',
    condensed_motor_diary: 'Condensed Motor Diary',
    comparative_motor_diary: 'Comparative Motor Diary',
    kinetic_state: 'Hypo/Hyperkinetic State',
  },
  researcher: {
    stream_builder: 'Stream Builder',
  },
}
