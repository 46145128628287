import React from 'react'
import clsx from 'clsx'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { RUNE_WHITE } from 'theme/colors'
import { SECTION_TITLE } from 'ui/clinicianScreens/Patient/consts'

const useStyles = makeStyles(() => ({
  list: {
    paddingTop: '1.75rem',
  },
  listText: {
    marginRight: '0.625rem',
    flex: '0 1 auto',
    width: 'max-content',
  },
  listBox: {
    display: 'contents',
  },
}))

const ColoredCirclesList = ({ listItems, sectionTitle }) => {
  const classes = useStyles()

  const listStyle = clsx({
    [classes.list]: sectionTitle !== SECTION_TITLE.sleep,
  })

  return (
    <List className={listStyle}>
      {listItems.map((listItem, mainKey) => (
        <ListItem key={mainKey}>
          {listItem.name.map((item, key) => (
            <Box key={key} className={classes.listBox}>
              <FiberManualRecordIcon
                style={
                  listItem.colors[key] === RUNE_WHITE
                    ? {
                        color: listItem.colors[key],
                        border: '1px solid #aaaaaa',
                        borderRadius: '20px',
                        marginLeft: '3px',
                        marginRight: '5px',
                        fontSize: '18px',
                      }
                    : { color: listItem.colors[key] }
                }
              />
              <ListItemText className={classes.listText} primary={item} />
            </Box>
          ))}
        </ListItem>
      ))}
    </List>
  )
}

export default ColoredCirclesList
