import { STREAM_TYPE_ACCELERATION } from './acceleration'

export const STREAM_ACCELERATION_NO_GRAVITY = {
  value: 'accelNoGravity',
  endpoint: 'accel',
  csvSupported: true,
  label: 'Acceleration (no gravity)',
  streamType: STREAM_TYPE_ACCELERATION,
  queryParams: {
    expression: 'user',
  },
}
