export const HTTP_METHOD = {
  get: 'get',
}

export const HEADER = {
  patientId: 'X-Rune-Patient-Id',
}

export const AGGREGATE_WINDOW_METRIC_TYPES = {
  walkingSpeed: 'walking_speed',
  doubleSupport: 'walking_double_support_percentage',
  stepLength: 'walking_step_length',
}
