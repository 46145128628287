import React from 'react'
import { Outlet } from 'react-router-dom'
import DefaultLayout from 'ui/components/DefaultLayout'
import SettingsSidebar from './SettingsSidebar'

/**
 * Layout and outlet for the "settings" view
 * @returns {JSX.Element} Default layout and outlet for Settings view
 */
const Settings = () => (
  <DefaultLayout sidebar={<SettingsSidebar />}>
    <Outlet />
  </DefaultLayout>
)

export default Settings
