export const MOBILITY_METRIC_TYPES = {
  walkingSpeed: 'Walking Speed',
  doubleSupport: 'Double Support',
  stepLength: 'Step Length',
}

export const WEEKLY_VIEW_TABLE_HEADINGS = {
  date: 'Date',
  pros: 'PROs',
  notes: 'Notes',
  medication: 'Medication',
  stepLength: MOBILITY_METRIC_TYPES.stepLength,
  walkingSpeed: MOBILITY_METRIC_TYPES.walkingSpeed,
  doubleSupport: MOBILITY_METRIC_TYPES.doubleSupport,
}
