import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { NEW_MEDICATION_DAYS_THRESHOLD, PD_MEDICATIONS } from './consts'
import { isSameOrNewerThanNDaysAgo } from 'utilities/time'
import moment from 'moment'

export const isPdMedication = (item) =>
  PD_MEDICATIONS.includes(item?.classification?.enum)

export const getNewMedicationData = (medCreationDate, selectedTimezone) => {
  const isNewMedication = isSameOrNewerThanNDaysAgo(
    medCreationDate,
    NEW_MEDICATION_DAYS_THRESHOLD,
  )
  const newMedicationDate = moment
    .unix(medCreationDate)
    .tz(selectedTimezone)
    .format('MM/DD/YYYY')
  const newMedicationDateAgo = moment
    .tz(selectedTimezone)
    .diff(newMedicationDate, 'days')
  return {
    isNewMedication,
    newMedicationDate,
    newMedicationDateAgo,
  }
}

export const formatTableDataObject = ({
  id,
  name,
  customData = [],
  customStyles = [],
}) => [
  {
    key: `medication-cell-${id}`,
    value: name,
    sx: customStyles[0] || {},
  },
  ...customData.map((item, index) => ({
    key: `custom-cell-${id}-${index}`,
    value: item,
    sx: customStyles[index + 1] || {},
  })),
]

export const formatMedicationDosage = (dosage, unit, unitQuantity) => {
  if (!dosage && !unitQuantity) return ''
  if (dosage && unitQuantity) {
    return `${dosage} x ${unitQuantity}${unit}`
  } else if (dosage && !unitQuantity) {
    return dosage
  } else {
    return unitQuantity + unit
  }
}

export const parseUniqueLoggedMedicationByNameAndDosage = (
  meds,
  resultMap,
  isPreviousData = false,
) => {
  meds.forEach((med) => {
    const { payload = {} } = med
    const { dosage, dosage_strength: { quantity, unit } = {} } =
      JSON.parse(payload)
    const formattedDosage = formatMedicationDosage(dosage, unit, quantity)
    const medName = getEventName(med)
    const current = resultMap.get(`${medName}|${formattedDosage}`)
    const countToCheck = isPreviousData ? 'prevCount' : 'count'
    const newCount = (current?.[countToCheck] || 0) + 1

    resultMap.set(`${medName}|${formattedDosage}`, {
      name: medName,
      dosage: formattedDosage,
      count: isPreviousData ? current?.count || 0 : newCount,
      prevCount: isPreviousData ? newCount : current?.prevCount || 0,
    })
  })
}
