import { createContext, useCallback, useState } from 'react'

/**
 * Persist state of snackbar notifications.
 */
export const SnackbarContext = createContext()

const useSnackbarContext = () => {
  const [notificationType, setNotificationType] = useState('')
  const [snackbarMessage, setSnackbarMessage] = useState('')

  return {
    notificationType,
    setNotificationType: useCallback((value) => setNotificationType(value), []),
    snackbarMessage,
    setSnackbarMessage: useCallback((value) => setSnackbarMessage(value), []),
    resetSnackbar: () => {
      setSnackbarMessage('')
      setNotificationType('')
    },
  }
}

export default useSnackbarContext
