import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { RUNE_GRAY_400 } from 'theme/colors'
import { LEGEND_TEXT_STYLE } from './consts'

const useStyles = makeStyles(() => ({
  legendNoDataCircle: {
    width: 18,
    height: 18,
    borderRadius: 9,
    backgroundColor: RUNE_GRAY_400,
    margin: '0 10px',
    paddingLeft: '18px',
  },
  legendNoDataText: {
    ...LEGEND_TEXT_STYLE,
  },
}))

const LegendNoDataIndicator = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.legendNoDataCircle} />
      <Typography className={classes.legendNoDataText}>No Data</Typography>
    </>
  )
}

LegendNoDataIndicator.propTypes = {}

LegendNoDataIndicator.defaultProps = {}

export default LegendNoDataIndicator
