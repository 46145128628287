export const TITLE = 'Patient Log'

/**
 * Date format used display in the Patient Log component(s).
 */
export const DATE_FORMAT = 'MMM D, h:mm A'

/**
 * Event type strings used as keys from the API response.
 */
const EVENT_TYPES_CATEGORY = {
  symptom: 'symptom',
  medication: 'medication',
  supplement: 'supplement',
  note: 'note',
  foodDrink: 'food-drink',
  activity: 'activity',
  mood: 'mood',
  trigger: 'trigger',
  sideEffect: 'side-effect',
  sleep: 'sleep',
  wellbeing: 'wellbeing',
  dbs: 'dbs',
}

/**
 * Event type strings to be displayed in the UI.
 */
const EVENT_TYPES_DISPLAY = {
  symptom: 'Symptom',
  medication: 'Medication',
  supplement: 'Supplement',
  note: 'Note',
  foodDrink: 'Food/Drink',
  activity: 'Activity',
  mood: 'Mood',
  trigger: 'Trigger',
  sideEffect: 'Side Effect',
  sleep: 'Sleep',
  wellbeing: 'Well-Being',
  dbs: 'DBS',
}

/**
 * Mapping from API event type strings to UI event type strings.
 */
export const EVENT_TYPES_MAPPING = {
  [EVENT_TYPES_CATEGORY.symptom]: EVENT_TYPES_DISPLAY.symptom,
  [EVENT_TYPES_CATEGORY.medication]: EVENT_TYPES_DISPLAY.medication,
  [EVENT_TYPES_CATEGORY.note]: EVENT_TYPES_DISPLAY.note,
  [EVENT_TYPES_CATEGORY.foodDrink]: EVENT_TYPES_DISPLAY.foodDrink,
  [EVENT_TYPES_CATEGORY.activity]: EVENT_TYPES_DISPLAY.activity,
  [EVENT_TYPES_CATEGORY.mood]: EVENT_TYPES_DISPLAY.mood,
  [EVENT_TYPES_CATEGORY.trigger]: EVENT_TYPES_DISPLAY.trigger,
  [EVENT_TYPES_CATEGORY.sideEffect]: EVENT_TYPES_DISPLAY.sideEffect,
  [EVENT_TYPES_CATEGORY.sleep]: EVENT_TYPES_DISPLAY.sleep,
  [EVENT_TYPES_CATEGORY.supplement]: EVENT_TYPES_DISPLAY.supplement,
  [EVENT_TYPES_CATEGORY.wellbeing]: EVENT_TYPES_DISPLAY.wellbeing,
  [EVENT_TYPES_CATEGORY.dbs]: EVENT_TYPES_DISPLAY.dbs,
}
