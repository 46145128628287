import { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  FormControl,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { validate as validateEmail } from 'email-validator'
import { confirmPassword as confirmPasswordAction } from 'domains/auth/actions'
import SignInContext from 'ui/contexts/SignInContext'
import FormWrapper from '../FormWrapper'
import { PasswordVisibilityToggler } from 'ui/screens/SignIn/PasswordVisibilityToggler'
import {
  SUBMIT_ROW,
  INVALID_PASSWORD_ERROR,
  INVALID_VERIFICATION_CODE_ERROR,
  TERMS_AND_PRIVACY_NOTICE_AGREEMENT,
} from '../consts'

const useStyles = makeStyles(() => ({
  submitRow: {
    ...SUBMIT_ROW,
  },
}))

/**
 * ResetPasswordForm component. Allows user to enter the verification code
 * they received via email and a new password to reset their password
 * @return {*} ResetPasswordForm component
 * @constructor
 */
const ResetPasswordForm = () => {
  const {
    email,
    error,
    password,
    setError,
    setForgotPassword,
    setPassword,
    setPasswordResetSuccessful,
    setVerificationCode,
    verificationCode,
  } = useContext(SignInContext)
  const [submitting, setSubmitting] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const dispatch = useDispatch()

  const classes = useStyles()

  const handleConfirmPasswordSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      if (submitting) {
        return
      }
      try {
        setSubmitting(true)
        await dispatch(
          confirmPasswordAction({
            username: email,
            verificationCode,
            newPassword: password,
          }),
        )
        setError(false)
        setPasswordResetSuccessful(true)
        setSubmitting(false)
        setForgotPassword(false)
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setError(error)
      }
    },
    [
      submitting,
      dispatch,
      email,
      verificationCode,
      password,
      setError,
      setPasswordResetSuccessful,
      setForgotPassword,
    ],
  )

  const showVerificationCodeError = (error) =>
    !!(
      error?.name === 'CodeMismatchException' ||
      (error?.name === 'InvalidParameterException' &&
        error?.message.includes('confirmationCode'))
    )

  const showPasswordError = (error) =>
    !!(
      error?.name === 'InvalidPasswordException' ||
      (error?.name === 'InvalidParameterException' &&
        error?.message.includes('password'))
    )

  return (
    <FormWrapper
      onSubmit={handleConfirmPasswordSubmit}
      termsAndPrivacyNoticeAgreementText={
        TERMS_AND_PRIVACY_NOTICE_AGREEMENT.existingUser
      }
    >
      <FormControl fullWidth>
        <FormControl fullWidth>
          <Typography variant="body1">
            You should receive an email containing a verification code. If you
            don't receive one within a few minutes, please contact
            support@runelabs.io
          </Typography>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            autoFocus
            label="Verification Code"
            margin="normal"
            data-cy="verification-code"
            error={showVerificationCodeError(error)}
            helperText={
              showVerificationCodeError(error)
                ? INVALID_VERIFICATION_CODE_ERROR
                : ''
            }
            onChange={(e) => {
              setVerificationCode(e.target.value)
              setError(false)
            }}
            value={verificationCode}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            margin="normal"
            data-cy="password"
            error={showPasswordError(error)}
            helperText={showPasswordError(error) ? INVALID_PASSWORD_ERROR : ''}
            onChange={(e) => {
              setPassword(e.target.value)
              setError(false)
            }}
            value={password}
            InputProps={{
              endAdornment: (
                <PasswordVisibilityToggler
                  showPassword={showPassword}
                  toggleShowPassword={toggleShowPassword}
                />
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth className={classes.submitRow}>
          <Button
            color="primary"
            disabled={submitting || !validateEmail(email)}
            type="submit"
            variant="contained"
          >
            Reset Password
          </Button>
        </FormControl>
      </FormControl>
    </FormWrapper>
  )
}

export default ResetPasswordForm
