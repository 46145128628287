import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { colors } from 'theme/colors'

const RedFlagIcon = ({ styles }) => {
  const STYLES = {
    redFlagIcon: {
      backgroundColor: colors.ORANGE[100],
      color: colors.ORANGE[500],
      borderRadius: '100%',
      padding: '0.15rem',
      verticalAlign: 'middle',
      ...styles,
    },
  }
  return (
    <PriorityHighIcon
      sx={STYLES.redFlagIcon}
      data-cy="red-flag-icon"
      fontSize="small"
    />
  )
}

export default RedFlagIcon
