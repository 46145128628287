import { useContext, useEffect, useState } from 'react'
import FormFieldWrapper from 'ui/components/FormFieldWrapper'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import ErrorFrame from 'ui/components/ErrorFrame'
import Loading from 'ui/components/Loading'
import { TaskNotificationContext } from 'ui/contexts'
import { usePatientList } from 'ui/hooks'
import {
  Box,
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { RUNE_GRAY_100, RUNE_GRAY_300, RUNE_GRAY_900 } from 'theme/colors'
import { makeStyles } from '@material-ui/core/styles'
import useBoundStore from 'domains/zustand/store'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    maxHeight: 400,
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginTop: theme.spacing(0.5),
    backgroundColor: RUNE_GRAY_100,
  },
  innerList: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  listItem: {
    padding: theme.spacing(0.75),
    marginLeft: theme.spacing(1.5),
  },
  listHeaderContainer: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.25),
    borderBottom: `1px solid ${RUNE_GRAY_300}`,
  },
  loadingPadding: {
    paddingTop: theme.spacing(10),
  },
  numberSelected: {
    fontSize: '0.5rem',
    marginLeft: 'auto',
    color: RUNE_GRAY_900,
    fontWeight: 'bold',
  },
  sort: {
    fontSize: '0.5rem',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sortIcon: {
    fontSize: '1rem',
    position: 'absolute',
    top: '-3px',
    right: '-24px',
  },
}))

/**
 * The Task PatientSelect section of the Create Task form.
 *
 * @returns {JSX.Element} Task Patient Select Checkbox list
 */
export const TaskPatientSelect = () => {
  const [sortAsc, setSortAsc] = useState(true)
  const classes = useStyles()
  const {
    taskPatientList,
    onTaskPatientListCheck,
    onTaskPatientListSelectAll,
  } = useContext(TaskNotificationContext)

  usePatientList({ limit: 200, fetchAll: true })
  const errorPatientList = useBoundStore((state) => state.errorPatientList)
  const loadingPatientList = useBoundStore((state) => state.loadingPatientList)
  const patients = useBoundStore((state) => state.patients)
  const resetPatientList = useBoundStore((state) => state.resetPatientList)

  useEffect(() => {
    resetPatientList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (errorPatientList) {
    return <ErrorFrame />
  }

  if (loadingPatientList) {
    return (
      <ErrorBoundary>
        <FormFieldWrapper label="Participant List">
          <div className={classes.loadingPadding}>
            <Loading />
          </div>
        </FormFieldWrapper>
      </ErrorBoundary>
    )
  }

  const allSelected = taskPatientList.length === patients?.length
  const selectAllLabel = allSelected ? 'Deselect All' : 'Select All'

  const reversedPatients = patients.slice(0).reverse()
  const sortedPatients = sortAsc ? patients : reversedPatients

  return (
    <ErrorBoundary>
      <FormFieldWrapper label="Participant List">
        <div className={classes.listContainer}>
          <Box display="flex" className={classes.listHeaderContainer}>
            <Typography
              className={classes.sort}
              onClick={() => setSortAsc((x) => !x)}
            >
              Patient ID
              {sortAsc ? (
                <ArrowDropDownIcon className={classes.sortIcon} />
              ) : (
                <ArrowDropUpIcon className={classes.sortIcon} />
              )}
            </Typography>
            <Typography className={classes.numberSelected}>
              {taskPatientList.length} selected
            </Typography>
          </Box>
          <FormGroup className={classes.innerList}>
            <FormControlLabel
              key="selectAll"
              control={
                <Checkbox
                  checked={allSelected}
                  className={classes.listItem}
                  onChange={() => onTaskPatientListSelectAll(patients)}
                  name="Select All"
                  color="primary"
                  disableRipple
                />
              }
              label={selectAllLabel}
            />
            {patients &&
              sortedPatients.map(({ id, preComputedName }, i) => (
                <FormControlLabel
                  key={id + i}
                  control={
                    <Checkbox
                      checked={taskPatientList.includes(id)}
                      className={classes.listItem}
                      onChange={() => onTaskPatientListCheck(id)}
                      name={preComputedName}
                      color="primary"
                      disableRipple
                      value={id}
                    />
                  }
                  label={preComputedName}
                />
              ))}
          </FormGroup>
        </div>
      </FormFieldWrapper>
    </ErrorBoundary>
  )
}

export default TaskPatientSelect
