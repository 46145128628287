import { memo } from 'react'
import Loading from '../Loading'

export const defaultHeight = 300

const ChartLoading = ({ height = defaultHeight, ...rest }) => (
  <Loading data-cy="chart-loading" height={height} {...rest} />
)

export default memo(ChartLoading)
