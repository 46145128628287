import React from 'react'
import EllipsisLoader from '../EllipsisLoader'
import Box from '@material-ui/core/Box'

const Loading = (props) => (
  <Box display="flex" justifyContent="center" alignItems="center" {...props}>
    <EllipsisLoader align="center" />
  </Box>
)

export default Loading
