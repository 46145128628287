import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { createGraphClient } from './client'

/**
 * Wrapper to allow the provision of an ApolloClient via an ApolloProvider.
 * Handles creation of a default ApolloClient, or one can be passed in via `client` prop
 * @param host
 * @param port
 * @param secure
 * @param getHeaders
 * @param client
 * @param children
 * @return {*}
 * @constructor
 */
export const CarrotGraphProvider = ({
  host,
  port = 443,
  secure = true,
  getHeaders = () => Promise.resolve({}),
  client,
  children,
}) => {
  client = client || createGraphClient({ host, port, secure, getHeaders })
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default CarrotGraphProvider
