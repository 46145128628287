import { memo } from 'react'
import { number, oneOf, string } from 'prop-types'
import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { InformationalIconTooltip } from 'ui/components/InformationalIconTooltip'
import Remove from '@mui/icons-material/Remove'
import {
  RUNE_GRAY_500,
  RUNE_GRAY_600,
  RUNE_GRAY_800,
  RUNE_GRAY_900,
} from 'theme/colors'
import SummaryMetricTrend from './SummaryMetricTrend'
import { LABELS, SCREEN_READER_NO_DATA_TEXT, TREND } from './consts'
import { Text } from 'ui/baseComponents/Text'

const StyledMetricValue = styled(Typography)({
  color: RUNE_GRAY_800,
  fontSize: '2.5rem',
  lineHeight: '1',
  paddingTop: '0.25rem',
  paddingBottom: '0.50rem',
})
const StyledMinMaxText = styled(Typography)({
  color: RUNE_GRAY_600,
  fontWeight: 500,
  fontSize: '0.875rem',
})
const StyledMinMaxValue = styled('span')({
  color: RUNE_GRAY_900,
  fontWeight: 400,
})
const StyledRemoveIcon = styled(Remove)({
  color: RUNE_GRAY_500,
  fontSize: '2.5rem',
  verticalAlign: 'middle',
})

const SummaryMetric = ({
  trend,
  title,
  metrics,
  minMetrics,
  maxMetrics,
  tooltip,
  isInComparisonMode,
  sx,
  children,
}) => {
  let renderMetric
  if (!metrics || Object.values(metrics).every((value) => !value)) {
    renderMetric = (
      <span>
        <StyledRemoveIcon
          data-cy={`metric-no-data-icon-${title}`}
          data-testid={`metric-no-data-icon-${title}`}
        />
        <Typography variant="srOnly">{SCREEN_READER_NO_DATA_TEXT}</Typography>
      </span>
    )
  } else {
    renderMetric = Object.entries(metrics).map(([key, value]) => (
      <span
        key={`${title}-${key}`}
        data-testid={`summary-metric-${title}-${key}`}
      >
        <Text variant="head32L">{value}</Text>
        <Text variant="head24L">{key === 'days' ? ` ${key}` : key}</Text>
      </span>
    ))
  }

  const renderMinMaxItem = (label, metrics) => (
    <Grid item>
      <StyledMinMaxText>
        <span>{label} </span>
        {Object.entries(metrics).map(([key, value]) => (
          <span
            key={`${label}-${title}-${key}`}
            data-testid={`summary-metric-${label}-${title}-${key}`}
          >
            <StyledMinMaxValue>{value}</StyledMinMaxValue>
            {key}
          </span>
        ))}
      </StyledMinMaxText>
    </Grid>
  )

  let renderMinMaxValues

  if (minMetrics || maxMetrics) {
    const minItem = renderMinMaxItem(LABELS.min, minMetrics)
    const maxItem = renderMinMaxItem(LABELS.max, maxMetrics)
    renderMinMaxValues = (
      <Grid container direction="row" spacing={1}>
        {minItem}
        {maxItem}
      </Grid>
    )
  }

  return (
    <div
      data-cy={`summary-metric-${title}`}
      style={{ ...sx, width: '250px', padding: '10px 0' }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          paddingRight: '2em',
        }}
      >
        <Text variant="caps12B">{title}</Text>
        {tooltip && (
          <InformationalIconTooltip
            placement="bottom-start"
            title={<Box>{tooltip}</Box>}
          />
        )}
      </Grid>
      <Grid item>
        {children || (
          <StyledMetricValue>
            {renderMetric}
            {trend && !isInComparisonMode && (
              <span>
                &nbsp;
                <SummaryMetricTrend trend={trend} />
              </span>
            )}
          </StyledMetricValue>
        )}
      </Grid>
      {renderMinMaxValues}
    </div>
  )
}

SummaryMetric.propTypes = {
  max: number,
  min: number,
  trend: oneOf([TREND.down, TREND.up, TREND.none]),
  metricUnit: string,
  metricValue: number || string,
  title: string.isRequired,
}

SummaryMetric.defaultProps = {}

export default memo(SummaryMetric)
