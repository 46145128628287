import {
  DEVICE_TYPE_APPLE_WATCH,
  DEVICE_TYPE_STRIVE_STUDY,
  DEVICE_TYPE_EVENTS,
} from 'ui/screens/EditPatient/Devices/consts'
import {
  STREAM_ACCELERATION,
  STREAM_ACCELERATION_NO_GRAVITY,
  STREAM_EVENTS,
  STREAM_HEART_RATE,
  STREAM_ROTATION,
  STREAM_PROB_DYSKINESIA,
  STREAM_PROB_TREMOR,
  STREAM_PROB_TREMOR_SEVERITY,
} from '../StreamTypes'

/**
 * @param {object} device,
 * @param {boolean} isLoggedIntoClinicianPortalAndCanSeePHI, indicates whether or not the user is logged into the clinician portal and has access to PHI
 * @returns {array} Return possible streams for a given device based on its type.
 * The first option in the array is considered the 'default'.
 */
export const getStreamOptionsForDevice = (
  device,
  isLoggedIntoClinicianPortalAndCanSeePHI,
) => {
  // For now, check that deviceType.id is either the displayName OR the id
  // Some legacy devices still have ids that are the
  switch (device.deviceType.id) {
    case DEVICE_TYPE_APPLE_WATCH.displayName:
    case DEVICE_TYPE_APPLE_WATCH.id: {
      /*
       * Apple Watch
       */
      const streams = [
        STREAM_ACCELERATION,
        STREAM_ACCELERATION_NO_GRAVITY,
        STREAM_HEART_RATE,
        STREAM_PROB_DYSKINESIA,
        STREAM_PROB_TREMOR,
        STREAM_ROTATION,
      ]

      // If the current user is not a clinician, we include percentage by severity for tremor data.
      const isResearcherUser =
        typeof isLoggedIntoClinicianPortalAndCanSeePHI !== 'undefined' &&
        !isLoggedIntoClinicianPortalAndCanSeePHI
      const DELETE_COUNT = 0

      if (isResearcherUser) {
        const insertAfterIndex = streams.indexOf(STREAM_PROB_TREMOR)
        const distanceFromInsertionIndex = 1
        const INSERTION_INDEX = insertAfterIndex + distanceFromInsertionIndex

        streams.splice(
          INSERTION_INDEX,
          DELETE_COUNT,
          STREAM_PROB_TREMOR_SEVERITY,
        )
      }
      return streams
    }

    case DEVICE_TYPE_EVENTS.displayName:
    case DEVICE_TYPE_EVENTS.id: {
      /*
       * Mixed event stream
       */
      return [STREAM_EVENTS]
    }
    case DEVICE_TYPE_STRIVE_STUDY.displayName:
    case DEVICE_TYPE_STRIVE_STUDY.id:
      /*
       * Strive
       */
      return []

    default:
      console.warn(`Unsupported device for stream selector: ${device.kind}`)
      return []
  }
}

/**
 * @param {object} streamOption, Object containing a device object and a stream object, e.g. {
    "device": {
        "alias": "Events Stream",
        "deviceShortId": "eventsStream",
        "deviceType": {
            "id": "eventsStream",
            "displayName": "Events"
        },
        "disabled": false,
        "id": "eventsStream",
        "identifier": "Events Stream",
        "kind": "Events"
    },
    "stream": {
        "value": "events",
        "label": "Patient Reported Events",
        "streamType": "events-stream"
    }
}
 * @param {object} stream, Object containing a deviceId and a streamValue, e.g. {
    "deviceId": "eventsStream",
    "streamValue": "events"
}
 * @return {boolean|boolean} Does a selected stream match an associated streamOption
 */
function isMatch(streamOption, stream) {
  if (!stream) {
    return false
  }
  return (
    stream.deviceId === streamOption.device.id &&
    stream.streamValue === streamOption.stream.value
  )
}

/**
 * Get a list of available device-stream options for a list of devices
 * @param {array} devices An array of objects.
 * @param {boolean} isLoggedIntoClinicianPortalAndCanSeePHI, Indicates whether or not the clinician is logged into the Clinician Portal and can see PHI
 * @return {array} An array of objects. Each object contains a device and a stream.
 * e.g. {
    "device": {
        "id": "patient-123,device-2fl6MHkk",
        "deviceShortId": "2fl6MHkk",
        "deviceType": {
            "id": "Apple Watch",
            "displayName": "Apple Watch",
        },
        "alias": "My Watch",
        "kind": "Apple Watch",
        "disabled": false,
        "channel": [
            "0",
            "1",
            "2",
            "3"
        ],
    },
    "stream": {
        "value": "accel",
        "endpoint": "accel",
        "csvSupported": true,
        "label": "Acceleration (with gravity)",
        "streamType": "accel",
        "queryParams": {
            "expression": "accel"
        }
    }
}
 */
export const getDeviceStreamOptions = (
  devices,
  isLoggedIntoClinicianPortalAndCanSeePHI,
) =>
  devices.reduce((iterator, device) => {
    const streamOptions = getStreamOptionsForDevice(
      device,
      isLoggedIntoClinicianPortalAndCanSeePHI,
    )

    return [
      ...iterator,
      ...streamOptions.map((stream) => ({
        device,
        stream,
      })),
    ]
  }, [])
/**
 * For a given set of stream Options, filter out any already-selected streams
 * @param {array} allStreamOptions, Array of stream option objects. Each object has a device object and a stream object.
 * @param {array} streams, Array of stream objects. Each object has a deviceId and a streamValue.
 * @param {object} includeStream Object containing the following properties: deviceId, index, streamValue.
 * @return {array} Array of objects. Each object contains a device object and a stream object.
 */
export const getAllAvailableOptions = (
  allStreamOptions,
  streams,
  includeStream,
) =>
  allStreamOptions.filter(
    (streamOption) =>
      // Automatically include the `includeStream`
      isMatch(streamOption, includeStream) ||
      // Otherwise only include a streamOption if it's not already in use
      !streams.some((stream) => isMatch(streamOption, stream)),
  )

/**
 * Given a set of stream options, return a unique list of device objects
 * @param {array} streamOptions, Array of stream option objects. Each object has a device object and a stream object.
 * @param {array} devices, Array of device objects. Each device has the following properties: alias, channels, deviceShortId, deviceType, disabled, id, kind.
 * @return {array} Array of unique device objects.
 */
export const getDevicesFromStreamOptions = (streamOptions, devices) =>
  Array.from(
    streamOptions.reduce(
      (iterator, streamOption) => iterator.add(streamOption.device.id),
      new Set(),
    ),
  ).map((deviceId) => devices.find((device) => device.id === deviceId))

/**
 * Get a list of streamValues for a given device
 * @param {array} streamOptions, Array of stream option objects. Each object has a device object and a stream object.
 * @param {string} deviceId, String containing the device id, e.g. 'patient-123,device-2fl6MHkk' or 'eventsStream'.
 * @return {array} An array of stream values for a given device. Each stream value is an object with the following properties: label, streamType, and value.
 */
export const getStreamValues = (streamOptions, deviceId) =>
  streamOptions
    .filter((streamOption) => streamOption.device.id === deviceId)
    .map((streamOption) => streamOption.stream)
