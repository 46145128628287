import { useContext, useEffect, useMemo } from 'react'
import ReportSection from '../../components/ReportSection'
import { PatientContext } from 'ui/contexts'
import {
  DATA_ATTRIBUTES,
  SECTION_TITLES,
} from 'ui/clinicianScreens/Patient/Report/consts'
import {
  HELPER_MEDICATIONS,
  HELPER_MEDICATIONS_EMPTY_STATE,
  SECTION_MEDICATIONS_EMPTY_STATE,
} from './consts'
import { getLoggedAsNeededAndActiveScheduledMeds } from 'ui/clinicianScreens/Patient/helpers'
import { reFetchEventList } from 'ui/screens/Patient/queries'
import usePatientEvents from 'ui/hooks/usePatientEvents'
import ReportChartMedication from './ReportChartMedication'
import EditableLLMContent from '../../components/EditableLLMContent'
import MedicationScheduledTables from 'ui/clinicianScreens/Patient/MedicationTables/MedicationScheduledTables'
import MedicationLoggedTables from 'ui/clinicianScreens/Patient/MedicationTables/MedicationLoggedTables'
import {
  isPdMedication,
  parseUniqueLoggedMedicationByNameAndDosage,
} from 'ui/clinicianScreens/Patient/MedicationTables/helpers'

const ReportSectionMedication = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  llmKey,
  llmSummary,
  onUpdate = () => {},
  addToUnsavedChanges = () => {},
  removeFromUnsavedChanges = () => {},
  startTime,
  endTime,
  prevStartTime,
  prevEndTime,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
}) => {
  const title = SECTION_TITLES.MEDICATION
  const { medicationScheduleList, id: patientId } = useContext(PatientContext)
  const { medication: medicationDataAttributes } = DATA_ATTRIBUTES
  const {
    noMedicationDataAttribute,
    loggedMedicationsChartAttribute,
    noLoggedMedicationsChartAttribute,
  } = medicationDataAttributes

  const {
    dataEvents,
    fetchMore,
    loading: patientEventsLoading,
  } = usePatientEvents(patientId, startTime, endTime, {
    namespace: 'patient',
    category: 'medication',
    enum: '*',
  })

  const {
    dataEvents: prevDataEvents,
    fetchMore: prevFetchMore,
    loading: prevPatientEventsLoading,
  } = usePatientEvents(patientId, prevStartTime, prevEndTime, {
    namespace: 'patient',
    category: 'medication',
    enum: '*',
  })

  const eventList = useMemo(
    () => dataEvents?.patient?.eventList?.events || [],
    [dataEvents],
  )

  const prevEventList = useMemo(
    () => prevDataEvents?.patient?.eventList?.events || [],
    [prevDataEvents],
  )

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = dataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(fetchMore, endCursor)
    }
  }, [dataEvents, fetchMore])

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = dataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(prevFetchMore, endCursor)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevDataEvents, prevFetchMore])

  useEffect(() => {
    if (patientEventsLoading || prevPatientEventsLoading) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientEventsLoading, prevPatientEventsLoading])

  const schedules = medicationScheduleList?.schedules || []

  const {
    loggedAsNeededMeds,
    prevLoggedAsNeededMeds,
    allActiveScheduledMedNames,
  } = getLoggedAsNeededAndActiveScheduledMeds(
    schedules,
    eventList,
    prevEventList,
  )

  const loggedAsNeededMedsToShow = isClinicianReport
    ? loggedAsNeededMeds.filter((item) => isPdMedication(item))
    : loggedAsNeededMeds

  const prevLoggedAsNeededMedsToShow = isClinicianReport
    ? prevLoggedAsNeededMeds.filter((item) => isPdMedication(item))
    : prevLoggedAsNeededMeds

  const loggedMedicationData = new Map()
  if (!loggedMedicationData.size) {
    parseUniqueLoggedMedicationByNameAndDosage(
      loggedAsNeededMedsToShow,
      loggedMedicationData,
    )
    parseUniqueLoggedMedicationByNameAndDosage(
      prevLoggedAsNeededMedsToShow,
      loggedMedicationData,
      true,
    )
  }

  const hasScheduledData = allActiveScheduledMedNames.size > 0
  const hasLoggedAsNeededData =
    loggedAsNeededMedsToShow.length > 0 ||
    prevLoggedAsNeededMedsToShow.length > 0
  const hasData = hasScheduledData || hasLoggedAsNeededData

  useEffect(() => {
    if (dataEvents) {
      hasData ? addToHasDataSet(title) : removeFromHasDataSet(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvents, hasData])

  const attributesForPlaywrightScript = useMemo(() => {
    const expectedAttributes = []

    if (!hasData) {
      expectedAttributes.push(
        noMedicationDataAttribute.playwrightScriptSelector,
      )
    }

    if (!patientEventsLoading && eventList?.length === 0) {
      expectedAttributes.push(
        noLoggedMedicationsChartAttribute.playwrightScriptSelector,
      )
    }

    if (eventList.length > 0) {
      expectedAttributes.push(
        loggedMedicationsChartAttribute.playwrightScriptSelector,
      )
    }

    return expectedAttributes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventList,
    prevEventList,
    hasData,
    patientEventsLoading,
    prevPatientEventsLoading,
  ])

  return (
    <ReportSection
      {...{
        title,
        hidden:
          hiddenSections.has(title) ||
          (isClinicianReport && !hasDataSet.has(title)),
        ...(!hasData && {
          [`data-${noMedicationDataAttribute.carrotWebSuffix}`]: true,
        }),
        ...(!patientEventsLoading &&
          eventList?.length === 0 && {
            [`data-${noLoggedMedicationsChartAttribute.carrotWebSuffix}`]: true,
          }),
        ...{
          'data-expected-medication-attributes': attributesForPlaywrightScript,
        },
      }}
    >
      {hasData ? (
        <>
          {llmSummary && (
            <EditableLLMContent
              {...{
                llmKey,
                llmSummary,
                onUpdate,
                addToUnsavedChanges,
                removeFromUnsavedChanges,
              }}
            >
              {llmSummary}
            </EditableLLMContent>
          )}
          {eventList?.length > 0 && (
            <ReportChartMedication eventList={eventList} />
          )}

          {hasLoggedAsNeededData && (
            <MedicationLoggedTables
              {...{
                data: loggedMedicationData,
                showPDMedsOnly: isClinicianReport,
              }}
            />
          )}

          {hasScheduledData && (
            <MedicationScheduledTables
              {...{
                schedules,
                showPDMedsOnly: isClinicianReport,
              }}
            />
          )}

          {!isClinicianReport && HELPER_MEDICATIONS}
        </>
      ) : (
        <>
          {SECTION_MEDICATIONS_EMPTY_STATE}
          {HELPER_MEDICATIONS_EMPTY_STATE}
        </>
      )}
    </ReportSection>
  )
}

export default ReportSectionMedication
