import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as MUI from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { RecentDataAvailabilityContext } from 'ui/contexts'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import { stringifyQueryParameters } from 'ui/clinicianScreens/Patient/helpers'
import DataAvailabilityDateRangePickerWrapper from 'ui/clinicianScreens/Patient/DataAvailabilityDateRangePicker/DataAvailabilityDateRangePickerWrapper'
import { Stack } from 'ui/baseComponents/Stack'
import TabActionableHeader from 'ui/templates/TabActionableHeader'
import useBoundStore from 'domains/zustand/store'
import OverviewSidebarContent from './OverviewSidebarContent/OverviewSidebarContent'
import OverviewMainContent from './OverviewMainContent/OverviewMainContent'
import TwoColumnLayout from 'ui/templates/TwoColumnLayout'
import DateJumpAlert from '../DateJumpAlert'
import OverviewChartContent from './OverviewChartContent/OverviewChartContent'

const STYLES = {
  contentContainer: { width: '100%' },
}

const Overview = () => {
  const smallScreens = MUI.useMediaQuery('(max-width:1160px)')

  const dateJumpAlert = useBoundStore((state) => state.dateJumpAlert)
  const hideDateJumpAlert = useBoundStore((state) => state.hideDateJumpAlert)

  const { data: { latestActivityDate } = {} } =
    useContext(RecentDataAvailabilityContext) || {}

  const { setDateRange: setDateRangeContext } = useContext(DateRangeContext)

  const [, setSearchParams] = useSearchParams()

  const updateSearchParams = (from, to) => {
    const newSearchParams = stringifyQueryParameters(from, to)
    setSearchParams(newSearchParams, {
      replace: true,
    })
  }

  const onDateRangeChange = (from, to) => {
    setDateRangeContext(from, to, DATE_RANGE.default.key)
    updateSearchParams(from, to)
    if (dateJumpAlert) {
      hideDateJumpAlert()
    }
  }

  return (
    <>
      <TabActionableHeader>
        <Stack
          direction={smallScreens ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          spacing={smallScreens ? 1 : 2}
          sx={STYLES.contentContainer}
        >
          <div>
            <DataAvailabilityDateRangePickerWrapper
              {...{ onDateRangeChange }}
            />
          </div>
          <Grid container xs justifyContent="center">
            {dateJumpAlert && (
              <DateJumpAlert latestActivityDate={latestActivityDate} />
            )}
          </Grid>
        </Stack>
      </TabActionableHeader>
      <OverviewChartContent />
      <TwoColumnLayout
        columnOne={<OverviewSidebarContent />}
        columnTwo={<OverviewMainContent />}
      />
    </>
  )
}

export default Overview
