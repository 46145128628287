import { memo } from 'react'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'
import ReportMetricTrend from './ReportMetricTrend'

const ReportMetric = ({
  label,
  value,
  trend = 0,
  trendLabel = '',
  afterValueText = '',
  variant = 'percent',
  noPrevData,
  iconVariant,
  ...props
}) => (
  <Stack direction="row" alignItems="center" {...props}>
    {label && <Text variant="body16">{label}</Text>}
    <Text variant="body16B">
      {value}
      {afterValueText && <Text variant="body16"> {afterValueText}</Text>}
    </Text>

    <ReportMetricTrend
      {...{
        value,
        trend,
        trendLabel,
        variant,
        noPrevData,
        iconVariant,
      }}
    />
  </Stack>
)

export default memo(ReportMetric)
