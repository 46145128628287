import { gql } from '@apollo/client'

export const GET_USERS = gql`
  query getUsers(
    $orgId: ID!
    $limit: Int
    $cursor: Cursor
    $withDisabled: Boolean
  ) {
    org(orgId: $orgId) {
      id
      membershipList(
        limit: $limit
        cursor: $cursor
        withDisabled: $withDisabled
      ) {
        pageInfo {
          endCursor
        }
        memberships {
          user {
            id
            displayName
            username
            email
          }
          status {
            displayName
            disabledAt
            disabledBy {
              user {
                id
                displayName
              }
            }
            invitedAt
            invitedBy {
              user {
                id
                displayName
              }
            }
            joinedAt
          }
          admin
          id
          disabled
          role {
            admin
            displayName
            canSeePHI
          }
        }
      }
    }
  }
`
