import { useEffect } from 'react'
import { Stack } from 'ui/baseComponents'
import CookieBanner from 'ui/components/CookieBanner/CookieBanner'
import CreatePatient from 'ui/screens/Patients/CreatePatient/CreatePatient'
import FixedWidthLayout from 'ui/components/FixedWidthLayout/FixedWidthLayout'
import { NotificationBar, Success, Warning } from 'ui/components/Notifications'
import {
  PatientTableToolbar,
  PatientTableWithoutPHIAccess,
} from 'ui/screens/Patients/PatientList/PatientTable'
import TaskNotificationsLink from 'ui/screens/Patients/TaskNotifications/TaskNotificationsLink/TaskNotificationsLink'
import useSnackbarContext, {
  SnackbarContext,
} from 'ui/contexts/SnackbarContext'
import { useIsAdmin } from 'ui/hooks'
import useBoundStore from 'domains/zustand/store'

/**
 * Renders the Patient List page for the Researcher Portal.
 * This component displays a table of patients and provides options to filter and view recent patients.
 *
  * @returns {JSX.Element} The rendered PatientListResarcherPortal component.

 */
const PatientListResearcherPortal = () => {
  const snackBarContextValue = useSnackbarContext()
  const { notificationType, snackbarMessage, resetSnackbar } =
    snackBarContextValue

  const { admin } = useIsAdmin()
  const success = notificationType === 'success'
  const warning = notificationType === 'warning'

  const resetPatientList = useBoundStore((state) => state.resetPatientList)
  const showAllPatients = useBoundStore((state) => state.showAllPatients)
  const toggleAllPatients = useBoundStore((state) => state.toggleAllPatients)

  useEffect(() => {
    resetPatientList()
    if (!showAllPatients) {
      toggleAllPatients()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SnackbarContext.Provider value={snackBarContextValue}>
      <FixedWidthLayout>
        {success && (
          <NotificationBar open onClose={resetSnackbar}>
            <Success message={snackbarMessage} hideCloseButton />
          </NotificationBar>
        )}
        {warning && (
          <NotificationBar open onClose={resetSnackbar}>
            <Warning message={snackbarMessage} hideCloseButton />
          </NotificationBar>
        )}
        <Stack direction="row" alignItems="center" justifyContent="center">
          <CreatePatient />
          {admin && <TaskNotificationsLink />}
        </Stack>
        <PatientTableToolbar />
        <PatientTableWithoutPHIAccess />
      </FixedWidthLayout>
      <CookieBanner />
    </SnackbarContext.Provider>
  )
}

export default PatientListResearcherPortal
