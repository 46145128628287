import { useContext } from 'react'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import useTremorAndDyskinesiaAggregate from 'ui/hooks/summaryAggregates/useTremorAndDyskinesiaAggregate'
import { SummaryMetric } from '../../Summary/components/SummaryMetric'
import { MetricsRow } from './MetricsRow'
import {
  HOUR_IN_SECONDS,
  prettyPrintRoundedTimeInHoursAndMinutes,
} from 'utilities/time'
import { ERROR_TEXT, NO_DATA_MOTOR, NO_DEVICE_TEXT } from '../consts'
import { NoDataText } from 'ui/components/NoDataText'

export const MotorMetrics = () => {
  const { dateRanges } = useContext(DateRangeContext)
  const { start, end } = dateRanges[DATE_RANGE.default.key] || {}

  const { isLoading, hasError, hasData, hasDevice, data } =
    useTremorAndDyskinesiaAggregate(start, end)

  const Content = () => {
    if (!hasDevice) {
      return <NoDataText text={NO_DEVICE_TEXT} />
    }

    if (hasError) {
      return <NoDataText text={ERROR_TEXT} />
    }

    if (!hasData && !isLoading) {
      return <NoDataText text={NO_DATA_MOTOR} />
    }

    const { tremor, dyskinesia } = data || {}

    const tremorAverage = prettyPrintRoundedTimeInHoursAndMinutes(
      tremor?.mean[0]?.value / HOUR_IN_SECONDS,
    )
    const tremorMax = prettyPrintRoundedTimeInHoursAndMinutes(
      tremor?.max[0]?.value / HOUR_IN_SECONDS,
    )

    const tremorMin = prettyPrintRoundedTimeInHoursAndMinutes(
      tremor?.min[0]?.value / HOUR_IN_SECONDS,
    )

    const dyskinesiaAverage = prettyPrintRoundedTimeInHoursAndMinutes(
      dyskinesia?.mean[0]?.value / HOUR_IN_SECONDS,
    )

    const dyskinesiaMax = prettyPrintRoundedTimeInHoursAndMinutes(
      dyskinesia?.max[0]?.value / HOUR_IN_SECONDS,
    )

    const dyskinesiaMin = prettyPrintRoundedTimeInHoursAndMinutes(
      dyskinesia?.min[0]?.value / HOUR_IN_SECONDS,
    )

    const tremorTrend = tremor?.trend
    const dyskinesiaTrend = dyskinesia?.trend

    return (
      <>
        <SummaryMetric
          title="Tremor"
          metrics={{
            h: tremorAverage.hours,
            m: tremorAverage.minutes,
          }}
          minMetrics={{
            h: tremorMin.hours,
            m: tremorMin.minutes,
          }}
          maxMetrics={{
            h: tremorMax.hours,
            m: tremorMax.minutes,
          }}
          trend={tremorTrend}
        />
        <SummaryMetric
          title="Dyskinesia"
          metrics={{
            h: dyskinesiaAverage.hours,
            m: dyskinesiaAverage.minutes,
          }}
          minMetrics={{
            h: dyskinesiaMin.hours,
            m: dyskinesiaMin.minutes,
          }}
          maxMetrics={{
            h: dyskinesiaMax.hours,
            m: dyskinesiaMax.minutes,
          }}
          trend={dyskinesiaTrend}
        />
      </>
    )
  }

  return (
    <MetricsRow title="Motor" isLoading={isLoading}>
      <Content />
    </MetricsRow>
  )
}
