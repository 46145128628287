import { gql, useQuery } from '@apollo/client'

const ACCESS_TOKENS_QUERY = gql`
  query getUserAccessTokens($cursor: Cursor) {
    user {
      id
      accessTokenList(cursor: $cursor) {
        accessTokens {
          id
          accessTokenId
          createdAt
          disabled
          disabledAt
          updatedAt
          usedAt
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
`

/**
 * Get a "full" Patient, along with its devices and clients.
 * @return {{loading, error, patient}}
 */
export const useAccessTokenList = () => {
  return useQuery(ACCESS_TOKENS_QUERY, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  })
}
export const useRefetchAccessTokenList = () => {
  return {
    query: ACCESS_TOKENS_QUERY,
  }
}
