import React from 'react'
import Box from '@material-ui/core/Box'
import CreateDevice from './CreateDevice'
import Device from './Device'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TableCell, makeStyles } from '@material-ui/core'
import { useDevices } from 'ui/contexts/PatientContext'
import { caseInsensitiveCompare } from 'utilities/sort'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))
const Devices = () => {
  const classes = useStyles()
  const devices = useDevices()

  const groupedDevices = devices.reduce(
    (acc, device) => {
      if (device.disabled) {
        acc.disabled.push(device)
      } else {
        acc.enabled.push(device)
      }
      return acc
    },
    {
      enabled: [],
      disabled: [],
    },
  )

  const getDeviceComponents = (deviceList) =>
    deviceList
      .sort(({ alias: aliasA }, { alias: aliasB }) =>
        caseInsensitiveCompare(aliasA, aliasB),
      )
      .map((device) => <Device key={device.id} device={device} />)

  return (
    <Box className={classes.root}>
      <CreateDevice />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Device</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getDeviceComponents(groupedDevices.enabled)}
          {getDeviceComponents(groupedDevices.disabled)}
        </TableBody>
      </Table>
    </Box>
  )
}

export default Devices
