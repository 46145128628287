import { useContext } from 'react'
import { CognitoUserPoolContext } from 'ui/contexts'
import { SHARED_ROUTES } from '../consts'
import ProtectedRoute from '../ProtectedRoute'
import { getAutomatedUserJwt } from 'utilities/automatedUser'

const ProtectedByLogin = () => {
  const cognitoUserPool = useContext(CognitoUserPoolContext)
  const isAllowed =
    !!getAutomatedUserJwt() || !!cognitoUserPool.getCurrentUser()

  if (!isAllowed) {
    const { pathname, search } = window.location
    const referrerPath = `${pathname}${search}`
    sessionStorage.setItem('referrer', referrerPath)
  }

  return (
    <ProtectedRoute
      isAllowed={isAllowed}
      redirectPath={SHARED_ROUTES.signin.path}
    />
  )
}

ProtectedByLogin.propTypes = {}

ProtectedByLogin.defaultProps = {}

export default ProtectedByLogin
