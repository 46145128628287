import { useCallback, useState } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import InviteUsers from './InviteUsers'
import ErrorBoundary from '../../../components/ErrorBoundary'

const InviteUsersWrapper = () => {
  const [open, setOpen] = useState(false)
  const handleClickOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])
  return (
    <Box justifyContent="center" display="flex" p={3}>
      <Button
        size="large"
        variant="contained"
        color="primary"
        data-cy="InviteUsersButton"
        onClick={handleClickOpen}
      >
        Invite Users
      </Button>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <ErrorBoundary>
          <InviteUsers handleClose={handleClose} />
        </ErrorBoundary>
      </Dialog>
    </Box>
  )
}

export default InviteUsersWrapper
