import { makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import { bool, oneOf } from 'prop-types'
import React from 'react'
import { colors, RUNE_GRAY_300, RUNE_GRAY_700 } from 'theme/colors'
import {
  Z_INDEX_STYLED_TOOLTIP_HIGHLIGHT,
  Z_INDEX_STYLED_TOOLTIP_TEXT,
} from 'theme/zIndexRegistry'

const useStyles = makeStyles((theme) => ({
  text: {
    fontWeight: 500,
  },
  tooltip: {
    backgroundColor: colors.PRIMARY[200],
    border: `1px solid ${RUNE_GRAY_300}`,
    borderRadius: 10,
    boxShadow:
      '0px 4px 8px -2px rgba(0, 44, 76, 0.10), 0px 2px 4px -2px rgba(0, 44, 76, 0.06)',
    color: colors.BLACK,
    fontSize: '1em',
    fontWeight: 400,
    '& .MuiTooltip-arrow': {
      color: colors.PRIMARY[200],
    },
  },
  variantUnderlinedDotted: {
    textDecoration: `underline dotted ${RUNE_GRAY_700}`,
    textDecorationThickness: 'unset',
    textUnderlineOffset: '4px',
  },
  wide: {
    maxWidth: 550,
  },
  content: {
    zIndex: Z_INDEX_STYLED_TOOLTIP_TEXT,
    position: 'relative',
  },
  highlight: {
    position: 'relative',
    '&:before': {
      content: `''`,
      position: 'absolute',
      top: '-0.2rem',
      bottom: '-0.2rem',
      left: '-0.2rem',
      right: '-0.2rem',
      backgroundColor: colors.PRIMARY[200],
      borderRadius: '0.75rem',
      opacity: 0,
      zIndex: Z_INDEX_STYLED_TOOLTIP_HIGHLIGHT,
      transition: 'opacity 0.3s ease',
    },
    '&:hover': {
      '&:before': {
        opacity: 1,
      },
    },
  },
}))

const StyledTooltip = ({
  children,
  variant,
  wide,
  highlight,
  contentStyles,
  ...props
}) => {
  const classes = useStyles()

  let variantClass = ''
  switch (variant) {
    case 'underlined-dotted':
      variantClass = classes.variantUnderlinedDotted
      break
    default:
      break
  }

  return (
    <Tooltip
      {...props}
      arrow
      className={clsx(classes.text, variantClass, {
        [classes.highlight]: highlight,
      })}
      classes={{
        tooltip: clsx({
          [classes.tooltip]: true,
          [classes.wide]: wide,
        }),
      }}
    >
      <span className={classes.content} style={contentStyles}>
        {children}
      </span>
    </Tooltip>
  )
}

StyledTooltip.propTypes = {
  variant: oneOf(['underlined-dotted']),
  wide: bool,
  highlight: bool,
}

StyledTooltip.defaultProps = {
  wide: false,
}

export default StyledTooltip
