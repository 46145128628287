import * as MUI from '@mui/material'
import { oneOf, string } from 'prop-types'
import { colors } from 'theme/colors'

const Chip = ({
  label,
  variant = 'contained',
  size = 'small',
  color,
  sx,
  ...props
}) => {
  const STYLES = {
    '&.MuiChip-containedSuccess': {
      backgroundColor: colors.TEAL[500],
      color: colors.WHITE,
    },
  }

  return (
    <MUI.Chip
      {...{
        'data-cy': 'chip',
        label,
        variant,
        color,
        sx: { ...STYLES, ...sx },
        size,
        ...props,
      }}
    />
  )
}

export default Chip

Chip.propTypes = {
  label: string.isRequired,
  color: string,
  size: oneOf(['small', 'medium']),
  variant: oneOf(['contained', 'outlined', 'text']),
}
