import { memo, useContext, useState } from 'react'
import * as MUI from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Stack } from 'ui/baseComponents/Stack'
import { colors } from 'theme/colors'
import { LoadingButton } from '@mui/lab'
import { saveWithPatientName } from '../helpers'
import { PatientContext } from 'ui/contexts'
import { patientName } from 'utilities/string'

const ReviewEditsModal = ({
  openEditsModal,
  edits,
  llmData,
  toggleEditsModal,
  onSave = () => {},
  refreshPage = () => {},
}) => {
  const [afterSave, setAfterSave] = useState(() => {})
  const [isSaving, setIsSaving] = useState(false)
  const [classificationValue, setClassificationValue] = useState('')
  const [notesValue, setNotesValue] = useState('')

  const isFormComplete = classificationValue && notesValue

  const patient = useContext(PatientContext)

  const refreshAfterSave = () => {
    setTimeout(() => {
      refreshPage(true)
      setIsSaving(false)
      toggleEditsModal()
    }, 500)
  }

  const printAfterSave = () => {
    toggleEditsModal()
    setTimeout(() => {
      saveWithPatientName(patientName(patient).fullName())
      setIsSaving(false)
      refreshPage()
    }, 500)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const { classification, notes } = e.target
    if (isFormComplete) {
      setIsSaving(true)
      onSave(classification.value, notes.value)
      afterSave()
    }
  }

  return (
    <MUI.Dialog open={openEditsModal} fullWidth maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <MUI.DialogTitle>Review edits</MUI.DialogTitle>

        <MUI.DialogContent>
          <Stack spacing={4}>
            <MUI.DialogContentText>
              Please classify and make any notes about what is incorrect in the
              generated text.
            </MUI.DialogContentText>

            <MUI.Table size="small">
              <MUI.TableHead>
                <MUI.TableRow>
                  <MUI.TableCell>Section</MUI.TableCell>
                  <MUI.TableCell>Original</MUI.TableCell>
                  <MUI.TableCell>Edited</MUI.TableCell>
                </MUI.TableRow>
              </MUI.TableHead>
              <MUI.TableBody>
                {[...edits.entries()].map(([key, value]) => (
                  <MUI.TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={key}
                  >
                    <MUI.TableCell sx={{ textTransform: 'capitalize' }}>
                      {key}
                    </MUI.TableCell>
                    <MUI.TableCell sx={{ color: colors.GREY[700] }}>
                      {llmData?.summaries?.[key]}
                    </MUI.TableCell>
                    <MUI.TableCell>{value}</MUI.TableCell>
                  </MUI.TableRow>
                ))}
              </MUI.TableBody>
            </MUI.Table>

            <Stack>
              <MUI.FormControl fullWidth size="small" required>
                <MUI.InputLabel>Classification</MUI.InputLabel>
                <MUI.Select
                  fullWidth
                  label="Classification"
                  name="classification"
                  onChange={(e) => setClassificationValue(e.target.value)}
                  value={classificationValue}
                >
                  <MUI.MenuItem value="FALSE_POSITIVE">
                    False positive
                  </MUI.MenuItem>
                  <MUI.MenuItem value="FALSE_NEGATIVE">
                    False negative
                  </MUI.MenuItem>
                  <MUI.MenuItem value="APPROPRIATENESS">
                    Appropriateness
                  </MUI.MenuItem>
                </MUI.Select>
              </MUI.FormControl>

              <MUI.TextField
                required
                name="notes"
                label="Notes"
                multiline
                minRows={4}
                fullWidth
                variant="outlined"
                onChange={(e) => setNotesValue(e.target.value)}
                value={notesValue}
              />
            </Stack>
          </Stack>
        </MUI.DialogContent>

        <MUI.DialogActions>
          <MUI.Button onClick={toggleEditsModal}>Cancel</MUI.Button>
          <LoadingButton
            variant="outlined"
            type="submit"
            startIcon={<RefreshIcon />}
            onClick={() => setAfterSave(refreshAfterSave)}
            disabled={!isFormComplete}
            loading={isSaving}
          >
            Regenerate LLM
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            startIcon={<DownloadIcon />}
            onClick={() => setAfterSave(printAfterSave)}
            disabled={!isFormComplete}
          >
            Download PDF
          </LoadingButton>
        </MUI.DialogActions>
      </form>
    </MUI.Dialog>
  )
}

export default memo(ReviewEditsModal)
