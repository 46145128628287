import { memo } from 'react'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'
import { Link } from 'ui/baseComponents/Link'
import ReportSection from './ReportSection'
import { FOOTER_DISCLAIMER, LLM_DISCLAIMER } from '../consts'

const ReportFooter = ({ showLLMDisclaimer, isClinicianReport }) => {
  const STYLES = {
    contactInfo: {
      '& span:not(:first-of-type)::before': {
        content: '"|"',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    },
  }

  return (
    <ReportSection>
      <Stack spacing={4}>
        {!isClinicianReport && (
          <Text variant="body16" component="p">
            We're improving your insights in StrivePD! This is a comprehensive
            summary of your data captured by the StrivePD app. For optimal
            results,{' '}
            <Link
              href="https://www.loom.com/share/8f1f69dd0eac42568bb42b53b0531004?sid=14d1df92-0984-4dd3-a478-a504ef15e99b"
              external
              underline
              light
            >
              watch our video
            </Link>{' '}
            on best practices. To obtain valuable insights, continue engaging
            with the app and wear your watch for at least 6 hours a day before
            your next report. Insights are generated every 4 weeks, so
            consistent usage over that period will provide the most
            comprehensive data for analysis.
          </Text>
        )}

        {!isClinicianReport && (
          <>
            {showLLMDisclaimer && LLM_DISCLAIMER}
            {FOOTER_DISCLAIMER}
          </>
        )}

        <Stack
          direction="row"
          justifyContent="center"
          spacing={0}
          sx={STYLES.contactInfo}
        >
          <Text variant="body16">StrivePD Support</Text>
          <Text variant="body16">
            Email:{' '}
            <Link href="mailto:support@runelabs.io" light external underline>
              support@runelabs.io
            </Link>
          </Text>
          <Text variant="body16">
            <Link href="https://www.strive.group" external light underline>
              www.strive.group
            </Link>
          </Text>
        </Stack>
      </Stack>
    </ReportSection>
  )
}

export default memo(ReportFooter)
