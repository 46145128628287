import { useContext } from 'react'
import { LinearProgress } from '@material-ui/core'
import { DataTable } from 'ui/components/DataTable'
import PatientErrorCard from '../PatientErrorCard'
import DefaultDateRangeHeader from '../Tabs/TabContentHeaders/Layouts/DefaultDateRangeHeader'
import { columns, options } from './configuration'
import { TITLE } from './consts'
import { processLog } from './helpers'
import useBoundStore from 'domains/zustand/store'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { UserPHIContext } from 'ui/contexts'

const Log = () => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const phiVisibility = useContext(UserPHIContext)
  const eventList = useBoundStore((state) => state.eventList)
  const errorEvents = useBoundStore((state) => state.errorEvents)
  const loadingEvents = useBoundStore((state) => state.loadingEvents)
  const processedEvents = processLog({
    eventsToProcess: eventList,
    phiVisibility,
    selectedTimezone,
  })

  if (errorEvents) {
    return (
      <>
        <DefaultDateRangeHeader />
        <PatientErrorCard />
      </>
    )
  }

  if (loadingEvents) {
    return (
      <>
        <DefaultDateRangeHeader />
        <LinearProgress />
      </>
    )
  }

  return (
    <>
      <DefaultDateRangeHeader />
      <DataTable
        title={TITLE}
        data={processedEvents}
        columns={columns}
        options={options}
      />
    </>
  )
}

Log.propTypes = {}

Log.defaultProps = {}

export default Log
