import { Accordion } from 'ui/components/Accordion'
import { string } from 'prop-types'
import { DATE_RANGE } from 'ui/contexts/DateRangeContext'
import { labels } from './consts'
import TremorAndDyskinesiaDetails from './TremorAndDyskinesiaDetails'
import TremorAndDyskinesiaSummary from './TremorAndDyskinesiaSummary'
import useTremorAndDyskinesiaAggregate from 'ui/hooks/summaryAggregates/useTremorAndDyskinesiaAggregate'

const TremorAndDyskinesia = ({
  titleSuffix,
  subtitle,
  dateRangeKey,
  start,
  end,
}) => {
  const tremorAndDyskinesiaAggregateData = useTremorAndDyskinesiaAggregate(
    start,
    end,
  )
  const { isLoading, hasError, hasDevice, hasData, data, metadata } =
    tremorAndDyskinesiaAggregateData || {}

  const title = titleSuffix ? `${labels.TITLE} ${titleSuffix}` : labels.TITLE

  if (!isLoading && !hasError && hasDevice && hasData) {
    return (
      <Accordion
        title={title}
        summary={
          <TremorAndDyskinesiaSummary
            title={title}
            subtitle={subtitle}
            isLoading={isLoading}
            hasError={hasError}
            hasDevice={hasDevice}
            hasData={hasData}
            data={data}
          />
        }
        details={
          <TremorAndDyskinesiaDetails
            dateRangeKey={dateRangeKey}
            start={start}
            end={end}
            data={data}
            metadata={metadata}
          />
        }
      />
    )
  }

  return (
    <Accordion
      title={title}
      summary={
        <TremorAndDyskinesiaSummary
          title={title}
          subtitle={subtitle}
          isLoading={isLoading}
          hasError={hasError}
          hasDevice={hasDevice}
          hasData={hasData}
        />
      }
    />
  )
}

TremorAndDyskinesia.propTypes = {
  titleSuffix: string,
  subtitle: string,
  dateRangeKey: string,
  start: string,
  end: string,
}

TremorAndDyskinesia.defaultProps = {
  titleSuffix: '',
  subtitle: '',
  dateRangeKey: DATE_RANGE.default.key,
  start: '',
  end: '',
}

export default TremorAndDyskinesia
