import { useQuery } from '@apollo/client'
import { get } from 'nested-property'
import { GET_DEFAULT_MEMBERSHIP } from '../queries'

/**
 * Get Patients available to the logged in user
 * @return {{loading, error, patient}} - returns loading bool, error object, and patient object
 */
export const usePermissions = () => {
  const { loading, error, membership } = useDefaultMembership()
  let permissions = {}
  if (membership) {
    const { admin } = membership
    permissions = { admin }
  }
  return { loading, error, permissions }
}

export const useDefaultMembership = () => {
  const { loading, error, data } = useQuery(GET_DEFAULT_MEMBERSHIP, {
    fetchPolicy: 'cache-first',
  })
  const membership = get(data, 'user.defaultMembership')
  return { loading, error, membership }
}
