import React from 'react'
import { Box } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import ErrorMessage from 'ui/components/ErrorMessage'

const PatientErrorCard = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <ErrorBoundary>
        <ErrorMessage
          icon={Search}
          message="Well shucks! Something seems to have gone wrong. Please try again or contact your administrator."
        />
      </ErrorBoundary>
    </Box>
  )
}

export default PatientErrorCard
