import { node, oneOfType, string } from 'prop-types'
import { colors } from 'theme/colors'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'
import { StyledTooltip } from 'ui/components/StyledTooltip'

const Subheading = ({
  mainText,
  secondaryText,
  tooltipContent,
  mainTextSeparator,
}) => {
  const STYLES = {
    subtitle: {
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: colors.GREY[200],
      padding: '.5rem 0',
    },
    tooltipText: {
      lineHeight: 1,
    },
  }

  let formattedMainText = [mainText]

  // Use stack for main text separation if separator is provided
  if (mainTextSeparator) {
    const separatorRegEx = new RegExp(`(${mainTextSeparator})`)
    formattedMainText = mainText.split(separatorRegEx)
  }

  const mainTextComponents = (
    <Stack direction="row" justifyContent="flex-start">
      {formattedMainText.map((text, index) => (
        <Text key={index} color={colors.GREY[800]} variant="caps12">
          {text}
        </Text>
      ))}
    </Stack>
  )

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      sx={STYLES.subtitle}
    >
      {mainTextComponents}
      {secondaryText && tooltipContent ? (
        <StyledTooltip
          title={tooltipContent}
          contentStyles={STYLES.tooltipText}
        >
          <Text data-cy="subtitle-secondary-text" variant="body14">
            {secondaryText}
          </Text>
        </StyledTooltip>
      ) : (
        <Text data-cy="subtitle-secondary-text" variant="body14">
          {secondaryText}
        </Text>
      )}
    </Stack>
  )
}

Subheading.propTypes = {
  mainText: string,
  secondaryText: oneOfType([string, node]),
}

export default Subheading
