import { getSecondsInMinutes } from 'ui/clinicianScreens/Patient/Report/helpers'
import {
  HOUR_IN_MINUTES,
  prettyPrintRoundedTimeInHoursAndMinutes,
} from 'utilities/time'

export const TREMOR_AND_DYSKINESIA_TITLE = 'From your watch'
export const TREMOR_AND_DYSKINESIA_SUBTITLE = 'Data recorded by Apple watch'

export const defaultAverage = {
  hours: 0,
  minutes: 0,
}

export const getAverageDiff = (average, prevAverage) => {
  const current = getSecondsInMinutes(average)
  const prev = getSecondsInMinutes(prevAverage)
  const diff = (current - prev) / HOUR_IN_MINUTES
  return prettyPrintRoundedTimeInHoursAndMinutes(diff)
}
