import React from 'react'
import { Box, TableCell, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import TableRow from 'ui/components/TableRow'
import { UserStatusToggle } from './UserStatusToggle'
import { UserTypeSwitch } from './UserTypeSwitch'
import styled from 'styled-components'
import { isToday, formatDateTime, formatRelativeTime } from 'utilities/time'

export const TABLE_STATUS = {
  active: 'ACTIVE',
  invited: 'INVITED',
  disabled: 'DISABLED',
}

const UserTableRow = styled(TableRow)`
   {
    td {
      font-size: ${({ theme }) => theme.typography.body1.fontSize};
    }
  }
`

/**
 * Component for dispaying single row of user info in the Team view
 *
 * @param {object} user Contains the following keys: admin, disabled, displayName, email, id, membershipId, membershipRole, status, username.
 * @return {JSX.Element} A table row with the following user info: email, displayName, status (eg. active, invited, disabled), dropdown with role options (Admin, Clinician, User), and option to Revoke Membership.
 */
const UserRow = (user) => {
  const { disabled, displayName, email, status } = user
  const formatDate = (time) =>
    isToday(time) ? formatRelativeTime(time) : formatDateTime(time, 'l')
  const formatDisplayName = (prefix, statusObj) =>
    statusObj?.user?.displayName
      ? [prefix, statusObj.user.displayName].join('')
      : ''

  let formattedStatus = ''
  let formattedDate = ''
  let formattedDisplayName = ''
  let statusPrefix = ''

  // Switch for status tooltip text
  switch (status.displayName) {
    case TABLE_STATUS.active:
      formattedStatus = 'Active'
      statusPrefix = 'Joined'
      formattedDate = formatDate(status.joinedAt)
      formattedDisplayName = formatDisplayName(
        ', invited by ',
        status.invitedBy,
      )
      break
    case TABLE_STATUS.invited:
      formattedStatus = statusPrefix = 'Invited'
      formattedDate = formatDate(status.invitedAt)
      formattedDisplayName = formatDisplayName(' by ', status.invitedBy)
      break
    case TABLE_STATUS.disabled:
      formattedStatus = statusPrefix = 'Disabled'
      formattedDate = formatDate(status.disabledAt)
      formattedDisplayName = formatDisplayName(' by ', status.disabledBy)
      break
    default:
      break
  }

  const tooltipText = `${statusPrefix} ${
    formattedDate || 'on unknown date'
  }${formattedDisplayName}`

  return (
    <UserTableRow className={clsx({ disabled })} data-cy={email}>
      <TableCell>{email}</TableCell>
      <TableCell>{displayName}</TableCell>

      <TableCell width="120">
        <Tooltip title={tooltipText}>
          <Box>{formattedStatus}</Box>
        </Tooltip>
      </TableCell>

      <TableCell>
        <UserTypeSwitch {...user} />
      </TableCell>

      <TableCell width="50">
        <UserStatusToggle {...user} />
      </TableCell>
    </UserTableRow>
  )
}

export default UserRow
