import { useEffect } from 'react'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import EmptyResultsBanner from 'ui/components/EmptyResultsBanner'
import { get } from 'nested-property'
import Loading from 'ui/components/Loading'
import TasksTableRow from './TasksTableRow'
import { useTaskNotifications, fetchAllTasks } from './queries'
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { RUNE_GRAY_600 } from 'theme/colors'

const useStyles = makeStyles((theme) => ({
  emptyMessageButton: {
    margin: '0 6px',
    padding: '6px',
    border: `1px solid ${RUNE_GRAY_600}`,
    borderRadius: '3px',
    position: 'relative',
    bottom: '6px',
  },
  taskNotificationsBanner: {
    paddingBottom: theme.spacing(0.2),
    paddingTop: theme.spacing(2),
  },
  statusHeader: {
    paddingLeft: theme.spacing(4),
  },
}))
/**
 * The display table container for all tasks.
 *
 * @return {JSX.Element} main table component
 */
const TasksTable = () => {
  const classes = useStyles()
  const { loading, data, fetchMore } = useTaskNotifications()
  const cursor = get(data, 'org.taskList.pageInfo.endCursor')

  useEffect(() => {
    // data must be present and a non-null cursor indicates there are more records to pull.
    if (data && cursor) {
      fetchAllTasks(fetchMore, data, cursor)
    }
  }, [data, fetchMore, cursor])

  const taskList = get(data, 'org.taskList.tasks') || []

  if (loading) {
    return (
      <Box mt={20}>
        <Loading />
      </Box>
    )
  }

  if (!taskList.length) {
    const message = (
      <>
        No task is available. Please click{' '}
        <span className={classes.emptyMessageButton}>CREATE TASK</span> on the
        top right corner to create new task.
      </>
    )

    return (
      <EmptyResultsBanner
        message={message}
        className={classes.taskNotificationsBanner}
      />
    )
  }

  return (
    <ErrorBoundary>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.statusHeader}>Status</TableCell>
              <TableCell>Task title</TableCell>
              <TableCell>Participants</TableCell>
              <TableCell>Default task period</TableCell>
              <TableCell>Default task frequency</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {taskList.map((task) => (
              <TasksTableRow task={task} key={task.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ErrorBoundary>
  )
}

export default TasksTable
