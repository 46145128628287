import { memo } from 'react'
import { string } from 'prop-types'

import SummaryBar from './SummaryBar'
import { LabelledLoading } from 'ui/components/LabelledLoading'

const SummaryBarLoading = ({
  mainContentLoadingLabel,
  rightmostContentLoadingLabel,
  subtitle,
  title,
}) => (
  <SummaryBar
    title={title}
    subtitle={subtitle}
    mainContent={<LabelledLoading label={mainContentLoadingLabel} />}
    rightmostContent={<LabelledLoading label={rightmostContentLoadingLabel} />}
  />
)

SummaryBarLoading.propTypes = {
  mainContentLabel: string.isRequired,
  rightmostContentLabel: string,
  subtitle: string,
  title: string.isRequired,
}

SummaryBarLoading.defaultProps = {
  mainContentLabel: 'Loading',
}

export default memo(SummaryBarLoading)
