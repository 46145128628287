import { useContext } from 'react'
import { TASK_NOTIFICATIONS_ROUTE } from '../consts'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import NavLink from 'ui/components/NavLink'
import UserPHIContext from 'ui/contexts/UserPHIContext'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'

const useStyles = makeStyles((theme) => ({
  taskNotificationsLink: {
    marginTop: theme.taskNotifications.button.topMargin,
  },
  taskNotificationsNavLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  taskNotificationsLinkButton: {
    padding: '7px 34px',
    maxHeight: '42px',
    color: theme.taskNotifications.button.color,
    backgroundColor: theme.taskNotifications.button.backgroundColor,
    margin: theme.taskNotifications.button.spacing,
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.taskNotifications.button.hoverColor,
    },
  },
}))

/**
 * The Link Button for Task Notifications. Brings you from the Patient List view
 * to the Task Notifications view.
 *
 * @returns {JSX.Element} The container that holds a Task Notifications button
 * @constructor
 *
 */
const TaskNotificationsLink = () => {
  const classes = useStyles()
  const { phiVisibility } = useContext(UserPHIContext)

  return (
    <ErrorBoundary>
      <div
        className={classes.taskNotificationsLink}
        data-cy="taskNotificationsLink"
      >
        <NavLink
          to={TASK_NOTIFICATIONS_ROUTE}
          className={classes.taskNotificationsNavLink}
        >
          <Button
            className={classes.taskNotificationsLinkButton}
            size={phiVisibility ? 'small' : 'large'}
            variant={'outlined'}
            color={phiVisibility ? 'default' : 'primary'}
            data-cy="patient-tasks-button"
          >
            <NoteAddOutlinedIcon
              fontSize={'small'}
              sx={{ marginRight: '.4rem' }}
            />
            Patient Tasks
          </Button>
        </NavLink>
      </div>
    </ErrorBoundary>
  )
}

export default TaskNotificationsLink
