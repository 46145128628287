import { Stack, Text } from 'ui/baseComponents'
import * as MUI from '@mui/material'

const ReportLoader = ({ loadingSet }) => (
  <Stack
    data-report-loader
    alignItems="center"
    spacing={4}
    sx={{ padding: 10 }}
  >
    <Text variant="body16">Loading {[...loadingSet].join(', ')}</Text>
    <MUI.CircularProgress size="5rem" />
  </Stack>
)

export default ReportLoader
