import { useContext } from 'react'
import { Stack } from 'ui/baseComponents/Stack'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import useMobilityAggregate from 'ui/hooks/summaryAggregates/useMobilityAggregate'
import { SummaryMetric } from '../../Summary/components/SummaryMetric'
import { MetricsRow } from './MetricsRow'
import { APPLE_MOBILITY_METRICS, ERROR_TEXT, NO_DATA_MOBILITY } from '../consts'
import { NoDataText } from 'ui/components/NoDataText'

export const MobilityMetrics = () => {
  const { dateRanges } = useContext(DateRangeContext)
  const { start, end } = dateRanges[DATE_RANGE.default.key] || {}
  const { allErrored, isLoading, data, hasData } = useMobilityAggregate(
    start,
    end,
  )

  const { stepLength, walkingSpeed, doubleSupportPercentage } = data || {}

  const Content = () => {
    if (allErrored) {
      return <NoDataText text={ERROR_TEXT} />
    }

    if (!hasData && !isLoading)
      return (
        <Stack spacing={1}>
          <NoDataText text={NO_DATA_MOBILITY} />
        </Stack>
      )

    return (
      <>
        <SummaryMetric
          title="Step Length"
          metrics={{ [`${stepLength?.averageUnit}`]: stepLength?.averageValue }}
          trend={stepLength?.trend}
        />
        <SummaryMetric
          title="Walking Speed"
          metrics={{
            [`${walkingSpeed?.averageUnit}`]: walkingSpeed?.averageValue,
          }}
        />
        <SummaryMetric
          title="Double Support %"
          metrics={{
            [`${doubleSupportPercentage?.averageUnit}`]:
              doubleSupportPercentage?.averageValue,
          }}
        />
      </>
    )
  }

  return (
    <MetricsRow
      title="Mobility"
      isLoading={isLoading}
      linkHref={APPLE_MOBILITY_METRICS.url}
      linkText={APPLE_MOBILITY_METRICS.linkText}
      linkExternal
    >
      <Content />
    </MetricsRow>
  )
}
