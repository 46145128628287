import { Accordion } from 'ui/components/Accordion'
import { string } from 'prop-types'
import { DATE_RANGE } from 'ui/contexts/DateRangeContext'
import { labels } from './consts'
import SleepDetails from './SleepDetails'
import SleepSummary from './SleepSummary'
import useSleepAggregate from 'ui/hooks/summaryAggregates/useSleepAggregate'

const Sleep = ({ titleSuffix, subtitle, start, end }) => {
  const { data, isLoading, hasData, hasError } =
    useSleepAggregate(start, end) || {}

  const { summaries, times, probabilities } = data || {}

  const title = titleSuffix ? `${labels.TITLE} ${titleSuffix}` : labels.TITLE

  if (!isLoading && !hasError && hasData) {
    return (
      <Accordion
        title={title}
        summary={
          <SleepSummary
            title={title}
            subtitle={subtitle}
            isLoading={isLoading}
            hasError={hasError}
            hasData={hasData}
            summaries={summaries}
          />
        }
        details={
          <SleepDetails
            start={start}
            end={end}
            times={times}
            probabilities={probabilities}
          />
        }
      />
    )
  }

  return (
    <Accordion
      title={title}
      summary={
        <SleepSummary
          title={title}
          subtitle={subtitle}
          isLoading={isLoading}
          hasError={hasError}
          hasData={hasData}
          summaries={summaries}
        />
      }
    />
  )
}

Sleep.propTypes = {
  titleSuffix: string,
  subtitle: string,
  dateRangeKey: string,
  start: string,
  end: string,
}

Sleep.defaultProps = {
  titleSuffix: '',
  subtitle: '',
  dateRangeKey: DATE_RANGE.default.key,
  start: '',
  end: '',
}

export default Sleep
