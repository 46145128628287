import { Fragment } from 'react'
import MainNav, { HEADER_HEIGHT } from '../MainNav'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '1024px',
    padding: theme.spacing(2),
  },
  contentContainer: {
    display: 'flex',
    overflowY: 'scroll',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
}))

const FixedWidthLayout = ({ children, sidebar }) => {
  const classes = useStyles()
  return (
    <Fragment>
      <MainNav />
      <Box className={classes.contentContainer}>
        {sidebar ? sidebar : null}
        <Box mx="auto" className={classes.container}>
          {children}
        </Box>
      </Box>
    </Fragment>
  )
}

export default FixedWidthLayout
