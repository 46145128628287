import { memo, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import * as MUI from '@mui/material'
import { Button } from 'ui/components/Button'
import { LoadingButton } from '@mui/lab'
import { Text } from 'ui/baseComponents/Text'
import { Stack } from 'ui/baseComponents/Stack'

export const AI_GENERATED_SUMMARY = 'This summary was generated by AI.*'

const EditableLLMContent = ({
  llmKey,
  llmSummary,
  onUpdate = () => {},
  addToUnsavedChanges = () => {},
  removeFromUnsavedChanges = () => {},
  ...props
}) => {
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const toggleEditMode = () => setEditMode(!editMode)
  const STYLES = {
    span: {
      display: 'inline-block',
      position: 'relative',
    },
    editButton: {
      cursor: 'pointer',
      position: 'absolute',
      top: -6,
      right: '100%',
      marginRight: 1,
      '@media print': {
        display: 'none',
      },
    },
    icon: {
      marginLeft: 0.5,
      '@media print': {
        display: 'none',
      },
    },
    actions: {
      padding: '1rem 0',
    },
  }

  useEffect(() => {
    if (editMode) {
      addToUnsavedChanges(llmKey)
    } else {
      removeFromUnsavedChanges(llmKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  const revert = () => {
    onUpdate(llmKey)
    toggleEditMode()
  }

  const handleSave = async (e) => {
    setLoading(true)
    const { value } = e.target.editedContent
    try {
      if (value !== llmSummary) {
        await onUpdate(llmKey, value)
      }
      toggleEditMode()
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  return (
    <div style={STYLES.span} {...props}>
      {editMode ? (
        <form onSubmit={handleSave}>
          <MUI.TextField
            multiline
            fullWidth
            type="text"
            name="editedContent"
            defaultValue={llmSummary}
            autoFocus
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={STYLES.actions}
          >
            <div>
              <Button onClick={revert}>Revert to original</Button>
            </div>
            <div>
              <Button onClick={toggleEditMode}>Cancel</Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={loading}
              >
                Done
              </LoadingButton>
            </div>
          </Stack>
        </form>
      ) : (
        <>
          <MUI.Button sx={STYLES.editButton} onClick={toggleEditMode}>
            LLM <EditIcon sx={STYLES.icon} />
          </MUI.Button>
          <Text variant="body16" component="p">
            {llmSummary}
          </Text>
          <Text variant="body16" component="p">
            {AI_GENERATED_SUMMARY}{' '}
          </Text>
        </>
      )}
    </div>
  )
}

export default memo(EditableLLMContent)
