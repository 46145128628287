import { Fragment, useState } from 'react'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core'
import { parseOneAddress } from 'email-addresses'

const useStyles = makeStyles(() => ({
  textArea: {
    width: '100%',
  },
}))

export const getUsersFromText = (text) => {
  const results = text
    .replace(/\n+/g, ',')
    .replace(/;+/g, ',')
    .replace(/,+/g, ',')
    .split(',')
    .filter((entry) => entry.length > 3)
    .map(parseOneAddress)
    .filter((entry) => entry && entry.address)

  return results.map((result) => ({
    email: result.address,
    displayName: result.name || '',
  }))
}

export const BulkInvite = ({ addUsers }) => {
  const [text, setText] = useState('')
  const parseText = () => {
    const users = getUsersFromText(text)
    addUsers(users)
    handleClose()
  }
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const classes = useStyles()

  return (
    <Fragment>
      <Button size="large" color="primary" onClick={handleClickOpen}>
        Bulk Invite (CSV)
      </Button>
      <Dialog
        width="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Bulk Invite Users</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a list of email addresses below, separated by commas (CSV)
          </DialogContentText>
          <FormControl fullWidth>
            <TextareaAutosize
              className={classes.textArea}
              placeholder={`john.doe@gmail.com,
Jane Doe <jane.doe@gmail.com>,
jill.johns@gmail.com,
etc.
							`}
              width="100%"
              rows={12}
              rowsMax={30}
              onChange={(e) => setText(e.target.value)}
            />
          </FormControl>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={parseText} disabled={!text.trim()}>
              Parse
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default BulkInvite
