import { useCallback, useContext, useEffect, useState } from 'react'
import { useStreamApiV2 } from 'domains/streamApi'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { PatientContext } from 'ui/contexts'
import { SLEEP_STATUS } from 'ui/clinicianScreens/Patient/Daily/consts'
import {
  getLatestDeviceId,
  DEVICE_KINDS_STRIVESTUDY,
} from 'ui/clinicianScreens/Patient/Summary/summaryHelpers'
import {
  getTimestampStartOfDay,
  getTimestampStartOfNextDay,
} from 'utilities/time'

const buildFilters = (patientId, deviceId) => ({
  patientId,
  deviceId,
  streamTypeId: 'span',
  parameters: [
    {
      key: 'measurement',
      value: 'sleep_state',
    },
  ],
})

const buildParams = (
  startTimeStartOfDay,
  endTimeEndOfDay,
  selectedTimezone,
) => {
  const startAndEndParams = {
    start_time: startTimeStartOfDay,
    end_time: endTimeEndOfDay,
  }

  const timezoneName = selectedTimezone

  return { ...startAndEndParams, timezone_name: timezoneName }
}

const useSleepStreamDataForDeviceId = (deviceId, patientId, startDate) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const initialData = []
  const NO_DATA = 0

  const [hasData, setHasData] = useState(false)
  const [data, setData] = useState(initialData)

  const startDateInUTC = getTimestampStartOfDay(startDate, selectedTimezone)
  const endDateInUTC = getTimestampStartOfNextDay(startDate, selectedTimezone)

  const {
    data: streamData = [],
    loading,
    errors,
  } = useStreamApiV2({
    filters: buildFilters(patientId, deviceId),
    params: buildParams(startDateInUTC, endDateInUTC, selectedTimezone),
    skip: !deviceId || !startDate,
    streamFilter: useCallback(
      ({ parameters }) =>
        parameters.find(
          (parameter) =>
            parameter.key === 'sleep_status' &&
            (parameter.value === SLEEP_STATUS.inBed ||
              parameter.value === SLEEP_STATUS.asleep),
        ),
      [],
    ),
  })

  useEffect(() => {
    const hasData = !!streamData.find((data) => data.cardinality > NO_DATA)
    if (hasData) {
      setHasData(hasData)
      const streamsWithData = streamData.filter(
        (stream) => stream.cardinality > NO_DATA,
      )
      setData(streamsWithData)
    }
  }, [streamData])

  return {
    loading,
    errors,
    hasData,
    data,
  }
}

const useSleepStreamData = (startDate) => {
  const { id, deviceList } = useContext(PatientContext)
  const { devices } = deviceList || {}

  const striveStudyDeviceId = getLatestDeviceId(
    devices,
    DEVICE_KINDS_STRIVESTUDY,
  )

  const { loading, errors, hasData, data } = useSleepStreamDataForDeviceId(
    striveStudyDeviceId,
    id,
    startDate,
  )

  return {
    isLoading: loading,
    hasError: !!errors?.length,
    hasData,
    data,
  }
}

export default useSleepStreamData
