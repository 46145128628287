import { useEffect, useState, useCallback, useContext } from 'react'
import CreateEntityDialog from 'ui/components/CreateEntity/CreateEntityDialog'
import {
  CREATE_TASK_NOTIFICATION,
  buildTaskNotificationRequestObject,
  isRequestReady,
} from './mutations'
import PropTypes from 'prop-types'
import TaskDialogContent from './TaskDialogContent'
import { TaskNotificationContext } from 'ui/contexts'
import { useMutation } from '@apollo/client'
import { useRefetchTaskNotifications } from '../TasksTable/queries'

/**
 * The modal for creating a Task Notification.
 *
 * @returns {JSX.Element} the Task Notification creation modal
 */
export const CreateTaskDialog = ({ handleClose }) => {
  const [success, setSuccess] = useState(false)
  const [fieldsCleared, setFieldsCleared] = useState(false)
  const [timeoutId, setTimeoutId] = useState(false)
  const { clearAllFields, ...taskNotificationContextValue } = useContext(
    TaskNotificationContext,
  )
  const [createTaskNotification, { loading }] = useMutation(
    CREATE_TASK_NOTIFICATION,
    {
      refetchQueries: [useRefetchTaskNotifications()],
      awaitRefetchQueries: true,
    },
  )

  useEffect(() => {
    if (!fieldsCleared) {
      setFieldsCleared(true)
      clearAllFields()
    }
  }, [fieldsCleared, setFieldsCleared, clearAllFields])

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      clearTimeout(timeoutId)

      try {
        const input = buildTaskNotificationRequestObject(
          taskNotificationContextValue,
        )
        await createTaskNotification({ variables: { input } })
        setSuccess(true)
        setTimeoutId(setTimeout(() => setSuccess(false), 2000))
        clearAllFields()
        handleClose()
      } catch (error) {
        console.error(error)
      }
    },
    [
      createTaskNotification,
      handleClose,
      taskNotificationContextValue,
      timeoutId,
      clearAllFields,
    ],
  )

  const disabled = !isRequestReady(taskNotificationContextValue)

  return (
    <CreateEntityDialog
      title="Create new task"
      data-testid="CreateTaskDialog"
      success={success}
      loading={loading}
      disabled={disabled}
      handleClose={handleClose}
      onSubmit={onSubmit}
      submitLabel="Save"
      successLabel="Saved"
    >
      <TaskDialogContent />
    </CreateEntityDialog>
  )
}

CreateTaskDialog.propTypes = {
  handleClose: PropTypes.func,
}

export default CreateTaskDialog
