import moment from 'moment'
import { WEEK_IN_SECONDS } from 'utilities/time'
import { formatInHoursAndMinutes } from '../../helpers'

const calculateWeeklyStartTimes = (periodStartTime) =>
  Array.from({ length: 4 }, (_, i) => periodStartTime + WEEK_IN_SECONDS * i)

export const calculateWeeklyActivityDurations = (
  events = [],
  periodStartTime,
) => {
  // Initialize an array with 4 elements, each set to 0, representing the 4 weeks.
  let weeklyDurations = Array(4).fill(0)
  const weeklyStartTimes = calculateWeeklyStartTimes(periodStartTime)
  events.forEach(({ duration: { startTime, endTimeMax } }) => {
    const durationInSeconds = endTimeMax ? endTimeMax - startTime : 0
    const weekIndex = weeklyStartTimes.findIndex(
      (weekStartTime, index) =>
        startTime >= weekStartTime &&
        startTime < (weeklyStartTimes[index + 1] || Infinity),
    )

    // If the event falls within the 4-week period, add its duration to the corresponding week.
    if (weekIndex >= 0 && weekIndex < 4) {
      weeklyDurations[weekIndex] += durationInSeconds
    }
  })

  weeklyDurations = weeklyDurations.map((durationInSeconds) =>
    Math.floor(durationInSeconds / 60),
  )

  return weeklyDurations
}

export const countWeeksAtOrOverTarget = (weeklyDurations = [], target = 150) =>
  weeklyDurations.filter((duration) => duration >= target).length

export const getAveragePerWeek = (totalDurationInMinutes = 0, returnAsNumber) =>
  returnAsNumber
    ? Math.floor(totalDurationInMinutes / 4)
    : `${Math.floor(totalDurationInMinutes / 4)}m`

export const getDurationInSeconds = (startTimestamp, endTimestamp) => {
  const start = moment.unix(startTimestamp)
  const end = endTimestamp ? moment.unix(endTimestamp) : start
  return moment.duration(end.diff(start)).asSeconds()
}

export const formatSecondsToHoursAndMinutes = (seconds) => {
  const duration = moment.duration(seconds, 'seconds')
  const { days, hours, minutes } = duration?._data
  return formatInHoursAndMinutes({ hours: hours + days * 24, minutes })
}
