import { gql } from '@apollo/client'

export const GET_DEFAULT_MEMBERSHIP = gql`
  query getDefaultMembership {
    user {
      id
      name
      username
      email
      defaultMembership {
        id
        org {
          id
          displayName
        }
        admin
      }
    }
  }
`
