import { toTitleCase } from 'utilities/string'
import { PLACEHOLDER } from '../consts'
import { eventType } from '../EventFormatting/consts'
import { formatEventTextAndColor } from '../EventFormatting/formatEventTextAndColor'
import { EVENT_TYPES_MAPPING } from './consts'

/**
 * Formatting for the various types of events: medication, symptom, activity, note, emotion, supplement
 *
 * @param {array} eventsToProcess The log items of all kinds (listed just above) to be made into rows
 * @param {boolean} phiVisibility Indicates whether or not the user has access to PHI
 * @param {string} selectedTimezone The timezone selected in the dropdown
 * @returns {object[]} List of events formatted with columns as additional keys
 */
export const processLog = ({
  eventsToProcess,
  phiVisibility,
  selectedTimezone,
}) =>
  eventsToProcess.reduce((acc, event) => {
    if (!phiVisibility && event.classification.category === eventType.NOTE) {
      return acc
    }

    const { eventText, eventColor } = formatEventTextAndColor({
      event,
      phiVisibility,
    })

    let note =
      eventText !== '' && typeof eventText !== 'undefined'
        ? String(eventText)
        : PLACEHOLDER

    note =
      event.classification.category === eventType.DBS ? toTitleCase(note) : note

    acc.push({
      eventColor,
      selectedTimezone,
      date: event.duration.startTime || PLACEHOLDER,
      type: EVENT_TYPES_MAPPING[event.classification.category] || PLACEHOLDER,
      note: note || PLACEHOLDER,
    })

    return acc
  }, [])
