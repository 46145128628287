import { useEffect, useState, useContext } from 'react'
import ErrorFrame from 'ui/components/ErrorFrame'
import Loading from 'ui/components/Loading'
import PropTypes from 'prop-types'
import TaskEditDialog from './TaskEditDialog.js'
import { TaskNotificationContext } from 'ui/contexts'
import { useFullTask } from './queries'
import {
  assignPatients,
  determineRecurrence,
  CALENDAR_CLOCK_SCHEDULE,
  INTERVAL_CLOCK_SCHEDULE,
} from './helpers'
import {
  TASK_RECURRENCE_ONCE,
  TASK_REPEATS_ON,
  TASK_REPEATS_EVERY,
  DAYS_IN_WEEK,
} from 'ui/screens/Patients/TaskNotifications/consts.js'
import { Box } from '@material-ui/core'

/**
 * The container Task Notification edit that sets its initial state
 *
 * @returns {JSX.Element} The container Task Notification edit that sets its initial state
 */
export const TaskEditContainer = ({ id, handleClose }) => {
  const [dataInitialized, setDataInitialized] = useState(false)
  const { data, error, loading } = useFullTask(id)

  const {
    setTaskTitle,
    setTaskSummary,
    setTaskDescription,
    setTaskRecurrence,
    setSingleTaskDate,
    setSingleTaskTime,
    setRecurringTaskStartDate,
    setRecurringTaskDoesEnd,
    setRecurringTaskEndDate,
    setTaskRepeatsWeekly,
    setTaskRepeatDaysArray,
    setTaskRepeatDaysInteger,
    setTaskScheduleTimesArray,
    setTaskPatientList,
  } = useContext(TaskNotificationContext)

  useEffect(() => {
    if (!!data && !dataInitialized) {
      const {
        title,
        introductionText,
        notificationText,
        defaultTaskSchedule,
        assignmentList,
      } = data.task

      const patientList = assignPatients(assignmentList)

      setTaskTitle({
        type: 'setTaskTitle',
        field: 'taskTitle',
        payload: title,
      })
      setTaskSummary({
        type: 'setTaskSummary',
        field: 'taskSummary',
        payload: notificationText,
      })
      setTaskDescription({
        type: 'setTaskDescription',
        field: 'taskDescription',
        payload: introductionText,
      })

      const schedule =
        defaultTaskSchedule?.schedules && defaultTaskSchedule?.schedules[0]
          ? defaultTaskSchedule.schedules[0]
          : {}

      const recurrence = determineRecurrence(schedule)
      setTaskRecurrence(recurrence)

      if (recurrence === TASK_RECURRENCE_ONCE) {
        const singleDateAndTime = defaultTaskSchedule.startTime.split('T')
        setSingleTaskDate(singleDateAndTime[0])
        setSingleTaskTime(singleDateAndTime[1])
      } else {
        if (schedule?.scheduleType === CALENDAR_CLOCK_SCHEDULE) {
          setRecurringTaskStartDate(defaultTaskSchedule?.startTime || '')
          setRecurringTaskDoesEnd(
            defaultTaskSchedule?.endTime ? 'true' : 'false',
          )
          setRecurringTaskEndDate(defaultTaskSchedule?.endTime || '')
          setTaskRepeatsWeekly(TASK_REPEATS_ON)
          setTaskRepeatDaysArray(schedule?.daysOfWeek || [])
          setTaskScheduleTimesArray(schedule?.times || [])
        }
        if (schedule?.scheduleType === INTERVAL_CLOCK_SCHEDULE) {
          setRecurringTaskStartDate(defaultTaskSchedule?.startTime || '')
          setRecurringTaskDoesEnd(
            defaultTaskSchedule?.endTime ? 'true' : 'false',
          )
          setRecurringTaskEndDate(defaultTaskSchedule?.endTime || '')
          setTaskRepeatsWeekly(TASK_REPEATS_EVERY)
          setTaskRepeatDaysInteger(schedule?.dateInterval || DAYS_IN_WEEK)
          setTaskScheduleTimesArray(schedule?.times || [])
        }
      }

      setTaskPatientList(patientList)
      setDataInitialized(true)
    }
  }, [
    data,
    dataInitialized,
    setTaskTitle,
    setRecurringTaskDoesEnd,
    setRecurringTaskEndDate,
    setRecurringTaskStartDate,
    setSingleTaskDate,
    setSingleTaskTime,
    setTaskDescription,
    setTaskPatientList,
    setTaskRecurrence,
    setTaskRepeatDaysArray,
    setTaskRepeatDaysInteger,
    setTaskRepeatsWeekly,
    setTaskScheduleTimesArray,
    setTaskSummary,
  ])

  if (error) {
    return <ErrorFrame />
  }

  if (loading) {
    return (
      <Box pt={25} sx={{ height: '400px', width: '1200px' }}>
        <Loading />
      </Box>
    )
  }

  return <TaskEditDialog id={id} handleClose={handleClose} />
}

TaskEditContainer.propTypes = {
  id: PropTypes.string,
  handleClose: PropTypes.func,
}

export default TaskEditContainer
