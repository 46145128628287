import { useContext } from 'react'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemLink from 'ui/components/ListItemLink'
import PatientSelect from 'ui/components/PatientSelect'
import { PatientContext } from 'ui/contexts/PatientContext'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { push } from 'redux-first-history'
import { Z_INDEX_DRAWER } from 'theme/zIndexRegistry'

const drawerWidth = 240
const useStyles = makeStyles(() => ({
  active: {
    fontWeight: 'bold',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: Z_INDEX_DRAWER,
  },
  drawerPaper: {
    top: 'auto',
    width: drawerWidth,
  },
}))

const MenuList = styled(List)`
  a.active {
    .menu-item {
      font-weight: bold !important;
    }
  }
`

const EditPatientSidebar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const patient = useContext(PatientContext)

  /**
   * Function that dispatches the user to the Edit Patient Details view
   * of the selected patient.
   *
   * @param {event} event The event created from selecting a new patient
   * @returns {void}
   */
  const onPatientChange = (event) => {
    const newPatientId = event.target.value

    // checks if a change has been made
    if (!newPatientId || newPatientId === patient.id) {
      return
    }

    // For simplicity, we're just redirecting to the edit patient details view
    const url = `/patients/${newPatientId}/manage/details`
    dispatch(push(url))
  }

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List>
        <ListItem>
          <PatientSelect
            patient={patient}
            onPatientChange={onPatientChange}
            patientBreadcrumb={false}
          />
        </ListItem>
      </List>
      <Divider />
      <MenuList>
        <ListItemLink
          button
          to={`/patients/${patient.id}/manage/details`}
          activeClassName="active"
          data-cy="edit-patient-sidebar-link-details"
        >
          <ListItemText
            classes={{ primary: 'menu-item' }}
            primary="Patient Details"
          />
        </ListItemLink>
        <ListItemLink
          button
          to={`/patients/${patient.id}/manage/clients`}
          activeClassName="active"
          data-cy="edit-patient-sidebar-link-clients"
        >
          <ListItemText classes={{ primary: 'menu-item' }} primary="Clients" />
        </ListItemLink>
        <ListItemLink
          button
          to={`/patients/${patient.id}/manage/devices`}
          activeClassName="active"
          data-cy="edit-patient-sidebar-link-devices"
        >
          <ListItemText classes={{ primary: 'menu-item' }} primary="Devices" />
        </ListItemLink>
      </MenuList>
    </Drawer>
  )
}

export default EditPatientSidebar
