import React from 'react'
import CreateTaskDialog from '../CreateTaskDialog'
import CreateEntity from 'ui/components/CreateEntity'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonClass: {
    color: theme.palette.primary,
    boxShadow: theme.shadows[2],
  },
  headerClass: {
    justifyContent: `${theme.createEntity.justifyContent}`,
    display: 'flex',
    color: `${theme.createEntity.color}`,
  },
}))
/**
 * The "create task" button and container for the modal it triggers.
 */
const CreateTask = () => {
  const classes = useStyles()
  return (
    <CreateEntity
      label="Create Task"
      variant="outlined"
      customButtonClass={classes.buttonClass}
      customHeaderClass={classes.headerClass}
      size={'lg'}
    >
      <CreateTaskDialog />
    </CreateEntity>
  )
}

export default CreateTask
