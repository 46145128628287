import ReportMetric from './ReportMetric'
import ReportChartWithTitle from './ReportChartWithTitle'
import {
  formatBarChartData,
  getReportMetricProps,
  getCombinedMaxValue,
} from '../helpers'
import { CircularProgress } from '@mui/material'
import { Stack } from 'ui/baseComponents/Stack'
import BarChart from 'ui/baseComponents/Charts/BarChart'
import { AVG_PER, PERIODS } from 'ui/clinicianScreens/Patient/Report/consts'

const ReportBarChart = ({
  title,
  currentData,
  prevData,
  diff,
  recentPeriodColor,
  previousPeriodColor,
}) => {
  const recentPeriodLegendItem = {
    name: PERIODS.recent,
    color: recentPeriodColor,
  }
  const previousPeriodLegendItem = {
    name: PERIODS.previous,
    color: previousPeriodColor,
  }

  const noData = !currentData?.numberOfDaysWithData
  const noPrevData = !prevData?.numberOfDaysWithData
  const formattedCurrentData = formatBarChartData(currentData, PERIODS.recent)
  const formattedPrevData = formatBarChartData(prevData, PERIODS.previous)
  const reportMetricProps = getReportMetricProps(currentData, prevData, diff)
  const yAxisMax = getCombinedMaxValue(currentData, prevData)

  const titleProps = {
    noData,
    subtitle: AVG_PER.day,
    trend: <ReportMetric {...reportMetricProps} />,
  }

  return (
    <ReportChartWithTitle {...{ title, ...titleProps }}>
      {!noData ? (
        <>
          <BarChart
            {...{
              title,
              data: formattedCurrentData,
              legendItem: recentPeriodLegendItem,
              yAxisMax,
              yAxisUnit: ' mins',
              legend: true,
              isAnimationActive: false,
              height: 150,
              chartId: `${title.toLowerCase()}-chart-recent`,
            }}
          />

          {!noPrevData && (
            <BarChart
              {...{
                title,
                data: formattedPrevData,
                legendItem: previousPeriodLegendItem,
                yAxisMax,
                yAxisUnit: ' mins',
                legend: true,
                isAnimationActive: false,
                height: 150,
                chartId: `${title.toLowerCase()}-chart-previous`,
              }}
            />
          )}
        </>
      ) : (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
    </ReportChartWithTitle>
  )
}

export default ReportBarChart
