import { gql, useQuery } from '@apollo/client'

export const GET_PERSISTENT_VIEWS = gql`
  query getPersistentViewList($id: ID!) {
    patient(id: $id) {
      id
      persistentViewList {
        pageInfo {
          endCursor
        }
        views {
          id
          createdAt
          latest {
            id
            createdByActor {
              ... on ExternalUser {
                displayName
              }
              ... on Membership {
                user {
                  id
                  displayName
                }
              }
            }
            data
            dataSchemaVersion
            displayName
          }
          patient {
            id
            codeName
          }
        }
      }
    }
  }
`

/**
 * Get the full list of persistent views for a patient
 * @return {{loading, error, persistentViewList}}
 */
export const usePersistentViewsList = (id) => {
  const { loading, error, data } = useQuery(GET_PERSISTENT_VIEWS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })

  const persistentViewList =
    data && data.patient && data.patient.persistentViewList
      ? data.patient.persistentViewList.views
      : undefined

  return {
    loading,
    error,
    persistentViewList,
  }
}

export const useRefetchPersistentViewsList = (id) => {
  return {
    query: GET_PERSISTENT_VIEWS,
    variables: { id },
  }
}
