import PatientErrorCard from '../../PatientErrorCard'
import * as MUI from '@mui/material'
import { Text } from 'ui/baseComponents/Text'
import { NO_DATA_TOP_SYMPTOMS } from '../consts'
import { NoDataText } from 'ui/components/NoDataText'
import useBoundStore from 'domains/zustand/store'

const PatientSymptoms = () => {
  const STYLES = {
    olNumber: {
      width: 26,
      display: 'inline-block',
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }

  const symptoms = useBoundStore((state) => state.topSymptomEventCounts)
  const isLoading = useBoundStore((state) => state.eventCountLoading)
  const hasError = useBoundStore((state) => state.eventCountError)

  if (hasError) {
    return <PatientErrorCard />
  }

  if (isLoading) {
    return <MUI.LinearProgress />
  }

  if (!symptoms?.length) {
    return <NoDataText text={NO_DATA_TOP_SYMPTOMS} />
  }

  return (
    <MUI.List subheader={<Text variant="caps12B">Top 5</Text>} component="ol">
      {symptoms.map(({ count, customDetail, displayName }, index) => {
        const name = customDetail?.displayName || displayName
        return (
          <MUI.ListItem
            key={name}
            id={name}
            disableGutters
            style={STYLES.listItem}
          >
            <MUI.ListItemText
              primary={
                <Text variant="body16">
                  <span style={STYLES.olNumber}>{index + 1}</span>
                  {name} ({count})
                </Text>
              }
            />
          </MUI.ListItem>
        )
      })}
    </MUI.List>
  )
}

export default PatientSymptoms
