import { Text } from 'ui/baseComponents'

const DailyChartTitle = ({ children, ...props }) => {
  const styles = props.sx
  return (
    <Text {...props} sx={{ zIndex: 1, ...styles }}>
      <span
        style={{
          backgroundColor: '#ffffffc4',
          display: 'inline-block',
        }}
      >
        {children}
      </span>
    </Text>
  )
}

export default DailyChartTitle
