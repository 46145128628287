import { memo } from 'react'
import { oneOf } from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

import { TREND } from './consts'

const useStyles = makeStyles({
  root: {
    fontSize: '1.75rem',
    verticalAlign: 'middle',
  },
})

const SummaryMetricTrend = ({ trend }) => {
  const classes = useStyles()
  if (!trend || trend === TREND.none) return null

  return (
    <>
      {trend === TREND.up && (
        <>
          <ArrowUpward classes={{ root: classes.root }} />
          {/* TODO: Update text for screenreaders */}
          <Typography variant="srOnly">Trending up</Typography>
        </>
      )}
      {trend === TREND.down && (
        <>
          <ArrowDownward classes={{ root: classes.root }} />
          {/* TODO: Update text for screenreaders */}
          <Typography variant="srOnly">Trending down</Typography>
        </>
      )}
    </>
  )
}

SummaryMetricTrend.propTypes = {
  trend: oneOf([TREND.down, TREND.up, TREND.none]),
}

SummaryMetricTrend.defaultProps = {}

export default memo(SummaryMetricTrend)
