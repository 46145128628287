import React from 'react'
import { Checkbox, withStyles } from '@material-ui/core'
import { STRIVEPD_PRIMARY_1000 } from 'theme/colors'

const CheckboxPrimary = withStyles({
  root: {
    '&$checked': {
      color: STRIVEPD_PRIMARY_1000,
    },
  },
  checked: {},
})((props) => (
  <Checkbox color="default" {...props} data-cy="checkbox-primary" />
))

export default CheckboxPrimary
