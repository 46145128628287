import { gql } from '@apollo/client'

export const CREATE_DEVICE = gql`
  mutation createDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      device {
        id
      }
    }
  }
`
