import styled, { keyframes } from 'styled-components'
import { STRIVEPD_PRIMARY_1000 } from 'theme/colors'

const Animation = keyframes`
  0% { opacity: 40% }
  25% { opacity: 60% }
  50% { opacity: 80% }
  75% { opacity: 60% }
  100% { opacity: 40% }
`

const Dot = styled.div`
  background-color: ${STRIVEPD_PRIMARY_1000};
  border-radius: 50%;
  width: 0.33em;
  height: 0.33em;
  margin: 0.2em;
  animation: ${Animation} 1.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`

export default Dot
