import { useStreamApiBatch } from 'domains/streamApi'
import {
  createDailyAggregateRequests,
  formatAggregateData,
} from './apiDataHelpers'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

export const METRICS = [
  { id: 'tremor_duration', name: 'tremor' },
  { id: 'dyskinesia_duration', name: 'dyskinesia' },
]

const ZERO_DAYS_OF_DATA = 0

/*
 *
 * @typedef {Object} TremorAndDyskinesiaAggregate
 * @property {Object<string, AggregateData>} data - the data returned from the API
 * @property {AggregateData} data.tremor - the tremor data
 * @property {AggregateData} data.dykinesia - the dyskinesia data
 * @property {number} data.numberOfDaysWithData - the max number of days with data for either metric
 *
 *
 *
 * useTremorAndDyskinesiaAggregate calls for the Tremor and Dyskinesia Daily Aggregate data
 *
 * @param {string} startDate - the date to begin querying
 * @param {string} endDate - the date to end querying
 * @param {int} resolution - the size of the time interval in seconds
 * @return {{
 *   data: TremorAndDyskinesiaAggregate,
 *   hasData: boolean,
 *   isLoading: boolean,
 *   hasError: boolean,
 * }} - the tremor and dyskinesia aggregate data
 *
 */
const useTremorAndDyskinesiaAggregate = (startDate, endDate, resolution) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const patientContext = useContext(PatientContext) || {}
  const devices = patientContext?.deviceList?.devices || []
  const requests = createDailyAggregateRequests({
    metrics: METRICS,
    startDate,
    endDate,
    patientId: patientContext.id,
    resolution,
    selectedTimezone,
  })

  const {
    data: streamData = [],
    errors,
    loading,
  } = useStreamApiBatch(requests, selectedTimezone)

  const formattedData = formatAggregateData(METRICS, streamData)

  return {
    data: formattedData,
    hasDevice: devices.length > 0,
    hasData:
      !!streamData?.length &&
      formattedData.numberOfDaysWithData > ZERO_DAYS_OF_DATA,
    isLoading: loading,
    hasError: !!(errors && errors.length) || !!formattedData.hasError,
  }
}

export default useTremorAndDyskinesiaAggregate
