import * as MUI from '@mui/material'
import { Stack } from 'ui/baseComponents'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Report/consts'
import { getIsDINVariant } from 'ui/clinicianScreens/Patient/Report/helpers'

const STYLES = {
  toggles: {
    '@media print': {
      display: 'none',
    },
  },
}

const ReportSectionsToggle = ({
  hasDataSet,
  hiddenSections,
  isClinicianReport,
  setHiddenSections,
  variant,
}) => {
  const isDINVariant = getIsDINVariant(variant)
  const {
    HIGHLIGHTS,
    SUPPORTING_MATERIALS,
    SUPPORTING_MATERIALS_RED_FLAGS_AND_OTHER_REPORTED_ISSUES,
    ABOUT_DIN,
    DYSKINESIA_SYMPTOMS,
    ...sectionTitles
  } = SECTION_TITLES

  let titlesToShow = sectionTitles

  if (!isDINVariant) {
    const { DIN, ...titles } = sectionTitles
    titlesToShow = titles
  }

  const { RED_FLAGS, OTHER_ISSUES, DIN } = titlesToShow

  const hideSection = (key) => {
    setHiddenSections((prev) => new Set(prev.add(key)))
  }

  const showSection = (key) => {
    setHiddenSections((prev) => {
      const next = new Set(prev)
      next.delete(key)
      return next
    })
  }

  const toggleLabels = isClinicianReport
    ? Object.values(titlesToShow)
    : Object.values(titlesToShow).filter(
        (title) => title !== RED_FLAGS && title !== OTHER_ISSUES,
      )
  return (
    <Stack
      sx={STYLES.toggles}
      direction="row"
      alignItems="center"
      spacing={4}
      flexWrap="wrap"
    >
      <MUI.FormLabel component="legend">Show sections:</MUI.FormLabel>
      <MUI.FormGroup row>
        {toggleLabels.map((label) => (
          <MUI.FormControlLabel
            control={
              <MUI.Checkbox
                disabled={
                  isClinicianReport &&
                  ![RED_FLAGS, OTHER_ISSUES, DIN].includes(label) &&
                  !hasDataSet.has(label)
                }
                checked={!hiddenSections.has(label)}
                onChange={(e) => {
                  if (e.target.checked) {
                    showSection(label)
                  } else {
                    hideSection(label)
                  }
                }}
              />
            }
            label={label}
            key={label}
            data-cy={`toggle-${label}`}
          />
        ))}
      </MUI.FormGroup>
    </Stack>
  )
}

export default ReportSectionsToggle
