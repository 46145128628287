import { RUNE_GRAY_900 } from 'theme/colors'

const theme = {
  overrides: {
    MUIDataTableHeadCell: {
      fixedHeader: {
        /* Prevent header cells from blocking the date range picker dropdown */
        zIndex: 'unset',
        /* Consistent header cell typography formatting */
        fontSize: '1em',
        color: RUNE_GRAY_900,
        fontWeight: 600,
        verticalAlign: 'middle',
      },
      data: {
        /* Consistent header cell typography formatting */
        letterSpacing: 0,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        /* Consistent cell alignment */
        verticalAlign: 'top',
      },
    },
    /* Consistent spacing between filter menu and show/hide column menu */
    MUIDataTableFilter: {
      root: {
        padding: '12px 24px 24px',
      },
      gridListTile: {
        marginTop: 0,
      },
    },
    MUIDataTableSearch: {
      /* Remove search icon from next to search field */
      searchIcon: {
        display: 'none',
      },
      /* Leverage entirety of parent space for search text */
      searchText: {
        flex: '1 0',
      },
    },
    MUIDataTableToolbar: {
      /* Enable left section of toolbar to fill space until toolbar icons */
      actions: {
        flex: 'unset',
      },
      /* Change minimum size */
      filterPaper: {
        minWidth: 240,
      },
      /* Recalibrate title font size */
      titleText: {
        fontSize: '1.5rem',
      },
    },
  },
}

export default theme
