import { number, string } from 'prop-types'
import JumpToDateLink from './JumpToDateLink'
const { VIEW_AVAILABLE_DATA_TEXT } = require('./consts')

const CollapsibleViewsNoData = ({ text, latestActivityDate, alertType }) => (
  <>
    {text}{' '}
    {latestActivityDate && (
      <JumpToDateLink date={latestActivityDate} alertType={alertType}>
        {VIEW_AVAILABLE_DATA_TEXT}
      </JumpToDateLink>
    )}
  </>
)

CollapsibleViewsNoData.propTypes = {
  text: string,
  latestActivityDate: number,
  alertType: string,
}

export default CollapsibleViewsNoData
