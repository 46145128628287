export const LEVADOPA_PD_MEDICATIONS = [
  'carbidopa-levodopa',
  'duopa',
  'parcopa',
  'rytary',
  'sinemet-cr',
  'sinemet',
  'stalevo',
]

export const NON_LEVADOPA_PD_MEDICATIONS = [
  'amantadine',
  'artane',
  'azilect',
  'comtan',
  'gocovri',
  'mirapex-er',
  'mirapex',
  'neupro',
  'nourianz',
  'opicapone',
  'requip ',
  'requip-xl',
  'rivastigmine',
  'selegiline-hcl',
  'selegiline',
]

export const PD_MEDICATIONS = [
  ...LEVADOPA_PD_MEDICATIONS,
  ...NON_LEVADOPA_PD_MEDICATIONS,
]

export const LEVADOPA_MED_DISPLAY_NAMES = [
  'Carbidopa/Levodopa',
  'Duopa',
  'Parcopa',
  'Rytary',
  'Sinemet CR',
  'Sinemet',
  'Stalevo',
]

export const NON_LEVADOPA_MED_DISPLAY_NAMES = [
  'Amantadine',
  'Artane',
  'Azilect',
  'Comtan',
  'Gocovri',
  'Mirapex ER',
  'Mirapex',
  'Neupro',
  'Nourianz',
  'Opicapone',
  'Requip XL',
  'Requip',
  'Rivastigmine',
  'Selegiline HC',
  'Selegiline',
]

export const TABLE_STYLES = (minimalVersion) => ({
  table: {
    flex: 1,
  },
  tableContent: {
    padding: minimalVersion ? 0 : '0.5rem 1.5rem',
    margin: 0,
  },
  firstColumn: {
    width: minimalVersion ? '240px' : '180px',
    '@media print': {
      width: '170px',
    },
  },
  secondColumn: {
    width: '150px',
    '@media print': {
      width: '120px',
    },
  },
  thirdColumn: {
    width: minimalVersion ? '' : '150px',
    minWidth: minimalVersion ? '200px' : '',
    '@media print': {
      width: '100px',
    },
  },
  fourthColumn: {
    width: minimalVersion ? '' : '200px',
    '@media print': {
      width: '130px',
    },
  },
  emptyColumn: {
    display: 'none',
  },
})

export const SCHEDULED_TABLE_TITLES = {
  pdMedications: 'PD Medications',
  otherMedications: 'Other Medications',
  supplements: 'Supplements',
  asNeeded: 'As needed',
  main: 'Current medication schedule',
}

export const SCHEDULED_TABLE_HEADINGS = {
  dosage: 'Dosage',
  frequency: 'Frequency',
  times: 'Times',
  change: 'Change | Date',
}

export const LOGGED_TABLE_TITLES = {
  medications: 'Medications',
  pdMedications: 'PD Medications',
}

export const LOGGED_TABLE_HEADINGS = {
  dosage: 'Dosage',
  logs: 'Logs',
  previousPeriod: 'Previous Period',
}

export const NEW_MEDICATION_DAYS_THRESHOLD = 90
