import { PropTypes } from 'prop-types'
import { useState, useEffect, useMemo } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import './MonthPicker.scss'
import { Box, Button, makeStyles } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { MAX_RANGE_ADJUSTED } from 'ui/components/LoadMoreMonths'
import { DATE_FORMAT } from 'ui/consts'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    float: 'right',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dateNavigation: {
    display: 'flex',
    alignItems: 'center',
  },

  monthPickerContainer: {
    padding: theme.spacing(1),
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  arrowForward: {
    display: 'inline-flex',
    padding: '1rem 0.5rem',
    color: theme.palette.secondary.medium,
  },
  arrow: {
    height: '3rem',
    width: '3rem',
    padding: '0.5rem',
    marginTop: '0.2rem',
    color: theme.palette.secondary.medium,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
    cursor: 'pointer',
  },
}))

const MonthPicker = ({ from: fromProp, to: toProp, setDateRange }) => {
  const classes = useStyles()
  const [startMonth, setStartMonth] = useState(fromProp)
  const [endMonth, setEndMonth] = useState(toProp)
  const [newStart, setNewStart] = useState()
  const [newEnd, setNewEnd] = useState()
  const [endMonthOpen, setEndMonthOpen] = useState(false)
  const [selectFinished, setSelectFinished] = useState(false)
  const startMaxDate = moment().endOf('month')
  const endMaxDate =
    moment(startMonth).add(1, 'years') < moment()
      ? moment(startMonth).add(MAX_RANGE_ADJUSTED, 'months').endOf('month')
      : moment().endOf('month')
  const [errorStartMonthDatePicker, setErrorStartMonthDatePicker] =
    useState(null)
  const [errorEndMonthDatePicker, setErrorEndMonthDatePicker] = useState(null)

  const updateErrorMessage = (error) => {
    switch (error) {
      case 'maxDate':
      case 'minDate': {
        return 'Invalid Date'
      }

      default: {
        return ''
      }
    }
  }

  const startMonthDatePickerErrorMessage = useMemo(() => {
    return updateErrorMessage(errorStartMonthDatePicker)
  }, [errorStartMonthDatePicker])

  const endMonthDatePickerErrorMessage = useMemo(() => {
    return updateErrorMessage(errorEndMonthDatePicker)
  }, [errorEndMonthDatePicker])

  // Opens the End Month picker on close of the start month picker. This gives
  // the component a connected feel and more importantly forces a close event on
  // the End Month picker later, which is when we trigger the request
  const handleStartMonthClose = () => {
    setEndMonthOpen(true)
  }

  // When the onClose function of the End Month Picker is triggered, set
  // selectFinished to true, which triggers the useEffect to run a new request
  // with the updated time range
  const handleEndMonthClose = () => {
    setEndMonthOpen(false)
    setSelectFinished(true)
  }

  // Updates the Start Month Picker. Both startMonth and newStart need to be
  // updated in order to reflect the local change in the picker and provide the
  // new request with props (using only startMonth causes the picker to make a
  // request with the old parameters because the change does not happen quickly enough)
  const handleStartMonthChange = (e) => {
    setStartMonth(e)
    setNewStart(e)
  }

  // Updates the End Month Picker. Both endMonth and newEnd need to be
  // updated in order to reflect the local change in the picker and provide the
  // new request with props (using only startMonth causes the picker to make a
  // request with the old parameters because the change does not happen quickly enough)
  const handleEndMonthChange = (e) => {
    if (!e.isAfter(startMaxDate)) {
      setEndMonth(e)
      setNewEnd(e)
    }
  }

  // Shifts the date range one month in the future. Will not run past the current month
  const onRightArrowPress = () => {
    const currentEndOfMonth = moment().endOf('month')
    const newStart = moment(fromProp).add(1, 'month').startOf('month')
    const newEnd = moment(toProp).add(1, 'month').endOf('month')

    if (!newEnd.isAfter(currentEndOfMonth)) {
      setDateRange({
        from: newStart.format(DATE_FORMAT),
        to: newEnd.format(DATE_FORMAT),
      })
    }
  }

  // Shifts the date range one month in the past.
  const onLeftArrowPress = () => {
    const newStart = moment(fromProp).subtract(1, 'month').startOf('month')
    const newEnd = moment(toProp).subtract(1, 'month').endOf('month')

    if (!newStart.isBefore('2019-01-01')) {
      setDateRange({
        from: newStart.format(DATE_FORMAT),
        to: newEnd.format(DATE_FORMAT),
      })
    }
  }

  // Resets the date range to the last three months
  const onTodayButtonPress = () => {
    const newEnd = moment().endOf('month')
    const newStart = moment().subtract(2, 'month').startOf('month')

    setDateRange({
      from: newStart.format(DATE_FORMAT),
      to: newEnd.format(DATE_FORMAT),
    })
  }

  useEffect(() => {
    // handler to prevent conflicts with other date adjustment components in the
    // same view. Updates the pickers if their props do not match their displayed
    // dates, assuming a new date has not been selected in the picker itself.
    if (
      !newEnd &&
      !newStart &&
      (fromProp !== startMonth || toProp !== endMonth)
    ) {
      setStartMonth(fromProp)
      setEndMonth(toProp)
    }

    // runs new date range request if the user has finished the entire flow.
    if (newEnd && selectFinished) {
      if (newStart) {
        setDateRange({
          from: newStart.format(DATE_FORMAT),
          to: newEnd.format(DATE_FORMAT),
        })
        setSelectFinished(false)
        setNewEnd('')
        setNewStart('')
      } else {
        setDateRange({
          from: fromProp,
          to: newEnd.format(DATE_FORMAT),
        })
        setSelectFinished(false)
        setNewEnd('')
        setNewStart('')
      }
    }
  }, [
    newEnd,
    newStart,
    selectFinished,
    setDateRange,
    fromProp,
    toProp,
    startMonth,
    endMonth,
  ])

  return (
    <Box className={classes.container}>
      <Box className={classes.dateNavigation}>
        <ChevronLeftIcon className={classes.arrow} onClick={onLeftArrowPress} />
        <Box className={classes.monthPickerContainer} id="monthPicker">
          <DatePicker
            format="MM/YYYY"
            views={['year', 'month']}
            label=""
            minDate={moment('2019-01-01')}
            maxDate={moment(startMaxDate)}
            value={moment(startMonth)}
            onChange={(e) => {
              handleStartMonthChange(e)
            }}
            onClose={handleStartMonthClose}
            onError={(newError) => setErrorStartMonthDatePicker(newError)}
            slotProps={{
              textField: {
                helperText: startMonthDatePickerErrorMessage,
              },
            }}
            className={classes.monthPicker}
          />

          <Box className={classes.arrowForward}>
            <ArrowForwardIcon />
          </Box>

          <DatePicker
            format="MM/YYYY"
            views={['year', 'month']}
            label=""
            minDate={moment(startMonth)}
            maxDate={moment(endMaxDate)}
            value={moment(endMonth)}
            onChange={(e) => {
              handleEndMonthChange(e)
            }}
            onClose={handleEndMonthClose}
            onOpen={() => setEndMonthOpen(true)}
            open={endMonthOpen}
            onError={(newError) => setErrorEndMonthDatePicker(newError)}
            slotProps={{
              textField: {
                helperText: endMonthDatePickerErrorMessage,
              },
            }}
          />
        </Box>
        <ChevronRightIcon
          className={classes.arrow}
          onClick={onRightArrowPress}
        />
        <Button
          variant="outlined"
          className={classes.todayButton}
          onClick={onTodayButtonPress}
        >
          Today
        </Button>
      </Box>
    </Box>
  )
}

MonthPicker.propTypes = {
  from: PropTypes.PropTypes.instanceOf(Date),
  to: PropTypes.PropTypes.instanceOf(Date),
  setDateRange: PropTypes.func,
}

export default MonthPicker
