import { Fragment, useState, useCallback } from 'react'
import NewPersistentViewDialog from './NewPersistentViewDialog'
import { NotificationBar, Success, Error } from 'ui/components/Notifications'
import { pushQuery as pushQueryAction } from 'query'
import { UPDATE_VIEW } from '../mutations'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { useRefetchPersistentViewsList } from '../queries'
import { Button, Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}))

/**
 * The button group for the Persistent Views feature. Currently contains update
 * and save-as-new-functionality and conditionally displays them.
 *
 * @param {boolean} editing Represents a change in streams from the saved version of the selected view
 * @param {object} selectedView The currently selected view
 * @param {object} streams The stored result of the streams context.
 * @param {object} patient The patient whose data is viewed
 */
const PersistentViewsButtonGroup = ({
  editing,
  selectedView,
  streams,
  patient,
}) => {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [timeoutId, setTimeoutId] = useState(false)
  const [createSuccess, setCreateSuccess] = useState()
  const [timeoutIdCreate, setTimeoutIdCreate] = useState(false)
  const dispatch = useDispatch()
  const pushQuery = (...args) => dispatch(pushQueryAction(...args))
  const confirm = useConfirm()
  const classes = useStyles()
  const viewString =
    selectedView && selectedView.latest && selectedView.latest.displayName
      ? 'Are you sure you want to update the view ' +
        selectedView.latest.displayName +
        '?'
      : 'Are you sure you want to update the current view?'
  const [updateViewMutation] = useMutation(UPDATE_VIEW, {
    onError: (error) => {
      console.error(error)
      setError('Unable to update view')
    },
    refetchQueries: [useRefetchPersistentViewsList(patient.id)],
    awaitRefetchQueries: true,
  })

  /**
   * Function that runs the request to update the current selected view
   */
  const updateView = useCallback(async () => {
    try {
      clearTimeout(timeoutId)
      const streamData = JSON.stringify(streams)
      await updateViewMutation({
        variables: {
          input: {
            persistentViewId: selectedView.id,
            dataSchemaVersion: 1,
            data: streamData,
            displayName: selectedView.latest.displayName,
          },
        },
      })
      setSuccess(true)
      setTimeoutId(setTimeout(() => setSuccess(false), 2500))
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }, [updateViewMutation, streams, selectedView, timeoutId])

  /**
   * Function that shows the user their view has been successfully saved
   */
  const showCreationSuccess = useCallback(async () => {
    clearTimeout(timeoutIdCreate)
    setCreateSuccess(true)
    setTimeoutIdCreate(setTimeout(() => setCreateSuccess(false), 2500))
  }, [timeoutIdCreate])

  /**
   * Displays a confirmation dialog, then runs the function to update the view
   */
  const updateViewConfirmation = useCallback(async () => {
    await confirm({
      confirmationButtonProps: { variant: 'contained' },
      title: 'Update this view',
      description: <Fragment>{viewString}</Fragment>,
    })
    updateView()
  }, [confirm, viewString, updateView])

  /**
   * Opens the NewPersistentViewDialog upon clicking the save-as-new button
   */
  const onClick = useCallback(async () => setOpen(true), [])

  /**
   * Closes the NewPersistentViewDialog
   */
  const handleClose = useCallback(async () => setOpen(false), [])

  /**
   * Function that pushes the new view ID to the URL params
   *
   * @param {object} data The returned response from the create view mutation
   */
  const pushViewParam = (data) => {
    try {
      pushQuery({
        view: data.createPersistentView.view.id,
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Grid
      classes={{ item: classes.grid }}
      container
      item
      data-cy="PersistentViewsButtonGroup"
    >
      {editing && (
        <Button
          variant="outlined"
          edge="start"
          className={classes.button}
          color="primary"
          endIcon={<SaveIcon />}
          size="small"
          onClick={updateViewConfirmation}
        >
          Update View
        </Button>
      )}
      {streams.length > 0 && (
        <Button
          variant="outlined"
          edge="start"
          className={classes.button}
          color="primary"
          endIcon={<AddIcon />}
          size="small"
          onClick={onClick}
        >
          Save As New View
        </Button>
      )}
      {open && (
        <NewPersistentViewDialog
          open={open}
          patient={patient}
          handleClose={handleClose}
          pushViewParam={pushViewParam}
          showCreationSuccess={showCreationSuccess}
          streams={streams}
        />
      )}
      {createSuccess && (
        <NotificationBar open={createSuccess}>
          <Success message="Your view was successfully saved." />
        </NotificationBar>
      )}

      {success && (
        <NotificationBar open={success}>
          <Success message="Your view was successfully updated." />
        </NotificationBar>
      )}

      {error && (
        <NotificationBar open={success}>
          <Error message={error} />
        </NotificationBar>
      )}
    </Grid>
  )
}
export default PersistentViewsButtonGroup
