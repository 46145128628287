import { useGetHeaders } from 'domains/auth/hooks'
import { useTangerineApi } from 'domains/tangerine/hooks'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'

const createPostReportLLMRequest = (
  patientId,
  startTime,
  endTime,
  regenerate,
) => ({
  patient_id: patientId,
  start_date: startTime,
  end_date: endTime,
  regenerate,
})

const createPatchReportLLMRequest = (
  patientId,
  reportId,
  editedSummaries,
  reviewerComments,
  reviewerClassification,
) => ({
  patient_id: patientId,
  llm_report_data_id: reportId,
  edited_summaries: editedSummaries,
  reviewer_comments: reviewerComments,
  reviewer_classification: reviewerClassification,
  approved: true,
})

const useReportLLM = ({ options, method = 'POST', endpointSuffix = '' }) => {
  const patientContext = useContext(PatientContext) || {}
  const { config } = window.Rune.Carrot

  let params = {}
  switch (method) {
    case 'GET':
      params = createPostReportLLMRequest(
        patientContext.id,
        options?.start,
        options?.end,
        options?.regenerate,
      )
      break
    case 'POST':
      params = createPostReportLLMRequest(
        patientContext.id,
        options?.start,
        options?.end,
        options?.regenerate,
      )
      break
    case 'PATCH':
      params = createPatchReportLLMRequest(
        patientContext.id,
        options?.reportId,
        options?.editedSummaries,
        options?.reviewerComments,
        options?.reviewerClassification,
      )
      break
    default:
      break
  }

  const getHeaders = useGetHeaders()
  const {
    data = [],
    errors,
    loading,
  } = useTangerineApi({
    url: `https://${config.strivepd.host}/api/v3/llm_report_data`,
    endpointSuffix,
    method,
    params,
    getHeaders,
    skip: !options,
  })

  return {
    data,
    isLoading: loading,
    hasError: !!(errors && errors.length),
  }
}

export default useReportLLM
