import { useContext } from 'react'
import { SummaryModeContext } from 'ui/contexts/SummaryModeContext'
import { ComparisonMode } from './ComparisonMode'
import { SummaryMode } from './SummaryMode'

const Summary = () => {
  const { comparisonModeEnabled } = useContext(SummaryModeContext)

  return <>{comparisonModeEnabled ? <ComparisonMode /> : <SummaryMode />}</>
}

Summary.propTypes = {}
Summary.defaultProps = {}

export default Summary
