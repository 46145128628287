import { node, number, objectOf, oneOfType, string } from 'prop-types'
import { StyledKeyValuePair } from 'ui/components/StyledKeyValuePair'
import { AccordionSummaryBar } from '../AccordionSummaryBar'
import { NoDataText } from '../NoDataText'

const AccordionSummaryBarNoData = ({
  title,
  subtitle,
  dataLabel,
  metadataObject,
  metadataComponent,
}) => {
  const metadataComponents = metadataComponent
    ? [metadataComponent]
    : (Object.keys(metadataObject) || []).map((metadataLabel) => (
        <StyledKeyValuePair
          label={metadataLabel}
          value={metadataObject[metadataLabel]}
        />
      ))

  return (
    <AccordionSummaryBar
      title={title}
      subtitle={subtitle}
      dataComponents={[<NoDataText text={dataLabel} />]}
      metadataComponents={metadataComponents}
    />
  )
}

AccordionSummaryBarNoData.propTypes = {
  title: string.isRequired,
  subtitle: string,
  dataLabel: oneOfType([string.isRequired, node.isRequired]),
  metadataObject: objectOf(oneOfType([string, number])),
  metadataComponent: node,
}

AccordionSummaryBarNoData.defaultProps = {
  subtitle: '',
  metadataObject: {},
}

export default AccordionSummaryBarNoData
