import { useCallback, useContext, useState } from 'react'
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined'
import clsx from 'clsx'
import CopyDeviceIdDialog from 'ui/components/CopyDeviceIdDialog'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { getStreamValues } from './streamOptions'
import StreamMenu from './StreamMenu'
import StreamsContext, {
  ADD_STREAM,
  REMOVE_STREAM,
  UPDATE_STREAM,
} from '../StreamsContext'
import {
  useAvailableDevices,
  useAvailableStreamOptions,
  useAvailableStreamValues,
} from './hooks'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'

const spacingValueTwo = 2
const spacingValueThree = 3
const useStyles = makeStyles((theme) => ({
  control: {
    marginRight: theme.spacing(spacingValueThree),
  },
  dragIcon: {
    marginRight: theme.spacing(spacingValueThree),
    cursor: 'grab',
    opacity: 0.5,
    '&:hover': { backgroundColor: 'transparent' },
  },
  newStreamIcon: {
    opacity: 0,
    cursor: 'default',
  },
  dropdown: {
    width: 240,
  },
  icon: {
    marginTop: theme.spacing(spacingValueTwo),
  },
}))

/**
 * @returns {JSX.Element} List item for selecting device streams
 */
const StreamItem = ({ deviceId, index, streamValue, innerRef, ...rest }) => {
  const classes = useStyles()
  const { dispatch } = useContext(StreamsContext)
  const includeStream = { deviceId, index, streamValue }
  const devices = useAvailableDevices(includeStream)
  const streamValues = useAvailableStreamValues(includeStream)
  const availableStreamOptions = useAvailableStreamOptions()
  const [openModal, setOpenModal] = useState(false)

  const onRemove = useCallback(
    () =>
      dispatch({
        type: REMOVE_STREAM,
        index,
      }),
    [dispatch, index],
  )

  /**
   * Changes the device and updates to the first available compatible stream
   */
  const handleDeviceChange = useCallback(
    (event) => {
      const deviceId = event.target.value
      // grab the first stream value for the device and set both values
      const [defaultStream] = getStreamValues(availableStreamOptions, deviceId)
      dispatch({
        type: UPDATE_STREAM,
        index,
        deviceId,
        streamValue: defaultStream.value,
      })
    },
    [dispatch, index, availableStreamOptions],
  )

  const handleStreamChange = useCallback(
    (event) => {
      const streamValue = event.target.value
      dispatch({
        type: UPDATE_STREAM,
        index,
        deviceId,
        streamValue,
      })
    },
    [deviceId, dispatch, index],
  )

  return (
    <Box display="flex" alignItems="center" ref={innerRef} p={1} {...rest}>
      <IconButton
        disableRipple={true}
        className={clsx(classes.icon, classes.dragIcon)}
        label="Reorder stream"
        onClick={onRemove}
      >
        <DragIndicatorIcon />
      </IconButton>
      <Box>
        <FormControl className={classes.control}>
          <InputLabel>Device</InputLabel>
          <Select
            classes={{ select: classes.dropdown }}
            onChange={handleDeviceChange}
            value={deviceId}
          >
            {devices.map((device) => (
              <MenuItem key={device.id} value={device.id}>
                {device.alias}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.control}>
          <InputLabel>Stream</InputLabel>
          <Select
            classes={{ select: classes.dropdown }}
            onChange={handleStreamChange}
            value={streamValue}
            data-testid={`stream-${streamValue}`}
          >
            {streamValues.map((stream) => (
              <MenuItem key={stream.value} value={stream.value}>
                {stream.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <IconButton
        className={classes.icon}
        label="Remove stream"
        onClick={onRemove}
      >
        <BackspaceOutlinedIcon />
      </IconButton>
      <StreamMenu id={deviceId} openCopyModal={() => setOpenModal(true)} />
      {openModal && (
        <CopyDeviceIdDialog
          device={devices.find((device) => device.id === deviceId)}
          open={openModal}
          handleClose={() => setOpenModal(false)}
        />
      )}
    </Box>
  )
}

/**
 * @return {JSX.Element} Stream item used when there are no streams selected
 * @constructor
 */
export const NewStreamItem = () => {
  const classes = useStyles()
  const { dispatch } = useContext(StreamsContext)
  const devices = useAvailableDevices()
  const availableStreamOptions = useAvailableStreamOptions()

  const handleDeviceChange = useCallback(
    (event) => {
      const deviceId = event.target.value
      // grab the first stream value for the device and set both values
      const [defaultStream] = getStreamValues(availableStreamOptions, deviceId)
      dispatch({
        type: ADD_STREAM,
        deviceId,
        streamValue: defaultStream.value,
      })
    },
    [availableStreamOptions, dispatch],
  )
  return (
    <Box p={1}>
      {/*the purpose of this 0 opacity element is to make the width match the regular <SelectedStreamItem />s */}
      <IconButton
        className={clsx(classes.icon, classes.dragIcon, classes.newStreamIcon)}
      >
        <DragIndicatorIcon />
      </IconButton>
      <FormControl className={classes.control}>
        <InputLabel>Add stream</InputLabel>
        <Select
          classes={{ select: classes.dropdown }}
          onChange={handleDeviceChange}
          value={''}
        >
          {devices.map((device) => (
            <MenuItem key={device.id} value={device.id}>
              {device.alias}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default StreamItem
