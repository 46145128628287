export const monthlyAvailabilityColorArray = [
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
  '#2A27D3',
  '#7D67D7',
  '#E5E93E',
  '#EBCD32',
  '#EF9D22',
  '#D26223',
  '#BD4141',
  '#73D376',
  '#0EC5A4',
  '#0A868E',
  '#5495BE',
  '#1478D8',
  '#F8698B',
  '#EF4BC1',
  '#533BEB',
]
