import { memo, useEffect, useRef, useState } from 'react'
import { colors } from 'theme/colors'
import { Text } from 'ui/baseComponents/Text'

const EditableContent = ({
  children,
  dataCy = 'editable-content',
  disableToggleHidePrintClass,
  stateKey,
  stateSrc,
  stateSaveCallback,
  textColor,
  textVariant = 'body16',
}) => {
  const initText = stateKey in stateSrc ? stateSrc[stateKey] : children
  const [text, setText] = useState('')

  const STYLES = {
    span: {
      display: 'block',
      padding: '0.5rem 1rem',
      backgroundColor: colors.YELLOW[100],
      '@media print': {
        backgroundColor: 'transparent',
        padding: 0,
      },
    },
  }

  const containerRef = useRef(null)

  const toggleHidePrintClass = (hide) => {
    if (disableToggleHidePrintClass) return
    if (hide) {
      containerRef.current.parentNode.classList.add('hidePrint')
    } else {
      containerRef.current.parentNode.classList.remove('hidePrint')
    }
  }

  const onChange = (e) => {
    const { innerText } = e.target || {}
    toggleHidePrintClass(!innerText)
    stateSaveCallback?.({ [stateKey]: innerText })
    setText(e.target.innerText)
  }

  useEffect(() => {
    toggleHidePrintClass(!initText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      ref={containerRef}
      data-cy={dataCy}
      className={disableToggleHidePrintClass && !text ? 'hidePrint' : ''}
    >
      <Text
        contentEditable="plaintext-only"
        sx={STYLES.span}
        suppressContentEditableWarning
        onBlur={onChange}
        variant={textVariant}
        color={textColor}
      >
        {initText}
      </Text>
    </div>
  )
}

export default memo(EditableContent)
