import { DESCENDING } from 'utilities/sort'

const options = {
  sortOrder: {
    name: 'date',
    direction: DESCENDING,
  },
}

export default options
