import createClient from 'domains/streamApi/client'
import { createMemoryHistory } from 'history'
import initStore from 'domains/store/initStore'
import { authenticated } from 'domains/auth/actions'
import { auth } from 'domains/auth/reducer'
import { getUserAttrs } from 'domains/auth/api'
import { mockGraphClient } from 'domains/carrotGraph/mocks/graphClient.mock'

const streamAPiConfig = {
  host: 'localhost',
  port: 8080,
  secure: false,
}
export const jwt = 'abc123xyz'
export const cognitoUserAttrs = [
  { Name: 'username', Value: 'bobby' },
  { Name: 'email', Value: 'bobby.mcgee@acme.net' },
  { Name: 'custom:default_login_portal', Value: 'DEFAULT' },
]
export const user = getUserAttrs(cognitoUserAttrs)
export const HI_OFFEST = -36000

export const defaultConfig = {
  carrotStream: streamAPiConfig,
  timezoneOffset: 0,
  vendors: {
    aws: {
      cognito: {
        clientId: 'abc123xyz',
        userPoolId: 'us-west-2_MABCP5BfN',
      },
    },
    launchdarkly: {
      clientSideId: 'abc123xyz',
    },
    rollbar: {
      accessToken: 'abc123',
      enabled: false,
      environment: 'development',
    },
  },
}

export const INVALID_SESSION = 'Invalid session'

/**
 * Mock Rollbar as a test dependency.
 * @returns {object} Mocked Rollbar configuration
 */
function mockRollbar() {
  const mockFunc = () => (typeof jest !== 'undefined' ? jest.fn() : () => {})
  return {
    options: {},
    log: mockFunc(),
    debug: mockFunc(),
    info: mockFunc(),
    warn: mockFunc(),
    warning: mockFunc(),
    error: mockFunc(),
    critical: mockFunc(),
    global: mockFunc(),
    configure: mockFunc(),
    handleUncaughtException: mockFunc(),
    handleUnhandledRejection: mockFunc(),
    wrap: mockFunc(),
    loadFull: mockFunc(),
    shimId: mockFunc(),
    captureEvent: mockFunc(),
    captureDomContentLoaded: mockFunc(),
    captureLoad: mockFunc(),
  }
}

/**
 * Bootstrap mocked version of our main app dependencies
 *
 * @param {object} config Mocked configuration
 * @param {object} historyObject Mocked history
 * @param {boolean} hasSession do we have a valid Cognito user session?
 * @param {object} graphClient Mocked GraphQL client
 * @return {object} Mocked dependencies for tests
 */
export const mockAppDependencies = ({
  config = defaultConfig,
  historyObject = createMemoryHistory(),
  hasSession = true,
  graphClient = mockGraphClient(),
} = {}) => {
  const accessToken = { getJwtToken: () => jwt }
  const session = {
    isValid: () => true,
    getAccessToken: () => accessToken,
  }
  const invalidSession = (callback) => callback(INVALID_SESSION, null)
  const validSession = (callback) => callback(null, session)
  const getSession = hasSession ? validSession : invalidSession
  const cognitoUser = {
    getSession,
    getUserAttributes: (callback) => callback(null, cognitoUserAttrs),
    getUsername: () => 'bobby',
  }
  const userPool = {
    getCurrentUser: () => cognitoUser,
  }
  const streamApiClient = createClient(streamAPiConfig)
  const Rollbar = mockRollbar()
  const dependencies = { userPool, Rollbar }
  const initialAuthState = hasSession
    ? auth(auth(), authenticated({ user, jwt }))
    : auth()
  const initialState = {
    config,
    // Pre-initialize an authenticated user
    auth: initialAuthState,
  }
  const { store, history } = initStore({
    initialState,
    dependencies,
    historyObject,
  })

  return {
    store,
    graphClient,
    streamApiClient,
    userPool,
    Rollbar,
    history,
    user,
    jwt,
  }
}
