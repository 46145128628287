import { useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import TableCell from '@material-ui/core/TableCell'
import { formatDateTime } from 'utilities/time'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { FormHelperText, makeStyles } from '@material-ui/core'
import TableRow from 'ui/components/TableRow'
import clsx from 'clsx'
import { UPDATE_CLIENT_KEY } from '../mutations'
import { useRefetchFullPatient } from '../../queries'
import { PatientContext } from 'ui/contexts'

const useStyles = makeStyles(() => ({
  disabledCell: {},
}))

export const ClientKey = ({
  id,
  clientKeyId,
  clientDisabled,
  createdAt,
  disabledAt,
  disabled,
}) => {
  const { id: patientId } = useContext(PatientContext)
  const classes = useStyles()
  const [error, setError] = useState()
  const [updateClientKey, { loading }] = useMutation(UPDATE_CLIENT_KEY, {
    onError: (error) => {
      console.error(error)
      setError('Unable to delete Client Key')
    },
    refetchQueries: [useRefetchFullPatient(patientId)],
  })
  const handleDelete = (id) => async (e) => {
    e.preventDefault()
    const input = { clientKeyId: id, disabled: true }
    await updateClientKey({ variables: { input } })
  }

  return (
    <TableRow data-cy="ClientKey" className={clsx({ disabled })}>
      <TableCell>{clientKeyId}</TableCell>
      <TableCell align="center">{formatDateTime(createdAt)}</TableCell>
      <TableCell align="center" className={classes.disabledCell}>
        {disabled ? (
          formatDateTime(disabledAt)
        ) : !clientDisabled ? (
          <FormControl>
            <IconButton
              disabled={loading}
              size="small"
              onClick={handleDelete(id)}
              title="Disable Client Key"
              data-cy="disable-access-key"
            >
              <DeleteIcon />
            </IconButton>
            {error && <FormHelperText error>{error}</FormHelperText>}
          </FormControl>
        ) : null}
      </TableCell>
    </TableRow>
  )
}
export default ClientKey
