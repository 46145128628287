import { Link, Text } from 'ui/baseComponents'
import { VIDEO_LINKS } from 'ui/clinicianScreens/Patient/Report/consts'

export const NO_DATA = 'No data'
export const DATE_OF_BIRTH = 'Date of birth: '
export const DATE_OF_DIAGNOSIS = 'Date of diagnosis: '
export const DATE_OF_REPORT = 'Report Date: '
export const PATIENT_NAME_PREFIX = {
  clinicianReport: 'Insights for ',
  patientReport: 'Hello, ',
}
export const REPORT_PERIODS_PREFIX =
  'Here are your StrivePD Insights compared for these two periods:'

export const REPORT_PERIODS = {
  recent: 'Recent period: ',
  previous: 'Previous period: ',
}

export const PATIENT_REPORT_EMPTY_STATE = (
  <Text variant="body16" component="p" data-cy="highlights-section-no-data">
    Is some of your data missing? For optimal results,{' '}
    <Link href={VIDEO_LINKS.bestPractices} external underline light>
      watch our video
    </Link>{' '}
    on best practices or use our{' '}
    <Link
      href="https://s3.us-west-2.amazonaws.com/docs.runelabs.io/static/2024/02/TroubleShooting+and+Best+Practices.pdf"
      external
      underline
      light
    >
      guide to troubleshoot
    </Link>
    . If you have further questions reach out to our Patient Support team via
    email{' '}
    <Link href="mailto:support@runelabs.io" external underline light>
      support@runelabs.io
    </Link>
    .
  </Text>
)
