import LegendPopover from 'ui/clinicianScreens/Patient/Daily/ChartLegend/LegendPopover'
import { ButtonWithPopover } from 'ui/components/ButtonWithPopover'
import DataAvailabilityDateRangePickerWrapper from 'ui/clinicianScreens/Patient/DataAvailabilityDateRangePicker/DataAvailabilityDateRangePickerWrapper'
import TabActionableHeader from 'ui/templates/TabActionableHeader'
import { Stack } from 'ui/baseComponents/Stack'

const DailyViewsHeader = () => (
  <TabActionableHeader>
    <Stack direction="row" spacing={1}>
      <DataAvailabilityDateRangePickerWrapper />
      <ButtonWithPopover label="Chart Legend">
        <LegendPopover />
      </ButtonWithPopover>
    </Stack>
  </TabActionableHeader>
)

DailyViewsHeader.propTypes = {}

DailyViewsHeader.defaultProps = {}

export default DailyViewsHeader
