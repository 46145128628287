import {
  SYMPTOM_INTENSITIES,
  SYMPTOM_INTENSITY_CHIP_COLORS,
  SYMPTOM_INTENSITY_DOT_COLORS,
  SYMPTOM_INTENSITY_LABELS,
  SYMPTOM_SUMMARY_EMPTY_STATE,
} from './consts'
import { Chip, Stack, Text } from 'ui/baseComponents'
import ReportTitle from 'ui/clinicianScreens/Patient/Report/components/ReportTitle'
import intensityIcon from 'assets/img/intensity.svg'

const ReportSymptomIntensitySummary = ({ recentSummary, prevSummary }) => {
  const {
    most_common_overall_intensity: recentMostReportedIntensity,
    overall_intensity_counts: recentIntensityCounts,
  } = recentSummary || {}
  const {
    most_common_overall_intensity: prevMostReportedIntensity,
    overall_intensity_counts: prevIntensityCounts,
  } = prevSummary || {}

  const intensityChipColors = SYMPTOM_INTENSITY_CHIP_COLORS.get(
    recentMostReportedIntensity,
  )

  const STYLES = {
    intensityChip: {
      color: intensityChipColors?.primary,
      backgroundColor: intensityChipColors?.secondary,
      fontWeight: '600',
      fontSize: '1rem',
    },
    intensityCount: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.4rem',
    },
    intensityDot: {
      width: '0.8rem',
      height: '0.8rem',
      borderRadius: '100%',
    },
  }

  const IntensityCounts = ({ intensity, recent, prev }) => (
    <Text variant="body16" sx={STYLES.intensityCount}>
      <div
        style={{
          ...STYLES.intensityDot,
          backgroundColor: SYMPTOM_INTENSITY_DOT_COLORS.get(intensity),
        }}
      />
      {SYMPTOM_INTENSITY_LABELS?.[intensity]}:{' '}
      <Text variant="body16B">{recent?.[intensity]}d</Text> |{' '}
      {prev?.[intensity]}d
    </Text>
  )

  const hasSummaryData = !!recentMostReportedIntensity

  return (
    <>
      <ReportTitle data-cy="symptom-intensity-summary-title">
        <Stack direction="row" alignItems="center" spacing={2}>
          <img
            alt="StrivePD powered by Rune Labs"
            src={intensityIcon}
            width={35}
          />
          <Text variant="body16B">Most reported symptom intensity</Text>
          {hasSummaryData ? (
            <>
              <Chip
                sx={STYLES.intensityChip}
                size="medium"
                label={
                  SYMPTOM_INTENSITY_LABELS[recentMostReportedIntensity] || ''
                }
                data-cy="symptom-intensity-summary-recently-reported"
              />
              <Text
                variant="body16"
                data-cy="symptom-intensity-summary-previously-reported"
              >
                Previous period:{' '}
                {SYMPTOM_INTENSITY_LABELS[prevMostReportedIntensity] || 'N/A'}
              </Text>
            </>
          ) : (
            SYMPTOM_SUMMARY_EMPTY_STATE
          )}
        </Stack>
      </ReportTitle>
      {hasSummaryData && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          data-cy="symptom-intensity-summary-logs"
        >
          <Text variant="body14">
            LOGS: <Text variant="body14B">RECENT</Text> | PREVIOUS
          </Text>
          <IntensityCounts
            intensity={SYMPTOM_INTENSITIES.severe}
            recent={recentIntensityCounts}
            prev={prevIntensityCounts}
          />
          <IntensityCounts
            intensity={SYMPTOM_INTENSITIES.moderate}
            recent={recentIntensityCounts}
            prev={prevIntensityCounts}
          />
          <IntensityCounts
            intensity={SYMPTOM_INTENSITIES.mild}
            recent={recentIntensityCounts}
            prev={prevIntensityCounts}
          />
          <IntensityCounts
            intensity={SYMPTOM_INTENSITIES.not_present}
            recent={recentIntensityCounts}
            prev={prevIntensityCounts}
          />
        </Stack>
      )}
    </>
  )
}

export default ReportSymptomIntensitySummary
