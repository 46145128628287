import { useContext } from 'react'
import moment from 'moment-timezone'
import { useStreamApiBatch } from 'domains/streamApi'
import {
  DAY_IN_SECONDS,
  MINUTE_IN_SECONDS,
  SECOND_IN_NANOSECONDS,
  convertTimeToUnixTimestamp,
} from 'utilities/time'
import { FILTER_THRESHOLD } from 'ui/clinicianScreens/Patient/Daily/consts'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

export const METRICS = [
  { id: 'tremor_duration', name: 'tremor' },
  { id: 'dyskinesia_duration', name: 'dyskinesia' },
]

/*
 *  This hook is used to get the tremor and dyskinesia data for a given date.
 *
 * @typedef {Object} TimeseriesDataPoint
 * @property {string} startTime - The start time of the data point.
 * @property {string} endTime - The end time of the data point.
 * @property {Array.<{unit: string, value: number}>} value - The value of the data point.
 * @property {Number} duration - The duration of the data point.
 *
 * @typedef {Object} Timeseries
 * @property {Array.<{unit: string, value: number}>} mean - The mean of all data points.
 * @property {Array.<{unit: string, value: number}>} min - The min value of all data points.
 * @property {Array.<{unit: string, value: number}>} max - The max value of all data points.
 * @property {Array.<TimeseriesDataPoint>} data - The data points.
 *
 *
 * @typedef {Object} TremorAndDyskinesiaRawData
 * @property {boolean} isLoading - Whether the data is loading.
 * @property {boolean} hasError - Whether there was an error getting the data.
 * @property {boolean} hasData - Whether there is data for the given date.
 * @property {Object.<string, Timeseries>} data - The data for the given date.
 * @property {Timeseries} data.tremor - The tremor data.
 * @property {Timeseries} data.dyskinesia - The dyskinesia data.
 *
 *  @param {string} forDate - The date for which to get the tremor and dyskinesia data.
 *  @return {TremorAndDyskinesiaRawData}
 */
const useTremorAndDyskinesiaData = (patientId, devices, forDate) => {
  const { selectedTimezone } = useContext(DateRangeContext)

  const start_time = convertTimeToUnixTimestamp({
    time: forDate,
    timezoneName: selectedTimezone,
  })

  const end_time = start_time + DAY_IN_SECONDS

  const params = {
    start_time,
    end_time,
    patient_id: patientId,
    resolution: MINUTE_IN_SECONDS,
    timestamp: 'iso',
    aggregate_function: 'sum',
  }

  const requests = METRICS.map((metric) => ({
    endpoint: `/metrics/${metric.id}/aggregate_window`,
    params: params,
    versionPrefix: 'v2',
  }))

  const { data, loading, errors } = useStreamApiBatch(
    requests,
    selectedTimezone,
  )
  const formatted = windowAggregateAPIToRawData(METRICS, data)

  return {
    isLoading: loading,
    hasError:
      (errors && !!errors.length) ||
      Object.values(formatted).some((metric) => metric.hasError),
    hasData: Object.values(formatted).some((metric) => !!metric.data.length),
    data: formatted,
  }
}

const windowAggregateAPIToRawData = (metrics, responses) => {
  const timeseries = {}
  if (!responses || !responses.length) {
    return timeseries
  }

  metrics.forEach((metric) => {
    const response = responses.find(
      (possibleResponse) => possibleResponse?.description?.id === metric.id,
    )
    if (!response) {
      timeseries[metric.name] = {
        mean: null,
        min: null,
        max: null,
        data: [],
        hasError: true,
      }
      return
    } else if (!response?.metric?.data) {
      timeseries[metric.name] = {
        mean: null,
        min: null,
        max: null,
        data: [],
        hasError: false,
      }
      return
    }

    const summary = response.metric.summary || {}
    timeseries[metric.name] = {
      mean: [{ unit: 's', value: summary.value_mean }],
      max: [{ unit: 's', value: summary.value_max }],
      min: [{ unit: 's', value: summary.value_min }],
      hasError: false,
    }

    timeseries[metric.name].data = dataToTimeseries(response.metric.data)
  })
  return timeseries
}

const dataToTimeseries = ({ time, aggregate_values, duration_sum }) =>
  time.map((startISO, index) => {
    const duration = duration_sum[index] / SECOND_IN_NANOSECONDS

    let value = aggregate_values[index]
    if (value / duration < FILTER_THRESHOLD) {
      value = 0
    }

    return {
      startTime: startISO,
      endTime: moment.parseZone(startISO).add(duration, 'seconds').format(),
      duration: [{ unit: 's', value: duration }],
      value: [{ unit: 's', value }],
    }
  })

export default useTremorAndDyskinesiaData
