import {
  GENERIC_ERROR_TEXT,
  MIN,
  MAX,
  NUMBER_DAYS_WITH_DATA,
  NO_DATA_TEXT,
} from '../consts'

export const STATE_LABEL = {
  asleep: 'asleep',
  asleepCore: 'asleepCore',
  asleepDeep: 'asleepDeep',
  asleepREM: 'asleepREM',
  inBed: 'inBed',
}

export const labels = {
  TITLE: 'Sleep',
  FETCHING_TEXT: 'Loading Sleep',
  ERROR_TEXT: GENERIC_ERROR_TEXT,
  NO_DATA_TEXT: NO_DATA_TEXT,
  AVG_SLEEP_PER_DAY: 'Avg. total sleep per day',
  MIN,
  MAX,
  NUMBER_DAYS_WITH_DATA,
  DAILY_AVERAGE_SLEEP: 'Daily Average',
  TIMELINE_LABEL_SLEEP: 'Sleep timeline',
  LINK_TEXT: 'Open Daily View',
  TOOLTIP_TOTAL_TEXT:
    'Average total time asleep per day within the selected time period',
  TOOLTIP_LONGEST_TEXT:
    'Average longest span of uninterrupted sleep per day within the selected time period',
  TOOLTIP_DAY_DEFINITION:
    'A day is defined as noon to noon - from noon of the day prior to the first day of the selected period to noon of the last day of the selected period',
  TOOLTIP_LIST_FOR_CHART_TITLE: [
    'Shows how often the patient is asleep at different timepoints within the selected time period',
    'Data is recorded when the patient is wearing their Apple Watch to sleep',
  ],
}
