export const STREAM_TYPE_ACCELERATION = 'accel'
export const STREAM_ACCELERATION = {
  value: 'accel',
  endpoint: 'accel',
  csvSupported: true,
  label: 'Acceleration (with gravity)',
  streamType: STREAM_TYPE_ACCELERATION,
  queryParams: {
    expression: 'accel',
  },
}
// duplicate streams are needed so that we can add additional parameters in a later step.
export const STREAM_ACCELERATION_RCS = {
  value: 'accel',
  endpoint: 'accel',
  csvSupported: true,
  label: 'Acceleration (with gravity)',
  streamType: STREAM_TYPE_ACCELERATION,
  queryParams: {
    expression: 'accel',
  },
}
