import { Grid, makeStyles, Typography } from '@material-ui/core'
import { InformationalIconTooltip } from 'ui/components/InformationalIconTooltip'
import { element, oneOfType, string } from 'prop-types'
import React from 'react'
import { RUNE_GRAY_800 } from 'theme/colors'

const useStyles = makeStyles(() => ({
  text: {
    fontSize: '1em',
    color: RUNE_GRAY_800,
    fontWeight: 700,
    verticalAlign: 'middle',
  },
}))

const TextWithInformationalIconTooltip = ({ text, ...props }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography className={classes.text}>{text}</Typography>
      </Grid>
      {props.title && (
        <Grid item>
          <InformationalIconTooltip color="dark" {...props} />
        </Grid>
      )}
    </Grid>
  )
}

TextWithInformationalIconTooltip.propTypes = {
  text: string.isRequired,
  title: oneOfType([element, string]).isRequired,
}

TextWithInformationalIconTooltip.defaultProps = {}

export default TextWithInformationalIconTooltip
