import { useContext } from 'react'
import FormFieldWrapper from 'ui/components/FormFieldWrapper'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import TaskFrequency from '../TaskFrequency'
import {
  TASK_RECURRENCE_ONCE,
  TASK_RECURRENCE_RECURRING,
} from 'ui/screens/Patients/TaskNotifications/consts.js'
import { TaskNotificationContext } from 'ui/contexts'
import { TaskScheduleOnce } from './TaskScheduleOnce'
import { TaskScheduleRecurring } from './TaskScheduleRecurring'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  miniLabel: theme.taskNotificationsMiniLabel,
}))

/**
 * The Task Schedule section of the Create Task form.
 *
 * @returns {JSX.Element} This component controls the conditional schedule states
 */
export const TaskSchedule = () => {
  const classes = useStyles()
  const { taskRecurrence, onTaskRecurrenceChange } = useContext(
    TaskNotificationContext,
  )

  return (
    <ErrorBoundary>
      <Box display="flex" sx={{ justifyContent: 'center' }}>
        <FormFieldWrapper label="Task Schedule" fullWidth={false}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.miniLabel}>
              Task Recurrence
            </FormLabel>
            <RadioGroup
              row
              aria-label="recurrence"
              name="recurrence"
              value={taskRecurrence}
              onChange={onTaskRecurrenceChange}
            >
              <FormControlLabel
                value={TASK_RECURRENCE_ONCE}
                control={<Radio />}
                label="Once"
              />
              <FormControlLabel
                value={TASK_RECURRENCE_RECURRING}
                control={<Radio />}
                label="Recurring"
              />
            </RadioGroup>
          </FormControl>

          {taskRecurrence === TASK_RECURRENCE_ONCE ? (
            <TaskScheduleOnce />
          ) : (
            <>
              <TaskScheduleRecurring />
              <TaskFrequency />
            </>
          )}

          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.miniLabel}>
              Time Zone
            </FormLabel>
            <Typography name="Time Zone">Mobile local time zone</Typography>
          </FormControl>
        </FormFieldWrapper>
      </Box>
    </ErrorBoundary>
  )
}

export default TaskSchedule
