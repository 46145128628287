import { makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import React from 'react'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import SendIcon from '@material-ui/icons/Send'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: 'none',
    minWidth: 100,
    height: 36,
    marginLeft: 10,
    marginRight: 10,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    color: green[500],
    '&:hover': {
      color: green[700],
    },
  },
}))

const SubmitButton = ({
  success,
  loading,
  successLabel = 'Saved',
  submitLabel = 'Save',
  icon = <SendIcon />,
  successIcon = <CheckIcon data-cy="Success" />,
  classes: classesProp = {},
  ...props
}) => {
  const classes = {
    ...useStyles(),
    ...classesProp,
  }
  return (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      data-cy="submit"
      endIcon={success ? successIcon : icon}
      disabled={loading}
      className={clsx(classes.button, {
        [classes.buttonSuccess]: success,
      })}
      {...props}
    >
      {success ? successLabel : submitLabel}
      {loading && (
        <CircularProgress
          data-cy="Saving"
          size={24}
          className={classes.buttonProgress}
        />
      )}
    </Button>
  )
}

export default SubmitButton
