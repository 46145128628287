import ReportSection from '../../components/ReportSection'
import {
  CLINICIAN_RED_FLAGS,
  DEFAULT_RED_FLAGS,
  DYSKINESIA_SYMPTOMS,
  HELPER_SYMPTOM_LOG,
  HELPER_SYMPTOM_LOG_EMPTY_STATE,
  RED_FLAG_CLASSIFICATIONS,
  SECTION_SYMPTOM_LOG_EMPTY_STATE,
  SYMPTOM_LOG_SUBTITLE,
} from './consts'
import { NEW_LABEL_COLOR, SECTION_TITLES } from '../../consts'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { useContext, useEffect } from 'react'
import { PatientContext } from 'ui/contexts'
import usePatientEventCount from 'ui/hooks/usePatientEventCount'
import EditableLLMContent from '../../components/EditableLLMContent'
import { Link, Stack, Table, Text } from 'ui/baseComponents'
import useDailyCheckInsSymptoms from 'ui/hooks/useDailyCheckInsSymptoms'
import ReportSymptomIntensitySummary from 'ui/clinicianScreens/Patient/Report/sections/SymptomLog/ReportSymptomIntensitySummary'
import useBoundStore from 'domains/zustand/store'
import {
  HELPER_DYSKINESIA_SYMPTOMS_CLINICIAN_EMPTY_STATE,
  HELPER_DYSKINESIA_SYMPTOMS_PATIENT_EMPTY_STATE,
} from 'ui/clinicianScreens/Patient/Report/sections/DIN/consts'
import DyskinesiaInsightsBanner from 'ui/clinicianScreens/Patient/Report/sections/DIN/DyskinesiaInsightsBanner'
import { RedFlagIcon } from 'ui/components/RedFlagIcon'

const ReportSectionSymptomLog = ({
  showOnlyDyskinesiaSymptoms,
  isDINVariant,
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  llmKey,
  llmSummary,
  onUpdate = () => {},
  addToUnsavedChanges = () => {},
  removeFromUnsavedChanges = () => {},
  startTime,
  endTime,
  prevStartTime,
  prevEndTime,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
  ...props
}) => {
  const STYLES = {
    newLabel: {
      color: NEW_LABEL_COLOR,
      marginLeft: '1rem',
    },
    symptomNameText: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
  }
  const title = showOnlyDyskinesiaSymptoms
    ? SECTION_TITLES.DYSKINESIA_SYMPTOMS
    : SECTION_TITLES.SYMPTOM_LOG

  const { id: patientId } = useContext(PatientContext)

  const appendRedFlags = useBoundStore((state) => state.appendRedFlags)
  const resetRedFlags = useBoundStore((state) => state.resetRedFlags)

  const { eventCountLoading, eventCountData } =
    usePatientEventCount(patientId, startTime, endTime, 50, [
      { namespace: 'patient', category: 'symptom', enum: '*' },
      { namespace: 'patient', category: 'side-effect', enum: '*' },
    ]) || {}

  const {
    eventCountLoading: prevEventCountLoading,
    eventCountData: prevEventCountData,
  } =
    usePatientEventCount(patientId, prevStartTime, prevEndTime, 50, [
      { namespace: 'patient', category: 'symptom', enum: '*' },
      { namespace: 'patient', category: 'side-effect', enum: '*' },
    ]) || {}

  const { data: checkinData = {} } =
    useDailyCheckInsSymptoms({
      options: {
        start: startTime,
        end: endTime,
      },
    }) || {}

  const { data: prevCheckinData = {} } =
    useDailyCheckInsSymptoms({
      options: {
        start: prevStartTime,
        end: prevEndTime,
      },
    }) || {}

  const { summary: recentSummary = {} } = checkinData
  const { summary: prevSummary = {} } = prevCheckinData

  const combineDuplicateEvents = (events) =>
    events.reduce((acc, event) => {
      // filter out non red flag symptoms for clinician report
      if (
        isClinicianReport &&
        !showOnlyDyskinesiaSymptoms &&
        !DEFAULT_RED_FLAGS.includes(event.classification.enum)
      ) {
        return acc
      }

      const name = getEventName(event)
      if (!acc.some((item) => item.name === name)) {
        const count = events.reduce((sum, item) => {
          if (getEventName(item) === name) {
            return sum + item.count
          }
          return sum
        }, 0)
        const showRedFlagIcon = isClinicianReport
          ? CLINICIAN_RED_FLAGS.includes(event.classification.enum)
          : DEFAULT_RED_FLAGS.includes(event.classification.enum)

        const link = RED_FLAG_CLASSIFICATIONS.get(
          event.classification.enum,
        )?.url
        acc.push({
          name,
          count,
          showRedFlagIcon,
          link,
          classification: event.classification.enum,
        })
      }
      return acc
    }, [])
  const recentData = eventCountData?.patient?.eventCount?.counts || []
  const prevData = prevEventCountData?.patient?.eventCount?.counts || []
  const symptoms = combineDuplicateEvents(recentData)
  const prevSymptoms = combineDuplicateEvents(prevData)
  const symptomsToShow = showOnlyDyskinesiaSymptoms
    ? symptoms.filter((symptom) =>
        DYSKINESIA_SYMPTOMS.includes(symptom.classification),
      )
    : isDINVariant
    ? symptoms.filter(
        (symptom) => !DYSKINESIA_SYMPTOMS.includes(symptom.classification),
      )
    : symptoms
  const sortedSymptoms = [...symptomsToShow].sort((a, b) => b?.count - a?.count)

  if (isClinicianReport) {
    if (!symptoms.some((symptom) => symptom.showRedFlagIcon)) {
      resetRedFlags()
    } else {
      symptoms.forEach((symptom) => {
        if (symptom.showRedFlagIcon) appendRedFlags(symptom.name)
      })
    }
  }

  useEffect(() => {
    if (eventCountLoading || prevEventCountLoading) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCountLoading, prevEventCountLoading])

  const symptomTableHeadings = [
    {
      key: 'name',
      value: 'Symptom',
    },
    {
      key: 'logs',
      value: 'Logs | Prev period',
    },
  ]

  const getSymptomTableRows = (event) => {
    const { name, count, link, showRedFlagIcon } = event || {}
    const prevEvent = prevSymptoms?.find((prevEvent) => prevEvent.name === name)
    const { count: prevCount } = prevEvent || {}
    const isNew = count && !prevCount
    const showLink = !isClinicianReport && link
    return [
      {
        key: `symptom-cell-${name}`,
        value: (
          <Text variant="body16B" sx={STYLES.symptomNameText}>
            {showLink ? (
              <Link href={link} external light underline>
                {name}
              </Link>
            ) : (
              name
            )}
            {showRedFlagIcon && <RedFlagIcon />}
          </Text>
        ),
        sx: { width: '60%' },
      },
      {
        key: `logs-cell-${name}`,
        value: (
          <>
            <Text variant="body16B">{event?.count || 0}</Text>
            <Text variant="body16"> | {prevEvent?.count || 0}</Text>
            {isNew && (
              <Text variant="body16B" sx={STYLES.newLabel}>
                New
              </Text>
            )}
          </>
        ),
      },
    ]
  }

  const TABLE_ROW_THRESHOLD = 5
  let symptomTableColumns = []
  if (sortedSymptoms.length > TABLE_ROW_THRESHOLD) {
    const half = Math.ceil(sortedSymptoms.length / 2)
    const firstColumn = sortedSymptoms.slice(0, half)
    const secondColumn = sortedSymptoms.slice(half, sortedSymptoms.length)
    symptomTableColumns = [firstColumn, secondColumn]
  } else {
    symptomTableColumns = [sortedSymptoms]
  }

  const hasData = sortedSymptoms?.length > 0

  useEffect(() => {
    if (eventCountData) {
      hasData ? addToHasDataSet(title) : removeFromHasDataSet(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCountData, hasData])

  const dinEmptyStateText = isClinicianReport
    ? HELPER_DYSKINESIA_SYMPTOMS_CLINICIAN_EMPTY_STATE
    : HELPER_DYSKINESIA_SYMPTOMS_PATIENT_EMPTY_STATE

  return (
    <>
      {showOnlyDyskinesiaSymptoms && (
        <DyskinesiaInsightsBanner {...{ isClinicianReport }} />
      )}
      <ReportSection
        title={title}
        subtitle={
          isClinicianReport
            ? SYMPTOM_LOG_SUBTITLE.clinician
            : SYMPTOM_LOG_SUBTITLE.patient
        }
        hidden={
          hiddenSections.has(title) ||
          (isClinicianReport &&
            !showOnlyDyskinesiaSymptoms &&
            !hasDataSet.has(title))
        }
        noBorder={showOnlyDyskinesiaSymptoms}
        {...props}
      >
        {!hasData &&
          !llmSummary &&
          !showOnlyDyskinesiaSymptoms &&
          SECTION_SYMPTOM_LOG_EMPTY_STATE}
        {llmSummary && (
          <EditableLLMContent
            {...{
              llmKey,
              llmSummary,
              onUpdate,
              addToUnsavedChanges,
              removeFromUnsavedChanges,
            }}
          >
            {llmSummary}
          </EditableLLMContent>
        )}
        {hasData ? (
          <>
            <Stack
              direction="row"
              alignItems="start"
              data-cy="symptom-log-table-container"
            >
              {symptomTableColumns.map((column, index) => (
                <Table
                  headings={symptomTableHeadings}
                  rows={column.map((event) => getSymptomTableRows(event))}
                  data-cy={`symptom-log-table-${index}`}
                  tableCellProps={{ size: 'small' }}
                  key={`symptom-log-table-${index}`}
                />
              ))}
            </Stack>

            {!isClinicianReport &&
              !showOnlyDyskinesiaSymptoms &&
              HELPER_SYMPTOM_LOG}
          </>
        ) : showOnlyDyskinesiaSymptoms ? (
          dinEmptyStateText
        ) : (
          HELPER_SYMPTOM_LOG_EMPTY_STATE
        )}
        {!showOnlyDyskinesiaSymptoms && (
          <ReportSymptomIntensitySummary {...{ recentSummary, prevSummary }} />
        )}
      </ReportSection>
    </>
  )
}

export default ReportSectionSymptomLog
