import useDateRangeContext, {
  DateRangeContext,
} from 'ui/contexts/DateRangeContext'

const { Report } = require('ui/clinicianScreens/Patient/Report')

const AutomatedReport = () => {
  const dateRangeContextValue = useDateRangeContext()

  return (
    <DateRangeContext.Provider value={dateRangeContextValue}>
      <Report />
    </DateRangeContext.Provider>
  )
}

export default AutomatedReport
