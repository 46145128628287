import { getLabels } from 'ui/components/DataTable/helpers'
import { IconText } from 'ui/components/Text'
import {
  convertUnixTimestampToSpecifiedTimezone,
  SECOND_IN_MILLISECONDS,
} from 'utilities/time'
import { DATE_FORMAT } from '../consts'

const columns = [
  {
    name: 'eventColor',
    options: {
      display: false,
      filter: false,
      searchable: false,
      viewColumns: false,
    },
  },
  {
    name: 'selectedTimezone',
    options: {
      display: false,
      filter: false,
      searchable: false,
      viewColumns: false,
    },
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta) =>
        convertUnixTimestampToSpecifiedTimezone({
          timestamp: value * SECOND_IN_MILLISECONDS,
          timezoneName: tableMeta.rowData[labels.selectedTimezone],
          format: DATE_FORMAT,
        }),
      setCellHeaderProps: () => ({
        style: {
          width: '20%',
        },
      }),
    },
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      filter: true,
      filterOptions: {
        fullWidth: true,
      },
      sort: true,
      customBodyRender: (value, tableMeta) => (
        <IconText
          text={value}
          iconColor={tableMeta.rowData[labels.eventColor]}
        />
      ),
      setCellHeaderProps: () => ({
        style: {
          width: '20%',
        },
      }),
    },
  },
  {
    name: 'note',
    label: 'Description',
    options: {
      filter: false,
      sort: true,
      setCellHeaderProps: () => ({
        style: {
          width: '60%',
        },
      }),
    },
  },
]

export const labels = getLabels(columns)

export default columns
