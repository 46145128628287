import { useContext } from 'react'
import TableRow from 'ui/components/TableRow'
import TableCell from '@material-ui/core/TableCell'
import NavLink from 'ui/components/NavLink'
import clsx from 'clsx'
import { PatientContext } from 'ui/contexts'

const Device = ({ device }) => {
  const { id: patientId } = useContext(PatientContext)
  const { id, alias, deviceType, disabled } = device

  return (
    <TableRow data-cy="Device" className={clsx({ disabled })}>
      <TableCell>
        <NavLink to={`/patients/${patientId}/manage/devices/${id}`}>
          {alias}
        </NavLink>
      </TableCell>
      <TableCell>{deviceType.displayName}</TableCell>
    </TableRow>
  )
}

export default Device
