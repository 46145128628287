/**
 * Spec for the symptom probability streams.
 */
export const STREAM_TYPE_PROB_SYMPTOM = 'probSymptom'
export const STREAM_TYPE_PROB_SYMPTOM_SEVERITY = 'probSymptomSeverity'

export const STREAM_PROB_DYSKINESIA = {
  value: 'probDyskinesia',
  endpoint: 'probability',
  csvSupported: true,
  label: 'Dyskinesia: Percentage',
  streamType: STREAM_TYPE_PROB_SYMPTOM,
  queryParams: {
    symptom: 'dyskinesia',
  },
}

export const STREAM_PROB_TREMOR = {
  value: 'probTremor',
  endpoint: 'probability',
  csvSupported: true,
  label: 'Tremor: Percentage',
  streamType: STREAM_TYPE_PROB_SYMPTOM,
  queryParams: {
    symptom: 'tremor',
  },
}

export const STREAM_PROB_TREMOR_SEVERITY = {
  value: 'probTremorSeverity',
  endpoint: 'probability',
  csvSupported: true,
  label: 'Tremor: Percentage by Severity',
  streamType: STREAM_TYPE_PROB_SYMPTOM_SEVERITY,
  queryParams: {
    severity: ['none', 'slight', 'mild', 'moderate', 'strong', 'unknown'],
    symptom: 'tremor',
  },
}
