import { useEffect } from 'react'
import { func, number, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { WEEK_IN_DAYS } from 'utilities/time'
import 'react-dates/lib/css/_datepicker.css'
import './DateRangePicker.scss'
import useDateRangePickerContext, {
  DateRangePickerContext,
} from 'ui/contexts/DateRangePickerContext'
import { MAX_WEEKS } from 'ui/consts'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}))

/**
 * Control for picking a start and end date (with no time-of-day resolution).
 * @returns {JSX.Element} Components for selecting a range of dates.
 */
const DateRangePickerWrapper = ({
  from: fromProp,
  to: toProp,
  minimumDate,
  maximumDate,
  setDateRange,
  maxDays,
  className,
  children,
}) => {
  const classes = useStyles()

  const dateRangePickerContextValue = useDateRangePickerContext(
    fromProp,
    toProp,
    minimumDate,
    maximumDate,
    maxDays,
    setDateRange,
  )
  const { to, setTo, from, setFrom, focusedInput } = dateRangePickerContextValue

  const updatedClassName = clsx(
    'patient-dataset-explorer-date-range',
    classes.root,
    className,
  )

  const INPUT = {
    openStartDate: 'startDate',
    openEndDate: 'endDate',
    exitDatePicker: null,
  }

  // Update component state if from / to props change
  useEffect(() => {
    setFrom(fromProp)
    setTo(toProp)
  }, [fromProp, toProp]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (focusedInput) {
      case INPUT.openStartDate:
        // Wipe out the startDate so that future dates (up to today) will be available for selection
        setFrom()
        break

      case INPUT.openEndDate:
        setTo()
        break

      case INPUT.exitDatePicker:
        if (from && to) {
          setDateRange({ from, to })
        }

        if (!from && fromProp) {
          setFrom(fromProp)
        }

        if (!to && toProp) {
          setTo(toProp)
        }

        break

      default:
        break
    }
  }, [focusedInput]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={updatedClassName}
      data-cy="date-range-picker"
      onClick={(e) => e.stopPropagation()}
    >
      <DateRangePickerContext.Provider value={dateRangePickerContextValue}>
        {children}
      </DateRangePickerContext.Provider>
    </div>
  )
}

DateRangePickerWrapper.propTypes = {
  from: string,
  to: string,
  minimumDate: string,
  maximumDate: string,
  setDateRange: func,
  maxDays: number,
  classNames: string,
}

DateRangePickerWrapper.defaultProps = {
  maxDays: MAX_WEEKS * WEEK_IN_DAYS,
}

export default DateRangePickerWrapper
