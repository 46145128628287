import { useState, useContext } from 'react'
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { UserContext } from 'ui/contexts'
import { useAnalyticsTracking } from 'ui/hooks'
import { ANALYTICS_TRACKING_STATUS } from 'domains/mixpanel/consts'

import { ARIA_LABEL } from './consts'
import ContentWrapper from '../ContentWrapper'
import { PrivacyNoticeLink } from 'ui/components/PrivacyNoticeLink'

const useStyles = makeStyles((theme) => ({
  explanationSection: {
    margin: '2rem 0 3rem',
  },
}))

const CookiePreferences = () => {
  const classes = useStyles()

  const { analyticsTracking } = useContext(UserContext)
  const trackingEnabled =
    analyticsTracking === ANALYTICS_TRACKING_STATUS.optedIn

  const [analyticsTrackingEnabled, setAnalyticsTrackingEnabled] =
    useState(trackingEnabled)

  const { updateAnalyticsTracking } = useAnalyticsTracking()

  const updatePreferences = (event) => {
    if (event.target.checked) {
      setAnalyticsTrackingEnabled(true)
      updateAnalyticsTracking(ANALYTICS_TRACKING_STATUS.optedIn)
    } else {
      setAnalyticsTrackingEnabled(false)
      updateAnalyticsTracking(ANALYTICS_TRACKING_STATUS.optedOut)
    }
  }

  return (
    <ContentWrapper>
      <Typography variant="h5" component="h1">
        Cookie Preferences
      </Typography>

      <Box className={classes.explanationSection}>
        <Typography variant="body1" gutterBottom>
          StrivePD uses cookies to enhance your experience and improve the
          product.
        </Typography>
        <Typography variant="body1">
          You can review or change your choice at any time. Learn more by
          reading our &nbsp;
          <PrivacyNoticeLink underline="always" />.
        </Typography>
      </Box>

      <Box>
        <Typography variant="body1">
          Accept or deny the use of non-essential cookies on our platform below:
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={analyticsTrackingEnabled}
              onChange={(event) => updatePreferences(event)}
              aria-label={
                analyticsTrackingEnabled
                  ? ARIA_LABEL.toggleOff
                  : ARIA_LABEL.toggleOn
              }
            />
          }
          label={analyticsTrackingEnabled ? 'Accepted' : 'Denied'}
        />
      </Box>
    </ContentWrapper>
  )
}

export default CookiePreferences
