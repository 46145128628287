import React from 'react'
import { node, oneOfType, string } from 'prop-types'
import { Text } from 'ui/baseComponents/Text'
import { colors } from 'theme/colors'

const NoDataText = ({ text }) => (
  <Text color={colors.GREY[800]} variant="body16" component="p">
    {text}
  </Text>
)

NoDataText.propTypes = {
  text: oneOfType([string, node]),
}

NoDataText.defaultProps = {}

export default NoDataText
