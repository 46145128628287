import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { InputAdornment, TextField } from '@mui/material'
import useBoundStore from 'domains/zustand/store'

/**
 * A search input component for searching patients.
 * @returns {JSX.Element} The rendered component.
 */
const PatientSearchInputDeprecated = () => {
  const searchInput = useBoundStore((state) => state.searchInput)
  const setSearchInput = useBoundStore((state) => state.setSearchInput)
  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <FilterAltIcon />
      </InputAdornment>
    ),
  }

  return (
    <TextField
      value={searchInput}
      placeholder="Filter by name"
      InputProps={inputProps}
      variant="outlined"
      onChange={(event) => setSearchInput(event.target.value)}
    />
  )
}
export default PatientSearchInputDeprecated
