import { Grid } from '@material-ui/core'
import { GoToButton } from 'ui/components/Button'
import { BulletedTooltipContent } from 'ui/components/InformationalIconTooltip'
import { useContext } from 'react'
import { clinicianRoutes } from 'ui/clinicianScreens/Patient/Tabs/consts'
import { Heatmap } from 'ui/components/Heatmap'
import { PatientContext } from 'ui/contexts'
import { TextWithInformationalIconTooltip } from 'ui/components/TextWithInformationalIconTooltip'
import { LegendDefault } from 'ui/components/Legend'
import { labels } from './consts'
import { string } from 'prop-types'
import { stringifyQueryParameters } from 'ui/clinicianScreens/Patient/helpers'
import { DATE_RANGE } from 'ui/contexts/DateRangeContext'

const SleepDetails = ({ start, end, times = [], probabilities = [] }) => {
  const { id } = useContext(PatientContext)

  const colorscale = [
    ['0', '#ffffff'],
    ['1', '#B38DD6'],
  ]

  const queryString = `?${stringifyQueryParameters(start, end)}`

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <TextWithInformationalIconTooltip
            text="Daily Average"
            title={
              <BulletedTooltipContent
                bulletedContent={labels.TOOLTIP_LIST_FOR_CHART_TITLE}
              />
            }
            wide
          />
        </Grid>
        <Grid item xs>
          <LegendDefault minText="Rarely" maxText="Often" colors={colorscale} />
        </Grid>
        <Grid item>
          <GoToButton
            label={labels.LINK_TEXT}
            to={`/patients/${id}/${clinicianRoutes.DAILY}${queryString}`}
          />
        </Grid>
      </Grid>
      <Heatmap
        xLabels={times}
        yLabels={['Sleep timeline']}
        data={[probabilities]}
        min={0}
        max={1}
        colors={colorscale}
        hoverTemplate="<b>Probability asleep</b><br>%{z:.2f}<br>%{x}<extra></extra>"
      />
    </>
  )
}

SleepDetails.propTypes = {
  start: string,
  end: string,
  dateRangeKey: string,
}

SleepDetails.defaultProps = {
  start: '',
  end: '',
  dateRangeKey: DATE_RANGE.default.key,
}

export default SleepDetails
