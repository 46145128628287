import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { getAutomatedUserJwt } from 'utilities/automatedUser'

export const initUserPool = ({ userPoolId, clientId }) => {
  return new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: clientId,
  })
}

/**
 * Authenticate a user, resolving with a CognitoUser instance
 *
 * @param user
 * @param authDetails
 * @return {Promise<any>} resolves with a CognitoUser instance
 */
const authenticateUser = (user, authDetails) =>
  new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      newPasswordRequired: function (userAttrs, requiredAttrs) {
        reject({
          newPasswordRequired: true,
          scope: this,
          user,
          userAttibutes: userAttrs,
          requiredAttributes: requiredAttrs,
        })
      },
      onSuccess: () => resolve(user),
      onFailure: (error) => reject(error),
    })
  })

/**
 * Return a promise which resolves to a Cognito user's JWT token, if any.
 */
export const getJwt = (cognitoUser) => {
  const automatedUserJwt = getAutomatedUserJwt()

  if (automatedUserJwt) {
    return Promise.resolve(automatedUserJwt)
  }

  if (!cognitoUser) {
    Promise.reject(new Error('Cognito user required'))
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((error, session) => {
      if (error) {
        if (error.code === 'NotAuthorizedException') {
          // Refresh token has expired, or user account has been disabled;
          // either way, kick them out
          window.location = '/signin'
        }

        console.error(error)

        return reject(error)
      }

      const accessToken = session.getAccessToken()
      resolve(accessToken.getJwtToken())
    })
  })
}

/**
 * Transform the Cognito User Attribute array into an object
 *
 * @param {Array} attrs
 * @return {Object}
 */
export const getUserAttrs = (attrs) =>
  attrs.reduce(
    (iterator, { Name, Value }) => ({ ...iterator, [Name]: Value }),
    {},
  )

export const getUser = (user) =>
  new Promise((resolve, reject) => {
    user.getUserAttributes((error, result) => {
      if (error) {
        return reject(error)
      }
      const attrs = getUserAttrs(result)
      resolve({
        ...attrs,
        username: user.getUsername(),
      })
    })
  })

/**
 *
 * @param username
 * @param password
 * @param userPool
 * @return {Promise} that resolves with user
 */
export const authenticate = ({ username, password, userPool }) => {
  const authDetails = new AmazonCognitoIdentity.AuthenticationDetails({
    Username: username,
    Password: password,
  })
  const user = new AmazonCognitoIdentity.CognitoUser({
    Username: username,
    Pool: userPool,
  })
  return authenticateUser(user, authDetails)
}

/**
 * Submit a forgot password request to Cognito
 * @param username
 * @param userPool
 * @return {Promise<unknown>}
 */
export const forgotPassword = ({ username, userPool }) => {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        // return a callback which the frontend can use to confirm the new password
        // This lets us hide the implementation details and specifics of
        // AWS cognito calls from the UI
        const confirmPassword = ({ verificationCode, newPassword }) => {
          return new Promise((resolve, reject) => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
              onSuccess: resolve,
              onFailure: reject,
            })
          })
        }

        return resolve({ data, confirmPassword })
      },
      onFailure: reject,
    })
  })
}

/**
 * Complete password reset process using the verification code
 * the user was emailed during the forgotPassword flow
 * @param username
 * @param verificationCode
 * @param newPassword
 * @param userPool
 * @return {Promise<unknown>}
 */
export const confirmPassword = ({
  username,
  verificationCode,
  newPassword,
  userPool,
}) => {
  const userData = {
    Username: username,
    Pool: userPool,
  }
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: resolve,
      onFailure: reject,
    })
  })
}
