import { useCallback, useContext, useState } from 'react'
import {
  Button,
  FormControl,
  Link,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { validate as validateEmail } from 'email-validator'
import SignInContext from 'ui/contexts/SignInContext'
import { NotificationBar, Success } from 'ui/components/Notifications'
import FormWrapper from './FormWrapper'
import { PasswordVisibilityToggler } from 'ui/screens/SignIn/PasswordVisibilityToggler'
import {
  INCORRECT_EMAIL_ERROR,
  INCORRECT_PASSWORD_ERROR,
  SUBMIT_BUTTON,
  SUBMIT_ROW,
  TERMS_AND_PRIVACY_NOTICE_AGREEMENT,
} from './consts'
import { STRIVEPD_PRIMARY_1000 } from 'theme/colors'

const useStyles = makeStyles(() => ({
  forgotPasswordLinkContainer: {
    marginTop: '1rem',
    textAlign: 'center',
    '& a': {
      color: STRIVEPD_PRIMARY_1000,
    },
  },
  submitButton: {
    ...SUBMIT_BUTTON,
  },
  submitRow: {
    ...SUBMIT_ROW,
  },
}))

export const SignInForm = ({ onSubmit }) => {
  const {
    email,
    error,
    setError,
    password,
    passwordResetSuccessful,
    setEmail,
    setForgotPassword,
    setPassword,
    setPasswordResetSuccessful,
  } = useContext(SignInContext)

  const handleClose = useCallback(
    () => setPasswordResetSuccessful(false),
    [setPasswordResetSuccessful],
  )

  const [showPassword, setShowPassword] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const classes = useStyles()

  const handleForgotPasswordClick = useCallback(
    (e) => {
      e.preventDefault()
      setForgotPassword(true)
      setPassword('')
    },
    [setForgotPassword, setPassword],
  )

  return (
    <FormWrapper
      onSubmit={onSubmit}
      termsAndPrivacyNoticeAgreementText={
        TERMS_AND_PRIVACY_NOTICE_AGREEMENT.existingUser
      }
    >
      {passwordResetSuccessful && (
        <NotificationBar open={passwordResetSuccessful} onClose={handleClose}>
          <Success message="Password reset successful" />
        </NotificationBar>
      )}
      <div>
        <FormControl fullWidth>
          <TextField
            type="email"
            inputProps={{ 'data-cy': 'email' }}
            label="Email address"
            autoFocus
            margin="normal"
            error={error && error?.name === 'UserNotFoundException'}
            helperText={
              error?.name === 'UserNotFoundException'
                ? INCORRECT_EMAIL_ERROR
                : ''
            }
            onChange={(e) => {
              setEmail(e.target.value)
              setError(false)
            }}
            value={email}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth>
          <TextField
            type={showPassword ? 'text' : 'password'}
            inputProps={{ 'data-cy': 'password' }}
            label="Password"
            margin="normal"
            error={error?.name === 'NotAuthorizedException'}
            helperText={
              error?.name === 'NotAuthorizedException'
                ? INCORRECT_PASSWORD_ERROR
                : ''
            }
            onChange={(e) => {
              setPassword(e.target.value)
              setError(false)
            }}
            value={password}
            InputProps={{
              endAdornment: (
                <PasswordVisibilityToggler
                  showPassword={showPassword}
                  toggleShowPassword={toggleShowPassword}
                />
              ),
            }}
          />
        </FormControl>
      </div>
      <FormControl fullWidth className={classes.submitRow}>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={!validateEmail(email) || !password}
          className={classes.submitButton}
        >
          Sign In
        </Button>
      </FormControl>
      <FormControl fullWidth className={classes.forgotPasswordLinkContainer}>
        <Link
          data-cy="forgot-password-link"
          href="#"
          onClick={handleForgotPasswordClick}
        >
          Forgot Password
        </Link>
      </FormControl>
    </FormWrapper>
  )
}

export default SignInForm
