import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, Stack } from 'ui/baseComponents'
import EditableContent from 'ui/clinicianScreens/Patient/Report/components/EditableContent'
import { BUTTON_BASE_STYLES, BUTTON_TEXT } from './consts'
import { colors } from 'theme/colors'

const UploadedImage = ({ image, onClickRemoveImage, title }) => {
  const { id } = image

  const STYLES = {
    image: {
      display: 'block',
      width: '100%',
      height: 'auto',
      maxHeight: '75vh', // Limit image height to 75vh to prevent overflow when printing.
      objectFit: 'contain',
      pageBreakInside: 'avoid',
    },
    removeImageButton: {
      ...BUTTON_BASE_STYLES,
      alignSelf: 'flex-end',
    },
    uploadedImageContainer: {
      pageBreakInside: 'avoid',
    },
  }

  return (
    <>
      {image && (
        <div style={STYLES.uploadedImageContainer}>
          <EditableContent
            dataCy="uploaded-image-title"
            disableToggleHidePrintClass={true}
            stateKey={id}
            stateSrc={{}}
            textVariant="body16B"
            textColor={colors.PRIMARY[1000]}
          >
            {title}
          </EditableContent>
          <Stack>
            <Button
              type="tertiary"
              startIcon={<DeleteOutlineIcon />}
              sx={STYLES.removeImageButton}
              onClick={onClickRemoveImage}
              data-cy="button-remove-image"
            >
              {BUTTON_TEXT.removeImage}
            </Button>

            <img
              src={image}
              alt="supporting material"
              style={STYLES.image}
              data-cy="supporting-material-image"
            />
          </Stack>
        </div>
      )}
    </>
  )
}

export default UploadedImage
