import { Fragment, useCallback, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { get } from 'nested-property'
import { Waypoint } from 'react-waypoint'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import * as MUI from '@mui/material'

import { SHARED_ROUTES } from 'app/AppRoutes/consts'
import CookieBanner from 'ui/components/CookieBanner/CookieBanner'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import ErrorFrame from 'ui/components/ErrorFrame/ErrorFrame'
import FixedWidthLayout from 'ui/components/FixedWidthLayout'
import Loading from 'ui/components/Loading'
import InviteUsers from './InviteUsers'
import UserRow from './UserRow/UserRow'
import { useIsAdmin, useUsers } from 'ui/hooks'
import UserPHIContext from 'ui/contexts/UserPHIContext'
import PageHeader from 'ui/templates/PageHeader'
import { Text } from 'ui/baseComponents/Text'
import MainContentWrapper from 'ui/templates/MainContentWrapper'
import MainContent from 'ui/templates/MainContent'
import MainNav from 'ui/components/MainNav'
import { colors } from 'theme/colors'

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
  },
  tableHead: {
    display: 'none',
  },
  tableRowSkeleton: {
    marginTop: 8,
    marginBottom: 8,
    height: '26px',
  },
  userCol: {
    width: 340,
  },
  emailCol: {
    mindWidth: 300,
  },
}))

const STYLES = {
  usersTableBox: {
    paddingTop: '1rem',
    backgroundColor: colors.COOL[100],
  },
  usersTableSubBox: {
    padding: '0 10%',
    overflowX: 'scroll',
    '@media (max-width: 1200px)': {
      padding: '0',
    },
  },
}

const SKELETON_WIDTH_1 = 60
const SKELETON_WIDTH_2 = 70
const SKELETON_WIDTH_3 = 50

/**
 * Component that conditionally renders error, loading, and data rich views for the Users view
 *
 * @returns {JSX.Element} The content of the user table
 */
const UserTableContent = () => {
  const classes = useStyles()
  const { loading, data, error, fetchMore } = useUsers()

  if (error) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <ErrorFrame error="Unable to load Users" />
        </TableCell>
      </TableRow>
    )
  }
  // Shows a beautiful skeleton for UX purposes
  if (!data && loading) {
    return [SKELETON_WIDTH_1, SKELETON_WIDTH_2, SKELETON_WIDTH_3].map(
      (width, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton
              width={`${width}%`}
              className={classes.tableRowSkeleton}
            />
          </TableCell>
          <TableCell>
            <Skeleton
              width={`${width}%`}
              className={classes.tableRowSkeleton}
            />
          </TableCell>
          <TableCell colSpan={2}></TableCell>
        </TableRow>
      ),
    )
  }

  return <UserRows data={data} fetchMore={fetchMore} />
}

/**
 * Request function that pulls memberships info and formats and returns user data
 *
 * @param {object} data the existing user data
 * @returns {Array} an array of users
 */
const getUsers = function (data) {
  const memberships = get(data, 'org.membershipList.memberships')
  const users = memberships.map(
    ({ id, status, admin, user, role, disabled }) => ({
      ...user,
      status,
      admin,
      membershipId: id,
      membershipRole: role,
      disabled,
    }),
  )
  return users
}
const getCursor = function (data) {
  return get(data, 'org.membershipList.pageInfo.endCursor')
}

/**
 * The rows of user data, which also handles fetching and appending more data
 * when the user scrolls to the last row
 *
 * @param {object} data the existing user data
 * @param {function} fetchMore Function used for requesting and appending more user data
 * @returns {JSX.Element} Rows of user data
 */
const UserRows = ({ data, fetchMore }) => {
  const users = getUsers(data)
  const cursor = getCursor(data)
  const sortedUsers = users.sort((a, b) =>
    (a.displayName || a.email).toLowerCase() >
    (b.displayName || b.email).toLowerCase()
      ? 1
      : -1,
  )

  const loadMore = useCallback(
    () =>
      fetchMore({
        variables: { cursor },
        updateQuery(previousResult, { fetchMoreResult }) {
          const prevMemberships =
            previousResult.org.membershipList.memberships || []
          const newMemberships =
            fetchMoreResult.org.membershipList.memberships || []

          return {
            ...fetchMoreResult,
            org: {
              ...(fetchMoreResult.org || {}),
              membershipList: {
                ...(fetchMoreResult.org.membershipList || {}),
                memberships: [...prevMemberships, ...newMemberships],
              },
            },
          }
        },
      }),
    [cursor, fetchMore],
  )

  return (
    <Fragment>
      {sortedUsers.map((user, index) => (
        <ErrorBoundary key={index}>
          <UserRow {...user} />
        </ErrorBoundary>
      ))}
      {cursor && (
        // scrollableAncestor={window} is needed to ensure that infinite scroll continues to work in conjunction with the overflowX scroll applied to the wrapper around the table.
        <Waypoint onEnter={loadMore} scrollableAncestor={window}>
          <TableRow>
            <TableCell colSpan={4}>
              <Loading />
            </TableCell>
          </TableRow>
        </Waypoint>
      )}
    </Fragment>
  )
}

/**
 * The user data table that sets the table head and renders the body components
 *
 * @returns {JSX.Element} The User Table
 */
const UserTable = () => {
  const classes = useStyles()

  return (
    <Table className={classes.table} aria-label="simple table">
      <TableHead className={classes.tableHead}>
        <TableRow>
          <TableCell align="left" className={classes.emailCol} />
          <TableCell align="left" className={classes.userCol} />
          <TableCell align="right" className={classes.statusCol} />
          <TableCell align="right" />
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        <UserTableContent />
      </TableBody>
    </Table>
  )
}

/**
 * The main container component for the user view
 *
 * @returns {JSX.Element} The container component
 */
export const Users = () => {
  const { admin } = useIsAdmin()
  const phiVisibility = useContext(UserPHIContext)

  // type check is required for the brief instant that admin could be undefined
  if (typeof admin === 'boolean' && !admin) {
    return <Navigate to={SHARED_ROUTES.patients.path} replace />
  }

  if (phiVisibility) {
    return (
      <>
        <MainNav />
        <PageHeader
          title={
            <Text component="h1" variant="head42">
              Team
            </Text>
          }
          contentRight={<InviteUsers />}
        />
        <MainContentWrapper>
          <ErrorBoundary>
            <MUI.Box sx={STYLES.usersTableBox}>
              <MainContent>
                <MUI.Box sx={STYLES.usersTableSubBox}>
                  <UserTable />
                </MUI.Box>
              </MainContent>
            </MUI.Box>
          </ErrorBoundary>
        </MainContentWrapper>
        <CookieBanner />
      </>
    )
  } else {
    return (
      <>
        <FixedWidthLayout>
          <ErrorBoundary>
            <InviteUsers />
            <Paper>
              <UserTable />
            </Paper>
          </ErrorBoundary>
        </FixedWidthLayout>
        <CookieBanner />
      </>
    )
  }
}

export default Users
