import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useState,
} from 'react'
import useIsMounted from 'utilities/hooks/isMounted'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { validate } from 'email-validator'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import SubmitButton from '../../../components/SubmitButton/SubmitButton'
import OrgContext from '../../../contexts/OrgContext'
import { constructRoleVariables, useCreateMembership } from '../mutations'
import { GET_USERS } from 'domains/carrotGraph/queries'
import { UserPHIContext } from 'ui/contexts'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
    width: 700,
  },
  formControl: {
    marginRight: theme.spacing(1),
  },
  emailContainer: {
    width: 300,
  },
  userType: {
    minWidth: 100,
  },
  closeButtonContainer: {
    width: 30,
    display: 'block',
  },
}))

export const isValid = ({ displayName, email }) => {
  return validate(email) && displayName.length < 100
}

export const getDuplicateErrorMessage = (email) =>
  `A user with the email ${email} is already a member.`

export const InviteUserRow = forwardRef(
  ({ confirm, user = {}, setUser, onClose = () => {}, showCloseIcon }, ref) => {
    const phiVisibility = useContext(UserPHIContext)
    const { admin, email, displayName, roleDisplayName } = user
    const organization = useContext(OrgContext)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [timeoutId, setTimeoutId] = useState(false)
    const isMounted = useIsMounted()

    const doClose = () => {
      if (!isMounted) return
      onClose()
    }
    if (success && !timeoutId) {
      setTimeoutId(setTimeout(doClose, 1500))
    }

    const [createMembership, { loading }] = useCreateMembership({
      onError: (error) => {
        console.error(error)
        const errorMessage = error.message.includes(409)
          ? getDuplicateErrorMessage(email)
          : `Unable to invite user ${email}`

        setError(errorMessage)
      },
      refetchQueries: [
        {
          query: GET_USERS,
          variables: { orgId: organization.id, withDisabled: true },
        },
      ],
    })
    // Expose submit to parent via a ref
    useImperativeHandle(ref, () => ({ submit: handleSubmit }))

    const classes = useStyles()

    const handleSubmit = useCallback(
      (e) => {
        e && e.preventDefault()
        const membership = {
          admin,
          displayName,
          email,
          orgId: organization.id,
        }

        const updateCreateMembershipVariables = (roleDisplayName) => {
          const [adminVal, canSeePHIVal] =
            constructRoleVariables(roleDisplayName)
          membership['admin'] = adminVal
          membership['canSeePHI'] = canSeePHIVal
        }
        updateCreateMembershipVariables(roleDisplayName)

        if (!isValid(user)) return

        return createMembership({ variables: { input: membership } }).then(
          (result) => {
            // If mutation fails, result is undefined
            setSuccess(!!result)
          },
        )
      },
      [
        admin,
        createMembership,
        displayName,
        email,
        organization.id,
        user,
        roleDisplayName,
      ],
    )
    const handleChange = (attr) => (e) => {
      setError(false)
      setUser({ ...user, [attr]: e.target.value })
    }

    return (
      <form id="invite-user" onSubmit={handleSubmit} data-cy="InviteUserRow">
        <Box display="flex" alignItems="baseline" className={classes.root}>
          <FormControl
            className={clsx(classes.formControl, classes.emailContainer)}
          >
            <TextField
              error={!!error}
              helperText={error}
              autoFocus
              id="email"
              data-cy="emailField"
              label="Email Address"
              type="email"
              value={email}
              onChange={handleChange('email')}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="displayName"
              data-cy="displayName"
              label="Display Name"
              value={displayName}
              onChange={handleChange('displayName')}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="admin-select">Type</InputLabel>
            <Select
              data-cy="userType"
              className={classes.userType}
              labelId="admin-select"
              value={roleDisplayName}
              label="Type"
              onChange={handleChange('roleDisplayName')}
            >
              <MenuItem value="Member" data-cy="invite-user-role-option-user">
                User
              </MenuItem>
              <MenuItem value="Admin" data-cy="invite-user-role-option-admin">
                Admin
              </MenuItem>
              {phiVisibility && (
                <MenuItem
                  value="Clinician"
                  data-cy="invite-user-role-option-clinician"
                >
                  Clinician
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <SubmitButton
              success={success}
              loading={loading}
              successLabel="Invited"
              submitLabel="Invite"
              disabled={!isValid(user) || loading}
            />
          </FormControl>
          <FormControl
            className={clsx(classes.formControl, classes.closeButtonContainer)}
          >
            {showCloseIcon && (
              <IconButton
                size="small"
                edge="start"
                aria-label="close"
                onClick={doClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </FormControl>
        </Box>
      </form>
    )
  },
)
export default InviteUserRow
