import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { withPermission } from 'domains/carrotGraph/user'
import { useLogger } from 'domains/logger/context'
import { Search } from '@material-ui/icons'
import DefaultLayout from 'ui/components/DefaultLayout'
import ErrorMessage from 'ui/components/ErrorMessage'
import LoadingPage from 'ui/components/LoadingPage/LoadingPage'
import { PatientProvider } from 'ui/contexts/PatientContext'
import EditPatientSidebar from 'ui/screens/EditPatient/EditPatientSidebar'
import { useFullPatient } from 'ui/screens/EditPatient/queries'

export const EditPatient = () => {
  const { patientId } = useParams()
  const { loading, error, patient } = useFullPatient(patientId)
  const logger = useLogger()

  if (loading) {
    return <LoadingPage data-cy="EditPatient-Loading" />
  }

  if (error || !patient) {
    logger.error(error)
    return (
      <DefaultLayout>
        <ErrorMessage
          icon={Search}
          message="Oops! Looks like this patient doesn't exist, or you don't have access to their data."
        />
      </DefaultLayout>
    )
  }

  return (
    <PatientProvider value={patient}>
      <DefaultLayout sidebar={<EditPatientSidebar />}>
        <Outlet />
      </DefaultLayout>
    </PatientProvider>
  )
}

export default withPermission('admin')(EditPatient)
