/**
 * Given a set of data table column configs, build a lookup table that gives an index of the column based on its name.
 * @param {object[]} columns Column configuration for a data table.
 * @returns {object} Lookup table where the key is the column name and the value is the index.
 */
export const getLabels = (columns = []) =>
  columns.reduce((acc, { name }, index) => {
    acc[name] = index
    return acc
  }, {})

/**
 * Given a set of data table column options, update the content to display when there is no data.
 * @param {object} options Basic configuration of the data table.
 * @param {string|element} contentToDisplay The string or element that we want to display instead of data.
 * @returns {object} A copy of the options passed in with textLabels.body.noMatch value updated
 */
export const updateTableBodyContent = ({ options, contentToDisplay }) => ({
  ...options,
  textLabels: {
    ...options.textLabels,
    body: {
      ...options.textLabels.body,
      noMatch: contentToDisplay,
    },
  },
})
