export const APPLE_MOBILITY_METRICS = {
  linkText: "Apple's Mobility Metrics",
  url: 'https://www.apple.com/healthcare/docs/site/Measuring_Walking_Quality_Through_iPhone_Mobility_Metrics.pdf',
}
export const NO_DATA_MOBILITY =
  'Data can be automatically recorded when the iPhone is carried near the waist, such as in a trouser pocket, and when walking steadily on flat ground.  This data gives valuable insights into functional mobility health.'

export const NO_DATA_SLEEP = 'No data is available for sleep.'
export const NO_DATA_TOP_SYMPTOMS = 'No symptoms to display.'
export const NO_DATA_PATIENT_NOTES = 'No patient notes to display.'
export const NO_DATA_MOTOR = 'No data is available for motor symptoms.'
export const NO_DEVICE_TEXT = 'No watch is paired with this patient.'
export const ACTION_ITEM_TEXT =
  'Encourage your patient to wear the Apple watch. Data can be automatically recorded when a patient carries their iPhone near the waist, such as in a trouser pocket, and walks steadily on flat ground. This data gives valuable insights into the patient’s care.'

export const ERROR_TEXT = 'An error occurred. Data may be absent or incomplete.'
