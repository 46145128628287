/**
 * Custom Plotly bundle for Carrot
 *
 * To reduce JS asset size, as well as memory needed to compile in CI, we
 * create a custom-tailered Plotly object with only visualization types we
 * use.
 */
import Plotly from 'plotly.js/lib/core'
import Bar from 'plotly.js/lib/bar'
import Heatmap from 'plotly.js/lib/heatmap'
import Histogram from 'plotly.js/lib/histogram'
import Histogram2dContour from 'plotly.js/lib/histogram2dcontour'
import Scatter from 'plotly.js/lib/scatter'
import createPlotlyComponent from 'react-plotly.js/factory'
import './Plot.scss'

Plotly.register([Bar, Heatmap, Scatter, Histogram, Histogram2dContour])

export default createPlotlyComponent(Plotly)
