import React from 'react'
import PropTypes from 'prop-types'
import { RUNE_GRAY_400 } from 'theme/colors'
import { Box, Checkbox, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  checkboxHidden: {
    visibility: 'hidden',
    display: 'none',
  },
  dayLetter: {
    position: 'relative',
    top: '2px',
    textAlign: 'center',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  selected: {
    height: '1.5rem',
    width: '1.5rem',
    marginLeft: theme.spacing(0.5),
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
  notSelected: {
    height: '1.5rem',
    width: '1.5rem',
    marginLeft: theme.spacing(0.5),
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: RUNE_GRAY_400,
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
}))

const DaySelect = ({ setDay, selectedDayArray }) => {
  const classes = useStyles()
  const FIRST_LETTER_POSITION = 0
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const daysOfWeekValues = [6, 0, 1, 2, 3, 4, 5]

  return (
    <Box display="block">
      {daysOfWeekValues.map((dayNumber, i) => (
        <div
          onClick={() => setDay(dayNumber)}
          className={
            selectedDayArray.includes(dayNumber)
              ? classes.selected
              : classes.notSelected
          }
          key={dayNumber}
        >
          <Typography className={classes.dayLetter}>
            {daysOfWeek[i][FIRST_LETTER_POSITION]}
          </Typography>
          <Checkbox
            checked={selectedDayArray.includes(dayNumber)}
            className={classes.checkboxHidden}
            name={daysOfWeek[i]}
            disableRipple
          />
        </div>
      ))}
    </Box>
  )
}

DaySelect.propTypes = {
  setDay: PropTypes.func.isRequired,
  selectedDayArray: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default DaySelect
