import { useState, useEffect, useCallback } from 'react'
import { fmtMomentLocal } from 'utilities/time'
import Plot from 'ui/components/Plot'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({ plot: { width: '100%' } }))

/**
 * Plot component that staggers and formats data for the Spans Plot, then renders it.
 *
 * @param {object} pointData The point event request data for the plot
 * @param {object} spanData The span event request data for the plot
 * @param {object} layout The plot layout
 * @param {string} plotTitle The basic plot title
 * @param {string} pointColor The plot point color
 * @param {array} colorScale The span plot colors for heatmap
 * @param {function} onHover Function that grabs point data to send to AdditionalInfo
 * @param {function} onUnhover Function that unmounts AdditionalInfo
 * @param {float} rangeStartLocal Beginning of time range, used only as a placeholder
 * @param {float} rangeEndLocal End of time range, used only as a placeholder
 */
const EventsStream = ({
  pointData,
  spanData,
  layout,
  plotTitle,
  pointColor,
  colorScale,
  onHover,
  onUnhover,
  rangeStartLocal,
  rangeEndLocal,
}) => {
  const classes = useStyles()
  const [formattedData, setFormattedData] = useState([])
  const placeholderDataObject = {
    points: {
      timeArray: [rangeStartLocal, null, rangeEndLocal],
      pointsArray: [null, plotTitle, null],
      hoverInfoArray: [null, null, null],
    },
    spans: {
      timeArray: [],
      zArray: [],
      title: plotTitle,
      hoverInfoArray: [],
    },
  }

  /**
   * Assigns each event to an identical object for easy time comparison
   *
   * @param {array} pointData The point event data to be keyed
   * @param {object} spanData The span event data to be keyed
   */
  const assignDataToKeys = (pointData, spanData) => {
    let assignedData = []
    if (spanData && spanData.span) {
      spanData.span.forEach((span, spanIndex) => {
        const keyedSpan = {
          startTime: span.start_time_min,
          endTime: span.end_time_max,
          type: 'span',
          index: spanIndex,
        }
        assignedData.push(keyedSpan)
      })
    }
    if (pointData) {
      pointData.forEach((point, pointIndex) => {
        const keyedPoint = {
          startTime: point.time,
          endTime: point.time,
          type: 'point',
          index: pointIndex,
        }
        assignedData.push(keyedPoint)
      })
    }
    return assignedData
  }

  /**
   * Separates and formats the staggered events by type
   *
   * @param {array} staggeredEventsArray an array of staggered events to be separated by type
   */
  const separatePointsAndSpans = (staggeredEventsArray) => {
    let separatedArray = []
    staggeredEventsArray.forEach((combinedArray) => {
      let pointEventsArray = []
      let spanEventsArray = []
      combinedArray.forEach((event) => {
        event.type === 'point'
          ? pointEventsArray.push(event)
          : spanEventsArray.push(event)
      })
      separatedArray.push({ points: pointEventsArray, spans: spanEventsArray })
    })
    return separatedArray
  }

  /**
   * Formats the most pertinent info for each stream to be shown on basic hover, with an exception for sleep
   *
   * @param {array} separatedEventsArray An array of arrays of non-overlapping events to format
   */
  const formatPayloadShortDesc = (payload) => {
    // if we add any more exceptions this should be moved to the individual stream wrappers
    if (payload.category === 'Sleep') {
      const quality = payload.quality ? payload.quality : 'Not Provided'
      return 'Sleep Quality: ' + quality
    }
    if (payload.category === 'StriveStudy') {
      return 'Symptom: ' + payload.name
    }
    return payload.category + ': ' + payload.name
  }

  /**
   * Formats each Event in the now-separated array of arrays of Events.
   *
   * @param {array} separatedEventsArray An array of arrays of non-overlapping events to format
   */
  const formatEachEventArray = useCallback(
    (separatedEventsArray) => {
      let formattedAndSeparatedEventsArray = []

      // iterates through each array of events, and creates a new structure in
      // formattedAndSeparatedEventsArray to which we will push select data
      separatedEventsArray.forEach((eventsObject, i) => {
        // Titles the first plot "Events" and subsequent plots "Events (2)", "Events (3)", etc.
        const title = i === 0 ? plotTitle : plotTitle + ' (' + (i + 1) + ')'
        formattedAndSeparatedEventsArray[i] = {
          points: {
            timeArray: [],
            pointsArray: [],
            hoverInfoArray: [],
          },
          spans: {
            timeArray: [],
            zArray: [],
            title: title,
            hoverInfoArray: [],
          },
        }

        // Iterates through each point event and pushes time and hover info to the new structure
        eventsObject.points.forEach((pointEvent) => {
          const formattedTime = fmtMomentLocal(
            'Y-MM-DD HH:mm:ss.SSS',
            pointData[pointEvent.index].time * 1000,
          )
          let payload = pointData[pointEvent.index].payload
          payload.shortDesc = formatPayloadShortDesc(payload)
          // Pushes this data to the corresponding array
          formattedAndSeparatedEventsArray[i].points.timeArray.push(
            null,
            formattedTime,
            null,
          )
          formattedAndSeparatedEventsArray[i].points.pointsArray.push(
            null,
            title,
            null,
          )
          formattedAndSeparatedEventsArray[i].points.hoverInfoArray.push(
            null,
            payload,
            null,
          ) // remove after format choices
        })

        // Iterates through each span event and pushes time and hover info to the new structure
        eventsObject.spans.forEach((spanEvent) => {
          const formattedTimeArray = spanData.time[spanEvent.index].map(
            (timestamp) =>
              fmtMomentLocal('Y-MM-DD HH:mm:ss.SSS', timestamp * 1000),
          )
          const indexedZArray = spanData.z[spanEvent.index]
          let payload = spanData.span[spanEvent.index].payload
          payload.shortDesc = formatPayloadShortDesc(payload)
          const mappedHoverArray = spanData.z[spanEvent.index].map(
            () => payload,
          )
          // Pushes this data to the corresponding array
          formattedAndSeparatedEventsArray[i].spans.timeArray.push(
            ...formattedTimeArray,
          )
          formattedAndSeparatedEventsArray[i].spans.zArray.push(
            ...indexedZArray,
          )
          formattedAndSeparatedEventsArray[i].spans.hoverInfoArray.push(
            ...mappedHoverArray,
          ) // remove after format choices
        })
      })
      return formattedAndSeparatedEventsArray
    },
    [plotTitle, pointData, spanData],
  )

  /**
   * Calls functions in order to filter Event data, then separate any that overlap,
   * then formats those separated Events for the Plot, then sets that data in state.
   */
  useEffect(() => {
    const keyedEvents = assignDataToKeys(pointData, spanData) // key the data for comparison
    const staggeredEventsArray = staggerEvents(keyedEvents) // separate overlap
    const separatedPointsAndSpansArray =
      separatePointsAndSpans(staggeredEventsArray)
    const formattedAndSeparatedEventsArray = formatEachEventArray(
      separatedPointsAndSpansArray,
    ) // format
    setFormattedData(formattedAndSeparatedEventsArray) // set in state
  }, [pointData, spanData, formatEachEventArray])

  /**
   * Separates any Events that overlap into separate arrays to be displayed in separate plots.
   *
   * @param {array} keyedEvents An array of Events that has been filtered to only the approved types
   */
  const staggerEvents = (keyedEvents) => {
    let separatedEventsArray = []
    keyedEvents.forEach((newEvent, newEventIndex) => {
      // if it's the first event, just add it in a new sub-array
      if (newEventIndex === 0) {
        separatedEventsArray.push([newEvent])
      } else {
        // For each existing array of events in the separatedEventsArray
        separatedEventsArray.some((singleEventArray, singleEventArrayIndex) => {
          let itemAdded = false

          // Iterate through the already-pushed events
          singleEventArray.some((oldEvent, oldEventIndex) => {
            // if any have overlap...
            if (
              newEvent.startTime === oldEvent.startTime ||
              newEvent.startTime === oldEvent.endTime ||
              newEvent.endTime === oldEvent.startTime ||
              newEvent.endTime === oldEvent.endTime ||
              (newEvent.startTime > oldEvent.startTime &&
                newEvent.startTime < oldEvent.endTime) ||
              (newEvent.endTime < oldEvent.endTime &&
                newEvent.endTime > oldEvent.startTime) ||
              (newEvent.startTime < oldEvent.startTime &&
                newEvent.endTime > oldEvent.endTime)
            ) {
              // if we reach the end of the separatedEventsArray, and there is
              // overlap in each, create a new array to plot
              if (separatedEventsArray.length - singleEventArrayIndex === 1) {
                separatedEventsArray.push([newEvent])
              }
              // break the loop if there is overlap (or if a new array was created)
              return true
            } else {
              if (singleEventArray.length - oldEventIndex === 1) {
                // if there is no overlap, just add it to the currently iterating array
                singleEventArray.push(newEvent)
                itemAdded = true
                // break the loop once added
                return true
              } else {
                // keep looping if there are more items to check for overlap.
                return false
              }
            }
          })
          return itemAdded
        })
      }
    })
    return separatedEventsArray
  }

  /**
   * Takes one separated and formatted events object-of-arrays and plugs it into a Plotly Plot Data Object
   *
   * @param {object} formattedDataObject An object with 3 arrays of Plot-relevant data
   */
  const processEventData = (formattedDataObject) => {
    return [
      {
        hovertemplate: `
          <b>%{x} - %{customdata.shortDesc}</b><extra></extra>
        `,
        hovermode: 'closest',
        mode: 'markers',
        marker: {
          size: 15,
          color: pointColor,
        },
        type: 'scatter',
        visible: true,
        x: formattedDataObject.points.timeArray,
        y: formattedDataObject.points.pointsArray,
        customdata: formattedDataObject.points.hoverInfoArray,
      },
      {
        hovertemplate: `
          <b>%{x} - %{customdata.shortDesc}</b><extra></extra>
        `,
        hovermode: 'closest',
        autocolorscale: 'false',
        hoverongaps: false,
        ygap: 1,
        colorbar: {
          bordercolor: 'rgba(0, 0, 0, 1)',
          borderwidth: 100,
        },
        colorscale: colorScale,
        showscale: false,
        visible: true,
        type: 'heatmap',
        x: formattedDataObject.spans.timeArray,
        y: [formattedDataObject.spans.title],
        z: [formattedDataObject.spans.zArray],
        customdata: [formattedDataObject.spans.hoverInfoArray],
      },
    ]
  }

  // Controls placeholder state
  const hasData = formattedData && formattedData.length

  return (
    <>
      {hasData ? (
        formattedData.map((formattedDataObject, i) => {
          return (
            <div key={'Spans-' + i}>
              <Plot
                className={classes.plot}
                config={{
                  autosize: true,
                  responsive: true,
                  displayModeBar: false,
                  doubleClick: false,
                  scrollZoom: false,
                }}
                data={processEventData(formattedDataObject)}
                layout={layout}
                useResizeHandler={true}
                onHover={(e) => {
                  onHover(e)
                }}
                onUnhover={onUnhover}
              />
            </div>
          )
        })
      ) : (
        <div key="Spans-Placeholder" data-cy={plotTitle || ''}>
          <Plot
            className={classes.plot}
            config={{
              autosize: true,
              responsive: true,
              displayModeBar: false,
              doubleClick: false,
              scrollZoom: false,
            }}
            data={processEventData(placeholderDataObject)}
            layout={layout}
            useResizeHandler={true}
          />
        </div>
      )}
    </>
  )
}

export default EventsStream
