import { useCallback, useContext, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { useMutation } from '@apollo/client'
import { ClientKeys } from './ClientKeys'
import CreateEntityDialog from 'ui/components/CreateEntity/CreateEntityDialog'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { CREATE_CLIENT } from '../mutations'
import { useRefetchFullPatient } from 'ui/screens/EditPatient/queries'
import { PatientContext } from 'ui/contexts'

export const CreateClientDialog = ({ handleClose }) => {
  const { id: patientId } = useContext(PatientContext)
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const [createClientMutation, { loading, data }] = useMutation(CREATE_CLIENT, {
    onError: (error) => {
      console.error(error)
      setError('Unable to create client')
    },
    refetchQueries: [useRefetchFullPatient(patientId)],
  })

  const createClient = useCallback(
    async (input) => {
      try {
        await createClientMutation({ variables: { input } })
        setSuccess(true)
      } catch (error) {
        console.error(error)
        setError(error)
      }
    },
    [createClientMutation],
  )
  const {
    createClient: { clientKeySecret, clientKey: { clientKeyId } = {} } = {},
  } = data || {}
  const [displayName, setDisplayName] = useState('')
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        await createClient({
          displayName,
          patientId,
          withClientKey: true,
        })
      } catch (error) {
        console.error(error)
      }
    },
    [createClient, displayName, patientId],
  )
  return (
    <CreateEntityDialog
      title="Create new Client"
      data-cy="CreateClient"
      success={success}
      loading={loading}
      disabled={!displayName.length}
      handleClose={handleClose}
      onSubmit={onSubmit}
    >
      <ErrorBoundary>
        {clientKeySecret ? (
          <ClientKeys secret={clientKeySecret} clientKeyId={clientKeyId} />
        ) : (
          <FormControl fullWidth>
            <TextField
              id="codeName"
              data-cy="codeName"
              error={!!error}
              helperText={error}
              disabled={loading}
              label="Client Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              autoFocus
            />
          </FormControl>
        )}
      </ErrorBoundary>
    </CreateEntityDialog>
  )
}
export default CreateClientDialog
