import { gql } from '@apollo/client'

export const GET_PATIENT_LIST = gql`
  query getPatientList($cursor: Cursor, $limit: Int) {
    org {
      id
      patientAccessList(cursor: $cursor, limit: $limit) {
        pageInfo {
          endCursor
        }
        patientAccess {
          patient {
            id
            codeName
            created_at: createdAt
            identifiableInfo {
              realName
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`
