import { createContext, useContext } from 'react'

import Logger, { MockRollbar } from './Logger'

/**
 * The logger context.
 */
export const LoggerContext = createContext()

/**
 * Shortcut for getting the logger context.
 */
export const useLogger = () => {
  return useContext(LoggerContext)
}

/**
 * Context provider for the logger.
 *
 * In `development` and `test` environments, Rollbar is mocked to log to
 * console.
 */
export const LoggerProvider = ({ rollbar, children }) => {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    rollbar = new MockRollbar()
  }

  const logger = new Logger(rollbar)

  return (
    <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>
  )
}

export default LoggerContext
