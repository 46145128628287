import { createContext, useCallback, useContext, useState } from 'react'
import moment from 'moment'
import { DATE_FORMAT, MAX_WEEKS } from 'ui/consts'
import { timezoneMap } from 'ui/clinicianScreens/Patient/TimezoneDropdown/consts'
import { PatientContext } from 'ui/contexts'
import { validateDates, WEEK_IN_DAYS } from 'utilities/time'
import { getReportDates } from 'ui/clinicianScreens/Patient/Report/helpers'

export const DateRangeContext = createContext()
export const DATE_RANGE = Object.freeze({
  default: {
    label: 'Period',
    key: 'DATE_RANGE.DEFAULT.KEY',
  },
  period1: {
    label: 'Period 1',
    titleSuffix: 'P1',
    key: 'DATE_RANGE.PERIOD1.KEY',
  },
  period2: {
    label: 'Period 2',
    titleSuffix: 'P2',
    key: 'DATE_RANGE.PERIOD2.KEY',
  },
  reportPeriod1: {
    label: 'Previous Period',
    key: 'DATE_RANGE.REPORT_PERIOD1.KEY',
  },
  reportPeriod2: {
    label: 'Recent Period',
    key: 'DATE_RANGE.REPORT_PERIOD2.KEY',
  },
  weeklyViewPeriod: {
    label: 'Weekly View Period',
    key: 'DATE_RANGE.WEEKLY_VIEW_PERIOD.KEY',
  },
})

const getTimezoneInIANAFormat = (timezone) =>
  timezone in timezoneMap ? timezoneMap[timezone] : timezone

const getInitialState = () => {
  const params = new URLSearchParams(window.location.search)
  const { pathname } = window.location
  const startTime = params.get('startTime')
  const endTime = params.get('endTime')
  const startTimePeriod1 = params.get('startTimePeriod1')
  const startTimePeriod2 = params.get('startTimePeriod2')
  const endTimePeriod1 = params.get('endTimePeriod1')
  const endTimePeriod2 = params.get('endTimePeriod2')

  const {
    startDate: reportDefaultStartDate,
    endDate: reportDefaultEndDate,
    prevStartDate: reportDefaultPrevStartDate,
    prevEndDate: reportDefaultPrevEndDate,
  } = getReportDates()

  const weeklyDefaultEndDate = reportDefaultPrevEndDate
  const weeklyDefaultStartDate = moment(reportDefaultPrevStartDate)
    .subtract(8 * WEEK_IN_DAYS - 1, 'd')
    .format(DATE_FORMAT)

  if (pathname.includes('weeklyView') && startTime && endTime) {
    return {
      [DATE_RANGE.default.key]: {
        start: moment()
          .subtract(MAX_WEEKS * WEEK_IN_DAYS - 1, 'd')
          .format(DATE_FORMAT),
        end: moment().format(DATE_FORMAT),
      },
      [DATE_RANGE.reportPeriod1.key]: {
        start: startTimePeriod1,
        end: endTimePeriod1,
      },
      [DATE_RANGE.reportPeriod2.key]: {
        start: startTimePeriod2,
        end: endTimePeriod2,
      },
      [DATE_RANGE.weeklyViewPeriod.key]: {
        start: startTime,
        end: endTime,
      },
    }
  }

  if (startTime || endTime) {
    const [validatedStartTime, validatedEndTime] = validateDates(
      startTime,
      endTime,
    )

    return {
      [DATE_RANGE.default.key]: {
        start: validatedStartTime,
        end: validatedEndTime,
      },
      [DATE_RANGE.reportPeriod1.key]: {
        start: reportDefaultPrevStartDate,
        end: reportDefaultPrevEndDate,
      },
      [DATE_RANGE.reportPeriod2.key]: {
        start: reportDefaultStartDate,
        end: reportDefaultEndDate,
      },
      [DATE_RANGE.weeklyViewPeriod.key]: {
        start: weeklyDefaultStartDate,
        end: weeklyDefaultEndDate,
      },
    }
  }

  if (
    pathname.includes('report') &&
    startTimePeriod1 &&
    endTimePeriod1 &&
    startTimePeriod2 &&
    endTimePeriod2
  ) {
    return {
      [DATE_RANGE.default.key]: {
        start: moment()
          .subtract(MAX_WEEKS * WEEK_IN_DAYS - 1, 'd')
          .format(DATE_FORMAT),
        end: moment().format(DATE_FORMAT),
      },
      [DATE_RANGE.reportPeriod1.key]: {
        start: startTimePeriod1,
        end: endTimePeriod1,
      },
      [DATE_RANGE.reportPeriod2.key]: {
        start: startTimePeriod2,
        end: endTimePeriod2,
      },
      [DATE_RANGE.weeklyViewPeriod.key]: {
        start: weeklyDefaultStartDate,
        end: weeklyDefaultEndDate,
      },
    }
  }

  return {
    [DATE_RANGE.default.key]: {
      start: moment()
        .subtract(MAX_WEEKS * WEEK_IN_DAYS - 1, 'd')
        .format(DATE_FORMAT),
      end: moment().format(DATE_FORMAT),
    },
    [DATE_RANGE.reportPeriod1.key]: {
      start: reportDefaultPrevStartDate,
      end: reportDefaultPrevEndDate,
    },
    [DATE_RANGE.reportPeriod2.key]: {
      start: reportDefaultStartDate,
      end: reportDefaultEndDate,
    },
    [DATE_RANGE.weeklyViewPeriod.key]: {
      start: weeklyDefaultStartDate,
      end: weeklyDefaultEndDate,
    },
  }
}

const useDateRangeContext = () => {
  const { userInfo } = useContext(PatientContext) || {}
  const { timezone: patientTimezone } = userInfo || {}

  const browserTimezone = moment.tz.guess()
  const patientTimezoneInIANAFormat =
    getTimezoneInIANAFormat(patientTimezone) || null

  const [selectedTimezone, setSelectedTimezone] = useState(
    patientTimezone ? patientTimezoneInIANAFormat : browserTimezone,
  )

  const [dateRanges, setDateRanges] = useState(getInitialState())

  return {
    dateRanges,
    patientTimezone: patientTimezoneInIANAFormat,
    selectedTimezone,
    setSelectedTimezone: useCallback((tz) => {
      setSelectedTimezone(tz)
    }, []),
    setDateRange: useCallback(
      (start, end, key = DATE_RANGE.default.key) =>
        setDateRanges((dateRanges) => ({
          ...dateRanges,
          [key]: {
            start,
            end,
          },
        })),
      [],
    ),
  }
}

export default useDateRangeContext
