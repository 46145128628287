import { Box } from '@material-ui/core'
import { shape, string } from 'prop-types'
import { InfoCard } from 'ui/components/Card'
import { translate as t } from 'ui/components/translate'
import ChartLoading from 'ui/components/ChartLoading'
import EmptyResults from 'ui/components/EmptyResults'
import ErrorFrame from 'ui/components/ErrorFrame'
import { List } from 'ui/components/List'
import useBoundStore from 'domains/zustand/store'

const TopPatientReportedSymptoms = ({ classes }) => {
  const eventCountLoading = useBoundStore((state) => state.eventCountLoading)
  const eventCountError = useBoundStore((state) => state.eventCountError)
  const symptoms = useBoundStore((state) => state.topSymptomEventCounts)

  const TOP_PATIENT_REPORTED_SYMPTOMS_CARD_TITLE = t(
    'patient.top_patient_reported_symptoms',
  )

  if (eventCountLoading) {
    return <ChartLoading height={150} />
  }

  if (eventCountError) {
    return <ErrorFrame error="Symptoms unable to load" height={'175px'} />
  }

  if (!symptoms.length) {
    return (
      <InfoCard
        className={classes.card}
        title={TOP_PATIENT_REPORTED_SYMPTOMS_CARD_TITLE}
      >
        <Box className={classes.emptyItem}>
          <EmptyResults message="None logged" />
        </Box>
      </InfoCard>
    )
  }

  return (
    <InfoCard
      className={classes.card}
      title={TOP_PATIENT_REPORTED_SYMPTOMS_CARD_TITLE}
    >
      <List
        component="ol"
        list={[
          ...symptoms.map(({ count, customDetail, displayName }) => ({
            key: customDetail?.displayName || displayName,
            id: customDetail?.displayName || displayName,
            value: (
              <span>
                {customDetail?.displayName || displayName} (<b>{count}</b>)
              </span>
            ),
          })),
        ]}
      />
    </InfoCard>
  )
}

TopPatientReportedSymptoms.propTypes = {
  classes: shape({
    card: string,
    emptyItem: string,
  }).isRequired,
}

TopPatientReportedSymptoms.defaultProps = {
  classes: {},
}

export default TopPatientReportedSymptoms
