import { useState, useContext, useMemo } from 'react'
import { oneOf } from 'prop-types'
import { Stack } from 'ui/baseComponents/Stack'
import TabActionableHeader from 'ui/templates/TabActionableHeader'
import { SECTION_TITLES } from './consts'
import { saveWithPatientName } from './helpers'
import { PatientContext } from 'ui/contexts'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import { getTimestampStartOfDay, getTimestampEndOfDay } from 'utilities/time'
import ReportDatePicker from './components/ReportDatePicker'
import useBoundStore from 'domains/zustand/store'
import { patientName } from 'utilities/string'
import ReportPatient from './ReportPatient'
import ReportClinician from './ReportClinician'
import ReportLoader from 'ui/clinicianScreens/Patient/Report/components/ReportLoader'
import ReportSectionsToggle from 'ui/clinicianScreens/Patient/Report/components/ReportSectionsToggle'
import { useSearchParams } from 'react-router-dom'

const STYLES = {
  container: {
    '@media screen': {
      padding: '2rem',
      maxWidth: 940,
      margin: '0 auto',
      '& br': {
        marginBottom: '2rem',
      },
    },
    '@media print': {
      WebkitPrintColorAdjust: 'exact !important',
      PrintColorAdjust: 'exact !important',
    },
    '& p+p': {
      marginTop: '1rem',
    },
  },
  hidden: {
    display: 'none',
  },
}

const Report = ({ isClinicianReport = false }) => {
  const [searchParams] = useSearchParams()
  const variant = searchParams.get('variant')

  const [loadingSet, setLoadingSet] = useState(new Set())
  const [hiddenSections, setHiddenSections] = useState(new Set())
  const [hasDataSet, setHasDataSet] = useState(new Set())
  const loading = useMemo(() => loadingSet.size > 0, [loadingSet])

  const { dateRanges, selectedTimezone } = useContext(DateRangeContext)

  const { start: reportPrevStartDate, end: reportPrevEndDate } =
    dateRanges[DATE_RANGE.reportPeriod1.key] || {}
  const { start: reportRecentStartDate, end: reportRecentEndDate } =
    dateRanges[DATE_RANGE.reportPeriod2.key] || {}
  const startTime = getTimestampStartOfDay(
    reportRecentStartDate,
    selectedTimezone,
  )
  const endTime = getTimestampEndOfDay(reportRecentEndDate, selectedTimezone)
  const prevStartTime = getTimestampStartOfDay(
    reportPrevStartDate,
    selectedTimezone,
  )
  const prevEndTime = getTimestampEndOfDay(reportPrevEndDate, selectedTimezone)

  const patient = useContext(PatientContext)
  const { id: patientId } = patient || {}

  const statePatientId = useBoundStore((state) => state.patientId)
  const setStatePatientId = useBoundStore((state) => state.setPatientId)
  const resetReportState = useBoundStore((state) => state.resetReportState)

  if (patientId !== statePatientId) {
    setStatePatientId(patientId)
    resetReportState()
  }

  const addToLoadingSet = (key) =>
    setLoadingSet((prev) => new Set(prev.add(key)))

  const removeFromLoadingSet = (key) => {
    setLoadingSet((prev) => {
      const next = new Set(prev)
      next.delete(key)
      return next
    })
  }

  const addToHasDataSet = (key) =>
    setHasDataSet((prev) => new Set(prev.add(key)))

  const removeFromHasDataSet = (key) => {
    setHasDataSet((prev) => {
      const next = new Set(prev)
      next.delete(key)
      return next
    })
  }

  const saveReport = () => {
    setTimeout(() => {
      saveWithPatientName(patientName(patient).fullName())
    }, 500)
  }

  const displayMissingDataText = useMemo(
    () =>
      hasDataSet.size === 0 ||
      (hasDataSet.size === 1 && hasDataSet.has(SECTION_TITLES.MEDICATION)),
    [hasDataSet],
  )

  const dataSectionProps = {
    onLoadStart: addToLoadingSet,
    onLoadEnd: removeFromLoadingSet,
    startDate: reportRecentStartDate,
    endDate: reportRecentEndDate,
    prevStartDate: reportPrevStartDate,
    prevEndDate: reportPrevEndDate,
    startTime,
    endTime,
    prevStartTime,
    prevEndTime,
    hasDataSet,
    addToHasDataSet,
    removeFromHasDataSet,
    hiddenSections,
    saveReport,
    isClinicianReport,
    displayMissingDataText,
    variant,
  }

  return (
    <>
      <TabActionableHeader>
        <Stack sx={{ flex: 1 }}>
          <ReportDatePicker />
          <Stack alignItems="flex-end">
            <ReportSectionsToggle
              {...{
                hasDataSet,
                hiddenSections,
                isClinicianReport,
                setHiddenSections,
                variant,
              }}
            />
          </Stack>
        </Stack>
      </TabActionableHeader>
      {loading && <ReportLoader {...{ loadingSet }} />}
      <Stack
        spacing={4}
        sx={{ ...STYLES.container, ...(loading ? STYLES.hidden : {}) }}
        data-loading-report={loading}
        data-cy="report-container"
      >
        {isClinicianReport ? (
          <ReportClinician {...dataSectionProps} />
        ) : (
          <ReportPatient {...dataSectionProps} />
        )}
      </Stack>
    </>
  )
}

Report.propTypes = {
  variant: oneOf(['patient', 'clinician']),
}

export default Report
