import * as MUI from '@mui/material'
import { node, oneOf, string } from 'prop-types'
import { colors } from 'theme/colors'

const Button = ({
  children,
  size = 'medium',
  sx,
  type = 'primary',
  ...props
}) => {
  const styles = {
    '&.MuiButton-containedPrimary': {
      backgroundColor: colors.PRIMARY[1000],
      borderColor: colors.PRIMARY[1000],
      color: colors.WHITE,
    },
    '&.MuiButton-containedPrimary.Mui-disabled': {
      backgroundColor: colors.GREY[400],
      borderColor: colors.GREY[400],
      color: colors.GREY[500],
    },
    '&.MuiButton-containedPrimary&:hover': {
      backgroundColor: colors.PRIMARY[1200],
      borderColor: colors.PRIMARY[1200],
      color: colors.WHITE,
    },
    '&.MuiButton-outlinedPrimary': {
      backgroundColor: colors.WHITE,
      borderColor: colors.PRIMARY[1000],
      color: colors.PRIMARY[1000],
    },
    '&.MuiButton-outlinedPrimary.Mui-disabled': {
      backgroundColor: colors.GREY[100],
      borderColor: colors.GREY[400],
      color: colors.GREY[400],
    },
    '&.MuiButton-outlinedPrimary&:hover': {
      backgroundColor: colors.PRIMARY[300],
      borderColor: colors.PRIMARY[1000],
      color: colors.PRIMARY[1000],
    },
    '&.MuiButton-textPrimary': {
      backgroundColor: colors.TRANSPARENT,
      color: colors.PRIMARY[1000],
      padding: '4px 0',
      borderBottom: `4px solid ${colors.TRANSPARENT}`,
      borderRadius: 'unset',
    },
    '&.MuiButton-textPrimary.Mui-disabled': {
      backgroundColor: colors.TRANSPARENT,
      color: colors.GREY[400],
      padding: '4px 0',
      borderBottom: `4px solid ${colors.TRANSPARENT}`,
      borderRadius: 'unset',
    },
    '&.MuiButton-textPrimary&:hover': {
      backgroundColor: colors.TRANSPARENT,
      color: colors.BLUEBERRY[500],
      padding: '4px 0',
      borderBottom: `4px solid ${colors.PRIMARY[1200]}`,
      borderRadius: 'unset',
    },
  }

  let variant = ''
  switch (type) {
    case 'secondary':
      variant = 'outlined'
      break
    case 'tertiary':
      variant = 'text'
      break
    default:
      variant = 'contained'
  }

  return (
    <MUI.Button
      {...{
        size,
        sx: { ...styles, ...sx },
        variant,
        ...props,
      }}
    >
      {children}
    </MUI.Button>
  )
}

export default Button

Button.propTypes = {
  children: node.isRequired,
  className: string,
  size: oneOf(['large', 'medium', 'small']),
  type: oneOf(['primary', 'secondary', 'tertiary']),
}
