export const DEVICE_TYPE_APPLE_WATCH = {
  id: 'applewatch',
  displayName: 'Apple Watch',
}
export const DEVICE_TYPE_EVENTS = {
  id: 'eventsStream',
  displayName: 'Events Stream',
}
export const DEVICE_TYPE_STRIVE_STUDY = {
  id: 'strivestudy',
  displayName: 'StriveStudy',
}
