import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

export const TITLE_WIDTH = 300

const SummaryBarTitleItem = styled(Grid)(({ theme }) => ({
  width: TITLE_WIDTH,
  [theme.breakpoints.up('xl')]: {
    minWidth: TITLE_WIDTH,
    width: `calc(20% - ${theme.spacing(8)})`,
  },
}))

export default SummaryBarTitleItem
