import { makeQuerySelector } from 'query'
import { pushQuery } from '../../index'

export const SELECTED_DEVICES = 'devices'
export const SELECTED_CHANNELS = 'channels'

export const getSelectedDeviceIds = makeQuerySelector(SELECTED_DEVICES)
export const setSelectedDeviceIds = (ids) =>
  pushQuery({ [SELECTED_DEVICES]: ids })

export const SELECTED_DEVICE = 'device'
export const SELECTED_CHANNEL = 'channel'

export const getSelectedDeviceId = makeQuerySelector(SELECTED_DEVICE)
export const setSelectedDeviceId = (id) => pushQuery({ [SELECTED_DEVICE]: id })

export const getSelectedChannel = makeQuerySelector(SELECTED_CHANNEL)
export const setSelectedChannel = (id) => pushQuery({ [SELECTED_CHANNEL]: id })
