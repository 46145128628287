import { createTheme, responsiveFontSizes } from '@material-ui/core'
import {
  INFOGRAPHIC_COLORS,
  RUNE_BLACK,
  RUNE_GRAY_300,
  RUNE_GRAY_400,
  RUNE_GRAY_500,
  RUNE_GRAY_800,
  RUNE_WHITE,
  STRIVEPD_PRIMARY_300,
  STRIVEPD_PRIMARY_1000,
  STRIVEPD_PRIMARY_1100,
} from './colors'

export const clinicianThemeMUIV4 = responsiveFontSizes(
  createTheme({
    palette: {
      action: {
        hover: '#faf9f8',
      },
      primary: {
        veryLight: '#dee9f0',
        light: '#9bbbd0',
        main: STRIVEPD_PRIMARY_1000,
        dark: STRIVEPD_PRIMARY_1100,
        contrastText: '#fff',
        warning: '#ecd875',
        transparent: 'transparent',
      },
      secondary: {
        veryLight: RUNE_GRAY_300,
        light: RUNE_GRAY_400,
        main: '#706f6d',
        medium: '#706f6d',
        dark: '#343433',
        darker: RUNE_GRAY_800,
        contrastText: '#fff',
      },
      error: {
        light: '#ea827f',
        main: '#df413c',
        dark: '#8d1b17',
        contrastText: '#fff',
      },
      text: {
        primary: '#333333',
      },
      background: {
        default: '#f4f4f4',
        legend: '#fdfcfa',
      },
      white: {
        main: '#FFF',
      },
      dark: {
        main: '#333',
      },
      ...INFOGRAPHIC_COLORS,
      pink: {
        veryLight: '#fdc7d6',
        light: '#fca1ba',
        main: '#fb7b9e',
        dark: '#fa5582',
        veryDark: '#f92f66',
      },
      magenta: {
        veryLight: '#f6e2ea',
        light: '#ecc5d3',
        main: '#DE98B2',
        dark: '#cb5d86',
        veryDark: '#a4355e',
      },
      teal: {
        veryLight: '#cffeff',
        light: '#01dce0',
        main: '#01b6b9',
        dark: '#016a6c',
        veryDark: '#001d1e',
      },
      cyan: {
        veryLight: '#a1d7e3',
        light: '#65bdd1',
        main: '#3AA8C1',
        dark: '#287385',
        veryDark: '#1a4c57',
      },
      brown: {
        veryLight: '#cebfb6',
        light: '#b7a093',
        main: '#9f8170',
        dark: '#7f6455',
        veryDark: '#5c483e',
      },
      default: {
        main: '#ffffff',
      },
    },
    props: {
      MuiTable: {
        size: 'small', // why is small larger than medium?
      },
      MuiToolbar: {
        variant: 'regular',
      },
    },
    status: {
      danger: '#E7726E',
    },
    typography: {
      fontFamily: '"Work Sans", sans-serif',
      fontSize: 14,
      fontWeight: 400,
      // 1.333 - Perfect fourth (https://type-scale.com/)
      h1: {
        fontWeight: 300,
      },
      h2: {
        fontSize: '5rem',
        fontWeight: 300,
      },
      h3: {
        fontSize: '4rem',
        fontWeight: 300,
      },
      h4: {
        fontSize: '3rem',
        fontWeight: 300,
      },
      h5: {
        fontSize: '1.5rem',
        fontWeight: 400,
      },
      h6: {
        fontSize: '1.15rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      button: {
        fontFamily: '"Work Sans", sans-serif',
        fontWeight: 600,
        letterSpacing: 2,
      },
    },
    header: {
      height: '73px',
      boxShadow: 'none',
      borderBottom: 'none',
      tabs: {
        indicator: RUNE_WHITE,
      },
    },
    tableRow: {
      height: '80px',
    },
    imageIcon: {
      height: '100%',
      marginLeft: '-3px',
      marginTop: '0px',
    },
    iconRoot: {
      textAlign: 'center',
      height: '2em',
      width: '8em',
    },
    image: {
      src: 'logoWhite',
    },
    filterCell: {
      minWidth: '250px',
      position: 'relative',
      backgroundColor: '#487a9a',
      color: '#ffffff',
      top: '125px',
      right: '250px',
    },
    createEntity: {
      backgroundColor: RUNE_WHITE,
      color: RUNE_BLACK,
      justifyContent: 'space-between',
      height: '150px',
      heightSmaller: '100px',
      button: {
        backgroundColor: STRIVEPD_PRIMARY_1000,
        color: RUNE_WHITE,
        hoverBackgroundColor: STRIVEPD_PRIMARY_1100,
      },
    },
    taskNotifications: {
      button: {
        backgroundColor: RUNE_WHITE,
        spacing: '0 0 0 24px',
        hoverColor: STRIVEPD_PRIMARY_300,
        color: STRIVEPD_PRIMARY_1000,
        topMargin: 0,
      },
    },
    taskNotificationsMiniLabel: {
      color: '#706f6d',
      fontSize: '0.75rem',
      paddingTop: '0.5rem',
    },
    overrides: {
      MuiPaper: {
        elevation1: {
          boxShadow: 'none',
        },
      },
      MuiButton: {
        root: {
          borderRadius: '8px',
        },
        textSizeSmall: {
          padding: '4px 10px',
          alignSelf: 'end',
        },
      },
      MuiCardHeader: {
        subheader: {
          color: '#2d2d2b',
          fontSize: '.85rem',
          fontWeight: '500',
        },
      },
      MuiBreadcrumbs: {
        ol: {
          color: '#2d2d2b',
        },
        li: {
          color: '#2d2d2b',
        },
      },
      MuiRadio: {
        root: {
          color: '#487a9a',
        },
        colorSecondary: {
          '&$checked': {
            color: '#487a9a',
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          color: RUNE_WHITE,
        },
        colorSecondary: {
          '&$checked': {
            color: RUNE_WHITE,
            '& + $track': {
              opacity: 1,
              backgroundColor: STRIVEPD_PRIMARY_1000,
              borderColor: STRIVEPD_PRIMARY_1000,
            },
          },
          '&$disabled': {
            color: RUNE_GRAY_300,
          },
        },
        track: {
          opacity: 1,
          backgroundColor: RUNE_GRAY_500,
        },
      },
    },
    patientListIcon: {
      fill: '#333333',
    },
  }),
)
