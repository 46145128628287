import { useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useLogger } from '../../../domains/logger/context'

/**
 * Split button for downloading the supported form(s) of the visualized data.
 */
export const DownloadAction = ({ device, options, patient }) => {
  const logger = useLogger()
  const setIFrame = useState(null)[1]
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  /**
   * Checks to make sure the iFrame URL begins with 'http'
   *
   * @param {string} url The download URL for the iFrame
   */
  const validURL = (url) => {
    const urlRegEx = new RegExp('^https:')
    return urlRegEx.test(url)
  }

  const handleClick = () => {
    /*
     * Download the dataset
     */
    const friendlyPatientName = patient.codeName.replace(
      /[^a-zA-Z0-9]+/gim,
      '-',
    )
    const friendlyDeviceName = device.alias.replace(/[^a-zA-Z0-9]+/gim, '-')
    const filenamePrefix = `${friendlyPatientName}_${friendlyDeviceName}`

    options[selectedIndex]
      .downloadUrl({
        filenamePrefix,
      })
      .then(
        (url) => {
          setIFrame((iframe) => {
            if (iframe) {
              iframe.remove()
            }

            if (validURL(url)) {
              let newIFrame = document.createElement('iframe')
              newIFrame.setAttribute('style', 'display: none;')
              newIFrame.setAttribute('src', url)
              document.body.appendChild(newIFrame)
              return newIFrame
            } else {
              logger.error('Invalid download url supplied: ' + url)
              return
            }
          })
        },
        (error) => {
          console.error(error)
          throw error
        },
      )
  }

  const handleMenuItemClick = (event, index) => {
    /*
     * Select a new download option and close
     */
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    /*
     * Toggle the dropdown options open/closed
     */
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    /*
     * Close the dropdown on click-away
     */
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <Grid item>
      <ButtonGroup color="primary" ref={anchorRef} variant="outlined">
        <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
        <Button onClick={handleToggle} size="small">
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        open={open}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  )
}

export default DownloadAction
