import { useState, useCallback, useReducer } from 'react'
import DefaultLayout from 'ui/components/DefaultLayout'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { TaskNotificationContext } from 'ui/contexts'
import TaskNotificationsHeader from './TaskNotificationsHeader'
import TasksTable from './TasksTable'
import {
  TASK_RECURRENCE_ONCE,
  TASK_REPEATS_ON,
  TASK_SCHEDULE_SPECIFIC,
  TASK_ACTION_NONE,
  DAYS_IN_WEEK,
} from './consts'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '2rem',
  },
  tasksContainer: {
    minHeight: '500px',
  },
}))

/**
 * The main TaskNotifications page. Allows you to schedule new tasks and see and
 * edit existing tasks.
 *
 * @returns {JSX.Element} Task Notifications parent component. All Task Notification components are descendants of this component.
 */
export const TaskNotifications = () => {
  const classes = useStyles()
  // column 1 state
  const [taskActions, setTaskActions] = useState(TASK_ACTION_NONE)
  const [taskExternalLinkTitle, setTaskExternalLinkTitle] = useState('')
  const [taskExternalLinkURL, setTaskExternalLinkURL] = useState('')

  const onTaskActionsChange = useCallback(
    (e) => setTaskActions(e.target.value),
    [setTaskActions],
  )

  const onTaskExternalLinkTitleChange = useCallback(
    (e) => setTaskExternalLinkTitle(e.target.value),
    [setTaskExternalLinkTitle],
  )

  const onTaskExternalLinkURLChange = useCallback(
    (e) => setTaskExternalLinkURL(e.target.value),
    [setTaskExternalLinkURL],
  )

  // column 1 state reducer
  const initialFirstColumnState = {
    taskTitle: '',
    taskSummary: '',
    taskDescription: '',
  }

  const firstColumnActions = {
    SET_TASK_TITLE: 'setTaskTitle',
    SET_TASK_SUMMARY: 'setTaskSummary',
    SET_TASK_DESCRIPTION: 'setTaskDescription',
  }

  const initialFirstColumnReducer = useCallback(
    (state, action) => {
      switch (action.type) {
        case firstColumnActions.SET_TASK_TITLE:
          return {
            ...state,
            [action.field]: action.payload,
          }
        case firstColumnActions.SET_TASK_SUMMARY:
          return {
            ...state,
            [action.field]: action.payload,
          }
        case firstColumnActions.SET_TASK_DESCRIPTION:
          return {
            ...state,
            [action.field]: action.payload,
          }
        default:
          throw new Error('Form Set Error in initialFirstColumnReducer')
      }
    },
    [
      firstColumnActions.SET_TASK_TITLE,
      firstColumnActions.SET_TASK_SUMMARY,
      firstColumnActions.SET_TASK_DESCRIPTION,
    ],
  )

  const [state, dispatch] = useReducer(
    initialFirstColumnReducer,
    initialFirstColumnState,
  )

  // column 2 state
  const [taskRecurrence, setTaskRecurrence] = useState(TASK_RECURRENCE_ONCE)
  const [singleTaskDate, setSingleTaskDate] = useState('')
  const [singleTaskTime, setSingleTaskTime] = useState('')
  const onTaskRecurrenceChange = useCallback(
    (e) => setTaskRecurrence(e.target.value),
    [setTaskRecurrence],
  )
  const onSingleTaskDateChange = useCallback(
    (e) => setSingleTaskDate(e),
    [setSingleTaskDate],
  )
  const onSingleTaskTimeChange = useCallback(
    (e) => setSingleTaskTime(e),
    [setSingleTaskTime],
  )
  // column 2 state - recurring task schedule
  const [recurringTaskStartDate, setRecurringTaskStartDate] = useState('')
  const [recurringTaskDoesEnd, setRecurringTaskDoesEnd] = useState('true')
  const [recurringTaskEndDate, setRecurringTaskEndDate] = useState('')
  const onRecurringTaskStartDate = useCallback(
    (e) => setRecurringTaskStartDate(e),
    [setRecurringTaskStartDate],
  )
  const onRecurringTaskDoesEnd = useCallback(
    (e) => setRecurringTaskDoesEnd(e.target.value),
    [setRecurringTaskDoesEnd],
  )
  const onRecurringTaskEndDate = useCallback(
    (e) => setRecurringTaskEndDate(e),
    [setRecurringTaskEndDate],
  )

  // column 2 state - Task Frequency
  const [taskRepeatsWeekly, setTaskRepeatsWeekly] = useState(TASK_REPEATS_ON)
  const [taskRepeatDaysArray, setTaskRepeatDaysArray] = useState([])
  const [taskRepeatDaysInteger, setTaskRepeatDaysInteger] =
    useState(DAYS_IN_WEEK)
  const onTaskRepeatsWeeklyChange = useCallback(
    (e) => setTaskRepeatsWeekly(e.target.value),
    [setTaskRepeatsWeekly],
  )
  const onTaskRepeatDaysArrayChange = useCallback(
    (newDay) => {
      if (taskRepeatDaysArray.includes(newDay)) {
        // if it is in the list, filter it out
        setTaskRepeatDaysArray(
          taskRepeatDaysArray.filter((day) => day !== newDay),
        )
      } else {
        // if it is not in the list, add it
        setTaskRepeatDaysArray((taskRepeatDaysArray) => [
          ...taskRepeatDaysArray,
          newDay,
        ])
      }
    },
    [taskRepeatDaysArray, setTaskRepeatDaysArray],
  )
  const onTaskRepeatDaysIntegerChange = useCallback(
    (e) => setTaskRepeatDaysInteger(e.target.value),
    [setTaskRepeatDaysInteger],
  )
  // column 2 state - Task Frequency Schedule
  const [taskScheduleRandom, setTaskScheduleRandom] = useState(
    TASK_SCHEDULE_SPECIFIC,
  )
  const [taskScheduleTimesArray, setTaskScheduleTimesArray] = useState([])
  const onTaskScheduleRandomChange = useCallback(
    (e) => setTaskScheduleRandom(e.target.value),
    [setTaskScheduleRandom],
  )
  const onTaskScheduleTimesArrayChange = useCallback(
    (newTime) => {
      if (taskScheduleTimesArray.includes(newTime)) {
        // if it is in the list, filter it out
        setTaskScheduleTimesArray(
          taskScheduleTimesArray.filter((time) => time !== newTime),
        )
      } else {
        // if it is not in the list, add it
        setTaskScheduleTimesArray((taskScheduleTimesArray) => [
          ...taskScheduleTimesArray,
          newTime,
        ])
      }
    },
    [taskScheduleTimesArray, setTaskScheduleTimesArray],
  )

  // patient list state
  const [taskPatientList, setTaskPatientList] = useState([])
  const onTaskPatientListCheck = useCallback(
    (selectedID) => {
      if (taskPatientList.includes(selectedID)) {
        // if it is in the list, filter it out
        setTaskPatientList(taskPatientList.filter((id) => id !== selectedID))
      } else {
        // if it is not in the list, add it
        setTaskPatientList((taskPatientList) => [
          ...taskPatientList,
          selectedID,
        ])
      }
    },
    [taskPatientList, setTaskPatientList],
  )
  const onTaskPatientListSelectAll = useCallback(
    (allPatients) => {
      if (taskPatientList.length === allPatients.length) {
        // if all IDs are selected, unselect all
        setTaskPatientList([])
      } else {
        // if not, then select all
        const allPatientIDs = allPatients.map((patient) => patient.id)
        setTaskPatientList(allPatientIDs)
      }
    },
    [taskPatientList, setTaskPatientList],
  )

  const clearAllFields = () => {
    dispatch({
      type: 'setTaskTitle',
      field: 'taskTitle',
      payload: '',
    })
    dispatch({
      type: 'setTaskSummary',
      field: 'taskSummary',
      payload: '',
    })
    dispatch({
      type: 'setTaskDescription',
      field: 'taskDescription',
      payload: '',
    })
    setTaskRecurrence(TASK_RECURRENCE_ONCE)
    setSingleTaskDate('')
    setSingleTaskTime('')
    setRecurringTaskStartDate('')
    setRecurringTaskDoesEnd('true')
    setRecurringTaskEndDate('')
    setTaskRepeatsWeekly(TASK_REPEATS_ON)
    setTaskRepeatDaysArray([])
    setTaskRepeatDaysInteger(DAYS_IN_WEEK)
    setTaskScheduleRandom(TASK_SCHEDULE_SPECIFIC)
    setTaskScheduleTimesArray([])
    setTaskPatientList([])
  }

  const taskNotificationContextValue = {
    taskTitle: state.taskTitle,
    taskSummary: state.taskSummary,
    taskDescription: state.taskDescription,
    taskActions,
    taskExternalLinkTitle,
    taskExternalLinkURL,
    onTaskTitleChange: dispatch,
    onTaskSummaryChange: dispatch,
    onTaskDescriptionChange: dispatch,
    onTaskActionsChange,
    onTaskExternalLinkTitleChange,
    onTaskExternalLinkURLChange,
    taskRecurrence,
    singleTaskDate,
    singleTaskTime,
    onTaskRecurrenceChange,
    onSingleTaskDateChange,
    onSingleTaskTimeChange,
    recurringTaskStartDate,
    recurringTaskDoesEnd,
    recurringTaskEndDate,
    onRecurringTaskStartDate,
    onRecurringTaskDoesEnd,
    onRecurringTaskEndDate,
    taskRepeatsWeekly,
    taskRepeatDaysArray,
    taskRepeatDaysInteger,
    onTaskRepeatsWeeklyChange,
    onTaskRepeatDaysArrayChange,
    onTaskRepeatDaysIntegerChange,
    taskScheduleRandom,
    taskScheduleTimesArray,
    onTaskScheduleRandomChange,
    onTaskScheduleTimesArrayChange,
    taskPatientList,
    onTaskPatientListCheck,
    onTaskPatientListSelectAll,
    setTaskTitle: dispatch,
    setTaskSummary: dispatch,
    setTaskDescription: dispatch,
    setTaskRecurrence,
    setSingleTaskDate,
    setSingleTaskTime,
    setRecurringTaskStartDate,
    setRecurringTaskDoesEnd,
    setRecurringTaskEndDate,
    setTaskRepeatsWeekly,
    setTaskRepeatDaysArray,
    setTaskRepeatDaysInteger,
    setTaskScheduleRandom,
    setTaskScheduleTimesArray,
    setTaskPatientList,
    clearAllFields,
  }

  return (
    <DefaultLayout>
      <TaskNotificationContext.Provider value={taskNotificationContextValue}>
        <ErrorBoundary>
          <div
            className={classes.root}
            data-testid="taskNotifications"
            id="taskNotifications"
          >
            <Paper className={classes.tasksContainer}>
              <TaskNotificationsHeader />
              <TasksTable />
            </Paper>
          </div>
        </ErrorBoundary>
      </TaskNotificationContext.Provider>
    </DefaultLayout>
  )
}

export default TaskNotifications
