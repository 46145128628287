import { Fragment, useCallback, useState } from 'react'
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { Error, Success, NotificationBar } from 'ui/components/Notifications'
import { isSameHour } from 'utilities/time'
import { MoreVert } from '@material-ui/icons'
import { useConfirm } from 'material-ui-confirm'
import { useDeleteMembership, useResendMembership } from '../mutations'
import { useRefetchUsers } from '../queries'
import { TABLE_STATUS } from './UserRow'

/**
 * UI component for removing user or resend invitation functionality
 *
 * @param {string} displayName the user's display name
 * @param {string} email the user's email
 * @param {string} membershipId the user's ID
 * @param {object} status the status object on the user
 *
 * @returns {React.Component} the UI component
 */
export const UserStatusToggle = ({
  displayName,
  email,
  membershipId,
  status,
}) => {
  const confirm = useConfirm()

  // shows success of resend invitation request, is reset to false after displaying an error
  const [success, setSuccess] = useState()

  // shows success of resend invitation request, is reset to false after displaying an error
  const [message, setMessage] = useState('')

  // Error for all requests, is reset to false after displaying an error
  const [error, setError] = useState()

  // Used for <Menu>
  const [anchorEl, setAnchorEl] = useState(null)

  // Used for removing a user from org
  const [deleteMembership, { loading }] = useDeleteMembership({
    onError: (error) => {
      console.error(error)
      setError(error)
    },
    onCompleted: () => {
      setMessage('Successfully removed user from this organization')
      setSuccess(true)
    },
    refetchQueries: [useRefetchUsers()],
    awaitRefetchQueries: true,
  })
  const [resendMembership, { loading: resendLoading }] = useResendMembership({
    onError: (error) => {
      console.error(error)
      setError(error)
    },
    onCompleted: () => {
      setMessage('Successfully sent invitation')
      setSuccess(true)
    },
    refetchQueries: [useRefetchUsers()],
    awaitRefetchQueries: true,
  })

  /**
   * Opens the <Menu> component to display options
   *
   * @param {event} event the click event
   */
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  /**
   * Closes the <Menu> component
   */
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  /**
   * Calls the deleteMembership endpoint to remove a user from an org
   *
   * @param {boolean} disabled used to denote being removed from an org
   */
  const deleteUserMembership = useCallback(async () => {
    const disabled = true
    await deleteMembership({
      variables: {
        input: { disabled, membershipId },
      },
    })
  }, [membershipId, deleteMembership])

  /**
   * Calls the resendMembership endpoint to resend an invitation, if the last sent
   * invitation was prior to one hour
   *
   * @param {boolean} disabled used to denote between disabled or enabled user
   *
   * @returns Promise
   */
  const triggerResend = useCallback(async () => {
    await resendMembership({
      variables: {
        input: { membershipId },
      },
    })
  }, [membershipId, resendMembership])

  /**
   * Sets error to false when the error message disappears or is dismissed
   *
   * @returns {void} void
   */
  const handleCloseError = () => {
    setError(false)
  }

  /**
   * Sets success to false when the success message disappears or is dismissed
   *
   * @returns {void} void
   */
  const handleCloseSuccess = () => {
    setSuccess(false)
  }

  /**
   * Displays a confirmation dialog, then closes the menu and triggers
   * deleteUserMembership() to delete the user from the org
   */
  const removeUser = useCallback(async () => {
    await confirm({
      confirmationButtonProps: { variant: 'contained' },
      title: 'Remove User',
      description: (
        <Fragment>
          Are you sure you wish to remove
          <strong> {displayName || email} </strong>
          from this organization?
        </Fragment>
      ),
    })
    handleClose()
    deleteUserMembership()
  }, [confirm, displayName, email, deleteUserMembership, handleClose])

  /**
   * Displays a confirmation dialog, then closes the menu and triggers
   * triggerResend to resend an invitation
   */
  const resendInvite = useCallback(async () => {
    await confirm({
      confirmationButtonProps: { variant: 'contained' },
      title: 'Resend Invitation',
      description: (
        <Fragment>
          Are you sure you wish to resend the invitation for
          <strong> {displayName || email}</strong>?
          <br />
          You can only resend this invitation once every hour.
        </Fragment>
      ),
    })
    handleClose()
    triggerResend()
  }, [confirm, displayName, email, triggerResend, handleClose])

  /**
   * Conditionally renders an enabled or disabled option to resend an invitation,
   * based on whether the last invitation was sent in the last hour
   *
   * @returns {React.Component} the UI component
   */
  function renderInvitedOptions() {
    if (isSameHour(status.invitedAt)) {
      return (
        <Tooltip title="You can only resend an invitation once every hour">
          <div>
            <MenuItem disabled={true}>Re-send Invitation</MenuItem>
          </div>
        </Tooltip>
      )
    } else {
      return (
        <MenuItem onClick={resendInvite} disabled={resendLoading}>
          Re-send Invitation
        </MenuItem>
      )
    }
  }

  return (
    <Box display="flex">
      <Tooltip title="More Options">
        <IconButton
          data-cy="team-more-options-button"
          aria-label={`more options for user ${email}`}
          aria-controls={`more-options-user-${membershipId}`}
          aria-haspopup="true"
          disabled={loading}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>

      <Menu
        id={`more-options-user-${membershipId}`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {(status.displayName === TABLE_STATUS.active ||
          status.displayName === TABLE_STATUS.invited) && (
          <MenuItem onClick={removeUser} disabled={loading}>
            Revoke Membership
          </MenuItem>
        )}

        {status.displayName === TABLE_STATUS.invited && renderInvitedOptions()}
      </Menu>

      {error && (
        <NotificationBar open={!!error} onClose={handleCloseError}>
          <Error
            message="Unable to update User status"
            onClose={handleCloseError}
          />
        </NotificationBar>
      )}

      {success && (
        <NotificationBar open={!!success} onClose={handleCloseSuccess}>
          <Success message={message} onClose={handleCloseSuccess} />
        </NotificationBar>
      )}
    </Box>
  )
}

export default UserStatusToggle
