const eventsSlice = (set) => ({
  eventList: [],
  topSymptomEventCounts: [],
  eventCountLoading: null,
  loadingEvents: null,
  eventCountError: null,
  errorEvents: null,
})

export default eventsSlice
