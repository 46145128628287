import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import {
  RESEARCHER_PORTAL_PRIMARY_GREEN,
  RUNE_GRAY_400,
  RUNE_WHITE,
  STRIVEPD_PRIMARY_1000,
  STRIVEPD_PRIMARY_900,
  colors,
} from './colors'

let researcherTheme = createTheme({
  palette: {
    primary: {
      main: RESEARCHER_PORTAL_PRIMARY_GREEN,
      contrastText: RUNE_WHITE,
      transparent: 'transparent',
    },
  },
  typography: {
    fontFamily: '"IBM Plex Sans", sans-serif',
    fontSize: 14,
    fontWeight: 400,
    color: colors.BLACK,
    head42: {
      fontSize: '2.625rem',
      fontWeight: 400,
    },
    head42B: {
      fontSize: '2.625rem',
      fontWeight: 600,
    },
    head42L: {
      fontSize: '2.625rem',
      fontWeight: 300,
    },
    head24: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    head24B: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    head24L: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    head20: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    head20B: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    head20L: {
      fontSize: '1.25rem',
      fontWeight: 300,
    },
    head18: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    head18B: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    body16: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body16B: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    body14: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    body14B: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    body12: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    body12B: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    body10: {
      fontSize: '0.625rem',
      fontWeight: 500,
    },
    body10B: {
      fontSize: '0.625rem',
      fontWeight: 600,
    },
    caps14: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: '0.05rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps14B: {
      fontSize: '0.875rem',
      fontWeight: 600,
      letterSpacing: '0.05rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps12: {
      fontSize: '0.75rem',
      fontWeight: 500,
      letterSpacing: '0.1rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    caps12B: {
      fontSize: '0.75rem',
      fontWeight: 600,
      letterSpacing: '0.1rem',
      lineHeight: '140%',
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: 600,
      letterSpacing: 2,
    },
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          minWidth: 72,
          fontSize: '1.25rem',
          fontWeight: 400,
          color: STRIVEPD_PRIMARY_900,
          '&:hover': {
            color: STRIVEPD_PRIMARY_1000,
            fontWeight: 600,
          },
          '&.Mui-selected': {
            color: STRIVEPD_PRIMARY_1000,
            fontWeight: 600,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        variant: 'scrollable',
      },
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${RUNE_GRAY_400}`,
          background: RUNE_WHITE,
          '& .MuiTabs-indicatorSpan': {
            backgroundColor: STRIVEPD_PRIMARY_1000,
          },
        },
      },
    },
  },
})
researcherTheme = responsiveFontSizes(researcherTheme)

export default researcherTheme
