import { useCallback, useState } from 'react'
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined'
import CopyDeviceIdDialog from 'ui/components/CopyDeviceIdDialog'
import StreamMenu from '../Browse/StreamSelector/StreamItem/StreamMenu'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  control: {
    marginRight: theme.spacing(3),
  },
  dragIcon: {
    marginRight: theme.spacing(3),
    cursor: 'grab',
    opacity: 0.5,
    '&:hover': { backgroundColor: 'transparent' },
  },
  newStreamIcon: {
    opacity: 0,
    cursor: 'default',
  },
  dropdown: {
    width: 240,
  },
  icon: {
    marginTop: theme.spacing(2),
  },
}))

/**
 * Individual list item for device to display in Explore view. Can be changed
 * using a select and the replaceSelected function
 *
 * @param {integer} index The index of the selected device
 * @param {function} setSelected Function that adds/removes devices
 * @param {function} replaceSelected Function that updates devices in place
 * @param {object} stream The device stream displayed
 * @param {object} deviceOptions Unselected Devices
 */
const StreamItem = ({
  index,
  setSelected,
  replaceSelected,
  stream,
  deviceOptions,
}) => {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)
  const streamId = stream && stream.id ? stream.id : ''
  /**
   * Replaces the current device stream with a newly selected one
   *
   * @param {event} event The change event for the newly selected device stream
   */
  const handleDeviceChange = useCallback(
    (event) => {
      const newDeviceID = event.target.value
      replaceSelected(streamId, newDeviceID)
    },
    [replaceSelected, streamId],
  )
  if (!stream) {
    return null
  }
  const combinedDeviceOptions = [
    { name: stream.alias, value: stream.id },
    ...deviceOptions,
  ]

  return (
    <Box display="flex" alignItems="center" p={1}>
      <Box>
        <FormControl className={classes.control}>
          <InputLabel>Device</InputLabel>
          <Select
            classes={{ select: classes.dropdown }}
            onChange={handleDeviceChange}
            value={stream.id}
          >
            {combinedDeviceOptions.map((device) => (
              <MenuItem key={device.value} value={device.value}>
                {device.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <IconButton
        className={classes.icon}
        label="Remove stream"
        onClick={() => {
          setSelected(stream.id)
        }}
      >
        <BackspaceOutlinedIcon />
      </IconButton>
      <StreamMenu id={stream.id} openCopyModal={() => setOpenModal(true)} />
      {openModal && (
        <CopyDeviceIdDialog
          device={stream}
          open={openModal}
          handleClose={() => setOpenModal(false)}
        />
      )}
    </Box>
  )
}

/**
 * Blank device stream item with options for devices to add.
 *
 * @param {function} setSelected Function that adds devices
 * @param {object} deviceOptions Unselected Devices
 */
export const NewStreamItem = ({ deviceOptions, setSelected }) => {
  const classes = useStyles()

  /**
   * Adds a newly selected device stream
   *
   * @param {event} event The change event for the newly selected device stream
   */
  const handleDeviceChange = useCallback(
    (event) => {
      setSelected(event.target.value)
    },
    [setSelected],
  )
  return (
    <Box p={1}>
      <FormControl className={classes.control}>
        <InputLabel>Add stream</InputLabel>
        <Select
          classes={{ select: classes.dropdown }}
          onChange={handleDeviceChange}
          value={''}
        >
          {deviceOptions.map((device) => (
            <MenuItem key={device.value} value={device.value}>
              {device.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default StreamItem
