import { FormControl, MenuItem, Input, Select, ListItem } from '@mui/material'
import { colors } from 'theme/colors'
import { Stack, Text } from 'ui/baseComponents'
import CheckboxPrimary from 'ui/components/Checkbox/CheckboxPrimary'

const DailyCategoryFilter = ({
  title,
  isCategoryActive,
  onToggleCategory,
  initialFilters = new Set(),
  activeFilters = [],
  onChange,
  disableFilter,
}) => {
  const showDropdown = initialFilters.size > 0
  const hasActiveFilters = activeFilters.length > 0
  const sortedInitialFilters = [...initialFilters].sort()
  const disabled = disableFilter || (showDropdown && !hasActiveFilters)

  return (
    <FormControl>
      <ListItem>
        <Stack>
          <Stack direction="row" alignItems="center" spacing={0}>
            <CheckboxPrimary
              checked={isCategoryActive}
              onChange={onToggleCategory}
              disabled={disabled}
            />
            <Text variant="body16" color={disabled ? colors.GREY[600] : null}>
              {title}
            </Text>
          </Stack>
          {showDropdown && (
            <Select
              multiple
              input={
                <Input
                  label={title}
                  style={{
                    marginLeft: '.75rem',
                    fontFamily: 'inherit',
                  }}
                />
              }
              value={activeFilters}
              onChange={onChange}
              renderValue={(selected) => selected.join(', ')}
              sx={{ width: '20rem', marginLeft: '2.7rem !important' }}
            >
              {sortedInitialFilters.map((filter) => (
                <MenuItem key={filter} value={filter}>
                  <CheckboxPrimary checked={activeFilters?.includes(filter)} />
                  <Text variant="body16">{filter}</Text>
                </MenuItem>
              ))}
            </Select>
          )}
        </Stack>
      </ListItem>
    </FormControl>
  )
}

export default DailyCategoryFilter
