import { Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { useCallback } from 'react'
import { useDeviceTypes } from './queries'

const DeviceTypeWrapper = (props) => {
  const { error, deviceTypes = [] } = useDeviceTypes()
  if (error) {
    console.error(error)
  }
  return <DeviceTypePicker deviceTypes={deviceTypes} {...props} />
}

const DeviceTypePicker = ({
  error,
  loading,
  deviceTypes,
  deviceType,
  setDeviceType,
  ...rest
}) => {
  const handleChange = useCallback(
    (e) => setDeviceType(deviceTypes.find((d) => d.id === e.target.value)),
    [deviceTypes, setDeviceType],
  )
  const value = deviceTypes.length && deviceType ? deviceType.id : ''
  return (
    <Select
      id="deviceType"
      data-cy="deviceType"
      error={!!error}
      disabled={loading}
      value={value}
      onChange={handleChange}
      {...rest}
    >
      {deviceTypes.map(({ id, displayName }) => (
        <MenuItem key={id} value={id}>
          {displayName}
        </MenuItem>
      ))}
    </Select>
  )
}

export default DeviceTypeWrapper
