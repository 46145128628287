import { dateFloat } from '../utils'

/**
 * WARNING!!! Do NOT import constants or anything from React modules!
 * This will mess up our loading order and Jest mocking, break our `mockFlags()`
 */

const membershipExpired = {
  user: {
    id: 'user1',
    displayName: 'Bugs Bunny',
    username: 'userone',
    email: 'user.one@runelabs.test.io',
    invitedAt: dateFloat('2019-06-01 13:00:00'),
  },
  status: {
    disabledAt: null,
    disabledBy: null,
    displayName: 'INVITED',
    invitedAt: 1596492385.358,
    invitedBy: {
      user: {
        id: 'user-porky,user',
        displayName: 'Porky Pig',
      },
    },
    joinedAt: null,
  },
  disabled: false,
  admin: false,
  id: 'membership-1',
  role: {
    admin: false,
    displayName: 'Member',
    canSeePHI: false,
  },
}
const membershipInvited = {
  user: {
    id: 'user2',
    displayName: 'Daffy Duck',
    username: 'usertwo',
    email: 'user.two@runelabs.test.io',
    invitedAt: dateFloat('2019-09-01 12:00:00'),
  },
  status: {
    disabledAt: null,
    disabledBy: null,
    displayName: 'INVITED',
    invitedAt: 1596492385.358,
    invitedBy: {
      user: {
        id: 'user-porky,user',
        displayName: 'Porky Pig',
      },
    },
    joinedAt: null,
  },
  disabled: false,
  admin: true,
  id: 'membership-2',
  role: {
    admin: true,
    displayName: 'Admin',
    canSeePHI: false,
  },
}
const membershipConfirmed = {
  user: {
    id: 'user3',
    displayName: 'Elmer Fudd',
    username: 'userthree',
    email: 'user.three@runelabs.test.io',
    invitedAt: dateFloat('2019-06-01 13:00:00'),
  },
  status: {
    disabledAt: null,
    disabledBy: null,
    displayName: 'INVITED',
    invitedAt: 1596492385.001,
    invitedBy: {
      user: {
        id: 'user-sam,user',
        displayName: 'Yosemite Sam',
      },
    },
    joinedAt: 1596492385.358,
  },
  disabled: false,
  admin: false,
  id: 'membership-3',
  role: {
    admin: false,
    displayName: 'Member',
    canSeePHI: false,
  },
}
export const memberships = [
  membershipExpired,
  membershipInvited,
  membershipConfirmed,
]
