import { useEffect } from 'react'
import BarChart from 'ui/baseComponents/Charts/BarChart'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'
import ReportChartWithTitle from '../../components/ReportChartWithTitle'
import { colors } from 'theme/colors'
import { summedArray } from '../../helpers'
import {
  DATA_ATTRIBUTES,
  NO_ACTIVITY_TIME,
  PERIODS,
} from 'ui/clinicianScreens/Patient/Report/consts'
import ReportMetricTrendIcon from '../../components/ReportMetricTrendIcon'
import { LabelList } from 'recharts'
import {
  calculateWeeklyActivityDurations,
  countWeeksAtOrOverTarget,
  getAveragePerWeek,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/helpers'

export const ReportChartActivityGoal = ({
  currentData,
  startTime,
  prevData,
  prevStartTime,
  updateAttributesForPlaywrightScript,
}) => {
  const STYLES = {
    chartsContainer: {
      paddingTop: '10px',
      paddingBottom: '20px',
      '& .recharts-reference-line .recharts-label': {
        transform: 'translate(-200px, 0)',
        fill: colors.LIME[700],
        fontWeight: 700,
      },
      '& .recharts-label-list:not(:last-child) .recharts-label': {
        fill: colors.BLACK,
        fontWeight: 700,
      },
      '& .recharts-xAxis .recharts-label': {
        fill: colors.BLACK,
      },
    },
    chartContainer: {
      position: 'relative',
      flex: 1,
    },
    averagePerWeekText: {
      position: 'absolute',
      top: -12,
      left: 25,
    },
  }

  const { activity: activityDataAttributes } = DATA_ATTRIBUTES
  const {
    goalChartEmptyAttribute,
    goalChartPreviousAttribute,
    goalChartRecentAttribute,
  } = activityDataAttributes

  const currentWeeklyActivityDurations = calculateWeeklyActivityDurations(
    currentData,
    startTime,
  )
  const prevWeeklyActivityDurations = calculateWeeklyActivityDurations(
    prevData,
    prevStartTime,
  )

  const findHighestActivityDuration = (currentDurations, prevDurations) => {
    const allDurations = [...currentDurations, ...prevDurations]
    return Math.max(...allDurations)
  }

  const yAxisMax = findHighestActivityDuration(
    currentWeeklyActivityDurations,
    prevWeeklyActivityDurations,
  )

  const weeksAtOrOverTargetCurrent = countWeeksAtOrOverTarget(
    currentWeeklyActivityDurations,
  )

  const weeksAtOrOverTargetPrev = countWeeksAtOrOverTarget(
    prevWeeklyActivityDurations,
  )

  const currentTotalDurationInMinutes = summedArray(
    currentWeeklyActivityDurations,
  )

  const prevTotalDurationInMinutes = summedArray(prevWeeklyActivityDurations)

  const currentAveragePerWeek = getAveragePerWeek(currentTotalDurationInMinutes)

  const prevAveragePerWeek = `${Math.floor(prevTotalDurationInMinutes / 4)}m`

  const noData = !currentData.length

  const hasTrendChange = weeksAtOrOverTargetCurrent !== weeksAtOrOverTargetPrev
  const trend = weeksAtOrOverTargetCurrent > weeksAtOrOverTargetPrev
  const hasActivityDuration = currentTotalDurationInMinutes > 0

  useEffect(() => {
    if (hasActivityDuration) {
      updateAttributesForPlaywrightScript([
        goalChartRecentAttribute.playwrightScriptSelector,
        goalChartPreviousAttribute.playwrightScriptSelector,
      ])
    } else {
      updateAttributesForPlaywrightScript([
        goalChartEmptyAttribute.playwrightScriptSelector,
      ])
    }
  }, [hasActivityDuration]) // eslint-disable-line react-hooks/exhaustive-deps

  const Trend = () => (
    <Stack direction="row">
      {hasActivityDuration ? (
        <>
          <Text variant="body16B">
            Achieved: {weeksAtOrOverTargetCurrent}{' '}
            {weeksAtOrOverTargetCurrent === 1 ? 'week' : 'weeks'}
          </Text>
          {hasTrendChange && (
            <ReportMetricTrendIcon
              direction={trend ? 'up' : 'down'}
              variant={trend ? 'better' : 'worse'}
            />
          )}
          <Text variant="body16">
            Previous period: {weeksAtOrOverTargetPrev}{' '}
            {weeksAtOrOverTargetPrev === 1 ? 'week' : 'weeks'}
          </Text>
        </>
      ) : (
        // data-goal-chart-empty is used by script for automated reports
        <Text variant="body16" data-goal-chart-empty>
          {NO_ACTIVITY_TIME}
        </Text>
      )}
    </Stack>
  )

  const GoalChart = ({ legendItem, dataValue, weeklyAverage, chartId }) => (
    <div style={STYLES.chartContainer}>
      <BarChart
        {...{
          height: 200,
          legend: true,
          legendItem,
          barSize: 50,
          showBarLabels: true,
          xAxisLabel: 'Total minutes of activities per week',
          xAxisInterval: 1,
          yAxisInterval: 100,
          yAxisMax: yAxisMax < 150 ? 150 : yAxisMax,
          hideGridLines: true,
          hideYAxis: true,
          isAnimationActive: false,
          data: [
            {
              [legendItem.name]: dataValue[0],
              weekLabel: 'w1',
            },
            {
              [legendItem.name]: dataValue[1],
              weekLabel: 'w2',
            },
            {
              [legendItem.name]: dataValue[2],
              weekLabel: 'w3',
            },
            {
              [legendItem.name]: dataValue[3],
              weekLabel: 'w4',
            },
          ],
          referenceLineValue: 150,
          referenceLineLabel: '150m',
          secondaryBarLabels: (
            <LabelList dataKey="weekLabel" position="bottom" offset={25} />
          ),
          margin: { top: 25, right: 16, left: 60, bottom: 40 },
          barRadius: [6, 6, 0, 0],
          chartId: `activity-goal-chart-${chartId}`,
        }}
      />
      <Text variant="body16" sx={STYLES.averagePerWeekText}>
        Avg/Week: <Text variant="body16B">{weeklyAverage}</Text>
      </Text>
    </div>
  )

  return (
    <ReportChartWithTitle
      {...{
        noData,
        title: 'Goal',
        subtitle: hasActivityDuration && '150 minutes per week',
        trend: <Trend />,
      }}
    >
      {hasActivityDuration && (
        <>
          <Stack
            direction="row"
            spacing={5}
            alignItems="center"
            sx={STYLES.chartsContainer}
          >
            <GoalChart
              legendItem={{
                name: PERIODS.recent,
                color: colors.PRIMARY[1000],
              }}
              dataValue={currentWeeklyActivityDurations}
              weeklyAverage={currentAveragePerWeek}
              chartId="recent"
            />
            <GoalChart
              legendItem={{
                name: 'Previous Period',
                color: colors.PRIMARY[700],
              }}
              dataValue={prevWeeklyActivityDurations}
              weeklyAverage={prevAveragePerWeek}
              chartId="previous"
            />
          </Stack>
        </>
      )}
    </ReportChartWithTitle>
  )
}
