import { forwardRef } from 'react'
import ListItem from '@material-ui/core/ListItem'
import { NavLink as RouterLink } from 'react-router-dom'

const AdapterLink = forwardRef((props, ref) => (
  <RouterLink ref={ref} {...props} />
))

const ListItemLink = (props) => {
  const LinkClass = forwardRef(({ activeClassName, ...props }, ref) => (
    <AdapterLink
      ref={ref}
      className={({ isActive }) => ({ ...(isActive ? activeClassName : null) })}
      {...props}
    />
  ))
  return <ListItem button component={LinkClass} {...props} />
}
export default ListItemLink
