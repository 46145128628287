import {
  GENERIC_ERROR_TEXT,
  NUMBER_DAYS_WITH_DATA,
} from 'ui/clinicianScreens/Patient/Summary/components/CollapsibleViews/consts'

export const ACTION_ITEM_TEXT =
  'Encourage your patient to wear the Apple watch. Data can be automatically recorded when a patient carries their iPhone near the waist, such as in a trouser pocket, and walks steadily on flat ground. This data gives valuable insights into the patient’s care.'

export const APPLE_MOBILITY_METRICS = {
  linkText: "Apple's Mobility Metrics",
  url: 'https://www.apple.com/healthcare/docs/site/Measuring_Walking_Quality_Through_iPhone_Mobility_Metrics.pdf',
}

export const labels = {
  AVG_DOUBLE_SUPPORT: 'AVG. DOUBLE SUPPORT %',
  AVG_PER_DAY: 'Average per day',
  AVG_PER_DAY_TOOLTIP_TEXT:
    'Data is recorded when the iPhone is carried near the waist, such as in a trouser pocket.',
  AVG_STEP_LENGTH: 'AVG. STEP LENGTH',
  AVG_WALKING_SPEED: 'AVG. WALKING SPEED',
  ERROR: GENERIC_ERROR_TEXT,
  LOADING: 'Loading Mobility',
  NO_DATA:
    'No data on step length, walking speed and double support percentage is available within the selected period.',
  NUMBER_OF_DAYS_WITH_DATA: NUMBER_DAYS_WITH_DATA,
  TITLE: 'Mobility',
}
