import { MOBILITY_METRICS } from 'ui/clinicianScreens/Patient/Report/sections/Mobility/consts'
import {
  AVG_PER,
  CHANGE_THRESHOLD_DEFAULT,
  NO_CHANGE,
  SECTION_TITLES,
} from 'ui/clinicianScreens/Patient/Report/consts'
import {
  formatAverage,
  formatDiff,
  formatInHoursAndMinutes,
  getRoundedPercentChange,
  isAtOrBeyondThreshold,
} from 'ui/clinicianScreens/Patient/Report/helpers'
import {
  formatMobilityDiff,
  getMobilityIconVariant,
} from 'ui/clinicianScreens/Patient/Report/sections/Mobility/helpers'
import { AFTER, BEFORE } from 'utilities/sort'
import { getAverageDiff } from '../TremorAndDyskinesia/helpers'

export const getHighlightDefaultText = (
  percentChange,
  trendDiff,
  currentAvg,
  prevAvg,
) => {
  if (currentAvg && !prevAvg) return ''
  if (!percentChange) return NO_CHANGE
  return `${Math.abs(percentChange)}% (${trendDiff})`
}

export const getMobilityHighlight = ({
  currentData,
  prevData,
  metricCategory,
  subtitle,
  invertColors,
  noteSuffix = '',
}) => {
  if (!currentData?.averageValue)
    return {
      trend: 0,
    }

  const value = `${currentData?.averageValue}${currentData?.averageUnit}`
  const percentChange = getRoundedPercentChange(
    currentData?.averageValue,
    prevData?.averageValue,
  )

  if (!isAtOrBeyondThreshold(percentChange)) return { trend: 0 }

  const iconVariant = getMobilityIconVariant({
    trend: percentChange,
    invertColors,
  })
  const trendLabel = formatMobilityDiff(currentData, prevData)

  return {
    metric: metricCategory,
    subtitle,
    value,
    trend: percentChange,
    iconVariant,
    notes: percentChange
      ? `${Math.abs(percentChange)}% (${trendLabel})${noteSuffix}`
      : `No significant change${noteSuffix}`,
  }
}

export const getTremorDyskinesiaHighlight = ({
  currentData,
  prevData,
  metricCategory,
  percentChange,
}) => {
  const currentAverage = formatAverage(currentData)
  const prevAverage = formatAverage(prevData)
  const getTDIconVariant = () => {
    if (!isAtOrBeyondThreshold(percentChange)) {
      return null
    }
    return percentChange < CHANGE_THRESHOLD_DEFAULT ? 'better' : 'worse'
  }
  const avgDiff = getAverageDiff(currentAverage, prevAverage)
  const trendDiff = formatDiff(avgDiff)

  return {
    metric: metricCategory,
    subtitle: AVG_PER.day,
    value: formatInHoursAndMinutes(currentAverage),
    trend: percentChange,
    iconVariant: getTDIconVariant(),
    notes: getHighlightDefaultText(
      percentChange,
      trendDiff,
      currentAverage,
      prevAverage,
    ),
  }
}

export const sortHighlights = (highlights) => {
  if (!highlights) return []
  const { TREMOR, DYSKINESIA, ACTIVITY, MOBILITY } = SECTION_TITLES
  const { doubleSupport, stepLength, walkingSpeed } = MOBILITY_METRICS

  const highlightsOrder = [
    TREMOR,
    DYSKINESIA,
    ACTIVITY,
    stepLength.title,
    walkingSpeed.title,
    doubleSupport.title,
    MOBILITY,
  ]

  const getItemFromHighlightsOrder = (highlight) => {
    const { metric, subtitle } = highlight

    if (metric === MOBILITY) {
      if (subtitle === stepLength.title) {
        return stepLength.title
      } else if (subtitle === walkingSpeed.title) {
        return walkingSpeed.title
      } else if (subtitle === doubleSupport.title) {
        return doubleSupport.title
      } else {
        return metric
      }
    }
    return metric
  }

  return highlights.sort((a, b) => {
    if (
      highlightsOrder.indexOf(getItemFromHighlightsOrder(a)) <
      highlightsOrder.indexOf(getItemFromHighlightsOrder(b))
    ) {
      return AFTER
    } else {
      return BEFORE
    }
  })
}
