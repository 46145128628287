import React from 'react'
import CopyTextIcon from 'ui/components/CopyText/CopyTextIcon'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 500,
  },
}))

/**
 * Component for displaying a dialog with easily copyable device info.
 *
 * @param {object} device The device with info to copy
 * @param {boolean} open The stateful boolean controlling the dialog's render
 * @param {function} handleClose Function that closes the dialog
 */
const CopyDeviceIdDialog = ({ device, open, handleClose }) => {
  const classes = useStyles()
  return (
    <Dialog open={open} p={3} onClose={handleClose}>
      <DialogTitle>Copy Device ID</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box my={1}>
          <InputLabel shrink={true}>Device ID</InputLabel>
        </Box>
        <Box mb={2}>
          <CopyTextIcon value={device.deviceShortId} size="full" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CopyDeviceIdDialog
