import { useState } from 'react'
import PropTypes from 'prop-types'
import TaskEditContainer from './TaskEditContainer'
import { Dialog, Typography } from '@material-ui/core'

/**
 * The task edit button
 *
 * @return {JSX.Element} The task edit button
 */
const TaskEdit = ({ id, editClass }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Typography className={editClass} onClick={() => setOpen(true)}>
        Edit
      </Typography>
      {open && (
        <Dialog open={true} maxWidth={'lg'} fullWidth={false}>
          <TaskEditContainer id={id} handleClose={() => setOpen(false)} />
        </Dialog>
      )}
    </>
  )
}

TaskEdit.propTypes = {
  id: PropTypes.string,
  editClass: PropTypes.string,
}

export default TaskEdit
