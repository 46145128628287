import { string } from 'prop-types'
import { useContext } from 'react'
import { UserPHIContext } from 'ui/contexts'
import ProtectedRoute from '../ProtectedRoute'

const ProtectedByClinicianRole = ({ redirectPath }) => {
  const phiVisibility = useContext(UserPHIContext)

  return (
    <ProtectedRoute isAllowed={phiVisibility} redirectPath={redirectPath} />
  )
}

ProtectedByClinicianRole.propTypes = {
  redirectPath: string.isRequired,
}

ProtectedByClinicianRole.defaultProps = {}

export default ProtectedByClinicianRole
