import { AFTER, BEFORE } from 'utilities/sort'

export const DEVICE_KINDS_STRIVESTUDY = ['strivestudy']

/**
 * Get the ID of the first device in the devices array to match one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} ID of first matching device if found, undefined if none found
 */
export const getDeviceId = (devices, kinds) =>
  (
    (devices || []).find(
      ({ id, kind, disabled }) =>
        kinds.includes(kind) && typeof id !== 'undefined' && !disabled,
    ) || {}
  ).id

/**
 * Get the ID of the most recently created device in the devices array that matches one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} ID of first matching device if found, undefined if none found
 */
export const getLatestDeviceId = (devices, kinds) => {
  const defaultDate = 0
  const sorted = [...(devices || [])].sort((a, b) =>
    (b.createdAt || defaultDate) < (a.createdAt || defaultDate)
      ? AFTER
      : BEFORE,
  )
  return getDeviceId(sorted, kinds)
}

/**
 * Get the short ID of the first device in the devices array to match one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} short ID of first matching device if found, undefined if none found
 */
const getDeviceShortId = (devices, kinds) =>
  (
    (devices || []).find(
      ({ id, kind, disabled }) =>
        kinds.includes(kind) && typeof id !== 'undefined' && !disabled,
    ) || {}
  ).deviceShortId

/**
 * Get the short ID of the most recently created device in the devices array that matches one of the supplied kinds.
 * @param {object[]} devices array of patient device objects
 * @param {string[]} kinds array of string 'kind' types to match to
 * @returns {string} short ID of first matching device if found, undefined if none found
 */
const getLatestDeviceShortId = (devices, kinds) => {
  const defaultDate = 0
  const sorted = [...(devices || [])].sort((a, b) =>
    (b.createdAt || defaultDate) < (a.createdAt || defaultDate)
      ? AFTER
      : BEFORE,
  )
  return getDeviceShortId(sorted, kinds)
}

/**
 * Get the short ID of the most recently created "strivestudy" device (Runespeak for iPhone).
 * @param {object[]} devices array of patient device objects
 * @returns {string} short ID of first matching iPhone if found, undefined if none found
 * **/
export const getLatestStriveStudyShortId = (devices) =>
  getLatestDeviceShortId(devices, DEVICE_KINDS_STRIVESTUDY)
