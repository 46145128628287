import { useContext, useState, useEffect, useCallback } from 'react'
import CognitoUserPoolContext from 'ui/contexts/CognitoUserPoolContext'
import { fmtMomentLocal } from 'utilities/time'
import { getDownloadUrl } from 'domains/streamApi/download'
import Plot from 'ui/components/Plot'
import { useStreamApiClient } from 'domains/streamApi/context'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  plot: {
    width: '100%',
  },
}))

/**
 * Plot component that staggers and formats data for the Tasks Plot, then renders it.
 *
 * @param {object} data The request data for the plot
 * @param {object} layout The plot layout
 * @param {object} patient The patient whose data is being visualized
 * @param {function} onHover Function that grabs point data to send to AdditionalInfo
 * @param {function} onUnhover Function that unmounts AdditionalInfo
 * @param {float} rangeStartLocal Beginning of time range, used only as a placeholder
 * @param {float} rangeEndLocal End of time range, used only as a placeholder
 */
const Tasks = ({
  data,
  layout,
  patient,
  onHover,
  onUnhover,
  rangeStartLocal,
  rangeEndLocal,
}) => {
  const streamApiClient = useStreamApiClient()
  const cognitoUserPool = useContext(CognitoUserPoolContext)
  const classes = useStyles()
  const [formattedData, setFormattedData] = useState([])
  const placeholderTaskObject = {
    timeArray: [rangeStartLocal, null, rangeEndLocal],
    spanArray: [null, 'Tasks', null],
    hoverInfoArray: [null, null, null],
  }

  /**
   * Formats task data to store in each point and send to AdditionalInfo on hover
   *
   * @param {object} task The task event object that will contribute some values to the hoverInfoArray
   * @param {array} hoverInfoArray The array that will hold this custom data for AdditionalInfo
   * @param {float} start The start time of the task event
   * @param {float} end The end time of the task event
   */
  const formatHoverInfo = useCallback(
    (task, hoverInfoArray, start, end) => {
      switch (task.schemaId) {
        case 'strivestudy-spiraltest-1':
          const instructions =
            'To download this image and additional stream data, click "Raw Device Data" in the Browse controls.'
          const duration =
            (task.endTime - task.startTime).toFixed(3) + ' seconds'
          let hoverStart = {
            time: start,
            timeTitle: 'Drawing Test:',
            duration: duration,
            instructions: instructions,
            category: 'Task',
          }
          // formatting for image url
          const friendlyDeviceName = task.device.alias.replace(
            /[^a-zA-Z0-9]+/gim,
            '-',
          )
          const friendlySessionId = task.deviceSessionId.replace(
            /[^a-zA-Z0-9]+/gim,
            '-',
          )
          const filename = `${friendlyDeviceName}_${friendlySessionId}_screenshot-drawing`
          const filenameBG = `${friendlyDeviceName}_${friendlySessionId}_screenshot-background-spiral`
          const params = {
            filename,
            patient_id: patient.id,
            session_id: task.id,
            stream_name: 'screenshot-drawing',
          }
          const paramsBG = {
            filename,
            patient_id: patient.id,
            session_id: task.id,
            stream_name: 'screenshot-background-spiral',
          }
          getDownloadUrl({
            endpoint: 'v1/session',
            params,
            filename,
            streamApiClient,
            cognitoUserPool,
          }).then((url) => {
            // set the URL for the AdditionalInfo image display and push to the hoverInfoArray
            hoverStart.imgUrl = url

            getDownloadUrl({
              endpoint: 'v1/session',
              params: paramsBG,
              filename: filenameBG,
              streamApiClient,
              cognitoUserPool,
            }).then((urlBG) => {
              // set the URL for the AdditionalInfo image display and push to the hoverInfoArray
              hoverStart.imgUrlBG = urlBG
              hoverInfoArray.push(null, hoverStart, null)
            })
          })
          break
        default:
          hoverInfoArray.push(null, null, null)
      }
    },
    [streamApiClient, cognitoUserPool, patient.id],
  )

  /**
   * Formats each Task in the now-separated array of arrays of Tasks.
   *
   * @param {array} separatedTasksArray An array of arrays of non-overlapping tasks to format
   */
  const formatEachTaskArray = useCallback(
    (separatedTasksArray) => {
      let formattedAndSeparatedTasksArray = []

      // iterates through each array of tasks, and creates a new structure in
      // formattedAndSeparatedTasksArray to which we will push select data
      separatedTasksArray.forEach((singleTaskArray, i) => {
        formattedAndSeparatedTasksArray[i] = {}
        formattedAndSeparatedTasksArray[i].timeArray = []
        formattedAndSeparatedTasksArray[i].spanArray = []
        formattedAndSeparatedTasksArray[i].hoverInfoArray = []

        // Iterates through each task and pushes time and hover info to the new structure
        singleTaskArray.forEach((task) => {
          const formattedStart = fmtMomentLocal(
            'Y-MM-DD HH:mm:ss.SSS',
            task.startTime * 1000,
          )
          // Titles the first plot "Tasks" and subsequent plots "Tasks (2)", "Tasks (3)", etc.
          const title = i === 0 ? 'Tasks' : 'Tasks (' + (i + 1) + ')'
          // Pushes this data to the corresponding array (formatting it further for the hover array)
          formattedAndSeparatedTasksArray[i].timeArray.push(
            null,
            formattedStart,
            null,
          )
          formattedAndSeparatedTasksArray[i].spanArray.push(null, title, null)
          formatHoverInfo(
            task,
            formattedAndSeparatedTasksArray[i].hoverInfoArray,
            formattedStart,
          )
        })
      })
      return formattedAndSeparatedTasksArray
    },
    [formatHoverInfo],
  )

  /**
   * Calls functions in order to filter Task data, then separate any that overlap,
   * then format those separated Tasks for the Plot, then sets that data in state.
   */
  useEffect(() => {
    const filteredTasks = filterTasks(data) // filter
    const separatedTasksArray = staggerTasks(filteredTasks) // separate overlap
    const formattedAndSeparatedTasksArray =
      formatEachTaskArray(separatedTasksArray) // format
    setFormattedData(formattedAndSeparatedTasksArray) // set in state
  }, [data, formatEachTaskArray])

  /**
   * Filters the Task data into an array of only approved types (currently only
   * the spiral test, but the flow is written so that there can easily
   * be other types added.)
   *
   * @param {object} data Task data to be filtered
   */
  const filterTasks = (data) => {
    // if the data is ready
    if (data && data.dataSessions) {
      return data.dataSessions.filter(
        (task) => task.schemaId === 'strivestudy-spiraltest-1',
      )
    }
    // if no data, return empty
    return []
  }

  /**
   * Separates any Tasks that overlap into separate arrays to be displayed in separate plots.
   *
   * @param {array} filteredTasks An array of Tasks that has been filtered to only the approved types
   */
  const staggerTasks = (filteredTasks) => {
    let separatedTasksArray = []
    filteredTasks.forEach((newTask, newTaskIndex) => {
      // if it's the first task, just add it in a new sub-array
      if (newTaskIndex === 0) {
        separatedTasksArray.push([newTask])
      } else {
        // For each existing array of tasks in the separatedTasksArray
        separatedTasksArray.some((singleTaskArray, singleTaskArrayIndex) => {
          let itemAdded = false

          // Iterate through the already-pushed tasks
          singleTaskArray.some((oldTask, oldTaskIndex) => {
            // if any have overlap...
            if (
              newTask.startTime === oldTask.startTime ||
              newTask.startTime === oldTask.endTime ||
              newTask.endTime === oldTask.startTime ||
              newTask.endTime === oldTask.endTime ||
              (newTask.startTime > oldTask.startTime &&
                newTask.startTime < oldTask.endTime) ||
              (newTask.endTime < oldTask.endTime &&
                newTask.endTime > oldTask.startTime) ||
              (newTask.startTime < oldTask.startTime &&
                newTask.endTime > oldTask.endTime)
            ) {
              // if we reach the end of the separatedTasksArray, and there is
              // overlap in each, create a new array to plot
              if (separatedTasksArray.length - singleTaskArrayIndex === 1) {
                separatedTasksArray.push([newTask])
              }
              // break the loop if there is overlap (or if a new array was created)
              return true
            } else {
              // if there is no overlap, just add it to the currently iterating array
              singleTaskArray.push(newTask)
              itemAdded = true
              // break the loop once added
              return true
            }
          })
          return itemAdded
        })
      }
    })
    return separatedTasksArray
  }

  /**
   * Takes one separated and formatted Task object-of-arrays and plugs it into a Plotly Plot Data Object
   *
   * @param {object} formattedTaskObject An object with 3 arrays of Plot-relevant data
   */
  const processTaskData = (formattedTaskObject) => {
    return [
      {
        hovertemplate: `Spiral Test on<br />%{x}`,
        hovermode: 'closest',
        mode: 'markers',
        marker: {
          size: 15,
          color: 'rgba(220, 200, 24, 1)',
          symbol: 'diamond',
          line: {
            color: 'rgb(0, 0, 0)',
            width: 1,
          },
        },
        type: 'scatter',
        visible: true,
        x: formattedTaskObject.timeArray,
        y: formattedTaskObject.spanArray,
        customdata: formattedTaskObject.hoverInfoArray,
      },
    ]
  }

  // Controls placeholder state
  const hasData = formattedData && formattedData.length

  return (
    <>
      {hasData ? (
        formattedData.map((formattedTaskObject, i) => {
          return (
            <div key={'Tasks-' + i}>
              <Plot
                className={classes.plot}
                config={{
                  autosize: true,
                  responsive: true,
                  displayModeBar: false,
                  doubleClick: false,
                  scrollZoom: false,
                }}
                data={processTaskData(formattedTaskObject)}
                layout={layout}
                useResizeHandler={true}
                onHover={(e) => {
                  onHover(e)
                }}
                onUnhover={onUnhover}
              />
            </div>
          )
        })
      ) : (
        <div key="Tasks-Placeholder" data-cy="Tasks">
          <Plot
            className={classes.plot}
            config={{
              autosize: true,
              responsive: true,
              displayModeBar: false,
              doubleClick: false,
              scrollZoom: false,
            }}
            data={processTaskData(placeholderTaskObject)}
            layout={layout}
            useResizeHandler={true}
          />
        </div>
      )}
    </>
  )
}

export default Tasks
