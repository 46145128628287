import axios from 'axios'

const HTTP_PORT = 80
const HTTPS_PORT = 443

const getBaseUrl = function ({ secure, host, port }) {
  const protocol = secure ? 'https' : 'http'
  let url = `${protocol}://${host}`
  if ((secure && port !== HTTPS_PORT) || (!secure && port !== HTTP_PORT)) {
    url = `${url}:${port}`
  }
  return `${url}`
}
/**
 * Create an axiosClient for Stream API calls
 *
 * @return {AxiosInstance} A newly configurated instance of the Axios client
 */
export const createClient = ({
  host,
  port = HTTPS_PORT,
  secure = true,
} = {}) => {
  const baseURL = getBaseUrl({ secure, host, port })
  return axios.create({ baseURL })
}

export default createClient
