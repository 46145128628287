import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  objectOf,
  oneOf,
  string,
} from 'prop-types'
import { DateRangePicker as DatePicker, isNextDay } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment-timezone'
import * as MUI from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import DateRangePickerLegend from './DateRangePickerLegend'
import { getTimestampStartOfDay } from 'utilities/time'

import { DATE_FORMAT, DATE_FORMAT_HUMAN_SHORT } from 'ui/consts'
import './DateRangePicker.css'
import { Text } from 'ui/baseComponents/Text'
import { colors } from 'theme/colors'
import { DATE_PICKER_DATA } from './consts'
import { hasValue } from './helpers'

const DateRangePicker = ({
  from,
  to,
  minimumDate,
  maximumDate,
  minimumNights = 0,
  focusedInput,
  onFocusChange,
  onLeftArrowClick,
  onRightArrowClick,
  onPrevMonthClick,
  onNextMonthClick,
  onDatePickerDatesChange,
  initialVisibleMonth,
  isOutsideRange,
  data,
  dataTypes,
  label,
  variant,
  timezoneName,
  highlightedDates,
  blockedDates,
  errorMessage,
  showArrows = true,
  hasError,
  ...props
}) => {
  const today = moment.tz(timezoneName).format(DATE_FORMAT)
  const showLegend = variant === 'withLegend'

  // Formats each day in the calendar to reflect data availability for that day
  const renderDayContents = (day) => {
    const unixDate = getTimestampStartOfDay(day, timezoneName)
    const dataForDate = data ? data[unixDate] : []
    const Date = () => <div>{moment.tz(day, timezoneName).format('D')}</div>

    if (!dataForDate) {
      return (
        <>
          <Date />
          {dataTypes.map((dataType) => (
            <div
              key={dataType}
              className="dayContents dataAvailabilityDateRangePickerNoData"
            ></div>
          ))}
        </>
      )
    }

    const classes = {}

    dataTypes.forEach((dataType) => {
      classes[dataType] = 'dataAvailabilityDateRangePickerNoData'
      if (dataForDate.includes(dataType.toLowerCase())) {
        classes[
          dataType
        ] = `dataAvailabilityDateRangePicker${DATE_PICKER_DATA[dataType].classNameSuffix}`
      }
    })

    return (
      <>
        <Date />
        {dataTypes.map((dataType) => (
          <div
            key={dataType}
            className={`dayContents ${classes[dataType]}`}
          ></div>
        ))}
      </>
    )
  }

  const renderCalendarInfo = () => (
    <div>
      <DateRangePickerLegend dataTypes={dataTypes} />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  )

  return (
    <div
      className={`strivepd-date-range-picker ${hasError ? 'error' : ''}`}
      data-cy="strivepd-date-range-picker"
    >
      <div style={{ marginRight: 8 }}>
        <Text variant="caps14B" color={colors.GREY[900]}>
          {label}
        </Text>
      </div>
      {showArrows && (
        <MUI.IconButton
          size="small"
          onClick={onLeftArrowClick}
          title="Previous period"
          disabled={
            !from ||
            !to ||
            isNextDay(
              moment.tz(minimumDate, timezoneName),
              moment.tz(from, timezoneName),
            )
          }
          data-cy="left-arrow"
        >
          <KeyboardArrowLeftIcon />
        </MUI.IconButton>
      )}
      <DatePicker
        startDateAriaLabel="Start Date"
        endDateAriaLabel="End Date"
        startDatePlaceholderText="Mth 00, 0000"
        endDatePlaceholderText="Mth 00, 0000"
        startDate={hasValue(from) ? moment.tz(from, timezoneName) : null}
        startDateId="start_date_id"
        endDate={hasValue(to) ? moment.tz(to, timezoneName) : null}
        endDateId="end_date_id"
        onDatesChange={onDatePickerDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        initialVisibleMonth={initialVisibleMonth}
        isOutsideRange={isOutsideRange}
        minimumNights={minimumNights}
        small
        noBorder
        renderDayContents={showLegend ? renderDayContents : null}
        renderCalendarInfo={showLegend ? renderCalendarInfo : null}
        hideKeyboardShortcutsPanel={true}
        onPrevMonthClick={onPrevMonthClick}
        onNextMonthClick={onNextMonthClick}
        displayFormat={DATE_FORMAT_HUMAN_SHORT}
        customInputIcon={
          <CalendarMonthOutlinedIcon color="inherit" fontSize="small" />
        }
        isDayBlocked={(day) => blockedDates?.has(day.format(DATE_FORMAT))}
        isDayHighlighted={(day) =>
          highlightedDates?.has(day.format(DATE_FORMAT))
        }
        {...props}
      />
      {showArrows && (
        <MUI.IconButton
          size="small"
          disabled={
            !from ||
            !to ||
            to === today ||
            isNextDay(
              moment.tz(to, timezoneName),
              moment.tz(maximumDate, timezoneName),
            )
          }
          data-cy="right-arrow"
          onClick={onRightArrowClick}
          title="Next period"
        >
          <KeyboardArrowRightIcon />
        </MUI.IconButton>
      )}
    </div>
  )
}

DateRangePicker.propTypes = {
  from: string, //e.g. '2021-09-01'
  to: string, //e.g. '2021-09-14'
  minimumDate: string, //e.g. '2019-09-01'
  maximumDate: string, //e.g. '2023-09-14'
  minimumNights: number,
  focusedInput: oneOf(['startDate', 'endDate', null]),
  onFocusChange: func.isRequired,
  onLeftArrowClick: func, // triggered when user clicks on arrow next to input field
  onRightArrowClick: func, // triggered when user clicks on arrow next to input field
  onPrevMonthClick: func, // triggered when user clicks on arrow in the month view
  onNextMonthClick: func, // triggered when user clicks on arrow in the month view
  onDatePickerDatesChange: func.isRequired,
  initialVisibleMonth: func,
  isOutsideRange: func,
  label: string,
  variant: oneOf(['default', 'withLegend']),
  data: objectOf(arrayOf(oneOf(Object.keys(DATE_PICKER_DATA)))),
  dataTypes: arrayOf(oneOf(Object.keys(DATE_PICKER_DATA))),
  timezoneName: string, //e.g. 'America/New_York'
  highlightedDates: instanceOf(Set),
  blockedDates: instanceOf(Set),
  errorMessage: string,
  showArrows: bool,
  hasError: bool,
}

export default DateRangePicker
