import { gql } from '@apollo/client'

export const GET_STREAM_LIST = gql`
  query getStreamList($filters: StreamQueryFilters) {
    streamList(filters: $filters) {
      streams {
        id
        maxTime
        minTime
        streamType {
          id
          name
          description
        }
        streamTypeId
        parameters {
          key
          value
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
`
