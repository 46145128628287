import React from 'react'
import { bool, number, shape, string } from 'prop-types'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'

import SummaryMetric from 'ui/clinicianScreens/Patient/Summary/components/SummaryMetric/SummaryMetric'
import MobilityMetricsLink from './MobilityMetricsLink'
import { InformationalIconTooltip } from 'ui/components/InformationalIconTooltip'
import { labels } from './consts'
import { RUNE_GRAY_600 } from 'theme/colors'

const useStyles = makeStyles(() => ({
  avgPerDayText: {
    color: RUNE_GRAY_600,
    fontSize: '.875rem',
  },
  rowOne: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
}))

const MobilitySummaryData = ({ data, isInComparisonMode }) => {
  const { doubleSupportPercentage, stepLength, walkingSpeed } = data
  const classes = useStyles()
  const METRIC_SPACING = 4

  return (
    <>
      <Grid container direction="row" spacing={6} alignItems="center">
        <Grid item className={classes.rowOne}>
          <Typography className={classes.avgPerDayText}>
            {labels.AVG_PER_DAY}
          </Typography>
          <InformationalIconTooltip
            placement="bottom-start"
            title={<Box>{labels.AVG_PER_DAY_TOOLTIP_TEXT}</Box>}
          />
        </Grid>
        <Grid item>
          <MobilityMetricsLink />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={METRIC_SPACING}
      >
        <Grid item>
          <SummaryMetric
            title={labels.AVG_STEP_LENGTH}
            metrics={{
              [stepLength.averageUnit]: stepLength.averageValue,
            }}
            trend={stepLength.trend}
            isInComparisonMode={isInComparisonMode}
          />
        </Grid>
        <Grid item>
          <SummaryMetric
            title={labels.AVG_WALKING_SPEED}
            metrics={{
              [walkingSpeed.averageUnit]: walkingSpeed.averageValue,
            }}
            trend={walkingSpeed.trend}
            isInComparisonMode={isInComparisonMode}
          />
        </Grid>
        <Grid item>
          <SummaryMetric
            title={labels.AVG_DOUBLE_SUPPORT}
            metrics={{
              [doubleSupportPercentage.averageUnit]:
                doubleSupportPercentage.averageValue,
            }}
            trend={doubleSupportPercentage.trend}
            isInComparisonMode={isInComparisonMode}
          />
        </Grid>
      </Grid>
    </>
  )
}

const MetricShape = shape({
  averageValue: number || string,
  averageUnit: string,
  hasError: bool,
})

MobilitySummaryData.propTypes = {
  data: shape({
    numberOfDaysWithData: number,
    doubleSupportPercentage: MetricShape,
    stepLength: MetricShape,
    walkingSpeed: MetricShape,
  }),
}

MobilitySummaryData.defaultProps = {}

export default MobilitySummaryData
