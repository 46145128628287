import { CLINICIAN_ROUTES, SHARED_ROUTES } from 'app/AppRoutes/consts'
import { useEffect } from 'react'

/**
 * Update the document title based on route
 * @param {string} pathname, the page title
 * @return {Object} includes loading, data, error, and fetchMore from graphQL query
 */
const useTitle = (pathname) => {
  const RUNE_LABS_TITLE = 'Rune Labs | Research and Therapy Development'
  const ALL_THE_ROUTES = {
    ...CLINICIAN_ROUTES,
    ...SHARED_ROUTES,
  }

  const splitPathname = pathname.split('/')
  const pagefromPathname = splitPathname.pop()
  const routeValues = Object.values(ALL_THE_ROUTES)
  const titleMatch = routeValues.find((route) => {
    const path = route?.path.replace('/', '')
    return path === pagefromPathname
  })?.title

  const prependPrefix = titleMatch ? `${titleMatch} - ` : ''
  const runeTitle = `${prependPrefix}${RUNE_LABS_TITLE}`

  useEffect(() => {
    document.title = runeTitle
  }, [runeTitle])
}

export default useTitle
