import { createContext, useCallback, useState } from 'react'

const SignInContext = createContext({})

export const useSignInContext = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordResetRequired, setPasswordResetRequired] = useState(false)
  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')

  return {
    email,
    error,
    forgotPassword,
    password,
    passwordResetRequired,
    passwordResetSuccessful,
    verificationCode,
    setEmail: useCallback((newEmail) => setEmail(newEmail), []),
    setError: useCallback((newError) => setError(newError), []),
    setForgotPassword: useCallback((value) => setForgotPassword(value), []),
    setPassword: useCallback((newPassword) => setPassword(newPassword), []),
    setPasswordResetRequired: useCallback(
      (value) => setPasswordResetRequired(value),
      [],
    ),
    setPasswordResetSuccessful: useCallback(
      (value) => setPasswordResetSuccessful(value),
      [],
    ),
    setVerificationCode: useCallback(
      (newVerificationCode) => setVerificationCode(newVerificationCode),
      [],
    ),
  }
}

export default SignInContext
