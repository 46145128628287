// Non-user-facing prototype LLM Playground
import React, { useState } from 'react'
import { Alert, Button, TextField, Tooltip, Stack } from '@mui/material'
import { Text } from 'ui/baseComponents/Text'
import {
  postLasagnaInternalReviewSample,
  postLasagnaEvaluationClassification,
  patchLasagnaEvaluation,
} from 'domains/lasagna/api'
import { colors } from 'theme/colors'

const STYLES = {
  pageContainer: {
    padding: '2em',
    marginTop: '5px',
  },
  tooltipText: {
    lineHeight: 1,
    padding: '2em',
  },
  nextButton: {
    backgroundColor: colors.GREEN[500],
    color: colors.WHITE,
  },
}

const StriveGPT = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [executionId, setExecutionId] = useState('')
  const [taskType, setTaskType] = useState(null)
  const [llmResponse, setLlmResponse] = useState(null)
  const [input, setInput] = useState(null)
  const [evaluationMap, setEvaluationMap] = useState(null)
  const [approveSummary, setApproveSummary] = useState(null)
  const [selectedClassifications, setSelectedClassifications] = useState([])
  const [comments, setComments] = useState('')
  const [fetchBatch, setFetchBatch] = useState(true)
  const [batchLlmResponses, setBatchLlmResponses] = useState(new Map())
  const [executionIds, setExecutionIds] = useState([])

  const generateSummaryBatch = async (taskType) => {
    const promises = []
    for (let i = 0; i < 5; i++) {
      promises.push(postLasagnaInternalReviewSample(taskType))
    }
    // First, get all sample responses in parallel. If any single request fails, do not block
    // the others and log the error.
    const responses = []
    await Promise.allSettled(promises).then((promise) => {
      promise.forEach((item) => {
        if (item.status === 'fulfilled') {
          responses.push(item.value)
        } else if (item.status === 'rejected') {
          console.error('lasagna.error', item.reason)
        }
      })
    })
    // Store both the responses and an array of execution ids
    const llmResponseMap = new Map()
    const executionIds = []
    responses.forEach((resp) => {
      llmResponseMap.set(resp.data.execution_id, resp.data)
      executionIds.push(resp.data.execution_id)
    })
    setBatchLlmResponses(llmResponseMap)

    // The first element of the execution ids array will be the first response evaluated
    const [currentExecutionId] = executionIds
    const currentSample = llmResponseMap.get(currentExecutionId)
    setExecutionId(currentExecutionId)
    setLlmResponse(currentSample.llm_response)
    setInput(currentSample.input.llm_input)

    // Since we will be evaluating the first element of the array first, we want to remove the
    // execution id from the array so we don't evaluate it twice
    const updatedExecutionIds = executionIds.filter(
      (i) => i !== currentExecutionId,
    )
    setExecutionIds(updatedExecutionIds)
  }

  const setNextLlmResponse = () => {
    if (executionIds.length > 0) {
      const [nextExecutionId] = executionIds
      setExecutionId(nextExecutionId)
      setExecutionIds(executionIds.filter((i) => i !== nextExecutionId))
      setLlmResponse(batchLlmResponses.get(nextExecutionId).llm_response)
      setInput(batchLlmResponses.get(nextExecutionId).input.llm_input)
    } else {
      setBatchLlmResponses(new Map())
      setFetchBatch(true)
      setIsLoading(false)
    }
  }

  const handleSummarySelection = async (taskType) => {
    setIsLoading(true)
    setFetchBatch(false)
    setTaskType(taskType)
    try {
      await generateSummaryBatch(taskType)

      const evaluationClassificationResp =
        await postLasagnaEvaluationClassification()
      const evaluationMap = evaluationClassificationResp.data
      setEvaluationMap(evaluationMap)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      console.error(err)
    }
  }

  const handleEvaluationClassificationButtonClick = (classification) => {
    const hasValue = selectedClassifications.includes(classification)
    if (hasValue) {
      setSelectedClassifications(
        selectedClassifications.filter((c) => c !== classification),
      )
    } else {
      setSelectedClassifications([...selectedClassifications, classification])
    }
  }

  const handleNext = async () => {
    try {
      await patchLasagnaEvaluation(
        executionId,
        taskType,
        approveSummary,
        selectedClassifications,
        comments,
      )
      setApproveSummary(null)
      setSelectedClassifications([])
      setComments('')
      setNextLlmResponse()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Alert severity="warning" sx={{ whiteSpace: 'pre-line' }}>
        INSTRUCTIONS: This is an internal review tool to be used to evaluate LLM
        summaries. These inputs are fake! They are not associated with any
        specific patient, even though this page is found under a specific
        patient. For each set of inputs, read through them and determine whether
        the LLM text on the right is an accurate summary given the user logs. If
        the summary is accurate, select the thumbs up and click 'Next'. If the
        summary is inaccurate, uses an inappropriate/wrong tone or has any other
        major issues considered inappropriate for a user to receive, please
        select the thumbs down and select one or more reasons (and optionally
        add a comment). When generating sample responses, a batch of five
        responses will be provided for you. Please evaluate each one!{' '}
      </Alert>
      <Stack sx={STYLES.pageContainer}>
        {!isLoading && fetchBatch && (
          <>
            {' '}
            <Text variant="head24"> Generate a batch of summaries:</Text>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Button
                    onClick={() => handleSummarySelection('symptom_summary')}
                    variant="outlined"
                    sx={{ color: 'primary.main', borderColor: 'primary.main' }}
                  >
                    Generate Symptom Summary Batch
                  </Button>
                  <Button
                    onClick={() => handleSummarySelection('activity_summary')}
                    variant="outlined"
                    sx={{ color: 'primary.main', borderColor: 'primary.main' }}
                  >
                    Generate Activity Summary Batch
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}

        {isLoading && <Text variant="head24"> Loading... </Text>}
        {!isLoading && !fetchBatch && (
          <>
            <Text variant="body16B" sx={{ marginBottom: '10px' }}>
              Execution ID: {executionId}
            </Text>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Input"
                multiline
                value={JSON.stringify(JSON.parse(input), null, 2)}
                rows={20}
                sx={{ width: '80%' }}
              />
              <TextField
                label="LLM Response"
                multiline
                value={llmResponse}
                rows={20}
                sx={{ width: '80%' }}
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              <Text variant="head24B">
                Is this summary acceptable for a user to receive? (Is accurate,
                relevant, and uses a respectful tone)
              </Text>
              <Stack direction="row" spacing={4} alignContent={'center'}>
                <Stack direction="row" spacing={4}>
                  <Tooltip title="Thumbs Up">
                    <Button
                      onClick={() => {
                        setApproveSummary(true)
                        setSelectedClassifications([])
                      }}
                      sx={{
                        bgcolor: approveSummary ? 'primary.main' : 'unset',
                        fontSize: '30px',
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      👍
                    </Button>
                  </Tooltip>
                  <Tooltip title="Thumbs Down">
                    <Button
                      onClick={() => setApproveSummary(false)}
                      sx={{
                        bgcolor:
                          !approveSummary && approveSummary !== null
                            ? 'primary.main'
                            : 'unset',
                        fontSize: '30px',
                      }}
                      color="primary"
                      variant="outlined"
                    >
                      👎
                    </Button>
                  </Tooltip>
                </Stack>
                <Stack direction="column" spacing={2}>
                  {!approveSummary &&
                    approveSummary !== null &&
                    Object.keys(evaluationMap).map((classification) => (
                      <Tooltip
                        key={classification}
                        title={evaluationMap[classification]}
                        sx={STYLES.tooltipText}
                      >
                        <Button
                          onClick={() =>
                            handleEvaluationClassificationButtonClick(
                              classification,
                            )
                          }
                          variant={
                            selectedClassifications.includes(classification)
                              ? 'contained'
                              : 'outlined'
                          }
                        >
                          {classification}
                        </Button>
                      </Tooltip>
                    ))}
                </Stack>
              </Stack>
              <Text variant="head24B">
                Do you have any other comments or feedback?
              </Text>
              <TextField
                label="Comments"
                multiline
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                sx={{ width: '100%' }}
              />
              <Button onClick={handleNext} sx={STYLES.nextButton}>
                Next
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </>
  )
}

export default StriveGPT
