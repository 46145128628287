import { LOGGING_IN, USER_AUTHENTICATED, USER_UNAUTHENTICATED } from './actions'
import { createSelector } from 'reselect'

export const initialState = {
  user: null,
  loading: false,
}

export const auth = (state = initialState, action = {}) => {
  const { user, type } = action

  switch (type) {
    case LOGGING_IN:
      return {
        ...state,
        loading: true,
      }
    case USER_AUTHENTICATED:
      return {
        ...state,
        user,
        loading: false,
      }
    case USER_UNAUTHENTICATED:
      return { ...initialState }
    default:
      return state
  }
}

export default auth
export const getUser = (state) => state.auth.user
export const getUserEmail = createSelector(getUser, (user) =>
  user ? user.email : null,
)

export const getUsername = createSelector(getUser, (user) =>
  user ? user.username : null,
)

export const isLoading = (state) => state.auth.loading
