import { arrayOf, oneOf } from 'prop-types'
import { Text } from 'ui/baseComponents/Text'

import { DATE_PICKER_DATA } from './consts'
import './DateRangePickerLegend.css'
import { colors } from 'theme/colors'
import { Stack } from 'ui/baseComponents/Stack'

const LegendItem = ({ dataType }) => (
  <div>
    <span
      className={`dataAvailabilityLegendItemIdentifier dataAvailabilityDateRangePicker${DATE_PICKER_DATA[dataType].classNameSuffix}`}
    ></span>
    <Text
      variant="caps12"
      color={colors.GREY[700]}
      className="dataAvailabilityDateRangePickerLegendText"
    >
      {DATE_PICKER_DATA[dataType].legendText}
    </Text>
  </div>
)

const DateRangePickerLegend = ({ dataTypes }) => (
  <Stack
    direction="row"
    spacing={3}
    className="dataAvailabilityDateRangePickerLegend"
  >
    {dataTypes.map((dataType) => (
      <LegendItem key={dataType} dataType={dataType}></LegendItem>
    ))}
  </Stack>
)

DateRangePickerLegend.propTypes = {
  dataTypes: arrayOf(oneOf(Object.keys(DATE_PICKER_DATA))).isRequired,
}

DateRangePickerLegend.defaultProps = {}

export default DateRangePickerLegend
