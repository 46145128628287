import React from 'react'
import CreateClientDialog from './CreateClientDialog'
import CreateEntity from 'ui/components/CreateEntity'

const CreateClient = () => (
  <CreateEntity label="Create Client">
    <CreateClientDialog />
  </CreateEntity>
)

export default CreateClient
