import { gql } from '@apollo/client'

export const UPDATE_TASK_NOTIFICATION = gql`
  mutation updateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`
