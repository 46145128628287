import { Text } from 'ui/baseComponents/Text'
import { Table } from 'ui/baseComponents/Table'
import _ from 'lodash'
import {
  PD_MEDICATIONS,
  LEVADOPA_PD_MEDICATIONS,
  NON_LEVADOPA_PD_MEDICATIONS,
  TABLE_STYLES,
  SCHEDULED_TABLE_TITLES,
  SCHEDULED_TABLE_HEADINGS,
} from 'ui/clinicianScreens/Patient/MedicationTables/consts'
import {
  getEventName,
  getFormattedDaysOfWeek,
} from 'ui/clinicianScreens/Patient/helpers'
import { convertTimesToAMPMFormat } from 'utilities/time'
import { Chip, Stack } from 'ui/baseComponents'
import {
  formatMedicationDosage,
  formatTableDataObject,
  getNewMedicationData,
} from './helpers'
import ReportTitle from 'ui/clinicianScreens/Patient/Report/components/ReportTitle'
import { StyledTooltip } from 'ui/components/StyledTooltip'
import { useContext } from 'react'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { NEW_LABEL_COLOR } from 'ui/clinicianScreens/Patient/Report/consts'

const MedicationScheduledTables = ({
  schedules = [],
  showPDMedsOnly,
  minimalVersion,
}) => {
  const STYLES = {
    chipContainer: {
      float: 'right',
    },
    newChip: {
      display: 'flex',
      height: 20,
    },
    ...TABLE_STYLES(minimalVersion),
  }

  const { selectedTimezone } = useContext(DateRangeContext)

  const isPdMedication = (item) =>
    PD_MEDICATIONS.includes(item?.classification?.enum)

  const pdLevadopaMedications = []
  const pdNonLevadopaMedications = []
  const otherMedications = []
  const asNeededMedications = []
  const supplements = []

  // The nesting in graphql needs flattening and sorting into medication arrays
  schedules.forEach((item) => {
    const {
      classification: { category, enum: medEnum },
      scheduleType,
    } = item
    if (item?.active) {
      if (scheduleType === 'ON_DEMAND') {
        asNeededMedications.push(item)
      } else if (LEVADOPA_PD_MEDICATIONS.includes(medEnum)) {
        pdLevadopaMedications.push(item)
      } else if (NON_LEVADOPA_PD_MEDICATIONS.includes(medEnum)) {
        pdNonLevadopaMedications.push(item)
      } else if (category === 'supplement') {
        supplements.push(item)
      } else {
        otherMedications.push(item)
      }
    }
  })

  const sortAlphabetically = (a, b) => {
    const aName = a[0].value
    const bName = b[0].value
    return aName.localeCompare(bName)
  }

  const formatScheduledMeds = (meds) => {
    const medications = []
    meds.forEach((med) => {
      let { scheduleType } = med
      scheduleType =
        med.scheduleType === 'ON_DEMAND'
          ? 'As needed'
          : _.capitalize(scheduleType)

      const medicationScheduleInPatientTimezone = med.localTime
      const medicationScheduleTimesFormatted = convertTimesToAMPMFormat(
        medicationScheduleInPatientTimezone,
      ).join(', ')

      // Either the schedule type OR days of the week depending on what's being utilized by the patient
      const scheduleFormatted =
        !med.daysOfWeek && med.scheduleType !== 'DAILY'
          ? scheduleType
          : medicationScheduleTimesFormatted

      const { dosage, unit, unitQuantity, createdAt } = med
      const { isNewMedication, newMedicationDate, newMedicationDateAgo } =
        getNewMedicationData(createdAt, selectedTimezone)

      const formattedDosage = formatMedicationDosage(dosage, unit, unitQuantity)
      const formattedFrequency = med?.daysOfWeek.length
        ? getFormattedDaysOfWeek(med?.daysOfWeek).join(', ')
        : scheduleType
      const formattedNewLabel = (
        <span style={STYLES.chipContainer}>
          {isNewMedication && (
            <StyledTooltip
              title={
                <>
                  New medication: <br />
                  {newMedicationDate} ({newMedicationDateAgo} days)
                </>
              }
            >
              <Chip label="NEW" color="success" sx={STYLES.newChip} />
            </StyledTooltip>
          )}
        </span>
      )
      const formattedNewCell = isNewMedication && (
        <>
          <Text color={NEW_LABEL_COLOR} variant="body16B">
            New
          </Text>
          <br />
          <Text color={NEW_LABEL_COLOR} variant="body16">
            {newMedicationDate} ({newMedicationDateAgo}d)
          </Text>
        </>
      )

      const tableObject = formatTableDataObject({
        id: med.id,
        name: getEventName(med),
        customData: [
          formattedDosage,
          ...(minimalVersion
            ? [
                <>
                  {formattedFrequency} {formattedNewLabel}
                  <br />
                  {scheduleFormatted}
                </>,
              ]
            : [formattedFrequency, scheduleFormatted ?? '-', formattedNewCell]),
        ],
        customStyles: [
          STYLES.firstColumn,
          STYLES.secondColumn,
          STYLES.thirdColumn,
          minimalVersion ? STYLES.emptyColumn : STYLES.fourthColumn,
          minimalVersion ? STYLES.emptyColumn : {},
        ],
      })
      medications.push(tableObject)
    })

    return medications.sort(sortAlphabetically)
  }

  const emptyHeadings = [
    {
      key: 'dosage',
      value: '',
      sx: STYLES.secondColumn,
    },
    {
      key: 'frequency',
      value: '',
      sx: STYLES.thirdColumn,
    },
    {
      key: 'times',
      value: '',
      sx: minimalVersion ? STYLES.emptyColumn : {},
    },
    {
      key: 'change',
      value: '',
      sx: minimalVersion ? STYLES.emptyColumn : {},
    },
  ]

  const scheduledPDMedsHeadings = [
    {
      key: 'pd medications',
      value: SCHEDULED_TABLE_TITLES.pdMedications,
      sx: STYLES.firstColumn,
    },
    {
      key: 'dosage',
      value: SCHEDULED_TABLE_HEADINGS.dosage,
      sx: STYLES.secondColumn,
    },
    {
      key: 'frequency',
      value: SCHEDULED_TABLE_HEADINGS.frequency,
      sx: STYLES.thirdColumn,
    },
    {
      key: 'times',
      value: minimalVersion ? '' : SCHEDULED_TABLE_HEADINGS.times,
      sx: minimalVersion ? STYLES.emptyColumn : STYLES.fourthColumn,
    },
    {
      key: 'change',
      value: minimalVersion ? '' : SCHEDULED_TABLE_HEADINGS.change,
      sx: minimalVersion ? STYLES.emptyColumn : {},
    },
  ]
  const scheduledOtherMedsHeadings = [
    {
      key: 'other medications',
      value: SCHEDULED_TABLE_TITLES.otherMedications,
      sx: STYLES.firstColumn,
    },
    ...emptyHeadings,
  ]
  const scheduledSupplementsHeadings = [
    {
      key: 'supplements',
      value: SCHEDULED_TABLE_TITLES.supplements,
      sx: STYLES.firstColumn,
    },
    ...emptyHeadings,
  ]
  const scheduledAsNeededHeadings = [
    {
      key: 'scheduled as needed',
      value: SCHEDULED_TABLE_TITLES.asNeeded,
      sx: STYLES.firstColumn,
    },
    ...emptyHeadings,
  ]

  const scheduledAsNeededMedsToShow = showPDMedsOnly
    ? asNeededMedications.filter((item) => isPdMedication(item))
    : asNeededMedications

  const pdLevadopaMedicationsFormatted = formatScheduledMeds(
    pdLevadopaMedications,
  )
  const pdNonLevadopaMedicationsFormatted = formatScheduledMeds(
    pdNonLevadopaMedications,
  )
  const pdMedsFormatted = [
    ...pdLevadopaMedicationsFormatted,
    ...pdNonLevadopaMedicationsFormatted,
  ]
  const otherMedsFormatted = formatScheduledMeds(otherMedications)
  const supplementsFormatted = formatScheduledMeds(supplements)
  const asNeededFormatted = formatScheduledMeds(scheduledAsNeededMedsToShow)
  const hasScheduledData =
    pdMedsFormatted.length > 0 ||
    otherMedsFormatted.length > 0 ||
    supplementsFormatted.length > 0 ||
    asNeededFormatted.length > 0

  return (
    <>
      {hasScheduledData && (
        <div style={STYLES.table} data-cy="medication-section-scheduled">
          {!minimalVersion && (
            <ReportTitle>
              <Text variant="body14B" component="h3">
                {SCHEDULED_TABLE_TITLES.main}
              </Text>
            </ReportTitle>
          )}
          <Stack
            style={STYLES.tableContent}
            data-cy="medication-section-scheduled-tables"
          >
            {pdMedsFormatted.length > 0 && (
              <Table
                headings={scheduledPDMedsHeadings}
                rows={pdMedsFormatted}
                data-cy="medication-section-pd-meds-table"
                tableCellProps={{ size: 'small' }}
              />
            )}
            {!showPDMedsOnly && (
              <>
                {otherMedsFormatted.length > 0 && (
                  <Table
                    headings={scheduledOtherMedsHeadings}
                    rows={otherMedsFormatted}
                    data-cy="medication-section-other-meds-table"
                    tableCellProps={{ size: 'small' }}
                  />
                )}
                {supplementsFormatted.length > 0 && (
                  <Table
                    headings={scheduledSupplementsHeadings}
                    rows={supplementsFormatted}
                    data-cy="medication-section-supplements-table"
                    tableCellProps={{ size: 'small' }}
                  />
                )}
              </>
            )}
            {asNeededFormatted.length > 0 && (
              <Table
                headings={scheduledAsNeededHeadings}
                rows={asNeededFormatted}
                data-cy="medication-section-scheduled-as-needed-table"
                tableCellProps={{ size: 'small' }}
              />
            )}
          </Stack>
        </div>
      )}
    </>
  )
}

export default MedicationScheduledTables
