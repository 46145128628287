import { useQuery } from '@apollo/client'
import { GET_PATIENT_SUMMARY } from '../screens/Patient/queries'

/**
 * Get a Patient's event summary information
 * @param {string} patientId, A string corresponding to the patient's id.
 * @param {string} startTime, A timestamp in seconds; e.g. 1654128000
 * @param {string} endTime, A timestamp in seconds; e.g. 1655337599
 * @param {object} includeFilters, an object of filters to include
 * @return {{loadingEvents, errorEvents, dataEvents, fetchMore}} loadingEvents is the loading state of the request returned by useQuery. errorEvents is the error state of the request, if any, returned by useQuery. dataEvents is an object with a patient; the patient object contains and eventList object with events (an array of events) and pageInfo (which includes an endCursor that indicates the # of events retrieved so far). fetchMore is a function provided by the useQuery hook; it is the recommended way to send followup queries with Apollo Client. https://www.apollographql.com/docs/react/pagination/core-api/
 */
export const usePatientEvents = (
  patientId,
  startTime,
  endTime,
  includeFilters,
) => {
  const variables = {
    id: patientId,
    startTime,
    endTime,
    includeFilters,
  }
  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
    fetchMore,
  } = useQuery(GET_PATIENT_SUMMARY, {
    variables,
    fetchPolicy: 'cache-and-network',
  })
  return { loadingEvents, errorEvents, dataEvents, fetchMore }
}

export default usePatientEvents
