import { Stack } from 'ui/baseComponents/Stack'
import './PageHeader.css'
import { node } from 'prop-types'

const PageHeader = ({ title, contentRight }) => (
  <Stack
    direction="row"
    className="pageHeaderContainer"
    alignItems="center"
    justifyContent="space-between"
    sx={{
      '@media print': {
        display: 'none',
      },
    }}
  >
    <div>{title}</div>
    <Stack direction="row">{contentRight}</Stack>
  </Stack>
)

PageHeader.propTypes = {
  title: node.isRequired,
  contentRight: node,
}

export default PageHeader
