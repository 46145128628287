import { memo } from 'react'
import { Box, Divider, makeStyles, Typography } from '@material-ui/core'
import add from 'assets/img/add.svg'
import pill from 'assets/img/pill.svg'
import watch from 'assets/img/watch.svg'
import { SECTION_TITLE } from 'ui/clinicianScreens/Patient/consts'
import { RUNE_GRAY_800, RUNE_WHITE } from 'theme/colors'
import ColoredCirclesList from './ColoredCirclesList'
import DataTypesList from './DataTypesList'
import { LEFT_COLUMN_WIDTH } from './consts'

const useStyles = makeStyles((theme) => ({
  boxBorder: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  boxStyle: {
    display: 'flex',
    width: '100%',
  },
  header: {
    width: '100%',
    padding: '1rem 1.5rem',
    backgroundColor: `${theme.palette.background.legend}`,
  },
  typography: {
    fontWeight: 600,
  },
  textLeftContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1rem 0 1.5rem',
    color: RUNE_GRAY_800,
    ...LEFT_COLUMN_WIDTH,
  },
}))

const LegendPopover = () => {
  const classes = useStyles()

  const selfLoggedLegendItems = {
    left: [
      { name: 'On/off period' },
      { name: 'Other logged symptoms/side effects', symbol: add },
      { name: 'Logged medications/supplements', symbol: pill },
    ],
    right: [
      { name: ['On', 'Off'], colors: ['#8AC8B2', '#D97872'] },
      {
        name: ['Mild', 'Typical', 'Severe'],
        colors: ['#ECD875', '#DFA464', '#D97872'],
      },
    ],
  }

  const sleepLegendItems = {
    right: [
      {
        name: ['Sleep', 'In bed', 'Awake'],
        colors: ['#B18BD6', '#D5C1E7', RUNE_WHITE],
      },
    ],
  }

  const watchLegendItems = {
    left: [
      { name: 'Tremor', symbol: watch },
      { name: 'Dyskinesia', symbol: watch },
    ],
    right: [
      {
        name: ['Tremor', 'No tremor'],
        colors: ['#648EAE', RUNE_WHITE],
      },
      {
        name: ['Dyskinesia', 'No dyskinesia'],
        colors: ['#648EAE', RUNE_WHITE],
      },
    ],
  }

  return (
    <div>
      <Box className={classes.header}>
        <Typography className={classes.typography}>Legend</Typography>
      </Box>
      <Box
        className={classes.boxStyle}
        aria-label={SECTION_TITLE.patientReported}
      >
        <DataTypesList
          listItems={selfLoggedLegendItems.left}
          sectionTitle={SECTION_TITLE.patientReported}
        />
        <Divider orientation="vertical" flexItem />
        <ColoredCirclesList listItems={selfLoggedLegendItems.right} />
      </Box>
      <Box className={classes.boxStyle} aria-label={SECTION_TITLE.watch}>
        <DataTypesList
          listItems={watchLegendItems.left}
          sectionTitle={SECTION_TITLE.watch}
        />
        <Divider orientation="vertical" flexItem />
        <ColoredCirclesList listItems={watchLegendItems.right} />
      </Box>
      <Box className={`${classes.boxStyle} ${classes.boxBorder}`}>
        <Box className={classes.textLeftContainer}>
          <Typography>
            Sleep data is captured from the Apple watch, Apple phone or smart
            bed.
          </Typography>
        </Box>
        <ColoredCirclesList
          listItems={sleepLegendItems.right}
          sectionTitle={SECTION_TITLE.sleep}
        />
      </Box>
    </div>
  )
}

export default memo(LegendPopover)
