import { dateFloat } from '../utils'
import { patients } from './patients'

const makeDataSessionStream = ({
  id = '74741fe4-a162-4aa1-8ef7-b012cf9aba61',
  name,
  dataSessionId,
  created = dateFloat(),
  updated = dateFloat(),
}) => ({
  id,
  name,
  dataSessionId,
  created,
  updated,
})
const makeStreams = (dataSessionId, names) =>
  names.map((name) => makeDataSessionStream({ dataSessionId, name }))
const sessionId1 = '42ce8842-b05e-4b3c-aa91-75f2cebf3d33'
const sessionId2 = '1d97b20c-ba9a-45aa-b983-77b3b3395402'
export const dataSessions = [
  {
    id: sessionId1,
    start: dateFloat('2019-06-01 12:00:00'),
    end: dateFloat('2019-06-01 13:00:00'),
    created: dateFloat('2019-06-01 15:00:00'),
    updated: dateFloat('2019-06-09 12:00:00'),
    sessionId: '6070a723-ba44-4014-a755-720658d21b6e',
    patient: patients[0],
    device: patients[0].deviceList.devices[0],
    streams: makeStreams(sessionId1, [
      'ErrorLog.json',
      'AdaptiveLog.json',
      'EventLog.json',
      'RawDataAccel.json',
      'RawDataFFT.json',
      'RawDataPower.json',
      'RawDataTD.json',
    ]),
  },
  {
    id: sessionId2,
    start: dateFloat('2019-06-01 12:00:00'),
    end: dateFloat('2019-06-01 13:00:00'),
    created: dateFloat('2019-06-01 15:00:00'),
    updated: dateFloat('2019-06-09 12:00:00'),
    sessionId: '7914ef4e-df0d-4af8-b925-18760a60fe7b',
    patient: patients[0],
    device: patients[0].deviceList.devices[0],
    streams: makeStreams(sessionId2, [
      'ErrorLog.json',
      'AdaptiveLog.json',
      'EventLog.json',
      'RawDataAccel.json',
      'RawDataFFT.json',
      'RawDataPower.json',
      'RawDataTD.json',
    ]),
  },
]
