import { useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useStreamApiBatch } from 'domains/streamApi'
import { GET_STREAM_LIST } from 'domains/carrotGraph/queries'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

/**
 * Function that links two existing hooks - one for querying the streamList and
 * one for querying the stream itself - and chains them together. Used to make
 * multiple requests matching a set of parameters.
 *
 * @param {object} args Function arguments object
 * @param {object} args.filters A collection of filters to feed to the streamList GQL query
 * @param {object} args.params Additional parameters needed for the requests
 * @param {string} args.endpointSuffix Stream API endpoint suffix
 * @param {boolean} args.skip If true, the queries are not executed
 * @param {function} args.streamFilter Optional method that returns true to include a stream and false to exclude it from further fetching
 * @returns {object} an object containing the response data as well as the loading and error states of the request
 */
export const useStreamApiV2 = ({
  filters = {},
  params = {},
  endpointSuffix = '',
  skip = false,
  streamFilter,
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState([])
  const { selectedTimezone } = useContext(DateRangeContext)

  // Fetch stream IDs that match filters
  const {
    data: graphData,
    loading: graphLoading,
    error: graphError,
  } = useQuery(GET_STREAM_LIST, {
    variables: { filters },
    fetchPolicy: 'cache-and-network',
    skip,
  })

  const filteredStreams = useMemo(
    () =>
      streamFilter
        ? (graphData?.streamList?.streams || []).filter((stream) =>
            streamFilter(stream),
          )
        : graphData?.streamList?.streams || [],
    [graphData, streamFilter],
  )

  // Construct requests from stream IDs and parameters
  const requests =
    buildV2BatchRequests({
      endpointSuffix,
      streams: filteredStreams,
      params,
    }) || []

  // Batch-fetch all stream API requests
  const {
    data: streamData,
    errors: streamErrors,
    loading: streamLoading,
  } = useStreamApiBatch(requests, selectedTimezone)

  useEffect(
    () => setLoading(graphLoading || streamLoading),
    [graphLoading, streamLoading],
  )
  useEffect(() => {
    // format errors so that we can have a nice clean array if there are errors,
    // or an empty array (which is the accepted "no errors" format) if not. This is
    // necessary in case someone wants to check the length or type of errors.
    const errors = []
    if (graphError) {
      errors.push(graphError)
    }
    if (streamErrors.length) {
      errors.push(...streamErrors)
    }
    setErrors(errors)
  }, [graphError, streamErrors])
  useEffect(() => {
    const dataWithStreamDetails = streamData?.map((streamResult, i) => ({
      ...streamResult,
      streamDetails: filteredStreams[i],
    }))
    setData(dataWithStreamDetails)
  }, [graphData, streamData, filteredStreams])

  return {
    data,
    loading,
    errors,
  }
}

/**
 * Helper function that formats streams pulled from our GQL streamList query to
 * get them ready to be requested in our v2 stream API.
 *
 * @param {object} args Request builder arguments
 * @param {string} args.endpointSuffix Request endpoint suffix
 * @param {object[]} args.streams The list of stream metadata objects
 * @param {object} args.params Additional parameters needed for the requests
 * @returns {array} an array of formatted requests
 */
export const buildV2BatchRequests = ({
  endpointSuffix,
  streams = [],
  params = {},
}) =>
  streams.map(({ id }) => {
    let endpoint = `streams/${id}`

    if (endpointSuffix) {
      endpoint = `${endpoint}/${endpointSuffix}`
    }

    return {
      endpoint,
      params: {
        ...params,
        format: 'json',
        timestamp: 'iso',
      },
      versionPrefix: '/v2/',
    }
  })

/**
 * Get the value associated with a specified parameter key.
 * @param {object[]} parameters List of parameter objects containing keys and values
 * @param {string} parameterKey Key for which the value is wanted
 * @returns {string} Value
 */
export const extractParameterValue = (parameters = [], parameterKey = '') =>
  (parameters.find(({ key }) => key === parameterKey) || {})?.value
