import { memo, useContext } from 'react'
import { Grid } from '@mui/material'

import { NoDataText } from 'ui/components/NoDataText'
import { ACTION_ITEM_TEXT, labels } from './consts'
import MobilityMetricsLink from './MobilityMetricsLink'
import CollapsibleViewsNoData from '../CollapsibleViewsNoData'
import { RecentDataAvailabilityContext } from 'ui/contexts'

const MobilitySummaryNoData = () => {
  const { data: { mobility } = {} } =
    useContext(RecentDataAvailabilityContext) || {}

  return (
    <Grid container spacing={1}>
      <Grid item sx={{ width: '100%' }}>
        <MobilityMetricsLink />
      </Grid>
      <Grid item>
        <NoDataText
          text={
            <CollapsibleViewsNoData
              text={labels.NO_DATA}
              latestActivityDate={mobility}
              alertType="Mobility"
            />
          }
        />
      </Grid>
      <Grid item lg={8}>
        <NoDataText text={ACTION_ITEM_TEXT} />
      </Grid>
    </Grid>
  )
}

export default memo(MobilitySummaryNoData)
