import { Text } from 'ui/baseComponents/Text'

const StyledTitle = ({ children }) => {
  const STYLES = {
    title: {
      lineHeight: 2,
    },
  }
  return (
    <Text variant="head24" sx={STYLES.title}>
      {children}
    </Text>
  )
}

export default StyledTitle
