import { useContext, useMemo } from 'react'
import moment from 'moment-timezone'
import useBoundStore from 'domains/zustand/store'
import { Alert } from 'ui/baseComponents/Alert'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

const DateJumpAlert = ({ latestActivityDate }) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const hideDateJumpAlert = useBoundStore((state) => state.hideDateJumpAlert)
  const dateJumpAlert = useBoundStore((state) => state.dateJumpAlert)
  const getAlertContent = useMemo(() => {
    const alertLatestActivityDate = () => {
      const now = moment.tz(selectedTimezone)
      const momentLatestActivityDate = moment
        .unix(latestActivityDate)
        .tz(selectedTimezone)

      const weekDiff = now.diff(momentLatestActivityDate, 'weeks')
      const monthDiff = now.diff(momentLatestActivityDate, 'months')
      const weekPluralized = weekDiff === 1 ? 'week' : 'weeks'
      const monthPluralized = monthDiff === 1 ? 'month' : 'months'

      if (weekDiff > 6) {
        return `${monthDiff} ${monthPluralized}`
      }
      return `${weekDiff} ${weekPluralized}`
    }

    if (dateJumpAlert === 'auto') {
      return (
        <>
          Time period has been adjusted to
          <strong> {alertLatestActivityDate()} ago</strong> to reflect the
          patient's latest available data.
        </>
      )
    }

    return `Time period has been adjusted to show the latest data available for ${dateJumpAlert}.`
  }, [latestActivityDate, dateJumpAlert, selectedTimezone])

  return (
    <Alert severity="warning" onClose={hideDateJumpAlert}>
      {getAlertContent}
    </Alert>
  )
}

export default DateJumpAlert
