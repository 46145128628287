import { Text, Table } from 'ui/baseComponents'
import { formatTableDataObject } from './helpers'
import ReportTitle from 'ui/clinicianScreens/Patient/Report/components/ReportTitle'
import {
  LOGGED_TABLE_HEADINGS,
  LOGGED_TABLE_TITLES,
  TABLE_STYLES,
} from 'ui/clinicianScreens/Patient/MedicationTables/consts'

const MedicationLoggedTables = ({
  data = new Map(),
  showPDMedsOnly,
  minimalVersion,
}) => {
  const STYLES = TABLE_STYLES(minimalVersion)

  const formatAsNeededMeds = () => {
    const values = [...data.values()]
    const result = values.map((med) => {
      const { name, dosage, count, prevCount } = med

      return formatTableDataObject({
        id: name + dosage,
        name: name,
        customData: minimalVersion
          ? [dosage, count]
          : [dosage, count, prevCount],
        customStyles: [
          STYLES.firstColumn,
          STYLES.secondColumn,
          STYLES.thirdColumn,
          minimalVersion ? STYLES.emptyColumn : STYLES.fourthColumn,
          minimalVersion ? STYLES.emptyColumn : {},
        ],
      })
    })

    const sortedByCurrentCount = result?.sort((a, b) => b[2].value - a[2].value)
    return sortedByCurrentCount
  }

  const loggedAsNeededHeadings = [
    {
      key: 'logs-as-needed',
      value: showPDMedsOnly
        ? LOGGED_TABLE_TITLES.pdMedications
        : LOGGED_TABLE_TITLES.medications,
      sx: STYLES.firstColumn,
    },
    {
      key: 'logs-dosage',
      value: LOGGED_TABLE_HEADINGS.dosage,
      sx: STYLES.secondColumn,
    },
    {
      key: 'logs-recent-period',
      value: LOGGED_TABLE_HEADINGS.logs,
      sx: STYLES.thirdColumn,
    },
    {
      key: 'logs-previous-period',
      value: minimalVersion ? '' : LOGGED_TABLE_HEADINGS.previousPeriod,
      sx: minimalVersion ? STYLES.emptyColumn : STYLES.fourthColumn,
    },
    {
      key: 'logs-change',
      value: '',
      sx: minimalVersion ? STYLES.emptyColumn : {},
    },
  ]

  const asNeededLog = formatAsNeededMeds()
  const hasLoggedAsNeededData = asNeededLog.length > 0

  return (
    <>
      {hasLoggedAsNeededData && (
        <div style={STYLES.table} data-cy="medication-section-logged-as-needed">
          {!minimalVersion && (
            <ReportTitle>
              <Text variant="body14B" component="h3">
                As needed medications logged
              </Text>
            </ReportTitle>
          )}
          <div style={STYLES.tableContent}>
            <Table
              headings={loggedAsNeededHeadings}
              rows={asNeededLog}
              data-cy="medication-section-logged-as-needed-table"
              tableCellProps={{ size: 'small' }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default MedicationLoggedTables
