import {
  GENERIC_ERROR_TEXT,
  MIN,
  MAX,
  NUMBER_DAYS_WITH_DATA,
  NO_DATA_TEXT,
} from '../consts'

export const labels = {
  TITLE: 'Tremor & Dyskinesia',
  FETCHING_TEXT: 'Loading Tremor & Dyskinesia',
  ERROR_TEXT: GENERIC_ERROR_TEXT,
  NO_DATA_TEXT: NO_DATA_TEXT,
  NO_DEVICE_TEXT: 'No watch is paired with this patient.',
  AVG_TREMOR_TIME: 'Avg. Tremor per day',
  AVG_DYSKINESIA_TIME: 'Avg. Dyskinesia per day',
  MIN,
  MAX,
  NUMBER_DAYS_WITH_DATA,
  LINK_TEXT: 'Open Daily View',
  TOOLTIP_LIST_FOR_TREMOR: [
    'Average total tremor time per day within the selected time period',
  ],
  TOOLTIP_LIST_FOR_DYSKINESIA: [
    'Average total dyskinesia time per day within the selected time period',
  ],
  TOOLTIP_LIST_FOR_CHART_TITLE: [
    'Shows how often the patient experiencing the given symptom at different timepoint within the selected time period',
    'Data is recorded when the patient has their Apple Watch on their wrist',
  ],
}
