import React from 'react'
import EmptyResults from '../EmptyResults'
import { InfoCard } from 'ui/components/Card'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'

/**
 * The reusable table component for the summary view
 * @param {array} children The child components passed in to the container
 * @param {object} classes The styles to be applied to the container
 * @param {object} tableTitle The title to be applied to the container
 * @param {string} dataCyForTable The data-cy for the container for ease of testing
 * @param {object} action If there is a link to another page/table it will be passed in here
 * @returns {JSX.Element}
 */
const SummaryTableCard = ({
  children,
  classes,
  tableTitle,
  dataCyForTable,
  action,
  subTitle,
  separateTableTitle = null,
}) => {
  //The population of the table along with formatting
  return (
    <InfoCard
      className={classes.card}
      title={tableTitle}
      action={action}
      subtitle={subTitle ? subTitle : ''}
    >
      <Box>
        <Table aria-label="simple table" data-cy={dataCyForTable}>
          {separateTableTitle && (
            <TableHead>
              <TableRow className={classes.tableCell}>
                <TableCell className={classes.tableCell}>
                  <span className={classes.subTitle}>{separateTableTitle}</span>
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {!children || children.length === 0 ? (
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell} colSpan={5}>
                  <EmptyResults message="None logged" />
                </TableCell>
              </TableRow>
            ) : (
              children.map((child) => child)
            )}
          </TableBody>
        </Table>
      </Box>
    </InfoCard>
  )
}

SummaryTableCard.propTypes = {
  children: PropTypes.array,
  classes: PropTypes.object,
  dataCyForTable: PropTypes.string,
  action: PropTypes.object,
}

export default SummaryTableCard
