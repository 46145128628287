import { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'

import CognitoUserPoolContext from 'ui/contexts/CognitoUserPoolContext'

import { getJwt } from './api'
import { unauthenticateUser } from './actions'
import { getAutomatedUserJwt } from 'utilities/automatedUser'

/**
 * Callback used as Apollo client link to set Rune API authentication headers.
 */
export const getAuthHeaders = async (userPool) => {
  const automatedUserJwt = getAutomatedUserJwt()
  if (automatedUserJwt) {
    return {
      'X-Rune-Internal-Service-Access-Token': automatedUserJwt,
    }
  }

  const user = userPool.getCurrentUser()

  if (!user) {
    return Promise.reject('Cognito user required for API auth')
  }

  const token = await getJwt(user)

  return {
    'X-Rune-User-Access-Token': token,
  }
}

/**
 * State hook for fetching Rune API authenticaton headers.
 */
export const useGetHeaders = () => {
  const cognitoUserPool = useContext(CognitoUserPoolContext)
  return () => getAuthHeaders(cognitoUserPool)
}

/**
 * use a callback for handling cases where user is no longer authenticated
 * @return {function(): *}
 */
export const useHandleUnauthenticated = () => {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(unauthenticateUser()), [dispatch])
}
