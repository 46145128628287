import { useCallback, useState } from 'react'
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

/**
 * Menu component for selecting additional stream options
 *
 * @param {string} id the stream ID, used only for unique menu keys
 * @param {function} openCopyModal function that opens the copy device modal
 */
export const StreamMenu = ({ id, openCopyModal }) => {
  const [anchorEl, setAnchorEl] = useState(null) // Used for <Menu>

  /**
   * Opens the <Menu> component to display options
   *
   * @param {event} event the click event
   */
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  /**
   * Closes the <Menu> component
   */
  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  /**
   * Opens the dialog and closes the <Menu> component
   */
  const handleItemClick = useCallback(() => {
    openCopyModal()
    setAnchorEl(null)
  }, [openCopyModal])

  return (
    <Box display="flex" mt={2}>
      <Tooltip title="More Options">
        <IconButton
          data-cy="stream-more-options-button"
          aria-label={'more options for stream ' + id}
          aria-controls={'more-options-stream-' + id}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>

      <Menu
        id={'more-options-stream-' + id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleItemClick}>Copy Device ID</MenuItem>
      </Menu>
    </Box>
  )
}

export default StreamMenu
