import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Button as MaterialButton,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  progress: {
    marginLeft: theme.spacing(1),
  },
}))

const Button = ({ children, loading, to, ...props }) => {
  const classes = useStyles()

  const CustomLink = to
    ? forwardRef((ps, ref) => <Link ref={ref} to={to} {...ps} />)
    : undefined // eslint-disable-line no-undefined

  return (
    <MaterialButton component={CustomLink} {...props} data-cy="button">
      {children}
      {loading && (
        <CircularProgress
          color="inherit"
          size={15}
          thickness={3}
          className={classes.progress}
        />
      )}
    </MaterialButton>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  /**
   * Button color
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'default', 'inherit']),
  /**
   * Show loading icon
   */
  loading: PropTypes.bool,
  /**
   * Button size
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * internal link destination for button
   */
  to: PropTypes.string,
  /**
   * Button type
   */
  variant: PropTypes.oneOf(['outlined', 'contained', 'text']),
}
Button.defaultProps = {
  children: null,
  color: 'primary',
  loading: false,
  variant: 'text',
}

export default Button
