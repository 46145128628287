import { memo } from 'react'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import ErrorFrame from 'ui/components/ErrorFrame'
import { FullWidthRow } from 'ui/screens/Patients/PatientList/PatientTable'
import { TABLE_STATUS_TEXT } from 'ui/screens/Patients/PatientList/PatientTable/consts'

/**
 * Renders an error message when there is a problem loading the patient list.
 *
 * @param {Object} props - The component props.
 * @param {String} props.error - The error.
 * @returns {JSX.Element} The rendered component.
 */
const PatientTableError = ({ error }) => {
  console.error(error)
  return (
    <FullWidthRow>
      <ErrorBoundary>
        <ErrorFrame error={TABLE_STATUS_TEXT.error} />
      </ErrorBoundary>
    </FullWidthRow>
  )
}

export default memo(PatientTableError)
