import { gql } from '@apollo/client'

export const CREATE_PATIENT_ACCESS_BY_DEVICE = gql`
  mutation createPatientAccessByDevice($input: CreatePatientAccessInput!) {
    createPatientAccess(input: $input) {
      patientAccess {
        id
        org {
          id
        }
        patient {
          id
          codeName
          deviceList {
            devices {
              deviceShortId
            }
          }
        }
      }
    }
  }
`
