import React from 'react'
import Loading from '../Loading/Loading'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'
import { HEADER_HEIGHT } from '../MainNav'
import DefaultLayout from '../DefaultLayout'

const useStyles = makeStyles(() => ({
  root: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
}))
const LoadingPage = (props) => {
  const classes = useStyles()
  return (
    <DefaultLayout>
      <Box
        {...props}
        className={classes.root}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <Loading />
        </Box>
      </Box>
    </DefaultLayout>
  )
}

export default LoadingPage
