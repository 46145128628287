import React from 'react'
import { Link } from '@material-ui/core'
import { string } from 'prop-types'

const PrivacyNoticeLink = ({ color, underline }) => (
  <Link
    href="https://www.runelabs.io/privacy-policy"
    target="_blank"
    rel="noopener noreferrer"
    color={color}
    underline={underline}
  >
    Privacy Notice
  </Link>
)

export default PrivacyNoticeLink

PrivacyNoticeLink.propTypes = {
  color: string,
  underline: string,
}

PrivacyNoticeLink.defaultProps = {
  color: 'primary',
  underline: 'none',
}
