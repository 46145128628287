import { useQuery } from '@apollo/client'
import { GET_PATIENT_ADHERENCE } from '../screens/Patient/queries'

/**
 * Get a Patient's adherence information
 * @param {string} patientId, A string corresponding to the patient's id.
 * @param {string} startTime, A timestamp in seconds; e.g. 1654128000
 * @param {string} endTime, A timestamp in seconds; e.g. 1655337599
 * @return {{loadingAdherence, errorAdherence, dataAdherence}} loadingAherence is the loading state of the request returned by useQuery. errorAdherence is the error state of the request, if any, returned by useQuery. dataAdherence is an object with a key of patient whose value is an object containing eventAdherence and id (corresponding to the patient id). eventAdherence contains the following properties: adherenceCount, adherencePercentage, classification, customDetail, displayName, nonAdherenceCount, and previousAdherencePercentage.
 */
const usePatientAdherence = (patientId, startTime, endTime) => {
  const {
    loading: loadingAdherence,
    error: errorAdherence,
    data: dataAdherence,
  } = useQuery(GET_PATIENT_ADHERENCE, {
    // @TODO for now the filters are hardcoded but they'll be dynamic later
    variables: {
      id: patientId,
      startTime: startTime,
      endTime: endTime,
      includeFilters: [
        { namespace: 'patient', category: 'medication', enum: '*' },
      ],
    },
    fetchPolicy: 'cache-and-network',
  })

  return { loadingAdherence, errorAdherence, dataAdherence }
}

export default usePatientAdherence
