import { createTheme, responsiveFontSizes } from '@material-ui/core'
import {
  INFOGRAPHIC_COLORS,
  RESEARCHER_PORTAL_PRIMARY_GREEN,
  RUNE_GRAY_300,
  RUNE_GRAY_500,
  RUNE_WHITE,
} from './colors'

export const researcherThemeMUIV4 = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: RESEARCHER_PORTAL_PRIMARY_GREEN,
        warning: '#ffa000',
        contrastText: RUNE_WHITE,
        transparent: 'transparent',
      },
      secondary: {
        main: '#ea80fc',
        veryLight: '#EEEDEA',
        light: '#CCCBC9',
        medium: '#706F6D',
        darker: '#2D2D2B',
      },
      text: {
        primary: '#333333',
      },
      background: {
        default: '#f4f4f4',
        legend: '#fdfcfa',
      },
      ...INFOGRAPHIC_COLORS,
      default: {
        main: RUNE_WHITE,
      },
    },
    props: {
      MuiToolbar: {
        variant: 'regular',
      },
    },
    typography: {
      fontFamily: '"IBM Plex Sans", sans-serif',
      fontSize: 14,
      fontWeight: 400,
      // 1.333 - Perfect fourth (https://type-scale.com/)
      h1: {
        fontWeight: 300,
      },
      h2: {
        fontSize: '5rem',
        fontWeight: 300,
      },
      h3: {
        fontSize: '4rem',
        fontWeight: 300,
      },
      h4: {
        fontSize: '3rem',
        fontWeight: 300,
      },
      h5: {
        fontSize: '1.5rem',
        fontWeight: 400,
      },
      h6: {
        fontSize: '1.15rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      button: {
        fontFamily: '"Work Sans", sans-serif',
        fontWeight: 600,
        letterSpacing: 2,
      },
    },
    header: {
      height: '73px',
      boxShadow: 'none',
      borderBottom: 'none',
      tabs: {
        indicator: RUNE_WHITE,
      },
    },
    tableRow: {
      height: '63px',
    },
    imageIcon: {
      height: '100%',
      marginLeft: '-3px',
      marginTop: '0px',
    },
    iconRoot: {
      textAlign: 'center',
      height: '2em',
      width: '2em',
    },
    filterCell: {
      minWidth: '250px',
      position: 'relative',
      backgroundColor: 'inherit',
      color: 'inherit',
    },
    createEntity: {
      backgroundColor: 'inherit',
      color: 'inherit',
      justifyContent: 'center',
      height: 'inherit',
      button: {
        color: RUNE_WHITE,
      },
    },
    taskNotifications: {
      button: {
        backgroundColor: RUNE_WHITE,
        spacing: '0',
        hoverColor: '#f4f4f4',
        topMargin: '24px',
      },
    },
    taskNotificationsMiniLabel: {
      color: '#706f6d',
      fontSize: '0.75rem',
      paddingTop: '0.5rem',
    },
    patientListIcon: {
      fill: 'hsla(270, 60%, 50%, 0.25)',
    },
    overrides: {
      MuiRadio: {
        root: {
          color: RESEARCHER_PORTAL_PRIMARY_GREEN,
        },
        colorSecondary: {
          '&$checked': {
            color: RESEARCHER_PORTAL_PRIMARY_GREEN,
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          color: RUNE_WHITE,
        },
        colorSecondary: {
          '&$checked': {
            color: RUNE_WHITE,
            '& + $track': {
              opacity: 1,
              backgroundColor: RESEARCHER_PORTAL_PRIMARY_GREEN,
              borderColor: RESEARCHER_PORTAL_PRIMARY_GREEN,
            },
          },
          '&$disabled': {
            color: RUNE_GRAY_300,
          },
        },
        track: {
          opacity: 1,
          backgroundColor: RUNE_GRAY_500,
        },
      },
    },
  }),
)
