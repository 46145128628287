import { Chip, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  chip: {
    color: '#FFFFFF',
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0.5),
    backgroundColor: '#DE2BCC',
    borderRadius: 8,
    fontWeight: 700,
  },
}))

const BetaTag = () => {
  const classes = useStyles()

  return <Chip size="small" label="NEW FEATURE" className={classes.chip} />
}

BetaTag.propTypes = {}

BetaTag.defaultProps = {}

export default BetaTag
