import { Box, Stack } from '@mui/material'
import { Text } from 'ui/baseComponents/Text'
import { colors } from 'theme/colors'
import { Fragment, useCallback, useEffect, useState } from 'react'
import {
  MEDICATION_OR_SUPPLEMENT_LABEL,
  SYMPTOM_OR_SIDE_EFFECT_LABEL,
} from './consts'

const EventHover = ({ hoverText, yLabel }) => {
  // Medication/Supplement and Symptom/Side Effect Formatting
  if (
    yLabel === MEDICATION_OR_SUPPLEMENT_LABEL ||
    yLabel === SYMPTOM_OR_SIDE_EFFECT_LABEL
  ) {
    return (
      <Stack spacing={1}>
        {hoverText.map((event, index) => {
          const { eventText, eventQuantifier, eventDetails } = event
          return (
            <Text
              component="p"
              variant="body14"
              key={index}
              sx={{ color: colors.GREY[800] }}
            >
              <Text variant="body14B">{eventText}</Text>
              {eventQuantifier && ` - ${eventQuantifier}`}
              {eventDetails?.map((eventDetail) => (
                <Text
                  component="p"
                  variant="body14"
                  sx={{ color: colors.GREY[600] }}
                >
                  {eventDetail}
                </Text>
              ))}
            </Text>
          )
        })}
      </Stack>
    )
  }

  // Standard formatting
  const [{ eventText, eventTime }] = hoverText
  return (
    <Fragment key={0}>
      <Text variant="body14">Time: </Text>
      <Text variant="body14B">{eventTime}</Text>
      <br />
      <Text variant="body14">{eventText}</Text>
    </Fragment>
  )
}

const PlotHover = ({ elementRef, plotted }) => {
  const [showHover, setShowHover] = useState(false)
  const [hoverText, setHoverText] = useState([])
  const [pointBbox, setPointBbox] = useState({ x0: 0, x1: 0, y0: 0, y1: 0 })
  const [hoverRight, setHoverRight] = useState(true)
  const [yLabel, setYLabel] = useState('')

  /**
   * Sets data to display on hover.
   *
   * @param {object} event The mouse event
   * @returns {void} void
   */
  const onHover = useCallback((event) => {
    const point = event?.points[0]
    const pointData = point?.data
    const hoverData = pointData?.text || []
    const pointY = point?.y || ''
    const startDate = pointData?.x[0] || ''
    const { x0, x1, y0, y1 } = point.bbox || { x0: 0, x1: 0, y0: 0, y1: 0 }
    // Hover box to right of point if before noon, to the left if after noon
    const hoverRight = new Date(startDate).getHours() < 12
    setHoverText(hoverData)
    setPointBbox({ x0, x1, y0, y1 })
    setHoverRight(hoverRight)
    setShowHover(true)
    setYLabel(pointY)
  }, [])

  /**
   * Hides the hovertext component
   * @returns {void} void
   */
  const onUnhover = useCallback(() => {
    setShowHover(false)
  }, [])

  useEffect(() => {
    const elementRefCurrent = elementRef?.current
    if (elementRefCurrent && plotted) {
      elementRefCurrent?.el.on('plotly_hover', onHover)
      elementRefCurrent?.el.on('plotly_unhover', onUnhover)
    }
  }, [elementRef, onHover, onUnhover, plotted])

  const { x0, x1, y0, y1 } = pointBbox
  const xWidth = x1 - x0
  const xCenter = (x1 + x0) / 2
  const yCenter = (y1 + y0) / 2
  const x = hoverRight ? xCenter + xWidth * 2 : xCenter - xWidth * 2
  const y = yCenter

  if (!showHover) {
    return <Fragment />
  }

  const STYLES = {
    border: `1px solid ${colors.GREY[300]}`,
    position: 'absolute',
    top: y,
    left: x,
    background: colors.WHITE,
    ...(hoverRight
      ? { transform: 'translateY(-50%)' }
      : { transform: 'translate(-100%, -50%)' }),
    padding: '0.25em 0.5em',
    ...(showHover ?? { display: 'none' }),
    pointerEvents: 'none',
    width: 'max-content',
  }

  return (
    <Box sx={STYLES}>
      <EventHover hoverText={hoverText} yLabel={yLabel} />
    </Box>
  )
}

export default PlotHover
