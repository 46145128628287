import { useContext } from 'react'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import FormFieldWrapper from 'ui/components/FormFieldWrapper'
import { TASK_ACTION_NONE, TASK_ACTION_EXTERNAL_LINK } from '../../../consts'
import { TaskNotificationContext } from 'ui/contexts'
import {
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  doubleLabelPad: {
    paddingTop: theme.spacing(0.5),
  },
  miniLabel: theme.taskNotificationsMiniLabel,
  selectToggle: {
    width: 150,
    marginRight: theme.spacing(2),
  },
}))

/**
 * The modal for creating a Task Notification.
 */
export const TaskActions = () => {
  const classes = useStyles()
  const {
    taskActions,
    taskExternalLinkTitle,
    taskExternalLinkURL,
    onTaskActionsChange,
    onTaskExternalLinkTitleChange,
    onTaskExternalLinkURLChange,
  } = useContext(TaskNotificationContext)

  return (
    <ErrorBoundary>
      <FormFieldWrapper label="Actions">
        <Box mb={1}>
          <FormControl variant="standard">
            <InputLabel className={classes.doubleLabelPad}>
              Action Type
            </InputLabel>
            <Select
              id="taskActions"
              name="Task Actions"
              data-testid="taskActions"
              label="Action Type"
              value={taskActions}
              onChange={onTaskActionsChange}
              className={classes.selectToggle}
              inputProps={{
                'aria-label': 'Action Type',
                'data-testid': 'actionTypeInput',
              }}
            >
              <MenuItem key={TASK_ACTION_NONE} value={TASK_ACTION_NONE}>
                None
              </MenuItem>
              <MenuItem
                key={TASK_ACTION_EXTERNAL_LINK}
                value={TASK_ACTION_EXTERNAL_LINK}
              >
                External Link
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        {taskActions === TASK_ACTION_EXTERNAL_LINK && (
          <>
            <Box mb={1}>
              <FormControl component="fieldset" fullWidth={true}>
                <FormLabel component="legend" className={classes.miniLabel}>
                  Title
                </FormLabel>
                <TextField
                  id="taskExternalLinkTitle"
                  data-testid="taskExternalLinkTitle"
                  fullWidth={true}
                  value={taskExternalLinkTitle}
                  placeholder="Name of the webpage"
                  onChange={onTaskExternalLinkTitleChange}
                  inputProps={{
                    maxLength: 27,
                    'aria-label': 'Task Actions Link Title Field',
                  }}
                />
              </FormControl>
            </Box>

            <Box mb={1}>
              <FormControl component="fieldset" fullWidth={true}>
                <FormLabel component="legend" className={classes.miniLabel}>
                  URL
                </FormLabel>
                <TextField
                  id="taskExternalLinkURL"
                  data-testid="taskExternalLinkURL"
                  fullWidth={true}
                  value={taskExternalLinkURL}
                  placeholder="hhtp://www."
                  onChange={onTaskExternalLinkURLChange}
                  multiline
                  inputProps={{ 'aria-label': 'Task Actions Link URL Field' }}
                />
              </FormControl>
            </Box>
          </>
        )}
      </FormFieldWrapper>
    </ErrorBoundary>
  )
}

export default TaskActions
