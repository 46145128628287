import { getJwt } from '../auth/api'
import qs from 'qs'

/**
 * Get download info for a Stream API request
 *
 * @param endpoint
 * @param params
 * @param filename
 * @param streamApiClient
 * @param cognitoUser
 * @return {Promise<string>}
 */
export const getDownloadUrl = async function ({
  endpoint,
  params,
  filename,
  streamApiClient,
  cognitoUserPool,
}) {
  if (!cognitoUserPool) {
    throw new Error('Cognito user pool required')
  }

  const jwt = await getJwt(cognitoUserPool.getCurrentUser())
  const baseUrl = `${streamApiClient.defaults.baseURL}/${endpoint}`
  const { frequency, interpolation, resolution, ...cleanedParams } = params
  const queryString = qs.stringify(
    {
      ...cleanedParams,
      user_access_token: jwt,
      filename,
    },
    { encode: false, arrayFormat: 'comma' },
  )

  return `${baseUrl}?${queryString}`
}
