import moment from 'moment-timezone'
import { EMOJI, PLOT_CONFIG } from '../consts'
import { DATETIME_FORMAT_SHORT_WITHOUT_SECONDS } from 'ui/consts'
import { toTitleCase } from 'utilities/string'

const v2DataToPlotlyData = (symptomName, selectedTimezone) => (data) => {
  const { startTime, endTime, value } = data
  if (!value?.[0]?.value) {
    return null
  }

  const dateStart = moment
    .tz(startTime, selectedTimezone)
    .format(DATETIME_FORMAT_SHORT_WITHOUT_SECONDS)

  const dateEnd = moment
    .tz(endTime, selectedTimezone)
    .format(DATETIME_FORMAT_SHORT_WITHOUT_SECONDS)

  const prettyName = toTitleCase(symptomName)
  const yAxisTitle = `${EMOJI.watch} ${prettyName}`

  const text = { eventTime: dateStart, eventText: prettyName }

  const plotData = {
    plotData: {
      ...PLOT_CONFIG.plotData,
      name: symptomName,
      text: [text],
      x: [dateStart, dateEnd],
      y: [yAxisTitle],
    },
  }

  return plotData
}

export const formatV2MotorSymptomsDataForPlotting = (
  motorSymptomData,
  selectedTimezone,
) =>
  Object.keys(motorSymptomData).reduce((acc, symptomName) => {
    const formatted = motorSymptomData[symptomName].data
      .map(v2DataToPlotlyData(symptomName, selectedTimezone))
      .filter((possibleValue) => possibleValue !== null)
    return acc.concat(formatted)
  }, [])
