import React from 'react'
import { array, number, oneOfType, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import LegendScaleWrapper from './LegendScaleWrapper'
import LegendScaleText from './LegendScaleText'

const useStyles = makeStyles(() => ({
  legendColors: (colors) => ({
    borderRadius: '20px',
    height: '4px',
    background: `linear-gradient(to right, ${colors
      .map(([_, color]) => color)
      .join()})`,
  }),
}))

const DefaultScale = ({ ariaLabel, colors, minText, maxText }) => {
  const classes = useStyles(colors)

  return (
    <>
      <LegendScaleText testId="default-scale-min-label">
        {minText}
      </LegendScaleText>
      <LegendScaleWrapper>
        <div
          className={classes.legendColors}
          aria-label={ariaLabel}
          data-cy="default-scale"
          data-testid="default-scale"
        />
      </LegendScaleWrapper>
      <LegendScaleText testId="default-scale-max-label">
        {maxText}
      </LegendScaleText>
    </>
  )
}

DefaultScale.propTypes = {
  ariaLabel: string.isRequired,
  colors: array.isRequired,
  minText: oneOfType([string, number]).isRequired,
  maxText: oneOfType([string, number]).isRequired,
}

DefaultScale.defaultProps = {}

export default DefaultScale
