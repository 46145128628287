import React from 'react'
import PropTypes from 'prop-types'
import { StyledKeyValuePair } from 'ui/components/StyledKeyValuePair'
import LoadingAnimation from './LoadingAnimation'

const LabelledLoading = ({ label }) => (
  <StyledKeyValuePair label={label} endIcon={<LoadingAnimation />} />
)

LabelledLoading.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

LabelledLoading.defaultProps = {
  label: null,
}

export default LabelledLoading
