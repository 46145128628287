import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import deepEqual from 'deep-equal'
import { callApi } from './api'
import {
  NEXT_PAGE_TOKEN,
  INITIAL_REVISION_NUMBER,
  REVISION_INCREMENT,
} from './consts'
import { getTimezoneName } from '../config/reducer'
import { useStreamApiClient } from './context'
import { useLogger } from '../logger/context'
import { useGetHeaders, useHandleUnauthenticated } from '../auth/hooks'
import { ConfigContext } from 'ui/contexts'
import { createClient } from 'domains/streamApi/client'

// There are other ways to do this but in this case we like the explicit setting of undefined,
// as it will ensure future developers that this is intended behavior rather than a bug.
/* eslint-disable no-undefined */
const emptyResponse = {
  data: undefined,
  currentUrl: undefined,
  currentParams: undefined,
}
/* eslint-enable no-undefined */

const V1_PREFIX = '/v1/'

export const useStreamApi = ({
  url,
  params,
  isShowLoading = () => true,
  axiosOptions = {},
  shouldExecute = true,
  method = 'GET',
  versionPrefix = V1_PREFIX,
  selectedTimezone,
}) => {
  const streamApiClient = useStreamApiClient()
  const logger = useLogger()
  const handleUnauthenticated = useHandleUnauthenticated()
  const getHeaders = useGetHeaders()
  // Store these values together so they stay in sync
  const browserTimezoneName = useSelector(getTimezoneName)
  const timezoneName = selectedTimezone ? selectedTimezone : browserTimezoneName
  const [response, setResponse] = useState(emptyResponse)
  const { data, currentParams, currentUrl } = response
  // store the params that are associated with the "current" response data
  // Allows hook consumers to compare changes in params to differentiate loading behavior
  // I.e. "if the only params that changed are start/end time, don't show the loading spinner, but if more than that changed, show it"
  const [error, setError] = useState()
  const [loading, setLoading] = useState(shouldExecute)
  const [revision, setRevision] = useState(INITIAL_REVISION_NUMBER)

  const signature = JSON.stringify({ url, params })

  useEffect(
    () => {
      /**
       * Maps the mobility aggregate data from the API to the format expected by the frontend. The function below is a hack used later in the function to let us run hooks conditionally.
       *
       * side effects: sets loading, revision, error, and response state
       *
       * @returns {undefined}
       */
      async function fetch() {
        // eslint-disable-line require-jsdoc
        // The below conditional allows us to return out of the function early and not return the response, if the shouldExecute param is false.
        if (!shouldExecute) {
          return
        }
        // This prevents us from doubly running a request with the same params,
        // using state as a check.
        const hasCurrentData =
          data && currentUrl === url && deepEqual(currentParams, params)
        if (hasCurrentData) {
          return
        }

        // The next line allows us to return loading as true until the request finishes.
        // This is the backbone of all of our loading states.
        setLoading(true)

        // Revision is returned for the benefit of our plotly data visualizations
        // for the benefit of creating plot revision history.
        setRevision(revision + REVISION_INCREMENT)

        const paramsWithTimeZone = { ...params, timezone_name: timezoneName }

        // The actual API call step, with all of the provided and conditionally set params.
        try {
          const data = await callApi({
            url: versionPrefix + url,
            params: paramsWithTimeZone,
            getHeaders,
            handleUnauthenticated,
            streamApiClient,
            axiosOptions,
            method,
          })
          // Set all values at once to avoid tricky re-renders where there's a mismatch
          setResponse({
            data,
            currentParams: params,
            currentUrl: url,
          })
        } catch (error) {
          console.error(error)
          if (logger?.error) {
            logger.error('Stream API error', { error, url, params })
          }
          setError(error)
          setResponse(emptyResponse) // important because in this case we do not want a partial return
        } finally {
          setLoading(false) // sets loading to false only when the request has finished
        }
      }
      fetch() // the exit function that allows us to conditionally not run a hook
    },
    // eslint-disable-next-line
    [url, signature, shouldExecute], // eslint escape needed to prevent useEffect from calling again if certain params update
  )

  // Covers outstanding request param changes. JSDoc block for the isShowLoading function describes this best:
  /**
   * Compare current (outstanding request) query params with those of the
   * previous request, decide whether the changes warrant displaying the
   * loading indicator or if we can just update on the fly.
   * In this case, were the changes temporal or more than just temporal?
   */
  const showLoading =
    loading && (isShowLoading(params, currentParams) || url !== currentUrl)
  return { data, currentParams, error, loading, showLoading, revision }
}

/**
 * Batch query the Stream API. Handles authentication, etc.
 *
 * @param {array} requests an array of requests to be batch sent
 * @param {string} selectedTimezone the timezone to be used for the requests (e.g. 'America/New_York')
 *
 * @returns {object} a batch of request responses
 */
export const useStreamApiBatch = (requests, selectedTimezone) => {
  const { error } = useLogger()
  const streamApiClient = useStreamApiClient()
  const getHeaders = useGetHeaders()
  const handleUnauthenticated = useHandleUnauthenticated()
  const browserTimezoneName = useSelector(getTimezoneName)
  const timezoneName = selectedTimezone ? selectedTimezone : browserTimezoneName
  const [data, setData] = useState()
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(true)

  const signature = JSON.stringify(
    requests.map(({ endpoint, params }) => ({ endpoint, params })),
  )

  useEffect(
    () => {
      if (!loading) {
        setLoading(true)
      }

      const promises = []
      for (const request of requests) {
        const versionPrefix = request.versionPrefix || V1_PREFIX
        const requestParamsWithTimezone = {
          ...request.params,
          timezone_name: timezoneName,
        }

        promises.push(
          callApi({
            url: versionPrefix + request.endpoint,
            handleUnauthenticated,
            params: requestParamsWithTimezone,
            getHeaders,
            streamApiClient,
          }).then(
            (data) => {
              if (request.success) {
                return request.success(data)
              }

              return data
            },
            (response) => {
              setErrors([...errors, response])
              error('Stream API error', ...errors)
              throw response
            },
          ),
        )
      }

      // prettier-ignore
      Promise.all(promises).then(
        (data) => {
          if (!errors.length) {
            setData(data)
          }
        },
        (error) => {
          console.error(error)
          setErrors([ ...errors, error ])
        }
      )
        .finally(() => {
          setLoading(false)
        })
    },
    // eslint-disable-next-line
    [signature, selectedTimezone],
  )

  return { data, errors, loading }
}

/**
 * Similar to useStreamApiBatch, except that it returns partial data if available.
 *
 * @param {array} requests an array of requests to be batch sent
 * @param {string} selectedTimezone the timezone to be used for the requests (e.g. 'America/New_York')
 * @returns {object} it includes data returned by successful calls, errors returned by the api, and the loading state of all the requests
 */
export const useStreamApiBatchWithPartialDataHandling = (
  requests,
  selectedTimezone,
) => {
  const streamApiClient = useStreamApiClient()
  const getHeaders = useGetHeaders()
  const handleUnauthenticated = useHandleUnauthenticated()
  const browserTimezoneName = useSelector(getTimezoneName)
  const timezoneName = selectedTimezone ? selectedTimezone : browserTimezoneName
  const [data, setData] = useState([])
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(true)
  const signature = JSON.stringify(
    requests.map(({ endpoint, params }) => ({ endpoint, params })),
  )

  useEffect(
    () => {
      if (!loading) {
        setLoading(true)
      }

      const promises = []
      for (const request of requests) {
        const versionPrefix = request.versionPrefix || V1_PREFIX
        const requestParamsWithTimezone = {
          ...request.params,
          timezone_name: timezoneName,
        }

        promises.push(
          callApi({
            url: versionPrefix + request.endpoint,
            handleUnauthenticated,
            params: requestParamsWithTimezone,
            getHeaders,
            streamApiClient,
          })
            .then((data) => {
              if (request.success) {
                return request.success(data)
              }

              return data
            })
            .catch((response) => {
              throw response
            }),
        )
      }

      Promise.allSettled(promises)
        .then((promise) => {
          const data = []
          const errors = []

          promise.forEach((item) => {
            if (item.status === 'fulfilled') {
              data.push(item.value)
            } else if (item.status === 'rejected') {
              errors.push(item.reason)
            }
          })

          setData(data)
          setErrors(errors)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    // eslint-disable-next-line
    [signature],
  )

  return { data, errors, loading }
}

/**
 * Query the Stream API with subsequent paginated api calls. Handles authentication, etc.
 *
 * @returns {object} a concatenated request response
 */
export const useStreamApiPaginated = ({
  url,
  params,
  axiosOptions = {},
  shouldExecute = true,
  method = 'GET',
  versionPrefix = V1_PREFIX,
}) => {
  const streamApiClient = useStreamApiClient()
  const logger = useLogger()
  const handleUnauthenticated = useHandleUnauthenticated()
  const getHeaders = useGetHeaders()
  const timezoneName = useSelector(getTimezoneName)

  const [response, setResponse] = useState(emptyResponse)
  const [error, setError] = useState([])
  const [loading, setLoading] = useState(shouldExecute)

  const { data } = response
  const signature = JSON.stringify({ url, params })

  useEffect(
    () => {
      const fetch = async () => {
        if (!shouldExecute) {
          setLoading(false)
          return
        }

        let batchedResponse = []
        let hasRequests = true
        let nextPageToken = ''

        setLoading(true)

        while (hasRequests) {
          try {
            const paramsWithTimezone = {
              ...params,
              timezone_name: timezoneName,
            }

            const response = await callApi({
              url: versionPrefix + url,
              params: {
                ...paramsWithTimezone,
                ...(nextPageToken && {
                  [NEXT_PAGE_TOKEN.requestHeader]: nextPageToken,
                }),
              },
              getHeaders,
              handleUnauthenticated,
              streamApiClient,
              axiosOptions,
              method,
            })

            if (response.result) {
              batchedResponse = batchedResponse.concat(response)
            }

            hasRequests = Object.prototype.hasOwnProperty.call(
              response,
              NEXT_PAGE_TOKEN.key,
            )
            nextPageToken = response?.[NEXT_PAGE_TOKEN.key] || ''
          } catch (err) {
            console.error(err)
            logger.error('Stream API error', { err, url, params })
            setError((prevState) => prevState.concat(err))
            hasRequests = false
          }
        }

        setResponse({
          data: (batchedResponse || []).map(({ result }) => result),
        })
        setLoading(false)
      }

      fetch()
    },
    // eslint-disable-next-line
    [url, signature],
  )

  return { data, error, loading }
}

export const useDataApi = ({ method, endpoint, headers, params, skip }) => {
  const handleUnauthenticated = useHandleUnauthenticated()
  const getHeaders = useGetHeaders()
  const logger = useLogger()
  const { radish: dataApiConfig } = useContext(ConfigContext)
  const dataApiClient = createClient(dataApiConfig)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  const signature = JSON.stringify({ method, endpoint, headers, params })

  useEffect(() => {
    /**
     * Maps the mobility aggregate data from the API to the format expected by the frontend. The function below is a hack used later in the function to let us run hooks conditionally.
     *
     * side effects: sets the loading state, error state, and data state
     *
     * @returns {undefined}
     */
    async function fetch() {
      // eslint-disable-line require-jsdoc
      if (skip) {
        return
      }

      let defaultHeaders = {}
      await getHeaders()
        .then((headers) => (defaultHeaders = headers))
        .catch((error) => {
          handleUnauthenticated(error)
          setLoading(false)
          setError(true)
        })

      await callApi({
        url: `/rest${V1_PREFIX}patient/${endpoint}`,
        params,
        headers: {
          ...defaultHeaders,
          ...headers,
        },
        handleUnauthenticated,
        streamApiClient: dataApiClient,
        method,
      })
        .then(({ data }) => {
          setData(data)
        })
        .catch((error) => {
          console.error(error)
          logger.error('Stream API error', { error, endpoint, params })
          setError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    fetch()
  }, [signature]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    loading,
    error,
  }
}
