import React from 'react'
import useDateRangeContext, {
  DateRangeContext,
} from 'ui/contexts/DateRangeContext'
import ClinicianPatient from './ClinicianPatient'

const ClinicianPatientWrapper = (props) => {
  const dateRangeContextValue = useDateRangeContext()

  return (
    <DateRangeContext.Provider value={dateRangeContextValue}>
      <ClinicianPatient {...props} />
    </DateRangeContext.Provider>
  )
}

export default ClinicianPatientWrapper
