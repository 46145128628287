import { gql } from '@apollo/client'

export const GET_USER = gql`
  query getUsersPatientList($cursor: Cursor, $patientListLimit: Int) {
    user {
      id
      name
      username
      email
      defaultMembership {
        id
        org {
          id
          displayName
          patientAccessList(cursor: $cursor, limit: $patientListLimit) {
            patientAccess {
              id
              patient {
                id
                codeName
                deviceList {
                  devices {
                    disabled
                  }
                }
                identifiableInfo {
                  realName
                  firstName
                  lastName
                }
              }
            }
            pageInfo {
              endCursor
            }
          }
        }
        admin
      }
    }
  }
`

// Refetch query for user's Patient List
export const useRefetchUser = () => ({ query: GET_USER })
