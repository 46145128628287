// "Device" that holds the properties necessary to enable events stream vizualization
// as an option for availability viz
export const eventsStreamDevice = {
  alias: 'Events Stream',
  deviceShortId: 'eventsStream',
  deviceType: { id: 'eventsStream', displayName: 'Events Stream' },
  disabled: false,
  id: 'eventsStream',
  identifier: 'Events Stream',
  kind: 'eventsStream',
}
