import React from 'react'
import { pushQuery as pushQueryAction } from 'query'
import { useDispatch } from 'react-redux'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dropdown: {
    width: 290,
  },
}))

/**
 * Select dropdown component for Persistent Views feature.
 *
 * @param {object} views The list of views for the patient
 * @param {object} selectedView The currently selected view
 */
const PersistentViewsSelect = ({ views, selectedView }) => {
  const dispatch = useDispatch()
  const pushQuery = (...args) => dispatch(pushQueryAction(...args))
  const classes = useStyles()
  const currentView = selectedView
  const noView = { id: 'noView' }
  const flatSelectedView = selectedView
    ? JSON.stringify(selectedView)
    : JSON.stringify(noView)

  /**
   * Function that dispatches the user to Persistent View they selected
   *
   * @param {event} event The event created from selecting a new view
   */
  const onViewChange = (event) => {
    const newView = JSON.parse(event.target.value)
    // checks if a change has been made
    if (!newView.id || newView.id === currentView.id) {
      return
    }
    // checks if the "no view" option has been selected
    if (newView.id === 'noView') {
      pushQuery({ view: '' })
      return
    }
    // otherise pushes the newly selected view info
    pushQuery({
      view: newView.id,
      streams: newView.latest.data,
    })
  }

  return (
    <Box display="flex">
      <FormControl>
        <InputLabel>Select View</InputLabel>
        <Select
          classes={{ select: classes.dropdown }}
          onChange={onViewChange}
          value={flatSelectedView}
          data-cy="PersistentViewsSelect"
        >
          <MenuItem key={noView.id} value={JSON.stringify(noView)}>
            No View Selected
          </MenuItem>
          {views &&
            views.map((view) => (
              <MenuItem key={view.id} value={JSON.stringify(view)}>
                {view.latest.displayName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  )
}
export default PersistentViewsSelect
