import { gql } from '@apollo/client'

export const CREATE_CLIENT = gql`
  mutation createClient($input: CreateClientInput!) {
    createClient(input: $input) {
      clientKey {
        clientKeyId
      }
      clientKeySecret
    }
  }
`
export const UPDATE_CLIENT = gql`
  mutation updateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      client {
        id
      }
    }
  }
`
export const CREATE_CLIENT_KEY = gql`
  mutation createClientKey($input: CreateClientKeyInput) {
    createClientKey(input: $input) {
      clientKey {
        clientKeyId
      }
      clientKeySecret
    }
  }
`
export const UPDATE_CLIENT_KEY = gql`
  mutation updateClientKey($input: UpdateClientKeyInput) {
    updateClientKey(input: $input) {
      clientKey {
        clientKeyId
        disabled
      }
    }
  }
`
