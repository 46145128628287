import React from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import styled from 'styled-components'
import { RUNE_GRAY_800 } from 'theme/colors.js'
import FormControl from './FormControl'

const ConfirmationTextField = styled(TextField)({
  '& .MuiFormLabel-root': {
    color: '#357B7F',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    color: RUNE_GRAY_800,
  },
})

const SuccessDialog = ({
  codeName,
  deviceId,
  patientId,
  handleClose,
  classes,
}) => (
  <Box className={classes.form}>
    <DialogTitle data-cy="Confirmation">
      <Box display="flex" justifyContent="space-between">
        Confirmation
      </Box>
    </DialogTitle>
    <DialogContent className={classes.content}>
      <Box>
        The following Patient and device are now connected. Keep this
        information for your records and for future reference.
      </Box>
      <br />
      <FormControl>
        <ConfirmationTextField
          id="patientCodeName"
          data-cy="patientCodeName"
          disabled={true}
          label="Patient Code Name"
          value={codeName}
          InputProps={{ disableUnderline: true }}
        />
        <br />
        <ConfirmationTextField
          id="deviceId"
          data-cy="deviceId"
          disabled={true}
          label="Phone Device ID"
          value={deviceId}
          InputProps={{ disableUnderline: true }}
        />
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button
        href={`/patients/${patientId}`}
        variant="outlined"
        color="primary"
        data-cy="success-dialog-button View Patient Page"
      >
        View Patient Page
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClose}
        data-cy="success-dialog-button Done"
      >
        Done
      </Button>
    </DialogActions>
  </Box>
)

export default SuccessDialog
