import { Divider, makeStyles } from '@material-ui/core'
import { useContext } from 'react'
import EmptyResultsBanner from 'ui/components/EmptyResultsBanner'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import SummaryViewsComparisonModeHeader from '../../Tabs/TabContentHeaders/Layouts/Tabs/SummaryViewsComparisonModeHeader'
import ComparisonModeCollapsibleViews from './ComparisonModeCollapsibleViews'
import {
  COMPARISON_MODE_CARD_MESSAGE,
  COMPARISON_MODE_EMPTY_STATE,
} from './consts'

const useStyles = makeStyles((theme) => ({
  collapsibleViews: {
    backgroundColor: '#FFF',
    padding: theme.spacing(25, 0),
  },
  cardViews: {
    backgroundColor: '#FFF',
    padding: theme.spacing(5, 0, 8),
  },
  divider: {
    margin: theme.spacing(3),
  },
}))

const ComparisonMode = () => {
  const { dateRanges } = useContext(DateRangeContext)
  const { start: p1start, end: p1end } =
    dateRanges[DATE_RANGE.period1.key] || {}
  const { start: p2start, end: p2end } =
    dateRanges[DATE_RANGE.period2.key] || {}
  const classes = useStyles()

  return (
    <>
      <SummaryViewsComparisonModeHeader />
      {p1start && p1end && p2start && p2end ? (
        <ComparisonModeCollapsibleViews />
      ) : (
        <EmptyResultsBanner
          message={COMPARISON_MODE_EMPTY_STATE}
          className={classes.collapsibleViews}
        />
      )}
      <Divider className={classes.divider} />
      <EmptyResultsBanner
        message={COMPARISON_MODE_CARD_MESSAGE}
        className={classes.cardViews}
      />
    </>
  )
}

export default ComparisonMode
