import { gql, useQuery } from '@apollo/client'

export const GET_PATIENT = gql`
  query getPatient($patientId: ID!, $withDisabled: Boolean) {
    patient(id: $patientId) {
      id
      codeName
      identifiableInfo {
        realName
        firstName
        lastName
        demographicData {
          dateOfBirth
          pdDiagnosisDate
        }
      }
      userInfo {
        timezone
      }
      deviceList(withDisabled: $withDisabled) {
        pageInfo {
          endCursor
        }
        devices {
          id
          deviceShortId
          deviceType {
            id
            displayName
          }
          alias
          kind
          disabled
          channels
          createdAt
        }
      }
      medicationScheduleList {
        pageInfo {
          endCursor
        }
        schedules {
          autolog
          active
          createdAt
          updatedAt
          displayName
          customDetail {
            displayName
          }
          dosage
          daysOfWeek
          id
          scheduleType
          unitQuantity
          localTime
          utcTime
          unit
          classification {
            namespace
            enum
            category
          }
        }
      }
    }
  }
`

/**
 * This query is specifically for getting the events with appropriate filters
 */
export const GET_PATIENT_SUMMARY = gql`
  query getPatientEvents(
    $id: ID!
    $cursor: Cursor
    $startTime: Float!
    $endTime: Float!
    $includeFilters: [EventClassificationFilter]
    $excludeFilters: [EventClassificationFilter]
    $limit: Int
  ) {
    patient(id: $id) {
      id
      eventList(
        cursor: $cursor
        limit: $limit
        startTime: $startTime
        endTime: $endTime
        includeFilters: $includeFilters
        excludeFilters: $excludeFilters
      ) {
        events {
          id
          classification {
            id
            namespace
            category
            enum
          }
          customDetail {
            displayName
          }
          displayName
          duration {
            startTime
            endTime
            endTimeMax
          }
          ongoing
          payload
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
`

/**
 * This query is specifically for getting the events with appropriate filters
 *
 * @param {string} id The patient ID
 * @param {number} startTime The starting of the period within which we're querying
 * @param {number} endTime The ending of the period within which we're querying
 * @param {array} includeFilters Additional filters for this query (primarily category MUST be medication)
 * @param {array} excludeFilters Additional filters for this query to be excluded
 */
export const GET_PATIENT_ADHERENCE = gql`
  query getPatientEventAdherence(
    $id: ID!
    $startTime: Float!
    $endTime: Float!
    $includeFilters: [EventClassificationFilter]
    $excludeFilters: [EventClassificationFilter]
  ) {
    patient(id: $id) {
      id
      eventAdherence(
        startTime: $startTime
        endTime: $endTime
        includeFilters: $includeFilters
        excludeFilters: $excludeFilters
      ) {
        adherenceCount
        nonAdherenceCount
        adherencePercentage
        displayName
        customDetail {
          displayName
        }
        classification {
          id
          category
          namespace
          enum
        }
        previousAdherencePercentage
      }
    }
  }
`

/**
 * This query is specifically for getting the events with appropriate filters
 */
export const GET_PATIENT_EVENT_COUNTS = gql`
  query getPatientEventCounts(
    $id: ID!
    $startTime: Float!
    $endTime: Float!
    $limit: Int!
    $includeFilters: [EventClassificationFilter]
    $excludeFilters: [EventClassificationFilter]
  ) {
    patient(id: $id) {
      id
      eventCount(
        startTime: $startTime
        endTime: $endTime
        limit: $limit
        includeFilters: $includeFilters
        excludeFilters: $excludeFilters
      ) {
        counts {
          displayName
          customDetail {
            displayName
          }
          classification {
            category
            namespace
            enum
          }
          count
        }
      }
    }
  }
`

/**
 * Get a Patient entity, along with its devices.
 * @param {string} patientId, A string corresponding to the patient's id.
 * @return {{loading, error, patient}} loading is the loading state of the request returned by useQuery. error is the error state of the request, if any, returned by useQuery. patient is an object with the following properties: codeName, deviceList, id, identifiableInfo, userInfo, and medicationScheduleList.
 */
export const usePatient = (patientId) => {
  const { loading, error, data } = useQuery(GET_PATIENT, {
    variables: { patientId, withDisabled: false },
    fetchPolicy: 'cache-and-network',
  })

  const patient = data?.patient

  return { loading, error, patient }
}

/**
 * Get a Patient's event summary information
 * @param {string} patientId, A string corresponding to the patient's id.
 * @param {string} startTime, A timestamp in seconds; e.g. 1654128000
 * @param {string} endTime, A timestamp in seconds; e.g. 1655337599
 * @return {{loadingEvents, errorEvents, dataEvents, fetchMore}} loadingEvents is the loading state of the request returned by useQuery. errorEvents is the error state of the request, if any, returned by useQuery. dataEvents is an object with a patient; the patient object contains and eventList object with events (an array of events) and pageInfo (which includes an endCursor that indicates the # of events retrieved so far). fetchMore is a function provided by the useQuery hook; it is the recommended way to send followup queries with Apollo Client. https://www.apollographql.com/docs/react/pagination/core-api/
 */
export const usePatientEvents = (patientId, startTime, endTime) => {
  const variables = {
    id: patientId,
    startTime,
    endTime,
  }
  const {
    loading: loadingEvents,
    error: errorEvents,
    data: dataEvents,
    fetchMore,
  } = useQuery(GET_PATIENT_SUMMARY, {
    variables,
    fetchPolicy: 'cache-and-network',
  })
  return { loadingEvents, errorEvents, dataEvents, fetchMore }
}

/**
 * Get a Patient's adherence information
 * @param {string} patientId, A string corresponding to the patient's id.
 * @param {string} startTime, A timestamp in seconds; e.g. 1654128000
 * @param {string} endTime, A timestamp in seconds; e.g. 1655337599
 * @return {{loadingAdherence, errorAdherence, dataAdherence}} loadingAherence is the loading state of the request returned by useQuery. errorAdherence is the error state of the request, if any, returned by useQuery. dataAdherence is an object with a key of patient whose value is an object containing eventAdherence and id (corresponding to the patient id). eventAdherence contains the following properties: adherenceCount, adherencePercentage, classification, customDetail, displayName, nonAdherenceCount, and previousAdherencePercentage.
 */
export const usePatientAdherence = (patientId, startTime, endTime) => {
  const {
    loading: loadingAdherence,
    error: errorAdherence,
    data: dataAdherence,
  } = useQuery(GET_PATIENT_ADHERENCE, {
    // @TODO for now the filters are hardcoded but they'll be dynamic later
    variables: {
      id: patientId,
      startTime: startTime,
      endTime: endTime,
      includeFilters: [
        { namespace: 'patient', category: 'medication', enum: '*' },
      ],
    },
    fetchPolicy: 'cache-and-network',
  })

  return { loadingAdherence, errorAdherence, dataAdherence }
}

/**
 * Get a Patient's event count information
 * @param {string} patientId, A string corresponding to the patient's id.
 * @param {string} startTime, A timestamp in seconds; e.g. 1654128000
 * @param {string} endTime, A timestamp in seconds; e.g. 1655337599
 * @return {{eventCountLoading, eventCountError, eventCountData}} eventCountLoading is the loading state of the request returned by useQuery. eventCountError is the error state of the request, if any, returned by useQuery. eventCountData is an object with a key of patient whose value is an object that countains eventCount and id (corresponding to the patient id). eventCounts contains another object with a key of counts and an array of objects. Each of these objects contains a classification, count, customDetail, and displayName.
 */
export const usePatientEventCount = (patientId, startTime, endTime) => {
  const {
    loading: eventCountLoading,
    error: eventCountError,
    data: eventCountData,
  } = useQuery(GET_PATIENT_EVENT_COUNTS, {
    // @TODO for now the filters are hardcoded but they'll be dynamic later
    variables: {
      id: patientId,
      startTime: startTime,
      endTime: endTime,
      limit: 5,
      includeFilters: [
        { namespace: 'patient', category: 'symptom', enum: '*' },
      ],
    },
    fetchPolicy: 'cache-and-network',
  })

  return { eventCountLoading, eventCountError, eventCountData }
}

/**
 * Get a Patient's activity log count information
 * @param {string} patientId, A string corresponding to the patient's id.
 * @param {string} startTime, A timestamp in seconds; e.g. 1654128000
 * @param {string} endTime, A timestamp in seconds; e.g. 1655337599
 * @return {{activityCountLoading, activityCountError, activityCountData}} activityCountLoading is the loading state of the request returned by useQuery. activityCountError is the error state of the request, if any, returned by useQuery. activityCountData is an object with a key of patient whose value is an object that countains activityCount and id (corresponding to the patient id). activityCounts contains another object with a key of counts and an array of objects. Each of these objects contains a classification, count, customDetail, and displayName.
 */
export const usePatientActivityCount = (patientId, startTime, endTime) => {
  const {
    loading: activityCountLoading,
    error: activityCountError,
    data: activityCountData,
  } = useQuery(GET_PATIENT_EVENT_COUNTS, {
    // @TODO for now the filters are hardcoded but they'll be dynamic later
    variables: {
      id: patientId,
      startTime: startTime,
      endTime: endTime,
      limit: 5,
      includeFilters: [
        { namespace: 'patient', category: 'activity', enum: '*' },
      ],
    },
    fetchPolicy: 'cache-and-network',
  })

  return { activityCountLoading, activityCountError, activityCountData }
}

/**
 * A function to re-fetch the eventList items to simulate pagination
 *
 * @param {function} fetchMore Function provided by the useQuery hook. Recommended way to send followup queries with Apollo Client. https://www.apollographql.com/docs/react/pagination/core-api/
 * @param {string} endCursor The end cursor received from previous queries; indicates the number of results retrieved so far.
 * @return {void} updates the Apollo Client cache
 */
export const reFetchEventList = (fetchMore, endCursor) => {
  const variables = {
    cursor: endCursor,
  }

  fetchMore({
    variables,
    updateQuery: (previousResult, { fetchMoreResult }) => ({
      ...previousResult,
      patient: {
        ...(previousResult.patient || {}),
        eventList: {
          ...(previousResult?.patient.eventList || {}),
          events: [
            ...(previousResult?.patient?.eventList?.events || []),
            ...(fetchMoreResult?.patient?.eventList?.events || []),
          ],
          pageInfo: {
            ...(previousResult?.patient?.eventList?.pageInfo || {}),
            endCursor: fetchMoreResult?.patient?.eventList?.pageInfo?.endCursor,
          },
        },
      },
    }),
  })
}
