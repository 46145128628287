export const daysOfWeekMapped = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const NOT_APPLICABLE = 'N/A'
export const STATUS_INACTIVE = 'Inactive'
export const STATUS_ACTIVE = 'Active'
export const PARTICIPANTS_UNASSIGNED = 'Unassigned'
export const INTERVAL_CLOCK_SCHEDULE = 'INTERVAL_CLOCK_SCHEDULE'
export const DAILY_INTERVAL = 0
export const DAYS_IN_WEEK = 7
