import {
  HOUR_IN_MINUTES,
  MINUTE_IN_SECONDS,
  SECOND_IN_MILLISECONDS,
} from 'utilities/time'

export const smallChartHeight = 12
export const mediumChartHeightWithAxis = 85
export const mediumChartHeightWithoutAxis = 55

export const SECTION_TITLES = {
  summary: 'Red Flag Symptoms',
  checkin: 'Daily Checkin',
  dyskinesia: 'Dyskinesia',
  medication: 'Medication',
  notes: 'Notes',
  symptoms: 'Symptoms',
  sleep: 'Sleep',
  tremor: 'Tremor',
  tremorAndDyskinesia: 'Tremor and Dyskinesia',
}

export const DAILY_EVENT_CATEGORIES = {
  symptom: 'symptom',
  sideEffect: 'side-effect',
  medication: 'medication',
  note: 'note',
}

export const ALL_DAILY_EVENT_CATEGORIES = [
  DAILY_EVENT_CATEGORIES.sideEffect,
  DAILY_EVENT_CATEGORIES.symptom,
  DAILY_EVENT_CATEGORIES.medication,
  DAILY_EVENT_CATEGORIES.note,
]

export const DAILY_EMPTY_DAY_TEXT = 'No data available'

// ============ OLD VIEW - TODO: DELETE WHEN dailyViewRevampedVisible flag has been deprecated ============ //

export const FILTER_THRESHOLD = 0.3

export const SYMPTOM_OR_SIDE_EFFECT_LABEL = 'Symptoms'
export const MEDICATION_OR_SUPPLEMENT_LABEL = 'Medications'

export const ON_OFF_PERIODS = Object.freeze({
  onPeriod: 'ON Period',
  offPeriod: 'OFF Period',
  onWithNonTroublesomeDyskinesia: 'ON with non-troublesome Dyskinesia',
  onWithTroublesomeDyskinesia: 'ON with troublesome Dyskinesia',
  onWithoutDyskinesia: 'ON without Dyskinesia',
})

export const Y_AXIS_ORDER = [
  'Sleep',
  'Symptom',
  'Dyskinesia',
  'Tremor',
  'ON/OFF',
  SYMPTOM_OR_SIDE_EFFECT_LABEL,
  MEDICATION_OR_SUPPLEMENT_LABEL,
]

export const EMOJI = Object.freeze({
  watch: '⌚',
  gear: '⚙️',
})

export const INTENSITY_LEVEL = Object.freeze({
  none: 0,
  mild: 1,
  typical: 2,
  severe: 3,
})

export const MINIMUM_INTENSITY = 0
export const MAXIMUM_INTENSITY = 1

export const SLEEP_STATE = Object.freeze({
  inBed: 0,
  asleep: 1,
  awake: 2,
  asleepCore: 3,
  asleepDeep: 4,
  asleepREM: 5,
})

export const SLEEP_STATUS = Object.freeze({
  asleep: 'asleep',
  inBed: 'in_bed',
})

export const PLOT_CONFIG = Object.freeze({
  plotData: {
    marker: {
      opacity: 0,
      symbol: 0,
    },
    hoverinfo: 'none',
  },
  layout: {
    font: { family: 'Work Sans' },
    autosize: true,
    orientation: 'h',
    margin: { t: 0, b: 20 },
    showlegend: false,
    visible: true,
    hovermode: 'closest',
  },
  xAxisLayout: {
    fixedrange: true,
    autorange: false,
    showgrid: false,
    gridlines: false,
    showline: true,
    linewidth: 1,
    linecolor: '#cccbc9',
    type: 'date',
  },
  yAxisLayout: {
    fixedrange: true,
    showline: true,
    linecolor: '#cccbc9',
    linewidth: 1,
    automargin: true,
    autorange: 'reversed',
    showgrid: false,
    gridlines: false,
    side: 'left',
    type: 'category',
    tickfont: {
      size: 12,
    },
    ticklen: 20,
    tickcolor: 'white',
  },
  icon: {
    xref: 'x',
    yref: 'y',
    layer: 'above',
    sizex: HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MILLISECONDS,
    sizey: 1,
    xanchor: 'center',
    yanchor: 'middle',
  },
  shape: {
    type: 'rect',
    opacity: 1,
  },
  shapeBackground: {
    layer: 'below',
    type: 'rect',
    xref: 'paper',
    yref: 'y',
    x0: 0,
    x1: 1,
    line: { width: 0 },
  },
  dottedLine: {
    layer: 'above',
    type: 'line',
    xref: 'x',
    yref: 'paper',
    y0: 0,
    y1: 0.62,
    line: {
      color: '#648eae',
      width: 2,
      dash: 'dot',
    },
  },
})
