import { Fragment, useState, useRef, useEffect } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Box from '@material-ui/core/Box'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import SubmitButton from 'ui/components/SubmitButton/SubmitButton'
import LoadingAnimation from 'ui/components/LabelledLoading/LoadingAnimation.js'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  content: {
    minWidth: 500,
  },
  form: {
    padding: '.5em 1em',
  },
  hidden: {
    display: 'none',
  },
}))

const CreateEntityDialog = ({
  title,
  children,
  displayLoadingAnimation = false,
  loading,
  success,
  onSubmit,
  handleClose,
  disabled,
  doneLabel = 'Done',
  submitLabel = 'Create',
  successLabel = 'Created',
  ...rest
}) => {
  const classes = useStyles()

  const contentRef = useRef(null)
  const buttonRef = useRef(null)

  const [loadingWidth, setLoadingWidth] = useState(0)
  const [loadingHeight, setLoadingHeight] = useState(0)

  const getHeight = (ref) => ref?.current?.clientHeight || 0
  const getWidth = (ref) => ref?.current?.clientWidth || 0

  useEffect(() => {
    if (!loading) {
      setLoadingHeight(getHeight(contentRef) + getHeight(buttonRef))
      setLoadingWidth(getWidth(contentRef))
    }
  }, [loading])

  const buttonLabelDataCy = (label) => `create-entity-dialog-button ${label}`

  const contentStyles = clsx({
    [classes.content]: true,
    [classes.hidden]: loading && displayLoadingAnimation,
  })

  const actionsStyles = clsx({
    [classes.hidden]: loading && displayLoadingAnimation,
  })

  return (
    <form className={classes.form} onSubmit={onSubmit} {...rest}>
      {title && (
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            {title}
          </Box>
        </DialogTitle>
      )}
      {loading && displayLoadingAnimation && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: `${loadingWidth}px`,
            minHeight: `${loadingHeight}px`,
            padding: 0,
            margin: 0,
          }}
        >
          <LoadingAnimation />
        </Box>
      )}
      <DialogContent ref={contentRef} className={contentStyles}>
        {children}
      </DialogContent>
      <DialogActions ref={buttonRef} className={actionsStyles}>
        {success ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            data-cy={buttonLabelDataCy(doneLabel)}
          >
            {doneLabel}
          </Button>
        ) : (
          <Fragment>
            <Button onClick={handleClose} data-cy={buttonLabelDataCy('Cancel')}>
              Cancel
            </Button>
            <SubmitButton
              data-cy={
                success
                  ? buttonLabelDataCy(successLabel)
                  : buttonLabelDataCy(submitLabel)
              }
              icon={null}
              success={success}
              loading={loading}
              disabled={disabled}
              submitLabel={submitLabel}
              successLabel={successLabel}
              onClick={onSubmit}
            />
          </Fragment>
        )}
      </DialogActions>
    </form>
  )
}

export default CreateEntityDialog
