import { useState } from 'react'
import PersistentViewsShareDialog from './PersistentViewsShareDialog'
import { Box, IconButton, Tooltip } from '@material-ui/core'
import ShareIcon from '@material-ui/icons/Share'

/**
 * Advanced options component for Persistent Views feature. Checks if
 * there is a selected view to conditionally show the share button.
 *
 * @param {object} selectedView The currently selected view.
 */
const PersistentViewsOptions = ({ selectedView }) => {
  const [open, setOpen] = useState(false)
  return (
    <Box display="flex" mt={1} data-cy="PersistentViewsOptions">
      {selectedView && (
        <Tooltip title="Share">
          <IconButton data-cy="share-view-button" onClick={() => setOpen(true)}>
            <ShareIcon />
          </IconButton>
        </Tooltip>
      )}
      {open && (
        <PersistentViewsShareDialog
          view={selectedView}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
    </Box>
  )
}
export default PersistentViewsOptions
