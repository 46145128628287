import mixpanel from 'mixpanel-browser'

/**
 * Tracks when the user clicks on the Apply button in Comparison Mode to compare two data sets
 *
 * @returns {void}
 */
export const trackComparisonModeApplied = () => {
  if (window.Cypress) {
    return
  }
  mixpanel.track('Comparison Mode applied', {
    description:
      'tracks when the user clicks on the Apply button in Comparison Mode',
  })
}

/**
 * Tracks when the user clicks on the Summary Mode/Comparison Mode toggle
 *
 * @param {boolean} checked Indicates whether the input is toggled on or off
 * @returns {void}
 */
export const trackComparisonModeToggled = (checked) => {
  if (window.Cypress) {
    return
  }
  mixpanel.track('Comparison Mode toggled', {
    description:
      'tracks when the user toggles between Summary Mode and Comparison Mode. Allows us to know the selected view.',
    'selected view': checked ? 'Comparison Mode' : 'Summary Mode',
  })
}
