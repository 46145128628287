import { memo } from 'react'
import { string } from 'prop-types'

import { MobilitySummaryData, MobilitySummaryNoData } from './'
import { NoDataText } from 'ui/components/NoDataText'
import { StyledKeyValuePair } from 'ui/components/StyledKeyValuePair'
import { SummaryBar, SummaryBarLoading } from 'ui/components/SummaryBar'
import { labels } from './consts'
import useMobilityAggregate from 'ui/hooks/summaryAggregates/useMobilityAggregate'

const MobilitySummary = ({
  subtitle,
  title,
  start,
  end,
  isInComparisonMode,
}) => {
  const { data, hasData, isLoading, allErrored } = useMobilityAggregate(
    start,
    end,
  )
  const { numberOfDaysWithData } = data

  if (isLoading) {
    return (
      <SummaryBarLoading
        title={title}
        subtitle={subtitle}
        mainContentLoadingLabel={labels.LOADING}
      />
    )
  }

  if (allErrored) {
    return (
      <SummaryBar
        title={title}
        subtitle={subtitle}
        mainContent={<NoDataText text={labels.ERROR} />}
      />
    )
  }

  if (!hasData) {
    return (
      <SummaryBar
        title={title}
        subtitle={subtitle}
        mainContent={<MobilitySummaryNoData />}
      />
    )
  }

  return (
    <SummaryBar
      title={title}
      subtitle={subtitle}
      mainContent={
        <MobilitySummaryData
          data={data}
          isInComparisonMode={isInComparisonMode}
        />
      }
      rightmostContent={
        <StyledKeyValuePair
          label={labels.NUMBER_OF_DAYS_WITH_DATA}
          value={numberOfDaysWithData}
        />
      }
    />
  )
}

MobilitySummary.propTypes = {
  subtitle: string,
  title: string.isRequired,
  start: string.isRequired,
  end: string.isRequired,
}

MobilitySummary.defaultProps = {}

export default memo(MobilitySummary)
