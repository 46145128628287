import { gql } from '@apollo/client'

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        displayName
        analyticsTracking
      }
    }
  }
`
