import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { BatteryCharging20TwoTone } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  root: {
    border: '3px dashed #f0f0f0',
    padding: '30px',
  },
  errorIcon: {
    alignSelf: 'center',
    marginBottom: '16px',
    width: 70,
    height: 70,
  },
  errorMessage: {
    alignSelf: 'center',
  },
}))
const defaultError =
  'Oops! Something preposterous happened while loading the data.'

const ErrorFrame = ({ error = defaultError, height = '384px' }) => {
  const classes = useStyles()
  const errorMessage = error || defaultError
  const message = typeof errorMessage === 'string' ? errorMessage : defaultError
  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="right"
      justifyContent="center"
      height={height}
      width="100%"
    >
      <Box display="flex" flexDirection="column" justifyContent="center">
        <BatteryCharging20TwoTone className={classes.errorIcon} />
        <Typography className={classes.errorMessage} variant="body1">
          {message}
        </Typography>
      </Box>
    </Box>
  )
}

export default ErrorFrame
