import { useContext, useState } from 'react'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import { TaskNotificationContext } from 'ui/contexts'
import TimePicker from 'react-time-picker'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './TasksDatePickerStyles.scss'
import { Box, FormControl, FormLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chronoInput: {
    width: 150,
  },
  miniLabel: {
    ...theme.taskNotificationsMiniLabel,
    marginBottom: theme.spacing(1),
  },
}))

/**
 * The Task Schedule section of the Create Task form.
 *
 * @returns {JSX.Element} A date and timepicker
 */
export const TaskScheduleOnce = () => {
  const classes = useStyles()
  const [focusedInput, setFocusedInput] = useState()
  const {
    singleTaskDate,
    singleTaskTime,
    onSingleTaskDateChange,
    onSingleTaskTimeChange,
  } = useContext(TaskNotificationContext)

  const hasValue = (x) => x && x !== 'Invalid date'

  return (
    <ErrorBoundary>
      <Box mb={1}>
        <FormControl component="fieldset">
          <FormLabel className={classes.miniLabel}>Date</FormLabel>
          <SingleDatePicker
            date={hasValue(singleTaskDate) ? moment(singleTaskDate) : null}
            onDateChange={(e) =>
              onSingleTaskDateChange(moment(e).format('YYYY-MM-DD'))
            }
            id="singleDate"
            focused={focusedInput}
            onFocusChange={({ focused }) => setFocusedInput(focused)}
            small={true}
            noBorder={true}
            ariaLabel="Date"
            placeholder="mm/dd/yyyy"
          />
        </FormControl>
      </Box>

      <Box mb={1}>
        <FormControl component="fieldset">
          <FormLabel className={classes.miniLabel}>Time</FormLabel>
          <TimePicker
            id="singleTime"
            name="Single Time"
            data-testid="singleTime"
            disableClock={true}
            clearIcon={null}
            value={singleTaskTime}
            onChange={onSingleTaskTimeChange}
          />
        </FormControl>
      </Box>
    </ErrorBoundary>
  )
}

export default TaskScheduleOnce
