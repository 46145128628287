import { memo, useContext } from 'react'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'
import StrivePDPoweredByRuneLogo from 'assets/img/strivepd-poweredby-rune-logo-2.svg'
import moment from 'moment'
import { PatientContext } from 'ui/contexts'
import ReportTitle from '../ReportTitle'
import { colors } from 'theme/colors'
import { PATIENT_REPORT_EMPTY_STATE } from './consts'
import { DATE_FORMAT_HUMAN_LONG, DATE_FORMAT_HUMAN_SHORT } from 'ui/consts'
import { formatDateString } from 'utilities/time'
import {
  DATE_OF_BIRTH,
  DATE_OF_DIAGNOSIS,
  DATE_OF_REPORT,
  NO_DATA,
  PATIENT_NAME_PREFIX,
  REPORT_PERIODS,
  REPORT_PERIODS_PREFIX,
} from 'ui/clinicianScreens/Patient/Report/components/ReportHeader/consts'
import { patientName } from 'utilities/string'

const ReportHeader = ({
  displayMissingDataText,
  isClinicianReport,
  startDate,
  endDate,
  prevStartDate,
  prevEndDate,
}) => {
  const STYLES = {
    headings: {
      marginBottom: '1rem',
    },
    headingClinicianReport: {
      marginTop: '1rem !important', // overriding MUI specificity
    },
    logo: {
      '@media screen': {
        display: 'none',
      },
      '@media print': {
        marginTop: '0 !important', // overriding ridiculous MUI specificity
      },
    },
  }

  const patient = useContext(PatientContext)
  const { id: patientId, identifiableInfo } = patient || {}

  // Hardcoding date for Ghost Inspector test to prevent daily screenshot changes
  const isGITest = patientId === 'ghost0'
  const reportDate = isGITest
    ? moment('06/26/2024', 'MM/DD/YYYY').format(DATE_FORMAT_HUMAN_LONG)
    : moment().format(DATE_FORMAT_HUMAN_LONG)

  const { demographicData } = identifiableInfo || {}
  const { dateOfBirth, pdDiagnosisDate } = demographicData || {}
  const name = patientName(patient).firstName()

  const formattedDates = {
    startDate: formatDateString({
      dateString: startDate,
      format: DATE_FORMAT_HUMAN_SHORT,
    }),
    endDate: formatDateString({
      dateString: endDate,
      format: DATE_FORMAT_HUMAN_SHORT,
    }),
    prevStartDate: formatDateString({
      dateString: prevStartDate,
      format: DATE_FORMAT_HUMAN_SHORT,
    }),
    prevEndDate: formatDateString({
      dateString: prevEndDate,
      format: DATE_FORMAT_HUMAN_SHORT,
    }),
  }

  return (
    <>
      <Stack spacing={0} alignItems="center" sx={STYLES.logo}>
        <img
          alt="StrivePD powered by Rune Labs"
          src={StrivePDPoweredByRuneLogo}
          width={150}
        />
      </Stack>

      {isClinicianReport && (
        <Text variant="head32" component="p">
          {PATIENT_NAME_PREFIX.clinicianReport}
          {patientName(patient).fullName()}
        </Text>
      )}

      <Stack
        sx={isClinicianReport ? STYLES.headingClinicianReport : STYLES.headings}
        direction="row"
        justifyContent="space-between"
      >
        {isClinicianReport ? (
          <Stack direction="row" alignItems="center" spacing={3}>
            <Text variant="body16B">
              {DATE_OF_BIRTH}
              <Text variant="body16" data-cy="dateOfBirth">
                {dateOfBirth
                  ? formatDateString({
                      dateString: dateOfBirth,
                      format: DATE_FORMAT_HUMAN_LONG,
                    })
                  : NO_DATA}
              </Text>
            </Text>

            <Text variant="body16B">
              {DATE_OF_DIAGNOSIS}
              <Text variant="body16" data-cy="dateOfDiagnosis">
                {pdDiagnosisDate
                  ? formatDateString({
                      dateString: pdDiagnosisDate,
                      format: DATE_FORMAT_HUMAN_LONG,
                    })
                  : NO_DATA}
              </Text>
            </Text>
          </Stack>
        ) : (
          <Text variant="body16" component="p">
            <Text variant="body16B">
              {PATIENT_NAME_PREFIX.patientReport}
              {name}! <br />
            </Text>
            {REPORT_PERIODS_PREFIX}
          </Text>
        )}
        <Text variant="body16" component="p" data-cy="report-date">
          <Text variant="body16B">{DATE_OF_REPORT}</Text>
          {reportDate}
        </Text>
      </Stack>
      <ReportTitle justifyContent="center">
        <Text
          variant="body16B"
          color={colors.PRIMARY[1000]}
          data-cy="report-period-dates"
        >
          {REPORT_PERIODS.recent}
          {formattedDates.startDate} - {formattedDates.endDate} |{' '}
          {REPORT_PERIODS.previous}
          {formattedDates.prevStartDate} - {formattedDates.prevEndDate}
        </Text>
      </ReportTitle>
      {!isClinicianReport &&
        displayMissingDataText &&
        PATIENT_REPORT_EMPTY_STATE}
    </>
  )
}

export default memo(ReportHeader)
