import { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as MUI from '@mui/material'
import { Button, Stack } from 'ui/baseComponents'
import DataAvailabilityDateRangePickerWrapper from 'ui/clinicianScreens/Patient/DataAvailabilityDateRangePicker/DataAvailabilityDateRangePickerWrapper'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'

const STYLES = {
  dateRangePickerContainer: {
    width: '100%',
    '@media print': {
      display: 'none',
    },
  },
}

const ReportDatePicker = () => {
  const smallScreens = MUI.useMediaQuery('(max-width:1279px)')
  const { dateRanges, setDateRange } = useContext(DateRangeContext)

  const SELECTABLE_DATE_COUNT = 28 // 4 weeks
  const MINIMUM_NIGHTS = SELECTABLE_DATE_COUNT - 1

  const [searchParams, setSearchParams] = useSearchParams()

  const [buttonEnabled, setButtonEnabled] = useState(false)
  const [hasError, setHasError] = useState(false)

  const reportVariantKey = 'variant'
  const reportVariantValue = searchParams.get(reportVariantKey)

  const { start: period1StartContext, end: period1EndContext } =
    dateRanges[DATE_RANGE.reportPeriod1.key] || {}
  const { start: period2StartContext, end: period2EndContext } =
    dateRanges[DATE_RANGE.reportPeriod2.key] || {}

  const period1InitialState = useMemo(
    () => dateRanges[DATE_RANGE.reportPeriod1.key] || {},
    [dateRanges],
  )

  const period2InitialState = useMemo(
    () => dateRanges[DATE_RANGE.reportPeriod2.key] || {},
    [dateRanges],
  )

  const [period1Range, setPeriod1Range] = useState(period1InitialState)
  const [period2Range, setPeriod2Range] = useState(period2InitialState)

  useEffect(() => {
    setPeriod1Range(period1InitialState)
    setPeriod2Range(period2InitialState)
  }, [period1InitialState, period2InitialState])

  useEffect(() => {
    const { start: period1StartInput, end: period1EndInput } = period1Range
    const { start: period2StartInput, end: period2EndInput } = period2Range

    const hasDateError = period1EndInput > period2StartInput
    setHasError(hasDateError)

    if (
      !hasDateError &&
      period1StartInput &&
      period1EndInput &&
      period2StartInput &&
      period2EndInput &&
      (period1StartInput !== period1StartContext ||
        period1EndInput !== period1EndContext ||
        period2StartInput !== period2StartContext ||
        period2EndInput !== period2EndContext)
    ) {
      setButtonEnabled(true)
    } else {
      setButtonEnabled(false)
    }
  }, [
    period1Range,
    period2Range,
    period1StartContext,
    period1EndContext,
    period2StartContext,
    period2EndContext,
    dateRanges,
  ])

  const onClickApply = () => {
    setDateRange(
      period1Range.start,
      period1Range.end,
      DATE_RANGE.reportPeriod1.key,
    )
    setDateRange(
      period2Range.start,
      period2Range.end,
      DATE_RANGE.reportPeriod2.key,
    )

    const newSearchParams = {
      startTimePeriod1: period1Range.start,
      endTimePeriod1: period1Range.end,
      startTimePeriod2: period2Range.start,
      endTimePeriod2: period2Range.end,
    }
    if (reportVariantValue) {
      newSearchParams[reportVariantKey] = reportVariantValue
    }

    setSearchParams(newSearchParams, {
      replace: true,
    })
  }

  return (
    <Stack
      direction={smallScreens ? 'column' : 'row'}
      sx={STYLES.dateRangePickerContainer}
    >
      <DataAvailabilityDateRangePickerWrapper
        label={DATE_RANGE.reportPeriod1.label}
        rangeKey={DATE_RANGE.reportPeriod1.key}
        onDateRangeChange={(start, end) => setPeriod1Range({ start, end })}
        maximumDate={period2Range.start}
        maxDays={SELECTABLE_DATE_COUNT}
        minimumNights={MINIMUM_NIGHTS}
        autoSelectDayCount={MINIMUM_NIGHTS}
        autoSelectEndDate={true}
        blockAndHighlightAutoSelectedDates={true}
        showArrows={false}
        initialStateDates={period1InitialState}
        hasError={hasError}
      />
      <MUI.Box component="span" />
      <DataAvailabilityDateRangePickerWrapper
        label={DATE_RANGE.reportPeriod2.label}
        rangeKey={DATE_RANGE.reportPeriod2.key}
        onDateRangeChange={(start, end) => setPeriod2Range({ start, end })}
        minimumDate={period1Range.end}
        maxDays={SELECTABLE_DATE_COUNT}
        minimumNights={MINIMUM_NIGHTS}
        autoSelectDayCount={MINIMUM_NIGHTS}
        autoSelectEndDate={true}
        blockAndHighlightAutoSelectedDates={true}
        showArrows={false}
        initialStateDates={period2InitialState}
        hasError={hasError}
      />
      <MUI.Box component="span" />
      <Button type="secondary" disabled={!buttonEnabled} onClick={onClickApply}>
        Apply
      </Button>
    </Stack>
  )
}

export default ReportDatePicker
