import DataAvailabilityDateRangePickerWrapper from 'ui/clinicianScreens/Patient/DataAvailabilityDateRangePicker/DataAvailabilityDateRangePickerWrapper'
import TabActionableHeader from 'ui/templates/TabActionableHeader'

const DefaultDateRangeHeader = () => (
  <TabActionableHeader>
    <DataAvailabilityDateRangePickerWrapper />
  </TabActionableHeader>
)

DefaultDateRangeHeader.propTypes = {}

DefaultDateRangeHeader.defaultProps = {}

export default DefaultDateRangeHeader
