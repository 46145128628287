import { useQuery } from '@apollo/client'
import { GET_PATIENT_EVENT_COUNTS } from '../screens/Patient/queries'

/**
 * Get a Patient's event count information
 * @param {string} patientId, A string corresponding to the patient's id.
 * @param {string} startTime, A timestamp in seconds; e.g. 1654128000
 * @param {string} endTime, A timestamp in seconds; e.g. 1655337599
 * @param {number} limit, the limit of the number of events to return
 * @param {array} includeFilters, filters to include in the query
 * @return {{eventCountLoading, eventCountError, eventCountData}} eventCountLoading is the loading state of the request returned by useQuery. eventCountError is the error state of the request, if any, returned by useQuery. eventCountData is an object with a key of patient whose value is an object that countains eventCount and id (corresponding to the patient id). eventCounts contains another object with a key of counts and an array of objects. Each of these objects contains a classification, count, customDetail, and displayName.
 */
export const usePatientEventCount = (
  patientId,
  startTime,
  endTime,
  limit = 5,
  includeFilters = [{ namespace: 'patient', category: 'symptom', enum: '*' }],
) => {
  const {
    loading: eventCountLoading,
    error: eventCountError,
    data: eventCountData,
  } = useQuery(GET_PATIENT_EVENT_COUNTS, {
    // @TODO for now the filters are hardcoded but they'll be dynamic later
    variables: {
      id: patientId,
      startTime: startTime,
      endTime: endTime,
      limit,
      includeFilters,
    },
    fetchPolicy: 'cache-and-network',
  })

  return { eventCountLoading, eventCountError, eventCountData }
}

export default usePatientEventCount
