import PatientNotes from './PatientNotes'
import { MetricsRow } from './MetricsRow'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'
import { SECTION_TITLE } from 'ui/clinicianScreens/Patient/consts'

export const OverviewPatientNotes = () => {
  const { id: patientId = '' } = useContext(PatientContext)

  return (
    <MetricsRow
      title={SECTION_TITLE.notes}
      linkHref={`/patients/${patientId}/log`}
      linkText="View All Notes"
      noBorder
    >
      <PatientNotes />
    </MetricsRow>
  )
}
