import { useContext } from 'react'
import { useStreamApi } from 'domains/streamApi'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { PatientContext } from 'ui/contexts'
import {
  getTimestampStartOfDay,
  getTimestampStartOfNextDay,
} from 'utilities/time'

/*
 * @typedef {Object.<Numeric, Array.<('mobility' | 'sleep' | 'tremor_dyskinesia')>} DataAvailabilitySnapshot
 *
 * useDataAvailabilitySnapshot calls for the Data Availability Snapshot data
 * @param {string} startDate - the time to begin querying e.g. '2023-01-01'
 * @param {string} endDate - the time to end querying e.g. '2023-02-28'
 * @param {string} metricCategories - the metric categories to query e.g. 'tremor_dyskinesia,sleep,mobility'
 * @return {{  data: DataAvailabilitySnapshot,  hasError: boolean,  isLoading: boolean,}} - the data availability snapshot data
 * @example {
 *  data:{
 *    1672531200: ['mobility', 'sleep', 'tremor_dyskinesia'],
 *    1672617600: ['sleep', 'tremor_dyskinesia']
 *  },
 *  hasError: false,
 *  isLoading: false
 * }
 */

const useDataAvailabilitySnapshot = ({
  startDate,
  endDate,
  metricCategories,
}) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const shouldExecute = !!startDate && !!endDate && !!metricCategories

  const { id: patientId } = useContext(PatientContext)

  // startTime should be the equivalent of 12:00am in the specified timezone
  const startTime = getTimestampStartOfDay(startDate, selectedTimezone)
  /* endTime should be the equivalent of 12:00am the day following the last day for which we want data in the specified timezone.
  (end_time is exclusive so we want it to be the first day out of range.)*/
  const endTime = getTimestampStartOfNextDay(endDate, selectedTimezone)

  const params = {
    patient_id: patientId,
    start_time: startTime,
    end_time: endTime,
    metric_categories: metricCategories,
  }

  const request = {
    url: '/metric_categories/availability_snapshot',
    params: params,
    versionPrefix: 'v2',
    shouldExecute,
    selectedTimezone,
  }

  const { data = {}, error, loading } = useStreamApi(request)

  return {
    ...data,
    hasError: !!error,
    isLoading: loading,
  }
}

export default useDataAvailabilitySnapshot
