import { useContext } from 'react'
import { Button } from 'ui/components/Button'
import { UserPHIContext } from 'ui/contexts'
import useBoundStore from 'domains/zustand/store'
import { useFlags } from 'domains/launchdarkly/hooks'

const VIEW_PREFERENCE_BUTTON_TEXT = {
  searchPatients: 'Search Patients',
  viewAll: 'View All',
  viewRecent: 'View Recent',
}

/**
 * Renders a button that toggles between showing all patients and showing recent patients.
 * @returns {JSX.Element} - The rendered button component.
 */
const ViewPreferenceButton = () => {
  const { deprecatedViewDisabled } = useFlags()
  const phiVisibility = useContext(UserPHIContext)
  const showAllPatients = useBoundStore((state) => state.showAllPatients)
  const toggleAllPatients = useBoundStore((state) => state.toggleAllPatients)
  const resetPatientList = useBoundStore((state) => state.resetPatientList)
  const resetSearchPatients = useBoundStore(
    (state) => state.resetSearchPatients,
  )
  const setSearchPatientsExecuted = useBoundStore(
    (state) => state.setSearchPatientsExecuted,
  )

  // TODO: When deprecatedViewDisabled is removed, remove this const and move VIEW_PREFERENCE_BUTTON_TEXT.searchPatients to the button component below.
  const alternateOptionText =
    phiVisibility || deprecatedViewDisabled
      ? VIEW_PREFERENCE_BUTTON_TEXT.searchPatients
      : VIEW_PREFERENCE_BUTTON_TEXT.viewRecent

  const onButtonClick = () => {
    toggleAllPatients()
    resetPatientList() // This clears the search input when toggling between recent and all patients
    resetSearchPatients()
    setSearchPatientsExecuted(false)
  }

  return (
    <Button variant="contained" color="primary" onClick={() => onButtonClick()}>
      {showAllPatients
        ? alternateOptionText
        : VIEW_PREFERENCE_BUTTON_TEXT.viewAll}
    </Button>
  )
}
export default ViewPreferenceButton
