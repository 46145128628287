import { useRef, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Checkbox,
  ClickAwayListener,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  grid: {
    marginLeft: theme.spacing(1),
  },
  listItem: {
    minWidth: '200px',
    fontSize: '1rem',
  },
}))

/**
 * Toggles the rendering of the Events component and of individual streams.
 *
 * @param {boolean} showEvents The current state of Event rendering
 * @param {function} toggleShowEvents The function that toggles Event rendering
 * @param {function} toggleShowSymptoms The function that toggles Symptoms event stream visibility
 * @param {function} toggleShowMedication The function that toggles Medication event stream visibility
 * @param {function} toggleShowFood The function that toggles Food event stream visibility
 * @param {function} toggleShowTasks The function that toggles Tasks event stream visibility
 * @param {function} toggleShowActivity The function that toggles Activity event stream visibility
 * @param {function} toggleShowSleep The function that toggles Sleep event stream visibility
 * @param {boolean} showSymptoms toggle state of Symptoms event stream
 * @param {boolean} showMedication toggle state of Medication event stream
 * @param {boolean} showFood toggle state of Food event stream
 * @param {boolean} showTasks toggle state of Tasks event stream
 * @param {boolean} showActivity toggle state of Activity event stream
 * @param {boolean} showSleep toggle state of Sleep event stream
 */
export const EventsToggleButton = ({
  showEvents,
  toggleShowEvents,
  toggleShowSymptoms,
  toggleShowMedication,
  toggleShowFood,
  toggleShowTasks,
  toggleShowActivity,
  toggleShowSleep,
  showSymptoms,
  showMedication,
  showFood,
  showTasks,
  showActivity,
  showSleep,
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const classes = useStyles()

  /**
   * Toggles the dropdown open and closed
   */
  const handleToggle = () => {
    // Toggle the dropdown options open/closed
    setOpen((prevOpen) => !prevOpen)
  }

  /**
   * Closes the dropdown on click-away.
   *
   * @param {event} event The close event
   */
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const buttonTitle = showEvents ? 'Hide Events' : 'Show Events'

  return (
    <Grid item className={classes.grid}>
      <ButtonGroup color="primary" ref={anchorRef} variant="outlined">
        <Button onClick={toggleShowEvents}>{buttonTitle}</Button>
        {showEvents && (
          <Button onClick={handleToggle} size="small">
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        anchorEl={anchorRef.current}
        open={open}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  <ListItem
                    key="sleep"
                    role={undefined}
                    dense
                    button
                    className={classes.listItem}
                    onClick={toggleShowSleep}
                  >
                    <Checkbox
                      checked={showSleep}
                      inputProps={{ 'aria-labelledby': 'sleepOption' }}
                    />
                    <ListItemText id="sleepOption" primary="Sleep" />
                  </ListItem>
                  <ListItem
                    key="tasks"
                    role={undefined}
                    dense
                    button
                    className={classes.listItem}
                    onClick={toggleShowTasks}
                  >
                    <Checkbox
                      checked={showTasks}
                      inputProps={{ 'aria-labelledby': 'tasksOption' }}
                    />
                    <ListItemText id="tasksOption" primary="Tasks" />
                  </ListItem>
                  <ListItem
                    key="symptoms"
                    role={undefined}
                    dense
                    button
                    className={classes.listItem}
                    onClick={toggleShowSymptoms}
                  >
                    <Checkbox
                      checked={showSymptoms}
                      inputProps={{ 'aria-labelledby': 'symptomsOption' }}
                    />
                    <ListItemText id="symptomsOption" primary="Symptoms" />
                  </ListItem>
                  <ListItem
                    key="medication"
                    role={undefined}
                    dense
                    button
                    className={classes.listItem}
                    onClick={toggleShowMedication}
                  >
                    <Checkbox
                      checked={showMedication}
                      inputProps={{ 'aria-labelledby': 'medicationOption' }}
                    />
                    <ListItemText id="medicationOption" primary="Medication" />
                  </ListItem>
                  <ListItem
                    key="food"
                    role={undefined}
                    dense
                    button
                    className={classes.listItem}
                    onClick={toggleShowFood}
                  >
                    <Checkbox
                      checked={showFood}
                      inputProps={{ 'aria-labelledby': 'foodOption' }}
                    />
                    <ListItemText id="foodOption" primary="Food" />
                  </ListItem>
                  <ListItem
                    key="activity"
                    role={undefined}
                    dense
                    button
                    className={classes.listItem}
                    onClick={toggleShowActivity}
                  >
                    <Checkbox
                      checked={showActivity}
                      inputProps={{ 'aria-labelledby': 'activityOption' }}
                    />
                    <ListItemText id="activityOption" primary="Activity" />
                  </ListItem>
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  )
}

export default EventsToggleButton
