import React from 'react'
import { element } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  legendScaleWrapper: {
    width: '10rem',
    margin: '0 0.625rem',
  },
}))

const LegendScaleWrapper = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.legendScaleWrapper}>{children}</div>
}

LegendScaleWrapper.propTypes = {
  children: element.isRequired,
}

LegendScaleWrapper.defaultProps = {}

export default LegendScaleWrapper
