import * as MUI from '@mui/material'
import { string, element, func, node, oneOf, oneOfType } from 'prop-types'
import { colors } from 'theme/colors'

/* https://mui.com/material-ui/react-alert/ */

const Alert = ({ children, title, onClose, severity, icon, action }) => {
  const styles = {
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '8px',
    boxShadow:
      '0px 4px 6px -2px rgba(0, 44, 76, 0.03), 0px 12px 16px -4px rgba(0, 44, 76, 0.08)',
    '&.MuiAlert-filledInfo': {
      backgroundColor: colors.PRIMARY[400],
      color: colors.PRIMARY[1000],
    },
    '&.MuiAlert-filledWarning': {
      backgroundColor: colors.YELLOW[500],
    },
    '&.MuiAlert-filledSuccess': {
      backgroundColor: colors.GREEN[500],
    },
    '&.MuiAlert-filledError': {
      backgroundColor: colors.RED[500],
      color: colors.WHITE,
    },
    '& .MuiButtonBase-root': {
      alignSelf: 'center',
      color: 'inherit',
    },
    '& .MuiAlert-icon, & .MuiAlert-message': {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    '& .MuiAlert-action': {
      paddingTop: 0,
    },
  }

  return (
    <MUI.Alert
      {...{
        'data-cy': 'alert',
        onClose,
        severity,
        icon,
        action,
        variant: 'filled',
        sx: styles,
      }}
    >
      {title && <MUI.AlertTitle>{title}</MUI.AlertTitle>}
      {children}
    </MUI.Alert>
  )
}

Alert.propTypes = {
  children: oneOfType([element, string, node]),
  title: string,
  onClose: func,
  severity: oneOf(['error', 'info', 'success', 'warning']),
  icon: node,
  action: node,
}

export default Alert
