import { Fragment, useCallback, useEffect, useState, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import PlusIcon from '@material-ui/icons/Add'
import DialogTitle from '@material-ui/core/DialogTitle'
import InviteUserRow, { isValid } from './InviteUserRow'
import BulkInvite from './BulkInvite'
import { makeStyles } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { TransitionGroup } from 'react-transition-group'

export const newUser = (attrs) => ({
  // give a unique id so that pre-synced user keys don't conflict
  _id: Math.random(),
  email: '',
  displayName: '',
  admin: false,
  roleDisplayName: 'Member',
  ...attrs,
})

const useStyles = makeStyles(() => ({
  addButton: {
    width: 700,
  },
}))

export const InviteUsers = ({ handleClose }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([newUser()])
  const [hasValidUsers, setHasValidUsers] = useState(false)
  useEffect(() => setHasValidUsers(users.some(isValid)), [users])
  const [userCount, setUserCount] = useState(users.length)
  useEffect(() => setUserCount(users.length), [users, users.length])
  useEffect(() => {
    if (userCount === 0) {
      setUsers([newUser()])
    }
  }, [userCount])
  const removeUser = (id) => () => {
    const index = users.findIndex((u) => u._id === id)
    users.splice(index, 1)
    setUserCount(users.length)
    if (users.length === 0) {
      handleClose()
    }
  }

  const submitRefs = useMemo(() => [], [])
  const submitAll = useCallback(() => {
    const doSubmit = async function () {
      setLoading(true)
      try {
        await Promise.all(submitRefs.map((ref) => ref.submit()))
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    }
    doSubmit()
  }, [submitRefs])
  const bulkAddUsers = useCallback((newUsers) => {
    const users = newUsers.map(newUser)
    setUsers(users)
  }, [])
  return (
    <Fragment>
      <DialogTitle id="form-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Box>Invite Users</Box>
          <Box>
            <BulkInvite addUsers={bulkAddUsers} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Invite users to your organization</DialogContentText>
        <TransitionGroup>
          {users.map((user, index) => {
            const setUser = (updatedUser) => {
              Object.assign(user, updatedUser)
              setUsers([...users])
            }
            return (
              <Collapse key={user._id}>
                <InviteUserRow
                  className={classes.inviteUser}
                  user={user}
                  key={user._id}
                  setUser={setUser}
                  onClose={removeUser(user._id)}
                  ref={(ref) => (submitRefs[index] = ref)}
                  showCloseIcon={index > 0}
                />
              </Collapse>
            )
          })}
        </TransitionGroup>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={2}
          className={classes.addButton}
        >
          <Button
            variant="outlined"
            onClick={() => setUsers([...users, newUser()])}
          >
            Add <PlusIcon />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {users.length > 1 && (
          <Fragment>
            <Button onClick={() => setUsers([newUser()])}>Clear All</Button>
            <Button
              variant="outlined"
              disabled={!hasValidUsers || loading}
              onClick={submitAll}
              color="primary"
            >
              Invite All
            </Button>
          </Fragment>
        )}
      </DialogActions>
    </Fragment>
  )
}

export default InviteUsers
