import React from 'react'
import { string } from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import { LEGEND_TEXT_STYLE } from './consts'

const useStyles = makeStyles(() => ({
  legendTitle: {
    ...LEGEND_TEXT_STYLE,
    paddingRight: '20px',
  },
}))

const LegendTitle = ({ children }) => {
  const classes = useStyles()

  return <Typography className={classes.legendTitle}>{children}</Typography>
}

LegendTitle.propTypes = {
  children: string,
}

LegendTitle.defaultProps = {}

export default LegendTitle
