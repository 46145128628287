import { useContext } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Box, RootRef, makeStyles } from '@material-ui/core'
import StreamItem, { NewStreamItem } from './StreamItem'
import StreamsContext, { REORDER_STREAMS } from './StreamsContext'
import { useAvailableDevices } from './StreamItem/hooks'
import { useDevicesAndEvents } from 'ui/contexts/PatientContext'

const useStyles = makeStyles((theme) => ({
  selectorList: {
    display: 'inline-block',
  },
}))

export const streamKey = ({ deviceId, streamValue }) =>
  `${deviceId}|${streamValue}`

/**
 * @returns {JSX.Element} Patient stream selector for data explorer.
 *
 * @see: https://codesandbox.io/s/4qp6vjp319
 */
const StreamSelector = () => {
  const classes = useStyles()
  // Always recalculate streamOptions based off of streams, but memo-ize so we don't do it more than necessary
  const { streams, dispatch } = useContext(StreamsContext)
  const devices = useDevicesAndEvents()

  // Remove streams with no-longer active devices
  let newStreams = streams.filter((stream) =>
    devices.some((device) => stream.deviceId === device.id),
  )

  const onDragEnd = (result) => {
    /*
     * Update stream ordering after drag-n-drop
     */
    if (!result.destination) {
      return
    }
    dispatch({
      type: REORDER_STREAMS,
      indexFrom: result.source.index,
      indexTo: result.destination.index,
    })
  }

  const availableDevices = useAvailableDevices()
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <RootRef rootRef={provided.innerRef}>
            <Box className={classes.selectorList}>
              {/* Currently selected streams */}
              {newStreams.map((stream, index) => {
                const key = streamKey(stream)
                return (
                  <Draggable key={key} draggableId={key} index={index}>
                    {(provided) => (
                      <StreamItem
                        index={index}
                        {...stream}
                        innerRef={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        data-testid={`stream-item-${key}`}
                      />
                    )}
                  </Draggable>
                )
              })}

              {/* Drop zones are on top of existing items, so we need one more
                  placeholder at the bottom, as there's no item there */}
              {provided.placeholder}
              {availableDevices.length ? <NewStreamItem /> : null}
            </Box>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default StreamSelector
