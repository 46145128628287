import { useFlags } from 'domains/launchdarkly/hooks'
import { string } from 'prop-types'
import React from 'react'
import ProtectedRoute from '../ProtectedRoute'

const ProtectedByFeatureFlag = ({ flagName, redirectPath }) => {
  const featureFlags = useFlags() || {}

  return (
    <ProtectedRoute
      isAllowed={featureFlags[flagName]}
      redirectPath={redirectPath}
    />
  )
}

ProtectedByFeatureFlag.propTypes = {
  flagName: string.isRequired,
  redirectPath: string.isRequired,
}

ProtectedByFeatureFlag.defaultProps = {}

export default ProtectedByFeatureFlag
