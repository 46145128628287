import { useContext } from 'react'
import { validate as validateEmail } from 'email-validator'
import {
  Button,
  FormControl,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import SignInContext from 'ui/contexts/SignInContext'
import FormWrapper from '../FormWrapper'
import {
  INCORRECT_EMAIL_ERROR,
  RESET_PASSWORD_ERROR,
  SUBMIT_ROW,
  TERMS_AND_PRIVACY_NOTICE_AGREEMENT,
} from '../consts'
import { STRIVEPD_PRIMARY_1100, STRIVEPD_PRIMARY_1000 } from 'theme/colors'

const useStyles = makeStyles(() => ({
  loginLinkContainer: {
    marginTop: '1rem',
    textAlign: 'center',
    '& a': {
      color: STRIVEPD_PRIMARY_1000,
    },
  },
  passwordResetButton: {
    background: STRIVEPD_PRIMARY_1000,
    '&:hover': {
      background: STRIVEPD_PRIMARY_1100,
    },
  },
  submitRow: {
    ...SUBMIT_ROW,
  },
}))

/**
 * SendValidationCode component. Allows user to submit a request for a
 * password reset verification code which will be sent to the user's email
 * @return {*} SendValidationCode component
 * @constructor
 */
const SendValidationCodeForm = ({ onSubmit, submitting }) => {
  const classes = useStyles()
  const { email, setEmail, error, setForgotPassword } =
    useContext(SignInContext)

  const showInvalidEmailError = (error) =>
    error?.name === 'UserNotFoundException'

  return (
    <FormWrapper
      onSubmit={onSubmit}
      termsAndPrivacyNoticeAgreementText={
        TERMS_AND_PRIVACY_NOTICE_AGREEMENT.existingUser
      }
    >
      <FormControl fullWidth>
        <Typography variant="subtitle1">
          Enter your email address to reset your password
        </Typography>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          type="email"
          inputProps={{ 'data-cy': 'email' }}
          label="Email address"
          autoFocus
          margin="normal"
          error={!!error}
          helperText={
            showInvalidEmailError(error)
              ? INCORRECT_EMAIL_ERROR
              : error
              ? RESET_PASSWORD_ERROR
              : ''
          }
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </FormControl>
      <FormControl fullWidth className={classes.submitRow}>
        <Button
          color="primary"
          disabled={submitting || !validateEmail(email)}
          type="submit"
          variant="contained"
          className={classes.passwordResetButton}
        >
          Send Password Reset Email
        </Button>
      </FormControl>
      <FormControl fullWidth className={classes.loginLinkContainer}>
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault()
            setForgotPassword(false)
          }}
        >
          Sign In
        </Link>
      </FormControl>
    </FormWrapper>
  )
}

export default SendValidationCodeForm
