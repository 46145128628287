import { gql, useMutation } from '@apollo/client'

export const UPDATE_MEMBERSHIP = gql`
  mutation updateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      user {
        id
        displayName
        username
        email
      }
      status {
        displayName
        disabledAt
        disabledBy {
          user {
            id
            displayName
          }
        }
        invitedAt
        invitedBy {
          user {
            id
            displayName
          }
        }
        joinedAt
      }
      admin
      role {
        admin
        canSeePHI
        displayName
      }
      id
      disabled
    }
  }
`

export const DELETE_MEMBERSHIP = gql`
  mutation updateMembership($input: UpdateMembershipInput!) {
    updateMembership(input: $input) {
      id
    }
  }
`

export const CREATE_MEMBERSHIP = gql`
  mutation createMembership($input: CreateMembershipInput!) {
    createMembership(input: $input) {
      id
    }
  }
`

export const RESEND_MEMBERSHIP_INVITATION = gql`
  mutation resendMembershipInvitation(
    $input: ResendMembershipInvitationInput!
  ) {
    resendMembershipInvitation(input: $input) {
      ok
    }
  }
`
export const useUpdateMembership = (...args) =>
  useMutation(UPDATE_MEMBERSHIP, ...args)
export const useDeleteMembership = (...args) =>
  useMutation(DELETE_MEMBERSHIP, ...args)
export const useCreateMembership = (...args) =>
  useMutation(CREATE_MEMBERSHIP, ...args)
export const useResendMembership = (...args) =>
  useMutation(RESEND_MEMBERSHIP_INVITATION, ...args)
export const constructRoleVariables = (roleDisplayName) => {
  let adminVal = false
  let canSeePHIVal = false
  switch (roleDisplayName) {
    case 'Member':
      ;[adminVal, canSeePHIVal] = [false, false]
      break
    case 'Admin':
      ;[adminVal, canSeePHIVal] = [true, false]
      break
    case 'Clinician':
      ;[adminVal, canSeePHIVal] = [true, true]
      break
    default:
      throw new Error('Invalid argument for role')
  }
  return [adminVal, canSeePHIVal]
}
