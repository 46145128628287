import { useMemo } from 'react'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import {
  STYLES,
  TABLE_HEADERS,
  TABLE_STATUS_TEXT,
} from 'ui/screens/Patients/PatientList/PatientTable/consts'
import {
  PatientTableLoading,
  PatientTableEmpty,
  PatientTableError,
  TableCellLinkToPatientSettings,
  TableCellHeader,
  TableCellPatientName,
} from 'ui/screens/Patients/PatientList/PatientTable'
import { calculateShowingConditions } from 'ui/screens/Patients/PatientList/PatientTable/helpers'
import { useIsAdmin, usePatientList, usePatientSearch } from 'ui/hooks'
import { PATIENT_LIST_LIMIT } from 'ui/hooks/usePatientList'
import useBoundStore from 'domains/zustand/store'
import { useFlags } from 'domains/launchdarkly/hooks'

/**
 * The patient table component for users without PHI access.
 * @returns {JSX.Element} The rendered component.
 */
const PatientTableWithoutPHIAccess = () => {
  const { deprecatedViewDisabled } = useFlags()
  const errorPatientList = useBoundStore((state) => state.errorPatientList)
  const loadingPatientList = useBoundStore((state) => state.loadingPatientList)
  const patientListResults = useBoundStore((state) => state.patients)
  const showAllPatients = useBoundStore((state) => state.showAllPatients)
  const { admin } = useIsAdmin()

  const searchPatientsExecuted = useBoundStore(
    (state) => state.searchPatientsExecuted,
  )
  const searchPatientsError = useBoundStore(
    (state) => state.searchPatientsError,
  )
  const searchPatientsLoading = useBoundStore(
    (state) => state.searchPatientsLoading,
  )
  const searchPatientsResults = useBoundStore(
    (state) => state.searchPatientsResults,
  )
  const error =
    deprecatedViewDisabled && !showAllPatients
      ? searchPatientsError
      : errorPatientList
  const loading =
    deprecatedViewDisabled && !showAllPatients
      ? searchPatientsLoading
      : loadingPatientList
  const patients =
    deprecatedViewDisabled && !showAllPatients
      ? searchPatientsResults
      : patientListResults

  const patientListLimit =
    !deprecatedViewDisabled && !showAllPatients
      ? PATIENT_LIST_LIMIT.recent
      : undefined // eslint-disable-line no-undefined
  usePatientList({
    limit: patientListLimit,
    fetchAll: !patientListLimit,
    skip: deprecatedViewDisabled && !showAllPatients,
  })

  usePatientSearch({ skip: showAllPatients })

  const SHOWING_CONDITIONS = useMemo(
    () =>
      calculateShowingConditions({
        loading,
        error,
        patients,
        searchPatientsExecuted,
      }),
    [loading, error, patients, searchPatientsExecuted],
  )

  return (
    <Table
      aria-label="Patient List"
      data-cy="patient-table-without-phi-access"
      data-testid="patient-table-without-phi-access"
    >
      <TableHead>
        <TableRow>
          {SHOWING_CONDITIONS.always && (
            <TableCellHeader>{TABLE_HEADERS.patient}</TableCellHeader>
          )}
          {admin && (
            <TableCellHeader sx={STYLES.iconCell}>
              {TABLE_HEADERS.settings}
            </TableCellHeader>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && <PatientTableLoading />}
        {error && <PatientTableError error={errorPatientList} />}
        {deprecatedViewDisabled &&
          !showAllPatients &&
          SHOWING_CONDITIONS.searchPatients && (
            <PatientTableEmpty
              icon="search"
              message={TABLE_STATUS_TEXT.search}
            />
          )}
        {(!SHOWING_CONDITIONS.searchPatients ||
          showAllPatients ||
          (!deprecatedViewDisabled && !showAllPatients)) &&
          SHOWING_CONDITIONS.noPatients && <PatientTableEmpty />}
        {SHOWING_CONDITIONS.allPatientsLoaded &&
          patients.map((patient) => {
            const { id, preComputedName } = patient

            return (
              <TableRow key={id} data-cy={`patient-row-${id}`}>
                <TableCellPatientName
                  patientId={id}
                  patientName={preComputedName}
                />
                {admin && <TableCellLinkToPatientSettings patientId={id} />}
              </TableRow>
            )
          })}
      </TableBody>
    </Table>
  )
}
export default PatientTableWithoutPHIAccess
