import React from 'react'
import StreamItem, { NewStreamItem } from './ExplorerStreamItem'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  selectorList: {
    display: 'inline-block',
  },
}))

/**
 * Selector component for Devices in the Explore view
 *
 * @param {object} deviceOptions Unselected Devices
 * @param {function} setSelected Function that adds/removes devices
 * @param {function} replaceSelected Function that updates devices in place
 * @param {object} selectedDevices Selected Devices
 */
const StreamSelector = ({
  deviceOptions,
  setSelected,
  replaceSelected,
  selectedDevices,
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.selectorList} ml={3}>
      {/* Currently selected streams */}
      {selectedDevices.map((stream, index) => {
        return (
          <div key={index}>
            <StreamItem
              index={index}
              setSelected={setSelected}
              replaceSelected={replaceSelected}
              stream={stream}
              deviceOptions={deviceOptions}
            />
          </div>
        )
      })}
      {deviceOptions.length ? (
        <NewStreamItem
          deviceOptions={deviceOptions}
          setSelected={setSelected}
        />
      ) : null}
    </Box>
  )
}

export default StreamSelector
