import React from 'react'
import { monthlyAvailabilityColorArray } from '../../consts.js'
import CircleIcon from '@material-ui/icons/FiberManualRecord'
import { makeStyles } from '@material-ui/core/styles'
import { Z_INDEX_STREAM_NAME_CONTAINER } from 'theme/zIndexRegistry.js'

const useStyles = makeStyles((theme) => ({
  circleIcon: {
    position: 'relative',
    top: '7px',
    margin: '0 0.1rem 0 1rem',
  },
  streamName: {
    display: 'inline-block',
  },
  streamNameContainer: {
    padding: '0.75rem 0.5rem 0.5rem 15rem',
    textAlign: 'right',
    backgroundColor: theme.palette.default.main,
    zIndex: Z_INDEX_STREAM_NAME_CONTAINER,
  },
}))

/**
 * Companion legend for the monthly availability bitmap
 *
 * @param {array} streamNames Array of all stream names in order
 */
const MonthBitmapLegend = ({ streamNames }) => {
  const classes = useStyles()
  return (
    <div className={classes.streamNameContainer}>
      {streamNames.map((streamName, i) => (
        <span
          key={i}
          className={classes.streamName}
          data-cy={monthlyAvailabilityColorArray[i]}
        >
          <CircleIcon
            mt={1}
            style={{ color: monthlyAvailabilityColorArray[i] }}
            className={classes.circleIcon}
          />
          {streamName}
        </span>
      ))}
    </div>
  )
}

export default MonthBitmapLegend
