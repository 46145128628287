import { Component } from 'react'
import PropTypes from 'prop-types'
// import Rollbar from 'rollbar'
import { Typography } from '@material-ui/core'

// not conveniently made functional
class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null, hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true }
  }

  componentDidCatch(error) {
    console.log(error)
    // You can also log the error to an error reporting service
    // Rollbar.error(error, errorInfo)
  }

  render() {
    const { children } = this.props
    const { error, hasError } = this.state

    if (hasError) {
      return (
        <Typography variant="h4">
          {`${error}` || 'Something went wrong!'}
        </Typography>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
}

export default ErrorBoundary
