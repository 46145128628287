import React from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { Search } from '@material-ui/icons'
import { useLogger } from 'domains/logger/context'
import DefaultLayout from 'ui/components/DefaultLayout'
import ErrorMessage from 'ui/components/ErrorMessage'
import LoadingPage from 'ui/components/LoadingPage/LoadingPage'
import { PatientProvider } from 'ui/contexts/PatientContext'
import { usePatient } from 'ui/screens/Patient/queries'
import { SHARED_ROUTES } from 'app/AppRoutes/consts'

const Patient = () => {
  const { pathname } = useLocation()
  const { patientId } = useParams()
  // Load the patient at the route level, then expose it to children via PatientProvider
  const { loading, error, patient } = usePatient(patientId)
  const logger = useLogger()
  const hideDefaultLayout = pathname.includes(SHARED_ROUTES.editPatient.path)

  if (error) {
    logger.error(error)
    return (
      <DefaultLayout>
        <ErrorMessage
          icon={Search}
          message="Oops! Looks like this patient doesn't exist, or you don't have access to their data."
        />
      </DefaultLayout>
    )
  }
  if (loading) {
    return <LoadingPage />
  }

  return (
    <PatientProvider value={patient}>
      {hideDefaultLayout ? (
        <Outlet />
      ) : (
        <DefaultLayout>
          <Outlet />
        </DefaultLayout>
      )}
    </PatientProvider>
  )
}

export default Patient
