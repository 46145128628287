import { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { OrgContext } from 'ui/contexts'
import { GET_USERS } from 'domains/carrotGraph/queries'

/**
 * Get a list of Users for the default organization
 * @return {Object} includes loading, data, error, and fetchMore from graphQL query
 */
const useUsers = () => {
  const { id: orgId } = useContext(OrgContext)

  const { loading, data, error, fetchMore } = useQuery(GET_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: { orgId, withDisabled: true },
    errorPolicy: 'all',
  })

  return {
    loading,
    data,
    error,
    fetchMore,
  }
}

export default useUsers
