import { useState } from 'react'
import { styled } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, Stack } from 'ui/baseComponents'
import EditableContent from 'ui/clinicianScreens/Patient/Report/components/EditableContent'
import ReportSection from 'ui/clinicianScreens/Patient/Report/components/ReportSection'
import UploadedImage from './UploadedImage'
import { BUTTON_BASE_STYLES, BUTTON_TEXT } from './consts'
import { createUniqueId } from 'ui/clinicianScreens/Patient/helpers'

const STYLES = {
  addImageButton: {
    ...BUTTON_BASE_STYLES,
    alignSelf: 'flex-start',
  },
}

const ReportSectionSupportingMaterial = ({
  sectionId,
  sectionType,
  supportingMaterialsSections,
  title,
  onClickAddNewSection,
  onClickRemoveSection,
}) => {
  const [uploadedImages, setUploadedImages] = useState(new Map())
  const hasUploadedImages = uploadedImages.size > 0
  const addImageButtonText = !hasUploadedImages
    ? BUTTON_TEXT.addImage
    : BUTTON_TEXT.addImageBelow

  const contentRight =
    supportingMaterialsSections.size > 1 ? (
      <Button
        type="tertiary"
        startIcon={<DeleteOutlineIcon />}
        sx={BUTTON_BASE_STYLES}
        onClick={() => onClickRemoveSection({ sectionId, sectionType })}
        data-cy="button-remove-section"
      >
        {BUTTON_TEXT.remove}
      </Button>
    ) : null

  // This comes from MUI documentation for creating a file upload button (https://mui.com/material-ui/react-button/)
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const removeImage = (imageId) => {
    setUploadedImages((prevImages) => {
      const updatedImages = new Map(prevImages)
      updatedImages.delete(imageId)
      return updatedImages
    })
  }

  const uploadFile = (e) => {
    const file = URL.createObjectURL(e.target.files[0])
    setUploadedImages((prevImages) => {
      const newId = createUniqueId()

      const updatedImages = new Map(prevImages)
      updatedImages.set(newId, {
        image: file,
        title: '',
      })

      return updatedImages
    })
  }

  return (
    <ReportSection
      {...{
        contentRight,
        forcePageBreak: true,
        hideWhenPrinting: !hasUploadedImages,
        title,
      }}
    >
      <EditableContent
        dataCy="supporting-material-section-text"
        disableToggleHidePrintClass={true}
        stateKey={sectionId}
        stateSrc={{}}
      />

      {[...uploadedImages].map(([imageId, { image, title }]) => (
        <UploadedImage
          key={imageId}
          image={image}
          onClickRemoveImage={() => removeImage(imageId)}
          title={title}
        />
      ))}

      <Stack direction="row" spacing={3}>
        <Button
          component="label"
          type="tertiary"
          startIcon={<AddIcon />}
          sx={STYLES.addImageButton}
          aria-label={addImageButtonText}
          data-cy={
            !hasUploadedImages ? 'button-add-image' : 'button-add-image-below'
          }
        >
          {addImageButtonText}
          <VisuallyHiddenInput type="file" onChange={uploadFile} />
        </Button>
        {hasUploadedImages && (
          <Button
            type="tertiary"
            startIcon={<AddIcon />}
            sx={STYLES.addImageButton}
            onClick={() => onClickAddNewSection({ sectionType })}
            data-cy="button-add-new-section"
          >
            {BUTTON_TEXT.addNewSection}
          </Button>
        )}
      </Stack>
    </ReportSection>
  )
}

export default ReportSectionSupportingMaterial
