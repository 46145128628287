import { Fragment, memo, useContext, useEffect, useRef, useState } from 'react'
import { arrayOf, number, object, shape, string } from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { MINIMUM_INTENSITY } from './consts'
import ChartLoading from 'ui/components/ChartLoading'
import EmptyResults from 'ui/components/EmptyResults'
import Plot from 'ui/components/Plot'
import { extractParameterValue } from 'domains/streamApi/hooksV2'
import { returnDataForSpecifiedDate } from './helpers/helpers'
import { formatDataForPlotly } from './helpers/layout'
import { formatSleepData } from './helpers/sleep'
import useSleepStreamData from 'ui/hooks/rawData/useSleepStreamData'
import useTremorAndDyskinesiaData from 'ui/hooks/rawData/useTremorAndDyskinesiaData'
import { PatientContext } from 'ui/contexts'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { formatV2MotorSymptomsDataForPlotting } from './helpers/motorSymptoms'
import PlotHover from './PlotHover'

const useStyles = makeStyles(() => ({
  plot: {
    width: '100%',
  },
}))

const DailyPlot = ({ formattedData, intensityRange, startDate }) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const classes = useStyles()

  const [plotted, setPlotted] = useState(false)
  const plotRef = useRef(null)
  const onPurge = (_, graphDiv) => {
    if (typeof graphDiv.removeAllListeners !== 'undefined') {
      graphDiv.removeAllListeners('plotly_hover')
      graphDiv.removeAllListeners('plotly_unhover')
    }
    setPlotted(false)
  }
  const onAfterPlot = () => setPlotted(true)

  const { id: patientId, deviceList } = useContext(PatientContext)

  const sleepDataFromStreamAPI = useSleepStreamData(startDate)
  const [sleepDataToVisualize, setSleepDataToVisualize] = useState([])

  const {
    hasData: hasMotorData,
    data: motorData,
    isLoading: motorDataIsLoading,
  } = useTremorAndDyskinesiaData(
    patientId,
    deviceList?.devices || [],
    startDate,
  )
  const [motorDataToVisualize, setMotorDataToVisualize] = useState([])

  const [dataFormattedForPlotly, setDataFormattedForPlotly] = useState([])

  const {
    isLoading: sleepDataFromApiIsLoading,
    hasData: sleepDataFromApiHasData,
    data: sleepDataFromApiData,
  } = sleepDataFromStreamAPI || {}

  useEffect(() => {
    if (sleepDataFromApiHasData) {
      setSleepDataToVisualize(sleepDataFromApiData)
    }
  }, [sleepDataFromApiData, sleepDataFromApiHasData])

  useEffect(() => {
    if (hasMotorData) {
      const motorDataForPlotly = formatV2MotorSymptomsDataForPlotting(
        motorData,
        selectedTimezone,
      )
      setMotorDataToVisualize(motorDataForPlotly)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMotorData, selectedTimezone])

  useEffect(() => {
    const { max, min } = intensityRange

    const formattedDataForCurrentDate =
      (max && min >= MINIMUM_INTENSITY && formattedData) || []

    if (sleepDataToVisualize) {
      const formattedSleepData = sleepDataToVisualize.map((streamData) => {
        const sleepStatus = extractParameterValue(
          streamData.streamDetails.parameters,
          'sleep_status',
        )

        return formatSleepData(streamData.data, sleepStatus, selectedTimezone)
      })

      const formattedSleepDataForCurrentDay = returnDataForSpecifiedDate({
        data: formattedSleepData,
        date: startDate,
      })
      // End of Hack.
      const sleepDataForCurrentDayCombined = [].concat(
        ...formattedSleepDataForCurrentDay,
      )

      const allFormattedData = [
        ...formattedDataForCurrentDate,
        ...sleepDataForCurrentDayCombined,
        ...motorDataToVisualize,
      ]

      setDataFormattedForPlotly(
        formatDataForPlotly(allFormattedData, startDate),
      )
    }

    if (!sleepDataToVisualize) {
      setDataFormattedForPlotly(
        formatDataForPlotly(
          formattedDataForCurrentDate.concat(motorDataToVisualize),
          startDate,
        ),
      )
    }
  }, [
    formattedData,
    intensityRange,
    sleepDataToVisualize,
    motorDataToVisualize,
    startDate,
    selectedTimezone,
  ])

  if (
    typeof formattedData === 'undefined' ||
    (formattedData?.length && !dataFormattedForPlotly?.data?.length) ||
    sleepDataFromApiIsLoading ||
    motorDataIsLoading
  ) {
    return <ChartLoading height={150} />
  }

  if (!dataFormattedForPlotly?.data?.length) {
    return <EmptyResults message="No data logged" />
  }

  return (
    <Fragment>
      <Plot
        className={classes.plot}
        config={{
          autosize: true,
          responsive: true,
          displayModeBar: false,
          doubleClick: false,
          scrollZoom: false,
        }}
        useResizeHandler={true}
        data={dataFormattedForPlotly.data}
        layout={dataFormattedForPlotly.layout}
        ref={plotRef}
        onPurge={onPurge}
        onAfterPlot={onAfterPlot}
      />
      <PlotHover elementRef={plotRef} plotted={plotted} />
    </Fragment>
  )
}

DailyPlot.propTypes = {
  startDate: string.isRequired,
  formattedData: arrayOf(
    shape({
      plotData: shape({
        hoverLabel: shape({
          bgcolor: string,
          bordercolor: string,
          font: object,
        }),
        marker: shape({ opacity: number, symbol: number }),
        name: string,
        text: arrayOf(object),
        x: arrayOf(string),
        y: arrayOf(string),
      }),
      intensity: number,
    }),
  ),
  intensityRange: shape({
    max: number,
    min: number,
  }),
}

DailyPlot.defaultProps = {}

export default memo(DailyPlot)
