import React from 'react'
import {
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { SECTION_TITLE } from 'ui/clinicianScreens/Patient/consts'
import { LEFT_COLUMN_WIDTH } from './consts'

const useStyles = makeStyles((theme) => ({
  codeIcon: {
    position: 'relative',
    bottom: '3px',
  },
  list: {
    paddingLeft: '1.5rem',
    ...LEFT_COLUMN_WIDTH,
  },
  images: {
    height: '1.25rem',
  },
  subheader: {
    textTransform: 'uppercase',
    fontWeight: 600,
    color: `${theme.palette.secondary.main}`,
    fontSize: '.85rem',
  },
}))

const DataTypesList = ({ listItems, sectionTitle }) => {
  const classes = useStyles()

  return (
    <List className={classes.list}>
      <Typography className={classes.subheader}>{sectionTitle}</Typography>
      {listItems.map((listItem) => (
        <ListItem key={listItem.name}>
          {listItem.symbol && (
            <Icon>
              {sectionTitle === SECTION_TITLE.patientReported && (
                <img
                  className={classes.images}
                  src={listItem.symbol}
                  alt={listItem.name}
                />
              )}
              {sectionTitle === SECTION_TITLE.watch && (
                <div className={classes.codeIcon}>&#8986;</div>
              )}
            </Icon>
          )}

          <ListItemText primary={listItem.name} />
        </ListItem>
      ))}
    </List>
  )
}

export default DataTypesList
