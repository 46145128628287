import ReportSection from 'ui/clinicianScreens/Patient/Report/components/ReportSection'
import DINMarketing from 'ui/clinicianScreens/Patient/Report/sections/DIN/DINMarketing'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Report/consts'
import ReportSectionSymptomLog from 'ui/clinicianScreens/Patient/Report/sections/SymptomLog/ReportSectionSymptomLog'

const ReportSectionDIN = ({ ...dataSectionProps }) => {
  const { isClinicianReport, hiddenSections } = dataSectionProps
  const title = SECTION_TITLES.DIN

  return (
    <ReportSection
      title={title}
      hidden={hiddenSections.has(title)}
      hideTitle
      noBorder
    >
      {!isClinicianReport && <DINMarketing />}
      <ReportSectionSymptomLog
        showOnlyDyskinesiaSymptoms
        data-cy="din-symptom-log"
        {...dataSectionProps}
      />
    </ReportSection>
  )
}

export default ReportSectionDIN
