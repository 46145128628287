import React from 'react'
import EventsStream from './EventsStream'

/**
 * Plot container component that passes Food-events-stream-specific options to the Events stream.
 *
 * @param {object} pointData The point event request data for the plot
 * @param {object} spanData The span event request data for the plot
 * @param {object} layout The plot layout
 * @param {function} onHover Function that grabs point data to send to AdditionalInfo
 * @param {function} onUnhover Function that unmounts AdditionalInfo
 * @param {float} rangeStartLocal Beginning of time range, used only as a placeholder
 * @param {float} rangeEndLocal End of time range, used only as a placeholder
 */
const Food = ({
  pointData,
  spanData,
  layout,
  onHover,
  onUnhover,
  rangeStartLocal,
  rangeEndLocal,
}) => {
  const pointColor = 'rgb(0, 90, 0)'
  const colorScale = [
    [0, 'rgba(0, 90, 0, 0.7)'],
    [1, 'rgba(0, 90, 0, 1)'],
  ]

  /**
   * Any custom logic/function, including custom hover handlers, would be
   * located here, passed to the EventsStream, and conditionally handled there.
   */

  return (
    <EventsStream
      pointData={pointData}
      spanData={spanData}
      layout={layout}
      plotTitle={'Food'}
      pointColor={pointColor}
      colorScale={colorScale}
      onHover={onHover}
      onUnhover={onUnhover}
      rangeStartLocal={rangeStartLocal}
      rangeEndLocal={rangeEndLocal}
    />
  )
}

export default Food
