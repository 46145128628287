import { useCallback, useEffect, useState } from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import copy from 'clipboard-copy'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CheckIcon from '@material-ui/icons/Check'
import CopyIcon from '@material-ui/icons/FileCopy'

const useStyles = makeStyles((theme) => ({
  copyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    minWidth: 250,
    maxWidth: 500,
  },
  copyButton: {
    width: 110,
  },
  copyInput: {
    flexGrow: 4,
  },
  break: {
    flexBasis: '100%',
    height: 0,
  },
}))

const CopyText = ({ value, label = 'Copy' }) => {
  const [copied, setCopied] = useState()
  const [resetTimeoutId, setResetTimeoutId] = useState()
  const classes = useStyles()
  // clear timeout when component unmounts only to prevent error
  useEffect(
    () => () => {
      clearTimeout(resetTimeoutId)
      setResetTimeoutId(false)
    },
    [resetTimeoutId],
  )
  const doCopy = useCallback(
    (e) => {
      e.preventDefault()
      copy(value)
      setCopied(true)
      setResetTimeoutId(setTimeout(() => setCopied(false), 2000))
    },
    [value],
  )
  return (
    <Box className={classes.copyContainer}>
      <OutlinedInput
        value={value}
        labelWidth={0}
        data-cy={'copy-input'}
        className={classes.copyInput}
        inputProps={{ tabIndex: '-1' }}
      />
      <Button
        onClick={doCopy}
        className={classes.copyButton}
        variant="contained"
        color="primary"
        endIcon={copied ? <CheckIcon /> : <CopyIcon />}
      >
        {copied ? 'Copied' : label}
      </Button>
      <div className={classes.break}></div>
    </Box>
  )
}

export default CopyText
