import { memo } from 'react'
import { styled } from '@mui/material/styles'
import { element, string } from 'prop-types'
import { Grid } from '@mui/material'

import { SUMMARY_BAR_BOX_SHADOW } from 'ui/components/SummaryBar/consts'
import { RUNE_GRAY_050, RUNE_GRAY_300, RUNE_GRAY_800 } from 'theme/colors'
import { Text } from 'ui/baseComponents/Text'
import SummaryBarTitleItem from './SummaryBarTitleItem'

const RIGHT_COLUMN_WIDTH = 360

const STYLES = {
  parentContainer: {
    backgroundColor: RUNE_GRAY_050,
    ...SUMMARY_BAR_BOX_SHADOW,
  },
  contentContainer: {
    width: '100%',
    padding: '1.25rem',
  },
  subtitle: {
    color: RUNE_GRAY_800,
  },
}

const RightmostContainer = styled(Grid)(({ theme }) => ({
  borderLeft: `1px solid ${RUNE_GRAY_300}`,
  width: RIGHT_COLUMN_WIDTH,
  [theme.breakpoints.up('xl')]: {
    minWidth: RIGHT_COLUMN_WIDTH,
  },
}))

const SummaryBar = ({ mainContent, rightmostContent, subtitle, title }) => (
  <Grid container direction="column" sx={STYLES.parentContainer}>
    <Grid
      container
      alignItems="center"
      sx={STYLES.contentContainer}
      direction="row"
    >
      <SummaryBarTitleItem item>
        <Grid item>
          <Text component="h3" variant="head24B">
            {title}
          </Text>
        </Grid>
        <Grid item>
          <Text component="p" variant="body14" sx={STYLES.subtitle}>
            {subtitle}
          </Text>
        </Grid>
      </SummaryBarTitleItem>
      <Grid item xs>
        {mainContent}
      </Grid>
      {/* If there is no rightmost content, the main content will wrap more nicely if we omit this Grid */}
      {rightmostContent && (
        <RightmostContainer item container spacing={2}>
          <Grid item>{rightmostContent}</Grid>
        </RightmostContainer>
      )}
    </Grid>
  </Grid>
)

SummaryBar.propTypes = {
  mainContent: element.isRequired,
  rightmostContent: element,
  subtitle: string,
  title: string.isRequired,
}

SummaryBar.defaultProps = {}

export default memo(SummaryBar)
