import React from 'react'
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core'
import styled from 'styled-components'
import ListItemLink from 'ui/components/ListItemLink'
import { Z_INDEX_DRAWER } from 'theme/zIndexRegistry'

const drawerWidth = 240
const useStyles = makeStyles(() => ({
  active: {
    fontWeight: 'bold',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: Z_INDEX_DRAWER,
  },
  drawerPaper: {
    top: 'auto',
    width: drawerWidth,
  },
}))

const MenuList = styled(List)`
  a.active {
    .menu-item {
      font-weight: bold !important;
    }
  }
`

/**
 * The sidebar navigation for the Settings ecosystem.
 */

const SettingsSidebar = () => {
  const classes = useStyles()

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      data-cy="settingsSidebar"
    >
      <List>
        <ListItem>
          <Typography variant="h6">User Settings</Typography>
        </ListItem>
      </List>
      <Divider />
      <MenuList>
        <ListItemLink
          button
          to={'/settings/profile'}
          activeClassName="active"
          data-cy="settings-sidebar-profile-link"
        >
          <ListItemText classes={{ primary: 'menu-item' }} primary="Profile" />
        </ListItemLink>
      </MenuList>
      <MenuList>
        <ListItemLink
          button
          to={'/settings/cookie-preferences'}
          activeClassName="active"
          data-cy="settings-sidebar-cookie-preferences-link"
        >
          <ListItemText
            classes={{ primary: 'menu-item' }}
            primary="Cookie Preferences"
          />
        </ListItemLink>
      </MenuList>
      <MenuList>
        <ListItemLink
          button
          to={'/settings/access-tokens'}
          activeClassName="active"
          data-cy="settings-sidebar-access-tokens-link"
        >
          <ListItemText
            classes={{ primary: 'menu-item' }}
            primary="Access Tokens"
          />
        </ListItemLink>
      </MenuList>
    </Drawer>
  )
}

export default SettingsSidebar
