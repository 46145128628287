import moment from 'moment'

const ONE_DAY_MS = 24 * 60 * 60 * 1000
/**
 * Given a date and a relative date, return the "day number"
 * i.e. how many days before or after the time basis
 * @param date
 * @param relativeDate
 * @return {number}
 */
export const getDayNumber = (date, relativeDate) => {
  const millisDiff = moment(date).valueOf() - moment(relativeDate).valueOf()
  const daysDiff = millisDiff / ONE_DAY_MS
  // always round the same direction to avoid multiple "Day 0"s
  return Math.ceil(daysDiff)
}

/**
 * Get a date relative to another date
 * @param dayNumber
 * @param relativeToDate
 * @return {moment.Moment}
 */
export const getDate = (dayNumber, relativeToDate) => {
  return moment(relativeToDate).add(dayNumber, 'days')
}

export const getDayName = (date, eventTimestamp) => {
  const daysDiff = getDayNumber(date, eventTimestamp)
  const sign = daysDiff > 0 ? '+' : ''
  return `Day ${sign}${daysDiff}`
}
