import {
  DAY_IN_SECONDS,
  getTimestampStartOfDay,
  getTimestampEndOfDay,
} from 'utilities/time'

const TEN_MINUTES_IN_SECONDS = 600

/*
 * createWeeklyAggregateRequests creates the requests for calling the aggregate_window endpoint
 * @param {[]string} metrics - the metrics to create requests for
 *  * @param {string} startTime - the date to begin querying
 * @param {string} endTime - the date to end querying
 * @param {string} patientId - the patient id
 * @param {int} resolution - the size of the time interval in seconds
 * @param {string} selectedTimezone - the timezone selected in the dropdown
 * @return {[]Object} - the requests to make
 */
export const createWeeklyAggregateRequests = ({
  metrics,
  startTime,
  endTime,
  patientId,
  resolution,
  selectedTimezone,
}) => {
  const startTimeStartOfDay = getTimestampStartOfDay(
    startTime,
    selectedTimezone,
  )

  const endTimeEndOfDay = getTimestampEndOfDay(endTime, selectedTimezone)

  const params = {
    start_time: startTimeStartOfDay,
    end_time: endTimeEndOfDay,
    resolution,
    patient_id: patientId,
    timezone_name: selectedTimezone,
    aggregate_function: 'mean',
  }

  const requests = metrics.map((metric) => ({
    endpoint: `/metrics/${metric}/aggregate_window`,
    params: params,
    versionPrefix: 'v2',
  }))
  return requests
}

/*
 * createDailyAggregateRequests creates the requests for calling the daily_aggregate endpoint
 * @param {[]string} metrics - the metrics to create requests for
 * @param {string} startDate - the date to begin querying
 * @param {string} endDate - the date to end querying
 * @param {string} patientId - the patient id
 * @param {int} resolution - the size of the time interval in seconds
 * @param {string} selectedTimezone - the timezone selected in the dropdown
 * @return {[]Object} - the requests to make
 */
export const createDailyAggregateRequests = ({
  metrics,
  startDate,
  endDate,
  patientId,
  resolution,
  selectedTimezone,
}) => {
  const startTimeStartOfDay = getTimestampStartOfDay(
    startDate,
    selectedTimezone,
  )

  const endTimeEndOfDay = getTimestampEndOfDay(endDate, selectedTimezone)

  const params = {
    start_time: startTimeStartOfDay,
    resolution: resolution ?? TEN_MINUTES_IN_SECONDS,
    n_days: Math.round(
      (endTimeEndOfDay - startTimeStartOfDay) / DAY_IN_SECONDS,
    ),
    patient_id: patientId,
  }

  const requests = metrics.map(({ id }) => ({
    endpoint: `/metrics/${id}/daily_aggregate`,
    params: params,
    versionPrefix: 'v2',
  }))
  return requests
}

/*
 *
 * @typedef {Object} AggregateData
 * @property {number} numberOfDaysWithData - the number of days with data for the metric
 * @property {Object} mean - the mean value for the metric
 * @property {Object} min - the min value for the metric
 * @property {Object} max - the max value for the metric
 * @property {[]string} xLabels - an array of strings representing the dates for the metric
 * @property {number} resolution - the size of the time interval in seconds
 * @property {[]Object} averaged - an array of objects with the following properties:
 * @property {string} averaged[].unit - the unit of the value
 * @property {number} averaged[].value - the value
 *
 * formatAggregateData converts the API response into an object mapping metrics to AggregateData objects
 *
 * @param {[]Object} metrics - the metrics that were queried for
 * @param {string} metrics[].id - the id of the metric -- this is what's in the endpoint
 * @param {string} metrics[].name - the key of the metric in the returned object
 * @param {[]Object} data - the data returned from the API
 * @return {Object.<string, AggregateData>} - the formatted data. Each key is a metric name, and AggregateData is
 * data formatted from the API response
 */
export const formatAggregateData = (metrics, data) => {
  if (!data || data.length === 0) {
    return {}
  }
  const formatted = metrics.reduce(reduceAPIDataToMetrics(data), {})

  const defaultDaysWithData = 0
  formatted.numberOfDaysWithData = Object.values(formatted).reduce(
    (acc, { numberOfDaysWithData }) => Math.max(acc, numberOfDaysWithData),
    defaultDaysWithData,
  )
  formatted.hasError = Object.values(formatted).some(({ hasError }) => hasError)
  return formatted
}

const reduceAPIDataToMetrics = (apiData) => {
  const reduceAPIDataToMetricsClosure = (acc, { id, name }) => {
    const response = apiData.find((data) => data.description.id === id)
    if (!response) {
      acc[name] = {
        numberOfDaysWithData: null,
        mean: [],
        min: [],
        max: [],
        averaged: [],
        xLabels: [],
        resolution: TEN_MINUTES_IN_SECONDS,
        trend: null,
        hasError: true,
      }
      return acc
    }

    const noStreamData = !response.metric.data
    if (noStreamData) {
      acc[name] = {
        numberOfDaysWithData: null,
        mean: [],
        min: [],
        max: [],
        averaged: [],
        xLabels: [],
        resolution: TEN_MINUTES_IN_SECONDS,
        trend: 'NO_DIRECTION',
        hasError: false,
      }
      return acc
    }

    const yDimension = 1
    const unit = response.description.dimensions[yDimension].unit_abbrev

    acc[name] = {
      numberOfDaysWithData: response.metric.summary.n_days_with_data_total,
      mean: unitMapper(response.metric.summary.value_mean, unit),
      min: unitMapper(response.metric.summary.value_min, unit),
      max: unitMapper(response.metric.summary.value_max, unit),
      averaged: response.metric.data.values.map((value) =>
        unitMapper(value, unit),
      ),
      resolution: TEN_MINUTES_IN_SECONDS,
      trend: response.metric.trend.direction,
      xLabels: response.metric.data.offset,
      hasError: false,
    }
    return acc
  }
  return reduceAPIDataToMetricsClosure
}

const unitMapper = (value, unit) => [{ unit, value }]
