const patientListSlice = (set) => ({
  errorPatientList: null,
  loadingPatientList: null,
  patients: [],
  resetPatientList: () => {
    set(() => ({ searchInput: '' }))
  },
  searchInput: '',
  setSearchInput: (searchInput) => set(() => ({ searchInput })),
  showAllPatients: false,
  toggleAllPatients: () =>
    set((state) => ({ showAllPatients: !state.showAllPatients })),
})

export default patientListSlice
