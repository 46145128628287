import { useContext, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import DateRangeIcon from '@material-ui/icons/DateRange'
import TimerIcon from '@material-ui/icons/Timer'
import { Box, Popover } from '@material-ui/core'
import TimeBasisPicker from './TimeBasisPicker'
import { TimeBasisContext } from './TimeBasisContext'
import { TIME_BASIS_RELATIVE } from './consts'

const TimeBasis = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const { timeBasis } = useContext(TimeBasisContext)
  return (
    <Box>
      <IconButton
        onClick={handleClick}
        title="Change Time Basis"
        data-cy={'time-basis'}
      >
        {timeBasis.type === TIME_BASIS_RELATIVE ? (
          <TimerIcon />
        ) : (
          <DateRangeIcon />
        )}
      </IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <TimeBasisPicker handleClose={handleClose} />
      </Popover>
    </Box>
  )
}

export default TimeBasis
