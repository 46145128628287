export const BUTTON_TEXT = {
  addNewSection: 'Add a new section',
  addImage: 'Add image',
  addImageBelow: 'Add image below',
  remove: 'Remove',
  removeImage: 'Remove image',
}

export const BUTTON_BASE_STYLES = {
  padding: '0',
  '@media print': {
    display: 'none',
  },
}

export const SUPPORTING_MATERIAL_SECTION_TYPES = {
  redFlagsAndOtherReportedIssues: 'redFlagsAndOtherReportedIssues',
  other: 'other',
}
