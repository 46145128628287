import {
  ADD_STREAM,
  REMOVE_STREAM,
  REORDER_STREAMS,
  REPLACE_STREAMS,
  UPDATE_STREAM,
} from './actions'

export const initialState = []

/**
 * Streams reducer for managing a set of device-streams on the Browse page
 * @param state
 * @param action
 * @return {*}
 */
const streamsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // This one is used to basically just wipe our state when query param changes,
    // i.e. browser back/forward
    case REPLACE_STREAMS: {
      const { streams } = action
      return streams
    }
    case UPDATE_STREAM: {
      const { index, deviceId, streamValue } = action
      if (!deviceId || !streamValue) {
        throw new Error('Must specify a device and stream type')
      }
      return state.map((stream, i) => {
        if (i === index) {
          return { deviceId, streamValue }
        }
        return stream
      })
    }
    case ADD_STREAM: {
      const { deviceId, streamValue } = action
      if (!deviceId || !streamValue) {
        throw new Error('Must specify a device and stream type')
      }
      return [...state, { deviceId, streamValue }]
    }
    case REMOVE_STREAM: {
      const { index } = action
      state.splice(index, 1)
      return [...state]
    }
    case REORDER_STREAMS: {
      const { indexFrom, indexTo } = action
      const movingStream = state[indexFrom]
      state.splice(indexFrom, 1)
      state.splice(indexTo, 0, movingStream)
      return [...state]
    }
    default:
      return state
  }
}

export default streamsReducer
