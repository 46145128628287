import React from 'react'
import {
  daysOfWeekMapped,
  NOT_APPLICABLE,
  STATUS_INACTIVE,
  STATUS_ACTIVE,
  PARTICIPANTS_UNASSIGNED,
  INTERVAL_CLOCK_SCHEDULE,
  DAILY_INTERVAL,
  DAYS_IN_WEEK,
} from './consts'
import TaskEdit from './TaskEdit'
import moment from 'moment'
import PropTypes from 'prop-types'
import TaskToggle from './TaskToggle'
import { RUNE_GRAY_600 } from 'theme/colors'
import { TableCell, TableRow, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  cell: {
    fontWeight: 500,
  },
  inactiveCell: {
    color: RUNE_GRAY_600,
    fontWeight: 500,
  },
  statusCol: {
    textTransform: 'uppercase',
    minWidth: 140,
  },
  statusColActive: {
    textTransform: 'uppercase',
    minWidth: 140,
    color: theme.palette.primary.main,
  },
  editLink: {
    color: theme.palette.primary.transparent,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  frequencyCol: {
    maxWidth: '400px',
  },
  rowContainer: {
    backgroundColor: theme.palette.background.legend,
    '&:hover': {
      '& $editLink': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

/**
 * The table row component for displaying a task
 *
 * @return {JSX.Element} Table row for tasks table
 */
const TasksTableRow = ({ task }) => {
  const classes = useStyles()
  const { id, disabled, title, assignmentList, defaultTaskSchedule } = task
  const status = disabled ? STATUS_INACTIVE : STATUS_ACTIVE
  const name = title || NOT_APPLICABLE

  const numberAssigned = assignmentList?.assignments.length
  const participants =
    numberAssigned > 0
      ? `${numberAssigned}/${numberAssigned} assigned`
      : PARTICIPANTS_UNASSIGNED

  const endDate = defaultTaskSchedule?.endTime
    ? moment(defaultTaskSchedule.endTime).format('M/D/YYYY')
    : 'Ongoing'
  const defaultTaskPeriod =
    defaultTaskSchedule?.hasDefaultPeriod && defaultTaskSchedule?.startTime
      ? `${moment(defaultTaskSchedule.startTime).format(
          'M/D/YYYY',
        )} - ${endDate}`
      : NOT_APPLICABLE

  const mainSchedule = defaultTaskSchedule?.schedules[0]
  const buildTaskFrequency = (schedule) => {
    const times = schedule.times.join(', ') || 'No times selected'
    if (schedule.scheduleType === INTERVAL_CLOCK_SCHEDULE) {
      return schedule.dateInterval === DAILY_INTERVAL
        ? `Daily, ${times}`
        : `Every ${schedule.dateInterval} days, ${times}`
    }

    const hasWeeklySchedule = schedule?.daysOfWeek && schedule.daysOfWeek.length
    const daysOfWeekFormatted = hasWeeklySchedule
      ? schedule.daysOfWeek.map((dayNumber) => daysOfWeekMapped[dayNumber])
      : []

    const days = !hasWeeklySchedule
      ? 'Once'
      : schedule.daysOfWeek.length === DAYS_IN_WEEK
      ? 'Daily'
      : `${daysOfWeekFormatted.join(', ')}`

    return `${days}, ${times}`
  }
  const defaultTaskFrequency = defaultTaskSchedule?.hasDefaultFrequency
    ? buildTaskFrequency(mainSchedule)
    : NOT_APPLICABLE
  const cellClass = disabled ? classes.inactiveCell : classes.cell
  return (
    <TableRow key={id} className={classes.rowContainer}>
      <TableCell
        className={disabled ? classes.statusCol : classes.statusColActive}
      >
        <TaskToggle id={id} label={status} inactive={disabled}></TaskToggle>
      </TableCell>
      <TableCell className={cellClass}>{name}</TableCell>
      <TableCell className={cellClass}>{participants}</TableCell>
      <TableCell className={cellClass}>{defaultTaskPeriod}</TableCell>
      <TableCell className={`${classes.frequencyCol} ${cellClass}`}>
        {defaultTaskFrequency}
      </TableCell>
      <TableCell className={cellClass}>
        <TaskEdit id={id} editClass={classes.editLink} />
      </TableCell>
    </TableRow>
  )
}

TasksTableRow.propTypes = {
  task: PropTypes.object,
}

export default TasksTableRow
