import { useContext } from 'react'
import {
  AccordionSummaryBar,
  AccordionSummaryBarLoading,
  AccordionSummaryBarNoData,
} from 'ui/components/AccordionSummaryBar'
import { labels } from './consts'
import { RecentDataAvailabilityContext } from 'ui/contexts'
import { StyledKeyValuePair } from 'ui/components/StyledKeyValuePair'
import { SummaryMetric } from '../../SummaryMetric'
import { prettyPrintRoundedTimeInHoursAndMinutes } from 'utilities/time'
import { BulletedTooltipContent } from 'ui/components/InformationalIconTooltip'
import { string } from 'prop-types'
import { DATE_RANGE } from 'ui/contexts/DateRangeContext'
import CollapsibleViewsNoData from '../CollapsibleViewsNoData'

const SleepSummary = ({
  title,
  subtitle,
  isLoading,
  hasData,
  hasError,
  summaries,
}) => {
  const { data: { sleep } = {} } =
    useContext(RecentDataAvailabilityContext) || {}

  const { totalSleepPerDayInHours, numberOfDaysWithData } = summaries || {}
  const ZERO = '0'

  if (isLoading) {
    return (
      <AccordionSummaryBarLoading
        title={title}
        subtitle={subtitle}
        dataLabel={labels.FETCHING_TEXT}
      />
    )
  }

  if (hasError) {
    return (
      <AccordionSummaryBarNoData
        title={title}
        subtitle={subtitle}
        dataLabel={labels.ERROR_TEXT}
      />
    )
  }

  if (!hasData) {
    return (
      <AccordionSummaryBarNoData
        title={title}
        subtitle={subtitle}
        dataLabel={
          <CollapsibleViewsNoData
            text={labels.NO_DATA_TEXT}
            latestActivityDate={sleep}
            alertType="Sleep"
          />
        }
      />
    )
  }

  const sleepAverage = prettyPrintRoundedTimeInHoursAndMinutes(
    totalSleepPerDayInHours?.average,
  )

  const sleepMax = prettyPrintRoundedTimeInHoursAndMinutes(
    totalSleepPerDayInHours?.max,
  )

  const sleepMin = prettyPrintRoundedTimeInHoursAndMinutes(
    totalSleepPerDayInHours?.min,
  )

  return (
    <AccordionSummaryBar
      title={title}
      subtitle={subtitle}
      dataComponents={[
        <SummaryMetric
          key={labels.AVG_SLEEP_PER_DAY}
          title={labels.AVG_SLEEP_PER_DAY}
          metrics={{
            h: sleepAverage.hours,
            m: sleepAverage.minutes,
          }}
          minMetrics={{
            h: sleepMin.hours,
            m: sleepMin.minutes,
          }}
          maxMetrics={{
            h: sleepMax.hours,
            m: sleepMax.minutes,
          }}
          tooltip={
            <BulletedTooltipContent
              bulletedContent={[
                labels.TOOLTIP_TOTAL_TEXT,
                labels.TOOLTIP_DAY_DEFINITION,
              ]}
            />
          }
        />,
      ]}
      metadataComponents={[
        <StyledKeyValuePair
          label={labels.NUMBER_DAYS_WITH_DATA}
          value={numberOfDaysWithData || ZERO}
        />,
      ]}
    />
  )
}

SleepSummary.propTypes = {
  title: string.isRequired,
  subtitle: string,
  dateRangeKey: string,
}

SleepSummary.defaultProps = {
  subtitle: '',
  dateRangeKey: DATE_RANGE.default.key,
}

export default SleepSummary
