import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import CopyText from '../../../../components/CopyText'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Warning } from '../../../../components/Notifications'
import ErrorBoundary from '../../../../components/ErrorBoundary'

const useStyles = makeStyles((theme) => ({
  root: {
    with: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  label: {
    color: '#666',
  },
  warning: {
    marginTop: theme.spacing(2),
  },
}))

export const ClientKeys = ({ secret, clientKeyId, deviceShortId, heading }) => {
  const classes = useStyles()
  return (
    <ErrorBoundary>
      <Box className={classes.root}>
        {heading}
        <FormControl className={classes.formControl}>
          <Typography className={classes.label} variant="subtitle1">
            Client Key ID
          </Typography>
          <CopyText value={clientKeyId} />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography className={classes.label} variant="subtitle1">
            Client Key Secret
          </Typography>
          <CopyText value={secret} />
          <Warning
            className={classes.warning}
            action={null}
            message="This is the only time this secret will be displayed. Please use it now, or copy it to a secure location for later."
          />
        </FormControl>
        {deviceShortId && (
          <FormControl className={classes.formControl}>
            <Typography className={classes.label} variant="subtitle1">
              Phone Device ID
            </Typography>
            <CopyText value={deviceShortId} />
          </FormControl>
        )}
      </Box>
    </ErrorBoundary>
  )
}
