import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import { RUNE_GRAY_600, RUNE_GRAY_800 } from 'theme/colors'
import { StyledTooltip } from 'ui/components/StyledTooltip'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: RUNE_GRAY_800,
    cursor: 'pointer',
    verticalAlign: 'middle',
    width: 20,
    height: 20,
  },
  light: {
    color: RUNE_GRAY_600,
  },
  dark: {
    color: RUNE_GRAY_800,
  },
  primary: {
    color: theme.palette.primary.main,
  },
}))

const InformationalIconTooltip = ({ color, ...props }) => {
  const classes = useStyles()

  return (
    <StyledTooltip {...props}>
      <InfoOutlinedIcon
        className={clsx({
          [classes.icon]: true,
          [classes[color]]: true,
        })}
        aria-label="Info Icon"
      />
    </StyledTooltip>
  )
}

InformationalIconTooltip.propTypes = {
  color: PropTypes.oneOf(['dark', 'light', 'primary']),
}

InformationalIconTooltip.defaultProps = {
  color: 'light',
}

export default InformationalIconTooltip
