import { node, object, oneOf, oneOfType, string } from 'prop-types'
import * as MUI from '@mui/material'

const Text = ({
  children,
  className,
  color,
  component = 'span',
  variant,
  sx,
  ...props
}) => (
  <MUI.Typography {...{ className, color, component, variant, sx, ...props }}>
    {children}
  </MUI.Typography>
)

Text.propTypes = {
  children: oneOfType([string.isRequired, node.isRequired]),
  className: string,
  color: string,
  component: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']), // determines the HTML tag
  variant: oneOf([
    'head72',
    'head72B',
    'head72L',
    'head56',
    'head56B',
    'head56L',
    'head42',
    'head42B',
    'head42L',
    'head32',
    'head32B',
    'head32L',
    'head24',
    'head24B',
    'head24L',
    'head22',
    'head22B',
    'head22L',
    'head20',
    'head20B',
    'head20L',
    'head18',
    'head18B',
    'body16',
    'body16B',
    'body14',
    'body14B',
    'body12',
    'body12B',
    'body10',
    'body10B',
    'caps16',
    'caps16B',
    'caps14',
    'caps14B',
    'caps12',
    'caps12B',
    'caps10',
    'caps10B',
  ]).isRequired,
  sx: object,
}

export default Text
