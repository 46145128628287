import { string } from 'prop-types'
import { useContext } from 'react'
import { UserPHIContext } from 'ui/contexts'
import ProtectedRoute from '../ProtectedRoute'

const ProtectedByResearcherRole = ({ redirectPath }) => {
  const phiVisibility = useContext(UserPHIContext)

  return (
    <ProtectedRoute isAllowed={!phiVisibility} redirectPath={redirectPath} />
  )
}

ProtectedByResearcherRole.propTypes = {
  redirectPath: string.isRequired,
}

ProtectedByResearcherRole.defaultProps = {}

export default ProtectedByResearcherRole
