import React from 'react'
import { Box, Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Z_INDEX_ADDITIONAL_INFO_CONTAINER } from 'theme/zIndexRegistry'

const useStyles = makeStyles((theme) => ({
  additionalInfoContainer: {
    width: '300px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: Z_INDEX_ADDITIONAL_INFO_CONTAINER,
    background: '#373737',
    color: '#fff',
  },
  img: {
    maxWidth: '100%',
  },
  divider: {
    marginBottom: '1rem',
    backgroundColor: '#fff',
  },
  breaker: {
    height: '0.5rem',
  },
  spiralTest: {
    minHeight: '400px',
    backgroundPosition: 'center center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
}))

/**
 * Displays additional info for event streams in a floating panel.
 * Supports multiple formats.
 *
 * @param {object} data The stream data to display in the panel
 * @param {boolean} visible The visibility of the panel
 */
const AdditionalInfo = ({ data, visible }) => {
  const classes = useStyles()

  // if not displayed, just return a fragment
  if (!visible) {
    return <></>
  }

  const taskFormat = (taskData) => {
    return (
      <Box p={2} className={classes.additionalInfoContainer}>
        <Typography variant="h5" mb={20}>
          Additional Info
        </Typography>
        <Divider className={classes.divider} />
        <Typography>
          {taskData.timeTitle}
          <br />
          {taskData.time}
        </Typography>
        <div className={classes.breaker} />
        <Typography>
          Duration:
          <br />
          {taskData.duration}
        </Typography>
        {taskData.imgUrl && (
          <div
            className={classes.spiralTest}
            style={{ backgroundImage: 'url(' + taskData.imgUrlBG + ')' }}
          >
            <div
              className={classes.spiralTest}
              style={{ backgroundImage: 'url(' + taskData.imgUrl + ')' }}
            />
          </div>
        )}
        {taskData.instructions && (
          <Typography>{taskData.instructions}</Typography>
        )}
      </Box>
    )
  }

  const sleepFormat = (sleepData) => {
    return (
      <Box p={2} className={classes.additionalInfoContainer}>
        <Typography variant="h5" mb={20}>
          Additional Info
        </Typography>
        <Divider className={classes.divider} />
        <Typography>Event type: Sleep</Typography>
        <div className={classes.breaker} />
        <Typography>Hours Slept: {sleepData.hours_slept}</Typography>
        <div className={classes.breaker} />
        <Typography>Quality: {sleepData.quality}</Typography>
        <div className={classes.breaker} />
        <Typography>Times Woken Up: {sleepData.times_woken_up}</Typography>
        <div className={classes.breaker} />
        <Typography>Nap: {sleepData.nap ? 'True' : 'False'}</Typography>
      </Box>
    )
  }

  const symptomFormat = (symptomData) => {
    return (
      <Box p={2} className={classes.additionalInfoContainer}>
        <Typography variant="h5" mb={20}>
          Additional Info
        </Typography>
        <Divider className={classes.divider} />
        <Typography>Event type: Symptom</Typography>
        <div className={classes.breaker} />
        <Typography>Symptom: {symptomData.name}</Typography>
        <div className={classes.breaker} />
        {symptomData.duration && (
          <>
            <Typography>Duration: {symptomData.duration}</Typography>
            <div className={classes.breaker} />
          </>
        )}
        <Typography>Intensity: {symptomData.intensity}</Typography>
      </Box>
    )
  }

  const foodFormat = (foodData) => {
    return (
      <Box p={2} className={classes.additionalInfoContainer}>
        <Typography variant="h5" mb={20}>
          Additional Info
        </Typography>
        <Divider className={classes.divider} />
        <Typography>Event type: Food/Drink</Typography>
        <div className={classes.breaker} />
        <Typography>Type: {foodData.name}</Typography>
      </Box>
    )
  }

  const medicationFormat = (medicationData) => {
    const hasDosage = medicationData.dosage_strength ? true : false
    const quantity =
      hasDosage && medicationData.dosage_strength.quantity
        ? medicationData.dosage_strength.quantity
        : ''
    const unit =
      hasDosage && medicationData.dosage_strength.unit
        ? medicationData.dosage_strength.unit
        : ''
    const formattedDosage = quantity + unit

    return (
      <Box p={2} className={classes.additionalInfoContainer}>
        <Typography variant="h5" mb={20}>
          Additional Info
        </Typography>
        <Divider className={classes.divider} />
        <Typography>Event type: Medication</Typography>
        <div className={classes.breaker} />
        <Typography>Medication Name: {medicationData.name}</Typography>
        <div className={classes.breaker} />
        <Typography>
          Dosage Quantity: {medicationData.dosage_quantity || 'Not Provided'}
        </Typography>
        <div className={classes.breaker} />
        <Typography>
          Dosage Strength: {formattedDosage || 'Not Provided'}
        </Typography>
        <div className={classes.breaker} />
        <Typography>
          Failed Dose: {medicationData.failed_dose ? 'True' : 'False'}
        </Typography>
        <div className={classes.breaker} />
        <Typography>
          On Schedule: {medicationData.on_schedule ? 'True' : 'False'}
        </Typography>
      </Box>
    )
  }

  const activityFormat = (activityData) => {
    return (
      <Box p={2} className={classes.additionalInfoContainer}>
        <Typography variant="h5" mb={20}>
          Additional Info
        </Typography>
        <Divider className={classes.divider} />
        <Typography>Event type: Activity</Typography>
        <div className={classes.breaker} />
        <Typography>Name: {activityData.name}</Typography>
      </Box>
    )
  }

  const formatAdditionalInfo = () => {
    let formattedInfo
    switch (data.category) {
      case 'Task':
        formattedInfo = taskFormat(data)
        break
      case 'Sleep':
        formattedInfo = sleepFormat(data)
        break
      case 'Symptom':
      case 'StriveStudy':
      case 'Side Effect':
        formattedInfo = symptomFormat(data)
        break
      case 'Food & Drink':
        formattedInfo = foodFormat(data)
        break
      case 'Medication':
        formattedInfo = medicationFormat(data)
        break
      case 'Activity':
        formattedInfo = activityFormat(data)
        break
      default:
        formattedInfo = <div />
    }
    return formattedInfo
  }

  return <>{formatAdditionalInfo()}</>
}

export default AdditionalInfo
