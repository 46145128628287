import { cloneElement, isValidElement } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import { GridItemList } from 'ui/components/Grid'
import { RUNE_GRAY_600, RUNE_GRAY_800 } from 'theme/colors'

const useStyles = makeStyles({
  label: {
    color: RUNE_GRAY_600,
    fontWeight: 400,
    fontSize: '1em',
  },
  value: {
    color: RUNE_GRAY_800,
    fontWeight: 600,
    fontSize: '1em',
  },
  valueBold: {
    color: RUNE_GRAY_800,
    fontWeight: 700,
    fontSize: '1.5em',
  },
  icon: {
    verticalAlign: 'bottom',
  },
})

const StyledKeyValuePair = ({
  label,
  value,
  variant,
  startIcon,
  middleIcon,
  endIcon,
}) => {
  const classes = useStyles()
  const valueClasses = variant === 'bold' ? classes.valueBold : classes.value

  const addClassName = (element = null, className) =>
    isValidElement(element)
      ? cloneElement(element, { className: className })
      : element

  const gridItems = [
    [startIcon, addClassName(startIcon, classes.icon)],
    [label, <Typography className={classes.label}>{label}</Typography>],
    [middleIcon, addClassName(middleIcon, classes.icon)],
    [
      value,
      <Typography className={valueClasses} data-testid={`value-${label}`}>
        {value}
      </Typography>,
    ],
    [endIcon, addClassName(endIcon, classes.icon)],
  ]
    .filter(([item]) => !!item)
    .map(([_, element]) => element)

  return <GridItemList elements={gridItems} spacing={1} />
}

StyledKeyValuePair.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(['bold']),
  startIcon: PropTypes.element,
  middleIcon: PropTypes.element,
  endIcon: PropTypes.element,
}

StyledKeyValuePair.defaultProps = {
  label: '',
  value: '',
  variant: null,
  startIcon: null,
  middleIcon: null,
  endIcon: null,
}

export default StyledKeyValuePair
