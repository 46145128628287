export class MockRollbar {
  error = (...args) => {
    console.error(...args)
  }

  critical = (...args) => {
    console.error(...args)
  }

  warning = (...args) => {
    console.warning(...args)
  }

  info = (...args) => {
    console.info(...args)
  }

  debug = (...args) => {
    console.log(...args)
  }
}

/**
 * Wrapper around the app's current logging vendor.
 */
class Logger {
  constructor(rollbar) {
    if (!rollbar) {
      throw new Error('Rollbar SDK client required')
    }

    this.rollbar = rollbar
  }

  error = (...args) => {
    return this.rollbar.error(...args)
  }

  critical = (...args) => {
    return this.rollbar.critical(...args)
  }

  warning = (...args) => {
    return this.rollbar.warning(...args)
  }

  info = (...args) => {
    return this.rollbar.info(...args)
  }

  debug = (...args) => {
    return this.rollbar.debug(...args)
  }
}

export default Logger
