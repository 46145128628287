import React from 'react'
import EventsStream from './EventsStream'

/**
 * Plot container component that passes Symptoms-events-stream-specific options to the Events stream.
 *
 * @param {object} pointData The point event request data for the plot
 * @param {object} spanData The span event request data for the plot
 * @param {object} layout The plot layout
 * @param {function} onHover Function that grabs point data to send to AdditionalInfo
 * @param {function} onUnhover Function that unmounts AdditionalInfo
 * @param {float} rangeStartLocal Beginning of time range, used only as a placeholder
 * @param {float} rangeEndLocal End of time range, used only as a placeholder
 */
const Symptoms = ({
  pointData,
  spanData,
  layout,
  onHover,
  onUnhover,
  rangeStartLocal,
  rangeEndLocal,
}) => {
  const pointColor = 'rgb(148, 20, 20)'
  const colorScale = [
    [0, 'rgba(148, 20, 20, 0.6)'],
    [1, 'rgba(148, 20, 20, 0.9)'],
  ]

  /**
   * Any custom logic/function, including custom hover handlers, would be
   * located here, passed to the EventsStream, and conditionally handled there.
   */

  return (
    <EventsStream
      pointData={pointData}
      spanData={spanData}
      layout={layout}
      plotTitle={'Symptoms'}
      pointColor={pointColor}
      colorScale={colorScale}
      onHover={onHover}
      onUnhover={onUnhover}
      rangeStartLocal={rangeStartLocal}
      rangeEndLocal={rangeEndLocal}
    />
  )
}

export default Symptoms
