import { memo } from 'react'
import { Grid, Link, makeStyles } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'

import { RUNE_BLUE_050, RUNE_GRAY_900 } from 'theme/colors'
import { oneOf, string } from 'prop-types'

const useStyles = makeStyles(() => ({
  linkRoot: {
    color: RUNE_GRAY_900,
    backgroundColor: RUNE_BLUE_050,
    fontWeight: 400,
    padding: '.25rem .5rem',
  },
  openInNewIconContainer: {
    paddingBottom: '0 !important',
  },
}))

const OpenLinkInNewTab = ({ justifyContentPosition, link, text }) => {
  const classes = useStyles()

  return (
    <Grid container justifyContent={justifyContentPosition}>
      <Link
        href={link}
        target="_blank"
        rel="noreferrer"
        classes={{ root: classes.linkRoot }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item>{text}</Grid>
          <Grid item className={classes.openInNewIconContainer}>
            <OpenInNew />
          </Grid>
        </Grid>
      </Link>
    </Grid>
  )
}

OpenLinkInNewTab.propTypes = {
  justifyContentPosition: oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-around',
    'space-evenly',
  ]),
  link: string.isRequired,
  text: string.isRequired,
}

OpenLinkInNewTab.defaultProps = {}

export default memo(OpenLinkInNewTab)
