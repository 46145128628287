import { useEffect, useMemo } from 'react'
import ReportSection from 'ui/clinicianScreens/Patient/Report/components/ReportSection'
import {
  formatAverage,
  formatDiff,
} from 'ui/clinicianScreens/Patient/Report/helpers'
import {
  defaultAverage,
  getAverageDiff,
} from 'ui/clinicianScreens/Patient/Report/sections/TremorAndDyskinesia/helpers'
import {
  HELPER_TREMOR_EMPTY_STATE,
  HELPER_TREMOR,
  HELPER_TREMOR_CLINICIAN,
} from 'ui/clinicianScreens/Patient/Report/sections/TremorAndDyskinesia/Tremor/consts'
import {
  DATA_ATTRIBUTES,
  SECTION_TITLES,
} from 'ui/clinicianScreens/Patient/Report/consts'
import ReportChartTremor from 'ui/clinicianScreens/Patient/Report/sections/TremorAndDyskinesia/Tremor/ReportChartTremor'

const ReportSectionTremor = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
  data,
  prevData,
  loading,
}) => {
  const title = SECTION_TITLES.TREMOR
  const { tremor: tremorDataAttributes } = DATA_ATTRIBUTES
  const {
    noTremorDataAttribute,
    tremorChartPreviousAttribute,
    tremorChartRecentAttribute,
  } = tremorDataAttributes

  useEffect(() => {
    if (loading) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  let tremorAverage = { ...defaultAverage }

  let prevTremorAverage = { ...defaultAverage }

  if (data) {
    tremorAverage = formatAverage(data)
  }

  if (prevData) {
    prevTremorAverage = formatAverage(prevData)
  }

  const tremorAverageDiff = getAverageDiff(tremorAverage, prevTremorAverage)
  const hasTremorData = data?.numberOfDaysWithData > 0
  const hasPrevTremorData = prevData?.numberOfDaysWithData > 0

  useEffect(() => {
    hasTremorData ? addToHasDataSet(title) : removeFromHasDataSet(title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTremorData])

  const attributesForPlaywrightScript = useMemo(() => {
    const expectedAttributes = []

    if (!hasTremorData) {
      expectedAttributes.push(noTremorDataAttribute.playwrightScriptSelector)
    }

    if (hasTremorData) {
      expectedAttributes.push(
        tremorChartRecentAttribute.playwrightScriptSelector,
      )
    }

    if (hasTremorData && hasPrevTremorData) {
      expectedAttributes.push(
        tremorChartPreviousAttribute.playwrightScriptSelector,
      )
    }

    return expectedAttributes
  }, [hasTremorData, hasPrevTremorData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ReportSection
        {...{
          hidden:
            hiddenSections.has(title) ||
            (isClinicianReport && !hasDataSet.has(title)),
          noBorder: true,
          ...(!hasTremorData && {
            [`data-${noTremorDataAttribute.carrotWebSuffix}`]: true,
          }),
          ...{
            'data-expected-tremor-attributes': attributesForPlaywrightScript,
          },
        }}
      >
        <ReportChartTremor
          {...{
            currentData: data,
            prevData,
            onLoadStart,
            onLoadEnd,
            diff: formatDiff(tremorAverageDiff),
          }}
        />
        {!isClinicianReport &&
          (hasTremorData ? HELPER_TREMOR : HELPER_TREMOR_EMPTY_STATE)}
        {isClinicianReport && hasTremorData && HELPER_TREMOR_CLINICIAN}
      </ReportSection>
    </>
  )
}

export default ReportSectionTremor
