import { useCallback, useContext } from 'react'
import moment from 'moment-timezone'
import { useStreamApiV2 } from 'domains/streamApi'
import { duration } from 'ui/clinicianScreens/Patient/consts'
import {
  getLatestDeviceId,
  DEVICE_KINDS_STRIVESTUDY,
} from 'ui/clinicianScreens/Patient/Summary/summaryHelpers'
import { formatSleepDataV2 } from 'ui/clinicianScreens/Patient/Summary/components/CollapsibleViews/Sleep/helpers'
import { PatientContext } from 'ui/contexts'
import { DAY_IN_SECONDS, MINUTE_IN_SECONDS, NOON_HOUR } from 'utilities/time'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'

const useSleepAggregationEndpoint = (startTime, endTime) => {
  const { deviceList, id } = useContext(PatientContext)
  const { devices } = deviceList || {}
  const deviceId = getLatestDeviceId(devices, DEVICE_KINDS_STRIVESTUDY)

  const buildFilters = (patientId, deviceId) => ({
    patientId,
    deviceId,
    streamTypeId: 'boolean',
    parameters: [
      {
        key: 'category',
        value: 'sleep',
      },
      {
        key: 'measurement',
        value: 'sleep_state',
      },
      {
        key: 'sleep_status',
        value: 'asleep',
      },
      {
        key: 'source_device',
        value: 'apple_health',
      },
    ],
    algorithm: 'ingest-strive-healthkit.1',
  })

  const buildParams = (startTime, endTime) => ({
    start_time: startTime,
    resolution: MINUTE_IN_SECONDS * duration.INTERVAL,
    n_days: Math.round((endTime - startTime) / DAY_IN_SECONDS),
  })

  const {
    data: streamData = [],
    loading,
    errors,
  } = useStreamApiV2({
    filters: buildFilters(id, deviceId),
    params: buildParams(startTime, endTime),
    endpointSuffix: 'daily_aggregate',
    skip: !deviceId || !startTime || !endTime,
    streamFilter: useCallback(
      ({ minTime, maxTime }) => startTime < maxTime && endTime > minTime,
      [endTime, startTime],
    ),
  })

  return {
    isLoading: loading,
    errors: errors,
    hasData: !!streamData?.length,
    data: streamData,
  }
}

const DEFAULT_TIME = 0

const useSleepAggregate = (startDate, endDate) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  let startTime = DEFAULT_TIME
  if (startDate) {
    startTime = moment
      .tz(startDate, selectedTimezone)
      .subtract(NOON_HOUR, 'h')
      .unix()
  }

  let endTime = DEFAULT_TIME
  if (endDate) {
    endTime = moment.tz(endDate, selectedTimezone).add(NOON_HOUR, 'h').unix()
  }

  const { data, hasData, isLoading, errors } = useSleepAggregationEndpoint(
    startTime,
    endTime,
  )

  return {
    data: hasData
      ? formatSleepDataV2({
          startTime,
          data,
          selectedTimezone,
        })
      : {},
    hasData: hasData,
    isLoading: isLoading,
    errors: errors,
    hasError: !!errors?.length,
  }
}
export default useSleepAggregate
