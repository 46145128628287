import useBoundStore from 'domains/zustand/store'
import { node, number, string } from 'prop-types'
import { useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { stringifyQueryParameters } from 'ui/clinicianScreens/Patient/helpers'
import { DATE_FORMAT } from 'ui/consts'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import {
  getDateFromTimestamp,
  getTwoWeeksBeforeTimestamp,
} from 'utilities/time'

const { Link } = require('ui/baseComponents/Link')

const JumpToDateLink = ({ children, date, alertType }) => {
  const [, setSearchParams] = useSearchParams()
  const { selectedTimezone, setDateRange: setDateRangeContext } =
    useContext(DateRangeContext)
  const setDateJumpAlert = useBoundStore((state) => state.setDateJumpAlert)

  const updateSearchParams = (from, to) => {
    const newSearchParams = stringifyQueryParameters(from, to)
    setDateRangeContext(from, to, DATE_RANGE.default.key)
    setSearchParams(newSearchParams, {
      replace: true,
    })
  }

  const onClick = () => {
    const from = getTwoWeeksBeforeTimestamp(date, selectedTimezone)
    const to = getDateFromTimestamp(date, selectedTimezone, DATE_FORMAT)
    updateSearchParams(from, to)

    if (alertType) {
      setDateJumpAlert(alertType)
    }
  }

  return <Link onClick={onClick}>{children}</Link>
}

JumpToDateLink.propTypes = {
  children: node.isRequired,
  date: number.isRequired,
  alertType: string,
}

export default JumpToDateLink
