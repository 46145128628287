import React from 'react'
import darkLogo from 'assets/img/rune_light.svg'
import { EmptyPage } from 'ui/components/EmptyPage'
import { fmtMomentLocal } from 'utilities/time'
import maintenance from 'assets/img/maintenance.svg'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  box: {
    fontSize: 'medium',
    display: 'block',
    alignItems: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.default.main,
    textAlign: 'center',
    width: '100%',
  },
  message: {
    margin: '40px',
  },
  imageIcon: {
    height: '40px',
    marginLeft: theme.imageIcon.marginLeft,
    marginTop: theme.imageIcon.marginTop,
  },
}))

/**
 * The container that holds a specific patient's name and some PHI as a header to their chart pages
 * @param {object} endTime Object with representation of when the maintenance is expected to end
 * @return {JSX.Element} Maintenance notification for the app user
 */
export const MaintenanceNotification = (endTime) => {
  const classes = useStyles()
  return (
    <EmptyPage
      style={{ backgroundColor: '#ffffff', height: '100vh', width: '100%' }}
    >
      <div className={classes.box}>
        <img
          src={maintenance}
          width={'90%'}
          alt="Cartoon of an anthropomorphic website jogging on a treadmill"
        />
        <div className={classes.message}>
          We're undergoing maintenance... It's like hitting the gym, but for
          websites.
        </div>
        <div className={classes.message}>
          We'll be back on {fmtMomentLocal('M/DD h:mma', endTime.endTime)}.
        </div>
        <img className={classes.imageIcon} src={darkLogo} alt="Rune logo" />
      </div>
    </EmptyPage>
  )
}
