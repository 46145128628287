import { useCallback, useContext, useState } from 'react'
import { forgotPassword as forgotPasswordAction } from 'domains/auth/actions'
import { useDispatch } from 'react-redux'
import ResetPasswordForm from './ResetPasswordForm'
import SendValidationCodeForm from './SendValidationCodeForm'
import SignInContext from 'ui/contexts/SignInContext'

/**
 * ResetPassword flow component. Allows user to submit a request for a
 * password reset verification code, and use that code to reset their password
 * @return {*} ResetPasswordForm or SendValidationCodeForm
 * @constructor
 */
const ResetPassword = () => {
  const { email, setError } = useContext(SignInContext)

  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [awaitingVerification, setAwaitingVerification] = useState(false)

  const handleResetPasswordSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        setSubmitting(true)
        await dispatch(forgotPasswordAction(email))
        setSubmitting(false)
        setAwaitingVerification(true)
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setError(error)
      }
    },
    [dispatch, email, setError],
  )

  if (awaitingVerification) {
    return <ResetPasswordForm />
  }

  return (
    <SendValidationCodeForm
      onSubmit={handleResetPasswordSubmit}
      submitting={submitting}
    />
  )
}

export default ResetPassword
