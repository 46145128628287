import React from 'react'
import { Chart } from 'react-google-charts'
import { Box, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const MAXIMUM_PERCENTAGE = 100
const ERROR_THRESHOLD = 75
const WARNING_THRESHOLD = 85

const useStyles = makeStyles(() => ({
  chartLabel: {
    width: '100%',
    transform: 'translate(-50%, -50%)',
  },
  chartPlaceholder: {
    height: '160px',
    width: '160px',
  },
}))

const getColors = (percent, theme) => {
  let color = theme.palette.green.main

  if (percent < ERROR_THRESHOLD) {
    color = theme.palette.red.main
  } else if (percent < WARNING_THRESHOLD) {
    color = theme.palette.orange.main
  }

  return [color, theme.palette.secondary.light]
}

const PercentCircle = ({ height, width, percent, label, ...props }) => {
  const classes = useStyles()
  const theme = useTheme()
  const myPercent = Math.min(Math.round(Math.abs(percent), MAXIMUM_PERCENTAGE))

  return (
    <Box position="relative" display="inline-flex" mx={3}>
      {percent ? (
        <Chart
          height={height}
          width={width}
          options={{
            colors: getColors(myPercent, theme),
            chartArea: {
              height: '90%',
              width: '90%',
            },
            pieHole: 0.8,
            legend: 'none',
            pieSliceText: 'none',
            tooltip: {
              trigger: 'none',
            },
          }}
          {...props}
          data={[
            ['State', 'Amount'],
            [label, myPercent],
            [`Non-${label}`, MAXIMUM_PERCENTAGE - myPercent],
          ]}
          chartType="PieChart"
        />
      ) : (
        <div className={classes.chartPlaceholder} />
      )}
      <Box
        position="absolute"
        top={height / 2}
        left={width / 2}
        className={classes.chartLabel}
      >
        <Typography variant="h5" align="center">
          {myPercent}%
        </Typography>
      </Box>
    </Box>
  )
}

export default PercentCircle
