import { usePermissions } from 'domains/carrotGraph/user'

/**
 * useIsAdmin returns the admin status of the user
 * @return {Object} - the admin status of the user, e.g. { loading: false, error: null, admin: true }
 */
const useIsAdmin = () => {
  const { loading, error, permissions } = usePermissions()
  const { admin } = permissions || {}
  return {
    loading,
    error,
    admin,
  }
}

export default useIsAdmin
