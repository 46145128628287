import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import { arrayOf, object } from 'prop-types'
import React from 'react'
import { defaultOptions, theme } from './configuration'
import clsx from 'clsx'

const DataTable = ({ title, options, noPadding, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
    noPadding: {
      padding: 0,
      '& [class*="MuiToolbar-root"]': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& h6[class*="MUIDataTableToolbar-titleText"]': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.5rem',
        },
      },
    },
  }))

  const classes = useStyles()

  const mergedOptions = {
    ...defaultOptions(title),
    ...options,
  }

  const mergedTheme = (parentTheme) =>
    createTheme({
      ...parentTheme,
      ...theme,
    })

  return (
    <ThemeProvider theme={mergedTheme}>
      <MUIDataTable
        title={title}
        options={mergedOptions}
        className={clsx(classes.root, { [classes.noPadding]: noPadding })}
        {...props}
      />
    </ThemeProvider>
  )
}

DataTable.propTypes = {
  options: object,
  columns: arrayOf(object),
}

DataTable.defaultProps = {
  options: {},
  columns: [],
}

export default DataTable
