import { RUNE_GRAY_600 } from 'theme/colors'

export const SUMMARY_BAR_BOX_SHADOW = {
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
}

export const SUMMARY_BAR_SUBTITLE_STYLE = {
  color: RUNE_GRAY_600,
  fontWeight: 400,
  fontSize: '1em',
}
