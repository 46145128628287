import { gql, useQuery } from '@apollo/client'

const GET_TASKS = gql`
  query getTasks(
    $patientId: ID!
    $startTime: Float!
    $endTime: Float!
    $deviceIds: [String!]
  ) {
    dataSessions(
      patientId: $patientId
      startTime: $startTime
      endTime: $endTime
      deviceIds: $deviceIds
    ) {
      id
      createdAt
      device {
        id
        alias
        deviceType {
          id
        }
      }
      deviceSessionId
      endTime
      startTime
      streams {
        streamName
      }
      schemaId
    }
  }
`

export const useDataSessions = ({ patientId, startTime, endTime }) => {
  return useQuery(GET_TASKS, {
    variables: {
      patientId,
      startTime,
      endTime,
    },
  })
}
