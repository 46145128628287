import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ClinicianPatientWrapper from 'ui/clinicianScreens/Patient/ClinicianPatientWrapper'
import EditPatient from 'ui/screens/EditPatient'
import Root from 'ui/screens/Root'
import { CLINICIAN_ROUTES, SHARED_ROUTES } from './consts'
import {
  ProtectedByAdmin,
  ProtectedByFeatureFlag,
  ProtectedByLogin,
} from './ProtectedRoutes'
import { useTitle } from 'ui/hooks'

const AppRoutes = () => {
  const { pathname } = useLocation()
  useTitle(pathname)
  // Reset scrolling position when path changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Routes>
      <Route element={<ProtectedByLogin />}>
        <Route path="/" element={<Root />}>
          <Route
            index
            element={<Navigate to={SHARED_ROUTES.patients.path} replace />}
          />

          {/* --------PATIENTS------- */}
          <Route path={SHARED_ROUTES.patients.path}>
            {/* --------DEFAULT (NO MATCH)------- */}
            <Route
              path="*"
              element={<Navigate to={SHARED_ROUTES.patients.path} />}
            />

            {/* --------PATIENT LIST------- */}
            <Route index element={SHARED_ROUTES.patients.element} />

            {/* --------TASK NOTIFICATIONS------- */}
            <Route element={<ProtectedByAdmin redirectPath=".." />}>
              <Route
                path={SHARED_ROUTES.taskNotifications.path}
                element={SHARED_ROUTES.taskNotifications.element}
              />
            </Route>

            {/* --------SINGLE PATIENT------- */}
            <Route
              path={SHARED_ROUTES.patientId.path}
              element={SHARED_ROUTES.patientId.element}
            >
              {/* --------PATIENT SETTINGS------- */}
              <Route element={<ProtectedByAdmin redirectPath=".." />}>
                <Route
                  path={SHARED_ROUTES.editPatient.path}
                  // element={SHARED_ROUTES.editPatient.element}  // doesn't work
                  element={<EditPatient />} // works
                >
                  {/* --------DEFAULT (NO MATCH)------- */}
                  <Route
                    index
                    path="*"
                    element={
                      <Navigate to={SHARED_ROUTES.details.path} replace />
                    }
                  />

                  {/* --------MATCHED ROUTES------- */}
                  <Route
                    path={SHARED_ROUTES.details.path}
                    element={SHARED_ROUTES.details.element}
                  />
                  <Route
                    path={SHARED_ROUTES.clients.path}
                    element={SHARED_ROUTES.clients.element}
                  />
                  <Route path={SHARED_ROUTES.devices.path}>
                    <Route index element={SHARED_ROUTES.devices.element} />
                    <Route
                      path={SHARED_ROUTES.deviceId.path}
                      element={SHARED_ROUTES.deviceId.element}
                    />
                  </Route>
                </Route>
              </Route>

              <Route
                path={CLINICIAN_ROUTES.reportPatientPDFTest.path}
                element={CLINICIAN_ROUTES.reportPatientPDFTest.element}
              />
              <Route element={<ClinicianPatientWrapper />}>
                {/* --------DEFAULT (NO MATCH)------- */}
                <Route
                  index
                  path="*"
                  element={
                    <Navigate to={CLINICIAN_ROUTES.summary.path} replace />
                  }
                />

                {/* --------MATCHED ROUTES------- */}
                <Route
                  path={CLINICIAN_ROUTES.daily.path}
                  element={CLINICIAN_ROUTES.daily.element}
                />
                <Route
                  path={CLINICIAN_ROUTES.log.path}
                  element={CLINICIAN_ROUTES.log.element}
                />
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="overviewTabVisible"
                      redirectPath={CLINICIAN_ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={CLINICIAN_ROUTES.overview.path}
                    element={CLINICIAN_ROUTES.overview.element}
                  />
                </Route>
                <Route
                  path={CLINICIAN_ROUTES.summary.path}
                  element={CLINICIAN_ROUTES.summary.element}
                />
                <Route
                  path={CLINICIAN_ROUTES.summaryComparison.path}
                  element={CLINICIAN_ROUTES.summaryComparison.element}
                />
                <Route
                  path={CLINICIAN_ROUTES.reportPatient.path}
                  element={CLINICIAN_ROUTES.reportPatient.element}
                />
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="clinicianReportVisible"
                      redirectPath={CLINICIAN_ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={CLINICIAN_ROUTES.reportClinician.path}
                    element={CLINICIAN_ROUTES.reportClinician.element}
                  />
                </Route>
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="weeklyViewTabVisible"
                      redirectPath={CLINICIAN_ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={CLINICIAN_ROUTES.weeklyView.path}
                    element={CLINICIAN_ROUTES.weeklyView.element}
                  />
                </Route>
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="striveGptVisible"
                      redirectPath={CLINICIAN_ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={CLINICIAN_ROUTES.questionAnswer.path}
                    element={CLINICIAN_ROUTES.questionAnswer.element}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* --------USER SETTINGS------- */}
          <Route
            path={SHARED_ROUTES.settings.path}
            element={SHARED_ROUTES.settings.element}
          >
            {/* --------DEFAULT (NO MATCH)------- */}
            <Route
              index
              path="*"
              element={<Navigate to={SHARED_ROUTES.profile.path} replace />}
            />

            {/* --------MATCHED ROUTES------- */}
            <Route
              path={SHARED_ROUTES.profile.path}
              element={SHARED_ROUTES.profile.element}
            />
            <Route
              path={SHARED_ROUTES.cookiePreferences.path}
              element={SHARED_ROUTES.cookiePreferences.element}
            />
            <Route
              path={SHARED_ROUTES.accessTokens.path}
              element={SHARED_ROUTES.accessTokens.element}
            />
          </Route>

          {/* --------USERS------- */}
          <Route
            element={
              <ProtectedByAdmin redirectPath={SHARED_ROUTES.patients.path} />
            }
          >
            <Route
              path={SHARED_ROUTES.users.path}
              element={SHARED_ROUTES.users.element}
            />
          </Route>
        </Route>
      </Route>

      {/* --------SIGN IN------- */}
      <Route
        path={SHARED_ROUTES.signin.path}
        element={SHARED_ROUTES.signin.element}
      />
    </Routes>
  )
}

export default AppRoutes
