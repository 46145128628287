import { memo, useContext } from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'

import { SummarySection } from 'ui/components/SummarySection'
import {
  Sleep,
  TremorAndDyskinesia,
} from 'ui/clinicianScreens/Patient/Summary/components/CollapsibleViews'
import { MobilitySummary } from 'ui/clinicianScreens/Patient/Summary/components/CollapsibleViews/Mobility'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import {
  SECTION_TITLE,
  TRENDS_TOOLTIP_TEXT,
} from 'ui/clinicianScreens/Patient/consts'
import { labels as mobilityLabels } from 'ui/clinicianScreens/Patient/Summary/components/CollapsibleViews/Mobility/consts'

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(3),
  },
}))

const SummaryModeCollapsibleViews = () => {
  const classes = useStyles()
  const { dateRanges } = useContext(DateRangeContext)
  const { start, end } = dateRanges[DATE_RANGE.default.key] || {}

  return (
    <>
      <SummarySection
        title={SECTION_TITLE.watchOrPhone}
        subtitle={SECTION_TITLE.trends}
        tooltipText={TRENDS_TOOLTIP_TEXT}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TremorAndDyskinesia start={start} end={end} />
          </Grid>
          <Grid item>
            <MobilitySummary
              title={mobilityLabels.TITLE}
              start={start}
              end={end}
            />
          </Grid>
          <Grid item>
            <Sleep start={start} end={end} />
          </Grid>
        </Grid>
      </SummarySection>
      <Divider className={classes.divider} />
    </>
  )
}

export default memo(SummaryModeCollapsibleViews)
