import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  emptyData: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.secondary.medium,
    backgroundColor: theme.palette.secondary.veryLight,
    fontStyle: 'italic',
    padding: theme.spacing(0.75),
    marginBottom: theme.spacing(0.5),
  },
}))

/**
 * Visualization of a patient's data availability bitmap for a single time period.
 */
const EmptyMonthStream = () => {
  const classes = useStyles()
  return (
    <div className={classes.emptyData} data-cy="EmptyMonthStream">
      No Data is available in this month
    </div>
  )
}

export default EmptyMonthStream
