import { useContext } from 'react'
import { useDevicesAndEvents } from '../../../../../contexts/PatientContext'
import {
  getAllAvailableOptions,
  getDevicesFromStreamOptions,
  getDeviceStreamOptions,
  getStreamValues,
} from './streamOptions'
import { StreamsContext } from '../StreamsContext'
import { UserPHIContext } from 'ui/contexts'

/**
 * Get a list of all device-stream options for a list of devices
 * @return {array} An array of objects. Each object contains a device and a stream.
 */
export const useAllDeviceStreamOptions = () => {
  const devices = useDevicesAndEvents() || []
  const phiVisibility = useContext(UserPHIContext)

  return getDeviceStreamOptions(devices, phiVisibility)
}

/**
 * For a given set of stream Options, filter out any already-selected streams
 * @param {object} includeStream, the "current" stream to include. Object containing the following properties: deviceId, index, streamValue.
 * @return {array} Array of objects. Each object contains a device object and a stream object.
 */
export const useAvailableStreamOptions = (includeStream) => {
  const allStreamOptions = useAllDeviceStreamOptions()
  const { streams } = useContext(StreamsContext)

  return getAllAvailableOptions(allStreamOptions, streams, includeStream)
}

/**
 * Get an array of available devices, including a "current" stream
 * @param {object} includeStream, Object containing the following properties: deviceId, index, streamValue.
 * @return {array} Array of unique device objects.
 */
export const useAvailableDevices = (includeStream) => {
  const devices = useDevicesAndEvents()
  const streamOptions = useAvailableStreamOptions(includeStream)
  return getDevicesFromStreamOptions(streamOptions, devices)
}

/**
 * Get an array of available stream values for a given stream/device
 * @param {object} includeStream, Object containing the following properties: deviceId, index, streamValue.
 * @return {array} An array of stream values for a given device. Each stream value is an object with the following properties: label, streamType, and value.
 */
export const useAvailableStreamValues = (includeStream) => {
  const availableOptions = useAvailableStreamOptions(includeStream)
  return getStreamValues(availableOptions, includeStream.deviceId)
}
