import { useStreamApiBatchWithPartialDataHandling } from 'domains/streamApi'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { AGGREGATE_WINDOW_METRIC_TYPES } from 'ui/hooks/consts'
import { createWeeklyAggregateRequests } from 'ui/hooks/summaryAggregates/apiDataHelpers'
import { WEEK_IN_SECONDS } from 'utilities/time'

const useAggregateWindow = (startTime, endTime) => {
  const metrics = Object.values(AGGREGATE_WINDOW_METRIC_TYPES)

  const { selectedTimezone } = useContext(DateRangeContext)

  const patientContext = useContext(PatientContext)

  const requests = createWeeklyAggregateRequests({
    metrics,
    startTime,
    endTime,
    resolution: WEEK_IN_SECONDS,
    patientId: patientContext.id,
    selectedTimezone,
  })

  const {
    data = [],
    errors,
    loading,
  } = useStreamApiBatchWithPartialDataHandling(requests, selectedTimezone)

  return { data, loading, errors }
}

export default useAggregateWindow
