import { useContext, useState } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import { Error, NotificationBar } from '../../../components/Notifications'
import { makeStyles } from '@material-ui/core'
import { constructRoleVariables, useUpdateMembership } from '../mutations'
import { useRefetchUsers } from '../queries'
import { UserPHIContext } from 'ui/contexts'

const useStyles = makeStyles((theme) => ({
  typeSelect: {
    width: 100,
  },
}))
export const UserTypeSwitch = ({
  membershipId,
  admin: adminProp,
  membershipRole: roleProp,
  disabled,
}) => {
  const phiVisibility = useContext(UserPHIContext)

  const classes = useStyles()
  const [error, setError] = useState()
  const [updateMembership, { loading }] = useUpdateMembership({
    onError: (error) => {
      setError(error)
    },
    refetchQueries: [useRefetchUsers()],
  })
  const role = roleProp

  const onAdminChange = (event) => {
    const toggleVal = event.target.value
    const [adminVal, canSeePHIVal] = constructRoleVariables(toggleVal)
    updateMembership({
      variables: {
        input: {
          admin: adminVal,
          canSeePHI: canSeePHIVal,
          membershipId: membershipId,
        },
      },
    })
  }

  const handleClose = () => {
    setError(false)
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      <FormControl>
        <Select
          value={
            role !== null ? role.displayName : adminProp ? 'Admin' : 'Member'
          }
          onChange={onAdminChange}
          disabled={loading || disabled}
          className={classes.typeSelect}
          data-cy="user-list-role-select"
        >
          <MenuItem value="Admin" data-cy="user-list-role-option-admin">
            Admin
          </MenuItem>
          <MenuItem
            value="Clinician"
            data-cy="user-list-role-option-clinician"
            disabled={!phiVisibility}
          >
            Clinician
          </MenuItem>
          <MenuItem value="Member" data-cy="user-list-role-option-member">
            User
          </MenuItem>
        </Select>
        {error && (
          <NotificationBar open={!!error} onClose={handleClose}>
            <Error message="Unable to update User Type" onClose={handleClose} />
          </NotificationBar>
        )}
      </FormControl>
    </Box>
  )
}

UserTypeSwitch.propTypes = {
  membershipId: PropTypes.string.isRequired,
  admin: PropTypes.string.isRequired,
  membershipRole: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}
