export * from './mockStore'
export * from './store'

export const sleep = async (ms) => new Promise((r) => setTimeout(() => r(), ms))

const addRemoveKeys = ({ query, withKeys, omitKeys }) => {
  if (withKeys) {
    return Object.entries(query).reduce((iterator, [key, value]) => {
      if (!withKeys.includes(key)) {
        return iterator
      }
      return {
        ...iterator,
        [key]: value,
      }
    }, {})
  }

  if (omitKeys) {
    return Object.entries(query).reduce((iterator, [key, value]) => {
      if (omitKeys.includes(key)) {
        return iterator
      }
      return {
        ...iterator,
        [key]: value,
      }
    }, {})
  }

  return { ...query }
}

export const getNewQuery = ({
  query,
  mapKeys,
  withKeys,
  omitKeys,
  addParams,
  replaceParams,
}) => {
  if (replaceParams) {
    return replaceParams
  }
  const newQuery = mapKeys
    ? mapKeys(query)
    : addRemoveKeys({ query, withKeys, omitKeys })
  if (addParams) {
    return {
      ...newQuery,
      ...addParams,
    }
  }
  return newQuery
}

export const capitalize = (string) => {
  const [firstChar, ...restOfString] = string
  return string ? firstChar.toUpperCase() + restOfString : string
}

export const getHexColor = (color, theme) =>
  theme.palette === `${color}.main` ? `${color}.main` : color

const cleanNumbers = (array) => {
  const arr = Array.isArray(array)
    ? array.filter((i) => typeof i === 'number')
    : []
  return arr.length ? arr : [0]
}

export const sum = (array) => cleanNumbers(array).reduce((tot, x) => tot + x)

export const average = (array) => sum(array) / array.length

/**
 * Check if object is empty
 *
 * @param {object} obj Javascript object to check
 * @return {boolean} true if object has no properties, false otherwise
 */
export const isEmpty = (obj) => !Object.getOwnPropertyNames(obj).length
