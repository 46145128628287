import { useContext } from 'react'
import AppFooter from '../AppFooter'
import MainNav from '../MainNav'
import { Box, makeStyles } from '@material-ui/core'
import { UserPHIContext } from 'ui/contexts'
import CookieBanner from '../CookieBanner/CookieBanner'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
  },
  content: {
    flexGrow: 1,
  },
}))

/**
 * The default layout.
 *
 * The "default" layout is used for all signed-in users. It serves as a
 * wrapper that includes the top-level navigation sidebar, though not
 * sub-navigation menus (which are part of individual modules).
 * @returns {JSX.Element} Wrapper component for app layout
 */
function DefaultLayout({ children, sidebar }) {
  const phiVisibility = useContext(UserPHIContext)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <MainNav />
      <Box className={classes.contentContainer}>
        {sidebar ? sidebar : null}
        <div className={classes.content}>{children}</div>
      </Box>
      {phiVisibility && <AppFooter />}
      <CookieBanner />
    </div>
  )
}

export default DefaultLayout
