import { TableCell, TableRow } from '@mui/material'

/**
 * A component that represents a full-width row in a table.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content of the row.
 * @returns {JSX.Element} - The rendered component.
 */

// Since the number of columns is unknown, we use `colSpan="100%"` to span the row across all columns.
const FullWidthRow = ({ children }) => (
  <TableRow>
    <TableCell colSpan="100%">{children}</TableCell>
  </TableRow>
)

export default FullWidthRow
