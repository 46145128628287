import React from 'react'
import CreateDeviceDialog from './CreateDeviceDialog'
import CreateEntity from 'ui/components/CreateEntity'

const CreateDevice = () => (
  <CreateEntity label="Register Device">
    <CreateDeviceDialog />
  </CreateEntity>
)

export default CreateDevice
