import { DateRangePicker as DatePicker, isNextDay } from 'react-dates'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import { bool, oneOf } from 'prop-types'
import { useContext } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './DateRangePicker.scss'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { DateRangePickerContext } from 'ui/contexts/DateRangePickerContext'
import { DATE_FORMAT } from 'ui/consts'
import './DateRangePicker.css'

const hasValue = (x) => x && x !== 'Invalid date'

/**
 * Control for picking a start and end date (with no time-of-day resolution).
 * @returns {JSX.Element} Components for selecting a range of dates.
 */
const DateRangePicker = ({ size, noBorder }) => {
  const {
    to,
    from,
    minimumDate,
    maximumDate,
    focusedInput,
    setFocusedInput,
    onLeftArrowClick,
    onRightArrowClick,
    onDatePickerDatesChange,
    initialVisibleMonth,
    isOutsideRange,
  } = useContext(DateRangePickerContext)

  const today = moment().format(DATE_FORMAT)

  return (
    <>
      <IconButton
        size={size}
        data-cy="left-arrow"
        onClick={onLeftArrowClick}
        title="Previous period"
        disabled={!from || !to || isNextDay(moment(minimumDate), moment(from))}
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <DatePicker
        startDateAriaLabel="Start Date"
        endDateAriaLabel="End Date"
        startDatePlaceholderText="MM/DD/YYYY"
        endDatePlaceholderText="MM/DD/YYYY"
        startDate={hasValue(from) ? moment(from) : null}
        startDateId="start_date_id"
        endDate={hasValue(to) ? moment(to) : null}
        endDateId="end_date_id"
        onDatesChange={onDatePickerDatesChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        initialVisibleMonth={initialVisibleMonth}
        isOutsideRange={isOutsideRange}
        minimumNights={0}
        small={size === 'small'}
        noBorder={!!noBorder}
      />
      <IconButton
        size={size}
        disabled={
          !from ||
          !to ||
          to === today ||
          isNextDay(moment(to), moment(maximumDate))
        }
        data-cy="right-arrow"
        onClick={onRightArrowClick}
        title="Next period"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </>
  )
}

DateRangePicker.propTypes = {
  size: oneOf(['small', 'medium']),
  noBorder: bool,
}

DateRangePicker.defaultProps = {
  size: 'medium',
  noBorder: false,
}

export default DateRangePicker
