import React from 'react'
import PropTypes from 'prop-types'
import { Box, FormControl, makeStyles, Typography } from '@material-ui/core'
import { component } from 'utilities/runePropTypes'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
  label: {
    width: 'fit-content',
  },
  box: {
    justifyContent: 'space-between',
  },
  helperText: {
    width: 'fit-content',
  },
}))

/**
 * Component that renders a dialog with various actions and options.
 * @returns {JSX.Element} Children components wrapped with container styling and labeling.
 */
const FormFieldWrapper = ({ label, helperText, fullWidth, children }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.container} fullWidth={fullWidth}>
      <Box display="flex" className={classes.box}>
        <Typography variant="button" className={classes.label}>
          {label}
        </Typography>
        {helperText && (
          <Typography variant="overline" className={classes.helperText}>
            {helperText}
          </Typography>
        )}
      </Box>
      {children}
    </FormControl>
  )
}

FormFieldWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([component, PropTypes.arrayOf(component)]),
}

FormFieldWrapper.defaultProps = {
  fullWidth: true,
}

export default FormFieldWrapper
