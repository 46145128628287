import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { List as MaterialList, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const SPACING_VALUE_ONE = 1
const useStyles = makeStyles((theme) => ({
  root: {},
  dense: {
    marginTop: 0,
    marginBottom: 0,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  olNumber: {
    marginRight: theme.spacing(SPACING_VALUE_ONE),
  },
}))

const List = ({ component, dense, list, ...props }) => {
  const classes = useStyles()
  const INDEX_OFFSET = 1

  return (
    <MaterialList
      component={component}
      disablePadding
      className={classes.root}
      dense={dense}
      {...props}
    >
      {list.map(({ id, value }, index) => (
        <ListItem key={id} className={classes.listItem} data-testid={id}>
          <ListItemText className={clsx({ [classes.dense]: dense })}>
            {component === 'ol' ? (
              <span className={classes.olNumber}>{index + INDEX_OFFSET}.</span>
            ) : (
              ''
            )}
            {value}
          </ListItemText>
        </ListItem>
      ))}
    </MaterialList>
  )
}

List.propTypes = {
  component: PropTypes.string,
  dense: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
}

List.defaultProps = {
  component: 'ul',
  dense: false,
  list: [],
}

export default List
