import { get } from 'nested-property'
import { useContext } from 'react'
import OrgContext from 'ui/contexts/OrgContext'
import { gql, useQuery } from '@apollo/client'

export const GET_TASK_NOTIFICATIONS = gql`
  query getTaskList($orgId: ID!, $cursor: Cursor) {
    org(orgId: $orgId) {
      taskList(cursor: $cursor) {
        tasks {
          id
          disabled
          title
          notificationText
          introductionText
          defaultTaskSchedule {
            startTime
            endTime
            hasDefaultPeriod
            hasDefaultFrequency
            schedules {
              ... on IntervalClockSchedule {
                scheduleType
                dateInterval
                times
              }
              ... on CalendarClockSchedule {
                scheduleType
                daysOfWeek
                daysOfMonth
                months
                times
              }
            }
          }
          assignmentList {
            assignments {
              id
              disabled
            }
          }
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
`

/**
 * Get a list of Tasks for the default organization
 * @return {Object} Org object containing taskList
 */
export const useTaskNotifications = () => {
  const { id: orgId } = useContext(OrgContext)
  return useQuery(GET_TASK_NOTIFICATIONS, {
    fetchPolicy: 'cache-first',
    variables: { orgId, withDisabled: true },
    errorPolicy: 'all',
  })
}
export const useRefetchTaskNotifications = () => {
  const { id: orgId } = useContext(OrgContext)
  return {
    query: GET_TASK_NOTIFICATIONS,
    variables: { orgId, withDisabled: true },
  }
}

/**
 * Function that fetches further pages of tasks based on a cursor
 *
 * @param {function} fetchMore the function to append to the user query
 * @param {object} data the data for the org, which includes the taskList
 * @param {boolean} cursor the cursor indicating the page depth of our query
 *
 * @return {Object} concatenated request object for tasks
 */
export const fetchAllTasks = (fetchMore, data, cursor) => {
  fetchMore({
    variables: { cursor },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      // isolates the list to which we are appending
      const prevTasks = get(previousResult, 'org.taskList.tasks') || []
      const newTasks = get(fetchMoreResult, 'org.taskList.tasks') || []
      // spread operator used repeatedly to append data to only the correct list.
      return {
        ...fetchMoreResult,
        org: {
          ...(fetchMoreResult.org || {}),
          taskList: {
            ...(fetchMoreResult.org.taskList || {}),
            tasks: [...prevTasks, ...newTasks],
          },
        },
      }
    },
  })
}
