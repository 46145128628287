import { useContext } from 'react'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import UserPHIContext from '../ui/contexts/UserPHIContext'
import { ThemeProvider as MuiV5ThemeProvider } from '@mui/material/styles'
import {
  ThemeProvider as MuiV4ThemeProvider,
  withTheme as muiWithTheme,
} from '@material-ui/core'
import { ConfirmProvider } from 'material-ui-confirm'
import { researcherThemeMUIV4 } from 'theme/researcherThemeMUIV4'
import { clinicianThemeMUIV4 } from 'theme/clinicianThemeMUIV4'
import { clinicianTheme, researcherTheme } from 'theme'
import { useFlags } from 'domains/launchdarkly/hooks'

const MaterialUiTheme = ({ children }) => {
  const { deprecatedViewDisabled } = useFlags()
  const phiVisibility = useContext(UserPHIContext)
  return (
    <MuiV4ThemeProvider
      theme={
        phiVisibility || deprecatedViewDisabled
          ? clinicianThemeMUIV4
          : researcherThemeMUIV4
      }
    >
      <MuiV5ThemeProvider
        theme={
          phiVisibility || deprecatedViewDisabled
            ? clinicianTheme
            : researcherTheme
        }
      >
        {children}
      </MuiV5ThemeProvider>
    </MuiV4ThemeProvider>
  )
}

const StyledComponentsTheme = ({ children }) => {
  const { deprecatedViewDisabled } = useFlags()
  const phiVisibility = useContext(UserPHIContext)
  return (
    <StyledComponentsThemeProvider
      theme={
        phiVisibility || deprecatedViewDisabled
          ? clinicianThemeMUIV4
          : researcherThemeMUIV4
      }
    >
      {children}
    </StyledComponentsThemeProvider>
  )
}

const StyledComponentsThemeWithMui = muiWithTheme(StyledComponentsTheme)

export const WithTheme = ({ children }) => (
  <MaterialUiTheme>
    <StyledComponentsThemeWithMui>
      <ConfirmProvider>{children}</ConfirmProvider>
    </StyledComponentsThemeWithMui>
  </MaterialUiTheme>
)
