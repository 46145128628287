import { useEffect } from 'react'
import { func, number, string } from 'prop-types'
import moment from 'moment-timezone'

import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import useDateRangePickerContext, {
  DateRangePickerContext,
} from 'ui/contexts/DateRangePickerContext'
import { DATE_FORMAT, MAX_WEEKS } from 'ui/consts'
import { WEEK_IN_DAYS } from 'utilities/time'

const DataAvailabilityDateRangePickerContextProvider = ({
  from: fromProp,
  to: toProp,
  minimumDate,
  maximumDate,
  setDateRange,
  maxDays = MAX_WEEKS * WEEK_IN_DAYS,
  children,
  autoSelectDayCount,
  autoSelectEndDate,
}) => {
  const { selectedTimezone } = DateRangeContext
  const dateRangePickerContextValue = useDateRangePickerContext(
    fromProp,
    toProp,
    minimumDate,
    maximumDate,
    maxDays,
    setDateRange,
    autoSelectDayCount,
    autoSelectEndDate,
  )
  const {
    to,
    setTo,
    from,
    setFrom,
    focusedInput,
    getFirstVisibleMonthStartDate,
    getSecondVisibleMonthEndDate,
    getThirdVisibleMonthEndDate,
    setFirstVisibleMonthStartDate,
    setSecondVisibleMonthEndDate,
    setThirdVisibleMonthEndDate,
    errorMessage,
    setErrorMessage,
  } = dateRangePickerContextValue

  const INPUT = {
    openStartDate: 'startDate',
    openEndDate: 'endDate',
    exitDatePicker: null,
  }

  // Update component state if from / to props change
  useEffect(() => {
    setFrom(fromProp)
    setTo(toProp)
    setFirstVisibleMonthStartDate(getFirstVisibleMonthStartDate(fromProp))
    setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(fromProp))
    setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(fromProp))
  }, [fromProp, toProp]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (focusedInput) {
      case INPUT.openStartDate:
        // Wipe out the startDate so that future dates (up to today) will be available for selection
        setFrom()

        if (autoSelectEndDate) {
          setTo()
        }
        break

      case INPUT.openEndDate:
        setTo()
        break

      case INPUT.exitDatePicker:
        if (errorMessage) {
          setFrom(fromProp)
          setTo(toProp)
          setDateRange({ from: fromProp, to: toProp })
          setFirstVisibleMonthStartDate(getFirstVisibleMonthStartDate(fromProp))
          setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(fromProp))
          setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(fromProp))
          setErrorMessage('')
          return
        }

        if (!autoSelectEndDate && from && to) {
          setDateRange({ from, to })
        }

        if (!autoSelectEndDate && !from && fromProp) {
          setFrom(fromProp)
        }

        if (!autoSelectEndDate && !to && toProp) {
          setTo(toProp)
        }

        if (autoSelectEndDate && !from) {
          setFrom(fromProp)
          setTo(toProp)
          setDateRange({ from: fromProp, to: toProp })
          setFirstVisibleMonthStartDate(getFirstVisibleMonthStartDate(fromProp))
          setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(fromProp))
          setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(fromProp))
        }

        if (autoSelectEndDate && from) {
          const start = moment.tz(from, selectedTimezone)
          const newTo = start
            .clone()
            .add(autoSelectDayCount, 'days')
            .format(DATE_FORMAT)

          setTo(newTo)
          setDateRange({ from, to: newTo })
          setFirstVisibleMonthStartDate(getFirstVisibleMonthStartDate(from))
          setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(from))
          setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(from))
        }

        if (!autoSelectEndDate) {
          if (from && to) {
            setFirstVisibleMonthStartDate(getFirstVisibleMonthStartDate(from))
            setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(from))
            setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(from))
          } else {
            setFirstVisibleMonthStartDate(
              getFirstVisibleMonthStartDate(fromProp),
            )
            setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(fromProp))
            setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(fromProp))
          }
        }

        break

      default:
        break
    }
  }, [focusedInput]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DateRangePickerContext.Provider value={dateRangePickerContextValue}>
      {children}
    </DateRangePickerContext.Provider>
  )
}

DataAvailabilityDateRangePickerContextProvider.propTypes = {
  from: string,
  to: string,
  minimumDate: string,
  maximumDate: string,
  setDateRange: func,
  maxDays: number,
}

export default DataAvailabilityDateRangePickerContextProvider
