import { TableCell } from '@mui/material'

/**
 * Renders a header cell for the patient table.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content of the header cell.
 * @param {Object} props.sx - The style object for the header cell.
 * @returns {JSX.Element} The rendered header cell component
 */
const TableCellHeader = ({ children, sx }) => (
  <TableCell {...{ sx }}>{children}</TableCell>
)

export default TableCellHeader
