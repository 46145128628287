import { gql, useQuery } from '@apollo/client'

export const GET_FULL_TASK = gql`
  query getTaskList($taskId: ID!) {
    task(taskId: $taskId) {
      id
      title
      notificationText
      introductionText
      defaultTaskSchedule {
        startTime
        endTime
        hasDefaultPeriod
        hasDefaultFrequency
        schedules {
          ... on IntervalClockSchedule {
            scheduleType
            dateInterval
            times
          }
          ... on CalendarClockSchedule {
            scheduleType
            daysOfWeek
            daysOfMonth
            months
            times
          }
        }
      }
      assignmentList {
        assignments {
          id
          disabled
        }
      }
    }
  }
`

/**
 * Get a list of Tasks for the default organization
 *
 * @param {string} taskId the id of the task being requested
 * @return {Object} Org object containing taskList
 */
export const useFullTask = (taskId) =>
  useQuery(GET_FULL_TASK, {
    fetchPolicy: 'cache-and-network',
    variables: { taskId },
    errorPolicy: 'all',
  })
