import React from 'react'
import TaskActions from './FormComponents/TaskActions'
import TaskCopy from './FormComponents/TaskCopy'
import TaskPatientSelect from './FormComponents/TaskPatientSelect'
import TaskSchedule from './FormComponents/TaskSchedule'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { useFlags } from 'domains/launchdarkly/hooks'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  taskColumn: {
    flexGrow: 1,
    width: '33%',
    minWidth: '200px',
    padding: `0 ${theme.spacing(2)}px`,
    margin: '0 auto',
  },
}))

/**
 * The modal for creating a Task Notification.
 *
 * @returns {JSX.Element} the Task Notification creation modal
 */
export const CreateTaskDialog = ({ handleClose }) => {
  const classes = useStyles()
  const { taskActionsVisibleFlag } = useFlags()

  return (
    <Box display="flex">
      <ErrorBoundary>
        <div className={classes.taskColumn}>
          <TaskCopy />
          {taskActionsVisibleFlag && <TaskActions />}
        </div>
        <div className={classes.taskColumn}>
          <TaskSchedule />
        </div>
        <div className={classes.taskColumn}>
          <TaskPatientSelect />
        </div>
      </ErrorBoundary>
    </Box>
  )
}

export default CreateTaskDialog
