import * as MUI from '@mui/material'
import { arrayOf, string, oneOfType, number, node, object } from 'prop-types'

const Stack = ({
  children,
  direction,
  justifyContent,
  alignItems,
  spacing = 1,
  className,
  sx,
  ...props
}) => (
  <MUI.Stack
    {...{
      'data-cy': 'section',
      direction,
      justifyContent,
      alignItems,
      spacing,
      className,
      sx,
      ...props,
    }}
  >
    {children}
  </MUI.Stack>
)

Stack.propTypes = {
  children: oneOfType([arrayOf(node), node, string]),
  direction: string,
  justifyContent: string,
  alignItems: string,
  spacing: oneOfType([number, string]),
  className: string,
  sx: object,
}

export default Stack
