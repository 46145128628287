import React from 'react'
import { makeQuerySelector } from 'query'
import PersistentViewsButtonGroup from './PersistentViewsButtonGroup/PersistentViewsButtonGroup'
import PersistentViewsOptions from './PersistentViewsOptions/PersistentViewsOptions'
import PersistentViewsSelect from './PersistentViewsSelect/PersistentViewsSelect'
import { usePersistentViewsList } from './queries'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'

const viewSelector = makeQuerySelector('view')
const streamSelector = makeQuerySelector('streams')

/**
 * Container component for Persistent Views feature. Stateless,
 * but handles URL Param Detection.
 *
 * @param {object} patient The patient whose views are loaded.
 * @param {object} streams The stored result of the streams context.
 */
const PersistentViews = ({ patient, streams }) => {
  const viewIDString = useSelector(viewSelector)
  const streamsParamString = useSelector(streamSelector)
  const { persistentViewList } = usePersistentViewsList(patient.id)
  // Matches the view in the URL to the view in the list
  const selectedView =
    viewIDString && persistentViewList
      ? persistentViewList.find((view) => view.id === viewIDString)
      : ''
  // Detects a change in the view to toggle Update button visibility
  const editing =
    selectedView && selectedView.latest
      ? streamsParamString !== selectedView.latest.data
      : false
  // Accounts for the appearing/disappearing share button
  const marginBottom = selectedView ? 0 : 1

  return (
    <Box mt={5} ml={2} data-cy="PersistentViews">
      <Typography variant="h6">Saved Views</Typography>
      <Box display="flex" mt={1} mb={marginBottom}>
        <PersistentViewsSelect
          views={persistentViewList}
          selectedView={selectedView}
        />
        <PersistentViewsOptions selectedView={selectedView} />
      </Box>
      <PersistentViewsButtonGroup
        selectedView={selectedView}
        editing={editing}
        streams={streams}
        patient={patient}
      />
    </Box>
  )
}
export default PersistentViews
