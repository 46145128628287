import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab'

import { GoToButton } from 'ui/components/Button'
import Card from './Card'

const InfoCard = ({ action, children, loading, ...props }) => (
  <Card
    {...props}
    actions={action ? [<GoToButton key="action" {...action} />] : []}
  >
    {!loading && children}
    {loading && <Skeleton variant="rect" width="100%" height={100} />}
  </Card>
)

InfoCard.propTypes = {
  action: PropTypes.shape({
    label: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  loading: PropTypes.bool,
}

InfoCard.defaultProps = {
  action: null,
  loading: false,
}

export default InfoCard
