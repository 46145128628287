import React from 'react'
import { number, oneOfType, string } from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import { LEGEND_TEXT_STYLE } from './consts'

const useStyles = makeStyles(() => ({
  legendScaleText: {
    ...LEGEND_TEXT_STYLE,
  },
}))

const LegendScaleText = ({ children, testId }) => {
  const classes = useStyles()

  return (
    <Typography data-testid={testId} className={classes.legendScaleText}>
      {children}
    </Typography>
  )
}

LegendScaleText.propTypes = {
  children: oneOfType([string, number]),
  testId: string,
}

LegendScaleText.defaultProps = {}

export default LegendScaleText
