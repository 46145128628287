import moment from 'moment-timezone'
import { duration } from 'ui/clinicianScreens/Patient/consts'
import { DATETIME_FORMAT, DATE_FORMAT } from 'ui/consts'
import {
  SECOND_IN_MILLISECONDS,
  SECOND_IN_MICROSECONDS,
  MINUTE_IN_SECONDS,
  roundIntervalTime,
  NOON_HOUR,
  DAY_IN_HOURS,
} from 'utilities/time'
import { AFTER, BEFORE } from 'utilities/sort'
import { STATE_LABEL } from './consts'

const FIRST_DATA_ITEM_INDEX = 0
/**
 * Parses a unix timestamp (e.g. `1579659193`) into a human-readable date-time string.
 * If an optional time zone (e.g. `America/Los_Angeles`) is provided, resulting date-time
 * string will be adjusted for that time zone.
 * @param {number} timestamp unix timestamp
 * @param {string} timezone string representation of time zone
 * @returns {string} representation of date-time for that given time zone
 */
export const parseToTimezone = (timestamp, timezone) => {
  const parsedTime = timezone
    ? moment(timestamp * SECOND_IN_MILLISECONDS).tz(timezone)
    : moment(timestamp * SECOND_IN_MILLISECONDS)

  return parsedTime.format(DATETIME_FORMAT)
}

/**
 * Normalize nested span object to pull out key information and flatten.
 * @param {object[]} timespans list of "span" data as produced by the Rune Stream API
 * @returns {object[]} array of objects containing human-readable (and time-zone adjusted) start and end times, plus corresponding span label
 */
export const normalizeSpanData = (timespans = []) =>
  timespans.map(({ start_time: startTime, end_time: endTime, payload }) => {
    const { state_label: label } = payload || {}
    const startTimeString = parseToTimezone(startTime)
    const startTimeHour = moment(startTimeString).get('h')

    // Account for sleep stages - treat all variants of 'asleep' the same
    const updatedLabel = label?.includes(STATE_LABEL.asleep)
      ? STATE_LABEL.asleep
      : label

    return {
      startTime: startTimeString,
      endTime: parseToTimezone(endTime),
      nightLabel:
        startTimeHour < NOON_HOUR
          ? moment(startTimeString).format(DATE_FORMAT)
          : moment(startTimeString).add(1, 'd').format(DATE_FORMAT),
      durationInMinutes: (endTime - startTime) / MINUTE_IN_SECONDS,
      label: updatedLabel,
    }
  })

/**
 * Trims the first half-day and last-half day of data out of the dataset.
 * Spans that overlap the start boundary are excluded.
 * Spans that overlap the end boundary are included.
 * @param {string} startDate start date string
 * @param {string} endDate end date string
 * @param {object[]} timespans list of normalized timespans
 * @returns {object[]} Filtered timespans
 */
export const omitLeadingAndTrailingHalfDaySpans = (
  startDate,
  endDate,
  timespans = [],
) => {
  const startDateNoon = moment(startDate).hours(NOON_HOUR)
  const endDateNoon = moment(endDate).hours(NOON_HOUR)

  return timespans.filter(({ startTime }) => {
    const spanStartTime = moment(startTime)

    return spanStartTime >= startDateNoon && spanStartTime < endDateNoon
  })
}

/**
 * Given a timespan, determine the best-fit interval representation. Spans with durations less than the
 * specified interval should be represented by a single interval. Spans with durations greater than one
 * interval should be rounded up or down to the nearest whole-interval duration. Normalized start/end
 * times should reflect the closest start/end of an interval while maintaining the rounded duration.
 * @param {object} timespan Information about the timespan
 * @param {string} timespan.startTime Start datetime of the span, ajdusted for timezone
 * @param {string} timespan.endTime End datetime of the span, adjusted for timezone
 * @param {number} timespan.durationInMinutes Duration of the span in minutes
 * @param {number} intervalInMinutes Size of data interval in minutes
 * @returns {string[]} Array of normalized intervals representing the intervals for which the given timespan has data
 */
export const roundSleepTimespan = (
  { startTime, endTime, durationInMinutes },
  intervalInMinutes = duration.INTERVAL,
) => {
  startTime = moment(startTime)
  endTime = moment(endTime)
  const roundedDuration = Math.max(
    Math.round(durationInMinutes / intervalInMinutes) * intervalInMinutes,
    intervalInMinutes,
  )

  const roundedStartTime = roundIntervalTime(startTime, intervalInMinutes)
  const roundedStartDiff = Math.abs(roundedStartTime - startTime)

  const roundedEndTime = roundIntervalTime(endTime, intervalInMinutes)
  const roundedEndDiff = Math.abs(roundedEndTime - endTime)

  const start =
    roundedStartDiff <= roundedEndDiff
      ? roundedStartTime
      : moment(roundedEndTime).subtract(roundedDuration, 'm')

  return [...Array(roundedDuration / intervalInMinutes)].map((_, i) =>
    moment(start)
      .add(intervalInMinutes * i, 'm')
      .format('HH:mm'),
  )
}

/**
 * Calculates the probability that, for any given interval throughout a 24-hour period, an
 * individual is within an 'asleep' span.
 * @param {object[]} timespans List of sleep data spans
 * @param {number} numberOfDaysWithData Integer number of days with data
 * @param {number} intervalInMinutes Size of data interval in minutes
 * @returns {object} Object with arrays of times (divided into properly sized intervals) and
 * probabilities for a full 24-hour day. Probabilities reflect averages across all input
 * timespans.
 */
export const processSleepSpans = (
  timespans,
  numberOfDaysWithData,
  intervalInMinutes = duration.INTERVAL,
) => {
  const MINIMUM_SLEEP_DURATION = 0
  const intervalFrequencies = timespans
    .filter(({ label }) => label === STATE_LABEL.asleep)
    .map((timespan) => roundSleepTimespan(timespan, intervalInMinutes))
    .flat()
    .reduce((acc, timestamp) => {
      acc[timestamp] = (acc[timestamp] || MINIMUM_SLEEP_DURATION) + 1
      return acc
    }, {})

  const MINIMUM_PROBABILITY = 0
  const MAXIMUM_PROBABILITY = 1

  const probabilities = [
    ...Array((DAY_IN_HOURS * MINUTE_IN_SECONDS) / intervalInMinutes),
  ].map((_, i) => {
    const timestamp = moment()
      .set({ hour: 0, minute: intervalInMinutes * i, second: 0 })
      .format('HH:mm')

    const probability = numberOfDaysWithData
      ? (intervalFrequencies[timestamp] || MINIMUM_PROBABILITY) /
        numberOfDaysWithData
      : MINIMUM_PROBABILITY

    return {
      time: timestamp,
      probability:
        probability > MAXIMUM_PROBABILITY ? MAXIMUM_PROBABILITY : probability,
    }
  })

  return {
    times: probabilities.map((p) => p.time),
    probabilities: probabilities.map((p) => p.probability),
  }
}

/**
 * Calculates the probability that, for any given interval throughout a 24-hour period, an
 * individual is within an 'asleep' span.
 * @param {object} data Sleep aggregate data from the API
 * @returns {object[]} Array detailing the probability of sleep for each interval
 */
export const processSleepProbabilities = (data) => {
  const ZERO_DAYS = 0
  const numberOfDaysWithData =
    data[FIRST_DATA_ITEM_INDEX]?.data.n_days_with_data || []
  const totalNumberOfDays =
    data[FIRST_DATA_ITEM_INDEX]?.summary.n_days_with_data_total || ZERO_DAYS
  const probabilities = numberOfDaysWithData?.map(
    (day) => day / totalNumberOfDays,
  )

  return probabilities
}

/**
 * Maps the sleep aggregate data from the API to the format expected by the frontend.
 * @param {object} data Sleep aggregate data from the API
 * @returns {object} Sleep aggregate data formatted for the frontend
 */
const mapSleepAggregateDataV2 = (data) => {
  const summary = data[FIRST_DATA_ITEM_INDEX]?.summary || {}

  return {
    totalSleepPerDayInHours: {
      average: moment
        .duration(summary?.duration_mean_per_day / SECOND_IN_MICROSECONDS)
        .asHours(),
      min: moment
        .duration(summary?.duration_min_per_day / SECOND_IN_MICROSECONDS)
        .asHours(),
      max: moment
        .duration(summary?.duration_max_per_day / SECOND_IN_MICROSECONDS)
        .asHours(),
    },
    numberOfDaysWithData: summary?.n_days_with_data_total,
  }
}

/**
 * Set the time values.
 *
 * The API returns a list of "offsets". While these look like clock times, they're actually the number of hours
 * and minutes from the startTime. For example, if the startTime is 2020-01-01T22:00:00.000Z, and we
 * get an offset "01:00", that means the data at that point is for 23:00 (22:00 + 01:00).
 *
 * Similarly, if we get an offset of "04:00" for the same startTime, that means the data at that point is for 02:00
 *
 * Once we convert the offsets to the real clock times, we want to sort by the time, so it starts at 00:00 and ends at 23:00
 *
 * Example:
 * For the following data:
 *  start_time: 2020-01-01T22:00:00.000Z
 *  offset: [00:00, 01:00, 02:00, 03:00, 04:00]
 *  values: [1, 2, 3, 4, 5]
 *
 * We convert it to:
 *  times: [22:00, 23:00, 00:00, 01:00, 02:00]
 *  values: [1, 2, 3, 4, 5]
 *
 * We then sort it by time:
 *  times: [00:00, 01:00, 02:00, 22:00, 23:00]
 *  values: [3, 4, 5, 1, 2]
 *
 * @param {number} startTime The unixtimestamp of the start time
 * @param {object} data Sleep aggregate data from the API
 * @param {string[]} data.offsets The offsets from the start date
 * @param {number[]} data.probabilities The values for each offset
 * @param {string} selectedTimezone the timezone selected in the dropdown
 * @return {object} The formatted data
 * @return {string[]} return.times The hour of the day
 * @return {number[]} return.values The values for each hour
 */
const formatSleepForStartTime = ({ startTime, data, selectedTimezone }) => {
  const { offsets, probabilities } = data
  if (!offsets?.length || !probabilities?.length) {
    return {
      times: [],
      probabilities: [],
    }
  }

  const times = offsets.map(function addOffsetToStartTime(offset, i) {
    const [hoursToAdd, minutesToAdd] = offset
      .split(':')
      .map((value) => parseInt(value, 10))

    const time = moment(startTime * SECOND_IN_MILLISECONDS)
      .tz(selectedTimezone)
      .add(hoursToAdd, 'hours')
      .add(minutesToAdd, 'minutes')
      .format('HH:mm')

    return time
  })

  const combinedTimesAndProbabilities = times.map((time, i) => [
    time,
    probabilities[i],
  ])
  const sortedByTime = combinedTimesAndProbabilities.sort(
    ([timeA, probA], [timeB, probB]) => (timeA < timeB ? AFTER : BEFORE),
  )

  return {
    times: sortedByTime.map(([time, _]) => time),
    probabilities: sortedByTime.map(([_, probability]) => probability),
  }
}

/**
 * Formats the sleep aggregate summary data and sleep probability data into the format expected by
 * the frontend.
 * @param {number} startTime The unixtimestamp of the start date of the data
 * @param {object} data Sleep aggregate data from the API
 * @param {string} selectedTimezone the timezone selected in the dropdown
 * @returns {object} Sleep aggregate data formatted for the frontend
 */
export const formatSleepDataV2 = ({ startTime, data, selectedTimezone }) => {
  const midnightIndexedData = {
    offsets: data[0]?.data.offset || [],
    probabilities: processSleepProbabilities(data),
  }
  return {
    summaries: mapSleepAggregateDataV2(data),
    ...formatSleepForStartTime({
      startTime,
      data: midnightIndexedData,
      selectedTimezone,
    }),
  }
}
