import { memo } from 'react'
import { colors } from 'theme/colors'
import { Stack } from 'ui/baseComponents/Stack'
import { Text } from 'ui/baseComponents/Text'
import ReportTitle from './ReportTitle'
import { NO_DATA_EMPTY_STATE } from '../consts'

const ReportChartWithTitle = ({
  title,
  subtitle,
  trend,
  children,
  rightContent,
  loading,
  noData,
}) => {
  const STYLES = {
    heading: {
      padding: '0.5rem 1.5rem',
      background: colors.COOL[100],
    },
    headingMain: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      minWidth: 180,
    },
    rightContent: {
      justifySelf: 'flex-end',
    },
    trend: {
      marginLeft: '3rem',
    },
  }

  if (loading) {
    return (
      <Stack spacing={2}>
        <ReportTitle>
          <div style={STYLES.headingMain}>
            <div style={STYLES.title}>
              {title && (
                <Text
                  variant="head20B"
                  component="h2"
                  color={colors.PRIMARY[1000]}
                >
                  {title}
                </Text>
              )}
            </div>
          </div>
        </ReportTitle>
      </Stack>
    )
  }

  if (noData) {
    return (
      <Stack spacing={2}>
        <ReportTitle>
          <div style={STYLES.headingMain}>
            <div style={STYLES.title}>
              {title && (
                <Text
                  variant="head20B"
                  component="h2"
                  color={colors.PRIMARY[1000]}
                >
                  {title}
                </Text>
              )}
            </div>
            <div>
              <Text variant="body14">{NO_DATA_EMPTY_STATE}</Text>
            </div>
          </div>
        </ReportTitle>
      </Stack>
    )
  }

  return (
    <Stack spacing={2}>
      <ReportTitle>
        <div
          style={STYLES.headingMain}
          data-cy="report-chart-with-title-heading"
        >
          <div style={STYLES.title}>
            {title && (
              <Text
                variant="head20B"
                component="h2"
                color={colors.PRIMARY[1000]}
              >
                {title}
              </Text>
            )}
            {subtitle && (
              <Text
                variant="body16"
                component="h2"
                color={colors.PRIMARY[1000]}
              >
                {subtitle}
              </Text>
            )}
          </div>
          {trend && <div style={!rightContent && STYLES.trend}>{trend}</div>}
        </div>
        {rightContent && <div style={STYLES.rightContent}>{rightContent}</div>}
      </ReportTitle>
      {children}
    </Stack>
  )
}

export default memo(ReportChartWithTitle)
