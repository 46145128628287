import { useContext } from 'react'
import moment from 'moment'
import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import { translate as t } from 'ui/components/translate'
import SummaryTableCard from 'ui/components/SummaryTableCard'
import { PatientContext } from 'ui/contexts'
import { shape, string } from 'prop-types'
import { convertTimesToAMPMFormat } from 'utilities/time'

// Additional styling to override core styles for the different summary table cards
const useStyles = makeStyles(() => ({
  card: {
    position: 'relative',
    boxShadow: 'none',
  },
}))

const formatMedicineOrSupplement = ({ data, classes }) =>
  data.map((event, key) => {
    let dayCount = 0
    let { scheduleType } = event
    scheduleType =
      event.scheduleType === 'ON_DEMAND'
        ? 'On Demand'
        : scheduleType.toLowerCase()

    // Format the dosage depending on availability of dosage, unitQuantity and units
    // dosage may have a value even if unitQuanity and unit are null
    // unitQuantity and unit may have values even if dosage is null
    let dosageFormatted = ''
    const dosageDetails = []
    const zeroDosage = 0

    if (event.dosage && event.dosage !== zeroDosage) {
      dosageDetails.push(event.dosage)
    }

    if (event.unitQuantity && event.unit) {
      dosageDetails.push(`${event.unitQuantity} ${event.unit}`)
    }

    if (dosageDetails.length) {
      dosageFormatted = `${dosageDetails.join(' x ')}`
    }

    const medicationScheduleInPatientTimezone = event.localTime
    const medicationScheduleTimesFormatted = convertTimesToAMPMFormat(
      medicationScheduleInPatientTimezone,
    ).join(', ')

    // Either the schedule type OR days of the week depending on what's being utilized by the patient
    const zeroDaysOfTheWeekSelected = 0
    const scheduleFormatted =
      event.daysOfWeek.length === zeroDaysOfTheWeekSelected
        ? scheduleType
        : event.daysOfWeek.map((dayNumber) => {
            dayCount++
            return dayCount < event.daysOfWeek.length
              ? `${moment().day(dayNumber).format('ddd')} / `
              : moment().day(dayNumber).format('ddd')
          })

    // Print the items in the row, with time formatted for local, and days formatted from iso format to Mo/We/Fr
    return (
      <TableRow
        key={`${key}-medSuppLog`}
        type={'tr'}
        className={classes.tableRow}
      >
        <TableCell className={classes.tableCellBold}>
          {event.customDetail?.displayName || event.displayName}
        </TableCell>
        <TableCell
          className={classes.tableCell}
          data-testid="medications-schedule-dosage"
        >
          {dosageFormatted}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {medicationScheduleTimesFormatted}
        </TableCell>
        <TableCell className={classes.tableCellDetails}>
          {scheduleFormatted}
        </TableCell>
      </TableRow>
    )
  })

/**
 * The parent component to handle the manipulation of medication schedule data and then render the table
 *
 * @param {object} classes The stylings we'll apply to the various table components
 * @returns {JSX.Element} Two tables displaying the patient's Medications and Supplements. Each row will show the following data as available: medication/supplement name, dosage, time, frequency.
 */
const MedicationSchedule = ({ classes }) => {
  const { id, medicationScheduleList } = useContext(PatientContext)
  const schedules = medicationScheduleList?.schedules || []

  const extraClasses = useStyles()
  // We need a style on the first card different than the second (border bottom on the second, not the first)
  const firstCardStyles = { ...classes }
  firstCardStyles.card = extraClasses.card

  const medications = []
  const supplements = []
  // The nesting in graphql needs flattening and sorting into medication/supplement arrays
  schedules.forEach((item) =>
    item.classification && item.classification.category === 'medication'
      ? medications.push(item)
      : supplements.push(item),
  )
  const medicationLogRender = formatMedicineOrSupplement({
    data: medications,
    classes,
  })
  const supplementLogRender = formatMedicineOrSupplement({
    data: supplements,
    classes,
  })

  // The population of the two tables along with formatting
  return (
    <>
      <SummaryTableCard
        id={id}
        classes={firstCardStyles}
        tableTitle={t('patient.medication_schedule')}
        dataCyForTable={'MedicationLogTable'}
        separateTableTitle={t('patient.medications')}
      >
        {medicationLogRender}
      </SummaryTableCard>
      <SummaryTableCard
        id={id}
        classes={classes}
        dataCyForTable={'SupplementLogTable'}
        separateTableTitle={t('patient.supplements')}
      >
        {supplementLogRender}
      </SummaryTableCard>
    </>
  )
}

MedicationSchedule.propTypes = {
  classes: shape({
    card: string,
    subTitle: string,
    tableCell: string,
    tableRow: string,
  }),
}

MedicationSchedule.defaultProps = {}

export default MedicationSchedule
