import { colors } from 'theme/colors'
import { Text } from 'ui/baseComponents/Text'

const STYLES = {
  customTooltip: {
    backgroundColor: colors.PRIMARY[200],
    border: `1px solid ${colors.RUNE_GRAY_300}`,
    borderRadius: 10,
    boxShadow:
      '0px 4px 8px -2px rgba(0, 44, 76, 0.10), 0px 2px 4px -2px rgba(0, 44, 76, 0.06)',
    color: colors.BLACK,
    fontSize: '1em',
    fontWeight: 400,
    padding: '0.5rem',
    zIndex: 1,
  },
}

export const CustomMotorTooltip = ({ active, payload }) => {
  if (active && payload?.length) {
    const { duration, timestamp, Tremor, Dyskinesia } =
      payload[0]?.payload || {}

    return (
      <div style={STYLES.customTooltip}>
        <Text component="p" variant="caps12">{`${timestamp}`}</Text>
        {(Tremor > 0 || Dyskinesia > 0) && (
          <Text component="p" variant="body16">
            {duration} out of 15 minutes
          </Text>
        )}
        {Tremor === 0 && (
          <Text component="p" variant="body16">
            No tremor
          </Text>
        )}
        {Dyskinesia === 0 && (
          <Text component="p" variant="body16">
            No dyskinesia
          </Text>
        )}
      </div>
    )
  }
  return null
}

export const CustomMedicationTooltip = ({ active, payload }) => {
  if (active && payload?.length) {
    const { displayObjects, value } = payload[0]?.payload || {}
    const medicationDescription = displayObjects
      .map((obj) => `${obj.name} - ${obj.exactTime}`)
      .join(', ')

    return (
      <div style={STYLES.customTooltip}>
        {value && (
          <Text
            component="p"
            variant="body16"
          >{`${medicationDescription}`}</Text>
        )}
      </div>
    )
  }
  return null
}
