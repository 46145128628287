import { Fragment, useCallback, useEffect, useState } from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Box, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '../FormControl'
import IconButton from '@material-ui/core/IconButton'
import { getDate, getDayName, getDayNumber } from '../../../utilities/dates'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  daySeparator: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  form: {
    marginTop: -theme.spacing(6),
  },
  formContent: {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
  },
  dayInputContainer: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  fromInput: {
    width: 80,
  },
  toInput: {
    width: 110,
  },
  formError: {
    marginLeft: theme.spacing(2),
    marginBottom: -50,
  },
}))

const RelativeDayRangePicker = ({
  from,
  to,
  eventTimestamp,
  onDatesChange,
  leftArrow,
  rightArrow,
}) => {
  const classes = useStyles()
  const [editing, setEditing] = useState(false)
  const handleClick = useCallback(() => setEditing(true), [])
  const handleClose = useCallback(() => setEditing(false), [])
  const fromDays = getDayNumber(from, eventTimestamp)
  const toDays = getDayNumber(to, eventTimestamp)
  const setDayRange = useCallback(
    ({ from, to }) => {
      const startDate = getDate(from, eventTimestamp)
      const endDate = getDate(to, eventTimestamp)
      onDatesChange({ startDate, endDate })
    },
    [eventTimestamp, onDatesChange],
  )
  return (
    <Box
      tabIndex={0}
      className={classes.root}
      onClick={handleClick}
      title="Click to change date range"
    >
      {editing ? (
        <DayRangeInputPicker
          fromDays={fromDays}
          toDays={toDays}
          setDayRange={setDayRange}
          handleClose={handleClose}
        />
      ) : (
        <Fragment>
          {leftArrow}
          <Typography variant="h6">
            {getDayName(from, eventTimestamp)}
          </Typography>
          <Typography className={classes.daySeparator}>to</Typography>
          <Typography variant="h6">{getDayName(to, eventTimestamp)}</Typography>
          {rightArrow}
        </Fragment>
      )}
    </Box>
  )
}

const isValidRange = (from, to) => {
  if (
    isNaN(from) ||
    isNaN(to) ||
    typeof from !== 'number' ||
    typeof to !== 'number'
  ) {
    return false
  }
  return from < to
}

const ESCAPE_KEY_CODE = 27

const DayRangeInputPicker = ({
  fromDays,
  toDays,
  setDayRange,
  handleClose,
}) => {
  const classes = useStyles()
  const [from, setFrom] = useState(fromDays)
  const [to, setTo] = useState(toDays)
  const handleFromChange = useCallback((e) => {
    const days = parseInt(e.target.value, 10)
    if (isNaN(days)) {
      setFrom(e.target.value)
    } else {
      setFrom(days)
    }
  }, [])
  const handleToChange = useCallback((e) => {
    const days = parseInt(e.target.value, 10)
    if (isNaN(days)) {
      setTo(e.target.value)
    } else {
      setTo(days)
    }
  }, [])
  const [isValid, setIsValid] = useState(isValidRange(from, to))
  useEffect(() => setIsValid(isValidRange(from, to)), [from, to])
  const error = isValid ? null : 'Please enter a valid range'

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      if (isValid) {
        setDayRange({ from, to })
        handleClose()
      }
    },
    [from, handleClose, isValid, setDayRange, to],
  )
  const closeOnEscape = useCallback(
    (e) => {
      if (e.which === ESCAPE_KEY_CODE) {
        handleClose()
      }
    },
    [handleClose],
  )
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Box className={classes.formContent}>
        <FormControl className={classes.dayInputContainer}>
          <TextField
            autoFocus
            className={classes.fromInput}
            label="From"
            onKeyDown={closeOnEscape}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">Day&nbsp;</InputAdornment>
              ),
            }}
            type="number"
            value={from}
            error={!!error}
            InputLabelProps={{ shrink: true }}
            onChange={handleFromChange}
          />
        </FormControl>
        <FormControl className={classes.dayInputContainer}>
          <TextField
            className={classes.toInput}
            label="To"
            onKeyDown={closeOnEscape}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">Day&nbsp;</InputAdornment>
              ),
              endAdornment: (
                <IconButton
                  className={classes.checkIcon}
                  title="Apply Changes"
                  type="submit"
                  disabled={!isValid}
                  size="small"
                  color="primary"
                >
                  <CheckIcon />
                </IconButton>
              ),
            }}
            type="number"
            value={to}
            error={!!error}
            InputLabelProps={{ shrink: true }}
            onChange={handleToChange}
          />
        </FormControl>
        <Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e && e.stopPropagation()
              handleClose()
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <FormHelperText className={classes.formError} error>
        {error}
      </FormHelperText>
    </form>
  )
}

export default RelativeDayRangePicker
