import PropTypes from 'prop-types'
import React from 'react'
import { LabelledLoading } from '../LabelledLoading'
import { AccordionSummaryBar } from '.'

const AccordionSummaryBarLoading = ({
  title,
  subtitle,
  dataLabel,
  metadataLabels,
}) => (
  <AccordionSummaryBar
    title={title}
    subtitle={subtitle}
    dataComponents={[<LabelledLoading label={dataLabel} />]}
    metadataComponents={metadataLabels.map((metadataLabel) => (
      <LabelledLoading key={metadataLabel} label={metadataLabel} />
    ))}
  />
)

AccordionSummaryBarLoading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  dataLabel: PropTypes.string.isRequired,
  metadataLabels: PropTypes.arrayOf(PropTypes.string, PropTypes.object),
}

AccordionSummaryBarLoading.defaultProps = {
  subtitle: '',
  metadataLabels: [],
}

export default AccordionSummaryBarLoading
