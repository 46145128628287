export const orgMembershipIdList = (currentUser) =>
  currentUser.membershipList.memberships
    .map((membership) => membership.org.id)
    .toString()
    .split(',org,')
    .join(',org, ')

export const orgMembershipNamesList = (currentUser) =>
  currentUser.membershipList.memberships
    .map((membership) => membership.org.displayName)
    .toString()
    .split(',')
    .join(', ')
