import { useContext } from 'react'
import { bool, number, string } from 'prop-types'
import moment from 'moment-timezone'

import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { DateRangePickerContext } from 'ui/contexts/DateRangePickerContext'
import DateRangePicker from 'ui/baseComponents/DateRangePicker/DateRangePicker'
import useDataAvailabilitySnapshot from 'ui/hooks/useDataAvailabilitySnapshot'
import { DATE_FORMAT } from 'ui/consts'
import { hasValue } from 'ui/baseComponents/DateRangePicker/helpers'

const DataAvailabilityDateRangePicker = ({
  blockAndHighlightAutoSelectedDates,
  hasError,
  label,
  minimumNights,
  showArrows,
  show3Months,
  ...props
}) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const {
    to,
    from,
    getFirstVisibleMonthStartDate,
    getSecondVisibleMonthEndDate,
    getThirdVisibleMonthEndDate,
    firstVisibleMonthStartDate,
    secondVisibleMonthEndDate,
    thirdVisibleMonthEndDate,
    setFirstVisibleMonthStartDate,
    setSecondVisibleMonthEndDate,
    setThirdVisibleMonthEndDate,
    minimumDate,
    maximumDate,
    focusedInput,
    setFocusedInput,
    onLeftArrowClick,
    onRightArrowClick,
    onDatePickerDatesChange,
    initialVisibleMonth,
    isOutsideRange,
    errorMessage,
  } = useContext(DateRangePickerContext)

  const { data } =
    useDataAvailabilitySnapshot({
      startDate: firstVisibleMonthStartDate,
      endDate: show3Months
        ? thirdVisibleMonthEndDate
        : secondVisibleMonthEndDate,
      metricCategories: 'tremor_dyskinesia,mobility,sleep',
    }) || {}

  const START_DATE = hasValue(from) ? moment.tz(from, selectedTimezone) : null

  const getHighlightedDates = (minimumNights) => {
    const highlightedDates = new Set()
    if (!blockAndHighlightAutoSelectedDates || !START_DATE) {
      return highlightedDates
    }
    const highlightStartDate = START_DATE.clone().add(1, 'day')
    const highlightEndDate = highlightStartDate
      .clone()
      .add(minimumNights - 1, 'day') // Subtract 1 day to account for the start date being a different color

    while (highlightStartDate.isSameOrBefore(highlightEndDate)) {
      highlightedDates.add(highlightStartDate.format(DATE_FORMAT))
      highlightStartDate.add(1, 'day')
    }

    return highlightedDates
  }

  const getBlockedDates = (minimumNights) => {
    const blockedDates = new Set()
    if (!blockAndHighlightAutoSelectedDates || !START_DATE) {
      return blockedDates
    }
    const blockedStartDate = START_DATE.clone().add(1, 'day')
    const highlightEndDate = blockedStartDate
      .clone()
      .add(minimumNights - 1, 'day') // Subtract 1 day to account for the start date being a different color

    while (blockedStartDate.isBefore(highlightEndDate)) {
      blockedDates.add(blockedStartDate.format(DATE_FORMAT))
      blockedStartDate.add(1, 'day')
    }

    return blockedDates
  }

  const highlightedDates = getHighlightedDates(minimumNights)
  const blockedDates = getBlockedDates(minimumNights)

  return (
    <DateRangePicker
      {...{
        to,
        from,
        label,
        minimumDate,
        maximumDate,
        minimumNights,
        focusedInput,
        onLeftArrowClick,
        onRightArrowClick,
        onDatePickerDatesChange,
        initialVisibleMonth,
        isOutsideRange,
        data,
        timezoneName: selectedTimezone,
        highlightedDates,
        blockedDates,
        errorMessage,
        showArrows,
        hasError,
        ...props,
      }}
      variant="withLegend"
      onFocusChange={setFocusedInput}
      onPrevMonthClick={(e) => {
        setFirstVisibleMonthStartDate(getFirstVisibleMonthStartDate(e))
        setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(e))
        setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(e))
      }}
      onNextMonthClick={(e) => {
        setFirstVisibleMonthStartDate(getFirstVisibleMonthStartDate(e))
        setSecondVisibleMonthEndDate(getSecondVisibleMonthEndDate(e))
        setThirdVisibleMonthEndDate(getThirdVisibleMonthEndDate(e))
      }}
      dataTypes={['tremor_dyskinesia', 'sleep', 'mobility']}
    />
  )
}

DataAvailabilityDateRangePicker.propTypes = {
  blockAndHighlightAutoSelectedDates: bool,
  hasError: bool,
  label: string,
  minimumNights: number,
  showArrows: bool,
}

export default DataAvailabilityDateRangePicker
