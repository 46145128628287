import { useState, useEffect, useCallback } from 'react'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import CreateEntityDialog from 'ui/components/CreateEntity/CreateEntityDialog'
import { CREATE_VIEW } from '../mutations'
import { useMutation } from '@apollo/client'
import { useRefetchPersistentViewsList } from '../queries'
import { Dialog, FormControl, TextField } from '@material-ui/core'

/**
 * Component for displaying a dialog to create a new persistent view.
 *
 * @param {boolean} open The stateful boolean controlling the dialog's render
 * @param {function} handleClose Function that closes the dialog
 * @param {object} patient The patient whose id is needed for requests
 * @param {function} pushViewParam Function to update new view id in URL
 * @param {function} showCreationSuccess Function to inform the user of a successful creation
 * @param {object} streams The stored result of the streams context.
 */
const NewPersistentViewDialog = ({
  open,
  handleClose,
  patient,
  pushViewParam,
  showCreationSuccess,
  streams,
}) => {
  const [viewName, setViewName] = useState('')
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const [createViewMutation, { loading, data }] = useMutation(CREATE_VIEW, {
    onError: (error) => {
      console.error(error)
      setError('Unable to create view')
    },
    refetchQueries: [useRefetchPersistentViewsList(patient.id)],
    awaitRefetchQueries: true,
  })

  /**
   * Function to create a new view
   *
   * @param {object} input The input needed for the request
   */
  const createView = useCallback(
    async (input) => {
      try {
        await createViewMutation({ variables: { input } })
        setSuccess(true)
      } catch (error) {
        console.error(error)
        setError(error)
      }
    },
    [createViewMutation],
  )

  /**
   * Function run on submit that gathers the parameters and submits the request
   * for the new view.
   *
   * @param {object} input The input params needed for the request
   */
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        await createView({
          patientId: patient.id,
          displayName: viewName,
          dataSchemaVersion: 1,
          data: JSON.stringify(streams),
        })
      } catch (error) {
        console.error(error)
      }
    },
    [createView, viewName, streams, patient],
  )

  /**
   * Stores the typed view name in state to prep for the request
   *
   * @param {event} e The typing event in the <input />
   */
  const onChange = useCallback(
    (e) => setViewName(e.target.value),
    [setViewName],
  )

  useEffect(() => {
    if (data) {
      pushViewParam(data)
      showCreationSuccess()
      handleClose()
    }
  }, [data, pushViewParam, handleClose, showCreationSuccess])

  return (
    <Dialog open={open} p={3} onClose={handleClose}>
      <CreateEntityDialog
        title="Create a new Saved View"
        data-cy="createPersistentView"
        success={success}
        loading={loading}
        disabled={false}
        handleClose={handleClose}
        onSubmit={onSubmit}
      >
        <ErrorBoundary>
          <FormControl fullWidth>
            <TextField
              id="viewName"
              data-cy="viewName"
              error={!!error}
              helperText={error}
              disabled={loading}
              label="Name this view"
              value={viewName}
              onChange={onChange}
              autoFocus
            />
          </FormControl>
        </ErrorBoundary>
      </CreateEntityDialog>
    </Dialog>
  )
}

export default NewPersistentViewDialog
