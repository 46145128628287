import { Link } from 'ui/baseComponents/Link'
import { Text } from 'ui/baseComponents/Text'
import { VIDEO_LINKS } from 'ui/clinicianScreens/Patient/Report/consts'

export const TREMOR_HELPER_TEXT = {
  tremorChartBarInfo:
    'Each bar represents time with tremor, shown in 10-minute intervals across 24 hours.',
  understandTheDifference:
    'To understand the difference between tremor and dyskinesia and how StrivePD captures this data,',
}

export const HELPER_TREMOR = (
  <Text variant="body14" component="p" data-cy="tremor-helper">
    {TREMOR_HELPER_TEXT.tremorChartBarInfo}{' '}
    {TREMOR_HELPER_TEXT.understandTheDifference}{' '}
    <Link href={VIDEO_LINKS.tremorAndDyskinesia} external underline light>
      watch our video
    </Link>
    .
  </Text>
)

export const HELPER_TREMOR_CLINICIAN = (
  <Text variant="body14" component="p" data-cy="tremor-helper">
    {TREMOR_HELPER_TEXT.tremorChartBarInfo}
  </Text>
)

export const HELPER_TREMOR_EMPTY_STATE = (
  <Text variant="body14" component="p" data-cy="tremor-helper-no-data">
    If your Apple Watch hasn't been set up yet,{' '}
    <Link
      href={VIDEO_LINKS.howToSetUpAndPairYourAppleWatch}
      external
      underline
      light
    >
      follow these steps
    </Link>{' '}
    in our video tutorial. {TREMOR_HELPER_TEXT.understandTheDifference}{' '}
    <Link href={VIDEO_LINKS.tremorAndDyskinesia} external underline light>
      watch our video
    </Link>
    .
  </Text>
)
