import { forwardRef } from 'react'
import Link from '@material-ui/core/Link'
import { NavLink as RouterLink } from 'react-router-dom'

export const AdapterLink = forwardRef((props, ref) => (
  <RouterLink ref={ref} {...props} />
))

const NavLink = (props) => <Link component={AdapterLink} {...props} />
export default NavLink
