import Mutation from './mutation.mock'
import Query from './query.mock'

/**
 * Wrap a function with a log message before and after, logging its arguments and return value
 * @param func
 * @param message
 * @return {function(...[*]): *}
 */
const wrapWithLog =
  (func, message) =>
  (...args) => {
    console.log(`Calling: ${message}`, ...args)
    const result = func(...args)
    console.log(`After: ${message}`, result)
    return result
  }

/**
 * Wrap an object of methods with logging
 * @param obj
 * @param prefix
 * @return {[string, unknown]}
 */
const wrapMethodsWithLog = (obj, prefix) =>
  Object.entries(obj).reduce((iterator, [key, value]) => {
    return {
      ...iterator,
      [key]: wrapWithLog(value, `${prefix}.${key}`),
    }
  }, {})

/**
 * Mock resolvers for GQL schema.
 *
 * @param { isAdmin: bool, verbose: bool }
 * @return { Query, Mutation }
 */
export const resolvers = ({ isAdmin, verbose } = {}) => {
  if (verbose) {
    return {
      Query: wrapMethodsWithLog(Query({ isAdmin }), 'Query'),
      Mutation: wrapMethodsWithLog(Mutation, 'Mutation'),
    }
  } else {
    return {
      Query: Query({ isAdmin }),
      Mutation,
    }
  }
}

export default resolvers
