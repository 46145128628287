import React from 'react'
import './EllipsisLoader.scss'

/**
 * Ellisps loading animation.
 */
function EllipsisLoader() {
  return (
    <div
      data-cy="ellipsis-loader"
      data-testid="ellipsis-loader"
      className="rune-ellipsis-loader"
      title="loading"
    >
      <div className="ellipsis">
        <div className="dots"></div>
      </div>
    </div>
  )
}

export default EllipsisLoader
