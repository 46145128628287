import { node } from 'prop-types'
import { Box } from '@mui/material'
import { colors } from 'theme/colors'
import { HEADER_HEIGHT } from 'ui/components/MainNav'

const MainContent = ({ children }) => {
  const STYLES = {
    mainContent: {
      backgroundColor: colors.WHITE,
      minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
    },
  }

  return <Box sx={STYLES.mainContent}>{children}</Box>
}

MainContent.propTypes = {
  children: node.isRequired,
}

export default MainContent
