import { memo, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { Stack } from 'ui/baseComponents'
import { Button } from 'ui/components/Button'
import { SAVE_BUTTON_STYLES } from './consts'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Report/consts'
import { SUPPORTING_MATERIAL_SECTION_TYPES } from './sections/SupportingMaterial/consts'
import ReportSectionActivity from './sections/Activity/ReportSectionActivity'
import ReportSectionHighlights from './sections/Highlights/ReportSectionHighlights'
import ReportSectionMedication from './sections/Medication/ReportSectionMedication'
import ReportSectionMobility from './sections/Mobility/ReportSectionMobility'
import ReportSectionSupportingMaterial from './sections/SupportingMaterial/ReportSectionSupportingMaterial'
import ReportSectionSymptomLog from './sections/SymptomLog/ReportSectionSymptomLog'
import ReportSectionTremorAndDyskinesia from './sections/TremorAndDyskinesia/ReportSectionTremorAndDyskinesia'
import ReportSectionDIN from 'ui/clinicianScreens/Patient/Report/sections/DIN/ReportSectionDIN'
import ReportHeader from './components/ReportHeader/ReportHeader'
import ReportFooter from './components/ReportFooter'
import { createUniqueId } from 'ui/clinicianScreens/Patient/helpers'
import { getIsDINVariant } from 'ui/clinicianScreens/Patient/Report/helpers'

const ReportClinician = ({ ...dataSectionProps }) => {
  const { saveReport, variant, ...sectionProps } = dataSectionProps
  const isDINVariant = getIsDINVariant(variant)

  const clinicianSectionProps = {
    ...sectionProps,
    isDINVariant,
  }

  const initiateSupportingMaterialSections = () => {
    const newId = createUniqueId()
    return new Map([[newId, '']])
  }
  const [
    supportingMaterialsSectionsForRedFlagsAndOtherReportedIssues,
    setSupportingMaterialsSectionsForRedFlagsAndOtherReportedIssues,
  ] = useState(initiateSupportingMaterialSections())

  const [
    supportingMaterialsSectionsOther,
    setSupportingMaterialsSectionsOther,
  ] = useState(initiateSupportingMaterialSections())

  const updateSupportingMaterialSections = ({
    sectionType,
    updateFunction,
  }) => {
    if (
      sectionType ===
      SUPPORTING_MATERIAL_SECTION_TYPES.redFlagsAndOtherReportedIssues
    ) {
      setSupportingMaterialsSectionsForRedFlagsAndOtherReportedIssues(
        updateFunction,
      )
    } else if (sectionType === SUPPORTING_MATERIAL_SECTION_TYPES.other) {
      setSupportingMaterialsSectionsOther(updateFunction)
    }
  }

  const addSupportingMaterialSection = ({ sectionType }) => {
    updateSupportingMaterialSections({
      sectionType,
      updateFunction: (prevSections) => {
        const newId = createUniqueId()
        const updatedSections = new Map(prevSections)
        updatedSections.set(newId, '')
        return updatedSections
      },
    })
  }

  const removeSupportingMaterialSection = ({ sectionId, sectionType }) => {
    updateSupportingMaterialSections({
      sectionType,
      updateFunction: (prevSections) => {
        const updatedSections = new Map(prevSections)
        updatedSections.delete(sectionId)
        return updatedSections
      },
    })
  }

  const SaveButton = () => (
    <Stack spacing={0} sx={SAVE_BUTTON_STYLES} alignItems="center">
      <Button
        variant="contained"
        color="primary"
        onClick={saveReport}
        startIcon={<DownloadIcon />}
      >
        Download PDF
      </Button>
    </Stack>
  )

  return (
    <>
      <SaveButton />

      <ReportHeader {...clinicianSectionProps} />

      <ReportSectionHighlights {...clinicianSectionProps} />
      <ReportSectionSymptomLog {...clinicianSectionProps} />
      {[
        ...supportingMaterialsSectionsForRedFlagsAndOtherReportedIssues.keys(),
      ].map((sectionId) => (
        <ReportSectionSupportingMaterial
          key={sectionId}
          {...{
            sectionId,
            supportingMaterialsSections:
              supportingMaterialsSectionsForRedFlagsAndOtherReportedIssues,
            sectionType:
              SUPPORTING_MATERIAL_SECTION_TYPES.redFlagsAndOtherReportedIssues,
            title:
              SECTION_TITLES.SUPPORTING_MATERIALS_RED_FLAGS_AND_OTHER_REPORTED_ISSUES,
            onClickAddNewSection: addSupportingMaterialSection,
            onClickRemoveSection: removeSupportingMaterialSection,
          }}
        />
      ))}
      {isDINVariant && <ReportSectionDIN {...clinicianSectionProps} />}

      <ReportSectionMedication {...clinicianSectionProps} />
      <ReportSectionTremorAndDyskinesia {...clinicianSectionProps} />
      <ReportSectionActivity {...clinicianSectionProps} />
      <ReportSectionMobility {...clinicianSectionProps} />
      {[...supportingMaterialsSectionsOther.keys()].map((sectionId) => (
        <ReportSectionSupportingMaterial
          key={sectionId}
          {...{
            sectionId,
            supportingMaterialsSections: supportingMaterialsSectionsOther,
            sectionType: SUPPORTING_MATERIAL_SECTION_TYPES.other,
            title: SECTION_TITLES.SUPPORTING_MATERIALS,
            onClickAddNewSection: addSupportingMaterialSection,
            onClickRemoveSection: removeSupportingMaterialSection,
          }}
        />
      ))}

      <ReportFooter isClinicianReport />
      <SaveButton />
    </>
  )
}

export default memo(ReportClinician)
