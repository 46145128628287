import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { display12HourTime } from 'utilities/time'
import TimePicker from 'react-time-picker'
import {
  RUNE_GRAY_100,
  RUNE_GRAY_200,
  RUNE_GRAY_600,
  RUNE_GRAY_700,
  RUNE_GRAY_900,
} from 'theme/colors'
import { Box, FormControl, FormLabel, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  timeListContainer: {
    maxWidth: 250,
  },
  miniLabel: {
    ...theme.taskNotificationsMiniLabel,
    marginBottom: theme.spacing(1),
  },
  addButton: {
    cursor: 'pointer',
    fontSize: '1rem',
    color: theme.palette.primary.main,
    marginLeft: 'auto',
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: '0.8rem',
    width: '0.8rem',
    borderRadius: '50%',
    padding: '1px',
    position: 'relative',
    top: '1px',
  },
  addTimeButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: '0.8rem',
    width: '0.8rem',
    borderRadius: '50%',
    padding: '1px',
    position: 'relative',
    top: '8px',
    left: '8px',
  },
  cancelButton: {
    cursor: 'pointer',
    fontSize: '1rem',
    color: RUNE_GRAY_700,
    marginLeft: 'auto',
  },
  cancelIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: RUNE_GRAY_700,
    height: '0.8rem',
    width: '0.8rem',
    borderRadius: '50%',
    padding: '1px',
    position: 'relative',
    top: '1px',
  },
  deleteButton: {
    color: 'transparent',
    cursor: 'pointer',
    height: '1.2rem',
    width: '1.2rem',
    marginLeft: 'auto',
  },
  timeInput: {
    maxWidth: '100%',
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.25),
    width: 212,
    '&:after': {
      borderBottom: 'none',
    },
    '& .react-time-picker__wrapper': {
      borderBottom: 'none',
    },
  },
  timeInputContainer: {
    backgroundColor: RUNE_GRAY_200,
  },
  timeAddContainer: {
    backgroundColor: RUNE_GRAY_200,
    width: 30,
  },
  timeList: {
    backgroundColor: RUNE_GRAY_100,
    color: RUNE_GRAY_600,
    fontSize: '1rem',
  },
  timeListItem: {
    backgroundColor: RUNE_GRAY_100,
    color: RUNE_GRAY_900,
    padding: theme.spacing(1),
    display: 'flex',
    '&:hover': {
      backgroundColor: RUNE_GRAY_200,
      color: theme.palette.primary.main,
      '& $deleteButton': {
        color: theme.palette.primary.main,
      },
    },
  },
  placeholderContainer: {
    backgroundColor: RUNE_GRAY_100,
    color: RUNE_GRAY_600,
  },
  placeholderAddIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: RUNE_GRAY_600,
    height: '0.8rem',
    width: '0.8rem',
    borderRadius: '50%',
    padding: '1px',
    position: 'relative',
    top: '1px',
  },
}))

const TimeList = ({ setTime, selectedTimeArray }) => {
  const classes = useStyles()
  const [timeInputVisible, setTimeInputVisible] = useState(false)
  const [pendingTime, setPendingTime] = useState('')
  const onPendingTimeAdd = useCallback(
    (time) => {
      if (!selectedTimeArray.includes(time) && !!time) {
        setTime(time)
        // add the time to the list and then clear the input
        setPendingTime('')
      }
    },
    [selectedTimeArray, setTime, setPendingTime],
  )

  return (
    <Box display="block" className={classes.timeListContainer}>
      {timeInputVisible ? (
        <Box>
          <Box display="flex">
            <FormLabel className={classes.miniLabel}>Schedule</FormLabel>
            <Typography
              className={classes.cancelButton}
              onClick={() => setTimeInputVisible(false)}
            >
              <CloseIcon className={classes.cancelIcon} /> Cancel
            </Typography>
          </Box>

          <FormControl component="fieldset">
            <Box display="flex" className={classes.timeInputContainer}>
              <TimePicker
                id="addTime"
                name="Add Time"
                data-testid="addTime"
                disableClock={true}
                className={classes.timeInput}
                clearIcon={null}
                value={pendingTime}
                onChange={(e) => setPendingTime(e)}
              />
              <Box className={classes.timeAddContainer}>
                <AddIcon
                  className={classes.addTimeButton}
                  onClick={() => onPendingTimeAdd(pendingTime)}
                />
              </Box>
            </Box>
          </FormControl>
        </Box>
      ) : (
        <Box display="flex">
          <FormLabel className={classes.miniLabel}>Schedule</FormLabel>
          <Typography
            className={classes.addButton}
            onClick={() => setTimeInputVisible(true)}
          >
            <AddIcon className={classes.addIcon} /> Add
          </Typography>
        </Box>
      )}

      <Box className={classes.timeList}>
        {selectedTimeArray.length ? (
          selectedTimeArray.map((time, i) => (
            <div className={classes.timeListItem} key={time + i}>
              {display12HourTime(time)}
              <DeleteIcon
                onClick={() => setTime(time)}
                className={classes.deleteButton}
              />
            </div>
          ))
        ) : (
          <Box className={classes.placeholderContainer} p={2}>
            <Typography>
              No time is added yet. <br /> Use{' '}
              <AddIcon className={classes.placeholderAddIcon} /> Add to add a
              time.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

TimeList.propTypes = {
  setTime: PropTypes.func.isRequired,
  selectedTimeArray: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TimeList
