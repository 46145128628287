export const TASK_NOTIFICATIONS_ROUTE = '/patients/task-notifications'
export const TASK_RECURRENCE_ONCE = 'once'
export const TASK_RECURRENCE_RECURRING = 'recurring'
export const TASK_ACTION_NONE = 'none'
export const TASK_ACTION_EXTERNAL_LINK = 'externalLink'
export const TASK_REPEATS_ON = 'true'
export const TASK_REPEATS_EVERY = 'false'
export const TASK_SCHEDULE_RANDOM = 'true'
export const TASK_SCHEDULE_SPECIFIC = 'false'
export const DAYS_IN_WEEK = 7
