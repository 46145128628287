import { useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as MUI from '@mui/material'
import { Chip, Stack, Text } from 'ui/baseComponents'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import moment from 'moment'
import { DATE_FORMAT } from 'ui/consts'
import { DateField, DatePicker } from '@mui/x-date-pickers'
import { colors } from 'theme/colors'

const STYLES = {
  endInput: {
    '& fieldset': { border: `2px solid ${colors.PRIMARY[1000]}` },
  },
}

const WeeklyViewDatePicker = () => {
  const smallScreens = MUI.useMediaQuery('(max-width:900px)')
  const { dateRanges, setDateRange, selectedTimezone } =
    useContext(DateRangeContext)

  const SELECTABLE_DATE_COUNT = 56 // 8 weeks
  const MINIMUM_NIGHTS = SELECTABLE_DATE_COUNT - 1

  const [, setSearchParams] = useSearchParams()

  const periodInitialState = useMemo(
    () => dateRanges[DATE_RANGE.weeklyViewPeriod.key] || {},
    [dateRanges],
  )

  const [periodRange, setPeriodRange] = useState(periodInitialState)

  useEffect(() => {
    setPeriodRange(periodInitialState)
  }, [periodInitialState])

  const handleEndDateChange = (value) => {
    const end = value.format(DATE_FORMAT)
    const start = moment(value)
      .subtract(MINIMUM_NIGHTS, 'days')
      .format(DATE_FORMAT)

    setPeriodRange({ start, end })
    setDateRange(start, end, DATE_RANGE.weeklyViewPeriod.key)

    const newSearchParams = {
      startTime: start,
      endTime: end,
    }
    setSearchParams(newSearchParams, {
      replace: true,
    })
  }

  const CustomDateShortcuts = (props) => {
    const { items, onChange, isValid, changeImportance = 'accept' } = props

    if (items == null || items.length === 0) {
      return null
    }

    const resolvedItems = items.map((item) => {
      const newValue = item.getValue({ isValid })

      return {
        label: item.label,
        onClick: () => {
          onChange(newValue, changeImportance, item)
        },
        disabled:
          !isValid(newValue) ||
          moment(newValue).format(DATE_FORMAT) === periodRange.end,
      }
    })

    return (
      <MUI.Box
        sx={{
          gridRow: 3,
          gridColumn: 2,
        }}
      >
        <Stack direction="row" gap={0.5} sx={{ padding: '0 1.5rem 1rem' }}>
          {resolvedItems.map((item) => {
            const { label, disabled, onClick } = item
            return (
              <Chip
                {...{
                  key: label,
                  label,
                  disabled,
                  onClick,
                  color: 'primary',
                  size: 'medium',
                }}
              />
            )
          })}
        </Stack>
      </MUI.Box>
    )
  }

  return (
    <Stack
      direction={smallScreens ? 'column' : 'row'}
      spacing={4}
      alignItems="center"
    >
      <Text component="p" variant="body16">
        Select the end date for an 8 week period:
      </Text>
      <Stack direction="row">
        <DateField
          label="Start date"
          readOnly
          value={moment(periodRange.start)}
          disabled
        />
        <DatePicker
          label="End date"
          value={moment(periodRange.end)}
          disableFuture
          onChange={handleEndDateChange}
          timezone={selectedTimezone}
          showDaysOutsideCurrentMonth
          sx={STYLES.endInput}
          slots={{
            shortcuts: CustomDateShortcuts,
          }}
          slotProps={{
            shortcuts: {
              items: [
                {
                  label: 'Today',
                  getValue: () => {
                    const today = moment()
                    return today
                  },
                },
                {
                  label: `Recent report period`,
                  getValue: () => {
                    const reportPeriodEnd = moment(
                      dateRanges[DATE_RANGE.reportPeriod2.key]?.end,
                    )
                    return reportPeriodEnd
                  },
                },
              ],
            },
          }}
        />
      </Stack>
    </Stack>
  )
}

export default WeeklyViewDatePicker
