import { useContext } from 'react'
import { connect } from 'react-redux'
import CopyTextIcon from 'ui/components/CopyText/CopyTextIcon'
import TimeBasisDateRangePicker from 'ui/components/DateRangePicker/TimeBasisDateRangePicker'
import ExplorerTabToggle from './ExplorerTabToggle/ExplorerTabToggle'
import MonthPicker from 'ui/components/MonthPicker'
import { eventsStreamDevice } from '../EventsAvailability/EventsStreamDevice'
import ExplorerStreamSelector from '../ExplorerStreamSelector'
import PatientSelect from 'ui/components/PatientSelect'
import { pushQuery } from 'query'
import { Box, InputLabel, makeStyles, Paper } from '@material-ui/core'
import DateRangePickerWrapper from 'ui/components/DateRangePicker/DateRangePickerWrapper'
import { UserPHIContext } from 'ui/contexts'
import { clinicianRoutes } from 'ui/clinicianScreens/Patient/Tabs/consts'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .Mui-focused': {
      backgroundColor: 'inherit',
    },
    padding: theme.spacing(3),
  },
  dateRangePicker: {
    float: 'right',
  },
  browseControls: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  controlsPaper: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(3),
    minWidth: '480px',
    width: 'min-content',
    flexGrow: 1,
    '@media (max-width: 1358px)': {
      marginRight: 0,
    },
  },
  streamsPaper: {
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    flexGrow: 1,
    minWidth: '800px',
  },
  streamsTitle: {
    display: 'inline-block',
    padding: theme.spacing(3),
  },
  dateRangePickerContainer: {
    paddingTop: theme.spacing(3),
    float: 'right',
  },
}))

/**
 * The controls section of the patient data explorer, allowing devices, date
 * ranges and the patient themselves to be selected.
 * @returns {JSX.Element} Components for Explorer view.
 */
export const ExplorerFilters = ({
  dateStart,
  dateEnd,
  monthlyView,
  selectedDeviceIds,
  setDateRange,
  setSelectedDeviceIds,
  replaceSelectedDeviceIds,
  patient,
  pushQuery,
  devices,
}) => {
  const phiVisibility = useContext(UserPHIContext)

  const classes = useStyles()

  const possibleStreamOptions = [
    ...patient.deviceList.devices,
    eventsStreamDevice,
  ]

  const NOT_FOUND_INDEX = -1
  // Remove selected streams from options
  const streamOptions = possibleStreamOptions.filter(function (e) {
    return this.indexOf(e.id) === NOT_FOUND_INDEX
  }, selectedDeviceIds)

  /**
   * Function that dispatches the user to the explorer view of the selected patient.
   *
   * @param {event} event The event created from selecting a new patient
   * @returns {void}
   */
  const onPatientChange = (event) => {
    const newPatientId = event.target.value

    if (!newPatientId || newPatientId === patient.id) {
      return
    }

    pushQuery(
      {
        from: dateStart,
        to: dateEnd,
      },
      {
        url: phiVisibility
          ? `/patients/${newPatientId}/${clinicianRoutes.SUMMARY}`
          : `/patients/${newPatientId}`,
        replaceQuery: true,
      },
    )
  }

  return (
    <Box className={classes.browseControls} data-cy="ExplorerFilters">
      <Paper className={classes.controlsPaper}>
        <PatientSelect patient={patient} onPatientChange={onPatientChange} />
        <Box mb={1} ml={2}>
          <InputLabel shrink={true}>Patient ID</InputLabel>
          <CopyTextIcon value={patient.id} />
        </Box>
      </Paper>
      <Paper className={classes.streamsPaper}>
        <Box>
          <ExplorerTabToggle
            monthlyView={monthlyView}
            dateStart={dateStart}
            dateEnd={dateEnd}
          />
          {monthlyView ? (
            <MonthPicker
              className={classes.dateRangePicker}
              from={dateStart}
              to={dateEnd}
              setDateRange={setDateRange}
            />
          ) : (
            <div className={classes.dateRangePickerContainer}>
              <DateRangePickerWrapper
                onClick={(e) => e.preventDefault()}
                className={classes.dateRangePicker}
                from={dateStart}
                to={dateEnd}
                setDateRange={setDateRange}
              >
                <TimeBasisDateRangePicker />
              </DateRangePickerWrapper>
            </div>
          )}
        </Box>

        <ExplorerStreamSelector
          deviceOptions={streamOptions.map((device) => ({
            value: device.id,
            name: device.alias,
          }))}
          setSelected={setSelectedDeviceIds}
          replaceSelected={replaceSelectedDeviceIds}
          selectedDevices={devices}
        />
      </Paper>
    </Box>
  )
}

export default connect((state, props) => props, { pushQuery })(ExplorerFilters)
