import { useContext } from 'react'

import { useStreamApiBatchWithPartialDataHandling } from 'domains/streamApi'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { PatientContext } from 'ui/contexts'
import { createDailyAggregateRequests } from './apiDataHelpers'
import { AGGREGATE_WINDOW_METRIC_TYPES } from 'ui/hooks/consts'

export const METRICS = [
  { id: AGGREGATE_WINDOW_METRIC_TYPES.walkingSpeed, name: 'walkingSpeed' },
  { id: AGGREGATE_WINDOW_METRIC_TYPES.stepLength, name: 'stepLength' },
  {
    id: AGGREGATE_WINDOW_METRIC_TYPES.doubleSupport,
    name: 'doubleSupportPercentage',
  },
]

const ZERO_DAYS_OF_DATA = 0
const INDEX_CONTAINING_METRIC_UNIT = 1

/*
 *
 * @typedef {Object} AggregateData
 * @property {number} numberOfDaysWithData - the number of days with data for the metric
 * @property {Object} mean - the mean value for the metric
 * @property {Object} min - the min value for the metric
 * @property {Object} max - the max value for the metric
 * @property {[]string} xLabels - an array of strings representing the dates for the metric
 * @property {[]Object} averaged - an array of objects with the following properties:
 * @property {string} averaged[].unit - the unit of the value
 * @property {number} averaged[].value - the value
 */

/**
 * Maps the mobility aggregate data from the API to the format expected by the frontend.
 * @param {array} data mobility aggregate data from the API
 * @param {boolean} allErrored, indicates whether all of the individual metric requests errored
 * @returns {object} Mobility aggregate data formatted for the frontend
 */
const mapMobilityAggregateData = (data, allErrored) => {
  // The !data.length && !allErrored condition accounts for the very rare circumstance where the useStreamApiBatchWithPartialDataHandling hook returns an empty array for data or a patient does not have a device
  if (allErrored || (!data.length && !allErrored)) {
    return {}
  }

  // Get the highest available summary.n_days_with_data_total from the metrics
  const daysWithData =
    Math.max(
      ...data.map(
        ({ metric }) =>
          metric?.summary?.n_days_with_data_total || ZERO_DAYS_OF_DATA,
      ),
      ZERO_DAYS_OF_DATA,
    ) || ZERO_DAYS_OF_DATA

  /**
   * Rounds a number to the number of decimal places passed specified by the decimalPlaces param
   * @param {number} num The number to round
   * @param {number} decimalPlaces The number of decimal places to round it to
   * @returns {number} The rounded number
   */
  const roundNumber = (num, decimalPlaces) =>
    // the rounding logic comes from this StackOverflow thread: https://stackoverflow.com/a/18358056
    +`${Math.round(`${num}e+${decimalPlaces}`)}e-${decimalPlaces}`

  const mappedData = { numberOfDaysWithData: daysWithData }

  METRICS.forEach(({ id: metricId, name: metricName }) => {
    const matchingDataItem = data.find(
      ({ description }) => description.id === metricId,
    )

    if (typeof matchingDataItem === 'undefined') {
      mappedData[metricName] = {
        averageValue: null,
        averageUnit: null,
        hasError: true,
      }
    } else {
      const { metric: dataItemMetric, description: dataItemDescription } =
        matchingDataItem
      const meanValue = dataItemMetric?.summary?.value_mean
      const percentageValue = 100

      const NUMBER_OF_DECIMAL_PLACES = { one: 1, two: 2 }

      const formatMeanValue = (metricName, meanValue) =>
        metricName === 'doubleSupportPercentage'
          ? roundNumber(
              meanValue * percentageValue,
              NUMBER_OF_DECIMAL_PLACES.one,
            )
          : roundNumber(meanValue, NUMBER_OF_DECIMAL_PLACES.two)

      mappedData[metricName] = {
        averageValue: meanValue ? formatMeanValue(metricName, meanValue) : null,
        averageUnit: dataItemDescription?.dimensions
          ? dataItemDescription.dimensions[INDEX_CONTAINING_METRIC_UNIT]
              .unit_abbrev
          : null,
        trend: dataItemMetric?.trend?.direction,
        hasError: false,
      }
    }
  })

  return mappedData
}

const useMobilityAggregate = (startDate, endDate) => {
  const patientContext = useContext(PatientContext) || {}
  const { selectedTimezone } = useContext(DateRangeContext)

  const requests = createDailyAggregateRequests({
    metrics: METRICS,
    startDate,
    endDate,
    patientId: patientContext.id,
    selectedTimezone,
  })

  const {
    data: streamData = [],
    errors,
    loading,
  } = useStreamApiBatchWithPartialDataHandling(requests, selectedTimezone)

  const allErrored = errors?.length === requests.length
  const formattedData = mapMobilityAggregateData(streamData, allErrored)

  return {
    data: formattedData,
    hasData:
      !!streamData?.length &&
      formattedData.numberOfDaysWithData > ZERO_DAYS_OF_DATA,
    isLoading: loading,
    allErrored: allErrored,
  }
}

export default useMobilityAggregate
