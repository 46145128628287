import { gql } from '@apollo/client'

export const UPDATE_PATIENT = gql`
  mutation updatePatient($input: UpdatePatientInput!) {
    updatePatient(input: $input) {
      patient {
        id
      }
    }
  }
`
