import { useState } from 'react'
import { Button, Popover } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'

const ButtonWithPopover = ({ label, children }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined // eslint-disable-line no-undefined

  return (
    <>
      <Button color="primary" endIcon={<ArrowDropDown />} onClick={handleClick}>
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {children}
      </Popover>
    </>
  )
}

export default ButtonWithPopover
