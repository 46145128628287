import { createContext, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import streamsReducer, { initialState } from './reducer'
import { REPLACE_STREAMS } from './actions'
import { makeQuerySelector, pushQuery } from '../../../../../../query'

const streamsSelector = makeQuerySelector(
  'streams',
  JSON.stringify(initialState),
)
export const StreamsContext = createContext()

/**
 * Provider for an array of Device Streams. Exposes a `streams` and `dispatch`
 * to allow mutation of the Streams state, and manages two-way syncing between
 * browser URL and the streams state
 * @param children
 * @return {*}
 * @constructor
 */
export const StreamsProvider = ({ children }) => {
  const reduxDispatch = useDispatch()
  // Manage a two-way sync between the streams context state, and the streams query param
  // Use JSON serialization to make sure that we aren't using object equality to trigger these
  // two-way updates in our useEffect()s, which would otherwise cause an infinite loop
  const streamsParamString = useSelector(streamsSelector)
  const [streams, dispatch] = useReducer(
    streamsReducer,
    JSON.parse(streamsParamString),
  )
  const streamsStateString = JSON.stringify(streams)
  // When the streams state changes, update the query param
  useEffect(
    () => {
      // The way these dueling useEffects work, updating state triggers a change to params, and vice-versa
      // In order to avoid duplicating our pushQuery calls, we simply check whether the values actually differ
      // If they don't, it's because we did something like this:
      // 1. Browser back button -> streamsParam changes, triggering a REPLACE_STREAMS in the useEffect below
      // 2. streamsStateString changes, triggering this useEffect
      // 3. But the streamsStateString is identical to the streamsParamString, cuz it was a downstream effect
      //    of the original Browser back button press in step 1. So we don't need to do anything.
      if (streamsParamString !== streamsStateString) {
        reduxDispatch(pushQuery({ streams: streamsStateString }))
      }
    },
    // eslint-disable-next-line
    [reduxDispatch, streamsStateString],
  )

  // When the query param changes, update the streams state
  useEffect(
    () => {
      const streams = JSON.parse(streamsParamString)
      if (streamsParamString !== streamsStateString) {
        dispatch({ type: REPLACE_STREAMS, streams })
      }
    },
    // eslint-disable-next-line
    [dispatch, streamsParamString],
  )

  return (
    <StreamsContext.Provider value={{ streams, dispatch }}>
      {children}
    </StreamsContext.Provider>
  )
}

export default StreamsContext
