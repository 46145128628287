import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import comparisonModeImg from 'assets/img/comparisonMode.png'

const useStyles = makeStyles((theme) => ({
  box: {
    margin: theme.spacing(2),
  },
  img: {
    width: 500,
  },
}))

const ComparisonModeTooltipContent = () => {
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="button">What is Comparison Mode?</Typography>
        </Grid>
        <Grid item>
          <Typography>
            Comparison Mode allows you to compare patient data from two 2-week
            time periods (Period 1 and Period 2). The patient's summary Symptom
            (Tremor/Dyskinesia), Mobility and Sleep data is viewable
            side-by-side.
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={comparisonModeImg}
            alt="Comparison Mode visualization of periods"
            className={classes.img}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

ComparisonModeTooltipContent.propTypes = {}

ComparisonModeTooltipContent.defaultProps = {}

export default ComparisonModeTooltipContent
