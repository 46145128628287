import {
  TASK_RECURRENCE_ONCE,
  TASK_RECURRENCE_RECURRING,
} from 'ui/screens/Patients/TaskNotifications/consts.js'
import { isEmpty } from 'utilities'
export const CALENDAR_CLOCK_SCHEDULE = 'CALENDAR_CLOCK_SCHEDULE'
export const INTERVAL_CLOCK_SCHEDULE = 'INTERVAL_CLOCK_SCHEDULE'
const NO_PATIENTS = 0
const NO_DAYS = 0

export const assignPatients = (assignmentList) => {
  const patients = assignmentList?.assignments
  if (patients && patients.length > NO_PATIENTS) {
    return patients.map((patient) => patient.id.split('patient-v0$')[1])
  }
  return []
}

export const determineRecurrence = (schedule) => {
  if (!schedule || isEmpty(schedule)) {
    return TASK_RECURRENCE_ONCE
  }
  if (
    schedule.scheduleType === CALENDAR_CLOCK_SCHEDULE &&
    (!schedule.daysOfWeek || schedule.daysOfWeek.length === NO_DAYS)
  ) {
    return TASK_RECURRENCE_ONCE
  }

  return TASK_RECURRENCE_RECURRING
}
