import { useContext } from 'react'
import RuneLabsLogo from 'assets/img/logo.svg'
import RuneLabsLogoWhite from 'assets/img/rune-logo-white.svg'
import { Icon, makeStyles, Typography } from '@material-ui/core'
import { UserPHIContext } from 'ui/contexts'
import { RUNE_WHITE } from 'theme/colors'

const useStyles = makeStyles((theme) => ({
  imageIcon: {
    height: `${theme.imageIcon.height}`,
    marginTop: `${theme.imageIcon.marginTop}`,
  },
  iconRoot: {
    textAlign: 'left',
    height: `${theme.iconRoot.height}`,
    width: `${theme.iconRoot.width}`,
  },
  iconText: {
    color: RUNE_WHITE,
    textAlign: 'left',
    padding: '0.5em 0',
    fontVariant: 'small-caps',
  },
}))

const RuneLogoIcon = () => {
  const phiVisibility = useContext(UserPHIContext)
  const classes = useStyles()

  return (
    <>
      <Typography classes={{ root: classes.iconText }}>powered by</Typography>
      <Icon classes={{ root: classes.iconRoot }}>
        <img
          className={classes.imageIcon}
          src={phiVisibility ? RuneLabsLogoWhite : RuneLabsLogo}
          alt="Rune logo"
        />
      </Icon>
    </>
  )
}
export default RuneLogoIcon
