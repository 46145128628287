import React from 'react'
import { SECOND_IN_MILLISECONDS, formatMomentToString } from 'utilities/time'
import { isShowLoading } from 'ui/screens/Patient/Browse/StreamResults/StreamResult'
import moment from 'moment'
import Plot from 'ui/components/Plot'
import StreamVisualization from './StreamVisualization'
import { useStreamApi } from 'domains/streamApi'
import { useDownloadOptions } from './downloadOptions'

/**
 * Line plot indicating the probability that a symptom or other condition has
 * manifested on the patient.
 *
 * Symptom probability data is available at regular intervals, and points
 * represent start times of those intervals. By always querying at least 60s
 * of data from end, we ensure we don't end up *between* the start points of
 * two intervals where it would incorrectly appear as if there's no data.
 * @returns {React.Component} ProbSymptom component
 */
const ProbSymptom = ({
  device,
  patient,
  query,
  title,
  tStart,
  tEnd,
  updateTimeRange,
}) => {
  const SECONDS_IN_MINUTE_FLOAT = 60.0
  const { symptom } = query
  const { data, error, showLoading, revision } = useStreamApi({
    url: 'probability_symptom.json',
    params: {
      ...query,
      device_id: device.id,
      patient_id: patient.id,
      start_time: tStart - SECONDS_IN_MINUTE_FLOAT,
      end_time: tEnd + SECONDS_IN_MINUTE_FLOAT,
      expression: 'probability',
      resolution: null, // @note: downsampling probabilities is ambiguous,
      frequency: null, // disabling for now
    },
    isShowLoading,
  })
  // @note: By always querying +/- 60s around range, we ensure we don't end up
  // *between* the start points of two intervals, where visually it would
  // incorrectly appear as if there's no data.

  const downloadOptions = useDownloadOptions({
    streamName: symptom,
    endpointName: 'probability_symptom',
    patientID: patient.id,
    deviceID: device.id,
    startTime: tStart,
    endTime: tEnd,
    query: {
      ...query,
      expression: 'probability',
      timestamp: 'unix',
    },
  })

  return (
    <StreamVisualization
      device={device}
      downloadOptions={downloadOptions}
      error={error}
      showLoading={showLoading}
      patient={patient}
      title={title}
      tStart={tStart}
      tEnd={tEnd}
      updateTimeRange={updateTimeRange}
    >
      {(defaults) => (
        <Plot
          className={defaults.className}
          config={defaults.config}
          data={[
            {
              fill: 'tozeroy',
              hovertemplate: `
                <b>%{x}</b>
                <br>
                <b>%{y} percentage</b>
              `,
              line: {
                shape: 'hv',
                width: 2,
              },
              mode: 'lines',
              type: 'scatter',
              visible: true,
              x: data.result.time,
              y: data.result.probability,
            },
          ]}
          layout={{
            ...defaults.layout,
            height: 260,
            showlegend: false,
            xaxis: {
              ...defaults.layout.xaxis,
              range: [
                formatMomentToString(
                  'Y-MM-DD HH:mm:ss.SSS',
                  moment(tStart * SECOND_IN_MILLISECONDS),
                ),
                formatMomentToString(
                  'Y-MM-DD HH:mm:ss.SSS',
                  moment(tEnd * SECOND_IN_MILLISECONDS),
                ),
              ],
            },
            yaxis: {
              ...defaults.layout.yaxis,
              fixedrange: true,
              range: [0, 1],
              tickfont: {
                size: 11,
              },
              tickformat: ',.1%',
              title: 'Percentage',
            },
          }}
          onClick={defaults.onClick}
          onInitialized={defaults.onInitialized}
          onRelayout={defaults.onRelayout}
          revision={revision}
          useResizeHandler={defaults.useResizeHandler}
        />
      )}
    </StreamVisualization>
  )
}

export default ProbSymptom
