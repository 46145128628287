import { useContext } from 'react'
import FormFieldWrapper from 'ui/components/FormFieldWrapper'
import { TaskNotificationContext } from 'ui/contexts'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { TextField } from '@material-ui/core'

/**
 * The modal for creating a Task Notification.
 */
export const TaskCopy = () => {
  const {
    taskTitle,
    onTaskTitleChange,
    taskSummary,
    onTaskSummaryChange,
    taskDescription,
    onTaskDescriptionChange,
  } = useContext(TaskNotificationContext)

  const characterCountTemplate = (characterCount, max) =>
    `${characterCount}/${max} characters`

  return (
    <ErrorBoundary>
      <FormFieldWrapper
        label="Task Title"
        helperText={characterCountTemplate(taskTitle.length, 27)}
      >
        <TextField
          id="taskTitle"
          data-testid="taskTitle"
          value={taskTitle}
          placeholder="Type here"
          onChange={(event) =>
            onTaskTitleChange({
              type: 'setTaskTitle',
              field: event.target.id,
              payload: event.target?.value,
            })
          }
          inputProps={{ maxLength: 27, 'aria-label': 'Task Title Field' }}
          autoFocus
        />
      </FormFieldWrapper>
      <FormFieldWrapper
        label="Task Summary"
        helperText={characterCountTemplate(taskSummary.length, 178)}
      >
        <TextField
          id="taskSummary"
          data-testid="taskSummary"
          value={taskSummary}
          placeholder="Type here"
          onChange={(event) =>
            onTaskSummaryChange({
              type: 'setTaskSummary',
              field: event.target.id,
              payload: event.target?.value,
            })
          }
          multiline
          inputProps={{ maxLength: 178, 'aria-label': 'Task Summary Field' }}
        />
      </FormFieldWrapper>
      <FormFieldWrapper
        label="Task Description"
        helperText={characterCountTemplate(taskDescription.length, 1000)}
      >
        <TextField
          id="taskDescription"
          data-testid="taskDescription"
          value={taskDescription}
          placeholder="Type here"
          onChange={(event) =>
            onTaskDescriptionChange({
              type: 'setTaskDescription',
              field: event.target.id,
              payload: event.target?.value,
            })
          }
          multiline
          inputProps={{
            maxLength: 1000,
            'aria-label': 'Task Description Field',
          }}
        />
      </FormFieldWrapper>
    </ErrorBoundary>
  )
}

export default TaskCopy
