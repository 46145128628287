import React from 'react'
import PropTypes from 'prop-types'
import { translate as t } from '../translate.js'

import Button from './Button'

const GoToButton = ({ label, ...props }) => {
  return (
    <Button size="small" variant="text" color="primary" {...props}>
      <span>{label || t('general.view')} ›</span>
    </Button>
  )
}

GoToButton.propTypes = {
  label: PropTypes.string,
}
GoToButton.defaultProps = {
  label: null,
}

export default GoToButton
