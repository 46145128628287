import { gql } from '@apollo/client'

export const GET_ME_QUERY = gql`
  query getMe($cursor: Cursor) {
    user {
      id
      displayName
      username
      email
      created
      analyticsTracking
      defaultMembership {
        id
        role {
          admin
          canSeePHI
          displayName
        }
        org {
          id
          displayName
          patientCount
        }
        admin
      }
      membershipList(cursor: $cursor, limit: 50) {
        memberships {
          id
          org {
            id
            displayName
          }
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
`
