import { Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

const GridItemList = ({ elements, spacing, sx }) => {
  if (!elements.length) {
    return null
  }
  return (
    <Grid item container alignItems="center" spacing={spacing}>
      {elements.map((component, index) => (
        <Grid item key={`item-${index}`} data-cy="grid-item" sx={sx}>
          {component}
        </Grid>
      ))}
    </Grid>
  )
}

GridItemList.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.element),
  spacing: PropTypes.number,
  sx: PropTypes.object,
}

GridItemList.defaultProps = {
  elements: [],
  spacing: 0,
}

export default GridItemList
