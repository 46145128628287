import { useLocation } from 'react-router-dom'
import RuneLogoIcon from '../RuneLogoIcon/RuneLogoIcon'
import NavLink from '../NavLink'
import Box from '@material-ui/core/Box'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { Z_INDEX_APP_FOOTER } from 'theme/zIndexRegistry'
import { STRIVEPD_DARK_BLUE } from 'theme/colors'
import { PrivacyNoticeLink } from 'ui/components/PrivacyNoticeLink'
import { CLINICIAN_ROUTES } from 'app/AppRoutes/consts'

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: STRIVEPD_DARK_BLUE,
    width: '100%',
    bottom: '0',
    minHeight: '200px',
    padding: '25px',
    justifyContent: 'space-between',
    zIndex: Z_INDEX_APP_FOOTER,
    color: theme.palette.primary.contrastText,
    '@media print': {
      display: 'none',
    },
  },
  footerContent: {
    width: '85%',
    margin: 'auto',
  },
  footerText: {
    marginRight: '5%',
    marginTop: '35px',
    fontFamily: `${theme.typography.fontFamily}`,
    textTransform: 'uppercase',
    letterSpacing: '.2em',
  },
  footerTextBox: {
    justifyContent: 'right',
  },
  footerLink: {
    color: theme.palette.primary.contrastText,
  },
  copyrightText: {
    lineHeight: 3,
  },
}))

/**
 * The content of the footer
 * @return {*}
 * @constructor
 */

const AppFooter = () => {
  const classes = useStyles()
  const { pathname } = useLocation()

  const hideFooter = pathname.includes(
    CLINICIAN_ROUTES.reportPatientPDFTest.path,
  )

  if (hideFooter) {
    return null
  }

  return (
    <Box className={classes.footer}>
      <Grid container className={classes.footerContent}>
        <Grid item xs={6}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Go to patients list"
          >
            <NavLink to="/patients" underline="none">
              <RuneLogoIcon />
            </NavLink>
          </IconButton>
        </Grid>
        <Grid container item xs={6} className={classes.footerTextBox}>
          <Box className={classes.footerText}>
            <div className={classes.copyrightText}>
              Copyright &#169; Rune Labs. All rights reserved.
            </div>
            <Link
              className={classes.footerLink}
              href="//www.runelabs.io/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </Link>
            &nbsp; &nbsp; &nbsp;
            <PrivacyNoticeLink color="inherit" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AppFooter
