import React from 'react'
import { arrayOf, bool, number, oneOfType, string } from 'prop-types'
import LegendNoDataIndicator from '../LegendNoDataIndicator'
import LegendTitle from '../LegendTitle'
import LegendWrapper from '../LegendWrapper'
import DefaultScale from '../DefaultScale'

const LegendDefault = ({
  title,
  minText,
  maxText,
  colors,
  showNoDataIndicator,
}) => (
  <LegendWrapper>
    <LegendTitle>{title}</LegendTitle>
    <DefaultScale
      ariaLabel={`${title} Legend Scale`}
      colors={colors}
      minText={minText}
      maxText={maxText}
    />

    {showNoDataIndicator && <LegendNoDataIndicator />}
  </LegendWrapper>
)

LegendDefault.propTypes = {
  title: string,
  minText: oneOfType([string, number]).isRequired,
  maxText: oneOfType([string, number]).isRequired,
  colors: arrayOf(arrayOf(string)).isRequired,
  showNoDataIndicator: bool,
}

LegendDefault.defaultProps = {
  title: '',
  showNoDataIndicator: false,
}

export default LegendDefault
