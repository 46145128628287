import { patientAccess } from './patients'
import { memberships } from './users'

export const org = {
  id: 'abc123',
  displayName: 'Acme, Inc',
  patientAccessList: {
    pageInfo: {
      endCursor: null,
    },
    patientAccess,
  },
  membershipList: {
    pageInfo: {
      // endCursor: 'abc123cursor',
    },
    memberships,
  },
}
