import { useCallback } from 'react'
import AccessTokenRow from './AccessTokenRow'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core'
import { Waypoint } from 'react-waypoint'
import Loading from 'ui/components/Loading'
import { get } from 'nested-property'

const useStyles = makeStyles((theme) => ({
  tokenPaper: {
    width: '100%',
  },
  dummyCellLarge: {
    width: '42%',
  },
  dummyCellMedium: {
    width: '18%',
  },
  dummyCellSmall: {
    width: '12%',
  },
}))

const getTokens = function (data) {
  return get(data, 'user.accessTokenList.accessTokens')
}

const getCursor = function (data) {
  return get(data, 'user.accessTokenList.pageInfo.endCursor')
}

/**
 * Component for displaying a single row of access token info.
 * Has a switch to toggle enabled state
 *
 * @param {object} data the data for the user, which includes the accessTokenList
 * @param {function} fetchMore the function to append to the user query
 * @param {boolean} listLoading boolean representing the loading status of the user query
 * @param {boolean} tokenLimitReached true when 4 tokens are enabled, used to disable activation of additional tokens
 */
const AccessTokensTable = ({
  data,
  fetchMore,
  listLoading,
  tokenLimitReached,
}) => {
  const classes = useStyles()
  const tokens = getTokens(data)
  const cursor = getCursor(data)

  /**
   * Function that fetches and appends tokens to the list
   */
  const loadMore = useCallback(
    () =>
      fetchMore({
        variables: { cursor },
        updateQuery(previousResult, { fetchMoreResult }) {
          const prevAccessTokens =
            previousResult.user.accessTokenList.accessTokens || []
          const newAccessTokens =
            fetchMoreResult.user.accessTokenList.accessTokens || []

          // uses the spread operator to inject new entries
          return {
            ...fetchMoreResult,
            user: {
              ...(fetchMoreResult.user || {}),
              accessTokenList: {
                ...(fetchMoreResult.user.accessTokenList || {}),
                accessTokens: [...prevAccessTokens, ...newAccessTokens],
              },
            },
          }
        },
      }),
    [cursor, fetchMore],
  )

  // loading skeleton for the table
  if (!data && listLoading) {
    return (
      <Box m={3}>
        <Paper className={classes.tokenPaper}>
          <Table>
            <TableBody>
              {[1, 2, 3, 4].map((width, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.dummyCellLarge}>
                    <Skeleton width="100%" />
                  </TableCell>
                  <TableCell className={classes.dummyCellMedium}>
                    <Skeleton width="100%" />
                  </TableCell>
                  <TableCell className={classes.dummyCellMedium}>
                    <Skeleton width="100%" />
                  </TableCell>
                  <TableCell className={classes.dummyCellSmall}>
                    <Skeleton width="100%" />
                  </TableCell>
                  <TableCell />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    )
  }

  // empty state for the table
  if (!tokens.length) {
    return (
      <ErrorBoundary>
        <Box m={3} display="flex" justifyContent="center">
          <Box p={3} style={{ textAlign: 'center' }}>
            <Typography>
              Use personal <strong>Access Tokens</strong> to authenticate with
              all Rune APIs and SDKs.
            </Typography>
          </Box>
        </Box>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary>
      <Box m={3} display="flex" justifyContent="center">
        {tokens && (
          <Paper className={classes.tokenPaper}>
            <Table aria-label="access token table" data-cy="AccessTokensTable">
              <TableHead>
                <TableRow>
                  <TableCell>Access Token ID</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Last Used</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {tokens.map((token, index) => (
                  <ErrorBoundary key={index}>
                    <AccessTokenRow
                      {...token}
                      tokenLimitReached={tokenLimitReached}
                    />
                  </ErrorBoundary>
                ))}
                {cursor && (
                  <Waypoint onEnter={loadMore}>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Loading />
                      </TableCell>
                    </TableRow>
                  </Waypoint>
                )}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Box>
    </ErrorBoundary>
  )
}

export default AccessTokensTable
