import { useCallback } from 'react'
import FormControl from '../FormControl'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'

const SingleFilter = ({
  label,
  selected,
  setSelected,
  options = [],
  renderOptions = (options) =>
    options.map((value, i) => {
      return typeof value === 'object' ? (
        <MenuItem key={i} value={value.value}>
          {value.name}
        </MenuItem>
      ) : (
        <MenuItem key={i} value={value}>
          {value}
        </MenuItem>
      )
    }),
  ...rest
}) => {
  const callback = useCallback(
    (e) => {
      const options = e.target.value || []
      setSelected(options)
    },
    [setSelected],
  )
  // Select doesn't like an empty "value", so we set it to empty string
  const value = typeof selected === 'undefined' ? '' : selected
  return (
    <FormControl>
      <InputLabel htmlFor={label.toLowerCase()}>{label}</InputLabel>
      <Select
        value={value}
        onChange={callback}
        input={<Input id={label.toLowerCase()} />}
        {...rest}
      >
        {renderOptions(options, value)}
      </Select>
    </FormControl>
  )
}

SingleFilter.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
  options: PropTypes.array,
  renderOptions: PropTypes.func,
}

export default SingleFilter
