import React from 'react'
import { Box, Button } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

/**
 * Component for shift forward and back buttons
 *
 * @param {function} updateTimeRange function that updates the time range for all streams
 * @param {int} tStart The initial start time
 * @param {int} tEnd The initial end time
 * @param {boolean} eventsStream boolean that toggles margin on left button
 */
const ShiftButtons = ({
  updateTimeRange,
  tStart,
  tEnd,
  eventsStream = false,
}) => {
  /**
   * Function that shifts the time range of streams by half the duration of the stream.
   *
   * @param {string} direction shifts forward by default but will shift backward if provided "back"
   */
  const shiftTime = (direction) => {
    // pans the view by 50% of the current duration
    const halfDuration = (tEnd - tStart) / 2
    direction === 'back'
      ? updateTimeRange(tStart - halfDuration, tEnd - halfDuration)
      : updateTimeRange(tStart + halfDuration, tEnd + halfDuration)
  }

  return (
    <>
      <Box ml={eventsStream ? 0 : 1} data-cy="shiftButtons">
        <Button
          variant="outlined"
          edge="start"
          ml={1}
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => shiftTime('back')}
        >
          Shift back
        </Button>
      </Box>
      <Box ml={1}>
        <Button
          variant="outlined"
          edge="start"
          ml={1}
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={shiftTime}
        >
          Shift forward
        </Button>
      </Box>
    </>
  )
}

export default ShiftButtons
