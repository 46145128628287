import { useState, useCallback, useContext } from 'react'
import CreateEntityDialog from 'ui/components/CreateEntity/CreateEntityDialog'
import {
  UPDATE_TASK_NOTIFICATION,
  buildTaskNotificationRequestObject,
  isRequestReady,
} from 'ui/screens/Patients/TaskNotifications/CreateTaskDialog/mutations.js'
import PropTypes from 'prop-types'
import TaskDialogContent from 'ui/screens/Patients/TaskNotifications/CreateTaskDialog/TaskDialogContent.js'
import { TaskNotificationContext } from 'ui/contexts'
import { useMutation } from '@apollo/client'
import { useRefetchTaskNotifications } from '../queries'

/**
 * The modal for editing a Task Notification.
 *
 * @returns {JSX.Element} the Task Notification edit modal
 */
export const TaskEditDialog = ({ id, handleClose }) => {
  const [success, setSuccess] = useState(false)
  const [timeoutId, setTimeoutId] = useState(false)
  const taskNotificationContextValue = useContext(TaskNotificationContext)
  const [updateTaskNotification, { loading }] = useMutation(
    UPDATE_TASK_NOTIFICATION,
    {
      refetchQueries: [useRefetchTaskNotifications()],
      awaitRefetchQueries: true,
    },
  )
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      clearTimeout(timeoutId)
      try {
        const input = buildTaskNotificationRequestObject(
          taskNotificationContextValue,
        )
        input.id = id
        await updateTaskNotification({ variables: { input } })
        setSuccess(true)
        setTimeoutId(setTimeout(() => setSuccess(false), 2000))
        handleClose()
      } catch (error) {
        console.error(error)
      }
    },
    [
      updateTaskNotification,
      handleClose,
      taskNotificationContextValue,
      timeoutId,
      id,
    ],
  )

  const disabled = !isRequestReady(taskNotificationContextValue)

  return (
    <CreateEntityDialog
      title="Edit task"
      data-testid="EditTaskDialog"
      success={success}
      loading={loading}
      disabled={disabled}
      handleClose={handleClose}
      onSubmit={onSubmit}
      submitLabel="Save"
      successLabel="Saved"
    >
      <TaskDialogContent />
    </CreateEntityDialog>
  )
}

TaskEditDialog.propTypes = {
  id: PropTypes.string,
  handleClose: PropTypes.func,
}

export default TaskEditDialog
