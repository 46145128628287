import { useState, useContext, useMemo, useEffect, memo } from 'react'
import { Stack } from 'ui/baseComponents/Stack'
import { Button } from 'ui/components/Button'
import DownloadIcon from '@mui/icons-material/Download'
import WarningIcon from '@mui/icons-material/Warning'
import RateReviewIcon from '@mui/icons-material/RateReview'
import ReportSectionHighlights from './sections/Highlights/ReportSectionHighlights'
import ReportSectionSymptomLog from './sections/SymptomLog/ReportSectionSymptomLog'
import ReportSectionActivity from './sections/Activity/ReportSectionActivity'
import ReportSectionMedication from './sections/Medication/ReportSectionMedication'
import ReportHeader from './components/ReportHeader/ReportHeader'
import ReportFooter from './components/ReportFooter'
import ReportSectionTremorAndDyskinesia from './sections/TremorAndDyskinesia/ReportSectionTremorAndDyskinesia'
import ReportSectionMobility from './sections/Mobility/ReportSectionMobility'
import { LLM_KEYS, SAVE_BUTTON_STYLES } from './consts'
import useReportLLM from 'ui/hooks/useReportLLM'
import ReviewEditsModal from './components/ReviewEditsModal'
import { PatientContext } from 'ui/contexts'
import { useSearchParams } from 'react-router-dom'
import useBoundStore from 'domains/zustand/store'
import ReportSectionDIN from 'ui/clinicianScreens/Patient/Report/sections/DIN/ReportSectionDIN'
import { getIsDINVariant } from 'ui/clinicianScreens/Patient/Report/helpers'

const ReportPatient = ({ ...dataSectionProps }) => {
  const {
    startTime,
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
    endTime,
    saveReport,
    onLoadStart,
    onLoadEnd,
    variant,
  } = dataSectionProps
  const [searchParams] = useSearchParams()
  const includeLLMSummariesQuery = searchParams.get('include_llm_summaries')

  const isLLMEnabled =
    includeLLMSummariesQuery === 'True' || !includeLLMSummariesQuery
  const isDINVariant = getIsDINVariant(variant)

  const [openEditsModal, setOpenEditsModal] = useState(false)
  const [llmData, setLlmData] = useState({})
  const [unsavedChanges, setUnsavedChanges] = useState(new Set())
  const [edits, setEdits] = useState(new Map())
  const [pageRefresher, setPageRefresher] = useState(false)
  const [generateNewLLM, setGenerateNewLLM] = useState(false)
  const [request, setRequest] = useState({})

  const hasEdits = useMemo(() => edits.size > 0, [edits])
  const hasUnsavedChanges = useMemo(
    () => unsavedChanges.size > 0,
    [unsavedChanges],
  )

  const { data, isLoading: isLLMLoading } = useReportLLM(request)

  const patient = useContext(PatientContext)
  const { id: patientId } = patient || {}

  const statePatientId = useBoundStore((state) => state.patientId)
  const setStatePatientId = useBoundStore((state) => state.setPatientId)
  const resetReportState = useBoundStore((state) => state.resetReportState)
  if (patientId !== statePatientId) {
    setStatePatientId(patientId)
    resetReportState()
  }

  const togglePageRefresher = () => {
    setPageRefresher((prev) => !prev)
  }

  useEffect(() => {
    if (isLLMEnabled) {
      setRequest({
        options: {
          start: startTime,
          end: endTime,
          promptType: 'MONTHLY_REPORT_SYMPTOMS_AND_ACTIVITIES',
          regenerate: generateNewLLM,
        },
        method: 'POST',
      })
    } else {
      setRequest({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRefresher, startDate, endDate, prevStartDate, prevEndDate])

  const refreshPage = (reloadLLM = false) => {
    setGenerateNewLLM(reloadLLM)
    togglePageRefresher()
    setUnsavedChanges(new Set())
    setEdits(new Map())
  }

  const editLLMSummary = (key, summary) => {
    if (summary) {
      setEdits((prev) => new Map(prev.set(key, summary)))
    } else {
      setEdits((prev) => {
        const next = new Map(prev)
        next.delete(key)
        return next
      })
    }
  }

  const saveLLMEdits = (reviewerComments = '', reviewerClassification = '') => {
    const {
      id: reportId,
      summaries: {
        activity: activitySummary,
        symptom: symptomSummary,
        reflection: reflectionSummary,
      } = {},
    } = llmData
    setRequest({
      options: {
        reportId,
        editedSummaries: {
          activity: edits.get('activity') || activitySummary,
          symptom: edits.get('symptom') || symptomSummary,
          reflection: edits.get('reflection') || reflectionSummary,
        },
        reviewerComments,
        reviewerClassification,
      },
      method: 'PATCH',
    })
  }

  const toggleEditsModal = () => setOpenEditsModal(!openEditsModal)

  const addToUnsavedChanges = (key) => {
    setUnsavedChanges((prev) => new Set(prev.add(key)))
  }
  const removeFromUnsavedChanges = (key) => {
    setUnsavedChanges((prev) => {
      const next = new Set(prev)
      next.delete(key)
      return next
    })
  }

  useEffect(() => {
    if (isLLMLoading) {
      onLoadStart('LLM data')
    } else {
      if (data) {
        setLlmData(data)
      }
      onLoadEnd('LLM data')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLLMLoading])

  const patientSectionProps = {
    ...dataSectionProps,
    onUpdate: editLLMSummary,
    addToUnsavedChanges,
    removeFromUnsavedChanges,
    isDINVariant,
  }

  const SaveButton = () => {
    const savePatientReport = () => {
      if (data?.id) {
        saveLLMEdits()
      }
      saveReport()
    }

    return (
      <Stack spacing={0} sx={SAVE_BUTTON_STYLES} alignItems="center">
        {hasUnsavedChanges ? (
          <Button variant="outlined" disabled startIcon={<WarningIcon />}>
            Edits still in progress
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={hasEdits ? toggleEditsModal : savePatientReport}
            startIcon={hasEdits ? <RateReviewIcon /> : <DownloadIcon />}
          >
            {hasEdits ? `Review edits (${edits.size})` : 'Download PDF'}
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <>
      <SaveButton />

      <ReportHeader {...patientSectionProps} />

      <ReportSectionHighlights {...patientSectionProps} />
      <ReportSectionSymptomLog
        {...{
          llmKey: LLM_KEYS.SYMPTOM,
          llmSummary:
            edits.get(LLM_KEYS.SYMPTOM) || llmData?.edited_summaries?.symptom,
          ...patientSectionProps,
        }}
      />
      {isDINVariant && <ReportSectionDIN {...patientSectionProps} />}

      <ReportSectionTremorAndDyskinesia
        {...{
          llmKey: 'tremorAndDyskinesia',
          llmSummary:
            edits.get('tremorAndDyskinesia') ||
            llmData?.edited_summaries?.tremorAndDyskinesia,
          ...patientSectionProps,
        }}
      />
      <ReportSectionActivity
        {...{
          llmKey: LLM_KEYS.ACTIVITY,
          llmSummary:
            edits.get(LLM_KEYS.ACTIVITY) || llmData?.edited_summaries?.activity,
          ...patientSectionProps,
        }}
      />
      <ReportSectionMobility {...patientSectionProps} />
      <ReportSectionMedication
        {...{
          llmKey: 'medication',
          llmSummary:
            edits.get('medication') || llmData?.edited_summaries?.medication,
          ...patientSectionProps,
        }}
      />

      <ReportFooter showLLMDisclaimer={!!llmData?.id} />

      <SaveButton />

      <ReviewEditsModal
        {...{
          openEditsModal,
          edits,
          llmData,
          toggleEditsModal,
          onSave: saveLLMEdits,
          refreshPage,
        }}
      />
    </>
  )
}

export default memo(ReportPatient)
