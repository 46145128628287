import { GET_PATIENT_LIST } from 'domains/carrotGraph/queries'
import { get } from 'nested-property'

/**
 * Function that fetches further pages of patients based on a cursor
 *
 * @param {function} fetchMore the function to append to the user query
 * @param {object} data the data for the user, which includes the patientAccessList
 * @param {boolean} cursor the cursor indicating the page depth of our query
 * @return {null} - no return value
 */
export const fetchAllPatients = (fetchMore, data, cursor) => {
  fetchMore({
    variables: { cursor },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      // isolates the list to which we are appending
      const prevPatients =
        get(
          previousResult,
          'user.defaultMembership.org.patientAccessList.patientAccess',
        ) || []
      const newPatients =
        get(
          fetchMoreResult,
          'user.defaultMembership.org.patientAccessList.patientAccess',
        ) || []
      // spread operator used repeatedly to append data to only the correct list.
      // A little bit verbose at this depth but necessary for our db structure.
      return {
        ...fetchMoreResult,
        user: {
          ...(fetchMoreResult.user || {}),
          defaultMembership: {
            ...(fetchMoreResult.user.defaultMembership || {}),
            org: {
              ...(fetchMoreResult.user.defaultMembership.org || {}),
              patientAccessList: {
                ...(fetchMoreResult.user.defaultMembership.org
                  .patientAccessList || {}),
                patientAccess: [...prevPatients, ...newPatients],
              },
            },
          },
        },
      }
    },
  })
}
export const useRefetchPatients = () => {
  const query = GET_PATIENT_LIST
  return {
    query,
  }
}
