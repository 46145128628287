import { useContext, useEffect, useState } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { colors } from 'theme/colors'
import OverviewMedicationChart from './OverviewMedicationChart'
import useTremorAndDyskinesiaAggregate from 'ui/hooks/summaryAggregates/useTremorAndDyskinesiaAggregate'
import {
  extractUniqueMedicationNames,
  extractUniqueSupplementNames,
  formatBarChartData,
} from '../helpers'
import { getCombinedMaxValue } from 'ui/clinicianScreens/Patient/Report/helpers'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import { Text } from 'ui/baseComponents/Text'
import BarChart from 'ui/baseComponents/Charts/BarChart'
import { CustomMotorTooltip } from './ChartTooltips'
import OverviewChartWithTitle from './OverviewChartWithTitle'
import { FIFTEEN_MINUTES_IN_SECONDS } from 'utilities/time'
import { PatientContext } from 'ui/contexts'
import OverviewMedicationFilters from './OverviewMedicationFilters'
import OverviewChartToggle from './OverviewChartToggle'
import { OverviewEmptyChart } from './OverviewEmptyChart'
import { useFlags } from 'domains/launchdarkly/hooks'

const OverviewChartContent = () => {
  const [tremorVisible, setTremorVisible] = useState(true)
  const [dyskinesiaVisible, setDyskinesiaVisible] = useState(true)
  const [medicationVisible, setMedicationVisible] = useState(true)
  const [selectedMedications, setSelectedMedications] = useState([])

  const { medicationScheduleList } = useContext(PatientContext)
  const activeSchedules =
    medicationScheduleList?.schedules.filter((item) => item.active) || []
  const medicationList = extractUniqueMedicationNames(activeSchedules)
  const supplementList = extractUniqueSupplementNames(activeSchedules)

  useEffect(() => {
    if (medicationList.length || supplementList.length) {
      setSelectedMedications([...medicationList, ...supplementList])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMedicationChange = (event) => {
    const {
      target: { value },
    } = event
    setSelectedMedications(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const { dateRanges } = useContext(DateRangeContext)
  const { start, end } = dateRanges[DATE_RANGE.default.key] || {}

  const STYLES = {
    loadingContainer: {
      margin: '1rem 0',
    },
    divider: {
      color: colors.COOL[100],
      backgroundColor: colors.COOL[100],
      height: '2em',
      border: 0,
    },
  }

  const { overviewChartsVisible } = useFlags()

  const {
    data: { tremor: tremorData, dyskinesia: dyskinesiaData },
    isLoading: isMotorLoading,
  } = useTremorAndDyskinesiaAggregate(start, end, FIFTEEN_MINUTES_IN_SECONDS)

  const hasTremorData = !!tremorData?.numberOfDaysWithData

  const formattedTremorData = formatBarChartData(tremorData, 'Tremor')
  const formattedDyskinesiaData = formatBarChartData(
    dyskinesiaData,
    'Dyskinesia',
  )

  const tremorLegendItem = {
    name: 'Tremor',
    color: colors.TREMOR[600],
  }

  const dyskinesiaLegendItem = {
    name: 'Dyskinesia',
    color: colors.DYSKINESIA[500],
  }

  const showTremor = tremorVisible && hasTremorData && !isMotorLoading
  const showDyskinesia = dyskinesiaVisible && hasTremorData && !isMotorLoading
  const showMotorLoading =
    (tremorVisible || dyskinesiaVisible) && isMotorLoading
  const showTremorNoData = tremorVisible && !hasTremorData && !isMotorLoading

  const yAxisMax = getCombinedMaxValue(tremorData, dyskinesiaData)

  return (
    <>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
          sx={{ padding: '1rem 1.5rem' }}
        >
          <Text variant="caps14B" color={colors.RUNE_GRAY_800}>
            24-hour average
          </Text>
          <Stack direction="row" spacing={4} alignItems="center">
            <OverviewChartToggle
              title="Tremor"
              checked={tremorVisible}
              onChange={(e) => setTremorVisible(e.target.checked)}
              sx={{
                color: colors.TREMOR[600],
                '&.Mui-checked': { color: colors.TREMOR[600] },
              }}
            />
            <OverviewChartToggle
              title="Dyskinesia"
              checked={dyskinesiaVisible}
              onChange={(e) => setDyskinesiaVisible(e.target.checked)}
              sx={{
                color: colors.DYSKINESIA[500],
                '&.Mui-checked': { color: colors.DYSKINESIA[500] },
              }}
            />
            {overviewChartsVisible && (
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <OverviewChartToggle
                  checked={medicationVisible}
                  onChange={(e) => setMedicationVisible(e.target.checked)}
                  sx={{
                    color: colors.PURPLE[800],
                    '&.Mui-checked': { color: colors.PURPLE[800] },
                  }}
                />
                <OverviewMedicationFilters
                  {...{
                    medicationList: medicationList,
                    supplementList: supplementList,
                    onChange: handleMedicationChange,
                    selectedMedications: selectedMedications,
                  }}
                />
              </span>
            )}
          </Stack>
        </Stack>
        {showMotorLoading && (
          <Stack alignItems="center" sx={STYLES.loadingContainer}>
            <CircularProgress />
          </Stack>
        )}
        {showTremor && (
          <OverviewChartWithTitle title="TREMOR">
            <BarChart
              {...{
                title: 'TREMOR',
                data: formattedTremorData,
                legendItem: tremorLegendItem,
                height: 100,
                xAxisInterval: 7,
                yAxisInterval: 1,
                yAxisMax,
                yAxisUnit: ' m',
                tooltip: CustomMotorTooltip,
                isAnimationActive: true,
              }}
            />
          </OverviewChartWithTitle>
        )}
        {showDyskinesia && (
          <OverviewChartWithTitle title="DYSKINESIA">
            <BarChart
              {...{
                title: 'DYSKINESIA',
                data: formattedDyskinesiaData,
                legendItem: dyskinesiaLegendItem,
                height: 100,
                xAxisInterval: 7,
                yAxisInterval: 1,
                yAxisMax,
                yAxisUnit: ' m',
                tooltip: CustomMotorTooltip,
              }}
            />
          </OverviewChartWithTitle>
        )}
        {showTremorNoData && (
          <OverviewEmptyChart title="Tremor and Dyskinesia" />
        )}
        {medicationVisible && overviewChartsVisible && (
          <OverviewMedicationChart
            {...{
              selectedMedications,
              schedules: activeSchedules,
            }}
          />
        )}
      </Stack>
      <hr style={STYLES.divider} />
    </>
  )
}

export default OverviewChartContent
