import { useContext, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { stringifyQueryParameters } from './helpers'
import { ClinicianPatientHeader } from 'ui/clinicianScreens/ClinicianPatientHeader'
import { getTimestampEndOfDay, getTimestampStartOfDay } from 'utilities/time'
import { getTabs } from './Tabs/getTabs'
import { PatientContext, RecentDataAvailabilityContext } from 'ui/contexts'
import {
  reFetchEventList,
  usePatientEvents,
  usePatientEventCount,
} from 'ui/screens/Patient/queries'
import { sharedRoutes } from 'ui/screens/Patient/consts'
import { clinicianRoutes } from './Tabs/consts'
import useRecentAvailability from 'ui/hooks/useRecentAvailability'
import { ContextCollection } from 'ui/components/ContextCollection'
import useSummaryModeContext, {
  SummaryModeContext,
} from 'ui/contexts/SummaryModeContext'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import useUiStateContext, { UiStateContext } from 'ui/contexts/UiStateContext'
import { useRecentDataAvailabilityContext } from 'ui/contexts/RecentDataAvailabilityContext'
import MainContentWrapper from 'ui/templates/MainContentWrapper'
import MainContent from 'ui/templates/MainContent'
import ClinicianPatientTabs from './Tabs/ClinicianPatientTabs'
import useBoundStore from 'domains/zustand/store'
import { Stack } from 'ui/baseComponents/Stack'
import TimezoneDropdown from 'ui/clinicianScreens/Patient/TimezoneDropdown'
import { colors } from 'theme/colors'

const ClinicianPatient = () => {
  const hideDateJumpAlert = useBoundStore((state) => state.hideDateJumpAlert)
  const { recentAvailabilityData, setRecentAvailabilityData } =
    useRecentDataAvailabilityContext()
  const [shouldExecuteAvailabilityCall, setShouldExecuteAvailabilityCall] =
    useState(true)

  const { id: patientId } = useContext(PatientContext)

  const { dateRanges, setDateRange, selectedTimezone, setSelectedTimezone } =
    useContext(DateRangeContext)
  const { start: startTime, end: endTime } =
    dateRanges[DATE_RANGE.default.key] || {}
  const { start: startTimePeriod1, end: endTimePeriod1 } =
    dateRanges[DATE_RANGE.period1.key] || {}
  const { start: startTimePeriod2, end: endTimePeriod2 } =
    dateRanges[DATE_RANGE.period2.key] || {}
  const { start: reportPrevStartDate, end: reportPrevEndDate } =
    dateRanges[DATE_RANGE.reportPeriod1.key] || {}
  const { start: reportRecentStartDate, end: reportRecentEndDate } =
    dateRanges[DATE_RANGE.reportPeriod2.key] || {}
  const { start: weeklyStartDate, end: weeklyEndDate } =
    dateRanges[DATE_RANGE.weeklyViewPeriod.key] || {}

  const { pathname } = useLocation()
  const llmSummaryQueryKey = 'include_llm_summaries'
  const [searchParams, setSearchParams] = useSearchParams()
  const includeLLMSummariesQuery = searchParams.get(llmSummaryQueryKey)

  const PATHNAMES = {
    comparisonMode: `/patients/${patientId}/${clinicianRoutes.SUMMARY_COMPARISON}`,
    weeklyView: `/patients/${patientId}/${clinicianRoutes.WEEKLY_VIEW}`,
    dailyView: `/patients/${patientId}/${clinicianRoutes.DAILY}`,
    detailView: `/patients/${patientId}/${clinicianRoutes.SUMMARY}`,
    overview: `/patients/${patientId}/${clinicianRoutes.OVERVIEW}`,
    patientLog: `/patients/${patientId}/${clinicianRoutes.LOG}`,
    reportClinician: `/patients/${patientId}/${clinicianRoutes.REPORT_CLINICIAN}`,
    reportPatient: `/patients/${patientId}/${clinicianRoutes.REPORT_PATIENT}`,
  }

  const startTimeForEventRequests = getTimestampStartOfDay(
    startTime,
    selectedTimezone,
  )
  const endTimeForEventRequests = getTimestampEndOfDay(
    endTime,
    selectedTimezone,
  )

  const { loadingEvents, errorEvents, dataEvents, fetchMore } =
    usePatientEvents(
      patientId,
      startTimeForEventRequests,
      endTimeForEventRequests,
    )

  const { eventCountLoading, eventCountError, eventCountData } =
    usePatientEventCount(
      patientId,
      startTimeForEventRequests,
      endTimeForEventRequests,
    )

  const eventList = useMemo(
    () => dataEvents?.patient?.eventList?.events || [],
    [dataEvents],
  )

  useEffect(() => {
    const topSymptomEventCounts =
      eventCountData?.patient?.eventCount?.counts || []

    useBoundStore.setState(() => ({
      eventList,
      topSymptomEventCounts,
      loadingEvents,
      eventCountError,
      eventCountLoading,
      errorEvents,
    }))
  }, [
    eventList,
    loadingEvents,
    eventCountError,
    eventCountLoading,
    errorEvents,
    eventCountData?.patient?.eventCount?.counts,
  ])

  /*  -------------------------
      CONTEXT VALUES
  -------------------------  */

  const uiStateContextValue = useUiStateContext()

  const summaryModeContextValue = useSummaryModeContext()
  const { comparisonModeEnabled, setComparisonModeEnabled } =
    summaryModeContextValue

  const recentDataAvailabilityContextValue = useRecentAvailability(
    startTime,
    endTime,
    shouldExecuteAvailabilityCall,
  )

  if (
    !recentAvailabilityData &&
    Object.values(recentDataAvailabilityContextValue?.data).some((val) => val)
  ) {
    setRecentAvailabilityData(recentDataAvailabilityContextValue)
  }

  if (recentAvailabilityData && shouldExecuteAvailabilityCall) {
    setShouldExecuteAvailabilityCall(false)
  }

  const patientDataContexts = [
    { context: UiStateContext, value: uiStateContextValue },
    { context: SummaryModeContext, value: summaryModeContextValue },
    { context: RecentDataAvailabilityContext, value: recentAvailabilityData },
  ]
  /*  -------------------------
      END CONTEXT VALUES
  -------------------------  */

  /*  -------------------------
      USE EFFECTS
  -------------------------  */
  useEffect(() => {
    hideDateJumpAlert()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId])

  useEffect(() => {
    // (XXX Elina): TODO -- replace with useSearchParams hook
    const searchParams = new URLSearchParams(window.location.search)
    const startTimeFromSearchParams = searchParams.get('startTime')
    const endTimeFromSearchParams = searchParams.get('endTime')
    const startTimePeriod1FromSearchParams =
      searchParams.get('startTimePeriod1')
    const endTimePeriod1FromSearchParams = searchParams.get('endTimePeriod1')
    const startTimePeriod2FromSearchParams =
      searchParams.get('startTimePeriod2')
    const endTimePeriod2FromSearchParams = searchParams.get('endTimePeriod2')

    // If the user navigates directly to the summaryComparison route, we set comparisonModeEnabled to true
    if (pathname === PATHNAMES.comparisonMode && !comparisonModeEnabled) {
      setComparisonModeEnabled(true)
    }

    /*  WEEKLY VIEW DATE CHECK
        ------------------------------
        URL search params are our source of truth.
        If they exist, ensure that the date range stored in our context matches.
        Otherwise (if no URL search params), set them based on what is stored in our context.
    */
    if (pathname === PATHNAMES.weeklyView) {
      if (!startTimeFromSearchParams || !endTimeFromSearchParams) {
        const newSearchParams = stringifyQueryParameters(
          reportPrevStartDate,
          reportRecentEndDate,
        )
        setSearchParams(newSearchParams, {
          replace: true,
        })
        setDateRange(
          reportPrevStartDate,
          reportRecentEndDate,
          DATE_RANGE.weeklyViewPeriod.key,
        )
      } else if (
        startTimeFromSearchParams &&
        endTimeFromSearchParams &&
        weeklyStartDate !== startTimeFromSearchParams &&
        weeklyEndDate !== endTimeFromSearchParams
      ) {
        setDateRange(
          weeklyStartDate,
          weeklyEndDate,
          DATE_RANGE.weeklyViewPeriod.key,
        )
      }
    } else if (
      /*  NON COMPARISON MODE DATE CHECK
        ------------------------------
        URL search params are our source of truth.
        If they exist, ensure that the date range stored in our context matches.
        Otherwise (if no URL search params), set them based on what is stored in our context.
    */
      pathname !== PATHNAMES.comparisonMode &&
      pathname !== PATHNAMES.reportClinician &&
      pathname !== PATHNAMES.reportPatient
    ) {
      if (
        startTimeFromSearchParams &&
        endTimeFromSearchParams &&
        startTime !== startTimeFromSearchParams &&
        endTime !== endTimeFromSearchParams
      ) {
        setDateRange(startTimeFromSearchParams, endTimeFromSearchParams)
      } else {
        const newSearchParams = stringifyQueryParameters(startTime, endTime)
        setSearchParams(newSearchParams, {
          replace: true,
        })
      }
    }

    /*  COMPARISON MODE DATE CHECK
        --------------------------
        URL search params are our source of truth.
        If they exist, ensure that the date range stored in our context matches.
        Otherwise (if no URL search params), set them based on what is stored in our context.
    */
    if (pathname === PATHNAMES.comparisonMode) {
      if (
        startTimePeriod1FromSearchParams &&
        endTimePeriod1FromSearchParams &&
        startTimePeriod2FromSearchParams &&
        endTimePeriod2FromSearchParams &&
        startTimePeriod1 !== startTimePeriod1FromSearchParams &&
        endTimePeriod1 !== endTimePeriod1FromSearchParams &&
        startTimePeriod2 !== startTimePeriod2FromSearchParams &&
        endTimePeriod2 !== endTimePeriod2FromSearchParams
      ) {
        setDateRange(
          startTimePeriod1FromSearchParams,
          endTimePeriod1FromSearchParams,
          DATE_RANGE.period1.key,
        )
        setDateRange(
          startTimePeriod2FromSearchParams,
          endTimePeriod2FromSearchParams,
          DATE_RANGE.period2.key,
        )
      } else {
        const summaryComparisonSearchParams =
          startTimePeriod1 &&
          endTimePeriod1 &&
          startTimePeriod2 &&
          endTimePeriod2
            ? {
                startTimePeriod1,
                endTimePeriod1,
                startTimePeriod2,
                endTimePeriod2,
              }
            : ''
        setSearchParams(summaryComparisonSearchParams, { replace: true })
      }
    }

    /*  REPORT DATE CHECK
        --------------------------
        URL search params are our source of truth.
        If they exist, ensure that the date range stored in our context matches.
        Otherwise (if no URL search params), set them based on what is stored in our context.
    */
    if (
      pathname === PATHNAMES.reportClinician ||
      pathname === PATHNAMES.reportPatient
    ) {
      if (
        startTimePeriod1FromSearchParams &&
        endTimePeriod1FromSearchParams &&
        startTimePeriod2FromSearchParams &&
        endTimePeriod2FromSearchParams &&
        startTimePeriod1 !== startTimePeriod1FromSearchParams &&
        endTimePeriod1 !== endTimePeriod1FromSearchParams &&
        startTimePeriod2 !== startTimePeriod2FromSearchParams &&
        endTimePeriod2 !== endTimePeriod2FromSearchParams
      ) {
        setDateRange(
          startTimePeriod1FromSearchParams,
          endTimePeriod1FromSearchParams,
          DATE_RANGE.reportPeriod1.key,
        )
        setDateRange(
          startTimePeriod2FromSearchParams,
          endTimePeriod2FromSearchParams,
          DATE_RANGE.reportPeriod2.key,
        )
      } else {
        const reportsSearchParams =
          reportRecentStartDate &&
          reportRecentEndDate &&
          reportPrevStartDate &&
          reportPrevEndDate
            ? {
                startTimePeriod1: reportPrevStartDate,
                endTimePeriod1: reportPrevEndDate,
                startTimePeriod2: reportRecentStartDate,
                endTimePeriod2: reportRecentEndDate,
              }
            : {}

        if (includeLLMSummariesQuery) {
          reportsSearchParams[llmSummaryQueryKey] = 'True'
        }

        setSearchParams(reportsSearchParams, { replace: true })
        setDateRange(
          reportPrevStartDate,
          reportPrevEndDate,
          DATE_RANGE.reportPeriod1.key,
        )
        setDateRange(
          reportRecentStartDate,
          reportRecentEndDate,
          DATE_RANGE.reportPeriod2.key,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  // The hook to continue fetching data in the instance that multiple "pages" would need querying
  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = dataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(fetchMore, endCursor)
    }
  }, [dataEvents, fetchMore])

  /*  -------------------------
      END USE EFFECTS
  -------------------------  */

  // To bypass the tabs entirely and make sure these two views populate correctly we're using the pathname to conditionally render
  if (
    pathname.includes(clinicianRoutes.EXPLORE) ||
    pathname.includes(sharedRoutes.BROWSE)
  ) {
    return <></>
  }

  const showTimezoneDropdown =
    pathname === PATHNAMES.comparisonMode ||
    pathname === PATHNAMES.dailyView ||
    pathname === PATHNAMES.detailView ||
    pathname === PATHNAMES.reportClinician ||
    pathname === PATHNAMES.overview ||
    pathname === PATHNAMES.patientLog ||
    pathname === PATHNAMES.reportPatient ||
    pathname === PATHNAMES.weeklyView

  return (
    <>
      <ClinicianPatientHeader getTabs={getTabs} />
      <ContextCollection contexts={patientDataContexts}>
        <MainContentWrapper>
          <MainContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ backgroundColor: colors.COOL[100] }}
              data-cy="tab-container"
            >
              <ClinicianPatientTabs getTabs={getTabs} />
              {showTimezoneDropdown && (
                <TimezoneDropdown
                  timezone={selectedTimezone}
                  updateTimezone={setSelectedTimezone}
                />
              )}
            </Stack>
            <Outlet />
          </MainContent>
        </MainContentWrapper>
      </ContextCollection>
    </>
  )
}

export default ClinicianPatient
