import { useContext } from 'react'
import ChartLoading from 'ui/components/ChartLoading'
import EmptyResults from 'ui/components/EmptyResults'
import ErrorFrame from 'ui/components/ErrorFrame'
import { InfoCard } from 'ui/components/Card'
import { List } from 'ui/components/List'
import { PercentCircle } from 'ui/components/PercentCircle'
import { shape, string } from 'prop-types'
import { translate as t } from 'ui/components/translate'
import { Box } from '@material-ui/core'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { DATE_RANGE, DateRangeContext } from 'ui/contexts/DateRangeContext'
import { PatientContext } from 'ui/contexts'
import usePatientAdherence from 'ui/hooks/usePatientAdherence'
import { getTimestampEndOfDay, getTimestampStartOfDay } from 'utilities/time'

const useStyles = makeStyles(() => ({
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
}))

/**
 * The adherence pie chart for the summary tab
 *
 * @param {object} classes Classes passed from parent
 * @returns {JSX.Element} Medication Adherence summary card with quick information about the current period.
 */
const MedicationAdherence = ({ classes }) => {
  const { id } = useContext(PatientContext)
  const { dateRanges, selectedTimezone } = useContext(DateRangeContext)

  const { start: startDate, end: endDate } =
    dateRanges[DATE_RANGE.default.key] || {}

  const startTime = getTimestampStartOfDay(startDate, selectedTimezone)
  const endTime = getTimestampEndOfDay(endDate, selectedTimezone)

  const { dataAdherence, errorAdherence, loadingAdherence } =
    usePatientAdherence(id, startTime, endTime) || {}

  const {
    adherencePercentage,
    previousAdherencePercentage,
    nonAdherenceCount,
    adherenceCount,
  } = dataAdherence?.patient?.eventAdherence || {}

  const adherenceClasses = useStyles()
  // TODO: Update summaryText here and below. In MVP, a user was only able to see two week intervals, but that has changed. https://runelabs.atlassian.net/browse/CLI-403
  let summaryText = 'No change vs. prior 2 weeks'
  const NO_ADHERENCE = 0
  const DECIMAL_PRECISION = 2

  if (adherencePercentage && previousAdherencePercentage) {
    const adherenceDiff = Number(
      parseFloat(adherencePercentage - previousAdherencePercentage).toFixed(
        DECIMAL_PRECISION,
      ),
    )

    if (adherenceDiff > NO_ADHERENCE) {
      summaryText = (
        <span className={adherenceClasses.icon}>
          <TrendingUpIcon />
          &nbsp; Up {adherenceDiff}% vs. prior 2 weeks
        </span>
      )
    } else if (adherenceDiff < NO_ADHERENCE) {
      summaryText = (
        <span className={adherenceClasses.icon}>
          <WarningIcon color={'error'} />
          &nbsp; Down {Math.abs(adherenceDiff)}% vs. prior 2 weeks
        </span>
      )
    }
  }

  if (loadingAdherence) {
    return <ChartLoading height={150} />
  }
  if (errorAdherence) {
    return (
      <ErrorFrame
        error="Medication Adherence unable to load"
        height={'175px'}
      />
    )
  }
  return (
    <InfoCard
      className={classes.card}
      subtitle={summaryText}
      title={t('patient.medication_adherence')}
    >
      <Box alignItems="center" display="flex">
        {!dataAdherence || !adherencePercentage ? (
          <Box className={classes.emptyItem}>
            <EmptyResults message="None logged" />
          </Box>
        ) : (
          <>
            <PercentCircle
              percent={adherencePercentage}
              height={160}
              width={160}
              label={'Adherence'}
            />
            <List
              component="ul"
              list={[
                {
                  id: 1,
                  value: (
                    <b>
                      {adherencePercentage}% {t('patient.adherence')}
                    </b>
                  ),
                },
                {
                  id: 2,
                  value: `${nonAdherenceCount} ${t('patient.missed_doses')}`,
                },
                {
                  id: 3,
                  value: `${adherenceCount} ${t('patient.doses_taken')}`,
                },
              ]}
            />
          </>
        )}
      </Box>
    </InfoCard>
  )
}

MedicationAdherence.propTypes = {
  classes: shape({
    card: string,
    emptyItem: string,
  }).isRequired,
}

MedicationAdherence.defaultProps = {
  classes: {},
}

export default MedicationAdherence
