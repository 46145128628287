import { gql } from '@apollo/client'

export const UPDATE_DEVICE = gql`
  mutation updateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      device {
        id
      }
    }
  }
`
