import { useCallback, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import CreateEntityDialog from 'ui/components/CreateEntity/CreateEntityDialog'
import { Box, TextField } from '@material-ui/core'
import { useRefetchPatients } from '../queries'
import { CREATE_PATIENT_ACCESS_BY_DEVICE } from './mutations'
import { makeStyles } from '@material-ui/core/styles'
import SuccessDialog from './SuccessDialog'
import FormControl from './FormControl'
import NetworkErrorAlert from './NetworkErrorAlert'
import { SnackbarContext } from 'ui/contexts/SnackbarContext'

const useStyles = makeStyles(() => ({
  content: {
    minWidth: 500,
    maxWidth: 'fit-content',
  },
  form: {
    padding: '0.5em 1em',
    maxWidth: 600,
    overflowWrap: 'break-word',
  },
  networkError: {
    backgroundColor: '#79AED0',
    boxShadow: 'none',
    maxWidth: 'fit-content',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '1rem',
  },
  message: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '0.9em',
    fontWeight: 500,
    lineHeight: '1.2em',
  },
}))

const CreatePatientDialog = ({ handleClose }) => {
  const classes = useStyles()

  const { setNotificationType, setSnackbarMessage } =
    useContext(SnackbarContext)

  const [deviceId, setDeviceId] = useState('')
  const [deviceIdInput, setDeviceIdInput] = useState('')
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true)
  const [error, setError] = useState('')
  const [networkError, setNetworkError] = useState(false)
  const [patientId, setPatientId] = useState('')
  const [codeName, setCodeName] = useState('')

  const handleCloseWithToast = () => {
    if (networkError) {
      setNotificationType('warning')
      setSnackbarMessage('Patient was not added.')
    } else {
      setNotificationType('success')
      setSnackbarMessage('Patient was successfully added.')
    }
    handleClose()
  }

  const [createPatientAccessByDevice, { loading }] = useMutation(
    CREATE_PATIENT_ACCESS_BY_DEVICE,
    {
      onCompleted: (data) => {
        const patientCodeName =
          data?.createPatientAccess?.patientAccess?.patient?.codeName
        const patientId = data?.createPatientAccess?.patientAccess?.patient?.id
        const devices =
          data?.createPatientAccess?.patientAccess?.patient?.deviceList
            ?.devices || []
        const deviceMatch = devices.find(
          (d) => d.deviceShortId === deviceIdInput,
        )
        setNetworkError(false)
        if (deviceMatch) {
          setCodeName(patientCodeName)
          setPatientId(patientId)
          setDeviceId(deviceIdInput)
          setError(null)
        } else {
          setSubmitButtonDisabled(true)
          setError('Input is invalid. Please confirm the Phone device ID.')
        }
      },
      onError: (error) => {
        if (error.networkError) {
          setNetworkError(true)
        } else if (error.graphQLErrors) {
          const { graphQLErrors } = error
          const errorMessages = graphQLErrors.map((e) => e.message).join(', ')
          setNetworkError(false)
          setSubmitButtonDisabled(true)
          setError(errorMessages)
        }
      },
      refetchQueries: [useRefetchPatients()],
    },
  )

  const handleDeviceIdChange = (e) => {
    const inputValue = e.target.value
    setDeviceIdInput(inputValue)
    inputValue ? setSubmitButtonDisabled(false) : setSubmitButtonDisabled(true)
  }

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      const input = {
        deviceId: deviceIdInput,
      }
      try {
        await createPatientAccessByDevice({ variables: { input } })
      } catch (error) {
        const errorMessage = error.message
        setNetworkError(errorMessage)
      }
    },
    [deviceIdInput, createPatientAccessByDevice],
  )

  const isSuccess = !!codeName && !!deviceId

  if (isSuccess) {
    return (
      <SuccessDialog
        classes={classes}
        data-cy="AddPatientSuccess"
        codeName={codeName}
        deviceId={deviceId}
        patientId={patientId}
        handleClose={handleCloseWithToast}
      />
    )
  }

  return (
    <CreateEntityDialog
      title="Add Patient"
      data-cy="addPatient"
      displayLoadingAnimation={true}
      loading={loading}
      success={patientId}
      disabled={submitButtonDisabled}
      handleClose={!networkError ? handleClose : handleCloseWithToast}
      onSubmit={onSubmit}
      submitLabel={!networkError ? 'Connect' : 'Retry'}
      doneLabel="Done"
      successLabel="Created"
    >
      {networkError && !loading && (
        <NetworkErrorAlert
          classes={classes}
          errorMessage="Unable to connect to the server at this time.  Please try again or contact support if the problem persists."
        />
      )}
      <Box>
        <FormControl fullWidth>
          <TextField
            id="deviceIdInput"
            data-cy="deviceIdInput"
            error={!!error}
            helperText={error}
            disabled={loading}
            label="Phone Device ID"
            value={deviceIdInput}
            onChange={(e) => handleDeviceIdChange(e)}
            required={true}
            placeholder="Type here"
            autoFocus
          />
        </FormControl>
        <Box mt={3}>Phone device ID is in the device settings of StrivePD</Box>
      </Box>
    </CreateEntityDialog>
  )
}

export default CreatePatientDialog
