import { useContext } from 'react'
import { DATA_ATTRIBUTES } from 'ui/clinicianScreens/Patient/Report/consts'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { formatBubbleChartData } from '../../helpers'
import { colors } from 'theme/colors'
import BubbleChart from 'ui/baseComponents/Charts/BubbleChart'

const ReportChartMedication = ({ eventList: loggedMeds }) => {
  const { selectedTimezone } = useContext(DateRangeContext)
  const title = 'Logged medication'
  const { medication: medicationDataAttributes } = DATA_ATTRIBUTES
  const { loggedMedicationsChartAttribute } = medicationDataAttributes

  const legendItem = {
    name: title,
    color: colors.PRIMARY[1000],
  }

  const data = formatBubbleChartData({
    label: 'Logged medication',
    data: loggedMeds,
    selectedTimezone,
  })

  return (
    <BubbleChart
      {...{
        title,
        data,
        legendItem,
        legend: true,
        height: 60,
        animateBubbles: false,
        chartId: loggedMedicationsChartAttribute.carrotWebSuffix,
      }}
    />
  )
}

export default ReportChartMedication
