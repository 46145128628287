import { createSelector } from 'reselect'

const initialState = {}

const config = (state = initialState) => state

export default config

export const getConfig = (state) => state.config
export const getLaunchDarklyClientSideId = createSelector(
  getConfig,
  (config) => config.vendors.launchdarkly.clientSideId,
)

export const getTimezoneOffset = (state) => state.config.timezoneOffset
export const getTimezoneName = (state) => state.config.timezoneName
