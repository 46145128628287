import { memo } from 'react'
import { Text } from 'ui/baseComponents/Text'
import { NO_CHANGE, NO_PREV_DATA } from '../consts'
import { Stack } from 'ui/baseComponents/Stack'
import ReportMetricTrendIcon from './ReportMetricTrendIcon'
import { isAtOrBeyondThreshold } from '../helpers'

const ReportMetricTrend = ({
  value,
  trend = 0,
  trendLabel = '',
  variant = 'percent',
  noPrevData,
  iconVariant,
}) => {
  const formatTrendPercent = (trend) => `${Math.abs(trend)}%`
  const formatTrendLessMore = (trend) => {
    if (trend > 0) {
      return `${trend} More than`
    } else {
      return `${trend * -1} Less than`
    }
  }

  const trendIconVariant = isAtOrBeyondThreshold(trend) ? iconVariant : null

  return trend ? (
    <Stack alignItems="center" direction="row">
      <ReportMetricTrendIcon
        direction={trend > 0 ? 'up' : 'down'}
        variant={trendIconVariant}
      />
      <Text variant="body16">
        {variant === 'lessmore'
          ? formatTrendLessMore(trend)
          : formatTrendPercent(trend)}
        {` (${trendLabel})`}
      </Text>
    </Stack>
  ) : (
    <Text variant="body16">
      {value === '—' ? '' : noPrevData ? NO_PREV_DATA : NO_CHANGE}
    </Text>
  )
}

export default memo(ReportMetricTrend)
