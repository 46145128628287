import { useContext } from 'react'
import NavLink from '../../NavLink'
import MembershipContext from 'ui/contexts/MembershipContext'
import PropTypes from 'prop-types'
import {
  Breadcrumbs,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import { UserPHIContext } from 'ui/contexts'
import { clinicianRoutes } from 'ui/clinicianScreens/Patient/Tabs/consts'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    display: 'flex',
    flex: '1 1 auto',
    padding: '1rem',
    color: theme.palette.text.primary,
  },
  date: {
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(4),
  },
  patientCodename: {
    fontSize: '1.4rem',
    fontWeight: 500,
  },
  patientsAdvanced: {
    color: theme.palette.text.primary,
  },
  patientsBasic: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  settingsIconContainer: {
    margin: '0 0 0.25rem 0.25rem',
  },
  settingsIconSmall: {
    height: '1.25rem',
  },
}))

const PatientBreadcrumbs = ({
  id,
  codeName,
  currentPageName,
  showCodeName,
}) => {
  const { admin } = useContext(MembershipContext)
  const phiVisibility = useContext(UserPHIContext)
  const classes = useStyles()

  return (
    <Breadcrumbs className={classes.breadcrumbs}>
      <NavLink
        to="/patients"
        className={
          showCodeName ? classes.patientsAdvanced : classes.patientsBasic
        }
        data-cy="patient-breadcrumbs-patients-list-link"
      >
        Patients
      </NavLink>
      {showCodeName && (
        <div>
          <NavLink
            className={classes.patientCodename}
            color="primary"
            to={
              phiVisibility
                ? `/patients/${id}/${clinicianRoutes.SUMMARY}`
                : `/patients/${id}`
            }
            data-cy="patient-breadcrumbs-patient-link"
          >
            {codeName || 'Patient'}
          </NavLink>
          {admin && (
            <IconButton size="small" className={classes.settingsIconContainer}>
              <NavLink
                to={`/patients/${id}/manage/details`}
                title="Edit Patient Settings"
                aria-label="patient-breadcrumbs-icon-link"
                className={classes.settingsIconSmall}
                data-cy="patient-breadcrumbs-patient-details-link"
              >
                <SettingsIcon fontSize="small" />
              </NavLink>
            </IconButton>
          )}
        </div>
      )}
      <Typography color="textPrimary" variant="h6">
        {currentPageName}
      </Typography>
    </Breadcrumbs>
  )
}

PatientBreadcrumbs.propTypes = {
  id: PropTypes.string,
  codeName: PropTypes.string,
  currentPageName: PropTypes.string.isRequired,
  showCodeName: PropTypes.bool,
}

PatientBreadcrumbs.defaultProps = {
  showCodeName: true,
}

export default PatientBreadcrumbs
