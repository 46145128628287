import { useContext, useState } from 'react'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import moment from 'moment'
import { SingleDatePicker } from 'react-dates'
import { TaskNotificationContext } from 'ui/contexts'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './TasksDatePickerStyles.scss'
import {
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  chronoInput: {
    width: 150,
  },
  selectToggle: {
    width: 80,
    marginRight: theme.spacing(2),
  },
  miniLabel: {
    ...theme.taskNotificationsMiniLabel,
    marginBottom: theme.spacing(1),
  },
}))

/**
 * The Task Schedule section of the Create Task form.
 *
 * @returns {JSX.Element} This component can render a conditional "end date" field
 */
export const TaskScheduleRecurring = () => {
  const classes = useStyles()
  const [focusedInputA, setFocusedInputA] = useState()
  const [focusedInputB, setFocusedInputB] = useState()
  const {
    recurringTaskStartDate,
    recurringTaskDoesEnd,
    recurringTaskEndDate,
    onRecurringTaskStartDate,
    onRecurringTaskDoesEnd,
    onRecurringTaskEndDate,
  } = useContext(TaskNotificationContext)

  const hasValue = (x) => x && x !== 'Invalid date'

  return (
    <ErrorBoundary>
      <Box mb={1}>
        <FormControl component="fieldset">
          <FormLabel className={classes.miniLabel}>Starts</FormLabel>
          <SingleDatePicker
            id="recurringStartDate"
            date={
              hasValue(recurringTaskStartDate)
                ? moment(recurringTaskStartDate)
                : null
            }
            onDateChange={(e) =>
              onRecurringTaskStartDate(moment(e).format('YYYY-MM-DD'))
            }
            focused={focusedInputA}
            onFocusChange={({ focused }) => setFocusedInputA(focused)}
            small={true}
            noBorder={true}
            ariaLabel="Date"
            placeholder="mm/dd/yyyy"
          />
        </FormControl>
      </Box>

      <Box mb={3}>
        <FormControl variant="standard">
          <InputLabel>Ends</InputLabel>
          <Select
            id="recurringTaskDoesEnd"
            name="Recurring Task Does End"
            data-testid="recurringTaskDoesEnd"
            label="Ends"
            value={recurringTaskDoesEnd}
            onChange={onRecurringTaskDoesEnd}
            className={classes.selectToggle}
            inputProps={{
              'aria-label': 'End Select Input',
              'data-testid': 'endSelectInput',
            }}
          >
            <MenuItem key="on" value="true">
              On
            </MenuItem>
            <MenuItem key="never" value="false">
              Never
            </MenuItem>
          </Select>
        </FormControl>

        {recurringTaskDoesEnd === 'true' && (
          <Box display="inline-block" mt={2.15}>
            <FormControl
              component="fieldset"
              data-testid="recurringEndDateInput"
            >
              <SingleDatePicker
                id="recurringEndDate"
                date={
                  hasValue(recurringTaskEndDate)
                    ? moment(recurringTaskEndDate)
                    : null
                }
                onDateChange={(e) =>
                  onRecurringTaskEndDate(moment(e).format('YYYY-MM-DD'))
                }
                focused={focusedInputB}
                onFocusChange={({ focused }) => setFocusedInputB(focused)}
                small={true}
                noBorder={true}
                ariaLabel="Date"
                placeholder="mm/dd/yyyy"
              />
            </FormControl>
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  )
}

export default TaskScheduleRecurring
