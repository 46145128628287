import React from 'react'
import { useAccessTokenList } from './queries'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import AccessTokensTable from './AccessTokensTable/AccessTokensTable'
import CreateAccessToken from './CreateAccessToken/CreateAccessToken'
import { makeStyles } from '@material-ui/core'
import { get } from 'nested-property'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  accessTokensContainer: {
    maxWidth: '1000px',
    minWidth: '800px',
    margin: 'auto',
  },
  emptyState: {
    maxWidth: '1000px',
    minWidth: '800px',
    height: '90vh',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

/**
 * Component for the access tokens view/ecosystem. Includes the access tokens
 * table and access token creation button.
 */
const AccessTokens = () => {
  const classes = useStyles()
  const { loading, data, fetchMore } = useAccessTokenList()
  // checks if there are 4 enabled access tokens and disabled new tokens and enabling old tokens if so
  const tokenLimitReached =
    get(data, 'user.accessTokenList.accessTokens.3') &&
    !get(data, 'user.accessTokenList.accessTokens.3.disabled')

  return (
    <ErrorBoundary>
      <Box
        className={
          !data && !loading ? classes.emptyState : classes.accessTokensContainer
        }
        data-cy="accessTokens"
      >
        <CreateAccessToken
          listLoading={loading}
          tokenLimitReached={tokenLimitReached}
        />
        <AccessTokensTable
          data={data}
          fetchMore={fetchMore}
          listLoading={loading}
          tokenLimitReached={tokenLimitReached}
        />
      </Box>
    </ErrorBoundary>
  )
}

export default AccessTokens
