import { useCallback, useContext, useState } from 'react'
import { FormGroup } from '@material-ui/core'
import SingleFilter from '../SingleFilter'
import EventPicker from './EventPicker'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { TimeBasisContext } from './TimeBasisContext'
import Box from '@material-ui/core/Box'
import FormControl from '../FormControl'
import { TIME_BASIS_ABSOLUTE, TIME_BASIS_RELATIVE } from './consts'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 332,
    margin: theme.spacing(1),
  },
  applyButtonContainer: {
    padding: theme.spacing(2),
    marginBottom: 0,
    display: 'flex',
    alignItems: 'flex-end',
  },
}))

const options = [
  {
    value: TIME_BASIS_ABSOLUTE,
    name: 'Absolute',
  },
  {
    value: TIME_BASIS_RELATIVE,
    name: 'Relative',
  },
]

const TimeBasisPicker = ({ handleClose }) => {
  const { timeBasis, setTimeBasis } = useContext(TimeBasisContext)
  const {
    type: typeProp,
    eventId: eventIdProp,
    eventTimestamp: eventTimestampProp,
  } = timeBasis
  const [type, setType] = useState(typeProp)
  const [eventId, setEventId] = useState(eventIdProp)
  const [eventTimestamp, setEventTimestamp] = useState(eventTimestampProp)
  const setEvent = useCallback((event) => {
    setEventId(event.id)
    setEventTimestamp(event.time)
  }, [])

  const handleApply = useCallback(() => {
    setTimeBasis({ type, eventId, eventTimestamp })
    handleClose()
  }, [eventId, eventTimestamp, handleClose, setTimeBasis, type])
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <FormGroup>
        <SingleFilter
          label="Time Basis"
          selected={type}
          setSelected={setType}
          options={options}
        />
        {type === TIME_BASIS_RELATIVE && (
          <EventPicker eventId={eventId} setEvent={setEvent} />
        )}
      </FormGroup>
      <FormControl className={classes.applyButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          disabled={type === TIME_BASIS_RELATIVE && !eventId}
          onClick={handleApply}
        >
          Apply
        </Button>
      </FormControl>
    </Box>
  )
}

export default TimeBasisPicker
