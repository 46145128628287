import { useContext } from 'react'
import EmptyMonthStream from './EmptyMonthStream/EmptyMonthStream'
import { getDayName } from 'utilities/dates'
import moment from 'moment'
import './MonthBitmapPlot.scss'
import MonthDateMask from './MonthDateMask/MonthDateMask'
import { monthlyAvailabilityColorArray } from '../consts.js'
import Plot from 'ui/components/Plot'
import { TimeBasisContext } from 'ui/components/TimeBasis/TimeBasisContext'
import { TIME_BASIS_RELATIVE } from 'ui/components/TimeBasis/consts'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import { Z_INDEX_MONTH_BITMAP_MONTH_NAME } from 'theme/zIndexRegistry'

const useStyles = makeStyles((theme) => ({
  dateContainer: {
    // set minWidth so that all of these date containers are the same width
    minWidth: 100,
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
  },
  plot: {
    width: '100%',
  },
  dateMask: {
    padding: '0 30px',
    display: 'flex',
    width: '100%',
  },
  monthName: {
    zIndex: Z_INDEX_MONTH_BITMAP_MONTH_NAME,
  },
}))

export const TimeBasisDate = ({ date }) => {
  const classes = useStyles()
  const { timeBasis } = useContext(TimeBasisContext)
  const { type, eventTimestamp } = timeBasis
  const dateReadableName = date.format('MMMM Y')

  const isRelative = type === TIME_BASIS_RELATIVE && eventTimestamp
  if (isRelative) {
    const relativeDaysName = getDayName(date, eventTimestamp)
    return (
      <div className={classes.dateContainer}>
        <Typography variant="subtitle1">{relativeDaysName}</Typography>
        <Typography variant="body2">{dateReadableName}</Typography>
      </div>
    )
  }
  return (
    <div className={classes.dateContainer}>
      <Typography variant="h5" className={classes.monthName}>
        {dateReadableName}
      </Typography>
    </div>
  )
}

/**
 * Visualization of a patient's data availability bitmap for a single time period.
 *
 * @param {array} streams streams to be visualized
 * @param {function} onSelect function that navigates to browse view for the date when clicked
 */
const MonthBitmapPlot = ({ streams, onSelect }) => {
  const classes = useStyles()

  const proto = streams[0]
  const keys = [...streams.keys()]
  const date = moment(proto.x[0])
  let hasData = false

  const z = keys.map((s) => {
    let stream = streams[s].y
    // NOTE: This is a modified carryover from DayBitmapPlot that has poor
    // efficiency, because it iterates over every item in the response. We
    // should do this in the back end or find a way to do it without iterating
    // over everything.
    for (let t = 0; t < stream.length; t++) {
      if (!stream[t]) {
        stream[t] = 0
      } else {
        stream[t] = s + 1
        hasData = true
      }
    }
    // ^^^ DO NOT use map() for this step. map() creates a new array, this
    //     needs to be done in-place
    return stream
  })

  // assigns colors from the monthlyAvailabilityColorArray to heatmap percentages
  const generateColorScale = () => {
    let colorScaleArray = z.flatMap((zItem, i) => {
      const percentage = (1 / z.length) * (i + 1)
      const adjustedPercentage =
        percentage < 1 ? percentage + 0.0001 : percentage
      return [
        [adjustedPercentage, monthlyAvailabilityColorArray[i]],
        [adjustedPercentage, monthlyAvailabilityColorArray[i + 1]],
      ]
    })
    colorScaleArray.pop()
    return colorScaleArray
  }

  const colorScale = generateColorScale()
  const labels = keys.map((s) => streams[s].label)
  const tickvalues = [...Array(labels.length).keys()] // values to assign to labels, allows for duplicate device names
  const customdata = labels.map((label) => Array(z[0].length).fill(label))
  // because of tickvalues fix, we must format customdata to match the structure of z

  const data = {
    name: '',
    colorscale: hasData
      ? [
          [0, '#ffffff'],
          [0.0001, '#ffffff'],
          [0.0001, '#2A27D3'],
          ...colorScale,
        ]
      : [
          [0.0, 'rgb(255,255,255)'],
          [1.0, 'rgb(255,255,255)'],
        ],
    showscale: false,
    type: 'heatmap',
    x: proto.x,
    y: tickvalues,
    z: z,
    customdata: customdata,
    zmin: 0,
    zmax: keys.length,
    hovertemplate: `
      <b>%{customdata}  <br>
      %{x}<br>
      Click date to browse
    `,
  }

  const height = Math.max(120, streams.length * 18)

  const layout = {
    font: { family: 'Work Sans' },
    height,
    margin: { l: 30, r: 30, t: 0, b: 56 },
    showlegend: false,
    visible: true,
    xaxis: {
      automargin: false,
      fixedrange: true,
      zeroline: true,
      linecolor: '#eeeeee',
      showline: true,
      showticklabels: false,
      ticks: '',
    },
    yaxis: {
      showgrid: false,
      zeroline: false,
      type: 'category',
      visible: false,
      showline: false,
      autotick: true,
      ticks: '',
      showticklabels: false,
      fixedrange: true,
      tickmode: 'array',
      ticktext: labels,
      tickvals: tickvalues,
    },
    hoverlabel: {
      bgcolor: '#111111',
      bordercolor: '#111111',
      font: {
        color: '#FDFCFA',
      },
    },
  }

  if (!hasData) {
    return (
      <>
        <TimeBasisDate date={date} />
        <EmptyMonthStream />
      </>
    )
  }

  return (
    <>
      <TimeBasisDate date={date} />
      <div
        data-cy="day-data-bitmap-plot"
        className="day-data-bitmap-plot"
        style={{ height: `${height}px` }}
      >
        <div className="bitmap-plot">
          <div className={classes.dateMask}>
            {proto.x.map((day, i) => (
              <MonthDateMask date={day} index={i} key={i} onSelect={onSelect} />
            ))}
          </div>
          <Plot
            className={classes.plot}
            config={{
              autosize: true,
              responsive: true,
              displayModeBar: false,
              doubleClick: false,
              scrollZoom: false,
            }}
            data={[data]}
            layout={layout}
          />
        </div>
      </div>
    </>
  )
}

export default MonthBitmapPlot
