import { useContext } from 'react'
import { bool, func, number, shape, string } from 'prop-types'
import { useSearchParams } from 'react-router-dom'

import { stringifyQueryParameters } from 'ui/clinicianScreens/Patient/helpers'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import DataAvailabilityDateRangePicker from 'ui/clinicianScreens/Patient/DataAvailabilityDateRangePicker/DataAvailabilityDateRangePicker'
import DataAvailabilityDateRangePickerContextProvider from 'ui/clinicianScreens/Patient/DataAvailabilityDateRangePicker/DataAvailabilityDateRangePickerContextProvider'

const DataAvailabilityDateRangePickerWrapper = ({
  label = DATE_RANGE.default.label,
  rangeKey = DATE_RANGE.default.key,
  maxDays,
  minimumDate,
  maximumDate,
  minimumNights,
  onDateRangeChange,
  autoSelectDayCount,
  autoSelectEndDate,
  blockAndHighlightAutoSelectedDates,
  showArrows,
  initialStateDates,
  hasError,
  ...props
}) => {
  const { dateRanges, setDateRange: setDateRangeContext } =
    useContext(DateRangeContext)
  /* Using initialStateDates prevents defaulting to the last two weeks when we're not using the DateRangeContext default value for the date range
  and we're setting date params elsewhere (e.g. Report) */
  const { start, end } = initialStateDates
    ? initialStateDates
    : dateRanges[rangeKey] || {}
  const [, setSearchParams] = useSearchParams()

  const setDateRangeCallback = ({ from, to }) => {
    if (typeof onDateRangeChange === 'function') {
      onDateRangeChange(from, to)
    } else {
      setDateRangeContext(from, to, rangeKey)
      const newSearchParams = stringifyQueryParameters(from, to)
      setSearchParams(newSearchParams, {
        replace: true,
      })
    }
  }

  return (
    <DataAvailabilityDateRangePickerContextProvider
      {...{
        from: start,
        to: end,
        maxDays,
        minimumDate,
        maximumDate,
        setDateRange: setDateRangeCallback,
        autoSelectDayCount,
        autoSelectEndDate,
      }}
    >
      <DataAvailabilityDateRangePicker
        {...{
          blockAndHighlightAutoSelectedDates,
          label,
          minimumNights,
          showArrows,
          hasError,
          ...props,
        }}
      />
    </DataAvailabilityDateRangePickerContextProvider>
  )
}

DataAvailabilityDateRangePickerWrapper.propTypes = {
  label: string,
  rangeKey: string,
  maxDays: number,
  minimumDate: string,
  maximumDate: string,
  onDateRangeChange: func,
  autoSelectDayCount: number,
  autoSelectEndDate: bool,
  blockAndHighlightAutoSelectedDates: bool,
  showArrows: bool,
  initialStateDates: shape({ start: string, end: string }),
  hasError: bool,
}

export default DataAvailabilityDateRangePickerWrapper
