import { makeExecutableSchema } from '@graphql-tools/schema'
import typeDefs from './typeDefs.mock'
import { resolvers } from './resolvers.mock'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { SchemaLink } from '@apollo/client/link/schema'

let schema
/**
 * Get a singleton schema
 * @param isAdmin
 * @return {GraphQLSchema}
 */
export const getSchema = function ({ isAdmin, verbose } = {}) {
  return (
    schema ||
    (schema = makeExecutableSchema({
      typeDefs,
      resolvers: resolvers({ isAdmin, verbose }),
      resolverValidationOptions: {
        requireResolversForResolveType: false,
      },
    }))
  )
}
export const clearSchema = () => {
  schema = undefined
}
/**
 * Create a mocked ApolloClient for Carrot GraphQL calls
 * @return {ApolloClient<any>}
 */
export const mockGraphClient = ({ isAdmin = true } = {}) => {
  const schema = getSchema({ isAdmin })
  return new ApolloClient({
    link: new SchemaLink({ schema }),
    cache: new InMemoryCache({
      possibleTypes: {
        Actor: ['Membership', 'ExternalUser'],
      },
    }),
  })
}
