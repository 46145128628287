import { memo, useEffect } from 'react'
import { Stack, Text } from 'ui/baseComponents'
import { getDataByDate } from 'ui/clinicianScreens/Patient/Daily/sections/helpers'
import DailyChartTitle from 'ui/clinicianScreens/Patient/Daily/components/DailyChartTitle/DailyChartTitle'
import { SECTION_TITLES } from 'ui/clinicianScreens/Patient/Daily/consts'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import { CLINICIAN_RED_FLAGS } from 'ui/clinicianScreens/Patient/Report/sections/SymptomLog/consts'
import { RedFlagIcon } from 'ui/components/RedFlagIcon'

const DailyCheckinSymptoms = ({
  date,
  data = [],
  setHasDataForDay = () => {},
}) => {
  const dataByDate = getDataByDate(data, date)
  const checkinNames = dataByDate.map((checkin) => ({
    name: getEventName(checkin),
    classification: checkin.classification.enum,
  }))

  useEffect(() => {
    if (dataByDate.length > 0) {
      setHasDataForDay(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataByDate.length])

  return dataByDate.length > 0 ? (
    <Stack>
      <DailyChartTitle variant="body16B" component="h2">
        {SECTION_TITLES.symptoms} ({SECTION_TITLES.checkin})
      </DailyChartTitle>
      <Text variant="body14" component="p">
        {checkinNames.map((item, i) => {
          const isRedFlag = CLINICIAN_RED_FLAGS.includes(item.classification)

          return (
            <Text
              variant={isRedFlag ? 'body14B' : 'body14'}
              key={`daily-checkin-${date + item.name}`}
            >
              {item.name}
              {isRedFlag && (
                <>
                  {' '}
                  <RedFlagIcon />
                </>
              )}
              {i !== checkinNames.length - 1 ? ', ' : ''}
            </Text>
          )
        })}
      </Text>
    </Stack>
  ) : null
}

export default memo(DailyCheckinSymptoms)
