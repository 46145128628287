import { duration as durationConsts } from './consts'

/**
 * Formats the user-facing text for an event of category type "activity."
 * Includes relevant duration details in the text representation if available.
 *
 * @param {object} event The event objects needing formatting
 * @returns {string} The user-facing text representation of the event
 */
export const formatActivityEventText = ({ displayName, payload }) => {
  const { duration } = JSON.parse(payload || '{}')

  if (duration === durationConsts.ONGOING) {
    return `${displayName} is ongoing`
  }

  if (duration) {
    return `${displayName} for ${duration}`
  }

  return `${displayName}`
}
