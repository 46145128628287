/**
 * Formats the user-facing text for an event of category type "medication."
 * Includes relevant dosage details in the text representation.
 *
 * @param {object} event The event objects needing formatting
 * @returns {string} The user-facing text representation of the event
 */
export const formatMedicationEventText = ({ displayName, payload }) => {
  const {
    failed_dose: dosageFailed,
    dosage: dosageQuantity,
    dosage_strength: dosageStrength,
  } = JSON.parse(payload)
  let dosageDetails = []

  if (dosageFailed) {
    return `${displayName} (Missed dose)`
  }

  if (dosageQuantity) {
    const unit = `pill${dosageQuantity > 1 ? 's' : ''}`
    dosageDetails.push(`${dosageQuantity} ${unit}`)
  }

  if (dosageStrength) {
    const { quantity, unit } = dosageStrength
    dosageDetails.push(`${quantity} ${unit}`)
  }

  if (dosageDetails.length) {
    return `${displayName} (${dosageDetails.join(' x ')})`
  }

  return displayName
}
