import { useContext, useEffect, useState } from 'react'
import {
  extractDailySchedules,
  filterMedications,
  formatMedicationScheduleChartData,
  getReportDates,
} from '../helpers'
import { colors } from 'theme/colors'
import { reFetchEventList, usePatientEvents } from 'ui/screens/Patient/queries'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { PatientContext } from 'ui/contexts'
import BubbleChart from 'ui/baseComponents/Charts/BubbleChart'
import { CustomMedicationTooltip } from './ChartTooltips'
import OverviewChartWithTitle from './OverviewChartWithTitle'
import { getTimestampEndOfDay, getTimestampStartOfDay } from 'utilities/time'

const OverviewMedicationChart = ({ selectedMedications, schedules = [] }) => {
  const title = 'MEDICATION'
  const legendItem = {
    name: title,
    color: colors.PURPLE[800],
  }

  const [filteredMedicationData, setFilteredMedicationData] = useState([])

  const scheduleData = extractDailySchedules(schedules)
  const formattedScheduleData = formatMedicationScheduleChartData(scheduleData)

  const updateFilteredMedicationData = () => {
    const data = filterMedications(formattedScheduleData, selectedMedications)
    setFilteredMedicationData(data)
  }

  const { id: patientId } = useContext(PatientContext)
  const { selectedTimezone } = useContext(DateRangeContext)

  const { startDate, endDate } = getReportDates()
  const startTime = getTimestampStartOfDay(startDate, selectedTimezone)

  const endTime = getTimestampEndOfDay(endDate, selectedTimezone)

  const { dataEvents, fetchMore } = usePatientEvents(
    patientId,
    startTime,
    endTime,
  )

  useEffect(() => {
    updateFilteredMedicationData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedications])

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = dataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(fetchMore, endCursor)
    }
  }, [dataEvents, fetchMore])

  return (
    <OverviewChartWithTitle title={title}>
      <BubbleChart
        {...{
          title,
          data: filteredMedicationData,
          legendItem,
          tooltip: CustomMedicationTooltip,
          height: 100,
          animateBubbles: true,
        }}
      />
    </OverviewChartWithTitle>
  )
}

export default OverviewMedicationChart
