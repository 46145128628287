import { gql, useQuery } from '@apollo/client'

export const GET_FULL_PATIENT = gql`
  query getFullPatient($patientId: ID!) {
    patient(id: $patientId) {
      id
      codeName
      deviceList {
        pageInfo {
          endCursor
        }
        devices {
          id
          deviceShortId
          deviceType {
            id
            displayName
          }
          alias
          kind
          disabled
          channels
        }
      }
      clientList {
        clients {
          id
          displayName
          disabled
          clientKeyList {
            clientKeys {
              id
              disabled
              disabledAt
              createdAt
              clientKeyId
            }
          }
        }
      }
      dataSessionList {
        dataSessions {
          id
          createdAt
          device {
            id
            deviceShortId
            alias
          }
          deviceSessionId
          startTime
          endTime
          schemaId
          streams {
            id
            streamName
          }
          uploadedBy {
            ... on ExternalUser {
              displayName
            }
            ... on Membership {
              user {
                id
                displayName
              }
            }
          }
        }
      }
    }
  }
`

/**
 * Get a "full" Patient, along with its devices and clients.
 * @param {string} patientId patient ID
 * @return {{loading, error, patient}} loading, error, patient
 */
export const useFullPatient = (patientId) => {
  const { loading, error, data } = useQuery(GET_FULL_PATIENT, {
    variables: { patientId },
    fetchPolicy: 'cache-and-network',
  })
  const patient = data?.patient
  return {
    loading,
    error,
    patient,
  }
}
export const useRefetchFullPatient = (patientId) => ({
  query: GET_FULL_PATIENT,
  variables: { patientId },
})
