import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { CREATE_ACCESS_TOKEN } from '../mutations'
import { useRefetchAccessTokenList } from '../queries'
import { makeStyles } from '@material-ui/core'
import CopyTextIcon from 'ui/components/CopyText/CopyTextIcon'
import { get } from 'nested-property'
import { Warning, NotificationBar, Error } from 'ui/components/Notifications'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  InputLabel,
  Tooltip,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  loadingButton: {
    width: '174px',
    height: '26px',
    paddingTop: '2px',
  },
  skeletonText: {
    width: '173px',
    margin: '4px 0',
  },
  dummyButton: {
    background: '#FFFFFF !important',
  },
}))

/**
 * Component for creating a new access token. Has a loading state and a
 * success dialog.
 *
 * @param {boolean} listLoading boolean representing the loading status of the user query
 * @param {boolean} tokenLimitReached true when 4 tokens are enabled, used to disable creation of new tokens
 */
const CreateAccessToken = ({ listLoading, tokenLimitReached }) => {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState()
  const [id, setId] = useState('')
  const [secret, setSecret] = useState('')
  const [createAccessTokenMutation, { loading }] = useMutation(
    CREATE_ACCESS_TOKEN,
    {
      onError: (error) => {
        console.error(error)
        setError('There was an error when creating your access token')
      },
      refetchQueries: [useRefetchAccessTokenList()],
    },
  )

  /**
   * Close function for the dialog that resets state
   */
  const handleClose = useCallback(() => {
    setSuccess(false)
    setId('')
    setSecret('')
  }, [])

  /**
   * Allows the error notification to be manually closed.
   */
  const handleCloseNotification = () => {
    setError(false)
  }

  /**
   * Runs the createAccessTokenMutation and sets the data in state
   */
  const createToken = useCallback(async () => {
    try {
      const result = await createAccessTokenMutation()
      const newID = get(
        result,
        'data.createAccessToken.accessToken.accessTokenId',
      )
      const newSecret = get(result, 'data.createAccessToken.accessTokenSecret')
      setId(newID)
      setSecret(newSecret)
      setSuccess(true)
    } catch (error) {
      console.error(error)
      setError(error)
    }
  }, [createAccessTokenMutation])

  /**
   * Upon submit, prevents the default event and runs an createToken request.
   *
   * @param {event} e the submit event that will be prevented
   */
  const onClick = useCallback(
    async (e) => {
      e.preventDefault()
      try {
        await createToken()
      } catch (error) {
        console.error(error)
      }
    },
    [createToken],
  )

  // Skeleton for the button
  if (listLoading) {
    return (
      <Box px={3} pt={5} pb={1} display="flex" justifyContent="center">
        <Button
          size="large"
          variant="contained"
          color="primary"
          disabled={true}
          data-cy="CreateEntityButton"
          className={classes.dummyButton}
        >
          <Skeleton className={classes.skeletonText} />
        </Button>
      </Box>
    )
  }

  const buttonDisabled = loading || tokenLimitReached
  const tooltipText = tokenLimitReached
    ? 'For security reasons, you may have at most 4 active tokens at one time.'
    : ''

  return (
    <ErrorBoundary>
      <Box px={3} pt={5} pb={1} display="flex" justifyContent="center">
        <Tooltip title={tooltipText}>
          <Box>
            <Button
              size="large"
              variant="contained"
              color="primary"
              data-cy="CreateEntityButton"
              disabled={buttonDisabled}
              onClick={onClick}
            >
              {loading ? (
                <Box className={classes.loadingButton}>
                  <CircularProgress size="1rem" />
                </Box>
              ) : (
                <Box>Create Access Token</Box>
              )}
            </Button>
          </Box>
        </Tooltip>
      </Box>

      <Dialog open={success} p={3}>
        <DialogContent>
          <Box mb={1} mt={3}>
            <InputLabel shrink={true}>Access Token ID</InputLabel>
          </Box>
          <Box mb={2}>
            <CopyTextIcon value={id} size="full" />
          </Box>
          <Box my={1}>
            <InputLabel shrink={true}>Access Token Secret</InputLabel>
          </Box>
          <Box mb={1}>
            <CopyTextIcon value={secret} size="full" />
          </Box>
        </DialogContent>
        <Box p={3} pt={1}>
          <Warning
            action={null}
            message="This is the only time this secret will be displayed. Please use it now, or copy it to a secure location for later."
          />
        </Box>
        <DialogActions>
          <Box px={3} pb={3}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Done
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <NotificationBar open={error} onClose={handleCloseNotification}>
        <Error message={error} onClose={handleCloseNotification} />
      </NotificationBar>
    </ErrorBoundary>
  )
}

export default CreateAccessToken
