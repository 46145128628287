const eventsSlice = (set) => ({
  dailyViewCombinedMedications: true,
  toggleDailyViewCombinedMedications: () =>
    set((state) => ({
      dailyViewCombinedMedications: !state.dailyViewCombinedMedications,
    })),
  dailyViewFixedYAxis: true,
  toggleDailyViewFixedYAxis: () =>
    set((state) => ({
      dailyViewFixedYAxis: !state.dailyViewFixedYAxis,
    })),
  dailyViewHideEmptyDays: false,
  toggleDailyViewHideEmptyDays: () =>
    set((state) => ({
      dailyViewHideEmptyDays: !state.dailyViewHideEmptyDays,
    })),
  dailyViewRedFlagDates: new Set(),
  addDailyViewRedFlagDates: (dates) =>
    set((state) => {
      const newSet = new Set(state.dailyViewRedFlagDates)
      dates.forEach((date) => newSet.add(date))
      return {
        dailyViewRedFlagDates: newSet,
      }
    }),
  resetDailyViewRedFlagDates: () =>
    set((state) => ({
      dailyViewRedFlagDates: new Set(),
    })),
})

export default eventsSlice
