import React from 'react'
import SignInContext, { useSignInContext } from 'ui/contexts/SignInContext'
import SignIn from './SignIn'

const SignInWrapper = (props) => {
  const signInContextValue = useSignInContext()

  return (
    <SignInContext.Provider value={signInContextValue}>
      <SignIn {...props} />
    </SignInContext.Provider>
  )
}

export default SignInWrapper
