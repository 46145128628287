import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Text } from 'ui/baseComponents'
import AppFooter from 'ui/components/AppFooter'
import CookieBanner from 'ui/components/CookieBanner/CookieBanner'
import MainContent from 'ui/templates/MainContent'
import MainContentWrapper from 'ui/templates/MainContentWrapper'
import MainNav from 'ui/components/MainNav'
import PageHeader from 'ui/templates/PageHeader'
import {
  PatientSearch,
  PatientTableToolbar,
  PatientTableWithPHIAccess,
  PatientTableWithoutPHIAccess,
  ViewPreferenceButton,
} from 'ui/screens/Patients/PatientList/PatientTable'
import TaskNotificationsLink from 'ui/screens/Patients/TaskNotifications/TaskNotificationsLink/TaskNotificationsLink'
import {
  MORE_RESULTS_AVAILABLE_TEXT,
  SEARCH_FIELDS,
} from 'ui/screens/Patients/PatientList/PatientTable/consts'
import UserContext from 'ui/contexts/UserContext'
import UserPHIContext from 'ui/contexts/UserPHIContext'
import { colors } from 'theme/colors'
import { useIsAdmin } from 'ui/hooks'
import useBoundStore from 'domains/zustand/store'

const STYLES = {
  patientTableBox: {
    paddingTop: '1rem',
    backgroundColor: colors.COOL[100],
  },
  patientTableSubBox: {
    maxWidth: 1063,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

/**
 * Renders the Patient List page for the Clinician Portal.
 * This component displays a table of patients and provides options to filter and view recent patients.
 *
 * @returns {JSX.Element} The rendered PatientListClinicianPortal component.

 */
const PatientListClinicianPortal = () => {
  const { admin } = useIsAdmin()
  const { defaultMembership } = useContext(UserContext)
  const phiVisibility = useContext(UserPHIContext)
  const [orgPatientCount, setOrgPatientCount] = useState(
    defaultMembership?.org?.patientCount,
  )
  const moreSearchResultsAvailable = useBoundStore(
    (state) => state.moreSearchResultsAvailable,
  )
  const resetPatientList = useBoundStore((state) => state.resetPatientList)
  const resetSearchPatients = useBoundStore(
    (state) => state.resetSearchPatients,
  )
  const setSearchPatientsExecuted = useBoundStore(
    (state) => state.setSearchPatientsExecuted,
  )

  const showAllPatients = useBoundStore((state) => state.showAllPatients)
  const toggleAllPatients = useBoundStore((state) => state.toggleAllPatients)

  useEffect(() => {
    setOrgPatientCount(defaultMembership?.org?.patientCount)
    resetPatientList()
    resetSearchPatients()
    setSearchPatientsExecuted(false)
    if (showAllPatients) {
      toggleAllPatients()
    }
  }, [defaultMembership.org.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const { codeName, email, firstName, lastName } = SEARCH_FIELDS
  const searchFields = phiVisibility ? [firstName, lastName, email] : [codeName]

  const searchFieldsDefaultFocus = phiVisibility ? firstName : codeName

  return (
    <>
      <MainNav />
      <PageHeader
        title={
          <Text variant="head42" component="h1">
            {defaultMembership?.org?.displayName} ({orgPatientCount})
          </Text>
        }
        contentRight={
          <>
            <ViewPreferenceButton />
            {admin && <TaskNotificationsLink />}
          </>
        }
      />
      <MainContentWrapper>
        <Box sx={STYLES.patientTableBox}>
          <MainContent>
            <Box sx={STYLES.patientTableSubBox}>
              <PatientTableToolbar
                searchComponent={
                  !showAllPatients && (
                    <PatientSearch
                      {...{
                        defaultFocus: searchFieldsDefaultFocus,
                        searchFields,
                      }}
                    />
                  )
                }
              />
              {phiVisibility ? (
                <PatientTableWithPHIAccess />
              ) : (
                <PatientTableWithoutPHIAccess />
              )}
              {moreSearchResultsAvailable && (
                <Text
                  variant="head18"
                  component="p"
                  sx={{ padding: '2rem 0', textAlign: 'center' }}
                >
                  {MORE_RESULTS_AVAILABLE_TEXT}
                </Text>
              )}
            </Box>
          </MainContent>
        </Box>
      </MainContentWrapper>
      <AppFooter />
      <CookieBanner />
    </>
  )
}

export default PatientListClinicianPortal
