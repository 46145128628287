import { useContext, useEffect, useMemo } from 'react'
import { ADD_STREAM } from 'ui/screens/Patient/Browse/StreamSelector/StreamsContext'
import { connect } from 'react-redux'
import EmptyResults from 'ui/components/EmptyResults/EmptyResults'
import ErrorBoundary from 'ui/components/ErrorBoundary'
import { eventsStreamDevice } from './EventsAvailability/EventsStreamDevice'
import ExplorerFilters from './ExplorerFilters'
import ExplorerBitmap from './ExplorerBitmap'
import { makeQuerySelector, pushQuery } from 'query'
import PatientContext from 'ui/contexts/PatientContext'
import { sharedRoutes } from '../consts'
import streamsReducer from 'ui/screens/Patient/Browse/StreamSelector/StreamsContext/reducer'
import { TimeBasisProvider } from 'ui/components/TimeBasis/TimeBasisContext'
import { useAllDeviceStreamOptions } from 'ui/screens/Patient/Browse/StreamSelector/StreamItem/hooks'
import { useLocation } from 'react-router-dom'
import { SECOND_IN_MILLISECONDS, validateDates } from 'utilities/time'
import { makeStyles, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 1 auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '2rem',
  },
  bitmap: {
    minHeight: '300px',
    padding: `0 ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    position: 'relative',
  },
}))

/**
 * Based off of available stream options and selected device ids,
 * pre-calculate the streams state we want our Browse drilldown
 * to populate the StreamsSelector with. We do this here because
 * it's much simpler than trying to intercept the first navigation
 * to the Browse page and calculate a valid stream state from the
 * query string, etc.
 * @param {object[]} streamOptions Array of stream options
 * @param {string[]} selectedDeviceIds Array of device IDs
 * @return {object[]} Default stream options for each device
 */
const initializeBrowseStreamsState = ({
  streamOptions,
  selectedDeviceIds = [],
}) => {
  const defaultStreamOptions = selectedDeviceIds.reduce(
    (iterator, deviceId) => {
      // Pick the first stream option for the device, if any
      const defaultDeviceStreamOption = streamOptions.find(
        (option) => option.device.id === deviceId,
      )
      return defaultDeviceStreamOption
        ? [...iterator, defaultDeviceStreamOption]
        : iterator
    },
    [],
  )
  // add the default stream option for every preselected device
  const streams = defaultStreamOptions.reduce(
    (iterator, streamOption) =>
      streamsReducer(iterator, {
        type: ADD_STREAM,
        deviceId: streamOption.device.id,
        streamValue: streamOption.stream.value,
      }),
    streamsReducer(),
  )

  return streams
}

export const Explorer = ({
  dateStart,
  dateEnd,
  pushQuery,
  selectedDeviceIds,
}) => {
  const location = useLocation() || {}
  const classes = useStyles()
  const patient = useContext(PatientContext)
  const monthlyView = location?.pathname.includes('explore-monthly')
  const validatedDates = validateDates(dateStart, dateEnd, false, monthlyView)
  const [validatedStartDate, validatedEndDate] = validatedDates

  useEffect(
    () => {
      // Push query either using the url params if valid, or with default as necessary
      if (validatedStartDate !== dateStart || validatedEndDate !== dateEnd) {
        pushQuery(
          {
            from: validatedStartDate,
            to: validatedEndDate,
          },
          {
            replaceHistory: true,
          },
        )
      }
      if (!selectedDeviceIds || !selectedDeviceIds.length) {
        const deviceListWithEvents = [
          ...patient.deviceList.devices,
          eventsStreamDevice,
        ]
        pushQuery(
          {
            devices: deviceListWithEvents.map((dev) => dev.id).join('|'),
          },
          {
            replaceHistory: true,
          },
        )
      }
    },
    // eslint-disable-next-line
    [],
  )

  const deviceDict = {}

  const possibleDeviceList = [...patient.deviceList.devices, eventsStreamDevice]

  for (const device of possibleDeviceList) {
    deviceDict[device.id] = device
  }
  const streamOptions = useAllDeviceStreamOptions()

  const streams = useMemo(
    () =>
      JSON.stringify(
        initializeBrowseStreamsState({
          streamOptions,
          selectedDeviceIds,
        }),
      ),
    [selectedDeviceIds, streamOptions],
  )

  /**
   * Adds or removes device to/from selected devices and updates the query.
   *
   * @param {string} id The ID of the device
   * @returns {void}
   */
  const addOrRemoveSelectedDeviceIds = (id) => {
    let updatedIDs = []
    const doesExist = selectedDeviceIds.includes(id)

    if (doesExist) {
      // remove
      updatedIDs = selectedDeviceIds.filter((x) => x !== id)
    } else {
      // add
      updatedIDs = [...selectedDeviceIds, id]
    }
    // update query
    pushQuery({ devices: updatedIDs.join('|') })
  }

  /**
   * Replaces a device in place when changed
   *
   * @param {string} id The ID of the device
   * @param {string} newID The ID of the replacement device
   * @returns {void}
   */
  const replaceSelectedDeviceIds = (id, newID) => {
    // Ignore if same device
    if (id === newID) {
      return
    }
    // get the index of the stream to be replaced
    const updatedIDs = selectedDeviceIds
    const NOT_FOUND = -1
    const index = updatedIDs.indexOf(id)
    // if it is present, replace it and push a new query
    if (index !== NOT_FOUND) {
      updatedIDs[index] = newID
      pushQuery({ devices: updatedIDs.join('|') })
    }
  }

  return (
    <ErrorBoundary>
      <div className={classes.root} data-cy="Explorer">
        <TimeBasisProvider>
          <ExplorerFilters
            dateStart={validatedStartDate}
            dateEnd={validatedEndDate}
            monthlyView={monthlyView}
            patient={patient}
            selectedDeviceIds={selectedDeviceIds}
            setDateRange={({ from, to }) => pushQuery({ from, to })}
            setSelectedDeviceIds={addOrRemoveSelectedDeviceIds}
            replaceSelectedDeviceIds={replaceSelectedDeviceIds}
            devices={selectedDeviceIds.map((deviceId) => deviceDict[deviceId])}
          />
          <Paper className={classes.bitmap}>
            {selectedDeviceIds.length ? (
              <ExplorerBitmap
                dateStart={validatedStartDate}
                dateEnd={validatedEndDate}
                patient={patient}
                devices={selectedDeviceIds.map(
                  (deviceId) => deviceDict[deviceId],
                )}
                monthlyView={monthlyView}
                setDateRange={({ from, to }) => pushQuery({ from, to })}
                onSelect={(tmStart, tmEnd) => {
                  pushQuery(
                    {
                      start: tmStart.valueOf() / SECOND_IN_MILLISECONDS,
                      end: tmEnd.valueOf() / SECOND_IN_MILLISECONDS,
                      streams,
                    },
                    {
                      url: `/patients/${patient.id}/${sharedRoutes.BROWSE}`,
                      replaceQuery: true,
                    },
                  )
                }}
              />
            ) : (
              <EmptyResults
                icon="devices_other_icon"
                message="Select device(s) to explore patient data"
              />
            )}
          </Paper>
        </TimeBasisProvider>
      </div>
    </ErrorBoundary>
  )
}

export default connect(
  (state, ownProps) => ({
    dateEnd: makeQuerySelector('to')(state),
    dateStart: makeQuerySelector('from')(state),
    selectedDeviceIds: (makeQuerySelector('devices')(state) || '')
      .split('|')
      .filter((id) => id),
  }),
  {
    pushQuery,
  },
)(Explorer)
