import { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import * as MUI from '@mui/material'
import { Button, Stack } from 'ui/baseComponents'
import { trackComparisonModeApplied } from 'domains/mixpanel'
import DataAvailabilityDateRangePickerWrapper from 'ui/clinicianScreens/Patient/DataAvailabilityDateRangePicker/DataAvailabilityDateRangePickerWrapper'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import SummaryComparisonSwitch from '../../Items/SummaryComparisonSwitch'
import { BetaBanner } from 'ui/components/Beta'
import TabActionableHeader from 'ui/templates/TabActionableHeader'

const STYLES = {
  contentContainer: { width: '100%' },
  dateRangePickerContainer: { width: '100%' },
  spacer: { margin: '8px' },
  switchContainer: { alignSelf: 'center' },
}

const INITIAL_STATE = {
  start: null,
  end: null,
}

const SummaryViewsComparisonModeHeader = () => {
  const smallScreens = MUI.useMediaQuery('(max-width:1382px)')
  const extraSmallScreens = MUI.useMediaQuery('(max-width:980px)')

  const { dateRanges, setDateRange } = useContext(DateRangeContext)
  const [, setSearchParams] = useSearchParams()

  const [buttonEnabled, setButtonEnabled] = useState(false)

  const { start: period1StartContext, end: period1EndContext } =
    dateRanges[DATE_RANGE.period1.key] || {}
  const { start: period2StartContext, end: period2EndContext } =
    dateRanges[DATE_RANGE.period2.key] || {}

  const period1InitialState =
    period1StartContext && period1EndContext
      ? dateRanges[DATE_RANGE.period1.key]
      : INITIAL_STATE

  const period2InitialState =
    period2StartContext && period2EndContext
      ? dateRanges[DATE_RANGE.period2.key]
      : INITIAL_STATE

  const [period1Range, setPeriod1Range] = useState(period1InitialState)
  const [period2Range, setPeriod2Range] = useState(period2InitialState)

  useEffect(() => {
    const { start: period1StartInput, end: period1EndInput } = period1Range
    const { start: period2StartInput, end: period2EndInput } = period2Range

    if (
      period1StartInput &&
      period1EndInput &&
      period2StartInput &&
      period2EndInput &&
      (period1StartInput !== period1StartContext ||
        period1EndInput !== period1EndContext ||
        period2StartInput !== period2StartContext ||
        period2EndInput !== period2EndContext)
    ) {
      setButtonEnabled(true)
    } else {
      setButtonEnabled(false)
    }
  }, [
    period1Range,
    period2Range,
    period1StartContext,
    period1EndContext,
    period2StartContext,
    period2EndContext,
    dateRanges,
  ])

  const onClickApply = () => {
    setDateRange(period1Range.start, period1Range.end, DATE_RANGE.period1.key)
    setDateRange(period2Range.start, period2Range.end, DATE_RANGE.period2.key)

    const newSearchParams = {
      startTimePeriod1: period1Range.start,
      endTimePeriod1: period1Range.end,
      startTimePeriod2: period2Range.start,
      endTimePeriod2: period2Range.end,
    }
    setSearchParams(newSearchParams, {
      replace: true,
    })

    trackComparisonModeApplied()
  }

  return (
    <>
      <TabActionableHeader>
        <Stack
          direction={extraSmallScreens ? 'column' : 'row'}
          justifyContent="space-between"
          sx={STYLES.contentContainer}
        >
          <Stack
            direction="row"
            sx={{
              alignSelf: 'center',
            }}
          >
            <Stack
              direction={smallScreens ? 'column' : 'row'}
              sx={STYLES.dateRangePickerContainer}
            >
              <DataAvailabilityDateRangePickerWrapper
                label={DATE_RANGE.period1.label}
                rangeKey={DATE_RANGE.period1.key}
                onDateRangeChange={(start, end) =>
                  setPeriod1Range({ start, end })
                }
                maximumDate={period2Range.start}
              />
              <MUI.Box component="span" sx={STYLES.spacer} />
              <DataAvailabilityDateRangePickerWrapper
                label={DATE_RANGE.period2.label}
                rangeKey={DATE_RANGE.period2.key}
                onDateRangeChange={(start, end) =>
                  setPeriod2Range({ start, end })
                }
                minimumDate={period1Range.end}
              />
            </Stack>

            <MUI.Box component="span" sx={STYLES.spacer} />
            <Button
              type="secondary"
              disabled={!buttonEnabled}
              onClick={onClickApply}
            >
              Apply
            </Button>
          </Stack>
          <Stack direction="row" sx={STYLES.switchContainer}>
            <SummaryComparisonSwitch />
          </Stack>
        </Stack>
      </TabActionableHeader>
      <BetaBanner
        featureName="Mobility Metrics"
        tutorialLink="https://www.loom.com/share/9722d578adb649c3bfc977db4516fd81"
      />
    </>
  )
}

export default SummaryViewsComparisonModeHeader
