import { gql } from '@apollo/client'

export const CREATE_ACCESS_TOKEN = gql`
  mutation createAccessToken {
    createAccessToken {
      accessToken {
        id
        accessTokenId
        createdAt
        disabled
        disabledAt
        updatedAt
        usedAt
      }
      accessTokenSecret
    }
  }
`

export const UPDATE_ACCESS_TOKEN = gql`
  mutation updateAccessToken($input: UpdateAccessTokenInput!) {
    updateAccessToken(input: $input) {
      accessToken {
        id
        accessTokenId
        createdAt
        disabled
        disabledAt
        updatedAt
        usedAt
      }
    }
  }
`
