import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Grid as MaterialGrid } from '@material-ui/core'

import ErrorBoundary from 'ui/components/ErrorBoundary'

const gridSpacing = 2

const useStyles = makeStyles((theme) => ({
  gutter: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(gridSpacing),
    },
  },
}))

const useGridStyles = makeStyles(() => ({
  'spacing-xs-2': {
    width: '100%',
  },
}))

/**
 * A grid container with sensible defaults
 */
export const GridContainer = ({ children, hasSubgrids }) => {
  const containerClasses = useGridStyles()

  return (
    <Grid
      container
      classes={hasSubgrids ? containerClasses : undefined}
      alignItems="flex-start"
      spacing={gridSpacing}
    >
      {children}
    </Grid>
  )
}

GridContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  hasSubgrids: PropTypes.bool, // TODO: hack
}

GridContainer.defaultProps = {
  hasSubgrids: false,
}

/**
 * When 2+ side-by-side grids are needed
 */
export const SubGrid = ({ children, isFirst, ...props }) => {
  const classes = useStyles()

  return (
    <Grid
      xs={12}
      md={6}
      {...props}
      container
      item
      spacing={gridSpacing}
      className={clsx({ [classes.gutter]: !isFirst })}
    >
      {children}
    </Grid>
  )
}

SubGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  /**
   * Is the first / left-most
   */
  isFirst: PropTypes.bool,
}

SubGrid.defaultProps = {
  isFirst: false,
}

/**
 * A grid container with sensible defaults
 */
export const Grid = ({ children, ...props }) => (
  <ErrorBoundary>
    <MaterialGrid {...props}>{children}</MaterialGrid>
  </ErrorBoundary>
)

Grid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

Grid.defaultProps = {}
