import React from 'react'
import { arrayOf, node, oneOfType } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  legendWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const LegendWrapper = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.legendWrapper}>{children}</div>
}

LegendWrapper.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
}

LegendWrapper.defaultProps = {}

export default LegendWrapper
