import { memo, useContext } from 'react'
import { Grid } from '@material-ui/core'
import { SummarySection } from 'ui/components/SummarySection'
import { DateRangeContext, DATE_RANGE } from 'ui/contexts/DateRangeContext'
import moment from 'moment'
import { Sleep, TremorAndDyskinesia } from '../components/CollapsibleViews'
import { SECTION_TITLE } from '../../consts'
import { MobilitySummary } from 'ui/clinicianScreens/Patient/Summary/components/CollapsibleViews/Mobility'
import { labels as mobilityLabels } from 'ui/clinicianScreens/Patient/Summary/components/CollapsibleViews/Mobility/consts'

const ComparisonModeCollapsibleViews = () => {
  const { dateRanges } = useContext(DateRangeContext)
  const { start: period1Start, end: period1End } =
    dateRanges[DATE_RANGE.period1.key] || {}
  const { start: period2Start, end: period2End } =
    dateRanges[DATE_RANGE.period2.key] || {}

  const formatDate = (date) => moment(date).format('MM/DD/YYYY')
  const period1RangeFormatted = `${formatDate(period1Start)} - ${formatDate(
    period1End,
  )}`
  const period2RangeFormatted = `${formatDate(period2Start)} - ${formatDate(
    period2End,
  )}`

  return (
    <>
      <SummarySection title={SECTION_TITLE.watchOrPhone}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TremorAndDyskinesia
              titleSuffix={`(${DATE_RANGE.period1.titleSuffix})`}
              subtitle={period1RangeFormatted}
              dateRangeKey={DATE_RANGE.period1.key}
              start={period1Start}
              end={period1End}
            />
          </Grid>
          <Grid item>
            <TremorAndDyskinesia
              titleSuffix={`(${DATE_RANGE.period2.titleSuffix})`}
              subtitle={period2RangeFormatted}
              dateRangeKey={DATE_RANGE.period2.key}
              start={period2Start}
              end={period2End}
            />
          </Grid>
          <>
            <Grid item>
              <MobilitySummary
                title={`${mobilityLabels.TITLE} (${DATE_RANGE.period1.titleSuffix})`}
                subtitle={period1RangeFormatted}
                start={period1Start}
                end={period1End}
                isInComparisonMode
              />
            </Grid>
            <Grid item>
              <MobilitySummary
                title={`${mobilityLabels.TITLE} (${DATE_RANGE.period2.titleSuffix})`}
                subtitle={period2RangeFormatted}
                start={period2Start}
                end={period2End}
                isInComparisonMode
              />
            </Grid>
          </>
          <Grid item>
            <Sleep
              titleSuffix={`(${DATE_RANGE.period1.titleSuffix})`}
              subtitle={period1RangeFormatted}
              dateRangeKey={DATE_RANGE.period1.key}
              start={period1Start}
              end={period1End}
            />
          </Grid>
          <Grid item>
            <Sleep
              titleSuffix={`(${DATE_RANGE.period2.titleSuffix})`}
              subtitle={period2RangeFormatted}
              dateRangeKey={DATE_RANGE.period2.key}
              start={period2Start}
              end={period2End}
            />
          </Grid>
        </Grid>
      </SummarySection>
    </>
  )
}

export default memo(ComparisonModeCollapsibleViews)
